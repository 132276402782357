<!-- 助力瓜分优惠券 -->
<template>
  <div class="wrap">
    <TablePage
      ref="tablePage"
      v-show="showList"
      v-model="options"
      @handleEvent="handleEvent"
    />
    <TablePage
      ref="tablePageDetail"
      v-if="!showList"
      class="detail"
      v-model="optionsDetail"
      @handleEvent="handleEventDetail"
    />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  carveUpCouponList,
  carveUpCouponEnable,
  carveUpCouponAudit,
  carveUpCouponDelete,
  carveUpCouponCopy,
  getMallMarketingCouponCarveUpLog,
} from "@/api/O2OMall/marketing/carveUpCoupon"; //接口api
export default {
  name: "StockQuery",
  components: { TablePage },
  dicts: ["bill_status"],
  data() {
    return {
      showList: true,
      openStockQuery: false,
      goodsDetailData: {},
      options: {
        uuid: "8897acfc-70b2-94d4-3df8-b454268e1ddc",
        radioSelect: true,
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        rowKey: "billId",
        check: [],
        title: "助力瓜分优惠券",
        tableTitle: "助力瓜分优惠券",
        search: [
          {
            label: "创建时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ queryBegTime: a, queryEndTime: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/活动编号/活动名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "活动编号" },
              { filter: "billNames", label: "活动名称" },
            ],
          },
          this.$select({
            key: "executionStatus",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "isStopState",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "bill_status",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "recon",
            label: "启用",
            type: "warning",
            btnType: "dropdown",
            other: [
              {
                click: "redoRecon",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
            alertText: '确认要删除选中的活动吗？',
          },
          {
            click: "oepnDetail",
            label: "助力瓜分优惠券",
            type: "primary",
            right: true,
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        getListApi: carveUpCouponList,
        columns: [
          {
            prop: "billNo",
            label: "活动编号",
            type: "link",
            click: "routerLink",
            minWidth: 155,
          },
          {
            prop: "billName",
            label: "活动名称",
            minWidth: 155,
          },
          {
            prop: "billStatus",
            label: "活动状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "executionStatusName",
            label: "执行状态",
            minWidth: 155,
            formatter: (v, row) => {
              let currentTime = new Date().getTime();
              let startTime = new Date(row.begTime).getTime();
              let endTime = new Date(row.endTime).getTime();
              return currentTime < startTime
                ? "未开始"
                : currentTime > endTime
                ? "已失效"
                : "进行中";
            },
          },
          {
            prop: "isStop",
            label: "是否停用",
            formatter: (v, row) => (row.isStop ? "禁用" : "启用"),
            minWidth: 120,
          },
          {
            prop: "begTime",
            label: "生效时间",
            width: 170,
          },
          {
            prop: "endTime",
            label: "失效时间",
            width: 170,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 120,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
        list: [
          {
            goodsNo: "000",
          },
        ],
      },
      optionsDetail: {
        uuid: "6c764d7b-61b4-c073-61c8-c3292e09122e",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "助力瓜分优惠券明细",
        tableTitle: "助力瓜分优惠券明细",
        rowKey: "billId",
        defaultBody: {
          billId: "",
        },
        getListApi: getMallMarketingCouponCarveUpLog,
        // getDataKey: (e)=> {
        // this.optionsDetail.search = this.optionsDetail.search.map(item => ({
        //   ...item,
        // }))
        //   return {list: e.data.tableDataInfo.rows, total: e.data.tableDataInfo.total}
        // },
        search: [
          {
            label: "领取时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/活动编号/活动名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNo", label: "活动编号" },
              { filter: "billNames", label: "活动名称" },
            ],
          },
          this.$select({
            key: "isStatus",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "close",
            label: "关闭",
            type: "primary",
            right: true,
          },
        ],
        columns: [
          { label: "活动编号", prop: "billNo", fixed: true, minWidth: 120 },
          { label: "活动名称", prop: "billName", fixed: true, minWidth: 120 },
          {
            label: "瓜分方式",
            prop: "caseCarveUpType",
            fixed: true,
            formatter: (v, row) => {
              return row.caseCarveUpType == 1 ? "好友均分" : "拼手气";
            },
            minWidth: 120,
          },
          { label: "已发起助力数", prop: "isPromoterCount", fixed: true, minWidth: 120 },
          { label: "助力成功数", prop: "successUsableCount", minWidth: 100 },
          {
            label: "剩余可发起瓜分数",
            prop: "num",
            formatter: (v, row) => {
              return row.caseUsableTotal - row.isPromoterCount;
            },
            minWidth: 120,
          },
          {
            label: "已使用（优惠券）",
            prop: "batchNo",
            formatter: (v, row) => {
              return row.isUse ? "是" : "否";
            },
            minWidth: 100,
          },
          {
            label: "状态",
            prop: "firstQty",
            formatter: (v, row) => {
              let obj = {
                1: "已助力",
                2: "助力成功",
                3: "未领取",
                4: "领取成功",
              };
              return obj[row.status];
            },
            minWidth: 80,
          },
        ],
        // summary: [
        //   'isPromoterCount',
        //   'successUsableCount',
        // ],
        list: [],
      },
    };
  },
  methods: {
    async handleEventDetail(type, row) {
      switch (type) {
        case "close": // 关闭二级弹窗
          this.showList = true;
          break;
      }
    },
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "close":
          this.showList = true;
          this.optionsDetail.list = [];
          break;
        case "oepnDetail": //获取点击商品信息
          if (this.options.check.length == 0) return this.$message.warning("请选择活动");
          this.optionsDetail.defaultBody.billId = this.options.check[0].billId;
          this.showList = false;
          break;
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: "CarveUpCouponDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push({
              name: "CarveUpCouponDetail",
              query: {
                type: "Add",
              },
            });
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            const billId = selectData
              .filter((i) => i.billStatus != 2)
              .map((i) => i.billId);
            if (billId.length == 0) {
              return this.$message.warning("请选择未审核的数据");
            }
            carveUpCouponAudit({ billIds: billId, auditStatus: 2 }).then((res) => {
              this.$message.success("审核成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            const billIds = selectData.map((i) => i.billId);
            carveUpCouponDelete(billIds).then((res) => {
              this.$message.success("删除成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        case "copy":
          {
            if (!selectData.length) return;
            const billIds = selectData.map((i) => i.billId);
            carveUpCouponCopy(billIds).then((res) => {
              this.$message.success("复制成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        case "recon":
          {
            if (!selectData.length) return;
            const billId = selectData.map((i) => i.billId);
            if (selectData[0].billStatus != 2)
              return this.$message.warning("只能操作已审核的数据");
            carveUpCouponEnable({ billIds: billId, isStop: false }).then((res) => {
              this.$message.success("启用成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        case "redoRecon":
          {
            if (!selectData.length) return;
            const billId = selectData.map((i) => i.billId);
            if (selectData[0].billStatus != 2)
              return this.$message.warning("只能操作已审核的数据");
            carveUpCouponEnable({ billIds: billId, isStop: true }).then((res) => {
              this.$message.success("禁用成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  ::v-deep .el-table {
    height: 500px !important;
    max-height: 500px !important;
    overflow: visible !important;
  }
}
</style>
