<template>
  <div class="wrap">
    <EditTable :options="options" @handleEvent="handleEvent" />
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
    </div>
  </div>
</template>
<script>
import { BILL_PATH } from "@/utils";
import { mixin } from "@/components/Dialog/components/mixin.js";
import EditTable from '@/components/editTable' //可编辑表格
export default {
  name: "SourceJump",
  mixins: [mixin],
  components: { EditTable },
  data() {
    return {
      billType: '',
      partnerId: '',
      options: {}
    };
  },
  created() {
    console.log(this.data)
    this.options = {
      curListIndex: -1,
      mutiSelect: false,
      list: ['190101', '190104'].includes(this.data.billType) ? this.data.arDetails : this.data.apDetails,
      curListIndex: -1,
      tableCellLabel: '序号',
      tableHeight: 300,
      status: undefined,
      isNoAddBtn: true,
      isNoDelBtn: true,
      hideTotal: true,
      retainColumn: true,
      isOperation: true,
      columns: [
        {
          prop: 'sourceBillId',
          label: '单据编号',
          type: "link",
          minWidth: 120,
          click: "routerLink",
          align: 'center'
        },
        {
          prop: 'sourceBillTypeName',
          label: '单据类型',
          minWidth: 120,
          input: 'sourceBillTypeName',
          align: 'center'
        },
      ],
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'routerLink':
          this.$emit('dialogEvent', 'dialogClose')
          this.$router.push({
            path: BILL_PATH[row.sourceBillType],
            query: {
              billId: row.sourceBillId,
              type: "Update",
            },
          });
          break
        default:
          break
      }
    },
  }
};
</script>


<style lang="scss" scoped>
::v-deep #table-page {
  height: 500px !important;
  width: 1000px;
}
.wrap {
  width: 600px;
  padding: 20px 0;
  .img {
    background: url(~@/assets/imgs/loginimgs/tips.png) no-repeat 100% 100%;
    width: 170px;
    height: 100px;
    margin: 30px auto;
  }
  .tips {
    color: #333;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
