<!-- 现烤订单制作 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />

    <!-- 新增和单独编辑对话框 -->
    <c-dialog title="生产完成" :width="1000" :showDialog.sync="dialogFormVisible">
      <template #content>
        <div style="display: flex">
          <div class="left" style="width: 600px">
            <el-table :data="produceList" border style="width: 100%; height: 500px">
              <el-table-column
                width="50"
                fixed
                label="选择"
                align="center"
                class-name="radioSelect"
                header-align="center"
                type=""
              >
                <template slot-scope="{ row, $index }">
                  <el-radio v-model="produceIndex" :label="$index">
                    {{ "" }}
                  </el-radio>
                </template>
              </el-table-column>
              <el-table-column
                prop="goodsNo"
                label="商品编码"
                width="120"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="goodsName"
                label="商品名称"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="barcode"
                label="商品条码"
                width="120"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="unitQty"
                label="待产数量"
                width="100"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="orderQty"
                label="订货数量"
                width="100"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
            </el-table>
          </div>
          <div class="right" style="width: 400px">
            <div class="workerBox">
              <div
                @click="workerClick(index)"
                v-for="(item, index) in workerList"
                :class="workerIndex == index ? 'activeLi' : ''"
                :key="index"
                class="li"
              >
                {{ item.workerName }}
              </div>
            </div>
            <div>
              <el-form :model="form" :rules="rules" ref="ruleForm">
                <el-form-item
                  label="合格数量"
                  :label-width="formLabelWidth"
                  prop="freshlyBakedFinishQty"
                >
                  <el-input
                    maxlength="20"
                    class="brandInput"
                    v-model.number="produceList[produceIndex].freshlyBakedFinishQty"
                    autocomplete="off"
                    placeholder="请输入合格数量"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="报损数量"
                  :label-width="formLabelWidth"
                  prop="freshlyBakedScrapQty"
                >
                  <el-input
                    maxlength="40"
                    class="brandInput"
                    v-model.number="produceList[produceIndex].freshlyBakedScrapQty"
                    autocomplete="off"
                    placeholder="请输入报损数量"
                  ></el-input>
                </el-form-item>
                <el-form-item label="生产数量" :label-width="formLabelWidth" prop="">
                  <span>{{
                    produceList[produceIndex].freshlyBakedFinishQty +
                    produceList[produceIndex].freshlyBakedScrapQty
                  }}</span>
                </el-form-item>
                <el-form-item
                  label="待产数量"
                  :label-width="formLabelWidth"
                  prop="unitQty"
                >
                  <span>{{ produceList[produceIndex].unitQty }}</span>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </template>
      <template #buttonList>
        <el-button @click="addBrand(true)">不再生产</el-button>
        <el-button type="primary" @click="addBrand(false)">生产完成</el-button>
      </template>
    </c-dialog>

    <!-- 订货详情 -->
    <c-dialog
      :title="options.body.freshlyBakedProduceStatus == 1 ? '订货详情' : '调出详情'"
      :width="1100"
      :showDialog.sync="openOrderGoodsDetail"
    >
      <template #content>
        <div class="content">
          <el-table
            :data="orderGoodsDetailList"
            border
            style="width: 100%; height: 500px"
          >
            <el-table-column
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              v-for="item in orderGoodsDetailColumns[
                Number(options.body.freshlyBakedProduceStatus)
              ]"
              :key="item.prop"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </div>
      </template>
      <template #buttonList>
        <el-button type="primary" @click="openOrderGoodsDetail = false">关 闭</el-button>
      </template>
    </c-dialog>

    <!-- 调至门店 -->
    <c-dialog title="调至门店" :width="1250" :showDialog.sync="openTransferShop">
      <template #content>
        <div
          class="content"
          style="display: flex; justify-content: space-between"
          v-loading="dialogLoading"
        >
          <div style="width: 780px; height: 500px">
            <el-table :data="transferList" border style="width: 780px; height: 100%">
              <el-table-column
                width="50"
                fixed
                label="选择"
                align="center"
                class-name="radioSelect"
                header-align="center"
                type=""
              >
                <template slot-scope="{ row, $index }">
                  <el-radio v-model="transferIndex" :label="$index">
                    {{ "" }}
                  </el-radio>
                </template>
              </el-table-column>
              <el-table-column
                prop="goodsNo"
                label="商品编码"
                width="120"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="goodsName"
                label="商品名称"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="barcode"
                label="商品条码"
                width="120"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="orderQty"
                label="订货数量"
                width="100"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="freshlyBakedFinishQty"
                label="合格数量"
                width="100"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="transferQty"
                label="调拨数量"
                width="100"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
            </el-table>
          </div>
          <div style="width: 420px; height: 500px">
            <el-table
              :data="
                transferList[transferIndex] && transferList[transferIndex].orderDetail
                  ? transferList[transferIndex].orderDetail
                  : []
              "
              border
              style="width: 420px; height: 100%"
            >
              <el-table-column
                prop="shopName"
                label="门店名称"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="orderQty"
                label="订货数"
                width="120"
                align="center"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="transferQty"
                label="调拨数"
                width="120"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="{ row, $index, column: columnObj }">
                  <el-input v-model="row.transferQty" size="mini" />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
      <template #buttonList>
        <el-button @click="openTransferShop = false">取消</el-button>
        <el-button type="primary" @click="handleEvent('clickTransferShopBtn')"
          >调至门店</el-button
        >
      </template>
    </c-dialog>
  </div>
</template>
<script>
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import TablePage from "@/components/tablePage";
import Dialog from "@/components/Dialog";
import { listBrand } from "@/api/freshlyBaked/base/teacher.js";
import {
  listOrder,
  freshlyProduceFinish,
  freshlyMoveToShop,
  listCompleteShopOrderGoodsQty,
  listShopOrderQty,
} from "@/api/freshlyBaked/bill/orderManage"; //现烤报损
export default {
  components: { TablePage, Dialog, CDialog },
  dicts: ["bill_status"],
  data() {
    return {
      formLabelWidth: "120px", //表单宽度
      workerList: [],
      workerIndex: 0,
      rules: {
        // freshlyBakedFinishQty: [
        //   {
        //     required: true,
        //     message: "生产数量不能为空",
        //     trigger: ["blur", "change"],
        //   },
        //   {
        //     pattern: /^(?:1000|[0-9]\d?)$/,
        //     message: "必须输入0-1000的整数",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        // freshlyBakedScrapQty: [
        //   {
        //     required: true,
        //     message: "报损数量不能为空",
        //     trigger: ["blur", "change"],
        //   },
        //   {
        //     pattern: /^(?:1000|[0-9]\d?)$/,
        //     message: "必须输入0-1000的整数",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        workerName: [
          {
            required: true,
            message: "现烤师名称不能为空",
            trigger: ["blur", "change"],
          },
          { pattern: /^.{1,40}$/, message: "请输入1 ~ 40个字符" },
        ],
      },
      form: {
        freshlyBakedFinishQty: "",
        freshlyBakedScrapQty: "",
        remark: "",
      },
      dialogFormVisible: false,
      openOrderGoodsDetail: false,
      openTransferShop: false,
      dialogLoading: false,
      dialogOptions: {
        curType: "",
        title: "选择现烤间",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      transferIndex: 0,
      options: {
        mutiSelect: true, // 多选
        rideoCheck: true,
        rideoStatus: "1",
        rideoDataList: [
          {
            label: "待生产",
            value: "1",
          },
          {
            label: "生产完成",
            value: "2",
          },
          {
            label: "已调出",
            value: "3",
          },
        ],
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        exportOption: {
          show: true,
          exportApi: "freshlyOrderExport",
          exportName: "现烤订单制作列表",
          timeout: 60 * 5 * 1000,
        },
        defaultBody: { freshlyBakedProduceStatus: 1 },
        getListApi: listOrder,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "billId",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginArrivalDate: a,
              endArrivalDate: b,
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/订单编号/门店名称/门店编号",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "订单编号" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "shopNo", label: "门店编号" },
            ],
          },
          {
            label: "到货日期",
            seniorSearch: true,
            type: "date",
            model: "",
            filter: "arrivalDate",
          },
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "freshlyBakedListRoom",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          // this.$select({ key: "freshlyBakedProduceStatus", option: { seniorSearch: true, model: "1" } }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "freshlyProduceFinish",
            label: "生产完成",
            type: "primary",
            disabled: false,
          },

          // {
          //   click: "freshlyMoveToShop",
          //   label: "调至门店",
          //   type: "primary",
          //   disabled: false,
          // },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "orderTypeName",
            label: "订货类型",
            minWidth: 100,
          },
          {
            prop: "goodsNo",
            label: "商品编码",
            minWidth: 120,
            type: "link",
            click: "routerLink",
            minWidth: 120,
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 120,
          },
          {
            prop: "barcode",
            label: "商品条码",
            minWidth: 120,
          },
          {
            prop: "unitQty",
            label: "待产数量",
            minWidth: 120,
          },
          {
            prop: "orderQty",
            label: "订货数量",
            minWidth: 120,
          },
        ],
        list: [],
      },
      produceList: [],
      produceIndex: 0,
      orderGoodsDetailList: [],
      transferList: [],
      orderGoodsDetailColumns: {
        1: [
          { prop: "billNo", label: "单据编号", width: "150" },
          { prop: "billDate", label: "单据日期", width: "120" },
          { prop: "shopName", label: "订货门店", width: "120" },
          { prop: "orderQty", label: "数量", width: "80" },
          { prop: "goodsNo", label: "商品编码", width: "120" },
          { prop: "goodsName", label: "商品名称" },
          { prop: "barcode", label: "商品条码", width: "120" },
          { prop: "unitName", label: "单位", width: "80" },
          { prop: "goodsSpec", label: "规格", width: "80" },
        ],
        3: [
          { prop: "billNo", label: "单据编号", width: "150" },
          { prop: "billDate", label: "单据日期", width: "120" },
          { prop: "shopName", label: "订货门店", width: "120" },
          { prop: "orderQty", label: "订货数", width: "80" },
          { prop: "transferQty", label: "调出数", width: "80" },
          { prop: "goodsNo", label: "商品编码", width: "120" },
          { prop: "goodsName", label: "商品名称" },
          { prop: "barcode", label: "商品条码", width: "120" },
          { prop: "unitName", label: "单位", width: "80" },
          { prop: "goodsSpec", label: "规格", width: "80" },
        ],
      },
    };
  },
  // beforeRouteEnter(to, from, next) {
  // //   next(async (vm) => {
  // //     //从首页待办业务进来
  // //     if (to.query.billStatus && from.fullPath == "/index") {
  // //       vm.options.defaultBody = { billStatus: to.query.billStatus };
  // //       vm.options.search[4].model = to.query.billStatus
  // //       vm.options.search[1].defaultValue = ''
  // //       let cloneData = cloneDeep(vm.options);
  // //       vm.options = null;
  // //       vm.options = cloneData;
  // //     }
  // //   });
  // },
  methods: {
    cancel() {
      this.dialogFormVisible = false;
    },
    workerClick(index) {
      this.workerIndex = index;
    },
    async addBrand(isEndProduce) {
      if (!this.workerList.length) {
        return this.$message.error("暂无现烤师");
      }
      try {
        if (this.dialogLoading) return;
        this.dialogLoading = true;
        let list = this.workerList[this.workerIndex];
        const selectData = this.produceList;
        const params = selectData.map((x) => ({
          billDetailId: x.billDetailId,
          freshlyBakedRoomWorkerId: list.workerId,
          roomStoreId: x.roomStoreId,
          freshlyBakedScrapQty: this.form.freshlyBakedScrapQty,
          freshlyBakedFinishQty: this.form.freshlyBakedFinishQty,
          goodsId: x.goodsId,
          unitId: x.unitId,
          orderTypeId: x.orderTypeId,
          isEndProduce,
          beginArrivalDate: this.options.body.beginArrivalDate,
          endArrivalDate: this.options.body.endArrivalDate,
        }));
        await freshlyProduceFinish(params);
        this.dialogFormVisible = false;
        this.$modal.msgSuccess(`操作成功`);
        this.$refs.tablePage.getList();
      } catch (err) {
      } finally {
        this.dialogLoading = false;
      }
    },
    async handleEvent(type, row) {
      const selectData = this.options.check;

      switch (type) {
        case "routerLink":
          try {
            this.options.loading = true;
            const { data } = await listShopOrderQty({
              freshlyBakedProduceStatus: this.options.body.freshlyBakedProduceStatus,
              beginArrivalDate: this.options.body.beginArrivalDate,
              endArrivalDate: this.options.body.endArrivalDate,
              goodsId: row.goodsId,
              unitId: row.unitId,
              orderTypeId: row.orderTypeId,
            });
            this.orderGoodsDetailList = data || [];
            this.openOrderGoodsDetail = true;
          } catch (err) {
          } finally {
            this.options.loading = false;
          }
          break;
        case "rideo":
          {
            // this.options.rideoStatus =
            if (this.options.rideoStatus == 1) {
              this.options.buttons = [
                {
                  click: "freshlyProduceFinish",
                  label: "生产完成",
                  type: "primary",
                  disabled: false,
                },
                {
                  click: "refresh",
                  right: true,
                  label: "刷新",
                  icon: "el-icon-refresh",
                  type: "",
                },
              ];
              this.options.defaultBody = {
                freshlyBakedProduceStatus: "1",
              };
              this.options.columns = [
                {
                  prop: "orderTypeName",
                  label: "订货类型",
                  minWidth: 100,
                },
                {
                  prop: "goodsNo",
                  label: "商品编码",
                  minWidth: 120,
                  type: "link",
                  click: "routerLink",
                },
                {
                  prop: "goodsName",
                  label: "商品名称",
                  minWidth: 120,
                },
                {
                  prop: "barcode",
                  label: "商品条码",
                  minWidth: 120,
                },
                {
                  prop: "unitQty",
                  label: "待产数量",
                  minWidth: 120,
                },
                {
                  prop: "orderQty",
                  label: "订货数量",
                  minWidth: 120,
                },
              ];
            } else if (this.options.rideoStatus == 2) {
              this.options.buttons = [
                {
                  click: "freshlyMoveToShop",
                  label: "调至门店",
                  type: "primary",
                  disabled: false,
                },
                {
                  click: "refresh",
                  right: true,
                  label: "刷新",
                  icon: "el-icon-refresh",
                  type: "",
                },
              ];
              this.options.defaultBody = {
                freshlyBakedProduceStatus: "2",
              };
              this.options.columns = [
                {
                  prop: "orderTypeName",
                  label: "订货类型",
                  minWidth: 100,
                },
                {
                  prop: "goodsNo",
                  label: "商品编码",
                  minWidth: 120,
                },
                {
                  prop: "goodsName",
                  label: "商品名称",
                  minWidth: 120,
                },
                {
                  prop: "barcode",
                  label: "商品条码",
                  minWidth: 120,
                },
                {
                  prop: "freshlyBakedFinishQty",
                  label: "合格数量",
                  minWidth: 120,
                },
                {
                  prop: "orderQty",
                  label: "订货数量",
                  minWidth: 120,
                },
                {
                  prop: "freshlyBakedRoomWorkerName",
                  label: "现烤师",
                  minWidth: 120,
                },
                {
                  prop: "billDate",
                  label: "完成时间",
                  minWidth: 120,
                },
              ];
            } else {
              this.options.buttons = [
                {
                  click: "refresh",
                  right: true,
                  label: "刷新",
                  icon: "el-icon-refresh",
                  type: "",
                },
              ];
              this.options.defaultBody = {
                freshlyBakedProduceStatus: "3",
              };
              this.options.columns = [
                {
                  prop: "goodsNo",
                  label: "商品编码",
                  minWidth: 120,
                  type: "link",
                  click: "routerLink",
                },
                {
                  prop: "goodsName",
                  label: "商品名称",
                  minWidth: 120,
                },
                {
                  prop: "barcode",
                  label: "商品条码",
                  minWidth: 120,
                },
                {
                  prop: "transferQty",
                  label: "调出数量",
                  minWidth: 120,
                },
                {
                  prop: "orderQty",
                  label: "订货数量",
                  minWidth: 120,
                },
              ];
            }
            this.$refs.tablePage.getList();
          }
          break;
        case "freshlyMoveToShop":
          try {
            if (!selectData.length) {
              return that.$message.error("请选择生产完成单据");
            }
            const billDetailIds = selectData.map((x) => x.billDetailId);
            const res = await listCompleteShopOrderGoodsQty({
              freshlyBakedProduceStatus: this.options.body.freshlyBakedProduceStatus,
              beginArrivalDate: this.options.body.beginArrivalDate,
              endArrivalDate: this.options.body.endArrivalDate,
              billDetailIds,
            });
            this.openTransferShop = true;
            this.transferList =
              res?.data.map((item) => ({
                ...item,
                orderDetail:
                  item.orderDetail?.map((item2) => ({
                    ...item2,
                    transferQty: item2.orderQty,
                  })) || [],
              })) || [];
          } catch (err) {}
          // {
          //   // setTimeout(() => {
          //   //       this.$refs.tablePage.getList();
          //   //     }, 500);
          //   //     // return
          //   const arrData = selectData.map((x) => ({
          //     produceBillDetailId: x.produceBillDetailId,
          //     orderStoreId: x.orderStoreId,
          //     roomStoreId: x.roomStoreId,
          //   }));
          //   const that = this;
          //   // let list = selectData.filter((item) => item.freshlyBakedProduceStatus == "2");
          //   let list = selectData;
          //   if (!list.length) {
          //     return that.$message.error("请选择生产完成单据");
          //   }
          //   // freshlyMoveToShop(arrData).then(() => {
          //   //   that.$nextTick(() => {
          //   //     that.options.check = [];
          //   //   });
          //   //   that.$modal.msgSuccess(`操作成功`);
          //   //   setTimeout(() => {
          //   //     that.$refs.tablePage.getList();
          //   //   }, 500);
          //   // });
          // }
          break;
        case "clickTransferShopBtn": // 调至门店
          // 校验调拨数
          try {
            if (this.dialogLoading) return;
            this.dialogLoading = true;
            // 校验调拨数量
            const index = this.transferList.findIndex((item) => {
              const orderQty = item.orderDetail.reduce(
                (qty, j) => Number(qty) + Number(j.transferQty),
                0
              );
              return orderQty > item.freshlyBakedFinishQty;
            });
            if (index !== -1) {
              this.dialogLoading = false;
              return this.$message.error(
                `${this.transferList[index].goodsName}的调拨数大于合格数`
              );
            }
            await freshlyMoveToShop(this.transferList);
            this.$message.success("调至门店成功");
            this.openTransferShop = false;
            this.transferIndex = 0;
            this.$refs.tablePage.getList();
          } catch (err) {
          } finally {
            this.dialogLoading = false;
          }
          break;
        case "freshlyProduceFinish":
          {
            let list = selectData.filter((item) => item.freshlyBakedProduceStatus == "1");
            const freshlyBakedRoomIds = list.map((i) => i.roomId);
            if (!list.length) {
              return this.$message.error("请选择待生产单据");
            }
            listBrand({
              pageNum: 1,
              pageSize: 100,
              freshlyBakedRoomIds: freshlyBakedRoomIds,
              delFlag: 0,
            }).then((res) => {
              this.workerList = res.rows;
              this.workerIndex = 0;
              this.form.freshlyBakedScrapQty = 0;
              this.form.freshlyBakedFinishQty = list[0].unitQty;
              this.dialogFormVisible = true;
            });
            this.produceIndex = 0;
            this.produceList =
              selectData?.map((x) => ({
                ...x,
                freshlyBakedFinishQty: x.unitQty,
                freshlyBakedScrapQty: 0,
              })) || {};

            // this.dialogOptions = {
            //   curType: 'roomId',
            //   title: '选择现烤师',
            //   width: 1250,
            //   show: true,
            //   type: 'TreeAndTable',
            //   // formData: this.$dialog({ key: 'freshlyListOrder' })
            //   formData: this.$dialog({ key: 'freshlyListOrder', option:{ queryParams: { delFlag: 0 }, isCheckList: true}})
            // }
            // this.dialogOptions.formData.table.mutiSelect = false
            // this.dialogOptions.formData.table.radioSelect = true
          }
          break;
        case "dialogChange":
          {
            let list = this.dialogOptions.formData.table.check;
            const params = selectData.map((x) => ({
              billDetailId: x.billDetailId,
              freshlyBakedRoomWorkerId: list[0].workerId,
              // orderStoreId: x.orderStoreId,
              roomStoreId: x.roomStoreId,
            }));
            freshlyProduceFinish(params).then(() => {
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$modal.msgSuccess(`操作成功`);
              setTimeout(() => {
                this.$refs.tablePage.getList();
              }, 500);
            });
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.workerBox {
  overflow: hidden;
  height: 200px;
  display: flex;
  justify-content: center;
  .li {
    width: 66px;
    height: 30px;
    border-radius: 10px;
    float: left;
    margin-bottom: 10px;
    margin: 0 5px;
    background: #0370ff96;
    line-height: 30px;
    font-size: 12px;
    text-align: center;
    color: #fff;
  }
  .activeLi {
    color: #fff;
    background: #0370ff;
  }
}
::-webkit-scrollbar {
  width: 4px; /* 设置滚动条的宽度 */
}

/* 定制滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 设置轨道的背景颜色 */
}

/* 定制滚动条的滑块（thumb） */
::-webkit-scrollbar-thumb {
  background: #888; /* 设置滑块的背景颜色 */
}

/* 当滑块悬停或活动时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #ccc; /* 设置滑块在悬停状态下的背景颜色 */
}
::v-deep .el-input--mini .el-input__inner {
  height: 22px !important;
}
</style>
