import request from '@/utils/request'

// 门店收款明细分析
/* export function payDetailAPI(query) {
  return request({
    url: "/api/system/shop/report/pay/analysis/listShopPayDetail",
    method: "get",
    params: query,
  });
} */
//改版
// 门店收款明细分析
export function paymentDetailsList(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/paymentDetailsList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 门店收款明细分析  合计行
export function paymentDetailsListSummary(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/paymentDetailsListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询(门店+收款方式+收银员)
export function shopPaymentShopCashierList(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/shopPaymentShopCashierList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询(门店+收款方式+收银员)  合计行
export function shopPaymentShopCashierListSummary(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/shopPaymentShopCashierListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询(门店+收款方式)
export function shopPaymentShopList(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/shopPaymentShopList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询(门店+收款方式)  合计行
export function shopPaymentShopListSummary(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/shopPaymentShopListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询(门店)
export function shopPaymentList(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/shopPaymentList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询(门店)  合计行
export function shopPaymentListSummary(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/shopPaymentListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询(收款方式)
export function paymentList(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/paymentList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询(收款方式)  合计行
export function paymentListSummary(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/paymentListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询(收款方式+收银员)
export function shopPaymentCashierList(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/shopPaymentCashierList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询(收款方式+收银员)  合计行
export function shopPaymentCashierListSummary(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/shopPaymentCashierListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询(日期)
export function shopPaymentDateList(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/shopPaymentDateList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询(日期)  合计行
export function shopPaymentDateListSummary(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/shopPaymentDateListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询(日期+门店+收款方式)
export function dateShopPaymentShopList(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/dateShopPaymentShopList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询(日期+门店+收款方式)  合计行
export function dateShopPaymentShopListSummary(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/dateShopPaymentShopListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询()  查询充值订单详情
export function paymentDetailsFill(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/paymentDetailsFill',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款商品明细分析
export function paymentGoodsDetailsList(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/paymentGoodsDetailsList',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款商品明细分析  合计行
export function paymentGoodsDetailsListSummary(params) {
  return request({
    url: '/api/system/shop/report/pay/analysis/paymentGoodsDetailsListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}