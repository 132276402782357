import request from "@/utils/request";

//会员充值查询明细
export function vipFillListAPI(params) {
    return request({
      url: '/api/system/vip/report/Fill/query/listFillDetail',
      method: 'get',
      params
    })
}

//会员充值查询明细(合计汇总)
export function vipFillListSummaryAPI(params) {
  return request({
    url: '/api/system/vip/report/Fill/query/listFillDetailSummary',
    method: 'get',
    params
  })
}

//会员充值查询明细按收款方式
export function vipFillPayListAPI(params) {
  return request({
    url: 'api/system/vip/report/Fill/query/listFillVipPayDetail',
    method: 'get',
    params
  })
}

//会员充值查询明细按收款方式(合计汇总)
export function vipFillPayListSummaryAPI(params) {
return request({
  url: 'api/system/vip/report/Fill/query/listFillVipPayDetailSummary',
  method: 'get',
  params
})
}
  

//收银员查询明细
export function cashierListAPI(params) {
    return request({
      url: '/api/system/vip/report/Fill/query/listCashier',
      method: 'get',
      params
    })
}
  

//收银员查询明细(合计汇总)
export function cashierListSummaryAPI(params) {
  return request({
    url: '/api/system/vip/report/Fill/query/listCashierSummary',
    method: 'get',
    params
  })
}


