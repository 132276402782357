var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (
    _vm.column.fieldCaption ? _vm.column.fieldIsDisplay : _vm.column.label
  )
    ? _c(
        "el-table-column",
        {
          attrs: {
            label: _vm.column.label,
            width: _vm.column.width,
            "min-width": _vm.column.minWidth,
            fixed: _vm.column.fixed,
            prop: _vm.column.prop,
            align: _vm.column.align || "center",
            "show-overflow-tooltip":
              !["html", "slot2", "slots", "tag"].includes(_vm.column.type) &&
              !_vm.column.hideTooltip,
            sortable: _vm.column.sortable,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  var columnObj = ref.column
                  return !_vm.column.children
                    ? [
                        _vm.column.type === "slots"
                          ? _vm._t(_vm.column.prop)
                          : [
                              _vm.column.type === "slot2"
                                ? _vm._t("slot-" + _vm.column.prop, null, {
                                    row: row,
                                    $index: $index,
                                    column: columnObj,
                                  })
                                : _vm.column.link || _vm.column.type === "link"
                                ? _c(
                                    "el-link",
                                    {
                                      staticClass: "sys-link-a",
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onClick(
                                            _vm.column.click,
                                            row
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.column.formatter
                                            ? _vm.column.formatter(
                                                row[_vm.column.prop],
                                                row,
                                                columnObj
                                              )
                                            : row[_vm.column.prop]
                                        )
                                      ),
                                    ]
                                  )
                                : _vm.column.type === "html"
                                ? _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      [
                                        _vm.column.html
                                          ? _c("div", {
                                              staticClass: "set-popper",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.column.html(row)
                                                ),
                                              },
                                            })
                                          : _c("div", {
                                              staticClass: "set-popper",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  row[_vm.column.prop]
                                                ),
                                              },
                                            }),
                                      ],
                                      _c("div", {
                                        staticClass: "set-content",
                                        attrs: { slot: "reference" },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            row[_vm.column.prop]
                                          ),
                                        },
                                        slot: "reference",
                                      }),
                                    ],
                                    2
                                  )
                                : _vm.column.type === "dict"
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.column.formatter
                                            ? _vm.column.formatter(
                                                row[_vm.column.prop],
                                                row,
                                                columnObj
                                              )
                                            : _vm.getDictLabel(_vm.column, row)
                                                .label
                                        ) +
                                        " "
                                    ),
                                  ]
                                : _vm.column.type === "dicts"
                                ? [
                                    !_vm.column.tag
                                      ? _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getDictsLabel(_vm.column, row)
                                            )
                                          ),
                                        ])
                                      : _c(
                                          "el-tag",
                                          { attrs: { type: _vm.column.tag } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getDictsLabel(
                                                  _vm.column,
                                                  row
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                  ]
                                : _vm.column.type === "image"
                                ? [
                                    row[_vm.column.prop]
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "50px",
                                            "object-fit": "cover",
                                            height: "50px",
                                          },
                                          attrs: {
                                            src: _vm.column.formatter
                                              ? _vm.column.formatter(
                                                  row[_vm.column.prop],
                                                  row,
                                                  columnObj
                                                )
                                              : row[_vm.column.prop],
                                            alt: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              _vm.showBigImage(
                                                _vm.column.formatter
                                                  ? _vm.column.formatter(
                                                      row[_vm.column.prop],
                                                      row,
                                                      columnObj
                                                    )
                                                  : row[_vm.column.prop]
                                              )
                                            },
                                          },
                                        })
                                      : _c("div", {
                                          staticStyle: {
                                            width: "50px",
                                            height: "50px",
                                            border: "1px solid #eeeeee",
                                            margin: "auto",
                                          },
                                        }),
                                  ]
                                : _vm.column.type === "tag"
                                ? _c(
                                    "div",
                                    [
                                      row[_vm.column.tagKey]
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              row[_vm.column.tagKey],
                                              function (tag, i) {
                                                return _c(
                                                  "el-tag",
                                                  {
                                                    key: tag + i,
                                                    staticStyle: {
                                                      margin: "3px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.column.tagLabel &&
                                                          tag
                                                          ? tag[
                                                              _vm.column
                                                                .tagLabel
                                                            ]
                                                          : tag || ""
                                                      )
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm.column.tagDefault
                                        ? _c(
                                            "el-tag",
                                            { staticStyle: { margin: "3px" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.column.tagDefault || ""
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm.column.type === "button"
                                ? _c(
                                    "div",
                                    _vm._l(_vm.column.button, function (item) {
                                      return _c(
                                        "el-button",
                                        {
                                          key: item.click,
                                          attrs: {
                                            size: item.size || "mini",
                                            type: item.type || "text",
                                            plain: item.plain,
                                            round: item.round,
                                            circle: item.circle,
                                            loading: item.loading,
                                            disabled: item.disabled,
                                            icon: item.icon,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.onClick(
                                                item.click,
                                                row,
                                                $index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.text) + " ")]
                                      )
                                    }),
                                    1
                                  )
                                : _vm.column.type === "icons"
                                ? _c("div", [
                                    _c("i", {
                                      class: _vm.column.formatter
                                        ? _vm.column.formatter(
                                            row[_vm.column.prop],
                                            row,
                                            columnObj
                                          ).icon
                                        : _vm.column.elIcon,
                                      style: _vm.column.formatter
                                        ? _vm.column.formatter(
                                            row[_vm.column.prop],
                                            row,
                                            columnObj
                                          ).style
                                        : _vm.column.elStyle,
                                    }),
                                  ])
                                : _vm.column.type === "selectTime"
                                ? _c(
                                    "div",
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "140px" },
                                        attrs: {
                                          type: "date",
                                          size: "mini",
                                          "value-format": "yyyy-MM-dd",
                                          disabled: _vm.column.disabled,
                                          placeholder: "选择日期",
                                        },
                                        model: {
                                          value: row[_vm.column.prop],
                                          callback: function ($$v) {
                                            _vm.$set(row, _vm.column.prop, $$v)
                                          },
                                          expression: "row[column.prop]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.column.activeText
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.column.activeText(row)) +
                                        " "
                                    ),
                                  ]
                                : _vm.column.type === "switch"
                                ? _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-value": _vm.column.active,
                                          "inactive-value": _vm.column.inactive,
                                          "active-text": _vm.column.activeLabel,
                                          "inactive-text":
                                            _vm.column.inactiveLabel,
                                          disabled:
                                            typeof _vm.column.disabled ===
                                            "function"
                                              ? _vm.column.disabled(
                                                  row[_vm.column.prop],
                                                  row
                                                )
                                              : _vm.column.disabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return true($event)
                                          },
                                          change: function ($event) {
                                            typeof _vm.column.onClick ===
                                            "function"
                                              ? _vm.column.onClick(
                                                  _vm.column.type,
                                                  row
                                                )
                                              : _vm.onClick(
                                                  _vm.column.type,
                                                  row
                                                )
                                          },
                                        },
                                        model: {
                                          value: row[_vm.column.prop],
                                          callback: function ($$v) {
                                            _vm.$set(row, _vm.column.prop, $$v)
                                          },
                                          expression: "row[column.prop]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.column.type === "my-select-local"
                                ? _c("SelectLocal", {
                                    attrs: {
                                      option:
                                        typeof _vm.column.option === "function"
                                          ? _vm.column.option(row)
                                          : _vm.column.option,
                                      filterable: _vm.column.option.filterable,
                                      multiple: _vm.column.option.multiple,
                                      disabled: _vm.column.disabled,
                                      clearable: _vm.column.option.clearable,
                                      "collapse-tags":
                                        _vm.column.option["collapse-tags"],
                                      placeholder:
                                        _vm.column.placeholder ||
                                        "选择" + _vm.column.label,
                                    },
                                    on: {
                                      selectChange: function () {
                                        return typeof _vm.column.rowChange ===
                                          "function"
                                          ? _vm.column.rowChange(row)
                                          : undefined
                                      },
                                    },
                                    model: {
                                      value: row[_vm.column.prop],
                                      callback: function ($$v) {
                                        _vm.$set(row, _vm.column.prop, $$v)
                                      },
                                      expression: "row[column.prop]",
                                    },
                                  })
                                : _vm.column.type === "my-checkbox"
                                ? [
                                    !row.children || !row.children.length
                                      ? _c("el-checkbox", {
                                          attrs: {
                                            "true-label":
                                              _vm.column.trueLabel || 1,
                                            "false-label":
                                              _vm.column.falseLabel || 0,
                                          },
                                          model: {
                                            value: row[_vm.column.prop],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                _vm.column.prop,
                                                $$v
                                              )
                                            },
                                            expression: "row[column.prop]",
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                : _vm.column.type === "my-input" &&
                                  (_vm.column.print ? !row.children : true)
                                ? _c("el-input", {
                                    attrs: {
                                      type: _vm.column.inputType
                                        ? _vm.column.inputType
                                        : "text",
                                      disabled:
                                        typeof _vm.column.disabled ===
                                        "function"
                                          ? _vm.column.disabled(
                                              row[_vm.column.prop],
                                              row
                                            )
                                          : _vm.column.disabled,
                                      maxlength: _vm.column.maxlength,
                                      size: "mini",
                                    },
                                    model: {
                                      value: row[_vm.column.prop],
                                      callback: function ($$v) {
                                        _vm.$set(row, _vm.column.prop, $$v)
                                      },
                                      expression: "row[column.prop]",
                                    },
                                  })
                                : _vm.column.type == "input"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: _vm.column.rules
                                          ? "list." +
                                            $index +
                                            "." +
                                            _vm.column.prop
                                          : "",
                                        rules: {
                                          required: true,
                                          message:
                                            _vm.column.label + "不能为空",
                                          trigger: ["change", "blur"],
                                        },
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          size: "mini",
                                          disabled: _vm.column.disabled,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.onInput(
                                              _vm.column.input,
                                              row,
                                              $index,
                                              _vm.column.prop,
                                              _vm.column.isMinus,
                                              _vm.column.inputNum,
                                              _vm.column.notVerify,
                                              _vm.column.maxNum
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.onBlur(
                                              _vm.column.blurInput,
                                              row,
                                              $index
                                            )
                                          },
                                        },
                                        model: {
                                          value: row[_vm.column.prop],
                                          callback: function ($$v) {
                                            _vm.$set(row, _vm.column.prop, $$v)
                                          },
                                          expression: "row[column.prop]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.column.type == "clickInput"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: _vm.column.rules
                                          ? "list." +
                                            $index +
                                            "." +
                                            _vm.column.prop
                                          : "",
                                        rules: {
                                          required: true,
                                          message:
                                            _vm.column.label + "不能为空",
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            size: "mini",
                                            disabled: _vm.column.disabled,
                                          },
                                          model: {
                                            value: row[_vm.column.prop],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                row,
                                                _vm.column.prop,
                                                $$v
                                              )
                                            },
                                            expression: "row[column.prop]",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-more pointer",
                                            attrs: { slot: "suffix" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.onClick(
                                                  _vm.column.click,
                                                  row,
                                                  $index,
                                                  _vm.column.prop
                                                )
                                              },
                                            },
                                            slot: "suffix",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.column.type === "localSelect"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: _vm.column.rules
                                          ? "list." +
                                            $index +
                                            "." +
                                            _vm.column.prop
                                          : "",
                                        rules: {
                                          required: true,
                                          message:
                                            _vm.column.label + "不能为空",
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c("SelectLocal", {
                                        attrs: {
                                          option: _vm.computedOptionLocalSelect(
                                            _vm.column,
                                            $index,
                                            row
                                          ),
                                          rowDataKey: _vm.column.rowDataKey,
                                          row: row,
                                          filterable:
                                            _vm.column.option.filterable ||
                                            false,
                                          multiple:
                                            _vm.column.option.multiple || false,
                                          disabled:
                                            typeof _vm.column.disabled ===
                                            "function"
                                              ? _vm.column.disabled(
                                                  row[_vm.column.prop],
                                                  row
                                                )
                                              : _vm.column.disabled,
                                          clearable:
                                            _vm.column.option.clearable ||
                                            false,
                                          "collapse-tags":
                                            _vm.column.option["collapse-tags"],
                                          placeholder:
                                            _vm.column.placeholder ||
                                            "选择" + _vm.column.label,
                                        },
                                        model: {
                                          value: row[_vm.column.prop],
                                          callback: function ($$v) {
                                            _vm.$set(row, _vm.column.prop, $$v)
                                          },
                                          expression: "row[column.prop]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.column.type === "remoteSelect"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: _vm.column.rules
                                          ? "list." +
                                            $index +
                                            "." +
                                            _vm.column.prop
                                          : "",
                                        rules: {
                                          required: true,
                                          message:
                                            _vm.column.label + "不能为空",
                                          trigger: ["blur", "change"],
                                        },
                                      },
                                    },
                                    [
                                      _c("SelectRemote", {
                                        attrs: {
                                          option:
                                            _vm.computedOptionRemoteSelect(
                                              _vm.column,
                                              $index,
                                              row
                                            ),
                                          filterable:
                                            _vm.column.option.filterable ||
                                            false,
                                          multiple:
                                            _vm.column.option.multiple || false,
                                          clearable:
                                            _vm.column.option.clearable ||
                                            false,
                                          "collapse-tags":
                                            _vm.column.option["collapse-tags"],
                                          placeholder:
                                            _vm.column.placeholder ||
                                            "选择" + _vm.column.label,
                                          disabled:
                                            typeof _vm.column.disabled ===
                                            "function"
                                              ? _vm.column.disabled(
                                                  row[_vm.column.prop],
                                                  row
                                                )
                                              : _vm.column.disabled,
                                        },
                                        on: {
                                          selectChange: function (
                                            newVal,
                                            remRow
                                          ) {
                                            _vm.$emit(
                                              "handleEvent",
                                              "remoteChange",
                                              Object.assign({}, remRow, {
                                                prop: _vm.column.prop,
                                              }),
                                              $index
                                            )
                                          },
                                          handleEvent: function (type, option) {
                                            _vm.$emit(
                                              "handleEvent",
                                              type,
                                              option
                                            )
                                          },
                                        },
                                        model: {
                                          value: row[_vm.column.prop],
                                          callback: function ($$v) {
                                            _vm.$set(row, _vm.column.prop, $$v)
                                          },
                                          expression: "row[column.prop]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : [
                                    _c(
                                      "span",
                                      {
                                        style: _vm.column.style
                                          ? _vm.column.style(
                                              row[_vm.column.prop],
                                              row,
                                              columnObj
                                            )
                                          : "",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.column.formatter
                                              ? _vm.column.formatter(
                                                  row[_vm.column.prop],
                                                  row,
                                                  columnObj
                                                )
                                              : row[_vm.column.prop]
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                            ],
                      ]
                    : undefined
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _vm.column.children
            ? _vm._l(
                _vm.getChildren(_vm.column.children),
                function (item, index) {
                  return _c(
                    "el-table-column",
                    {
                      key: _vm.column.prop + "." + item.prop + "." + index,
                      attrs: {
                        label: item.label,
                        prop: item.prop,
                        width: item.width,
                        "min-width": item.minWidth,
                        align: item.align || "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(_vm._s(_vm.getValue(row, item)))]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm.getColumnsType == 2 &&
                      _vm.getChildren2(item, 1).length > 0
                        ? _vm._l(_vm.getChildren2(item, 2), function (item2) {
                            return _c("el-table-column", {
                              key: item2,
                              attrs: {
                                prop: _vm.prop2Computed(item, item2),
                                label: item2.payModeName,
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getValue2(row, item, item2)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          })
                        : _vm._e(),
                    ],
                    2
                  )
                }
              )
            : _vm._e(),
          !_vm.column.children && _vm.column.rules
            ? _c("template", { slot: "header" }, [
                _c("i", { staticStyle: { color: "#ff4949" } }, [_vm._v("*")]),
                _vm._v(" " + _vm._s(_vm.column.label) + " "),
              ])
            : _vm._e(),
          !_vm.column.children && _vm.column.tooltip
            ? _c("template", { slot: "header" }, [
                _c(
                  "div",
                  { staticClass: "header-container" },
                  [
                    _vm._v(" " + _vm._s(_vm.column.label) + " "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "tooltip",
                        attrs: {
                          effect: "dark",
                          content: _vm.column.tooltip,
                          placement: "top",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-question",
                          staticStyle: { "font-size": "14px" },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.column.type === "slot2" ? _vm._t("default") : _vm._e(),
          _c("c-dialog", {
            attrs: {
              title: "图片预览",
              width: 500,
              showDialog: _vm.dialogImageVisible,
            },
            on: {
              "update:showDialog": function ($event) {
                _vm.dialogImageVisible = $event
              },
              "update:show-dialog": function ($event) {
                _vm.dialogImageVisible = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "x-fc" }, [
                        _c("img", {
                          staticClass: "image",
                          attrs: { src: _vm.dialogImageUrl, alt: "" },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2786995866
            ),
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }