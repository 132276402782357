import request from '@/utils/request'

// 商城资料分页列表
export function popupCaseList(params) {
  return request({
    url: '/api/system/mall/marketing/popup/case/list',
    method: 'get',
    params
  })
}
// 审核
export function popupCaseAudit(data) {
  return request({
    url: '/api/system/mall/marketing/popup/case/audit',
    method: 'put',
    data
  })
}
// 复制
export function popupCaseCopyActivity(data) {
  return request({
    url: '/api/system/mall/marketing/popup/case/copyActivity',
    method: 'post',
    data
  })
}
// 启用/禁用
export function popupCaseUpdateIsEnable(data) {
  return request({
    url: '/api/system/mall/marketing/popup/case/updateIsStop',
    method: 'put',
    data
  })
}
// 删除
export function popupCaseUpdateDelete(data) {
  return request({
    url: '/api/system/mall/marketing/popup/case/delete',
    method: 'delete',
    data
  })
}
// 新增
export function popupCaseUpdateGroupSave(data) {
  return request({
    url: '/api/system/mall/marketing/popup/case/save',
    method: 'post',
    data
  })
}
// 详情
export function popupCaseGetDetail(billId) {
  return request({
    url: '/api/system/mall/marketing/popup/case/getDetail?billId=' + billId,
    method: 'get',
  })
}
// 修改
export function popupCaseUpdate(data) {
  return request({
    url: '/api/system/mall/marketing/popup/case/update',
    method: 'put',
    data
  })
}