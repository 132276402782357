<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  timeAPI,
  shopAPI,
  cashierAPI,
  timeAPISummary,
  cashierAPISummary,
} from "@/api/shop/analyse/posCashCheck"; ///收款对账明细
export default {
  name: "SaleTotal",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        isRestTablecolumns: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "门店收银对账",
        rowKey: "shopId",
        search: [
          {
            label: "收款时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "cashierNos", label: "收银员编号" },
              { filter: "cashierNames", label: "收银员名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "shop",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCashier",
            option: {
              filter: "cashierIds",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "cashier",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收银员",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "cashier" }),
                    },
                  },
                ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            hide: () => this.options?.tabColumnType != "detail",
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "detail",
        summaryNoVerificationArr: ['receivedPayModes','notPickedUpPayModes3','notPickedUpPayModes5','pickedUpPayModes3','pickedUpPayModes5'],
        tabsColumns: [
          {
            title: "收款对账日期汇总查询",
            type: "detail",
            getSummaryApi: timeAPISummary,
            getListApi: timeAPI,
            uuid: "3d22beaf-aaf5-2ff9-1c73-a5d9c25a052c",
            // defaultBody: { isSummary: false },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/pay/reconciliation/listDateExport",
              exportName: "收款对账日期汇总查询",
            },
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 150,
              },
              {
                prop: "payTypes",
                align: "center",
                label: "",
                getColumnsType: 2,
                getColumns: (list) => {
                  function columsChilKey(key, id) {
                    const mapping = {
                      '2': { '12已收订金': 'receivedPayModes' },
                      '3': { '000未取货': 'notPickedUpPayModes3', '000已取货': 'pickedUpPayModes3' },
                      '5': { '000未取货': 'notPickedUpPayModes5', '000已取货': 'pickedUpPayModes5' }
                    };
                    return mapping[key]?.[id] || null;
                  }
                  const oneRow = list?.[0];
                  let colums = [
                    { label: "总部充值", key: 0 },
                    { label: "门店充值", key: 1 },
                    { label: "门店销售", key: 2 },
                    { label: "门店预订", key: 3 },
                    { label: "线上充值", key: 4 },
                    { label: "自营商城", key: 5 },
                    { label: "美团", key: 6 },
                    { label: "饿了么", key: 7 },
                    { label: "有赞", key: 8 },
                  ]
                    .filter((x) => oneRow.payTypeIds?.includes(x.key))
                    .map((item) => ({
                      ...item,
                      align: "center",
                      prop: `payTypes${item.key}`,
                      children: () => {
                        const showitem = list?.find((x) => Boolean(x.payData[item.key]));
                        return (
                          showitem?.payData?.[item.key]?.payTypes?.map?.((x, index) => ({
                            ...x,
                            label: x.payModeName,
                            prop: `payData_${item.key}_payDetailRespWithDate.${x.payModeId}.payModeId.payMoney`,
                            prop2: showitem?.shopReconclilationPaySubsetPayTypes?.[columsChilKey(item.key, x.payModeId)]?.length > 0 ? columsChilKey(item.key, x.payModeId) : null,
                            minWidth: 110,
                            summary: true,
                            // shopReconciliationPayDetailResps: showitem?.payData?.[item.key]?.payDetailRespWithDate.filter(k => k.payModeId == x.payModeId && k.shopReconciliationPayDetailResps && k.shopReconciliationPayDetailResps.length > 0)?.[0]?.shopReconciliationPayDetailResps,
                            shopReconciliationPayDetailResps: showitem?.shopReconclilationPaySubsetPayTypes?.[columsChilKey(item.key, x.payModeId)]?.length > 0 ? showitem?.shopReconclilationPaySubsetPayTypes?.[columsChilKey(item.key, x.payModeId)] : null,
                            getValue: (row, prop) =>
                              row?.payData?.[item.key]?.payDetailRespWithDate?.find(
                                (y) => y.payModeId === x.payModeId
                              )?.payMoney || 0,
                            getValue2: (row, item2) => {
                              return row?.payData?.[item.key]?.payDetailRespWithDate?.find(
                                (y) => y.payModeId === x.payModeId
                              )?.shopReconciliationPayDetailResps?.find(l => l.payModeId == item2.payModeId)?.payMoney || 0
                            },
                          })) || []
                        );
                      },
                    }));
                  return colums;
                },
              },
              {
                prop: "summaryNum",
                label: "汇总",
                minWidth: 150,
                getColumns: (list) => {
                  const oneRow = list?.[0]?.summaryPayTypes;
                  let colums = [
                    { label: "汇总", key: 0 },
                  ].map((item) => ({
                    ...item,
                    align: "center",
                    prop: `summaryDetails${item.payModeId}`,
                    children: () => {
                      return (
                        oneRow?.map?.(x => ({
                          ...x,
                          label: x.payModeName,
                          prop: `summaryDetails.${x.payModeId}.payModeId.payMoney`,
                          minWidth: 110,
                          summary: true,
                          getValue: (row, prop) =>
                            row.summaryDetails?.find(
                              (y) => y.payModeId === x.payModeId
                            )?.payMoney || 0,
                        })) || []
                      );
                    },
                  }));
                  return colums;
                },
              },
            ],
            // summary: ["summaryNum"],
          },
          {
            title: "收款对账门店汇总查询",
            type: "shop",
            getSummaryApi: timeAPISummary,
            getListApi: shopAPI,
            uuid: "af9a2179-e868-561a-4b7c-30c4e7d3ac16",
            // defaultBody: { isSummary: false },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/pay/reconciliation/listShopExport",
              exportName: "收款对账门店汇总查询",
            },
            columns: [
              {
                fixed: true,
                prop: "shopNo",
                label: "门店编码",
                minWidth: 120,
              },
              {
                fixed: true,
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "payTypes",
                align: "center",
                label: "",
                getColumnsType: 2,
                getColumns: (list) => {
                  function columsChilKey(key, id) {
                    const mapping = {
                      '2': { '12已收订金': 'receivedPayModes' },
                      '3': { '000未取货': 'notPickedUpPayModes3', '000已取货': 'pickedUpPayModes3' },
                      '5': { '000未取货': 'notPickedUpPayModes5', '000已取货': 'pickedUpPayModes5' }
                    };
                    return mapping[key]?.[id] || null;
                  }
                  const oneRow = list?.[0];
                  let colums = [
                    { label: "总部充值", key: 0 },
                    { label: "门店充值", key: 1 },
                    { label: "门店销售", key: 2 },
                    { label: "门店预订", key: 3 },
                    { label: "线上充值", key: 4 },
                    { label: "自营商城", key: 5 },
                    { label: "美团", key: 6 },
                    { label: "饿了么", key: 7 },
                    { label: "有赞", key: 8 },
                  ]
                    .filter((x) => oneRow.payTypeIds?.includes(x.key))
                    .map((item) => ({
                      ...item,
                      align: "center",
                      prop: `payTypes${item.key}`,
                      children: () => {
                        const showitem = list?.find((x) => Boolean(x.payData[item.key]));
                        return (
                          showitem?.payData?.[item.key]?.payTypes?.map?.((x, index) => ({
                            ...x,
                            label: x.payModeName,
                            prop: `payData_${item.key}_payDetailRespWithDate.${x.payModeId}.payModeId.payMoney`,
                            prop2: showitem?.shopReconclilationPaySubsetPayTypes?.[columsChilKey(item.key, x.payModeId)]?.length > 0 ? columsChilKey(item.key, x.payModeId) : null,
                            minWidth: 110,
                            summary: true,
                            shopReconciliationPayDetailResps: showitem?.shopReconclilationPaySubsetPayTypes?.[columsChilKey(item.key, x.payModeId)]?.length > 0 ? showitem?.shopReconclilationPaySubsetPayTypes?.[columsChilKey(item.key, x.payModeId)] : null,
                            getValue: (row, prop) =>
                              row?.payData?.[item.key]?.payDetailRespWithDate?.find(
                                (y) => y.payModeId === x.payModeId
                              )?.payMoney || 0,
                            getValue2: (row, item2) => {
                              return row?.payData?.[item.key]?.payDetailRespWithDate?.find(
                                (y) => y.payModeId === x.payModeId
                              )?.shopReconciliationPayDetailResps?.find(l => l.payModeId == item2.payModeId)?.payMoney || 0
                            },
                          })) || []
                        );
                      },
                    }));
                  return colums;
                },
              },
              {
                prop: "summaryNum",
                label: "汇总",
                minWidth: 150,
                getColumns: (list) => {
                  const oneRow = list?.[0]?.summaryPayTypes;
                  let colums = [
                    { label: "汇总", key: 0 },
                  ].map((item) => ({
                    ...item,
                    align: "center",
                    prop: `summaryDetails${item.payModeId}`,
                    children: () => {
                      return (
                        oneRow?.map?.(x => ({
                          ...x,
                          label: x.payModeName,
                          prop: `summaryDetails.${x.payModeId}.payModeId.payMoney`,
                          minWidth: 110,
                          summary: true,
                          getValue: (row, prop) =>
                            row.summaryDetails?.find(
                              (y) => y.payModeId === x.payModeId
                            )?.payMoney || 0,
                        })) || []
                      );
                    },
                  }));
                  return colums;
                },
              },
            ],
          },
          {
            title: "收款对账收银员汇总查询",
            type: "cashier",
            getSummaryApi: cashierAPISummary,
            getListApi: cashierAPI,
            uuid: "ec9f9b4b-9de8-b482-b936-b2340c5a9d8e",
            // defaultBody: { isSummary: false },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/pay/reconciliation/listCashierExport",
              exportName: "收款对账收银员汇总查询",
            },
            columns: [
              {
                prop: "cashierNo",
                label: "收银员编号",
                minWidth: 120,
              },
              {
                prop: "cashierName",
                label: "收银员名称",
                minWidth: 150,
              },
              {
                prop: "payTypes",
                align: "center",
                label: "",
                getColumnsType: 2,
                getColumns: (list) => {
                  function columsChilKey(key, id) {
                    const mapping = {
                      '2': { '12已收订金': 'receivedPayModes' },
                      '3': { '000未取货': 'notPickedUpPayModes3', '000已取货': 'pickedUpPayModes3' },
                      '5': { '000未取货': 'notPickedUpPayModes5', '000已取货': 'pickedUpPayModes5' }
                    };
                    return mapping[key]?.[id] || null;
                  }
                  const oneRow = list?.[0];
                  let colums = [
                    { label: "总部充值", key: 0 },
                    { label: "门店充值", key: 1 },
                    { label: "门店销售", key: 2 },
                    { label: "门店预订", key: 3 },
                    { label: "线上充值", key: 4 },
                    { label: "自营商城", key: 5 },
                    { label: "美团", key: 6 },
                    { label: "饿了么", key: 7 },
                    { label: "有赞", key: 8 },
                  ]
                    .filter((x) => oneRow.payTypeIds?.includes(x.key))
                    .map((item) => ({
                      ...item,
                      align: "center",
                      prop: `payTypes${item.key}`,
                      children: () => {
                        const showitem = list?.find((x) => Boolean(x.payData[item.key]));
                        return (
                          showitem?.payData?.[item.key]?.payTypes?.map?.((x, index) => ({
                            ...x,
                            label: x.payModeName,
                            prop: `payData_${item.key}_payDetailRespWithDate.${x.payModeId}.payModeId.payMoney`,
                            prop2: showitem?.shopReconclilationPaySubsetPayTypes?.[columsChilKey(item.key, x.payModeId)]?.length > 0 ? columsChilKey(item.key, x.payModeId) : null,
                            minWidth: 110,
                            summary: true,
                            shopReconciliationPayDetailResps: showitem?.shopReconclilationPaySubsetPayTypes?.[columsChilKey(item.key, x.payModeId)]?.length > 0 ? showitem?.shopReconclilationPaySubsetPayTypes?.[columsChilKey(item.key, x.payModeId)] : null,
                            getValue: (row, prop) =>
                              row?.payData?.[item.key]?.payDetailRespWithDate?.find(
                                (y) => y.payModeId === x.payModeId
                              )?.payMoney || 0,
                            getValue2: (row, item2) => {
                              return row?.payData?.[item.key]?.payDetailRespWithDate?.find(
                                (y) => y.payModeId === x.payModeId
                              )?.shopReconciliationPayDetailResps?.find(l => l.payModeId == item2.payModeId)?.payMoney || 0
                            },
                          })) || []
                        );
                      },
                    }));
                  return colums;
                },
              },
              {
                prop: "summaryNum",
                label: "汇总",
                minWidth: 150,
                getColumns: (list) => {
                  const oneRow = list?.[0]?.summaryPayTypes;
                  let colums = [
                    { label: "汇总", key: 0 },
                  ].map((item) => ({
                    ...item,
                    align: "center",
                    prop: `summaryDetails${item.payModeId}`,
                    children: () => {
                      return (
                        oneRow?.map?.(x => ({
                          ...x,
                          label: x.payModeName,
                          prop: `summaryDetails.${x.payModeId}.payModeId.payMoney`,
                          minWidth: 110,
                          summary: true,
                          getValue: (row, prop) =>
                            row.summaryDetails?.find(
                              (y) => y.payModeId === x.payModeId
                            )?.payMoney || 0,
                        })) || []
                      );
                    },
                  }));
                  return colums;
                },
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          if (this.options.tabColumnType === "note") {
            this.$router.push({
              name: "saleTotalDetail",
              query: {
                billNo: row.billNo,
                type: "Update",
              },
            });
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
