<template>
  <div :style="{marginTop: `${data.style.marginTop}px`, marginBottom: `${data.style.marginBottom}px`}">
    <div v-if="data.style.selectedList && data.style.selectedList.length > 0">
      <CubeMatrix2 :layout='data.style.type' v-model="data.style.selectedList"
        v-if="[1, 2, 3, 4, 5, 6, 7].includes(data.style.type)" />
      <div v-else-if="data.style.type == 8" class="decorate-cube" :style="{height:mainHeight}">
        <div class="cube-selected" v-for="(item, index) in data.style.selectedList"
          :style="{'width':(item.width/2) + 'px','height':(item.height/2) + 'px','top':item.top + 'px','left':item.left-1 + 'px'}"
          :key="index">
          <img :src="item.imageUrl" :style="{height: (item.height/2) + 'px', width: (item.width/2) + 'px'}" />
        </div>
      </div>
    </div>
    <img v-else style="width: 100%;height: auto;"
      src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png" />
  </div>
</template>

<script>
import CubeMatrix2 from "@/views/O2OMall/config/template/decoratePage/components/rightTemplate/components/seniorCubeCom.vue";
export default {
  name: 'seniorCube',
  components: { CubeMatrix2 },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
    }
  },
  computed: {
    mainHeight() {
      if (this.data.style.type == 8) {
        const yCount = this.findMaxYCount(this.data)
        const cubeCellHeight = ((750 / this.data.style.density) * yCount) / 2
        console.log('cubeCellHeight', cubeCellHeight, this.data.style.density, yCount);
        return cubeCellHeight + 'px'
      } else {
        return 0
      }
    }
  },
  methods: {
    findMaxYCount(data) {
      if (!data || !data.style || !data.style.selectedList || !Array.isArray(data.style.selectedList)) {
        return 0
      }

      let maxYCount = -Infinity;

      data.style.selectedList.forEach(item => {
        if (typeof item.end.y === 'number' && item.end.y > maxYCount) {
          maxYCount = item.end.y;
        }
      });
      return maxYCount;
    }
  }
}
</script>

<style lang='scss' scoped>
.decorate-cube {
  position: relative;
  .cube-selected {
    position: absolute;
    img {
      height: inherit;
      vertical-align: baseline;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
}
img {
  display: block;
}
</style>
