import request from '@/utils/request'

// 门店报损列表
export function listOrder (query) {
  return request({
    url: '/api/system/freshly/baked/room/order/v2/list',
    method: 'get',
    params: query
  })
}

// 将订单调至指定门店
export function freshlyMoveToShop (data) {
  return request({
    url: '/api/system/freshly/baked/room/order/v2/moveToShop',
    method: 'post',
    data
  })
}
// 将订单改为制作完成的订单
export function freshlyProduceFinish (data) {
  return request({
    url: '/api/system/freshly/baked/room/order/v2/produceFinish',
    method: 'post',
    data
  })
}


// 汇总生产完成
export function listCompleteShopOrderGoodsQty (data) {
  return request({
    url: '/api/system/freshly/baked/room/order/v2/listCompleteShopOrderGoodsQty',
    method: 'post',
    data
  })
}

// 订货详情 
export function listShopOrderQty (params) {
  return request({
    url: '/api/system/freshly/baked/room/order/v2/listShopOrderQty',
    method: 'get',
    params
  })
}