var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap x-f",
      attrs: { id: "wrap" },
    },
    [
      _c("categoryTreeCard", {
        attrs: {
          categoryTreeTitle: "商品资料",
          isGoods: true,
          isEditBtn: true,
          boxHeight: _vm.boxHeight,
          boxInternalHeight: _vm.boxInternalHeight,
          categoryTreeOptions: _vm.categoryTreeOptions,
        },
        on: {
          treeNodeId: _vm.getTreeNodeId,
          appendTree: _vm.appendTree,
          editTree: _vm.editTree,
          removeTree: _vm.removeTree,
        },
      }),
      _c(
        "div",
        { staticClass: "rightBox y-b" },
        [
          _c(
            "cardTitleCom",
            {
              staticClass: "headGoodsSearch",
              attrs: { cardTitle: "查询", id: "search-card" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "navSearch" },
                  [
                    _c("seniorSearch", {
                      attrs: {
                        getList: _vm.getList,
                        dropDownList: _vm.dropDownList,
                        isChildLevelIds: true,
                        isBillDate: false,
                        isSearchInput: true,
                        isSearchInputTitle: "输入商品编码、名称、条码查询",
                      },
                      on: { isShowHighCom: _vm.getIsShowHigh },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showHigh,
                            expression: "showHigh",
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "marT10 marB10" },
                          [
                            _c("span", { staticClass: "fS14MR10 marL15" }, [
                              _vm._v("商品类型："),
                            ]),
                            _c("SelectLocal", {
                              staticClass: "marR15",
                              staticStyle: { width: "170px" },
                              attrs: {
                                multiple: true,
                                option: {
                                  data: _vm.dict.type.goods_type,
                                  value: "value",
                                  label: "label",
                                },
                              },
                              model: {
                                value: _vm.queryParams.goodsTypes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "goodsTypes", $$v)
                                },
                                expression: "queryParams.goodsTypes",
                              },
                            }),
                            _c("span", { staticClass: "fS14MR10" }, [
                              _vm._v("商品状态："),
                            ]),
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidth marR15",
                                attrs: {
                                  size: "mini",
                                  filterable: "",
                                  clearable: "",
                                  placeholder: "请选择商品状态",
                                },
                                model: {
                                  value: _vm.queryParams.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "status", $$v)
                                  },
                                  expression: "queryParams.status",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.common_status,
                                function (dict) {
                                  return _c("el-option", {
                                    key: dict.value,
                                    attrs: {
                                      label: dict.label,
                                      value: dict.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                            _c("span", { staticClass: "fS14MR10" }, [
                              _vm._v("计价方式："),
                            ]),
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidth marR15",
                                attrs: {
                                  size: "mini",
                                  filterable: "",
                                  placeholder: "请选择计价方式",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryParams.pricingMode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "pricingMode",
                                      $$v
                                    )
                                  },
                                  expression: "queryParams.pricingMode",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.pricing_mode,
                                function (dict) {
                                  return _c("el-option", {
                                    key: dict.value,
                                    attrs: {
                                      label: dict.label,
                                      value: dict.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                            _c("span", { staticClass: "fS14MR10" }, [
                              _vm._v("是否管库存："),
                            ]),
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidth marR15",
                                attrs: {
                                  size: "mini",
                                  filterable: "",
                                  placeholder: "请选择是否管库存",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryParams.isManageStock,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams,
                                      "isManageStock",
                                      $$v
                                    )
                                  },
                                  expression: "queryParams.isManageStock",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "是", value: "1" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "否", value: "0" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("span", { staticClass: "fS14MR10" }, [
                              _vm._v("保质期天数："),
                            ]),
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                placeholder: "保质期天数",
                                maxlength: "10",
                              },
                              model: {
                                value: _vm.queryParams.qualityDays,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "qualityDays", $$v)
                                },
                                expression: "queryParams.qualityDays",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c(
                      "operatingButton",
                      {
                        attrs: {
                          getList: _vm.handleEvent,
                          isRecycleBtn: true,
                          isDeleteBtn: false,
                          multiple: _vm.multiple,
                          isBatchEditorBtn: true,
                          isImportBtn: true,
                          isExportBtn: true,
                        },
                        on: {
                          handleExport: _vm.handleExport,
                          handleImport: function ($event) {
                            _vm.openToChannel = true
                          },
                          handleAdd: _vm.handleAdd,
                          batchEditor: _vm.batchEditor,
                          handleDelete: _vm.handleDelete,
                          handleRedoDelete: function ($event) {
                            _vm.dialogOptions.show = true
                          },
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "specialDiyBtn" },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#fff",
                                  "background-color": "#12cc9f",
                                },
                                attrs: {
                                  icon: "el-icon-document-copy",
                                  disabled: _vm.multiple,
                                  size: "mini",
                                },
                                on: { click: _vm.getCopyOrder },
                              },
                              [_vm._v("复制 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.tableData,
                              "tooltip-effect": "dark",
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "tree-props": {
                                children: "children",
                                hasChildren: "hasChildren",
                              },
                              "row-key": "goodsId",
                            },
                            on: {
                              select: _vm.handleSelect,
                              "select-all": _vm.selectTableAll,
                              "selection-change": _vm.handleSelectionChange,
                              "row-click": _vm.handleRowClick,
                            },
                          },
                          [
                            _vm._v("> "),
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "50",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                type: "",
                                width: "50",
                                label: "序号",
                                align: "center",
                                prop: "parentIndex",
                              },
                            }),
                            _c("el-table-column", {
                              key: "goodsNo",
                              attrs: {
                                label: "商品编码",
                                width: "160",
                                align: "center",
                                prop: "goodsNo",
                                "show-overflow-tooltip": true,
                                sortable: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleUpdate(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.goodsNo) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              key: "goodsName",
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                width: "160",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "barcode",
                              attrs: {
                                label: "条码",
                                width: "100",
                                align: "center",
                                prop: "barcode",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品类型",
                                align: "center",
                                prop: "goodsType",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("dict-tag", {
                                        attrs: {
                                          options: _vm.dict.type.goods_type,
                                          value: scope.row.goodsType,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              key: "categoryName",
                              attrs: {
                                label: "商品类别",
                                align: "center",
                                prop: "categoryName",
                                width: "140",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "brandName",
                              attrs: {
                                label: "商品品牌",
                                align: "center",
                                prop: "brandName",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "unitName",
                              attrs: {
                                label: "基本单位",
                                align: "center",
                                prop: "unitName",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "goodsSpec",
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "weight",
                              attrs: {
                                label: "重量",
                                align: "center",
                                prop: "weight",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "goodsModel",
                              attrs: {
                                label: "型号",
                                align: "center",
                                prop: "goodsModel",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品状态",
                                align: "center",
                                prop: "status",
                                width: "120",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("dict-tag", {
                                        attrs: {
                                          options: _vm.dict.type.common_status,
                                          value: scope.row.status,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              key: "qualityDays",
                              attrs: {
                                label: "保质期天数",
                                align: "center",
                                prop: "qualityDays",
                                "show-overflow-tooltip": true,
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              key: "purPrice",
                              attrs: {
                                label: "商品进价",
                                align: "center",
                                prop: "purPrice",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "salePrice",
                              attrs: {
                                label: "商品售价",
                                align: "center",
                                prop: "salePrice",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "taxRate",
                              attrs: {
                                label: "税率(%)",
                                align: "center",
                                prop: "taxRate",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "计价方式",
                                align: "center",
                                prop: "pricingMode",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("dict-tag", {
                                        attrs: {
                                          options: _vm.dict.type.pricing_mode,
                                          value: scope.row.pricingMode,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "是否管库存",
                                align: "center",
                                prop: "isManageStock",
                                width: "100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("dict-tag", {
                                        attrs: {
                                          options:
                                            _vm.dict.type.App_is_manage_stock,
                                          value: scope.row.isManageStock,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              key: "zjm",
                              attrs: {
                                label: "助记码",
                                align: "center",
                                prop: "zjm",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "remark",
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "remark",
                                width: "120",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "updateBy",
                              attrs: {
                                label: "修改人",
                                align: "center",
                                prop: "updateBy",
                                width: "120",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "修改时间",
                                align: "center",
                                prop: "updateTime",
                                width: "155",
                              },
                            }),
                            _c("el-table-column", {
                              key: "createBy",
                              attrs: {
                                label: "创建人",
                                align: "center",
                                prop: "createBy",
                                width: "120",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "创建时间",
                                align: "center",
                                prop: "createTime",
                                width: "155",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("bottomPage", {
                          attrs: {
                            getList: _vm.getList,
                            idsCom: _vm.ids,
                            totalCom: _vm.total,
                          },
                          on: { selectAllCom: _vm.selectAll },
                          model: {
                            value: _vm.queryParams,
                            callback: function ($$v) {
                              _vm.queryParams = $$v
                            },
                            expression: "queryParams",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", {
        attrs: { title: "批量修改", width: 750, showDialog: _vm.openBatch },
        on: {
          "update:showDialog": function ($event) {
            _vm.openBatch = $event
          },
          "update:show-dialog": function ($event) {
            _vm.openBatch = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "batchForm",
                    attrs: { model: _vm.batchForm, "label-width": "95px" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品类别", prop: "parentId" } },
                          [
                            _c("treeselect", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                size: "mini",
                                options: _vm.categoryTreeOptionsUnclassified,
                                "show-count": true,
                                placeholder: "请选择类别",
                                normalizer: _vm.normalizerCategory,
                              },
                              model: {
                                value: _vm.batchForm.categoryId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.batchForm, "categoryId", $$v)
                                },
                                expression: "batchForm.categoryId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品品牌", prop: "brandId" } },
                          [
                            _c("SelectRemote", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                option: _vm.$select({
                                  key: "listBrand",
                                  option: {
                                    option: {
                                      clearable: true,
                                    },
                                  },
                                }).option,
                              },
                              model: {
                                value: _vm.batchForm.brandId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.batchForm, "brandId", $$v)
                                },
                                expression: "batchForm.brandId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品类型", prop: "goodsType" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidth",
                                attrs: {
                                  size: "mini",
                                  clearable: "",
                                  placeholder: "请输入商品类型",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.batchForm.goodsType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.batchForm, "goodsType", $$v)
                                  },
                                  expression: "batchForm.goodsType",
                                },
                              },
                              _vm._l(_vm.dict.type.goods_type, function (dict) {
                                return _c("el-option", {
                                  key: dict.value,
                                  attrs: {
                                    label: dict.label,
                                    value: dict.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "计价方式", prop: "pricingMode" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidth",
                                attrs: {
                                  size: "mini",
                                  clearable: "",
                                  placeholder: "请选择计价方式",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.batchForm.pricingMode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.batchForm, "pricingMode", $$v)
                                  },
                                  expression: "batchForm.pricingMode",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.pricing_mode,
                                function (dict) {
                                  return _c("el-option", {
                                    key: dict.value,
                                    attrs: {
                                      label: dict.label,
                                      value: dict.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "是否管库存",
                              prop: "isManageStock",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidth",
                                attrs: {
                                  size: "mini",
                                  clearable: "",
                                  placeholder: "请选择是否管库存",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.batchForm.isManageStock,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.batchForm,
                                      "isManageStock",
                                      $$v
                                    )
                                  },
                                  expression: "batchForm.isManageStock",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.App_is_manage_stock,
                                function (dict) {
                                  return _c("el-option", {
                                    key: dict.value,
                                    attrs: {
                                      label: dict.label,
                                      value: dict.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "储存方式", prop: "depositWay" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                placeholder: "请输入储存方式",
                                maxlength: "40",
                              },
                              model: {
                                value: _vm.batchForm.depositWay,
                                callback: function ($$v) {
                                  _vm.$set(_vm.batchForm, "depositWay", $$v)
                                },
                                expression: "batchForm.depositWay",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "保质期天数", prop: "qualityDays" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                placeholder: "请输入保质期天数",
                              },
                              model: {
                                value: _vm.batchForm.qualityDays,
                                callback: function ($$v) {
                                  _vm.$set(_vm.batchForm, "qualityDays", $$v)
                                },
                                expression: "batchForm.qualityDays",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "基本单位", prop: "brandId" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidth",
                                attrs: {
                                  size: "mini",
                                  clearable: "",
                                  placeholder: "请选择基本单位",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.batchForm.unitName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.batchForm, "unitName", $$v)
                                  },
                                  expression: "batchForm.unitName",
                                },
                              },
                              _vm._l(_vm.unitList, function (item) {
                                return _c("el-option", {
                                  key: item.dictValue,
                                  attrs: {
                                    label: item.dictLabel,
                                    value: item.dictValue,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "税率(%)", prop: "taxRate" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                maxlength: "7",
                                placeholder: "请输入税率(%)",
                              },
                              model: {
                                value: _vm.batchForm.taxRate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.batchForm,
                                    "taxRate",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "batchForm.taxRate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "供应商", prop: "partnerId" } },
                          [
                            _c("SelectRemote", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                option: _vm.$select({
                                  key: "listSupplier",
                                  option: {
                                    option: {
                                      clearable: true,
                                    },
                                  },
                                }).option,
                              },
                              model: {
                                value: _vm.batchForm.partnerId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.batchForm, "partnerId", $$v)
                                },
                                expression: "batchForm.partnerId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "预警天数",
                              prop: "qualityAlertDays",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                placeholder: "请输入预警天数",
                                maxlength: "7",
                              },
                              model: {
                                value: _vm.batchForm.qualityAlertDays,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.batchForm,
                                    "qualityAlertDays",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "batchForm.qualityAlertDays",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品重量(kg)", prop: "weight" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                placeholder: "请输入商品重量(kg)",
                                maxlength: "20",
                              },
                              model: {
                                value: _vm.batchForm.weight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.batchForm, "weight", $$v)
                                },
                                expression: "batchForm.weight",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "商品状态", prop: "status" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "inputWidth",
                            attrs: {
                              size: "mini",
                              clearable: "",
                              placeholder: "请选择商品状态",
                              filterable: "",
                            },
                            model: {
                              value: _vm.batchForm.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.batchForm, "status", $$v)
                              },
                              expression: "batchForm.status",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "正常", value: 0 },
                            }),
                            _c("el-option", {
                              attrs: { label: "停用", value: 1 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.openBatch = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.submitBatchForm },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: {
          title: _vm.categoryTitle,
          width: 500,
          showDialog: _vm.openCategory,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.openCategory = $event
          },
          "update:show-dialog": function ($event) {
            _vm.openCategory = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingDia,
                        expression: "loadingDia",
                      },
                    ],
                    ref: "categoryForm",
                    attrs: {
                      model: _vm.categoryForm,
                      rules: _vm.categoryRules,
                      "label-width": "80px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "上级类别", prop: "parentId" },
                              },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options:
                                      _vm.categoryTreeOptionsUnclassified,
                                    "show-count": true,
                                    placeholder: "请选择上级类别",
                                    normalizer: _vm.normalizerCategory,
                                    open: _vm.getTreeselect,
                                  },
                                  on: { input: _vm.changeParentId },
                                  model: {
                                    value: _vm.categoryForm.parentId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.categoryForm,
                                        "parentId",
                                        $$v
                                      )
                                    },
                                    expression: "categoryForm.parentId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "类别编码",
                                  prop: "categoryNo",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "x-fc" },
                                  [
                                    _c("el-input", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.categoryForm.parentId != 0,
                                          expression:
                                            "categoryForm.parentId != 0",
                                        },
                                      ],
                                      attrs: {
                                        placeholder: "请选择上级类别",
                                        disabled: "",
                                      },
                                      model: {
                                        value: _vm.categoryForm.categoryNo1,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.categoryForm,
                                            "categoryNo1",
                                            $$v
                                          )
                                        },
                                        expression: "categoryForm.categoryNo1",
                                      },
                                    }),
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入类别编码",
                                        maxlength: "13",
                                      },
                                      model: {
                                        value: _vm.categoryForm.categoryNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.categoryForm,
                                            "categoryNo",
                                            $$v
                                          )
                                        },
                                        expression: "categoryForm.categoryNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "类别名称",
                                  prop: "categoryName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入类别名称",
                                    maxlength: "30",
                                  },
                                  model: {
                                    value: _vm.categoryForm.categoryName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.categoryForm,
                                        "categoryName",
                                        $$v
                                      )
                                    },
                                    expression: "categoryForm.categoryName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "排序编号", prop: "sortNo" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入排序编号",
                                    maxlength: "30",
                                  },
                                  model: {
                                    value: _vm.categoryForm.sortNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.categoryForm,
                                        "sortNo",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "categoryForm.sortNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "零售可见",
                                  prop: "isPosVisible",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.categoryForm.isPosVisible,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.categoryForm,
                                          "isPosVisible",
                                          $$v
                                        )
                                      },
                                      expression: "categoryForm.isPosVisible",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dict.type.App_is_pos_visible,
                                    function (dict) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: dict.value,
                                          attrs: { label: dict.value },
                                        },
                                        [_vm._v(_vm._s(dict.label) + " ")]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注", prop: "remark" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "备注长度介于 1 和 80 字符之间",
                                    maxlength: "80",
                                  },
                                  model: {
                                    value: _vm.categoryForm.remark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.categoryForm, "remark", $$v)
                                    },
                                    expression: "categoryForm.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancelCategory } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.submitCategoryForm },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("to-channel", {
        attrs: { visible: _vm.openToChannel, downloadFile: _vm.downloadFile },
        on: {
          "update:visible": function ($event) {
            _vm.openToChannel = $event
          },
          getFileUrl: _vm.getFileUrl,
          removeFile: _vm.removeFile,
          upload: _vm.uploadFile,
        },
      }),
      _c("importError", {
        attrs: { errVisible: _vm.errVisible, errorList: _vm.errorList },
        on: {
          "update:errVisible": function ($event) {
            _vm.errVisible = $event
          },
          "update:err-visible": function ($event) {
            _vm.errVisible = $event
          },
        },
      }),
      _c("Dialog2", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }