var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "store-detail" }, [
    _c(
      "div",
      { staticClass: "card" },
      [
        _c(
          "topOperatingButton",
          {
            attrs: {
              disabled:
                _vm.disabled &&
                (_vm.disabledBtn === undefined || _vm.disabledBtn === true),
              showAudit: _vm.showAudit,
              isAddBtn: _vm.showAdd,
              isExamineBtn: ["130101", "140309", "170309", "170409"].includes(
                _vm.billType
              )
                ? true
                : false,
              isAuditBillBtn: ["130101", "140309", "170309", "170409"].includes(
                _vm.billType
              )
                ? false
                : true,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm(false, "仓库")
              },
              addBill: _vm.AddBill,
              auditBill: _vm.changeBillStatus,
              handleAdd: _vm.handleAdd,
              getQuit: _vm.getQuit,
            },
          },
          [
            _c(
              "template",
              { slot: "specialDiyBtn" },
              [
                _vm.billType === "130105" || _vm.billType === "130106"
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini", disabled: _vm.disabled },
                        on: { click: _vm.storeSelectBills },
                      },
                      [_vm._v("引入源单 ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-remove-outline",
                      size: "mini",
                      disabled: _vm.single,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeBillStatus("关闭")
                      },
                    },
                  },
                  [_vm._v("终止单据 ")]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "ken-detail-form",
            attrs: {
              model: _vm.form,
              "label-width": "100px",
              "label-position": "right",
              rules: _vm.rules,
              size: "mini",
            },
          },
          [
            _c(
              "cardTitleCom",
              {
                attrs: {
                  cardTitle: "基本信息",
                  billStatus: _vm.form.billStatus,
                  id: "basicInformation",
                },
              },
              [
                _c("template", { slot: "cardContent" }, [
                  _c(
                    "div",
                    { staticClass: "x-f marT10" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据编号", prop: "billNo" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "单据编号",
                              disabled: "",
                              size: "mini",
                            },
                            model: {
                              value: _vm.form.billNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billNo", $$v)
                              },
                              expression: "form.billNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据日期", prop: "billDate" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              disabled: _vm.disabled,
                              "value-format": "yyyy-MM-dd",
                              size: "mini",
                            },
                            model: {
                              value: _vm.form.billDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "billDate", $$v)
                              },
                              expression: "form.billDate",
                            },
                          }),
                        ],
                        1
                      ),
                      ["130105", "130106"].includes(_vm.billType)
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "业务类型",
                                prop: "billCustomType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "250px" },
                                  attrs: {
                                    size: "mini",
                                    disabled:
                                      _vm.billType == 130106
                                        ? true
                                        : _vm.disabled,
                                    placeholder: "请选择业务类型",
                                  },
                                  model: {
                                    value: _vm.form.billCustomType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "billCustomType", $$v)
                                    },
                                    expression: "form.billCustomType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "同价调拨", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "异价调拨", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._t("selectOneArea", null, {
                        form: _vm.form,
                        disabled: _vm.disabled,
                        storeData: _vm.storeData,
                        getStore: _vm.getStore,
                        deptData: _vm.deptData,
                        normalizerStore: _vm.normalizerStore,
                        getDiyselectId: _vm.getDiyselectId,
                      }),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _vm._t("selectTwoArea", null, {
                        form: _vm.form,
                        disabled: _vm.disabled,
                      }),
                    ],
                    2
                  ),
                ]),
              ],
              2
            ),
            _c(
              "cardTitleCom",
              { attrs: { cardTitle: "商品信息" } },
              [
                _c("template", { slot: "rightCardTitle" }, [
                  _c(
                    "div",
                    { staticClass: "marR10" },
                    [
                      ["130102", "130103"].includes(_vm.billType)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.disabled,
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getDistribution(0)
                                },
                              },
                            },
                            [_vm._v("批量修改仓库")]
                          )
                        : _vm._e(),
                      ["130104"].includes(_vm.billType)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.disabled,
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getDistribution(2)
                                },
                              },
                            },
                            [_vm._v("批量修改调入仓库")]
                          )
                        : _vm._e(),
                      ["130104"].includes(_vm.billType)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.disabled,
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getDistribution(1)
                                },
                              },
                            },
                            [_vm._v("批量修改调出仓库")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          staticClass: "marL10",
                          attrs: {
                            type: "primary",
                            size: "mini",
                            disabled: _vm.form.billStatus != "0",
                          },
                          on: { click: _vm.handleOpenImport },
                        },
                        [_vm._v("导入")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("template", { slot: "cardContent" }, [
                  _c(
                    "div",
                    { staticClass: "store-table" },
                    [
                      _c(
                        "el-row",
                        [
                          ["130101", "140309", "170309", "170409"].includes(
                            _vm.billType
                          )
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "vxe-table",
                                    {
                                      ref: "dragTable",
                                      staticClass: "table",
                                      attrs: {
                                        data: _vm.form.details,
                                        border: "",
                                        "show-overflow": "",
                                        "max-height": "600px",
                                        height: "600px",
                                        "show-header-overflow": "",
                                        "checkbox-config": { trigger: "row" },
                                        "row-config": { isHover: true },
                                        "sort-config": { trigger: "cell" },
                                        "scroll-y": { enabled: true },
                                        "footer-method": _vm.footerMethod,
                                        "show-footer": "",
                                      },
                                    },
                                    [
                                      _c("vxe-column", {
                                        attrs: {
                                          align: "center",
                                          title: _vm.tableCellLabel,
                                          width: "80",
                                          fixed: "left",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      on: {
                                                        mouseenter: function (
                                                          $event
                                                        ) {
                                                          return _vm.cellMouseEnter(
                                                            scope.row
                                                          )
                                                        },
                                                        mouseleave: function (
                                                          $event
                                                        ) {
                                                          return _vm.cellMouseLeave(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              scope.row
                                                                .hoverRow &&
                                                              !_vm.disabled,
                                                            expression:
                                                              "scope.row.hoverRow && !disabled",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "el-icon-circle-plus operatePush",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.row(
                                                              "push",
                                                              scope.rowIndex
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("i", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              scope.row
                                                                .hoverRow &&
                                                              !_vm.disabled,
                                                            expression:
                                                              "scope.row.hoverRow && !disabled",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "el-icon-remove operateDel",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.row(
                                                              "del",
                                                              scope.rowIndex
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                !scope.row
                                                                  .hoverRow,
                                                              expression:
                                                                "!scope.row.hoverRow",
                                                            },
                                                          ],
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                scope.rowIndex +
                                                                  1
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1319412080
                                        ),
                                      }),
                                      _c(
                                        "vxe-column",
                                        {
                                          attrs: {
                                            title: "商品编码",
                                            width: "160",
                                            align: "center",
                                            field: "goodsNo",
                                            fixed: "left",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "details[" +
                                                            scope.rowIndex +
                                                            "].goodsNo",
                                                          rules:
                                                            _vm.rules[
                                                              "details.goodsNo"
                                                            ],
                                                        },
                                                      },
                                                      [
                                                        _c("SelectRemote", {
                                                          ref:
                                                            "goodsNo" +
                                                            "_" +
                                                            scope.rowIndex +
                                                            "_" +
                                                            _vm
                                                              .canKeyTableColumnList[0]
                                                              .columnindex,
                                                          attrs: {
                                                            size: "mini",
                                                            disabled: [
                                                              "130106",
                                                            ].includes(
                                                              _vm.billType
                                                            )
                                                              ? true
                                                              : _vm.disabled,
                                                            option:
                                                              _vm.optionGoods,
                                                            filterable: true,
                                                            placeholder:
                                                              "选择商品",
                                                          },
                                                          on: {
                                                            handleSelectKeydown:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.handleTableKeydown(
                                                                  $event,
                                                                  scope.row,
                                                                  "goodsNo",
                                                                  scope.rowIndex,
                                                                  _vm
                                                                    .canKeyTableColumnList[0]
                                                                    .columnindex
                                                                )
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.goodsNo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "goodsNo",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.goodsNo",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            796118734
                                          ),
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  color: "#ff4949",
                                                },
                                              },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v(" 商品编码 "),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _c("vxe-column", {
                                        attrs: {
                                          title: "商品名称",
                                          width: "160",
                                          align: "center",
                                          field: "goodsName",
                                          fixed: "left",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          title: "条码",
                                          width: "160",
                                          align: "center",
                                          field: "barcode",
                                          fixed: "left",
                                        },
                                      }),
                                      _vm._t("tableFront"),
                                      _c("vxe-column", {
                                        attrs: {
                                          title: "规格",
                                          width: "120",
                                          align: "center",
                                          field: "goodsSpec",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "details[" +
                                                          scope.rowIndex +
                                                          "].unitId",
                                                        rules:
                                                          _vm.rules[
                                                            "details.unitId"
                                                          ],
                                                      },
                                                    },
                                                    [
                                                      _c("SelectLocal", {
                                                        ref:
                                                          "goodsSpec" +
                                                          "_" +
                                                          scope.rowIndex +
                                                          "_" +
                                                          _vm
                                                            .canKeyTableColumnList[1]
                                                            .columnindex,
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled ||
                                                            (!scope.row
                                                              .unitId &&
                                                              scope.row
                                                                .unitId != 0),
                                                          option: {
                                                            data: scope.row
                                                              .units,
                                                            label: "goodsSpec",
                                                            value: "unitId",
                                                          },
                                                        },
                                                        on: {
                                                          handleSelectKeydown:
                                                            function ($event) {
                                                              return _vm.handleTableKeydown(
                                                                $event,
                                                                scope.row,
                                                                "goodsSpec",
                                                                scope.rowIndex,
                                                                _vm
                                                                  .canKeyTableColumnList[1]
                                                                  .columnindex
                                                              )
                                                            },
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.$refs.form.validateField(
                                                              "details[" +
                                                                scope.rowIndex +
                                                                "].unitId"
                                                            )
                                                          },
                                                          selectChange:
                                                            function (v, row) {
                                                              return [
                                                                "130101",
                                                                "140309",
                                                                "170309",
                                                                "170409",
                                                              ].includes(
                                                                _vm.billType
                                                              )
                                                                ? _vm.checkTableChange(
                                                                    "unitId",
                                                                    scope.rowIndex,
                                                                    row,
                                                                    scope.row
                                                                  )
                                                                : _vm.changeUnitPrice(
                                                                    scope.row
                                                                      .unitId,
                                                                    scope.row
                                                                      .units,
                                                                    scope.rowIndex,
                                                                    false
                                                                  )
                                                            },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.unitId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "unitId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.unitId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          112056600
                                        ),
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          title: "账面数",
                                          align: "center",
                                          width: "120",
                                          field: "basUnitAccountQty",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          title: "基本单位盘点数",
                                          align: "center",
                                          width: "120",
                                          field: "basUnitQty",
                                        },
                                      }),
                                      !["130105"].includes(_vm.billType)
                                        ? _c("vxe-column", {
                                            attrs: {
                                              title: "商品批号",
                                              width: "160",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input", {
                                                        ref:
                                                          "batchNo" +
                                                          "_" +
                                                          scope.rowIndex +
                                                          "_" +
                                                          _vm
                                                            .canKeyTableColumnList[2]
                                                            .columnindex,
                                                        attrs: {
                                                          size: "mini",
                                                          disabled: [
                                                            "130106",
                                                          ].includes(
                                                            _vm.billType
                                                          )
                                                            ? true
                                                            : _vm.disabled,
                                                        },
                                                        on: {
                                                          blur: _vm.watchScroll,
                                                        },
                                                        nativeOn: {
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleTableKeydown(
                                                              $event,
                                                              scope.row,
                                                              "batchNo",
                                                              scope.rowIndex,
                                                              _vm
                                                                .canKeyTableColumnList[2]
                                                                .columnindex
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.batchNo,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "batchNo",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.batchNo",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3481863186
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._t("tableMiddleSpecial"),
                                      _c(
                                        "vxe-column",
                                        {
                                          attrs: {
                                            title: "单位",
                                            align: "center",
                                            width: "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "details[" +
                                                            scope.rowIndex +
                                                            "].unitId",
                                                          rules:
                                                            _vm.rules[
                                                              "details.unitId"
                                                            ],
                                                        },
                                                      },
                                                      [
                                                        _c("SelectLocal", {
                                                          ref:
                                                            "unitId" +
                                                            "_" +
                                                            scope.rowIndex +
                                                            "_" +
                                                            _vm
                                                              .canKeyTableColumnList[3]
                                                              .columnindex,
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled ||
                                                              (!scope.row
                                                                .unitId &&
                                                                scope.row
                                                                  .unitId != 0),
                                                            option: {
                                                              data: scope.row
                                                                .units,
                                                              label: "unitName",
                                                              value: "unitId",
                                                            },
                                                          },
                                                          on: {
                                                            handleSelectKeydown:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.handleTableKeydown(
                                                                  $event,
                                                                  scope.row,
                                                                  "unitId",
                                                                  scope.rowIndex,
                                                                  _vm
                                                                    .canKeyTableColumnList[3]
                                                                    .columnindex
                                                                )
                                                              },
                                                            blur: function (
                                                              $event
                                                            ) {
                                                              return _vm.$refs.form.validateField(
                                                                "details[" +
                                                                  scope.rowIndex +
                                                                  "].unitId"
                                                              )
                                                            },
                                                            selectChange:
                                                              function (
                                                                v,
                                                                row
                                                              ) {
                                                                return [
                                                                  "130101",
                                                                  "140309",
                                                                  "170309",
                                                                  "170409",
                                                                ].includes(
                                                                  _vm.billType
                                                                )
                                                                  ? _vm.checkTableChange(
                                                                      "unitId",
                                                                      scope.rowIndex,
                                                                      row
                                                                    )
                                                                  : _vm.changeUnitPrice(
                                                                      scope.row
                                                                        .unitId,
                                                                      scope.row
                                                                        .units,
                                                                      scope.rowIndex,
                                                                      false
                                                                    )
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.unitId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "unitId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.unitId",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            329942340
                                          ),
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  color: "#ff4949",
                                                },
                                              },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v(" 单位 "),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _vm.TypeBill != "1000" &&
                                      _vm.billType != "130101" &&
                                      _vm.billType != "140309" &&
                                      _vm.billType != "170309" &&
                                      _vm.billType != "170409"
                                        ? _c(
                                            "vxe-column",
                                            {
                                              attrs: {
                                                title: _vm.qtyLabel,
                                                align: "center",
                                                width: "120",
                                                field: "unitQty",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                "details[" +
                                                                scope.rowIndex +
                                                                "].unitQty",
                                                              rules:
                                                                _vm.rules[
                                                                  "details.unitQty"
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              ref:
                                                                "unitQty" +
                                                                "_" +
                                                                scope.rowIndex +
                                                                "_" +
                                                                _vm
                                                                  .canKeyTableColumnList[4]
                                                                  .columnindex,
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disabled,
                                                                oninput:
                                                                  "value=value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^([0-9-, -]\\d*\\.?\\d{0,8})?.*$/,'$1').replace(/^\\./g, '').replace(/^0+(?!\\.|$)/g, ''),value = Number(value) >= 9999999 ? 9999999 : value",
                                                                sunyunFormat:
                                                                  function (v) {
                                                                    return _vm.$syInput(
                                                                      1,
                                                                      v
                                                                    )
                                                                  },
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onkeyupUnitQty(
                                                                    scope.row
                                                                  )
                                                                },
                                                              },
                                                              nativeOn: {
                                                                keydown: [
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "up",
                                                                        38,
                                                                        $event.key,
                                                                        [
                                                                          "Up",
                                                                          "ArrowUp",
                                                                        ]
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    $event.preventDefault()
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "unitQty",
                                                                      scope.rowIndex,
                                                                      _vm
                                                                        .canKeyTableColumnList[4]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "down",
                                                                        40,
                                                                        $event.key,
                                                                        [
                                                                          "Down",
                                                                          "ArrowDown",
                                                                        ]
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    $event.preventDefault()
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "unitQty",
                                                                      scope.rowIndex,
                                                                      _vm
                                                                        .canKeyTableColumnList[4]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "left",
                                                                        37,
                                                                        $event.key,
                                                                        [
                                                                          "Left",
                                                                          "ArrowLeft",
                                                                        ]
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    if (
                                                                      "button" in
                                                                        $event &&
                                                                      $event.button !==
                                                                        0
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    $event.preventDefault()
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "unitQty",
                                                                      scope.rowIndex,
                                                                      _vm
                                                                        .canKeyTableColumnList[4]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "right",
                                                                        39,
                                                                        $event.key,
                                                                        [
                                                                          "Right",
                                                                          "ArrowRight",
                                                                        ]
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    if (
                                                                      "button" in
                                                                        $event &&
                                                                      $event.button !==
                                                                        2
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    $event.preventDefault()
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "unitQty",
                                                                      scope.rowIndex,
                                                                      _vm
                                                                        .canKeyTableColumnList[4]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    $event.preventDefault()
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "unitQty",
                                                                      scope.rowIndex,
                                                                      _vm
                                                                        .canKeyTableColumnList[4]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                                ],
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .unitQty,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "unitQty",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.unitQty",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1205925614
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#ff4949",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.qtyLabel) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm.TypeBill != "1000" &&
                                      _vm.billType != "130105" &&
                                      _vm.billType != "130101" &&
                                      _vm.billType != "170409" &&
                                      _vm.billType != "140309" &&
                                      _vm.billType != "170309"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              title:
                                                "" +
                                                (_vm.billType == 130107
                                                  ? "盘盈采购单价(元)"
                                                  : _vm.billType == 130108
                                                  ? "盘亏采购单价(元)"
                                                  : "单价(元)"),
                                              align: "center",
                                              width: "140",
                                              field: "unitPrice",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.TypeBill != "1000" &&
                                      _vm.billType != "130105" &&
                                      _vm.billType != "130101" &&
                                      _vm.billType != "170409" &&
                                      _vm.billType != "140309" &&
                                      _vm.billType != "170309"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              title: _vm.moneyLabel,
                                              align: "center",
                                              field: "unitMoney",
                                              width: "140",
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "vxe-column",
                                        {
                                          attrs: {
                                            align: "center",
                                            width: "120",
                                            field: "unitQty",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "details[" +
                                                            scope.rowIndex +
                                                            "].unitQty",
                                                          rules:
                                                            _vm.rules[
                                                              "details.unitQty"
                                                            ],
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          ref:
                                                            "unitQty1" +
                                                            "_" +
                                                            scope.rowIndex +
                                                            "_" +
                                                            _vm
                                                              .canKeyTableColumnList[5]
                                                              .columnindex,
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                            oninput:
                                                              "value=value.replace(/[^\\d.-]/g, '').replace(/^-?\\d*\\.?\\d{0,8}.*$/, '$&').replace(/\\.{2,}/g, '.').replace(/(?!^)-/g, '').replace(/^(\\d+)(\\.\\d{0,8})?$/, '$1$2').replace(/^0+(?!\\.|$)/g, ''),value = Number(value) < -9999999 ? -9999999 : (Number(value) > 9999999 ? 9999999 : value)",
                                                            sunyunFormat:
                                                              function (v) {
                                                                return _vm.$syInput(
                                                                  1,
                                                                  v
                                                                )
                                                              },
                                                          },
                                                          on: {
                                                            blur: function (
                                                              $event
                                                            ) {
                                                              return _vm.checkTableChange(
                                                                "unitQty",
                                                                scope.rowIndex
                                                              )
                                                            },
                                                          },
                                                          nativeOn: {
                                                            keydown: [
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "up",
                                                                    38,
                                                                    $event.key,
                                                                    [
                                                                      "Up",
                                                                      "ArrowUp",
                                                                    ]
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                $event.preventDefault()
                                                                return _vm.handleTableKeydown(
                                                                  $event,
                                                                  scope.row,
                                                                  "unitQty1",
                                                                  scope.rowIndex,
                                                                  _vm
                                                                    .canKeyTableColumnList[5]
                                                                    .columnindex
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "down",
                                                                    40,
                                                                    $event.key,
                                                                    [
                                                                      "Down",
                                                                      "ArrowDown",
                                                                    ]
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                $event.preventDefault()
                                                                return _vm.handleTableKeydown(
                                                                  $event,
                                                                  scope.row,
                                                                  "unitQty1",
                                                                  scope.rowIndex,
                                                                  _vm
                                                                    .canKeyTableColumnList[5]
                                                                    .columnindex
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "left",
                                                                    37,
                                                                    $event.key,
                                                                    [
                                                                      "Left",
                                                                      "ArrowLeft",
                                                                    ]
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                if (
                                                                  "button" in
                                                                    $event &&
                                                                  $event.button !==
                                                                    0
                                                                ) {
                                                                  return null
                                                                }
                                                                $event.preventDefault()
                                                                return _vm.handleTableKeydown(
                                                                  $event,
                                                                  scope.row,
                                                                  "unitQty1",
                                                                  scope.rowIndex,
                                                                  _vm
                                                                    .canKeyTableColumnList[5]
                                                                    .columnindex
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "right",
                                                                    39,
                                                                    $event.key,
                                                                    [
                                                                      "Right",
                                                                      "ArrowRight",
                                                                    ]
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                if (
                                                                  "button" in
                                                                    $event &&
                                                                  $event.button !==
                                                                    2
                                                                ) {
                                                                  return null
                                                                }
                                                                $event.preventDefault()
                                                                return _vm.handleTableKeydown(
                                                                  $event,
                                                                  scope.row,
                                                                  "unitQty1",
                                                                  scope.rowIndex,
                                                                  _vm
                                                                    .canKeyTableColumnList[5]
                                                                    .columnindex
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter",
                                                                    13,
                                                                    $event.key,
                                                                    "Enter"
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                $event.preventDefault()
                                                                return _vm.handleTableKeydown(
                                                                  $event,
                                                                  scope.row,
                                                                  "unitQty1",
                                                                  scope.rowIndex,
                                                                  _vm
                                                                    .canKeyTableColumnList[5]
                                                                    .columnindex
                                                                )
                                                              },
                                                            ],
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.unitQty,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "unitQty",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.unitQty",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2236009713
                                          ),
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  color: "#ff4949",
                                                },
                                              },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v(" 盘点数 "),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "vxe-colgroup",
                                        {
                                          attrs: {
                                            title: "盘点进价金额",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              title: "单位进价(元)",
                                              align: "center",
                                              field: "unitPurPrice",
                                              width: "150",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$syInput(
                                                              3,
                                                              scope.row
                                                                .unitPurPrice
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3826402556
                                            ),
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              title: "进价金额(元)",
                                              align: "center",
                                              field: "unitPurMoney",
                                              width: "150",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$syInput(
                                                              3,
                                                              scope.row
                                                                .unitPurMoney
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              4075064833
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vxe-colgroup",
                                        {
                                          attrs: {
                                            title: "盘点售价金额",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              title: "单位售价(元)",
                                              align: "center",
                                              field: "unitSalePrice",
                                              width: "150",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$syInput(
                                                              3,
                                                              scope.row
                                                                .unitSalePrice
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1790828464
                                            ),
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              title: "售价金额(元)",
                                              align: "center",
                                              field: "unitSaleMoney",
                                              width: "150",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$syInput(
                                                              3,
                                                              scope.row
                                                                .unitSaleMoney
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              4111241197
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("vxe-column", {
                                        attrs: {
                                          title: "盘盈数量",
                                          align: "center",
                                          width: "120",
                                          field: "basUnitAdjustQty",
                                        },
                                      }),
                                      _c(
                                        "vxe-colgroup",
                                        {
                                          attrs: {
                                            title: "盘盈金额",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              title: "进价金额(元)",
                                              align: "center",
                                              field: "basUnitAdjustPurPrice",
                                              width: "150",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$syInput(
                                                              3,
                                                              scope.row
                                                                .basUnitAdjustPurPrice
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1284704177
                                            ),
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              title: "售价金额(元)",
                                              align: "center",
                                              field: "basUnitAdjustSalePrice",
                                              width: "150",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$syInput(
                                                              3,
                                                              scope.row
                                                                .basUnitAdjustSalePrice
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1093332093
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("vxe-column", {
                                        attrs: {
                                          title: "盘亏数量",
                                          align: "center",
                                          width: "120",
                                          field: "basUnitLoseQty",
                                        },
                                      }),
                                      _c(
                                        "vxe-colgroup",
                                        {
                                          attrs: {
                                            title: "盘亏金额",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              title: "进价金额(元)",
                                              align: "center",
                                              field: "basUnitLosePurPrice",
                                              width: "150",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$syInput(
                                                              3,
                                                              scope.row
                                                                .basUnitLosePurPrice
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              4064353881
                                            ),
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              title: "售价金额(元)",
                                              align: "center",
                                              field: "basUnitLoseSalePrice",
                                              width: "150",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$syInput(
                                                              3,
                                                              scope.row
                                                                .basUnitLoseSalePrice
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              284972821
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._t("tableSpecial"),
                                      _c("vxe-column", {
                                        attrs: {
                                          title: "备注",
                                          align: "center",
                                          "min-width": "200",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  !["130106"].includes(
                                                    _vm.billType
                                                  )
                                                    ? _c("el-input", {
                                                        ref:
                                                          "remark" +
                                                          "_" +
                                                          scope.rowIndex +
                                                          "_" +
                                                          _vm
                                                            .canKeyTableColumnList[6]
                                                            .columnindex,
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled &&
                                                            (_vm.disabledRemark ===
                                                              undefined ||
                                                              _vm.disabledRemark ===
                                                                true),
                                                        },
                                                        nativeOn: {
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleTableKeydown(
                                                              $event,
                                                              scope.row,
                                                              "remark",
                                                              scope.rowIndex,
                                                              _vm
                                                                .canKeyTableColumnList[6]
                                                                .columnindex
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.remark,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "remark",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.remark",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  ["130106"].includes(
                                                    _vm.billType
                                                  )
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row.remark
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2460232046
                                        ),
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !["130101", "140309", "170309", "170409"].includes(
                            _vm.billType
                          )
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      ref: "dragTable",
                                      staticClass: "table",
                                      attrs: {
                                        data: _vm.form.details,
                                        "row-key": "columnId",
                                        height: "500",
                                        border: "",
                                        "show-summary": "",
                                        "summary-method": _vm.getStoreSummaries,
                                      },
                                      on: {
                                        "selection-change":
                                          _vm.handleSelectionChange,
                                        "cell-mouse-enter": _vm.cellMouseEnter,
                                        "cell-mouse-leave": _vm.cellMouseLeave,
                                      },
                                    },
                                    [
                                      ["130102", "130103", "130104"].includes(
                                        _vm.billType
                                      )
                                        ? _c("el-table-column", {
                                            attrs: {
                                              type: "selection",
                                              width: "60",
                                              align: "center",
                                            },
                                          })
                                        : _vm._e(),
                                      !["130106"].includes(_vm.billType)
                                        ? _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              width: "80",
                                              label: _vm.tableCellLabel,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      scope.row.hoverRow &&
                                                      !_vm.disabled
                                                        ? _c("i", {
                                                            staticClass:
                                                              "el-icon-circle-plus operatePush",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.row(
                                                                  "push",
                                                                  scope.$index
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      scope.row.hoverRow &&
                                                      !_vm.disabled
                                                        ? _c("i", {
                                                            staticClass:
                                                              "el-icon-remove operateDel",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.row(
                                                                  "del",
                                                                  scope.$index
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      !scope.row.hoverRow
                                                        ? _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.$index +
                                                                    1
                                                                ) +
                                                                " "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1950924868
                                            ),
                                          })
                                        : _vm._e(),
                                      ["130106"].includes(_vm.billType)
                                        ? _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              width: "80",
                                              label: _vm.tableCellLabel,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              scope.$index + 1
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1755799989
                                            ),
                                          })
                                        : _vm._e(),
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            label: "商品编码",
                                            width: "160",
                                            align: "center",
                                            prop: "goodsNo",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "details[" +
                                                            scope.$index +
                                                            "].goodsNo",
                                                          rules:
                                                            _vm.rules[
                                                              "details.goodsNo"
                                                            ],
                                                        },
                                                      },
                                                      [
                                                        _c("SelectRemote", {
                                                          ref:
                                                            "goodsNo" +
                                                            "_" +
                                                            scope.$index +
                                                            "_" +
                                                            _vm
                                                              .canKeyTableColumnList[0]
                                                              .columnindex,
                                                          attrs: {
                                                            size: "mini",
                                                            disabled: [
                                                              "130106",
                                                            ].includes(
                                                              _vm.billType
                                                            )
                                                              ? true
                                                              : _vm.disabled,
                                                            option:
                                                              _vm.optionGoods,
                                                            filterable: true,
                                                            placeholder:
                                                              "选择商品",
                                                          },
                                                          on: {
                                                            handleSelectKeydown:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.handleTableKeydown(
                                                                  $event,
                                                                  scope.row,
                                                                  "goodsNo",
                                                                  scope.$index,
                                                                  _vm
                                                                    .canKeyTableColumnList[0]
                                                                    .columnindex
                                                                )
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.goodsNo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "goodsNo",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.goodsNo",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            599240064
                                          ),
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  color: "#ff4949",
                                                },
                                              },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v(" 商品编码 "),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "商品名称",
                                          align: "center",
                                          prop: "goodsName",
                                          "show-overflow-tooltip": "",
                                          width: "120",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "条码",
                                          width: "160",
                                          align: "center",
                                          prop: "barcode",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      ["130103", "130102"].includes(
                                        _vm.billType
                                      )
                                        ? _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "仓库",
                                                align: "center",
                                                width: "180",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                "details[" +
                                                                scope.$index +
                                                                "].storeId",
                                                              rules:
                                                                _vm.rules[
                                                                  "details.storeId"
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _c("SelectRemote", {
                                                              ref:
                                                                "storeId" +
                                                                "_" +
                                                                scope.$index +
                                                                "_" +
                                                                _vm
                                                                  .canKeyTableColumnList[1]
                                                                  .columnindex,
                                                              staticStyle: {
                                                                width: "150px",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disabled,
                                                                option:
                                                                  _vm.$select({
                                                                    key: "listStore",
                                                                  }).option,
                                                              },
                                                              on: {
                                                                selectChange:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.dataUpdate(
                                                                      scope,
                                                                      "storeId"
                                                                    )
                                                                  },
                                                                handleSelectKeydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "storeId",
                                                                      scope.$index,
                                                                      _vm
                                                                        .canKeyTableColumnList[1]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .storeId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "storeId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.storeId",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2864523481
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#ff4949",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" 仓库 "),
                                                ]
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      ["130104"].includes(_vm.billType)
                                        ? _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "调入仓库",
                                                align: "center",
                                                width: "180",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                "details[" +
                                                                scope.$index +
                                                                "].inStoreId",
                                                              rules:
                                                                _vm.rules[
                                                                  "details.inStoreId"
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _c("SelectRemote", {
                                                              ref:
                                                                "inStoreId" +
                                                                "_" +
                                                                scope.$index +
                                                                "_" +
                                                                _vm
                                                                  .canKeyTableColumnList[2]
                                                                  .columnindex,
                                                              staticStyle: {
                                                                width: "150px",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disabled,
                                                                option:
                                                                  _vm.$select({
                                                                    key: "listStore",
                                                                  }).option,
                                                              },
                                                              on: {
                                                                handleSelectKeydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "inStoreId",
                                                                      scope.$index,
                                                                      _vm
                                                                        .canKeyTableColumnList[2]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .inStoreId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "inStoreId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.inStoreId",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1168687285
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#ff4949",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" 调入仓库 "),
                                                ]
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      ["130104"].includes(_vm.billType)
                                        ? _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "调出仓库",
                                                align: "center",
                                                width: "180",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                "details[" +
                                                                scope.$index +
                                                                "].outStoreId",
                                                              rules:
                                                                _vm.rules[
                                                                  "details.outStoreId"
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _c("SelectRemote", {
                                                              ref:
                                                                "outStoreId" +
                                                                "_" +
                                                                scope.$index +
                                                                "_" +
                                                                _vm
                                                                  .canKeyTableColumnList[3]
                                                                  .columnindex,
                                                              staticStyle: {
                                                                width: "150px",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disabled,
                                                                option:
                                                                  _vm.$select({
                                                                    key: "listStore",
                                                                  }).option,
                                                              },
                                                              on: {
                                                                handleSelectKeydown:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "outStoreId",
                                                                      scope.$index,
                                                                      _vm
                                                                        .canKeyTableColumnList[3]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .outStoreId,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "outStoreId",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.outStoreId",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1115078108
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#ff4949",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" 调出仓库 "),
                                                ]
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm._t("tableFront"),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "规格",
                                          width: "120",
                                          align: "center",
                                          prop: "goodsSpec",
                                          "show-overflow-tooltip": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "details[" +
                                                          scope.$index +
                                                          "].unitId",
                                                        rules:
                                                          _vm.rules[
                                                            "details.unitId"
                                                          ],
                                                      },
                                                    },
                                                    [
                                                      _c("SelectLocal", {
                                                        ref:
                                                          "goodsSpec" +
                                                          "_" +
                                                          scope.$index +
                                                          "_" +
                                                          _vm
                                                            .canKeyTableColumnList[4]
                                                            .columnindex,
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled ||
                                                            (!scope.row
                                                              .unitId &&
                                                              scope.row
                                                                .unitId != 0),
                                                          option: {
                                                            data: scope.row
                                                              .units,
                                                            label: "goodsSpec",
                                                            value: "unitId",
                                                          },
                                                        },
                                                        on: {
                                                          handleSelectKeydown:
                                                            function ($event) {
                                                              return _vm.handleTableKeydown(
                                                                $event,
                                                                scope.row,
                                                                "goodsSpec",
                                                                scope.$index,
                                                                _vm
                                                                  .canKeyTableColumnList[4]
                                                                  .columnindex
                                                              )
                                                            },
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.$refs.form.validateField(
                                                              "details[" +
                                                                scope.$index +
                                                                "].unitId"
                                                            )
                                                          },
                                                          selectChange:
                                                            function (v, row) {
                                                              return [
                                                                "130101",
                                                                "140309",
                                                                "170309",
                                                                "170409",
                                                              ].includes(
                                                                _vm.billType
                                                              )
                                                                ? _vm.checkTableChange(
                                                                    "unitId",
                                                                    scope.$index,
                                                                    row
                                                                  )
                                                                : _vm.changeUnitPrice(
                                                                    scope.row
                                                                      .unitId,
                                                                    scope.row
                                                                      .units,
                                                                    scope.$index,
                                                                    false,
                                                                    scope.row
                                                                  )
                                                            },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.unitId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "unitId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.unitId",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          4131295928
                                        ),
                                      }),
                                      !["130105"].includes(_vm.billType)
                                        ? _c("el-table-column", {
                                            attrs: {
                                              label: "商品批号",
                                              width: "160",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input", {
                                                        ref:
                                                          "batchNo" +
                                                          "_" +
                                                          scope.$index +
                                                          "_" +
                                                          _vm
                                                            .canKeyTableColumnList[5]
                                                            .columnindex,
                                                        attrs: {
                                                          size: "mini",
                                                          disabled: [
                                                            "130106",
                                                          ].includes(
                                                            _vm.billType
                                                          )
                                                            ? true
                                                            : _vm.disabled,
                                                        },
                                                        on: {
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.watchScroll(
                                                              scope.row,
                                                              scope.$index,
                                                              "batchNo"
                                                            )
                                                          },
                                                        },
                                                        nativeOn: {
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleTableKeydown(
                                                              $event,
                                                              scope.row,
                                                              "batchNo",
                                                              scope.$index,
                                                              _vm
                                                                .canKeyTableColumnList[5]
                                                                .columnindex
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.batchNo,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "batchNo",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.batchNo",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2690423417
                                            ),
                                          })
                                        : _vm._e(),
                                      _vm._t("tableMiddleSpecial"),
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            label: "单位",
                                            align: "center",
                                            width: "120",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "details[" +
                                                            scope.$index +
                                                            "].unitId",
                                                          rules:
                                                            _vm.rules[
                                                              "details.unitId"
                                                            ],
                                                        },
                                                      },
                                                      [
                                                        _c("SelectLocal", {
                                                          ref:
                                                            "unitId" +
                                                            "_" +
                                                            scope.$index +
                                                            "_" +
                                                            _vm
                                                              .canKeyTableColumnList[6]
                                                              .columnindex,
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled ||
                                                              (!scope.row
                                                                .unitId &&
                                                                scope.row
                                                                  .unitId != 0),
                                                            option: {
                                                              data: scope.row
                                                                .units,
                                                              label: "unitName",
                                                              value: "unitId",
                                                            },
                                                          },
                                                          on: {
                                                            handleSelectKeydown:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.handleTableKeydown(
                                                                  $event,
                                                                  scope.row,
                                                                  "unitId",
                                                                  scope.$index,
                                                                  _vm
                                                                    .canKeyTableColumnList[6]
                                                                    .columnindex
                                                                )
                                                              },
                                                            blur: function (
                                                              $event
                                                            ) {
                                                              return _vm.$refs.form.validateField(
                                                                "details[" +
                                                                  scope.$index +
                                                                  "].unitId"
                                                              )
                                                            },
                                                            selectChange:
                                                              function (
                                                                v,
                                                                row
                                                              ) {
                                                                return [
                                                                  "130101",
                                                                  "140309",
                                                                  "170309",
                                                                  "170409",
                                                                ].includes(
                                                                  _vm.billType
                                                                )
                                                                  ? _vm.checkTableChange(
                                                                      "unitId",
                                                                      scope.$index,
                                                                      row
                                                                    )
                                                                  : _vm.changeUnitPrice(
                                                                      scope.row
                                                                        .unitId,
                                                                      scope.row
                                                                        .units,
                                                                      scope.$index,
                                                                      false,
                                                                      scope.row
                                                                    )
                                                              },
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.unitId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "unitId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.unitId",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            686306284
                                          ),
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  color: "#ff4949",
                                                },
                                              },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v(" 单位 "),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _vm.TypeBill != "1000" &&
                                      _vm.billType != "130101" &&
                                      _vm.billType != "140309"
                                        ? _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: _vm.qtyLabel,
                                                align: "center",
                                                width: "120",
                                                prop: "unitQty",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              prop:
                                                                "details[" +
                                                                scope.$index +
                                                                "].unitQty",
                                                              rules:
                                                                _vm.rules[
                                                                  "details.unitQty"
                                                                ],
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              ref:
                                                                "unitQty" +
                                                                "_" +
                                                                scope.$index +
                                                                "_" +
                                                                _vm
                                                                  .canKeyTableColumnList[7]
                                                                  .columnindex,
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disabled,
                                                                oninput:
                                                                  "value=value.replace(/[^\\d.-]/g, '').replace(/^-?\\d*\\.?\\d{0,8}.*$/, '$&').replace(/\\.{2,}/g, '.').replace(/(?!^)-/g, '').replace(/^(\\d+)(\\.\\d{0,8})?$/, '$1$2').replace(/^0+(?!\\.|$)/g, ''),value = Number(value) < -9999999 ? -9999999 : (Number(value) > 9999999 ? 9999999 : value)",
                                                                sunyunFormat:
                                                                  function (v) {
                                                                    return _vm.$syInput(
                                                                      1,
                                                                      v
                                                                    )
                                                                  },
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onkeyupUnitQty(
                                                                    scope.row
                                                                  )
                                                                },
                                                              },
                                                              nativeOn: {
                                                                keydown: [
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "up",
                                                                        38,
                                                                        $event.key,
                                                                        [
                                                                          "Up",
                                                                          "ArrowUp",
                                                                        ]
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    $event.preventDefault()
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "unitQty",
                                                                      scope.$index,
                                                                      _vm
                                                                        .canKeyTableColumnList[7]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "down",
                                                                        40,
                                                                        $event.key,
                                                                        [
                                                                          "Down",
                                                                          "ArrowDown",
                                                                        ]
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    $event.preventDefault()
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "unitQty",
                                                                      scope.$index,
                                                                      _vm
                                                                        .canKeyTableColumnList[7]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "left",
                                                                        37,
                                                                        $event.key,
                                                                        [
                                                                          "Left",
                                                                          "ArrowLeft",
                                                                        ]
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    if (
                                                                      "button" in
                                                                        $event &&
                                                                      $event.button !==
                                                                        0
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    $event.preventDefault()
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "unitQty",
                                                                      scope.$index,
                                                                      _vm
                                                                        .canKeyTableColumnList[7]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "right",
                                                                        39,
                                                                        $event.key,
                                                                        [
                                                                          "Right",
                                                                          "ArrowRight",
                                                                        ]
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    if (
                                                                      "button" in
                                                                        $event &&
                                                                      $event.button !==
                                                                        2
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    $event.preventDefault()
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "unitQty",
                                                                      scope.$index,
                                                                      _vm
                                                                        .canKeyTableColumnList[7]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      !$event.type.indexOf(
                                                                        "key"
                                                                      ) &&
                                                                      _vm._k(
                                                                        $event.keyCode,
                                                                        "enter",
                                                                        13,
                                                                        $event.key,
                                                                        "Enter"
                                                                      )
                                                                    ) {
                                                                      return null
                                                                    }
                                                                    $event.preventDefault()
                                                                    return _vm.handleTableKeydown(
                                                                      $event,
                                                                      scope.row,
                                                                      "unitQty",
                                                                      scope.$index,
                                                                      _vm
                                                                        .canKeyTableColumnList[7]
                                                                        .columnindex
                                                                    )
                                                                  },
                                                                ],
                                                              },
                                                              model: {
                                                                value:
                                                                  scope.row
                                                                    .unitQty,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      scope.row,
                                                                      "unitQty",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "scope.row.unitQty",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3054797088
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#ff4949",
                                                      },
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.qtyLabel) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      ["130102", "130103"].includes(
                                        _vm.billType
                                      )
                                        ? _c("el-table-column", {
                                            attrs: {
                                              label: "库存数",
                                              align: "center",
                                              "min-width": "120",
                                              prop: "stockQtyText",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.TypeBill != "1000" &&
                                      _vm.billType != "130105" &&
                                      _vm.billType != "130101" &&
                                      _vm.billType != "140309"
                                        ? _c("el-table-column", {
                                            attrs: {
                                              label:
                                                "" +
                                                (_vm.billType == 130107
                                                  ? "盘盈采购单价(元)"
                                                  : _vm.billType == 130108
                                                  ? "盘亏采购单价(元)"
                                                  : "单价(元)"),
                                              align: "center",
                                              width: "140",
                                              prop: "unitPrice",
                                              "show-overflow-tooltip": "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.TypeBill != "1000" &&
                                      _vm.billType != "130105" &&
                                      _vm.billType != "130101" &&
                                      _vm.billType != "140309"
                                        ? _c("el-table-column", {
                                            attrs: {
                                              label: _vm.moneyLabel,
                                              align: "center",
                                              prop: "unitMoney",
                                              width: "140",
                                            },
                                          })
                                        : _vm._e(),
                                      ["130107", "130108"].includes(
                                        _vm.billType
                                      )
                                        ? _c("el-table-column", {
                                            attrs: {
                                              prop: "unitSalePrice",
                                              label:
                                                "" +
                                                (_vm.billType == 130107
                                                  ? "盘盈销售单价(元)"
                                                  : _vm.billType == 130108
                                                  ? "盘亏销售单价(元)"
                                                  : ""),
                                              align: "center",
                                              width: "200",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row
                                                              .unitSalePrice
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3061340141
                                            ),
                                          })
                                        : _vm._e(),
                                      ["130107", "130108"].includes(
                                        _vm.billType
                                      )
                                        ? _c("el-table-column", {
                                            attrs: {
                                              prop: "unitSaleMoney",
                                              label:
                                                "" +
                                                (_vm.billType == 130107
                                                  ? "盘盈销售金额"
                                                  : _vm.billType == 130108
                                                  ? "盘亏销售金额"
                                                  : ""),
                                              align: "center",
                                              width: "200",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.row
                                                              .unitSaleMoney
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              9757520
                                            ),
                                          })
                                        : _vm._e(),
                                      ["130105"].includes(_vm.billType) &&
                                      _vm.form.billCustomType == 2
                                        ? _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "调拨单价金额",
                                                align: "center",
                                                "min-width": "150",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "调拨单价(元)",
                                                  align: "center",
                                                  width: "120",
                                                  prop: "unitPrice",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                prop:
                                                                  "details." +
                                                                  scope.$index +
                                                                  ".unitPrice",
                                                                rules:
                                                                  _vm.rules[
                                                                    "details.unitPrice"
                                                                  ],
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                ref:
                                                                  "unitPrice" +
                                                                  "_" +
                                                                  scope.$index +
                                                                  "_" +
                                                                  _vm
                                                                    .canKeyTableColumnList[8]
                                                                    .columnindex,
                                                                attrs: {
                                                                  type: "number",
                                                                  disabled:
                                                                    _vm.disabled,
                                                                  maxlength:
                                                                    "7",
                                                                  sunyunFormat:
                                                                    function (
                                                                      v
                                                                    ) {
                                                                      return _vm.$syInput(
                                                                        2,
                                                                        v
                                                                      )
                                                                    },
                                                                },
                                                                nativeOn: {
                                                                  mousewheel:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                    },
                                                                  DOMMouseScroll:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                    },
                                                                  keyup:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onkeyupUnitQty(
                                                                        scope.row
                                                                      )
                                                                    },
                                                                  keydown: [
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "up",
                                                                          38,
                                                                          $event.key,
                                                                          [
                                                                            "Up",
                                                                            "ArrowUp",
                                                                          ]
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      $event.preventDefault()
                                                                      return _vm.handleTableKeydown(
                                                                        $event,
                                                                        scope.row,
                                                                        "unitPrice",
                                                                        scope.$index,
                                                                        _vm
                                                                          .canKeyTableColumnList[8]
                                                                          .columnindex
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "down",
                                                                          40,
                                                                          $event.key,
                                                                          [
                                                                            "Down",
                                                                            "ArrowDown",
                                                                          ]
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      $event.preventDefault()
                                                                      return _vm.handleTableKeydown(
                                                                        $event,
                                                                        scope.row,
                                                                        "unitPrice",
                                                                        scope.$index,
                                                                        _vm
                                                                          .canKeyTableColumnList[8]
                                                                          .columnindex
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "left",
                                                                          37,
                                                                          $event.key,
                                                                          [
                                                                            "Left",
                                                                            "ArrowLeft",
                                                                          ]
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      if (
                                                                        "button" in
                                                                          $event &&
                                                                        $event.button !==
                                                                          0
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      $event.preventDefault()
                                                                      return _vm.handleTableKeydown(
                                                                        $event,
                                                                        scope.row,
                                                                        "unitPrice",
                                                                        scope.$index,
                                                                        _vm
                                                                          .canKeyTableColumnList[8]
                                                                          .columnindex
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "right",
                                                                          39,
                                                                          $event.key,
                                                                          [
                                                                            "Right",
                                                                            "ArrowRight",
                                                                          ]
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      if (
                                                                        "button" in
                                                                          $event &&
                                                                        $event.button !==
                                                                          2
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      $event.preventDefault()
                                                                      return _vm.handleTableKeydown(
                                                                        $event,
                                                                        scope.row,
                                                                        "unitPrice",
                                                                        scope.$index,
                                                                        _vm
                                                                          .canKeyTableColumnList[8]
                                                                          .columnindex
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "enter",
                                                                          13,
                                                                          $event.key,
                                                                          "Enter"
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      $event.preventDefault()
                                                                      return _vm.handleTableKeydown(
                                                                        $event,
                                                                        scope.row,
                                                                        "unitPrice",
                                                                        scope.$index,
                                                                        _vm
                                                                          .canKeyTableColumnList[8]
                                                                          .columnindex
                                                                      )
                                                                    },
                                                                  ],
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .unitPrice,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "unitPrice",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.unitPrice",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3527577501
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "调拨金额(元)",
                                                  align: "center",
                                                  width: "120",
                                                  prop: "unitMoney",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      [
                                        "130101",
                                        "140309",
                                        "170309",
                                        "170409",
                                      ].includes(_vm.billType)
                                        ? [
                                            _c(
                                              "el-table-column",
                                              {
                                                attrs: {
                                                  align: "center",
                                                  width: "120",
                                                  prop: "unitQty",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                prop:
                                                                  "details[" +
                                                                  scope.$index +
                                                                  "].unitQty",
                                                                rules:
                                                                  _vm.rules[
                                                                    "details.unitQty"
                                                                  ],
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                ref:
                                                                  "unitQty1" +
                                                                  "_" +
                                                                  scope.$index +
                                                                  "_" +
                                                                  _vm
                                                                    .canKeyTableColumnList[9]
                                                                    .columnindex,
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.disabled,
                                                                  oninput:
                                                                    "value=value.replace(/[^\\d.-]/g, '').replace(/^-?\\d*\\.?\\d{0,8}.*$/, '$&').replace(/\\.{2,}/g, '.').replace(/(?!^)-/g, '').replace(/^(\\d+)(\\.\\d{0,8})?$/, '$1$2').replace(/^0+(?!\\.|$)/g, ''),value = Number(value) < -9999999 ? -9999999 : (Number(value) > 9999999 ? 9999999 : value)",
                                                                  sunyunFormat:
                                                                    function (
                                                                      v
                                                                    ) {
                                                                      return _vm.$syInput(
                                                                        1,
                                                                        v
                                                                      )
                                                                    },
                                                                },
                                                                on: {
                                                                  blur: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.checkTableChange(
                                                                      "unitQty",
                                                                      scope.$index
                                                                    )
                                                                  },
                                                                },
                                                                nativeOn: {
                                                                  keydown: [
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "up",
                                                                          38,
                                                                          $event.key,
                                                                          [
                                                                            "Up",
                                                                            "ArrowUp",
                                                                          ]
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      $event.preventDefault()
                                                                      return _vm.handleTableKeydown(
                                                                        $event,
                                                                        scope.row,
                                                                        "unitQty1",
                                                                        scope.$index,
                                                                        _vm
                                                                          .canKeyTableColumnList[9]
                                                                          .columnindex
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "down",
                                                                          40,
                                                                          $event.key,
                                                                          [
                                                                            "Down",
                                                                            "ArrowDown",
                                                                          ]
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      $event.preventDefault()
                                                                      return _vm.handleTableKeydown(
                                                                        $event,
                                                                        scope.row,
                                                                        "unitQty1",
                                                                        scope.$index,
                                                                        _vm
                                                                          .canKeyTableColumnList[9]
                                                                          .columnindex
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "left",
                                                                          37,
                                                                          $event.key,
                                                                          [
                                                                            "Left",
                                                                            "ArrowLeft",
                                                                          ]
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      if (
                                                                        "button" in
                                                                          $event &&
                                                                        $event.button !==
                                                                          0
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      $event.preventDefault()
                                                                      return _vm.handleTableKeydown(
                                                                        $event,
                                                                        scope.row,
                                                                        "unitQty1",
                                                                        scope.$index,
                                                                        _vm
                                                                          .canKeyTableColumnList[9]
                                                                          .columnindex
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "right",
                                                                          39,
                                                                          $event.key,
                                                                          [
                                                                            "Right",
                                                                            "ArrowRight",
                                                                          ]
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      if (
                                                                        "button" in
                                                                          $event &&
                                                                        $event.button !==
                                                                          2
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      $event.preventDefault()
                                                                      return _vm.handleTableKeydown(
                                                                        $event,
                                                                        scope.row,
                                                                        "unitQty1",
                                                                        scope.$index,
                                                                        _vm
                                                                          .canKeyTableColumnList[9]
                                                                          .columnindex
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "enter",
                                                                          13,
                                                                          $event.key,
                                                                          "Enter"
                                                                        )
                                                                      ) {
                                                                        return null
                                                                      }
                                                                      $event.preventDefault()
                                                                      return _vm.handleTableKeydown(
                                                                        $event,
                                                                        scope.row,
                                                                        "unitQty1",
                                                                        scope.$index,
                                                                        _vm
                                                                          .canKeyTableColumnList[9]
                                                                          .columnindex
                                                                      )
                                                                    },
                                                                  ],
                                                                },
                                                                model: {
                                                                  value:
                                                                    scope.row
                                                                      .unitQty,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "unitQty",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row.unitQty",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2590112049
                                                ),
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "header" },
                                                  [
                                                    _c(
                                                      "i",
                                                      {
                                                        staticStyle: {
                                                          color: "#ff4949",
                                                        },
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                    _vm._v(" 盘点数 "),
                                                  ]
                                                ),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "el-table-column",
                                              {
                                                attrs: {
                                                  label: "盘点进价金额",
                                                  align: "center",
                                                  "min-width": "150",
                                                },
                                              },
                                              [
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "基本单位进价(元)",
                                                    align: "center",
                                                    width: "150",
                                                    prop: "basUnitPurPrice",
                                                  },
                                                }),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "进价金额(元)",
                                                    align: "center",
                                                    width: "120",
                                                    prop: "basUnitPurMoney",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-table-column",
                                              {
                                                attrs: {
                                                  label: "盘点售价金额",
                                                  align: "center",
                                                  "min-width": "150",
                                                },
                                              },
                                              [
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "基本单位售价(元)",
                                                    align: "center",
                                                    width: "150",
                                                    prop: "basUnitSalePrice",
                                                  },
                                                }),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "售价金额(元)",
                                                    align: "center",
                                                    width: "120",
                                                    prop: "basUnitSaleMoney",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "盘盈数量",
                                                align: "center",
                                                prop: "basUnitAdjustQty",
                                                width: "120",
                                                "show-overflow-tooltip": "",
                                              },
                                            }),
                                            _c(
                                              "el-table-column",
                                              {
                                                attrs: {
                                                  label: "盘盈金额",
                                                  align: "center",
                                                  "min-width": "150",
                                                },
                                              },
                                              [
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "进价金额(元)",
                                                    align: "center",
                                                    width: "150",
                                                    prop: "basUnitAdjustPurPrice",
                                                  },
                                                }),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "售价金额(元)",
                                                    align: "center",
                                                    width: "120",
                                                    prop: "basUnitAdjustSalePrice",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "盘亏数量",
                                                align: "center",
                                                prop: "basUnitLoseQty",
                                                width: "120",
                                                "show-overflow-tooltip": "",
                                              },
                                            }),
                                            _c(
                                              "el-table-column",
                                              {
                                                attrs: {
                                                  label: "盘亏金额",
                                                  align: "center",
                                                  "min-width": "150",
                                                },
                                              },
                                              [
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "进价金额(元)",
                                                    align: "center",
                                                    width: "150",
                                                    prop: "basUnitLosePurPrice",
                                                  },
                                                }),
                                                _c("el-table-column", {
                                                  attrs: {
                                                    label: "售价金额(元)",
                                                    align: "center",
                                                    width: "120",
                                                    prop: "basUnitLoseSalePrice",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                      ["130104"].includes(_vm.billType)
                                        ? _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                label: "成本金额",
                                                align: "center",
                                                "min-width": "150",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "单位成本价",
                                                  align: "center",
                                                  prop: "unitCostPrice",
                                                  "show-overflow-tooltip": "",
                                                  width: "120",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$syInput(
                                                                  2,
                                                                  scope.row
                                                                    .unitCostPrice
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  557972865
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "单位成本额",
                                                  align: "center",
                                                  prop: "unitCostMoney",
                                                  "show-overflow-tooltip": "",
                                                  width: "120",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$syInput(
                                                                  3,
                                                                  scope.row
                                                                    .unitCostMoney
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  48480029
                                                ),
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._t("tableSpecial"),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "备注",
                                          align: "center",
                                          "min-width": "200",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  !["130106"].includes(
                                                    _vm.billType
                                                  )
                                                    ? _c("el-input", {
                                                        ref:
                                                          "remark" +
                                                          "_" +
                                                          scope.$index +
                                                          "_" +
                                                          _vm
                                                            .canKeyTableColumnList[10]
                                                            .columnindex,
                                                        attrs: {
                                                          disabled:
                                                            _vm.disabled &&
                                                            (_vm.disabledRemark ===
                                                              undefined ||
                                                              _vm.disabledRemark ===
                                                                true),
                                                        },
                                                        nativeOn: {
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleTableKeydown(
                                                              $event,
                                                              scope.row,
                                                              "remark",
                                                              scope.$index,
                                                              _vm
                                                                .canKeyTableColumnList[10]
                                                                .columnindex
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.remark,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "remark",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.remark",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  ["130106"].includes(
                                                    _vm.billType
                                                  )
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row.remark
                                                          )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2256197134
                                        ),
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "store-footer" },
              [
                _c("accessoryUpload", {
                  attrs: {
                    disabled:
                      _vm.disabled &&
                      (_vm.disabledBtn === undefined ||
                        _vm.disabledBtn === true),
                    fileList: _vm.form.fileItems,
                  },
                  on: {
                    getFileItems: _vm.getFileItemsData,
                    delFileItems: _vm.delFileItems,
                  },
                }),
                _c(
                  "div",
                  { staticClass: "right-remark" },
                  [
                    _c("span", [_vm._v("单据备注:")]),
                    _c("el-input", {
                      staticClass: "input",
                      attrs: {
                        placeholder: "备注",
                        disabled:
                          _vm.disabled &&
                          (_vm.disabledRemark === undefined ||
                            _vm.disabledRemark === true),
                        type: "textarea",
                        rows: 3,
                      },
                      model: {
                        value: _vm.form.billRemark,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "billRemark", $$v)
                        },
                        expression: "form.billRemark",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("SelectGoods", {
          attrs: {
            OpenGoods: _vm.OpenGoods,
            queryCode: _vm.queryCode,
            isStore: _vm.isNewFetchGoods,
            extraParams: _vm.selectGoodsExtraParams,
          },
          on: {
            "update:OpenGoods": function ($event) {
              _vm.OpenGoods = $event
            },
            "update:open-goods": function ($event) {
              _vm.OpenGoods = $event
            },
            getGoodsIds: _vm.getGoods,
          },
        }),
        _c("SelectBills", {
          attrs: {
            title: _vm.title,
            NoFields: _vm.NoFields,
            tableColumnFields: _vm.tableColumnFields,
            billList: _vm.billList,
            openDialog: _vm.openBillDia,
            loadingDia: _vm.loadingDia,
            billTotal: _vm.billTotal,
            allBillList: _vm.allBillList,
            billType: _vm.billType,
          },
          on: {
            "update:openDialog": function ($event) {
              _vm.openBillDia = $event
            },
            "update:open-dialog": function ($event) {
              _vm.openBillDia = $event
            },
            getSelect: _vm.storeSelectBills,
            getAllBills: _vm.getSelectBills,
            getBillsIds: function ($event) {
              return _vm.getSelectIds($event, true)
            },
            search: function (searchObj) {
              return _vm.storeSelectBills(searchObj)
            },
          },
        }),
        _c("Dialog", {
          attrs: { options: _vm.dialogOptions },
          on: {
            "update:options": function ($event) {
              _vm.dialogOptions = $event
            },
            handleEvent: _vm.handleEvent,
          },
        }),
        _c("to-channel", {
          attrs: { visible: _vm.openToChannel, downloadFile: _vm.downloadFile },
          on: {
            "update:visible": function ($event) {
              _vm.openToChannel = $event
            },
            getFileUrl: _vm.getFileUrl,
            upload: _vm.uploadFile,
          },
        }),
        _c("importError", {
          attrs: { errVisible: _vm.errVisible, errorList: _vm.errorList },
          on: {
            "update:errVisible": function ($event) {
              _vm.errVisible = $event
            },
            "update:err-visible": function ($event) {
              _vm.errVisible = $event
            },
          },
        }),
      ],
      1
    ),
    ["130101", "140309", "170309", "170409"].includes(_vm.billType)
      ? _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "left" }),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("单据金额 :")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.billMoney)),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }