<!-- 应收账款明细表 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
      <!-- <el-table-column type="expand" fixed slot="expand">
        <template slot-scope="props"> -->
      <!-- props.row -->
      <!-- </template>
      </el-table-column> -->
    </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { listShouldReceiveFundDetailOfSubtotal, listShouldReceiveFundDetailByPartnerId, sumListShouldReceiveFundDetail } from "@/api/arap/index";
import { getUid } from "@/utils";
export default {
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "90d198f4-2f95-aafd-09d6-f1830b87669613",
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        exportOption: {
          fastExportUrl: "/api/system/finance/book/detail/exportListShouldReceiveFundDetail",
          exportName: "应收账款明细表",
        },
        treeTableLoadOptions:{
          lazy:true,
          partnerId:'partnerId',
          rowKey: "rowKey",
          loadApi:listShouldReceiveFundDetailByPartnerId
        },
        getListApi: listShouldReceiveFundDetailOfSubtotal,
        getSummaryApi: sumListShouldReceiveFundDetail,
        getDataKey: (res) => {
          let rows = res.rows.map((x, index) => ({ ...x, parentIndex: index + 1, hasChildren: true,rowKey:getUid() }))
          return {
            list: rows,
            total: res.total,
          };
        },
        body: {},
        // listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "rowKey",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/创建人/修改人/审核人",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "createBys", label: "创建人" },
              { filter: "updateBys", label: "修改人" },
              { filter: "auditBys", label: "审核人" },
            ],
          },
          this.$select({
            key: "listCustomer",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSimpleQuery",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSaleEmployee",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "parentIndex",
            label: "序号",
            align: "left",
            minWidth: 100,
          },
          {
            prop: "areaName",
            label: "客供区域",
            formatter: (v, row) =>
              row.partnerInfo?.areaName ? row.partnerInfo?.areaName : "",
            minWidth: 120,
          },
          {
            prop: "groupName",
            label: "客供分组",
            formatter: (v, row) =>
              row.partnerInfo?.groupName ? row.partnerInfo?.groupName : "",
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "客户编号",
            formatter: (v, row) =>
              row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : "",
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) =>
              row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : "",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
          },
          {
            prop: "billTypeName",
            label: "单据类型",
            minWidth: 165,
          },
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 180,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 140,
          },
          {
            prop: "preRpMoney",
            label: "预收金额",
            minWidth: 120,
          },
          {
            prop: "preWriteOffMoney",
            label: "预收核销金额",
            minWidth: 120,
          },
          {
            prop: "psRpMoney",
            label: "销售应收金额",
            minWidth: 120,
          },
          {
            prop: "totalRpMoney",
            label: "应收合计金额",
            minWidth: 120,
          },
          {
            prop: "psWriteOffMoney",
            label: "销售款核销金额",
            minWidth: 120,
          },
          {
            prop: "totalWriteOffMoney",
            label: "应收核销金额",
            minWidth: 120,
          },
          {
            prop: "currFactRpMoney",
            label: "本期实收金额",
            minWidth: 120,
          },
          {
            prop: "preRpBalance",
            label: "预收余额",
            minWidth: 120,
          },
          {
            prop: "psRpBalance",
            label: "销售应收余额",
            minWidth: 120,
          },
          {
            prop: "otherRpMoney",
            label: "其他应收金额",
            minWidth: 120,
          },
          {
            prop: "otherWriteOffMoney",
            label: "其他应收核销金额",
            minWidth: 130,
          },
          {
            prop: "otherRpBalance",
            label: "其他应收余额",
            minWidth: 120,
          },
          {
            prop: "totalRpBalance",
            label: "余额",
            minWidth: 120,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 120,
          },
          {
            prop: "totalFactRpMoney",
            label: "实际收款金额",
            minWidth: 120,
          },
          {
            prop: "deptNo",
            label: "部门编码",
            formatter: (v, row) => (row.deptInfo?.deptNo ? row.deptInfo?.deptNo : ""),
            minWidth: 120,
          },
          {
            prop: "deptName",
            label: "部门名称",
            formatter: (v, row) => (row.deptInfo?.deptName ? row.deptInfo?.deptName : ""),
            minWidth: 120,
          },
          {
            prop: "employeeNo",
            label: "业务员编码",
            formatter: (v, row) =>
              row.saleEmployeeInfo?.employeeNo ? row.saleEmployeeInfo?.employeeNo : "",
            minWidth: 120,
          },
          {
            prop: "employeeName",
            label: "业务员名称",
            formatter: (v, row) =>
              row.saleEmployeeInfo?.employeeName
                ? row.saleEmployeeInfo?.employeeName
                : "",
            minWidth: 120,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
        ],
        summary: ['preRpMoney', 'preWriteOffMoney', 'psRpMoney', 'totalRpMoney', 'psWriteOffMoney', 'totalWriteOffMoney', 'currFactRpMoney', 'preRpBalance', 'psRpBalance', 'otherRpMoney', 'otherWriteOffMoney', 'otherRpBalance', 'totalRpBalance', 'discountMoney', 'totalFactRpMoney'],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table__cell {
  .cell {
    display: flex;
  }
}
.demo-table-expand {
  font-size: 0;
}
::v-deep .demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
::v-deep .demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 20%;
}
</style>
