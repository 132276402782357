<template>
  <div>
    <div class="im-box" :style="{
            marginBottom:`-${data.coverage}px`,
            padding: data.type > 1 ? '40px 10px 0px 10px' : '',
            background: data.type === 2 ? `linear-gradient(to bottom, ${data.contentList[active].title} 0%, #f2f2f2 40%)` : '',
        }">
      <div v-if="data.type === 3" :style="{
            backgroundColor: data.contentList[active].title,
            borderBottomLeftRadius: data.type === 3 ? '15px' : '',
            borderBottomRightRadius: data.type === 3 ? '15px' : '',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '200px',
            width: '100%',
        }">

      </div>
      <!-- 内容 -->
      <div :style="{
                margin:
                    data.marT +
                    'px ' +
                    data.marR +
                    'px ' +
                    data.marB +
                    'px ' +
                    data.marL +
                    'px',
                    overflow: 'hidden',
                    borderRadius: data.borRadius + 'px',
            }">
        <!-- 内容 -->
        <div class="warp x-fc" :style="{
                    borderRadius: data.borRadius + 'px',
                    // backgroundColor: data.style.bgColor
                }">
          <!-- <div class="flex1 x-fc" v-for="(item, index) in data.contentList" :key="index">
                        <div>
                            <el-image :src="item.icon" fut="cover" />
                        </div>
                    </div> -->
          <div class="carousel-container">
            <div class="carousel-inner">
              <div v-if="data.carouselType == 2" style="position: relative">
                <div class="qm-banner-bgImage uperUderImg">
                  <div class="aaaa" v-for="(item,index) in underImgList" :style="{'opacity':item.opacity }"
                    :key='index'>
                    <el-image mode="widthFix" lazy-load :src="item.icon" style="width: 100%;display: block;"
                      :style="{height:carouselHeight}"></el-image>
                  </div>
                </div>
                <el-carousel @change="ontransition" class="v-qm-banner" :height="carouselHeight"
                  :interval="data.style.interval">
                  <el-carousel-item class="swiper-item" v-for="(item, index) in data.contentList" :key="index">
                    <el-image style="display: block" :src="item.bgIcon"
                      @load="event => updateCarouselHeight(event, index)"
                      :style="{height:carouselHeight,width:'100%'}"></el-image>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div v-else>
                <el-carousel :height="carouselHeight" @change="changeImg" v-if="this.data.isIcon">
                  <el-carousel-item v-for="(item, index) in data.contentList" :key="index">
                    <el-image :src="item.icon" @load="event => updateCarouselHeight(event, index)" fit="cover"
                      :style="{borderRadius: data.borRadius + 'px'}" />
                  </el-carousel-item>
                </el-carousel>
                <el-carousel :height="carouselHeight" @change="changeImg" v-if="!this.data.isIcon">
                  <el-carousel-item v-for="(item, index) in data.contentList" :key="index" :label="index + 1">
                    <el-image :src="item.icon" @load="event => updateCarouselHeight(event, index)" fit="cover"
                      :style="{borderRadius: data.borRadius + 'px'}" />
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deepCopy } from '@/utils'
export default {
  name: 'anniuzu',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      underImgList: [],
      carouselHeight: 'auto',
      bgColor: 0,
      active: 0,

    }
  },
  created() {
    console.log('----data------', this.data);
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        if (this.data.carouselType == 2) {
          this.underImgList = this.data?.contentList?.map((v, i) => ({ index: i, bgIcon: v.bgIcon, opacity: i == 0 ? 1 : 0, icon: v.icon }))
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    changeImg(v) {
      this.active = v
    },
    ontransition(data) {
      let underImgList = this.data?.contentList ? deepCopy(this.data.contentList) : []
      this.underImgList = underImgList.map((v, i) => ({ ...v, opacity: data == i ? 1 : 0 }))
    },
    updateCarouselHeight(event, index) {
      if (index === 0) {
        this.carouselHeight = event.target.height + 'px';
        this.data.height = event.target.height
        this.$emit('setTemp', this.data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//内容
// .warp {
//     height: 48px;
// }
.im-box {
  // padding: 40px 10px 0px 10px;
}
.custom-indicator {
  margin: 5px;
  background-color: #909399;
  color: #fff;
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
}

.custom-indicator.is-active {
  background-color: #409eff;
}
// ::v-deep .el-carousel__container {
//     max-height: 625px;
// }
.carousel-container {
  width: 100%;
}

.carousel-inner {
  width: 100%;
}

el-carousel {
  width: 100%;
  display: block;
}

el-image {
  display: block;
  width: 100%;
}

.v-qm-banner {
  background: transparent;
}
.qm-banner-bgImage {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 99;
}

.uperUderImg {
  width: 100%;
  height: 100%;
}

.aaaa {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  transform: translate(0%, 0) translateZ(0);
  transition: 1s;
}
</style>
