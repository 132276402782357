<template>
  <div class="btnTOPCom x-c-end">
    <slot name="infrontSpecialDiyBtn"></slot>
    <el-button
      type="primary"
      v-if="isShowImg"
      @click="$emit('synchronousImg', true)"
      size="mini"
      >同步图片</el-button
    >
    <el-button
      type="primary"
      v-if="isShowUnit"
      @click="$emit('synchronousUnit', true)"
      size="mini"
      >同步规格加值属性</el-button
    >
    <el-button
      v-if="isSubmitBtn"
      type="primary"
      size="mini"
      :disabled="disabled"
      @click="$emit('submitForm', true)"
      >{{ submitText || "保存" }}
    </el-button>
    <el-button
      v-if="isSubmitAddBtn"
      type="primary"
      size="mini"
      :disabled="disabled"
      @click="$emit('addBill')"
      >保存并新增
    </el-button>
    <el-button
      size="mini"
      :disabled="showAudit"
      @click="$emit('auditBill', '审核')"
      v-if="isExamineBtn"
    >
      审核
    </el-button>
    <el-dropdown
      v-if="isAuditBillBtn && !isExamineBtn"
      class="marL10 marR10"
      split-button
      @click="$emit('auditBill', '审核')"
      icon="el-icon-circle-check"
      :disabled="showAudit"
      @command="$emit('auditBill', '反审核')"
      size="mini"
    >
      审核
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="反审核">反审核</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dropdown
      v-if="isStopBtn"
      class="marR10"
      :class="{ marL10: isExamineBtn }"
      split-button
      @click="$emit('isStop', '启用')"
      icon="el-icon-circle-check"
      :disabled="showIsStop"
      @command="$emit('isStop', '禁用')"
      size="mini"
    >
      启用
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="禁用">禁用</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-button
      v-if="isAddBtn"
      type="primary"
      icon="el-icon-plus"
      size="mini"
      @click="$emit('handleAdd')"
      >新增</el-button
    >
    <el-button @click="$emit('getImportOrder')" v-if="isImportOrderBtn" size="mini">
      引入源单
    </el-button>
    <el-button
      v-if="isClearDetailsBtn"
      type="primary"
      size="mini"
      :disabled="disabled"
      @click="$emit('getClearDetails')"
      >清除明细
    </el-button>
    <el-button
      v-if="isZeroClearingBtn"
      type="primary"
      size="mini"
      :disabled="disabled"
      @click="$emit('getZeroClearing')"
      >清零
    </el-button>
    <!-- 特殊自定义按钮  -->
    <slot name="specialDiyBtn"></slot>
    <el-button v-if="isReset" @click="$emit('reset')" size="mini">重置</el-button>
    <el-button v-if="isQuitBtn" @click="$emit('getQuit')" size="mini">退出</el-button>
  </div>
</template>

<script>
export default {
  name: "topOperatingButton",
  props: {
    //清零
    isZeroClearingBtn: {
      type: Boolean,
      default: false,
    },
    //清除明细
    isClearDetailsBtn: {
      type: Boolean,
      default: false,
    },
    //按钮禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 同步规格
    isShowUnit: {
      type: Boolean,
      default: false,
    },
    // 同步图片
    isShowImg: {
      type: Boolean,
      default: false,
    },
    //审核/反审核按钮禁用
    showAudit: {
      type: Boolean,
      default: true,
    },
    //审核按钮
    isExamineBtn: {
      type: Boolean,
      default: false,
    },
    //保存按钮
    isSubmitBtn: {
      type: Boolean,
      default: true,
    },
    //保存并新增按钮
    isSubmitAddBtn: {
      type: Boolean,
      default: true,
    },
    //审核按钮
    isAuditBillBtn: {
      type: Boolean,
      default: true,
    },
    //重置按钮
    isReset: {
      type: Boolean,
      default: false,
    },
    //退出按钮
    isQuitBtn: {
      type: Boolean,
      default: true,
    },
    //新增按钮
    isAddBtn: {
      type: Boolean,
      default: false,
    },
    //启用按钮
    isStopBtn: {
      type: Boolean,
      default: false,
    },
    //启用按钮是否禁用
    showIsStop: {
      type: Boolean,
      default: false,
    },
    //引入源单
    isImportOrderBtn: {
      type: Boolean,
      default: false,
    },
    //保存
    submitText: {
      type: String,
      default: "保存",
    },
  },
};
</script>

<style scoped lang="scss">
.btnTOPCom {
  z-index: 999;
  background-color: #eaeaea;
  width: 100%;
  position: fixed;
  top: 84px;
  right: 0;
  padding: 6px 10px 6px 0;
  transition: all 0.3s ease-in-out;
}
</style>
