<!-- 客户对账列表 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { customerCheckListSubTotal, customerCheckListByPartnerId, sumCustomerCheckList } from "@/api/arap/index";
import { getUid } from "@/utils";
const typesList = ["190103", "120102", "120103", "190105", "190106", '140312'];
export default {
  name: "customerCheckBill",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "2099fbee-28de-9db6-5738-ff2d1deb3986",
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        treeTableLoadOptions: {
          lazy: true,
          partnerId: 'partnerId',
          rowKey: "rowKey",
          loadApi: customerCheckListByPartnerId
        },
        getListApi: customerCheckListSubTotal,
        getSummaryApi: sumCustomerCheckList,
        getDataKey: (res) => {
          let rows = res.rows.map((x, index) => ({ ...x, parentIndex: index + 1, hasChildren: true, rowKey: getUid() }))
          return {
            list: rows,
            total: res.total,
          };
        },
        exportOption: {
          show: true,
          exportApi: "exportCustomerCheckList",
          exportName: "客户对账单",
          timeout: 60 * 5 * 1000,
        },
        body: {},
        // listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "rowKey",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          this.$select({
            key: "listCustomer",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSimpleQuery",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSaleEmployee",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "checkAccountStatus",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "billTypeList",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
                dataKey: (res) => res.data.filter((x) => typesList.includes(x.dictValue)),
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "parentIndex",
            label: "序号",
            align: "left",
            minWidth: 100,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 120,
          },
          {
            prop: "billTypeName",
            label: "单据类型",
            minWidth: 120,
          },
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 180,
          },
          {
            prop: "checkAccountStatusName",
            label: "单据对账状态",
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "客户编号",
            formatter: (v, row) =>
              row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : "",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) =>
              row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : "",
            minWidth: 150,
          },
          {
            prop: "deptNo",
            label: "部门编码",
            formatter: (v, row) => (row.deptInfo?.deptNo ? row.deptInfo?.deptNo : ""),
            minWidth: 120,
          },
          {
            prop: "deptName",
            label: "部门名称",
            formatter: (v, row) => (row.deptInfo?.deptName ? row.deptInfo?.deptName : ""),
            minWidth: 120,
          },
          {
            prop: "employeeNo",
            label: "业务员编码",
            formatter: (v, row) =>
              row.saleEmployeeInfo?.employeeNo ? row.saleEmployeeInfo?.employeeNo : "",
            minWidth: 120,
          },
          {
            prop: "employeeName",
            label: "业务员名称",
            formatter: (v, row) =>
              row.saleEmployeeInfo?.employeeName
                ? row.saleEmployeeInfo?.employeeName
                : "",
            minWidth: 120,
          },
          {
            prop: "checkAccountBy",
            label: "最后对账人",
            minWidth: 120,
          },
          {
            prop: "checkAccountTime",
            label: "最近对账时间",
            minWidth: 120,
          },
          {
            prop: "billMoney",
            label: "单据金额",
            minWidth: 120,
          },
          {
            prop: "settleMoney",
            label: "已核销金额",
            minWidth: 120,
          },
          {
            prop: "notSettleMoney",
            label: "待收款金额",
            minWidth: 120,
          },
          {
            prop: "tranBalance",
            label: "往来余额",
            minWidth: 120,
          },
          {
            prop: "actualRpMoney",
            label: "实际收款金额",
            minWidth: 120,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 120,
          },
          {
            prop: "settlementDate",
            label: "结算日期",
            minWidth: 120,
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 140,
          },
          // {
          //   prop: "overDays",
          //   label: "超期天数",
          //   minWidth: 140,
          // },
        ],
        list: [],
        summary: ['billMoney', 'settleMoney', 'notSettleMoney', 'tranBalance', 'actualRpMoney', 'discountMoney']
      },
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
