<template>
  <div class="wrap" v-loading="loading" id="wrap" ref="wrap">
    <el-form ref="form" :model="options" :rules="rules" label-width="120px">
      <topOperatingButton
        :disabled="disableOperate"
        :isExamineBtn="![2, '2'].includes(options.billStatus)"
        :showAudit="isShowAudit"
        :isStopBtn="false"
        id="topOperatingButton"
        ref="topOperatingButton"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditForm"
        @getQuit="$router.go(-1)"
      >
      </topOperatingButton>
      <cardTitleCom cardTitle="基本信息" id="basicInformation" ref="basicInformation">
        <template slot="cardContent">
          <div class="x-f marT10">
            <el-form-item label="单据编号" prop="billNo">
              <el-input
                class="inputBut"
                size="mini"
                :disabled="true"
                v-model="options.billNo"
                placeholder="单据编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker
                class="inputBut"
                size="mini"
                v-model="options.billDate"
                type="date"
                placeholder="单据日期"
                :disabled="disableOperate"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="手工单据号" prop="handBillNo">
              <el-input
                class="inputBut"
                v-model="options.handBillNo"
                :disabled="disableOperate"
                placeholder="手工单据号"
                size="mini"
                oninput="value=value.replace(/[^0-9a-zA-Z]/g,'')"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品批号" prop="relateGoodsBatchNo">
              <el-input
                class="inputBut"
                v-model="options.relateGoodsBatchNo"
                :disabled="disableOperate"
                placeholder="商品批号"
                size="mini"
                oninput="value=value.replace(/[^0-9a-zA-Z]/g,'')"
              ></el-input>
            </el-form-item>
          </div>
          <div class="x-f">
            <el-form-item label="入库仓库" prop="inStoreId">
              <selectRemote
                :multiple="false"
                v-model="options.inStoreId"
                class="inputBut"
                :option="
                  $select({
                    key: 'listStore',
                    option: {
                      option: {
                        disabled: disableOperate,
                      },
                    },
                  }).option
                "
              >
              </selectRemote>
            </el-form-item>
            <el-form-item label="组装商品" prop="relateGoodsId">
              <selectRemote
                v-model="options.relateGoodsId"
                class="inputBut"
                :option="goodsOptions"
              >
              </selectRemote>
            </el-form-item>
            <el-form-item label="单位">
              <el-input
                class="inputBut"
                size="mini"
                :disabled="true"
                v-model="options.relateUnitName"
                placeholder="单位"
              ></el-input>
            </el-form-item>
            <el-form-item label="组装数量" prop="relateGoodsQty">
              <el-input
                class="inputBut"
                size="mini"
                @blur="inputBlur"
                oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^([0-9-, -]\d*\.?\d{0,2})?.*$/,'$1').replace(/^\./g, '').replace(/^0+(?!\.|$)/g, ''),value = Number(value) >= 9999999 ? 9999999 : value"
                :disabled="disableOperate"
                v-model="options.relateGoodsQty"
                placeholder="请输入优惠金额"
              ></el-input>
            </el-form-item>
            <el-form-item label="单据备注" prop="billRemark">
              <el-input
                size="mini"
                class="inputWidth"
                v-model="options.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 1 }"
                :disabled="disableOperate"
              />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="商品信息" class="options">
        <template slot="rightCardTitle">
          <div style="padding: 5px">
            <el-button
              type="primary"
              :disabled="disableOperate"
              size="mini"
              @click="modifyWarehouse()"
              >批量修改领出仓库</el-button
            >
          </div>
        </template>
        <template slot="cardContent">
          <div class="table">
            <EditTable :options="options" @handleEvent="handleEvent" />
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import selectRemote from "@/components/tablePage/select/select-remote/index.vue";
import {
  listSubGoods,
  addAssembleGoodsBill,
  modifyAssembleGoodsBill,
  modifyBillStatus,
  getAssembleGoodsBillDetail,
} from "@/api/combinStrip/index";

import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import EditTable from "@/components/editTable"; //可编辑表格
import Dialog from "@/components/Dialog";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import { getBillNo } from "@/api/codeRule";
import { uniqWith } from "lodash"; //去重
import { limitInputlength, fcount } from "@/utils";

export default {
  name: "combinBillDetail",
  components: {
    topOperatingButton,
    EditTable,
    cardTitleCom,
    Dialog,
    selectRemote,
  },
  watch: {
    "options.billStatus": {
      handler(val) {
        console.log(val, "val");
        this.disableOperate = ["2", "3", "4", 2, 3, 4].includes(val);
        this.isShowAudit = ["", 4, "4"].includes(val);
        this.options.columns[0].disabled = this.disableOperate;
        this.options.columns[3].disabled = this.disableOperate;
        this.options.columns[4].disabled = this.disableOperate;
        this.options.columns[6].disabled = this.disableOperate;
        this.options.columns[7].disabled = this.disableOperate;
        this.options.status = this.disableOperate;
      },
      immediate: true,
    },
  },
  data() {
    return {
      goodsOptions: this.$select({
        key: "mainGoods",
        option: {
          option: {
            disabled: this.disableOperate,
            remoteKey: (e) => {
              let array = e.rows.map((item) => ({
                ...item,
                mainGoodsName: item.relateGoodsInfo.goodsName,
                mainGoodsId: item.relateGoodsInfo.goodsId,
                mainGoodsNo: item.relateGoodsInfo.goodsNo,
                units: item.relateUnitInfos,
              }));
              return array;
            },
            change: (propValue, row) => {
              this.dataOperation(propValue, row, "change");
            },
            tableChange: (propValue, row) => {
              this.dataOperation(propValue, row, "tableChange");
            },
            buttons: [
              {
                type: "more",
                option: {
                  title: "选择商品",
                  width: 1250,
                  type: "TreeAndTable",
                  formData: {
                    ...this.$dialog({ key: "mainGoods" }),
                    table: {
                      ...this.$dialog({ key: "mainGoods" }).table,
                      radioSelect: true,
                      mutiSelect: false,
                    },
                  },
                },
              },
            ],
          },
        },
      }).option,
      disableOperate: false,
      isShowAudit: "",
      isAdd: "",
      customerData: [],
      deptData: [],
      employeeData: [],
      loading: false, //遮罩层
      //表格配置
      options: {
        // mutiSelect: false, //是否多选
        mutiSelect: true,
        radioSelect: false,
        check: [],
        relateUnitId: undefined,
        relateUnitName: undefined,
        billRemark: undefined,
        billNo: undefined,
        billDate: undefined,
        handBillNo: undefined,
        inStoreId: undefined,
        relateGoodsId: undefined,
        relateGoodsBatchNo: undefined,
        relateGoodsQty: undefined,

        billStatus: "",
        tableIndex: 0,
        retainColumn: true,
        curListIndex: -1, //当前表格行
        list: [{}],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: 570, //表格高度
        status: undefined, //单据状态
        // isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "goodsId",
            label: "商品名称",
            type: "remoteSelect",
            minWidth: 160,
            align: "center",
            option: this.$select({
              key: "listGoods",
              option: {
                option: {
                  remoteBody: { excludeGoodsTypes: [-1] },
                  change: (propValue, row) => {
                    console.log(propValue, row, "change");
                    this.optionsList(this.options.tableIndex, propValue, row);
                  },
                  tableChange: (propValue, row) => {
                    console.log(propValue, row, "tableChange");
                    this.optionsList(this.options.tableIndex, propValue, row.check);
                  },
                  buttons: [
                    {
                      type: "more",
                      option: {
                        title: "选择商品",
                        width: 1250,
                        type: "TreeAndTable",
                        formData: this.$dialog({
                          key: "goods",
                          option: {
                            queryParams: { excludeGoodsTypes: [-1] },
                            table: {
                              ...this.$dialog({ key: "goods" }).table,
                              radioSelect: true,
                              mutiSelect: false,
                            },
                          },
                        }),
                      },
                    },
                  ],
                },
              },
            }).option,
            disabled: false,
            rules: true,
          },
          {
            prop: "goodsNo",
            label: "商品编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "barcode",
            label: "商品条码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "outStoreId",
            label: "领出仓库",
            type: "remoteSelect",
            minWidth: 160,
            align: "center",
            option: this.$select({
              key: "listStore",
              option: {
                option: {
                  buttons: [
                    {
                      type: "more",
                      option: {
                        title: "选择仓库",
                        width: 1250,
                        type: "TreeAndTable",
                        formData: this.$dialog({
                          key: "store",
                          option: {
                            table: {
                              ...this.$dialog({ key: "store" }).table,
                              radioSelect: true,
                              mutiSelect: false,
                            },
                          },
                        }),
                      },
                    },
                  ],
                },
              },
            }).option,
            disabled: false,
            // rules: true
          },
          {
            prop: "batchNo",
            label: "商品批号",
            minWidth: 120,
            align: "center",
            type: "input",
            inputNum: 0,
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "unitId",
            label: "单位",
            type: "localSelect",
            minWidth: 120,
            align: "center",
            option: {
              rowDataKey: "units",
              label: "unitName",
              value: "unitId",
              filterable: true,
              change: (val, row) => {
                let tableIndex = this.options.tableIndex;
                // this.options.list[tableIndex].unitId = val
                this.numUnit(row, tableIndex);
              },
            },
            disabled: false,
          },
          {
            prop: "unitRelateQty",
            label: "单位用量",
            width: 120,
            type: "input",
            inputNum: this.$store.state.permission.inputConfig
              .system_BUSINESS_DATA_CONFIG_11,
            blurInput: "blurInput",
            align: "center",
          },
          {
            prop: "unitQty",
            label: "领出数量",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "unitPurPrice",
            label: "进价",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "unitPurMoney",
            label: "进价金额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "unitSalePrice",
            label: "售价",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "unitSaleMoney",
            label: "售价金额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            width: 120,
            type: "my-input",
            align: "center",
          },
        ],
        summary: ["unitQty", "unitPurMoney", "unitSaleMoney"],
      },
      dialogOptions: {
        title: "选择",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      rules: {
        billNo: [
          { required: true, message: "请输入方案编号", trigger: ["blur", "change"] },
        ],
        billDate: [{ required: true, message: "请输入单据日期", trigger: "blur" }],
        relateGoodsQty: [{ required: true, message: "请输入组装数量", trigger: "blur" }],
        relateGoodsId: [{ required: true, message: "请输入组装商品", trigger: "blur" }],
        inStoreId: [
          { required: true, message: "请输入组装商品", trigger: ["blur", "change"] },
        ],
      },
      ids: [],
    };
  },
  activated() {
    this.getDetail();
  },
  mounted() {
    this.getDetail();
  },
  beforeDestroy() {},
  created() {},
  computed: {},
  methods: {
    modifyWarehouse() {
      this.ids = this.options.check
        .map((item) => item.goodsId)
        .filter((ite) => typeof ite !== "undefined");
      if (this.ids.length == 0) return this.$message.warning("请选择商品");
      this.dialogOptions.title = "选择仓库";
      this.dialogOptions.formData = this.$dialog({ key: "store" });
      this.dialogOptions.show = true;
    },
    numUnit(row, tableIndex) {
      let relateGoodsQty = this.options.relateGoodsQty ? this.options.relateGoodsQty : 0;
      let unitRelateQty = this.options.list[tableIndex].unitRelateQty;
      let purPrice = row.purPrice;
      let salePrice = row.purPrice;
      if (unitRelateQty && relateGoodsQty) {
        let unitQty = fcount([unitRelateQty, relateGoodsQty], "*");
        this.$set(this.options.list[tableIndex], "unitPurPrice", purPrice);
        this.$set(this.options.list[tableIndex], "unitSalePrice", salePrice);
        this.$set(this.options.list[tableIndex], "unitQty", unitQty);
        this.$set(this.options.list[tableIndex], "goodsSpec", row.goodsSpec);
        this.$set(
          this.options.list[tableIndex],
          "unitPurMoney",
          fcount([unitQty, purPrice], "*")
        );
        this.$set(
          this.options.list[tableIndex],
          "unitSaleMoney",
          fcount([unitQty, salePrice], "*")
        );
      } else {
        this.$set(this.options.list[tableIndex], "unitPurPrice", purPrice);
        this.$set(this.options.list[tableIndex], "unitSalePrice", salePrice);
        this.$set(this.options.list[tableIndex], "unitQty", "");
        this.$set(this.options.list[tableIndex], "goodsSpec", row.goodsSpec);
        this.$set(this.options.list[tableIndex], "unitPurMoney", "");
        this.$set(this.options.list[tableIndex], "unitSaleMoney", "");
      }
    },
    inputBlur(val) {
      this.options.list = this.options.list.map((item) => ({
        ...item,
        unitQty: fcount(
          [
            this.options.relateGoodsQty ? this.options.relateGoodsQty : 0,
            item.unitRelateQty ? item.unitRelateQty : 0,
          ],
          "*"
        ),
        unitPurMoney: fcount(
          [
            item.unitPurPrice,
            fcount(
              [
                this.options.relateGoodsQty ? this.options.relateGoodsQty : 0,
                item.unitRelateQty ? item.unitRelateQty : 0,
              ],
              "*"
            ),
          ],
          "*"
        ),
        unitSaleMoney: fcount(
          [
            item.unitSalePrice,
            fcount(
              [
                this.options.relateGoodsQty ? this.options.relateGoodsQty : 0,
                item.unitRelateQty ? item.unitRelateQty : 0,
              ],
              "*"
            ),
          ],
          "*"
        ),
      }));
    },
    optionsList(tableIndex, val, row) {
      let obj = {
        goodsId: row.goodsId,
        goodsNo: row.goodsNo,
        goodsName: row.goodsName,
        barcode: row.barcode,
        outStoreId: "",
        batchNo: "",
        goodsSpec: row.goodsSpec,
        unitName: row.unitName,
        unitId: row.unitId,
        unitRelateQty: "",
        unitQty: "",
        unitPurPrice: row.purPrice,
        unitPurMoney: "",
        unitSalePrice: row.salePrice,
        unitSaleMoney: "",
        units: row.units,
        remark: "",
      };
      this.$set(this.options.list, tableIndex, obj);
    },
    async dataOperation(val, row, type) {
      console.log(val, row, type);
      let obj = {};
      if (type == "tableChange") {
        obj = JSON.parse(JSON.stringify(row.check));
        console.log(obj, "obj");
        this.options.relateGoodsId = obj.relateGoodsInfo.goodsId;
      } else if (type == "change") {
        obj = JSON.parse(JSON.stringify(row));
      }
      listSubGoods({ relateGoodsId: obj.relateGoodsId, relateUnitId: obj.relateUnitId })
        .then((res) => {
          this.options.relateGoodsQty = 1;
          this.options.relateUnitId = obj.relateUnitInfo.unitId;
          this.options.relateUnitName = obj.relateUnitInfo.unitName;
          if (res.rows.length == 0) {
            this.options.list = [{}];
            return;
          }
          this.options.list = res.rows.map((item) => ({
            ...item,
            goodsId: item.goodsInfo.goodsId,
            goodsName: item.goodsInfo.goodsName,
            barcode: item.unitInfo.barcode,
            outStoreId: "",
            batchNo: "",
            goodsSpec: item.unitInfo.goodsSpec,
            unitName: item.unitInfo.unitName,
            unitId: item.unitId,
            unitRelateQty: item.unitQty,
            unitQty: fcount([this.options.relateGoodsQty, item.unitQty], "*"),
            unitPurPrice: item.unitInfo.purPrice,
            purPrice: item.unitInfo.purPrice,
            unitPurMoney: fcount(
              [
                item.unitInfo.purPrice,
                fcount([this.options.relateGoodsQty, item.unitQty], "*"),
              ],
              "*"
            ),
            unitSalePrice: item.unitInfo.salePrice,
            salePrice: item.unitInfo.salePrice,
            unitSaleMoney: fcount(
              [
                item.unitInfo.salePrice,
                fcount([this.options.relateGoodsQty, item.unitQty], "*"),
              ],
              "*"
            ),
            units: item.unitInfos,
          }));
        })
        .catch((err) => {
          this.options.list = [{}];
        });
    },
    limitInputlength,
    //获取详情
    async getDetail() {
      this.reset();
      if (this.$route.query.type === "Update" && this.$route.query.billId) {
        this.loading = true;
        try {
          const { data } = await getAssembleGoodsBillDetail({
            billId: this.$route.query.billId,
          });
          //方案赋值
          this.goodsOptions.showItem = [
            {
              mainGoodsName: data.relateGoodsName,
              mainGoodsId: data.relateGoodsId,
            },
          ];
          this.options = {
            ...this.options,
            ...data,
            list: [],
          };
          this.options.relateUnitName = data.relateUnitInfo.unitName;
          this.options.relateUnitId = data.relateUnitId;
          this.options.list = data.details;
        } catch (err) {}
        this.loading = false;
      } else {
        this.getBillno();
      }
    },
    async handleEvent(type, row) {
      let contains = function (list, row) {
        var i = list.length;
        while (i--) {
          if (list[i] === row) {
            return i;
          }
        }
        return false;
      };
      // let listIndex = contains(this.sourceOptions.list, row)
      switch (type) {
        case "dialogChange":
          this.options.list = this.options.list.map((item) => ({
            ...item,
            outStoreId: this.ids.includes(item.goodsId)
              ? row.formData.table.check[0].storeId
              : item.outStoreId,
            hoverRow: false,
          }));
          this.options.check = [];
          break;
        case "tableIndex":
          console.log(type, row, "type, tableIndex row");
          this.options.tableIndex = row;
          break;
        case "blurInput":
          let relateGoodsQty = this.options.relateGoodsQty
            ? this.options.relateGoodsQty
            : 0;
          let tableIndex = row.index;
          let unitRelateQty = this.options.list[tableIndex].unitRelateQty;

          if (unitRelateQty && relateGoodsQty) {
            let unitQty = fcount([unitRelateQty, relateGoodsQty], "*");
            let purPrice = this.options.list[tableIndex].unitPurPrice;
            let salePrice = this.options.list[tableIndex].unitSalePrice;
            this.$set(this.options.list[tableIndex], "unitQty", unitQty);
            this.$set(
              this.options.list[tableIndex],
              "unitPurMoney",
              fcount([unitQty, purPrice], "*")
            );
            this.$set(
              this.options.list[tableIndex],
              "unitSaleMoney",
              fcount([unitQty, salePrice], "*")
            );
          } else {
            this.$set(this.options.list[tableIndex], "unitQty", "");
            this.$set(this.options.list[tableIndex], "unitPurMoney", "");
            this.$set(this.options.list[tableIndex], "unitSaleMoney", "");
          }

          break;
        case "clickAccount":
          //保存当前表格行下标
          this.options.curListIndex = contains(this.options.list, row);
          this.dialogOptions.show = true;
          //弹窗配置
          this.dialogOptions = {
            title: "选择账户",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "product" }),
          };
          break;
        default:
          break;
      }
    },
    filterDataFun(list) {
      let arrlist = list.map((item) => ({
        ...item,
        billDate: item.sourceBillDateStr,
        billTaxMoney: item.sourceBillMoney,
        billTypeName: item.sourceBillTypeName,
        billNo: item.sourceBillNo,
        remark: item.remark,
        billRemark: item.sourceBillRemark,
      }));
      return arrlist;
    },
    // 提交保存
    async submitForm(isAdd) {
      let isValid = this.$refs["form"].validate();
      if (!isValid) return this.$message.error("请输入必填项!");
      let form = {
        billNo: this.options.billNo,
        billId: this.options.billId,
        billDate: this.options.billDate ? this.options.billDate.split(" ")[0] : "",
        handBillNo: this.options.handBillNo,
        inStoreId: this.options.inStoreId,
        relateGoodsId: this.options.relateGoodsId,
        relateGoodsBatchNo: this.options.relateGoodsBatchNo,
        relateGoodsQty: this.options.relateGoodsQty,
        relateUnitId: this.options.relateUnitId,
        billRemark: this.options.billRemark,
        details: this.options.list,
      };
      this.loading = true;
      let updateUrl = form.billId ? modifyAssembleGoodsBill : addAssembleGoodsBill;
      try {
        let res = await updateUrl(form);
        this.options = {
          ...this.options,
          billStatus: res.data.billStatus,
          billId: res.data.billId,
        };
        console.log(this.options, "options");
        console.log(res.data, "res.data");
        console.log(res.data.billId, "res.data.billId");
        this.$message.success(`${form.billId ? "修改成功" : "保存成功"}`);
        if (isAdd) {
          this.reset();
          this.getBillno();
        }
        this.loading = false;
        return true;
      } catch (error) {
        this.loading = false;
        return false;
      }
    },
    //初始化数据
    reset() {
      //表格配置
      this.options = {
        ...this.options,
        billNo: undefined,
        relateUnitId: undefined,
        relateUnitName: undefined,
        billDate: undefined,
        inStoreId: undefined,
        relateGoodsId: undefined,
        relateGoodsBatchNo: undefined,
        relateGoodsQty: undefined,
        handBillNo: undefined,
        billRemark: undefined,
      };
      (this.options.list = [{}]), this.resetForm("form");
    },
    // 获取订单编号
    async getBillno() {
      this.options.billNo = await getBillNo("130201");
      this.options.billDate = new Date().toLocaleDateString().replace(/\//g, "-");
    },
    // 审核/反审核  按钮
    async auditForm(type) {
      console.log(type);
      console.log(this.options, "options");
      if ([2, "2"].includes(this.options.billStatus) && type == "审核")
        return this.$message.warning("此单据不是未审核的状态哦~");
      if (["审核"].includes(type)) {
        await this.submitForm();
      }
      if (["审核", "反审核"].includes(type)) {
        this.loading = true;
        modifyBillStatus({
          billIds: [this.options.billId],
          billStatus: type == "审核" ? 2 : 0,
        })
          .then((res) => {
            console.log(res, "res");
            this.$message.success(`${type}成功`);
            this.options = {
              ...this.options,
              billStatus: res.data.billStatus,
            };
            this.loading = false;
          })
          .catch((err) => {
            console.log(err, "err");
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  overflow-y: scroll;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 250px;
}

.table {
  padding: 10px;
}

.leftContent {
  .text {
    width: 70px;
    font-size: 14px;
    line-height: 40px;
  }

  .el-icon-question {
    margin-top: 10px;
  }

  ::v-deep .el-input--medium {
    width: 140px;
  }

  ::v-deep .el-input__inner {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #ccc;
  }
}
.inputBut {
  width: 200px !important;
}
</style>
