var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-box" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "ken-detail-form",
          attrs: {
            model: _vm.form,
            "label-position": "right",
            rules: _vm.validRules,
            size: "mini",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "vxe-table",
                    {
                      ref: "keyBoardTable",
                      staticClass: "table",
                      attrs: {
                        data: _vm.form.details,
                        border: "",
                        resizable: "",
                        "keep-source": "",
                        "show-overflow": "",
                        "show-footer": _vm.showFooter,
                        "max-height": _vm.tableHeight,
                        height: _vm.tableHeight,
                        "show-header-overflow": "",
                        "checkbox-config": { trigger: "row" },
                        "row-config": { isHover: true },
                        "sort-config": { trigger: "cell" },
                        "scroll-y": { enabled: true },
                        "footer-method": _vm.footerMethod,
                      },
                      on: {
                        "cell-click": _vm.handleCellClick,
                        "checkbox-change": _vm.handleSelectionChange,
                        "checkbox-all": _vm.handleSelectionChange,
                      },
                    },
                    [
                      _vm.isShowCheckbox
                        ? _c("vxe-column", {
                            attrs: {
                              type: "checkbox",
                              width: "50",
                              fixed: _vm.isHadFixed,
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _c("vxe-column", {
                        attrs: {
                          align: "center",
                          title: _vm.tableCellLabel,
                          width: "80",
                          fixed: _vm.isHadFixed,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.cellMouseEnter(scope.row)
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.cellMouseLeave(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: scope.row.hoverRow,
                                          expression: "scope.row.hoverRow",
                                        },
                                      ],
                                      staticClass:
                                        "el-icon-circle-plus operatePush",
                                      on: {
                                        click: function ($event) {
                                          return _vm.row("push", scope.rowIndex)
                                        },
                                      },
                                    }),
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: scope.row.hoverRow,
                                          expression: "scope.row.hoverRow",
                                        },
                                      ],
                                      staticClass: "el-icon-remove operateDel",
                                      on: {
                                        click: function ($event) {
                                          return _vm.row("del", scope.rowIndex)
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !scope.row.hoverRow,
                                            expression: "!scope.row.hoverRow",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(scope.rowIndex + 1) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._l(_vm.tableColumns, function (item) {
                        return [
                          item.fieldIsDisplay
                            ? _c(
                                "vxe-column",
                                {
                                  key: item.key,
                                  attrs: {
                                    title: item.title,
                                    width: item.width,
                                    align: item.align || "center",
                                    field: item.key,
                                    fixed: item.fixed || undefined,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            !item.type
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(scope.row[item.key])
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _c(
                                              "el-form-item",
                                              {
                                                ref:
                                                  item.key +
                                                  "_" +
                                                  scope.rowIndex +
                                                  "_" +
                                                  item.columnindex +
                                                  "Form",
                                                refInFor: true,
                                                attrs: {
                                                  prop:
                                                    "details." +
                                                    scope.rowIndex +
                                                    "." +
                                                    item.key,
                                                  rules:
                                                    _vm.validRules[item.key],
                                                },
                                              },
                                              [
                                                item.type &&
                                                item.type.indexOf("input") > -1
                                                  ? _c(
                                                      "el-input",
                                                      {
                                                        ref:
                                                          item.key +
                                                          "_" +
                                                          scope.rowIndex +
                                                          "_" +
                                                          item.columnindex,
                                                        refInFor: true,
                                                        attrs: {
                                                          type:
                                                            item.type ==
                                                            "inputText"
                                                              ? "text"
                                                              : "number",
                                                          maxlength:
                                                            item.maxlength,
                                                          disabled:
                                                            item.disabled ||
                                                            (item.rowDisabled &&
                                                              scope.row
                                                                .isLargess),
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleInput(
                                                              $event,
                                                              scope,
                                                              item
                                                            )
                                                          },
                                                          blur: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleBlur(
                                                              $event,
                                                              scope,
                                                              item
                                                            )
                                                          },
                                                        },
                                                        nativeOn: {
                                                          keydown: [
                                                            function ($event) {
                                                              return _vm.handleInputKeydownEvent(
                                                                $event,
                                                                scope,
                                                                item,
                                                                "all"
                                                              )
                                                            },
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "up",
                                                                  38,
                                                                  $event.key,
                                                                  [
                                                                    "Up",
                                                                    "ArrowUp",
                                                                  ]
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              $event.preventDefault()
                                                              return _vm.handleInputKeydownEvent(
                                                                $event,
                                                                scope,
                                                                item,
                                                                "up"
                                                              )
                                                            },
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "down",
                                                                  40,
                                                                  $event.key,
                                                                  [
                                                                    "Down",
                                                                    "ArrowDown",
                                                                  ]
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              $event.preventDefault()
                                                              return _vm.handleInputKeydownEvent(
                                                                $event,
                                                                scope,
                                                                item,
                                                                "down"
                                                              )
                                                            },
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "left",
                                                                  37,
                                                                  $event.key,
                                                                  [
                                                                    "Left",
                                                                    "ArrowLeft",
                                                                  ]
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              if (
                                                                "button" in
                                                                  $event &&
                                                                $event.button !==
                                                                  0
                                                              ) {
                                                                return null
                                                              }
                                                              $event.preventDefault()
                                                              return _vm.handleInputKeydownEvent(
                                                                $event,
                                                                scope,
                                                                item,
                                                                "left"
                                                              )
                                                            },
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "right",
                                                                  39,
                                                                  $event.key,
                                                                  [
                                                                    "Right",
                                                                    "ArrowRight",
                                                                  ]
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              if (
                                                                "button" in
                                                                  $event &&
                                                                $event.button !==
                                                                  2
                                                              ) {
                                                                return null
                                                              }
                                                              $event.preventDefault()
                                                              return _vm.handleInputKeydownEvent(
                                                                $event,
                                                                scope,
                                                                item,
                                                                "right"
                                                              )
                                                            },
                                                          ],
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row[item.key],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              item.key,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row[item.key]",
                                                        },
                                                      },
                                                      [
                                                        item.icon
                                                          ? _c("i", {
                                                              class: item.icon,
                                                              staticStyle: {
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                              attrs: {
                                                                slot: "suffix",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleClickDefineInputIcon(
                                                                      scope,
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                              slot: "suffix",
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                item.type == "selectRemote"
                                                  ? _c("SelectRemote", {
                                                      ref:
                                                        item.key +
                                                        "_" +
                                                        scope.rowIndex +
                                                        "_" +
                                                        item.columnindex,
                                                      refInFor: true,
                                                      attrs: {
                                                        size: "mini",
                                                        disabled: item.disabled,
                                                        option: item.options,
                                                        curKey: item.key,
                                                        filterable:
                                                          item.filterable ||
                                                          true,
                                                        placeholder:
                                                          item.placeholder ||
                                                          "选择" + item.title,
                                                      },
                                                      on: {
                                                        selectChange: function (
                                                          v,
                                                          row
                                                        ) {
                                                          return _vm.handleSelectRemoteChange(
                                                            v,
                                                            scope,
                                                            item
                                                          )
                                                        },
                                                        handleSelectKeydown:
                                                          function ($event) {
                                                            return _vm.handleTableKeydown(
                                                              $event,
                                                              scope.row,
                                                              item.key,
                                                              scope.rowIndex,
                                                              item.columnindex
                                                            )
                                                          },
                                                        handleChooseCallback:
                                                          function ($event) {
                                                            return _vm.handleSelectRemoteChoose(
                                                              scope,
                                                              item
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row[item.key],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            item.key,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[item.key]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                item.type == "selectLocal"
                                                  ? _c("SelectLocal", {
                                                      ref:
                                                        item.key +
                                                        "_" +
                                                        scope.rowIndex +
                                                        "_" +
                                                        item.columnindex,
                                                      refInFor: true,
                                                      attrs: {
                                                        size: "mini",
                                                        disabled: item.disabled,
                                                        option: item.options,
                                                      },
                                                      on: {
                                                        blur: _vm.handleSelectLocalBlur,
                                                        selectChange: function (
                                                          v,
                                                          row
                                                        ) {
                                                          return _vm.handleSelectLocalChange
                                                        },
                                                        handleSelectKeydown:
                                                          function ($event) {
                                                            return _vm.handleTableKeydown(
                                                              $event,
                                                              scope.row,
                                                              item.key,
                                                              scope.rowIndex,
                                                              item.columnindex
                                                            )
                                                          },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row[item.key],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            item.key,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[item.key]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                item.type == "select"
                                                  ? _c(
                                                      "el-select",
                                                      {
                                                        ref:
                                                          item.key +
                                                          "_" +
                                                          scope.rowIndex +
                                                          "_" +
                                                          item.columnindex,
                                                        refInFor: true,
                                                        attrs: {
                                                          disabled:
                                                            item.disabled ||
                                                            (item.rowDisabled &&
                                                              scope.row
                                                                .disabled),
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.hanldeSelectChange(
                                                              $event,
                                                              scope,
                                                              item
                                                            )
                                                          },
                                                        },
                                                        nativeOn: {
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleTableKeydown(
                                                              $event,
                                                              scope.row,
                                                              item.key,
                                                              scope.rowIndex,
                                                              item.columnindex
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row[item.key],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              item.key,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row[item.key]",
                                                        },
                                                      },
                                                      _vm._l(
                                                        scope.row[
                                                          item.optionListName
                                                        ],
                                                        function (pro, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                value:
                                                                  pro[
                                                                    item
                                                                      .optionValue
                                                                  ],
                                                                label:
                                                                  pro[
                                                                    item
                                                                      .optionLabel
                                                                  ],
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.type === "checkbox"
                                                  ? [
                                                      _c("el-checkbox", {
                                                        ref:
                                                          item.key +
                                                          "_" +
                                                          scope.rowIndex +
                                                          "_" +
                                                          item.columnindex,
                                                        refInFor: true,
                                                        attrs: {
                                                          "true-label":
                                                            item.trueLabel ||
                                                            true,
                                                          "false-label":
                                                            item.falseLabel ||
                                                            false,
                                                          disabled:
                                                            item.disabled,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.hanldeCheckboxChange(
                                                              scope.row,
                                                              scope
                                                            )
                                                          },
                                                        },
                                                        nativeOn: {
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleTableKeydown(
                                                              $event,
                                                              scope.row,
                                                              item.key,
                                                              scope.rowIndex,
                                                              item.columnindex
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row[item.key],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              item.key,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row[item.key]",
                                                        },
                                                      }),
                                                    ]
                                                  : _vm._e(),
                                                item.type &&
                                                item.type.indexOf("date") > -1
                                                  ? _c("el-date-picker", {
                                                      ref:
                                                        item.key +
                                                        "_" +
                                                        scope.rowIndex +
                                                        "_" +
                                                        item.columnindex,
                                                      refInFor: true,
                                                      style: {
                                                        width:
                                                          item.width -
                                                          20 +
                                                          "px",
                                                      },
                                                      attrs: {
                                                        type: item.type,
                                                        "aria-setsize": "mini",
                                                        placeholder:
                                                          item.placeholder ||
                                                          "选择日期",
                                                        disabled: item.disabled,
                                                        "value-format":
                                                          item.valueFormat ||
                                                          "yyyy-MM-dd",
                                                      },
                                                      nativeOn: {
                                                        keydown: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleTableKeydown(
                                                            $event,
                                                            scope.row,
                                                            item.key,
                                                            scope.rowIndex,
                                                            item.columnindex
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row[item.key],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            item.key,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[item.key]",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  item.isRequired
                                    ? _c("template", { slot: "header" }, [
                                        _c(
                                          "i",
                                          { staticStyle: { color: "#ff4949" } },
                                          [_vm._v("* ")]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.title)),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }