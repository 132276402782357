import request from '@/utils/request'

// 原料成本列表
export function listMaterialCost(params) {
  return request({
    url: '/api/system/produce/bill/bom/listMaterialCost',
    method: 'get',
    params
  })
}
// 成品或半成品成本列表
export function listGoodsCost(params) {
  return request({
    url: '/api/system/produce/bill/bom/listGoodsCost',
    method: 'get',
    params
  })
}
// 修改商品标准进价
export function updateGoodsPurPrice(data) {
  return request({
    url: '/api/system/goods/info/updateGoodsPurPrice',
    method: 'post',
    data
  })
}
// 修改单个成本价
export function updateOneCostPrice(data) {
  return request({
    url: '/api/system/produce/bill/bom/updateOneCostPrice',
    method: 'post',
    data
  })
}
