<template>
  <div class="dialog-content">
    <div class="content">
      <el-form ref="form" :model="options" :rules="rules" label-width="106px">
        <el-form-item label="编码" prop="settlementTermNo">
          <el-input v-model="options.settlementTermNo" style="width:230px"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="settlementTermName">
          <el-input v-model="options.settlementTermName" style="width:230px"></el-input>
        </el-form-item>
        <el-form-item label="结算期限" prop="settlementTermDays">
          <div class="x-f">
             <el-input
            onkeyup="value=value.replace(/[^\d]/g,'')"
            v-model="options.settlementTermDays" style="width:230px;margin-right:10px"></el-input>
            <span>天</span>
          </div>
        </el-form-item>
        <el-form-item label="对账日为每月" prop="settlementCheckAccountDay">
          <el-input style="width:230px;margin-right:10px" maxlength=2 minlength=1 onkeyup="value=value.replace(/[^\d]/g,'')" v-model="options.settlementCheckAccountDay"></el-input>
          <span>日</span>
        </el-form-item>
        <el-form-item label="排序" >
          <el-input
            style="width:230px"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            v-model="options.sortNo"></el-input>
        </el-form-item>
        <el-form-item label="备注" >
          <el-input
            size="mini"
            class="inputWidth"
            v-model="options.remark"
            type="textarea"
            placeholder="备注长度介于 1 和 80 字符之间"
            maxlength="80"
            :autosize="{ minRows: 1, maxRows: 2 }"
            :disabled="disableOperate"
          />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="categorySubmit">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";

export default {
  name: "SettlementTermOperationList",
  mixins: [mixin],
  components: { },
  data() {
    return {
      options: {
        settlementTermNo: undefined,
        settlementTermName: undefined,
        settlementTermDays: undefined,
        settlementCheckAccountDay: undefined,
        sortNo: undefined,
      },
      categoryList: [],
      rules: {
        settlementTermNo: [
          { required: true, message: '请输入编码', trigger: 'blur' }
        ],
        settlementTermName: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        settlementTermDays: [
          { required: true, message: '请输入结算期限', trigger: 'blur' }
        ],
        settlementCheckAccountDay: [
          { required: true, message: '请输入对账日', trigger: 'blur' }
        ],
      },
    };
  },
  async created() {
    console.log(this.data, 'data');
    this.options = this.data.obj
    // this.type = this.data.type
    // this.categoryList = this.data.list
  },
  methods: {
   
  },
};
</script>

<style lang="scss" scoped>
  .dialog-content {
    width: 400px;
  }
</style>
