var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.load
    ? _c(
        "el-container",
        { staticClass: "page" },
        [
          _c("el-header", [
            _c("div", { staticClass: "header" }, [_vm._v("自定义打印模板")]),
            _c(
              "div",
              { staticClass: "header-content" },
              [
                _c(
                  "el-button-group",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [_vm._v(" 返回 ")]
                    ),
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.saveTempalte()
                            },
                          },
                        },
                        [_vm._v(" 保存模板 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            disabled: !_vm.printTemplateId,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.defalutTempalte()
                            },
                          },
                        },
                        [_vm._v(" 设为默认 ")]
                      ),
                    ],
                  ],
                  2
                ),
                _c("el-divider", { attrs: { direction: "vertical" } }),
                _vm.key === "topCom" && _vm.pageConfig.topCom[_vm.activeIndex]
                  ? [
                      _c("SelectLocal", {
                        staticStyle: { width: "150px", "margin-right": "10px" },
                        attrs: {
                          option: {
                            data: _vm.fontData,
                            value: "value",
                            label: "label",
                          },
                        },
                        model: {
                          value:
                            _vm.pageConfig.topCom[_vm.activeIndex].fontFamily,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pageConfig.topCom[_vm.activeIndex],
                              "fontFamily",
                              $$v
                            )
                          },
                          expression:
                            "pageConfig.topCom[activeIndex].fontFamily",
                        },
                      }),
                      _c("SelectLocal", {
                        staticStyle: { width: "80px", "margin-right": "10px" },
                        attrs: {
                          option: {
                            data: _vm.fontSizeData,
                            value: "value",
                            label: "label",
                          },
                        },
                        model: {
                          value:
                            _vm.pageConfig.topCom[_vm.activeIndex].fontSize,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pageConfig.topCom[_vm.activeIndex],
                              "fontSize",
                              $$v
                            )
                          },
                          expression: "pageConfig.topCom[activeIndex].fontSize",
                        },
                      }),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "粗体",
                            placement: "bottom",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "bold-view",
                              style: {
                                marginRight: "10px",
                                fontWeight:
                                  _vm.pageConfig.topCom[_vm.activeIndex]
                                    .fontWeight,
                                color: _vm.pageConfig.topCom[_vm.activeIndex]
                                  .fontWeight
                                  ? "#409eff"
                                  : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setDivStyle("fontWeight", "bold")
                                },
                              },
                            },
                            [_vm._v(" B ")]
                          ),
                        ]
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "斜体",
                            placement: "bottom",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "bold-view",
                              style: {
                                fontStyle:
                                  _vm.pageConfig.topCom[_vm.activeIndex]
                                    .fontStyle,
                                color: _vm.pageConfig.topCom[_vm.activeIndex]
                                  .fontStyle
                                  ? "#409eff"
                                  : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setDivStyle("fontStyle", "italic")
                                },
                              },
                            },
                            [_vm._v(" I ")]
                          ),
                        ]
                      ),
                    ]
                  : _vm.key === "bottomCom" &&
                    _vm.pageConfig.bottomCom[_vm.activeIndex]
                  ? [
                      _c("SelectLocal", {
                        staticStyle: { width: "150px", "margin-right": "10px" },
                        attrs: {
                          option: {
                            data: _vm.fontData,
                            value: "value",
                            label: "label",
                          },
                        },
                        model: {
                          value:
                            _vm.pageConfig.bottomCom[_vm.activeIndex]
                              .fontFamily,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pageConfig.bottomCom[_vm.activeIndex],
                              "fontFamily",
                              $$v
                            )
                          },
                          expression:
                            "pageConfig.bottomCom[activeIndex].fontFamily",
                        },
                      }),
                      _c("SelectLocal", {
                        staticStyle: { width: "80px", "margin-right": "10px" },
                        attrs: {
                          option: {
                            data: _vm.fontSizeData,
                            value: "value",
                            label: "label",
                          },
                        },
                        model: {
                          value:
                            _vm.pageConfig.bottomCom[_vm.activeIndex].fontSize,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.pageConfig.bottomCom[_vm.activeIndex],
                              "fontSize",
                              $$v
                            )
                          },
                          expression:
                            "pageConfig.bottomCom[activeIndex].fontSize",
                        },
                      }),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "粗体",
                            placement: "bottom",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "bold-view",
                              style: {
                                fontWeight:
                                  _vm.pageConfig.bottomCom[_vm.activeIndex]
                                    .fontWeight,
                                marginRight: "10px",
                                color: _vm.pageConfig.bottomCom[_vm.activeIndex]
                                  .fontWeight
                                  ? "#409eff"
                                  : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setDivStyle("fontWeight", "bold")
                                },
                              },
                            },
                            [_vm._v(" B ")]
                          ),
                        ]
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "斜体",
                            placement: "bottom",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "bold-view",
                              style: {
                                fontStyle:
                                  _vm.pageConfig.bottomCom[_vm.activeIndex]
                                    .fontStyle,
                                color: _vm.pageConfig.bottomCom[_vm.activeIndex]
                                  .fontStyle
                                  ? "#409eff"
                                  : "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setDivStyle("fontStyle", "italic")
                                },
                              },
                            },
                            [_vm._v(" I ")]
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
                [
                  _c("SelectLocal", {
                    staticStyle: { width: "150px", "margin-right": "10px" },
                    attrs: {
                      option: {
                        data: _vm.fontData,
                        value: "value",
                        label: "label",
                      },
                    },
                    model: {
                      value: _vm.fontFamily,
                      callback: function ($$v) {
                        _vm.fontFamily = $$v
                      },
                      expression: "fontFamily",
                    },
                  }),
                  _c("SelectLocal", {
                    staticStyle: { width: "80px", "margin-right": "10px" },
                    attrs: {
                      option: {
                        data: _vm.fontSizeData,
                        value: "value",
                        label: "label",
                      },
                    },
                    model: {
                      value: _vm.fontSize,
                      callback: function ($$v) {
                        _vm.fontSize = $$v
                      },
                      expression: "fontSize",
                    },
                  }),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "粗体",
                        placement: "bottom",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "bold-view",
                          staticStyle: { "margin-right": "10px" },
                        },
                        [_vm._v("B")]
                      ),
                    ]
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "斜体",
                        placement: "bottom",
                      },
                    },
                    [_c("div", { staticClass: "bold-view" }, [_vm._v("I")])]
                  ),
                ],
                _c("el-divider", { attrs: { direction: "vertical" } }),
                [
                  _c("SelectLocal", {
                    staticStyle: { width: "135px", "margin-right": "10px" },
                    attrs: {
                      option: {
                        data: _vm.headPrintData,
                        value: "value",
                        label: "label",
                      },
                    },
                    model: {
                      value: _vm.pageConfig.headPrintType,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageConfig, "headPrintType", $$v)
                      },
                      expression: "pageConfig.headPrintType",
                    },
                  }),
                  _c("SelectLocal", {
                    staticStyle: { width: "135px", "margin-right": "10px" },
                    attrs: {
                      option: {
                        data: _vm.endPrintData,
                        value: "value",
                        label: "label",
                      },
                    },
                    model: {
                      value: _vm.pageConfig.endPrintType,
                      callback: function ($$v) {
                        _vm.$set(_vm.pageConfig, "endPrintType", $$v)
                      },
                      expression: "pageConfig.endPrintType",
                    },
                  }),
                ],
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "打印纸张设置",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-tickets",
                      on: {
                        click: function ($event) {
                          return _vm.openPageDialog()
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "明细列表设置",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-s-grid",
                      on: {
                        click: function ($event) {
                          return _vm.openListDialog()
                        },
                      },
                    }),
                  ]
                ),
              ],
              2
            ),
          ]),
          _c(
            "el-container",
            { staticClass: "content" },
            [
              _c(
                "el-aside",
                { staticClass: "aside", attrs: { width: "250px" } },
                [
                  _c("leftView", {
                    attrs: { pointer: _vm.pointer, data: _vm.defaultData },
                    on: {
                      "update:pointer": function ($event) {
                        _vm.pointer = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c("el-main", [
                _c(
                  "section",
                  {
                    style: {
                      paddingLeft:
                        _vm.getDpi(_vm.pageConfig.paddingLeft) + "px",
                      paddingRight:
                        _vm.getDpi(_vm.pageConfig.paddingRight) + "px",
                      paddingTop: _vm.getDpi(_vm.pageConfig.paddingTop) + "px",
                      paddingBottom:
                        _vm.getDpi(_vm.pageConfig.paddingBottom) + "px",
                      width:
                        (_vm.pageConfig.direction === 1
                          ? _vm.getDpi(_vm.pageConfig.width)
                          : _vm.getDpi(_vm.pageConfig.height)) + "px",
                      height:
                        (_vm.pageConfig.direction === 1
                          ? _vm.getDpi(_vm.pageConfig.height)
                          : _vm.getDpi(_vm.pageConfig.width)) + "px",
                    },
                    attrs: { id: "print-container" },
                    on: { click: _vm.onClick },
                  },
                  [
                    _vm.pageConfig.topCom
                      ? _c(
                          "section",
                          {
                            class: {
                              "top-view": true,
                              "topCom-view": true,
                              bor: _vm.isEdit,
                              "pointer-events": _vm.pagePointerEvents,
                              "bor-out": _vm.isEdit && _vm.pointer.show,
                              "bor-in":
                                _vm.isEdit &&
                                (_vm.pointer.inType === 1 ||
                                  _vm.key === "topCom"),
                            },
                            style: {
                              height: _vm.pageConfig.topStyle.height + "px",
                            },
                            on: {
                              drop: function (e) {
                                return _vm.drop(e, "topCom")
                              },
                              dragenter: function (e) {
                                return _vm.dragenter(e, "topCom")
                              },
                              dragleave: function (e) {
                                return _vm.dragleave(e, "topCom")
                              },
                              dragover: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _vm._l(
                              _vm.pageConfig.topCom,
                              function (item, index) {
                                return [
                                  _c("TextBlock", {
                                    directives: [
                                      {
                                        name: "drag",
                                        rawName: "v-drag",
                                        value: function (e) {
                                          return _vm.dragChange(
                                            "topCom",
                                            index,
                                            e
                                          )
                                        },
                                        expression:
                                          "(e) => dragChange('topCom', index, e)",
                                      },
                                    ],
                                    key: index,
                                    class: [
                                      "ewmBox",
                                      "topCom-" + index,
                                      _vm.pointerEvents ? "pointer-events" : "",
                                      _vm.activeIndex === index &&
                                      _vm.key === "topCom"
                                        ? "on-click"
                                        : "",
                                    ],
                                    style: item,
                                    attrs: {
                                      del:
                                        _vm.activeIndex === index &&
                                        _vm.key === "topCom",
                                      type: !_vm.isEdit
                                        ? 3
                                        : item.common === "pageInput" ||
                                          item.value === "input"
                                        ? 2
                                        : 1,
                                    },
                                    on: {
                                      stopClick: function ($event) {
                                        return _vm.selectCom("topCom", index)
                                      },
                                      delClick: function ($event) {
                                        return _vm.delCom("topCom", index)
                                      },
                                    },
                                    model: {
                                      value: item.name,
                                      callback: function ($$v) {
                                        _vm.$set(item, "name", $$v)
                                      },
                                      expression: "item.name",
                                    },
                                  }),
                                ]
                              }
                            ),
                            _c("div", {
                              directives: [
                                {
                                  name: "dragTop",
                                  rawName: "v-dragTop:value",
                                  value: _vm.pageConfig,
                                  expression: "pageConfig",
                                  arg: "value",
                                },
                              ],
                              staticClass: "move_tz",
                              attrs: { title: "拖动调整大小" },
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.showTable
                      ? _c(
                          "div",
                          {
                            class: {
                              bor: _vm.isEdit,
                            },
                            style: {
                              margin: "8px 0px",
                              marginRight: "2px",
                              width: "calc(100% - 1px)",
                              height:
                                [1, 3].includes(_vm.pageConfig.endPrintType) &&
                                !_vm.isEdit
                                  ? "auto"
                                  : (_vm.showTableHeight || _vm.tableHeihgt) +
                                    "px",
                            },
                          },
                          [
                            _vm.options.list.length
                              ? _c("TablePage", {
                                  ref: "tablePage",
                                  on: { handleEvent: _vm.handleEvent },
                                  model: {
                                    value: _vm.options,
                                    callback: function ($$v) {
                                      _vm.options = $$v
                                    },
                                    expression: "options",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    (_vm.pageConfig.endPrintType !== 5 || _vm.isEdit) &&
                    _vm.pageConfig.bottomCom
                      ? _c(
                          "section",
                          {
                            class: {
                              "top-view": true,
                              "bottomCom-view": true,
                              bor: _vm.isEdit,
                              "pointer-events": _vm.pagePointerEvents,
                              "bor-out": _vm.isEdit && _vm.pointer.show,
                              "bor-in":
                                _vm.isEdit &&
                                (_vm.pointer.inType === 2 ||
                                  _vm.key === "bottomCom"),
                            },
                            style: {
                              height: _vm.pageConfig.bottomStyle.height + "px",
                            },
                            on: {
                              drop: function (e) {
                                return _vm.drop(e, "bottomCom")
                              },
                              dragenter: function (e) {
                                return _vm.dragenter(e, "bottomCom")
                              },
                              dragleave: function (e) {
                                return _vm.dragleave(e, "bottomCom")
                              },
                              dragover: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _vm._l(
                              _vm.pageConfig.bottomCom,
                              function (item, index) {
                                return [
                                  _c("TextBlock", {
                                    directives: [
                                      {
                                        name: "drag",
                                        rawName: "v-drag",
                                        value: function (e) {
                                          return _vm.dragChange(
                                            "bottomCom",
                                            index,
                                            e
                                          )
                                        },
                                        expression:
                                          "(e) => dragChange('bottomCom', index, e)",
                                      },
                                    ],
                                    key: index,
                                    class: [
                                      "ewmBox",
                                      "bottomCom-" + index,
                                      _vm.pointerEvents ? "pointer-events" : "",
                                      _vm.activeIndex === index &&
                                      _vm.key === "bottomCom"
                                        ? "on-click"
                                        : "",
                                    ],
                                    style: item,
                                    attrs: {
                                      del:
                                        _vm.activeIndex === index &&
                                        _vm.key === "bottomCom",
                                      type: !_vm.isEdit
                                        ? 3
                                        : item.common === "pageInput" ||
                                          item.value === "input"
                                        ? 2
                                        : 1,
                                    },
                                    on: {
                                      stopClick: function ($event) {
                                        return _vm.selectCom("bottomCom", index)
                                      },
                                      delClick: function ($event) {
                                        return _vm.delCom("bottomCom", index)
                                      },
                                    },
                                    model: {
                                      value: item.name,
                                      callback: function ($$v) {
                                        _vm.$set(item, "name", $$v)
                                      },
                                      expression: "item.name",
                                    },
                                  }),
                                ]
                              }
                            ),
                            _c("div", {
                              directives: [
                                {
                                  name: "dragBottom",
                                  rawName: "v-dragBottom:value",
                                  value: _vm.pageConfig,
                                  expression: "pageConfig",
                                  arg: "value",
                                },
                              ],
                              staticClass: "move_tz_bottom",
                              attrs: { title: "拖动调整大小" },
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c("Dialog", {
            ref: "Dialog",
            attrs: { options: _vm.dialogOptions },
            on: {
              "update:options": function ($event) {
                _vm.dialogOptions = $event
              },
              handleEvent: _vm.handleEvent,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }