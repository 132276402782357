<template>
    <div class="wrap" v-loading="loading" id="wrap" ref="wrap">
        <el-form ref="form" :model="options" :rules="rules" label-width="106px">
            <topOperatingButton :isExamineBtn="![2 , '2'].includes(options.billStatus)" :showAudit="isShowAudit" :isStopBtn="false" id="topOperatingButton"
                ref="topOperatingButton" @submitForm="submitForm(false)" @addBill="submitForm(true)" @auditBill="auditForm"
                :disabled= 'disableOperate'
                 @getQuit="getQuit">
            </topOperatingButton>
            <cardTitleCom cardTitle="基本信息" id="basicInformation" ref="basicInformation" :billStatus="options.billStatus">
                <template slot="cardContent">
                    <div class="x-f marT10">
                        <el-form-item label="单据编号" required>
                            <el-input class="inputWidth" size="mini" :disabled="true" v-model="options.billNo"
                                placeholder="单据编号"></el-input>
                        </el-form-item>
                        <el-form-item label="单据日期" required>
                            <el-date-picker class="inputWidth" size="mini" :disabled="disableOperate" v-model="options.billDate" type="date"
                                placeholder="单据日期"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="供应商" required>
                            <el-select @change="changePartner" :disabled="disableOperate" v-model="options.partnerId" clearable filterable size="mini"
                                placeholder="请选择">
                                <el-option v-for="item in customerData" :key="item.supplierId" :label="item.supplierName"
                                    :value="item.supplierId" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="部门">
                            <el-select v-model="options.deptId" :disabled="disableOperate" clearable filterable size="mini" placeholder="请选择">
                                <el-option v-for="item in deptData" :key="item.deptId" :label="item.deptName"
                                    :value="item.deptId" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="业务员">
                            <el-select v-model="options.saleEmployeeId" :disabled="disableOperate" clearable filterable size="mini" placeholder="请选择">
                                <el-option v-for="item in employeeData" :key="item.employeeId" :label="item.employeeName"
                                    :value="item.employeeId" />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="x-f">
                        <el-form-item label="应付款余额">
                            <el-input size="mini" :disabled="true" class="inputWidth" v-model="options.accountsBalance"
                                type="text" placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="备注" prop="billRemark">
                            <el-input size="mini" class="inputWidth" v-model="options.billRemark" type="textarea"
                                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 1 }"
                                :disabled="disableOperate"  />
                        </el-form-item>
                    </div>
                </template>
            </cardTitleCom>
            <cardTitleCom cardTitle="退款信息" class="options">

                <template slot="leftCardTitle">
                    <div class="leftContent x-fsa marL15">
                        <span class="text">优惠金额：</span>
                        <el-input class="fl"
                            :disabled="disableOperate"  @input="limitInputlength(options.discountMoney, options,'discountMoney', false, 4 , true , 99999999)"
                            v-model="options.discountMoney" placeholder="请输入优惠金额"></el-input>
                        <el-tooltip class="fl" effect="dark" content="用于记录付款时的优惠或折扣" placement="top">
                            <i class="el-icon-question" style="color: #a4a4a4"></i>
                        </el-tooltip>
                    </div>
                </template>
                <template slot="cardContent">
                    <div class="table">
                        <!-- 可编辑表格 -->
                        <EditTable :options="options" @handleEvent="handleEvent" />
                    </div>
                </template>
            </cardTitleCom>
            <cardTitleCom cardTitle="源单信息" class="sourceOptions">
                <template slot="leftCardTitle">
                    <div style="padding: 5px;">
                        <el-button size="mini" :disabled="disableOperate" @click="selectSource()">选择源单</el-button>
                        <el-button type="primary"  @click="verification()" :disabled="disableOperate" size="mini">自动核销</el-button>
                    </div>
                </template>
                <template slot="cardContent">
                    <div class="table">
                        <!-- 可编辑表格 -->
                        <EditTable :options="sourceOptions" @handleEvent="handleEvent" />
                    </div>
                </template>
            </cardTitleCom>
            <cardTitleCom cardTitle="附件">
                <template slot="cardContent">
                <!-- 附件上传组件 -->
                <accessoryUpload
                    listType="img"
                    class="accessoryUpload"
                    :disabled="disableOperate"
                    :fileList="options.fileItems"
                    @getFileItems="getFileItemsData"
                    @delFileItems="deleteList"
                ></accessoryUpload>
                </template>
            </cardTitleCom>
        </el-form>
        <!-- 弹窗 -->
        <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </div>
</template>
  
<script>
import { getShouldPaymentPurchaseFunds, addPaymentRefundBill, modifyPaymentRefundBill, getPaymentRefundBillByBillId, paymentRefundModifyBillStatus } from '@/api/arap/index'
import { listDept } from '@/api/system/dept'
import { allPartnerList } from '@/api/partner/partner'
import { allEmployeeList } from '@/api/system/employee' //业务员
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import EditTable from '@/components/editTable' //可编辑表格
import Dialog from '@/components/Dialog'
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import { getBillNo } from '@/api/codeRule'
import { uniqWith } from 'lodash' //去重
import { limitInputlength, processSourceOptionsList, fcount } from '@/utils'
import accessoryUpload from '@/views/components/accessoryUpload' //附件上传组件

export default {
    name: 'paymentBackDetail',
    components: {
        topOperatingButton,
        EditTable,
        cardTitleCom,
        Dialog,
        accessoryUpload,
    },
    watch: {
        'options.billStatus': {
            handler(val) {
                console.log(val, 'val')
                this.disableOperate = ['2', '3', '4', 2, 3, 4].includes(val)
                this.isShowAudit = ['', 4, '4'].includes(val)

                this.options.columns[0].disabled = this.disableOperate
                this.options.columns[1].disabled = this.disableOperate
                this.options.columns[2].disabled = this.disableOperate
                this.options.columns[3].disabled = this.disableOperate
                
                this.sourceOptions.columns[7].disabled = this.disableOperate
                this.sourceOptions.columns[8].disabled = this.disableOperate
                this.options.status = this.disableOperate
            },
            immediate: true
        },
    },
    data() {
        return {
            disableOperate: false,
            isShowAudit: '',
            isAdd: '',
            customerData: [],
            deptData: [],
            employeeData: [],
            loading: false, //遮罩层
            //表格配置
            options: {
                fileItems: [],
                billNo: undefined,
                billDate: undefined,
                partnerId: undefined,
                deptId: undefined,
                saleEmployeeId: undefined,
                accountsBalance: undefined, // 应付款余额 不传的
                discountMoney: undefined,
                billStatus: '',

                tableIndex: 0,
                retainColumn: true,
                curListIndex: -1, //当前表格行
                mutiSelect: false, //是否多选
                list: [{}],
                curListIndex: -1, //当前表格下标
                tableCellLabel: '序号',
                tableHeight: 200, //表格高度
                status: undefined, //单据状态
                // isNoAddBtn: true, //不显示表格增加
                hideTotal: true,
                columns: [
                    {
                        prop: 'bankAccountName',
                        label: '退款账号',
                        type: 'remoteSelect',
                        click: 'clickAccount',
                        Width: 200,
                        align: 'center',
                        option: this.$select({
                            key: 'account',
                            option: {
                                option: {
                                    label: 'bankAccountName',
                                    value: 'bankAccountName',
                                    modal: 'bankAccountName',
                                    change: (propValue, row) => {
                                        console.log(propValue, row)
                                        let tableIndex = this.options.tableIndex
                                        if (row && this.options.tableIndex != undefined) {
                                            this.$set(this.options.list[tableIndex], 'bankAccountId', row.bankAccountId)
                                            this.$set(this.options.list[tableIndex], 'bankAccountNo', row.bankAccountNo)
                                            this.$set(this.options.list[tableIndex], 'bankAccountName', row.bankAccountName)
                                        }
                                    },
                                    tableChange: (propValue, row) => {
                                        this.handleEvent('getSelectData', row)
                                    },
                                    buttons: [
                                        {
                                            type: 'more',
                                            option: {
                                                title: '选择退款账号',
                                                width: 1250,
                                                type: 'TreeAndTable',
                                                formData: {
                                                    ...this.$dialog({ key: 'account' }),
                                                    table: {
                                                        ...this.$dialog({ key: 'account' }).table,
                                                        mutiSelect: true
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                }
                            }
                        }).option,
                        disabled: false,
                        rules: true
                    },
                    {
                        prop: 'payModeNo',
                        label: '退款方式',
                        type: 'remoteSelect',
                        click: 'clickAccount',
                        Width: 200,
                        align: 'center',
                        option: this.$select({
                            key: 'paymode',
                            option: {
                                option: {
                                    label: 'payModeName',
                                    value: 'payModeNo',
                                    modal: 'payModeNo',
                                    tableChange: (propValue, row) => {
                                        this.handleEvent('getSelectDataPay', row)
                                    },
                                    change: (propValue, row) => {
                                        console.log(propValue, row)
                                        let tableIndex = this.options.tableIndex
                                        if (row && this.options.tableIndex != undefined) {
                                            this.$set(this.options.list[tableIndex], 'payModeId', row.payModeId)
                                            this.$set(this.options.list[tableIndex], 'payModeName', row.payModeName)
                                            this.$set(this.options.list[tableIndex], 'payModeNo', row.payModeNo)
                                        }
                                    },
                                    buttons: [
                                        {
                                            type: 'more',
                                            option: {
                                                title: '选择退款方式',
                                                width: 1250,
                                                type: 'TreeAndTable',
                                                formData: {
                                                    ...this.$dialog({ key: 'paymode' }),
                                                    table: {
                                                        ...this.$dialog({ key: 'paymode' }).table,
                                                        mutiSelect: true
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                }
                            }
                        }).option,
                        disabled: false,
                        rules: true
                    },
                    {
                        prop: 'payMoney',
                        label: '退款金额',
                        minWidth: 120,
                        align: 'center',
                        type: 'input',
                        disabled: false,
                        rules: true,
                        isMinus: true,
                        inputNum: 4
                    },
                    {
                        prop: 'remark',
                        label: '备注',
                        minWidth: 120,
                        align: 'center',
                        type: 'my-input',
                        disabled: false,
                        maxlength:80,
                    }
                ],
                summary: ['payMoney'],
            },
            sourceOptions: {
                curListIndex: -1, //当前表格行
                mutiSelect: false, //是否多选
                list: [],
                curListIndex: -1, //当前表格下标
                tableCellLabel: '序号',
                tableHeight: 300, //表格高度
                status: undefined, //单据状态
                isNoAddBtn: true, //不显示表格增加
                hideTotal: true,
                retainColumn: true,
                columns: [
                    {
                        prop: 'billDate',
                        label: '源单日期',
                        minWidth: 120,
                        align: 'center'
                    },
                    {
                        prop: 'billTypeName',
                        label: '源单类型',
                        minWidth: 120,
                        align: 'center'
                    },
                    {
                        prop: 'billNo',
                        label: '源单编号',
                        minWidth: 120,
                        align: 'center'
                    },
                    {
                        prop: 'billRemark',
                        label: '源单备注',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'billTaxMoney',
                        label: '源单金额',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'settleMoney',
                        label: '源单已核销金额',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'unverified',
                        label: '源单未核销金额',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'nowWriteOffMoney',
                        label: '本次核销金额',
                        minWidth: 100,
                        align: 'center',
                        type: 'input',
                        input: 'nowWriteOffMoney',
                        disabled: false,
                        isMinus: false,
                        inputNum: 4
                    },
                    {
                        prop: 'remark',
                        label: '备注',
                        minWidth: 100,
                        type: 'my-input',
                        align: 'center',
                        maxlength:80,
                    },
                ],
                summary: ['billTaxMoney', 'settleMoney', 'unverified', 'nowWriteOffMoney'],
            },
            //弹窗配置
            dialogOptions: {
                title: '选择',
                width: 1250,
                show: false,
                type: 'TreeAndTable'
            },
            rules: {
                billNo: [
                    {
                        required: true,
                        message: '请输入方案编号',
                        trigger: ['blur', 'change']
                    }
                ],
                billDate: [
                    { required: true, message: '请输入制单日期', trigger: 'blur' }
                ]
            },
            ceshi: undefined,
            pageH: 0, //页面高度
            btnsH: 0, //搜索框高度
            basicH: 0, //基础框高度
            paginationH: 50 //分页高度
        }
    },
    activated() {
        this.getDetail()
    },
    mounted() {
        this.getDetail()
    },
    beforeDestroy() {
    },
    created() {
        this.allCustomerListFun()
        this.listDeptFun()
        this.allEmployeeListFun()
    },
    computed: {},
    methods: {
     //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/arap/apbill/paymentBack" });
    },
        filterData (item) {
            let obj = {}
            if ([190201, '190201'].includes(item.billType)) {
                obj = {
                    ...item,
                    billTaxMoney: item.billWriteOffMoney,
                    unverified: fcount([item.billWriteOffMoney, item.settleMoney],'-'),
                    nowWriteOffMoney: fcount([item.billWriteOffMoney, item.settleMoney],'-'),
                    sourceBillType: item.billType,
                    sourceBillId: item.billId,
                    sourceBillWrittenOffMoney: item.settleMoney,
                    remark: '',
                    billRemark: item.billRemark,
                }
                return obj
            } else if ([110103, '110103', 140318, '140318'].includes(item.billType)) {
                obj = {
                    ...item,
                    unverified: fcount([item.billTaxMoney, item.settleMoney],'-'),
                    nowWriteOffMoney: fcount([item.billTaxMoney, item.settleMoney],'-'),
                    sourceBillType: item.billType,
                    sourceBillId: item.billId,
                    sourceBillWrittenOffMoney: item.settleMoney,
                    remark: '',
                    billRemark: item.billRemark,
                }
                return obj
            } else if ([190206, '190206'].includes(item.billType)) {
                obj = {
                    ...item,
                    billDate: item.billDate,
                    unverified: fcount([item.billWriteOffMoney, item.settleMoney? item.settleMoney : 0],'-'),
                    nowWriteOffMoney: fcount([item.billWriteOffMoney, item.settleMoney? item.settleMoney : 0],'-'),
                    billTaxMoney: item.billWriteOffMoney,
                    settleMoney: item.settleMoney,
                    sourceBillType: item.billType,
                    sourceBillId: item.billId,
                    sourceBillWrittenOffMoney: item.settleMoney,
                    remark: item.remark,
                    billRemark: item.billRemark,
                }
                return obj
            } else if ([190106].includes(item.billType)) {
                obj = {
                ...item,
                billDate: item.billDate,
                unverified: fcount([item.billWriteOffMoney, item.settleMoney? item.settleMoney : 0],'-'),
                nowWriteOffMoney: fcount([item.billWriteOffMoney, item.settleMoney? item.settleMoney : 0],'-'),
                billTaxMoney: item.billWriteOffMoney,
                settleMoney: item.settleMoney,
                sourceBillType: item.billType,
                sourceBillId: item.billId,
                sourceBillWrittenOffMoney: item.settleMoney,
                remark: item.remark,
                billRemark: item.billRemark,
                }
                return obj
            }
            },
    deleteList(val) {
      this.options.fileItems = this.options.fileItems.filter(v => v.url != val.url)
    },
            //获取附件上传的信息数据
            getFileItemsData (data) {
            console.log(data, 'data')
            this.options.fileItems.push({
                accessUrl: data.accessUrl,
                domain: data.domain,
                filePath:data.filePath,
                fileName: data.fileName,
                fileSize: data.fileSize,
                name: data.fileName,
                url: data.accessUrl
            })
        },
        verification () {
          this.sourceOptions = processSourceOptionsList(this.options, this.sourceOptions, {text:'退款'})
        },
        changePartner(val) {
            this.sourceOptions.list = []
            if(!val) return
            getShouldPaymentPurchaseFunds({ partnerId: val }).then(res => {
                this.options.accountsBalance = res.data.shouldSettleMoney
            })
        },
        selectSource() {
            if (!this.options.partnerId) return this.$message.error('请选择供应商')
            this.dialogOptions = {
                show: true,
                type: "SelectSource",
                click: 'SelectSource',
                data: { billType: "190202", partnerId: this.options.partnerId },
                title: "选择源单",
            };
        },
        //保留小数位
        limitInputlength,
        allCustomerListFun() {
            allPartnerList().then(response => {
                this.customerData = response.data
            })
        },
        listDeptFun() {
            listDept({
                pageNum: 1,
                pageSize: 1000
            }).then(response => {
                this.deptData = response.data
            })
        },
        allEmployeeListFun() {
            allEmployeeList({}).then(response => {
                this.employeeData = response.data
            })
        },
        filterDataFun (list) {
            let arrlist = list.map((item)=> (
                {
                    ...item,
                    billDate: item.sourceBillDateStr,
                    billTypeName: item.sourceBillTypeName,
                    billNo: item.sourceBillNo,
                    remark: item.remark,
                    billTaxMoney: item.sourceBillMoney,
                    billRemark: item.sourceBillRemark,
                    settleMoney: item.sourceBillWrittenOffMoney,
                    unverified: fcount([item.sourceBillMoney, item.sourceBillWrittenOffMoney],'-'),
                    nowWriteOffMoney: item.nowWriteOffMoney,
                }
            ))
            return arrlist
        },
        //获取详情
        async getDetail() {
            this.reset()
            if (this.$route.query.type === 'Update' && this.$route.query.billId) {
                this.loading = true
                try {
                    const { data } = await getPaymentRefundBillByBillId({ billId: this.$route.query.billId })
                    //方案赋值
                    this.options = {
                        ...this.options,
                        ...data,
                        list: []
                    }
                    this.options.list = data.payDetails
                    let arrlist = data.apDetails.map((item) => (
                        {
                            ...item,
                            billDate: item.sourceBillDateStr,
                            billTypeName: item.sourceBillTypeName,
                            billNo: item.sourceBillNo,
                            remark: item.remark,
                            billRemark: item.sourceBillRemark,
                            billTaxMoney: item.sourceBillMoney,
                            settleMoney: item.sourceBillWrittenOffMoney,
                            unverified: fcount([item.sourceBillMoney, item.sourceBillWrittenOffMoney],'-'),
                            nowWriteOffMoney: item.nowWriteOffMoney,
                        }
                    ))
                    if (this.options.fileItems?.length > 0) {
                        this.options.fileItems = this.options.fileItems.map((item)=> (
                        {
                            ...item,
                            name: item.fileName,
                            url: item.accessUrl,
                        }
                        ))
                    }
                    this.sourceOptions.list = arrlist
                    getShouldPaymentPurchaseFunds({ partnerId: this.options.partnerId }).then(res => {
                        this.options.accountsBalance = res.data.shouldSettleMoney
                    })
                } catch (err) { }
                this.loading = false
            } else {
                this.getBillno()
            }
        },
        async handleEvent(type, row) {
            //获取元素在数组的下标
            let contains = function (list, row) {
                var i = list.length
                while (i--) {
                    if (list[i] === row) {
                        return i
                    }
                }
                return false
            }
            switch (type) {
                case 'SourceDialogChange':
                    console.log(row, 'row');
                    this.dialogOptions.show = false
                    row.list = row.list.map((item) => (
                        {
                            ...this.filterData(item)
                        }
                    ))

                    // if (row.index == 0) {
                    //     row.list = row.list.map((item) => (
                    //         {
                    //             ...item,
                    //             billTaxMoney: item.billWriteOffMoney,
                    //             unverified: fcount([item.billWriteOffMoney, item.settleMoney],'-'),
                    //             nowWriteOffMoney: fcount([item.billWriteOffMoney, item.settleMoney],'-'),
                    //             sourceBillType: item.billType,
                    //             sourceBillId: item.billId,
                    //             sourceBillWrittenOffMoney: item.settleMoney,
                    //             remark: '',
                    //             billRemark: item.billRemark,
                    //         }
                    //     ))
                    // } else {
                    //     row.list = row.list.map((item) => (
                    //         {
                    //             ...item,
                    //             unverified: fcount([item.billTaxMoney, item.settleMoney],'-'),
                    //             nowWriteOffMoney: fcount([item.billTaxMoney, item.settleMoney],'-'),
                    //             sourceBillType: item.billType,
                    //             sourceBillId: item.billId,
                    //             sourceBillWrittenOffMoney: item.settleMoney,
                    //             remark: '',
                    //             billRemark: item.billRemark,
                    //         }
                    //     ))
                    // }
                    this.sourceOptions.list = [...this.sourceOptions.list, ...row.list]
                    this.sourceOptions.list = uniqWith(this.sourceOptions.list, (x, y) => x.sourceBillId== y.sourceBillId).filter(x => x.sourceBillId)
                    break;
                case 'clickAccount':
                    //保存当前表格行下标
                    this.options.curListIndex = contains(this.options.list, row)
                    this.dialogOptions.show = true
                    //弹窗配置
                    this.dialogOptions = {
                        title: '选择账户',
                        width: 1250,
                        show: true,
                        type: 'TreeAndTable',
                        formData: this.$dialog({ key: 'product' })
                    }
                    break
                case 'nowWriteOffMoney':
                    break
                case 'getSelectDataPay':
                case 'getSelectData':
                    if (!row) return
                    let { check } = row
                    if (check.length <= 0) return
                    if (type == 'getSelectData') {
                        let tableIndex = this.options.tableIndex
                        this.$set(this.options.list[tableIndex], 'bankAccountId', check[0].bankAccountId)
                        this.$set(this.options.list[tableIndex], 'bankAccountNo', check[0].bankAccountNo)
                        this.$set(this.options.list[tableIndex], 'bankAccountName', check[0].bankAccountName)
                    } else {
                        let tableIndex = this.options.tableIndex
                        this.$set(this.options.list[tableIndex], 'payModeId', check[0].payModeId)
                        this.$set(this.options.list[tableIndex], 'payModeName', check[0].payModeName)
                        this.$set(this.options.list[tableIndex], 'payModeNo', check[0].payModeNo)
                    }
                    break
                case 'tableIndex':
                    this.options.tableIndex = row
                    break
                default:
                    break
            }
        },
        async userSubmit(isAdd) {
            this.loading = true
            let form = {
                billNo: this.options.billNo, // 方案号
                billId: this.options.billId, // 方案ID
                partnerId: this.options.partnerId, // 方案ID
                deptId: this.options.deptId, // 方案ID
                saleEmployeeId: this.options.saleEmployeeId, // 方案ID
                billRemark: this.options.billRemark, // 方案ID
                discountMoney: this.options.discountMoney, // 方案ID
                billDate: this.options.billDate
                    ? this.options.billDate.split(' ')[0]
                    : '', // 方案时间
                payDetails: this.options.list,
                apDetails: this.sourceOptions.list,
                fileItems : this.options.fileItems,
            }
            if (form.billId) {
                modifyPaymentRefundBill(form).then((res) => {
                    this.options = {
                        ...this.options,
                        billStatus: res.data.billStatus
                    }
                    let arrlist = this.filterDataFun(res.data.apDetails)
                    this.sourceOptions.list = arrlist
                    this.$message.success('修改成功')
                    //新增
                    if (isAdd) {
                        this.reset()
                        this.getBillno()
                    }
                    this.loading = false
                }).catch((err) => {
                    console.log(err, 'err')
                    this.loading = false
                })

            } else {
                addPaymentRefundBill(form).then((res) => {
                    this.options = {
                        ...this.options,
                        billStatus: res.data.billStatus,
                        billId: res.data.billId
                    }
                    let arrlist = this.filterDataFun(res.data.apDetails)
                    this.sourceOptions.list = arrlist
                    this.$message.success('新增成功')
                    //新增
                    if (isAdd) {
                        this.reset()
                        this.getBillno()
                    }
                    this.loading = false
                }).catch((err) => {
                    console.log(err, 'err')
                    this.loading = false
                })
            }

        },
        // 提交保存
        async submitForm(isAdd) {
            this.isAdd = isAdd
            this.$refs['form'].validate(async valid => {
                if (valid) {
                    let sourceIndex = this.sourceOptions.list.findIndex(item => item.nowWriteOffMoney == '' || item.nowWriteOffMoney == undefined)
                    if (sourceIndex != '-1' || this.sourceOptions.list.length == 0) return this.$message.error('请先录入完整的源单信息')
                    // 优惠金额 = 本次核销金额 - (收款金额 + 手续费)
                    let payMoneyTotal = 0; // 本次收款统计
                    let nowWriteOffMoneyTotal = 0; // 本次核销金额统计
                    payMoneyTotal = this.options.list.reduce((prev, next) => { return fcount([prev, next.payMoney],'+') }, 0);
                    nowWriteOffMoneyTotal = this.sourceOptions.list.reduce((prev, next) => { return fcount([prev, next.nowWriteOffMoney],'+')}, 0);
                    let discountMoney = this.options.discountMoney ? this.options.discountMoney : 0

                    if (fcount([payMoneyTotal, discountMoney],'+') != nowWriteOffMoneyTotal) return this.$message.error('本次核销总金额不等于退款核销总金额!')

                    this.userSubmit(isAdd)
                } else this.$message.error('请输入必填项!')
            })
        },
        //初始化数据
        reset() {
            //表格配置
            this.options = {
                ...this.options,
                billNo: undefined,
                billDate: undefined,
                partnerId: undefined,
                deptId: undefined,
                saleEmployeeId: undefined,
                accountsBalance: undefined, // 应付款余额 不传的
                discountMoney: undefined,
                billStatus: '',
                billRemark: '',
                fileItems: [],
            }
            this.options.list = [{}],
                this.sourceOptions.list = [],
                this.resetForm('form')
        },
        // 获取订单编号
        async getBillno() {
            this.options.billNo = await getBillNo(190202)
            this.options.billDate = new Date()
                .toLocaleDateString()
                .replace(/\//g, '-')
        },
        // 审核/反审核  按钮
        async auditForm(type) {
            if ([2, '2'].includes(this.options.billStatus) && type == '审核') return this.$message.warning('此单据不是未审核的状态哦~')
            if (['审核', '反审核'].includes(type)) {
                await this.$confirm(`确定${type}该方案`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                this.loading = true
                const { data } = await paymentRefundModifyBillStatus({
                    billIds: [this.options.billId],
                    billStatus: type == '审核'? 2 : 0
                })
                this.options = {
                    ...this.options,
                    billStatus: data.billStatus
                }
                const res = await getShouldPaymentPurchaseFunds({ partnerId: this.options.partnerId })
                this.options.accountsBalance = res.data.shouldSettleMoney
                this.$message.success(`${type}成功`)
                this.loading = false
            }
        },
    }
}
</script>
  
<style lang="scss" scoped>
.wrap {
    padding: 40px 10px 10px 10px;
    background-color: #eaeaea;
    height: calc(100vh - 84px);
    overflow-y: scroll;
    .accessoryUpload {
        margin: 6px 10px;
        min-height: 150px;
    }
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 250px;
}

.table {
    padding: 10px;
}

.leftContent {
    .text {
        width: 70px;
        font-size: 14px;
        line-height: 40px;
    }

    .el-icon-question {
        margin-top: 10px;
    }

    ::v-deep .el-input--medium {
        width: 140px;
    }

    ::v-deep .el-input__inner {
        border-radius: 0;
        border: none;
        border-bottom: 2px solid #ccc;
    }
}
</style>
  