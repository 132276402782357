var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clearfix", style: { height: _vm.heightWarp + "px" } },
    _vm._l(_vm.selectedList, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "cube-selected",
          class: _vm.styleClass,
          style: {
            border:
              (item.imageUrl ? 0 : 1) +
              "px solid " +
              (_vm.active == index ? "#1890ff" : "#ebedf0"),
          },
          on: {
            click: function ($event) {
              return _vm.changeActive(index)
            },
          },
        },
        [
          item.imageUrl
            ? _c("img", {
                attrs: { src: item.imageUrl, height: "100%", width: "100%" },
              })
            : _c("div", { staticClass: "cube-selected-text" }, [
                _vm._v(_vm._s(item.label)),
              ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }