<!-- 应收账款汇总表 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { listShouldReceiveFundStatistics, sumListShouldReceiveFundStatistics } from "@/api/arap/index";
export default {
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "d8874ff6-1960-5c30-7542-13c8ebb4d08c",
        mutiSelect: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        exportOption: {
          fastExportUrl: "/api/system/finance/book/detail/exportListShouldReceiveFundStatistics",
          exportName: "应收账款汇总表",
        },
        getListApi: listShouldReceiveFundStatistics,
        getSummaryApi: sumListShouldReceiveFundStatistics,
        body: {},
        listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "billId",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          this.$select({ key: "listCustomer", option: { option: { multiple: true } } }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "partnerNo",
            label: "客户编号",
            formatter: (v, row) =>
              row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : "",
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) =>
              row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : "",
            minWidth: 150,
          },
          {
            prop: "firstPreRpMoney",
            label: "期初预收",
            minWidth: 120,
          },
          {
            prop: "firstPsRpMoney",
            label: "期初销售应收",
            minWidth: 120,
          },
          {
            prop: "firstRpBalance",
            label: "期初余额",
            minWidth: 120,
          },
          {
            prop: "preRpMoney",
            label: "预收金额",
            minWidth: 120,
          },
          {
            prop: "preWriteOffMoney",
            label: "预收核销金额",
            minWidth: 120,
          },
          {
            prop: "psRpMoney",
            label: "销售应收金额",
            minWidth: 120,
          },
          {
            prop: "totalRpMoney",
            label: "应收合计金额",
            minWidth: 120,
          },
          {
            prop: "psWriteOffMoney",
            label: "销售核销金额",
            minWidth: 120,
          },
          {
            prop: "totalWriteOffMoney",
            label: "应收核销金额",
            minWidth: 120,
          },
          {
            prop: "currFactRpMoney",
            label: "本期实收金额",
            minWidth: 120,
          },
          {
            prop: "preRpBalance",
            label: "预收余额",
            minWidth: 120,
          },
          {
            prop: "psRpBalance",
            label: "销售应收余额",
            minWidth: 120,
          },
          {
            prop: "otherRpMoney",
            label: "其他应收金额",
            minWidth: 120,
          },
          {
            prop: "otherWriteOffMoney",
            label: "其他应收核销金额",
            minWidth: 130,
          },
          {
            prop: "otherRpBalance",
            label: "其他应收余额",
            minWidth: 120,
          },
          {
            prop: "totalRpBalance",
            label: "余额",
            minWidth: 120,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 120,
          },
          {
            prop: "totalFactRpMoney",
            label: "实际收款金额",
            minWidth: 120,
          },
        ],
        summary: ['firstPreRpMoney','firstPsRpMoney','firstRpBalance','preRpMoney','preWriteOffMoney','psRpMoney','totalRpMoney','psWriteOffMoney','totalWriteOffMoney','currFactRpMoney','preRpBalance','psRpBalance','otherRpMoney','otherWriteOffMoney','otherRpBalance','totalRpBalance','discountMoney','totalFactRpMoney'],
        list: [],
      },
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
