import { listCategoryTree } from '@/api/goods/category' //商品类别api
import { allStoreList } from '@/api/system/store' //仓库api
import { getAllGoodsList } from '@/api/goods/goods' //商品名称api
import { allBrand } from '@/api/goods/brand' //商品品牌api
import { allGoodsMachining } from '@/api/goods/goodsMachining' //商品属性api
import { getAllRegion } from '@/api/goods/region' //省市区
import { treeselect } from '@/api/system/dept' //部门
import { AllTenantAPI } from '@/api/system/tenant' //所有商户
import { getAllShopInfo } from '@/api/shop/base/shopInfo' //所有门店

//共同方法  获取数据 type区别类型
export async function getData (type) {
  let List //返回的数据
  if (type === 'goodsCategory') {
    let { data } = await listCategoryTree() //商品类别数据
    List = data
  } else if (type === 'store') {
    let { data } = await allStoreList() //仓库数据
    List = data
  } else if (type === 'goodsName') {
    let { data } = await getAllGoodsList() //商品名称数据
    List = data
  } else if (type === 'goodsBrand') {
    let { data } = await allBrand() //商品名称数据
    List = data
  } else if (type === 'goodsMachining') {
    let { data } = await allGoodsMachining() //商品属性数据
    List = data
  } else if (type === 'AllRegion') {
    let { data } = await getAllRegion()
    List = data
  } else if (type === 'dept') {
    let { data } = await treeselect()
    List = data
  } else if (type === 'tenant') {
    let { data } = await AllTenantAPI()
    List = data
  } else if (type === 'shop') {
    let { data } = await getAllShopInfo({ isAdmin: true, isAssemble: true })
    List = data
  }
  return List
}

//适用时间日期转换
export async function getDateChangeDetail(value) {
  let specialDate = []
  value.forEach(v => {
    //声明属性
    // 第一行
    let weekText1
    if (v.specialDateWeekMode ||v.specialDateWeekMode == 0) {
      if (v.specialDateWeekMode == '0') {
        weekText1 = '仅单周'
      } else if (v.specialDateWeekMode == '1') {
        weekText1 = '仅双周'
      } else if (v.specialDateWeekMode == '2') {
        weekText1 = `仅每月第${v.specialDateWeekNum}周`
      }
      const id = Date.parse(new Date()) //标识行id 使用时间戳
      v.dateTime = weekText1 //dateTime 日期时间共用字段
      v.id = id + 1
      v.specialDateType = 0 //日期每一行类型
      // this.$set(v, 'dateTime', weekText1) //dateTime 日期时间共用字段
      // this.$set(v, 'id', id + 1)
      // this.$set(v, 'specialDateType', 0) //日期每一行类型
      specialDate.push(v)
    } else if (v.specialDateWeeksArr && v.specialDateWeeksArr.length > 0) {
      // 第二行  (星期转换文字)
      let array = [...v.specialDateWeeksArr]
      for (let index = 0; index < array.length; index++) {
        if (array[index] == '1') {
          array[index] = '星期一'
        } else if (array[index] == '2') {
          array[index] = '星期二'
        } else if (array[index] == '3') {
          array[index] = '星期三'
        } else if (array[index] == '4') {
          array[index] = '星期四'
        } else if (array[index] == '5') {
          array[index] = '星期五'
        } else if (array[index] == '6') {
          array[index] = '星期六'
        } else if (array[index] == '7') {
          array[index] = '星期日'
        }
      }
      let val = array.toString()
      const id = Date.parse(new Date()) //标识行id 使用时间戳
      console.log(Date.parse(new Date()))
      // this.$set(v, 'dateTime', val)
      // this.$set(v, 'id', id + 2)
      // this.$set(v, 'specialDateType', 1)
      v.dateTime = val //dateTime 日期时间共用字段
      v.id = id + 2
      v.specialDateType = 1 //日期每一行类型
      specialDate.push(v)
    } else if (v.isTopMonthPeriod && v.isDownMonthPeriod) {
      // 第三行
      const val = `仅每月第${v.topFromDay}至${v.topToDay}天,每月最后${v.downFromDay}至${v.downToDay}天`
      const id = Date.parse(new Date()) //标识行id 使用时间戳
      // this.$set(v, 'dateTime', val)
      // this.$set(v, 'id', id + 3)
      // this.$set(v, 'specialDateType', 2)
      v.dateTime = val //dateTime 日期时间共用字段
      v.id = id + 3
      v.specialDateType = 2 //日期每一行类型
      specialDate.push(v)
    } else if (v.isTopMonthPeriod) {
      const val = `仅每月第${v.topFromDay}至${v.topToDay}天`
      const id = Date.parse(new Date()) //标识行id 使用时间戳
      // this.$set(v, 'dateTime', val)
      // this.$set(v, 'id', id + 4)
      // this.$set(v, 'specialDateType', 2)
      v.dateTime = val //dateTime 日期时间共用字段
      v.id = id + 4
      v.specialDateType = 2 //日期每一行类型
      specialDate.push(v)
    } else if (v.isDownMonthPeriod) {
      const val = `每月最后${v.downFromDay}至${v.downToDay}天`
      const id = Date.parse(new Date()) //标识行id 使用时间戳
      // this.$set(v, 'dateTime', val)
      // this.$set(v, 'id', id + 5)
      // this.$set(v, 'specialDateType', 2)
      v.dateTime = val //dateTime 日期时间共用字段
      v.id = id + 5
      v.specialDateType = 2 //日期每一行类型
      specialDate.push(v)
    } else if (v.specialDayArr && v.specialDayArr.length > 0) {
      // 第四行
      v.specialDayArr.sort() //进行排序
      let val = ''
      //转换number类型,进行排序
      v.specialDayArr.forEach(i => {
        val += i + ','
      })
      val = val.slice(0, val.length - 1)
      val = `指定日期: 每月${val}日`
      const id = Date.parse(new Date()) //标识行id 使用时间戳
      // this.$set(v, 'dateTime', val)
      // this.$set(v, 'id', id + 6)
      // this.$set(v, 'specialDateType', 3)
      v.dateTime = val //dateTime 日期时间共用字段
      v.id = id + 6
      v.specialDateType = 3 //日期每一行类型
      specialDate.push(v)
    } else if (v.specialBegTime) {
      // 第五行
      const val = `时间范围：${v.specialBegTime}--${v.specialEndTime}`
      const id = Date.parse(new Date()) //标识行id 使用时间戳
      // this.$set(v, 'dateTime', val)
      // this.$set(v, 'id', id + 7)
      // this.$set(v, 'specialDateType', 4)
      v.dateTime = val //dateTime 日期时间共用字段
      v.id = id + 7
      v.specialDateType = 4 //日期每一行类型
      specialDate.push(v)
      console.log(v)
    }
  })
  return specialDate
}
//传几，就保留几位，只舍不入  number数字  decimalPlaces保留位数
export function roundToDecimalPlaces (number, decimalPlaces = '2') {
  const factor = Math.pow(10, Number(decimalPlaces))
  return Math.floor(number * factor) / factor
}
