<template>
  <div
    id="table-page"
    ref="box"
    :class="{
      'show-left-view': config.tree,
      'show-left-view2': config.tableTree,
    }"
    :style="{
      background: config.hideCard ? '#ffffff' : '',
      padding: config.hideCard ? 0 : '',
    }"
  >
    <!-- left tree 树形 -->
    <CardTitleCom
      v-if="config.tree"
      hideCard
      class="page-left-view"
      :style="`height: 100%`"
    >
      <TableTree
        ref="tableTree"
        slot="cardContent"
        v-model="config.tree.body"
        :option.sync="config.tree"
        class="page-tree-view"
        :style="`height: ${treeHeight}px`"
        @change="() => handleEvent('search')"
      />
    </CardTitleCom>
    <div class="page-right-view">
      <!-- 筛选项 -->
      <CardTitleCom
        :cardTitle="
          typeof config.tableTitle === 'string' ? config.tableTitle : $route.meta.title
        "
        v-if="config.search"
        id="search-card"
        :hideCard="config.hideCard"
      >
        <template slot="cardContent">
          <TableSearch
            v-model="config.search"
            :column.sync="config.columns"
            :seniorSearch.sync="config.seniorSearch"
            :button="config.searchButton"
            :show-column="config.showColumn"
            :column-mote="config.columnMote"
            :label-width="config.labelWidth"
            @seniorSearchChange="seniorSearchChange"
            @handleEvent="handleEvent"
          >
            <slot
              slot="search-slot"
              slot-scope="scope"
              name="search-slot"
              v-bind="scope"
            />
          </TableSearch>
          <div class="export">
            <!-- <el-button size="mini" type="primary" class="grid-btn" @click="handleTableColumnConfig" v-if="!options.noShowColumnSet">列配置</el-button> -->
            <el-button
              icon="el-icon-upload2"
              size="mini"
              v-if="config.exportOption"
              @click="handleEvent('export')"
              >导出</el-button
            >
          </div>
        </template>
      </CardTitleCom>
      <CardTitleCom
        :card="2"
        :hideCard="Boolean(!config.tableTitle || config.search)"
        :cardTitle="
          typeof config.tableTitle === 'string' ? config.tableTitle : $route.meta.title
        "
      >
        <template slot="cardContent">
          <!-- 多表格切换 -->
          <el-tabs
            v-if="config.tabsColumns"
            v-model="tabsActive"
            @tab-click="handleClick"
          >
            <el-tab-pane
              v-for="(tab, index) in config.tabsColumns"
              :label="tab.title"
              :key="`${tab.type}.${index}`"
              :name="String(index)"
            ></el-tab-pane>
          </el-tabs>
          <!-- 表格操作按钮组 -->
          <div style="display: flex">
            <div
              v-if="config.rideoCheck"
              style="
                width: 300px;
                vertical-align: sub;
                margin-right: 20px;
                margin-top: 14px;
              "
            >
              <el-radio
                @input="handleEvent('rideo')"
                v-for="(rideoItem, rideoIndex) in config.rideoDataList"
                :key="rideoIndex"
                v-model="config.rideoStatus"
                :label="rideoItem.value"
                >{{ rideoItem.label }}</el-radio
              >
            </div>
            <div style="flex: 1">
              <TableButtons
                v-if="config.buttons && config.buttons.length"
                :buttons="config.buttons"
                :tableConfig="config"
                @handleEvent="handleEvent"
              />
            </div>
            <!-- <div class="grid-btn-box" v-if="!options.noShowColumnSet">
              <el-button size="mini" class="grid-btn" @click="handleTableColumnConfig">列配置</el-button>
            </div> -->
          </div>
          <!-- 表格主题 -->
          <div :class="{ 'show-left-view': config.tableTree }">
            <!-- 表格内部树形筛选 -->
            <TableTree
              ref="tableTree"
              v-if="config.tableTree"
              v-model="config.tableTree.body"
              :option.sync="config.tableTree"
              class="page-tree-view"
              :style="`height: ${config.height || tableHeihgt}px`"
              @change="handleEvent('search')"
            />
            <div
              class="page-right-view-2"
              :style="
                typeof this.config.getTopEchartsOption === 'function'
                  ? `width: 100%; height: ${
                      config.height === 'auto'
                        ? undefined
                        : (config.height || tableHeihgt) + 70
                    }px; overflow-y: auto;`
                  : ''
              "
            >
              <!-- 表主题 -->
              <div
                v-if="
                  typeof this.config.getEchartsOption === 'function' ||
                  typeof this.config.getTopEchartsOption === 'function'
                "
                :id="config.tableId || 'chart-container'"
                :style="`width: 100%; height: ${
                  this.config.topEchartsHeight
                    ? this.config.topEchartsHeight
                    : (config.height || tableHeihgt) + 70
                }px;${
                  typeof this.config.getTopEchartsOption === 'function'
                    ? 'margin-bottom: 20px'
                    : ''
                }`"
              ></div>
              <Table
                :id="config.tableId || 'tablePage'"
                :classTitle="config.classTitle"
                v-if="
                  (config.refreshTable == undefined ? showTbale : config.refreshTable) &&
                  !this.config.getEchartsOption
                "
                ref="table"
                reserve-selection
                :muti-select="config.mutiSelect"
                :selectable="config.selectable"
                :operates="operates"
                :operate-width="`${config.operateWidth || 200}`"
                :linkage="config.linkage"
                :list="config.list || []"
                :loading="config.loading"
                :row-key="config.rowKey"
                :height="
                  config.height === 'auto' ? undefined : config.height || tableHeihgt
                "
                :maxHeight="
                  config.height === 'auto'
                    ? undefined
                    : `${config.height || tableHeihgt}px`
                "
                :border="config.border || true"
                :header-cell-style="handleEventRowClass"
                :show-summary="Boolean(summary && summary.length) && !config.hideSummary"
                :summary="summary"
                :summaryNoVerificationArr="config.summaryNoVerificationArr"
                :summary-dateils="summaryDateils"
                :print-summary-data="config.printSummaryData"
                :default-expand-all="config.defaultExpandAll"
                :tree-props="config.treeProps"
                :select-on-indeterminate="config.selectOnIndeterminate"
                :row-style="config.rowStyle"
                :cell-style="config.cellStyle"
                :virtual-scroll="config.virtualScroll"
                :treeTableLoadOptions="config.treeTableLoadOptions"
                :optionsBody="config.body"
                :row-class-name="config.rowClassName"
                @header-dragend="headerDragend"
                @select="(selection, row) => handleEvent('selection', { selection, row })"
                @select-all="
                  (selection) => handleEvent('selection', { selection, row: config.list })
                "
                @rowClick="
                  (row, row2) =>
                    rowClick
                      ? handleEvent('rowClick', { ...row, property: row2.property })
                      : undefined
                "
                @handleEvent="handleEvent"
                @expand-change="expandChange"
                @tableMouseEvent="mouseEvent"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
              >
                <template
                  v-slot:table-column
                  v-if="config.columns && config.columns.length"
                >
                  <el-table-column
                    v-if="config.radioSelect && !config.mutiSelect"
                    width="55"
                    fixed
                    label="选择"
                    align="center"
                    class-name="radioSelect"
                    header-align="center"
                  >
                    <template slot-scope="{ $index, row }">
                      <el-radio
                        :disabled="
                          config.selectable ? config.selectable(row, $index) : undefined
                        "
                        v-model="radioSelect"
                        :label="row[config.rowKey]"
                      >
                        {{ "" }}
                      </el-radio>
                    </template>
                  </el-table-column>
                  <el-table-column
                    width="80"
                    :label="config.titleMouse"
                    v-if="config.listMouse"
                    align="center"
                    header-align="center"
                    :resizable="false"
                    fixed
                  >
                    <template slot-scope="{ $index, row }">
                      <div v-if="row.hoverRow">
                        <i
                          @click.stop.self="handleTableRow('push', $index)"
                          class="el-icon-circle-plus operatePush"
                        ></i>
                        <i
                          @click.stop.self="handleTableRow('del', $index)"
                          class="el-icon-remove operateDel"
                        ></i>
                      </div>
                      <span v-else>{{ $index + 1 }}</span>
                    </template>
                  </el-table-column>
                  <!-- 55 -->
                  <el-table-column
                    width="72"
                    label="序号"
                    v-if="config.listNo"
                    align="center"
                    header-align="center"
                    :resizable="false"
                    fixed
                  >
                    <template slot="header">
                      <i
                        class="el-icon-s-operation"
                        style="font-size: 18px; cursor: pointer"
                        @click="handleTableColumnConfig"
                        v-if="!options.noShowColumnSet && isShowColumnBtn"
                      ></i>
                      <span v-else>序号</span>
                    </template>
                    <template slot-scope="{ $index }">
                      <div>
                        <span
                          v-if="
                            config.pagination &&
                            config.pagination.page &&
                            config.pagination.size
                          "
                          >{{
                            $index +
                            1 +
                            (config.pagination.page - 1) * config.pagination.size
                          }}
                        </span>
                        <span v-else>{{ $index + 1 }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    width="75"
                    label="操作"
                    v-if="config.isplusMinusSign"
                    align="center"
                    header-align="center"
                    :resizable="false"
                    fixed
                  >
                    <template slot-scope="{ $index, row }">
                      <div>
                        <i
                          @click.stop="tableRowChange('push', $index)"
                          class="el-icon-circle-plus operatePush"
                        />
                        <i
                          @click.stop="tableRowChange('del', $index)"
                          class="el-icon-remove"
                          :class="{
                            operateDel: !row.delDisabled,
                            operateDelDisabled: row.delDisabled,
                          }"
                        />
                      </div>
                    </template>
                  </el-table-column>
                  <template v-for="(item, i) in config.columns">
                    <!-- 动态表格 -->
                    <template
                      v-if="
                        typeof item.getColumns === 'function' &&
                        config.list &&
                        config.list.length
                      "
                    >
                      <TableColumns
                        v-for="(item2, index) in item.getColumns(config.list)"
                        :key="`${item2.prop}.${i}.${index}`"
                        :column="item2"
                        :getColumnsType="item.getColumnsType"
                        @handleEvent="handleEvent"
                      />
                    </template>
                    <slot
                      v-else-if="item.type === 'slot'"
                      :name="item.prop"
                      :slot="item.prop"
                    />
                    <TableColumns
                      v-else
                      :key="`${item.prop}.${i}`"
                      :column="item"
                      @handleEvent="handleEvent"
                    >
                      <slot
                        v-if="item.type === 'slots'"
                        :name="item.prop"
                        :slot="item.prop"
                      />
                      <slot
                        v-if="item.type === 'multiple'"
                        :name="item.prop"
                        :slot="item.prop"
                      />
                      <template :slot="'slot-' + item.prop" slot-scope="scope">
                        <slot :name="item.prop" v-bind="scope" />
                      </template>
                    </TableColumns>
                  </template>
                </template>
              </Table>
              <!-- 自定义合计 原转移何志 -->
              <CustomTotal
                ref="customTotal"
                v-if="config.customTotal"
                v-model="config.customTotal"
                :body="config.body"
                :total="config.pagination ? config.pagination.total : config.list.length"
                @onload="getOtherViewH"
              />
              <!-- 分页 -->
              <TablePagination
                v-if="
                  config.pagination &&
                  !config.hidePagination &&
                  typeof this.config.getEchartsOption !== 'function'
                "
                :allSelect="config.mutiSelect && config.allSelect"
                :isSelect="config.mutiSelect || config.radioSelect"
                :onlySelectTotal="config.pagination.onlySelectTotal || false"
                :check.sync="config.check"
                :page-sizes="config.pagination.pageSizes"
                :page.sync="config.pagination.page"
                :limit.sync="config.pagination.size"
                :total="config.pagination.total"
                :filter="config.pagination.filter"
                :auto-scroll="config.pagination.autoScroll"
                @select="select"
                @pagination="(obj) => handleEvent('pagination', obj)"
              />
            </div>
          </div>
        </template>
      </CardTitleCom>
    </div>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import CardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import TablePagination from "@/components/tablePage/tablePagination";
import CustomTotal from "@/components/tablePage/customTotal";
import moment from "moment";

import { haveContent, isObject, isArray, deepCopy } from "@/utils/index.js";
import { getTimeDate } from "@/utils/newDate/index.js"; //年月日时分秒
import exportApi from "@/api/export.js";
import { ossExportAPI, ossExportKeyAPI } from "@/api/ossExport";
import tableColulumnConfig from "@/utils/mixin/tableColumnConfig";
import { arrayObjectCopy } from "@/utils";

import { getColumnConfigDetail } from "@/api/tableColumnConfig";

export default {
  name: "ComponentTablePage",
  mixins: [tableColulumnConfig],
  components: {
    TableSearch: () => import("@/components/tablePage/tableSearch"),
    TableButtons: () => import("@/components/tablePage/tableButtons"),
    Table: () => import("@/components/tablePage/table"),
    TableColumns: () => import("@/components/tablePage/tableColumns"),
    TableTree: () => import("@/components/tablePage/table-tree"),
    TablePagination,
    CustomTotal,
    CardTitleCom,
    Dialog: () => import("@/components/Dialog"),
  },
  model: { prop: "options", event: "Device" },
  props: {
    options: { type: Object, required: true },
  },
  data() {
    return {
      myChart: null,
      showTbale: true,
      onGetList: false, // 防止重复请求
      isArray,
      isTablePageCom: true, // 确定当前表格页面
      tableConfig: {},
      tabsActive: null,
      radioSelect: null,
      allSelectData: [],
      vivwH: 0,
      searchH: 0,
      tableButtonH: 0,
      tableMergeData: [], // 合并行或列下标
      check: 0,
      customTotalH: 0,
      summaryDateils: null,
      summary: [],
      dialogOptions: {},
      originColumns: [],
      //原始列表数据
      originColumnsCopy: [],
      tableKey: Date.now(),
      isShowColumnBtn: true,
    };
  },
  watch: {
    "options.buttons": {
      handler(newValue) {
        this.tableButtonH = newValue?.length ? 56 : 0;
      },
      deep: true,
      immediate: true,
    },
    "options.check": {
      handler(newValue, OldValue) {
        if (isArray(newValue)) {
          this.check = newValue.length;
        }
        if (!newValue?.length && OldValue?.length) {
          this.clearSelection();
        }
      },
      deep: true,
    },
  },
  computed: {
    rowClick() {
      return this.options?.rowClick !== false; // 是否多选
    },
    treeHeight() {
      // 判断是否包含自定义总计组件
      return this.vivwH - (this.options?.pagination ? 70 : 0) - 15;
    },
    tableHeihgt() {
      // 判断是否包含自定义总计组件
      return (
        this.vivwH -
        (this.options?.pagination && !this.config.hidePagination ? 70 : 0) -
        this.searchH -
        this.tableButtonH -
        (this.options?.delHeight || 0) -
        (this.options?.tabsColumns?.length ? 56 : 0) -
        this.customTotalH -
        (this.config.tableTitle && !this.config.search ? 38 : 0) -
        10 -
        (this.options.delTableHeigth ? Number(this.options.delTableHeigth) + 15 : 15)
      );
    },
    operates() {
      return isObject(this.options.operates)
        ? this.options.operates
        : Boolean(this.options.operates);
    },
    config: {
      get() {
        this.$nextTick(() => {
          try {
            if (!this.options.getListApi && this.options.list?.length) {
              this.setSummary();
            }
          } catch (error) {}
        });
        return this.options;
      },
      set(val) {
        this.$emit("Device", val);
      },
    },
  },
  created() {
    let configId = "";
    let routePath = this.$route.path;
    let routePathArr = routePath.split("/");
    routePathArr.forEach((item) => {
      configId += item + "_";
    });
    console.log(configId, "configId");
    setTimeout(() => {
      this.isShowColumnBtn = true;
      console.log(this.config?.columns, "this.config?.columns");
      this.config?.columns?.forEach((item) => {
        if (item.getColumns || item.children) {
          this.isShowColumnBtn = false;
        }
      });
      if (configId.indexOf("storeQuery") > -1 || !this.config.columns) {
        this.isShowColumnBtn = false;
      }
      if (!this.options.noShowColumnSet && this.isShowColumnBtn && this.config.columns) {
        // this.handleGetGridColumns(this.config.columns);
      }
    }, 750);
    this.getTableColumn();
  },
  activated() {
    // this.handleGetGridColumns(this.config.columns);
  },
  async mounted() {
    const awaitTime = (t = 100) => new Promise((resolve) => setTimeout(resolve, t));
    await awaitTime(500);
    this.config.loading = false;
    if (!this.config.radio) {
      this.config.radio = null;
    }
    this.tableConfig = deepCopy(this.options);
    if (this.config.tabsColumns?.length) {
      await this.changeColumns();
    }
    this.getOtherViewH();
    await this.$nextTick();
    if (!this.config.hidePagination) {
      this.config.pagination = this.getInitPagination(this.config.pagination);
    }
    if (!this.config.tree && !this.config.tableTree) {
      await this.$nextTick();
      this.handleEvent("init"); //  初始化成功
    } else {
      const awaitTime = (t = 100) => new Promise((resolve) => setTimeout(resolve, t));
      await awaitTime(500);
      await this.$nextTick();
      this.$refs?.tableTree?.handleEvent("init", true);
    }
    this.getOtherViewH();
    window.addEventListener("resize", this.getOtherViewH);
    //console.log('sssssss', this.options);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getOtherViewH);
  },
  methods: {
    // 获取列配置
    async getTableColumn() {
      let uuid = this.config.uuid;
      if (this.config.tabsColumns) {
        uuid = this.config.tabsColumns[0]?.uuid;
      }
      if (!uuid) return;
      console.log("进来了嘛列配置");
      try {
        this.loading = true;
        const { data = {} } = await getColumnConfigDetail({
          gridUuid: uuid || "",
        });
        this.config.columns = data?.gridItems
          ? deepCopy(data.gridItems)
              .filter((item) => item.fieldIsDisplay)
              .map((item) => ({
                ...item,
                prop: item.fieldName,
                minWidth: item.fieldCustomWidth,
                label: item.fieldCustomCaption,
                fixed: item.fieldFixedFront
                  ? "left"
                  : item.fieldFixedBack
                  ? "right"
                  : undefined,
                option: item.option
                  ? this.config.columns.find((x) => x.label === item.fieldCaption)?.option
                  : {},
              }))
          : this.config.columns;
        this.loading = false;
        console.log("列配置", this.config.columns);
      } catch (err) {
        this.loading = false;
      }
    },
    tableRowChange(name, index) {
      this.$emit("handleEvent", "tableRowChange", { name, index });
    },
    cellMouseEnter(row) {
      this.$emit("handleEvent", "cellMouseEnter", row);
    },
    cellMouseLeave() {
      this.$emit("handleEvent", "cellMouseLeave");
    },
    handleTableRow(type, index) {
      this.handleEvent("mouseEvent", { type: type, index: index });
    },
    mouseEvent(type, row) {
      if (this.config.mouseEvent) {
        let listIndex = this.config.list.indexOf(row);
        this.config.list = this.config.list.map((item, index) => ({
          ...item,
          hoverRow: type == "enter" ? (index == listIndex ? true : false) : false,
        }));
        if (type == "enter") {
          this.config.titleMouse = "操作";
        } else {
          this.config.titleMouse = "序号";
        }
      }
    },
    setSummary() {
      let summary = [];
      let columns = this.config.columns;
      const { list } = this.config;
      if (this.config.columns?.find?.((x) => typeof x.getColumns === "function")) {
        columns = [];
        this.config.columns.forEach((x) => {
          if (typeof x.getColumns === "function") {
            columns = [...columns, ...x.getColumns(list)];
          } else {
            columns = [...columns, x];
          }
        });
      }
      if (this.config.columns?.forEach) {
        columns.forEach((x) => {
          let arr = [x];
          if (x.children) {
            arr = x.children;
            if (typeof x.children === "function") {
              arr = x.children();
            }
          }
          summary = [
            ...summary,
            ...(arr
              ?.filter?.((x) => x.summary)
              ?.map?.((y) => (typeof y.summary === "string" ? y.summary : y.prop)) || []),
          ];
          summary = [
            ...summary,
            ...(arr
              ?.filter?.((x) => x.summary && x.prop2)
              ?.map?.((y) => (typeof y.summary === "string" ? y.summary : y.prop2)) ||
              []),
          ];
        });
      }
      this.summary = [...(this.options?.summary || []), ...summary];
      this.$refs.table?.$refs?.mutipleTable?.doLayout();
    },
    getInitPagination(pagination) {
      const sizes = [15, 30, 50, 100];
      return {
        ...pagination,
        page: 1,
        size: !sizes.includes(pagination?.size) ? sizes[0] : pagination?.size,
      };
    },
    seniorSearchChange() {
      this.$nextTick(this.getOtherViewH);
    },
    getOtherViewH() {
      try {
        this.myChart.resize();
      } catch (error) {}
      this.$nextTick(() => {
        try {
          this.customTotalH = this.$refs.customTotal?.$el?.offsetHeight || 0;
        } catch (error) {}
        try {
          this.searchH = document.getElementById("search-card")?.clientHeight || 0;
        } catch (error) {}
        try {
          this.vivwH = this.$refs.box.offsetHeight;
        } catch (error) {}
        this.$nextTick(() => {
          try {
            this.$refs.table?.$refs?.mutipleTable?.doLayout();
          } catch (error) {}
        });
      });
    },
    async changeColumns() {
      // 先删除清空几个大模块
      const tabColumnIndex = this.tabsActive
        ? Number(this.tabsActive)
        : this.config.tabColumnIndex || 0;
      const tabOption = deepCopy(this.config.tabsColumns[tabColumnIndex]);
      this.config.tabColumnType = tabOption.type;
      this.config.tabColumnIndex = tabColumnIndex;
      delete this.config.tree;
      delete this.config.tableTree;
      delete this.config.getEchartsOption;
      delete this.config.getTopEchartsOption;
      delete this.config.defaultBody;
      delete this.config.getDataKey;
      delete this.config.handleEventRowClass;
      delete tabOption.type;
      delete tabOption.title;
      this.tabsActive = String(tabColumnIndex);
      this.config = {
        ...this.config,
        ...tabOption,
        ...(typeof this.config.getTabsColumns === "function"
          ? this.config.getTabsColumns({
              tabColumnType: this.config.tabColumnType,
              tabColumnIndex,
            })
          : {}),
        ...(typeof this.config.getListApi === "function" ? { list: [] } : null),
        check: [],
        pagination: tabOption.hidePagination
          ? false
          : this.getInitPagination(this.config.pagination),
      };
      await this.$nextTick();
      //console.log(tabColumnIndex);
      this.handleGetGridColumns(this.config.columns, tabColumnIndex);
      this.$emit("handleEvent", "columnsChange");
      this.setOrClearSearch(true);
      if (!this.config.getListApi) {
        this.setSummary();
      }
    },
    async setBody(load = false) {
      await this.$nextTick();
      const { page: pageNum = 1, size: pageSize = 15 } = this.config.pagination || {};
      // 筛选项数据
      let obj = {};
      if (this.config?.search?.length) {
        const searchs = deepCopy(this.config.search);
        this.config.search.forEach(
          ({ filter, filters, filterFnc, model, type, hide }) => {
            if (typeof hide === "function" ? hide(searchs) : true) {
              let newObj = {};
              if (typeof filterFnc === "function" && haveContent(model)) {
                newObj = filterFnc(model);
              } else if (type === "filters" && filters?.length) {
                newObj = model;
              } else if (
                filter &&
                ((isObject(model) && Object.keys(model).length) ||
                  (isArray(model) && model.length) ||
                  (!isObject(model) && !isArray(model) && haveContent(model)))
              ) {
                newObj = { [filter]: model };
              }
              obj = { ...obj, ...newObj };
            }
          }
        );
      }
      let defaultBody = this.config.defaultBody || {};
      if (typeof this.config.defaultBody === "function") {
        defaultBody = await this.config.defaultBody();
      }
      this.config.defaultBody = defaultBody;
      this.config.body = {
        ...defaultBody,
        ...obj,
        ...(this.config.hidePagination ? {} : { pageNum, pageSize }),
        ...(this.config.dcollection ? this.config.dcollectionBody : {}),
        ...(!this.config.tableTree?.notCallTableList ? this.config.tableTree?.body : {}),
        ...(!this.config.tree?.notCallTableList ? this.config.tree?.body : {}),
      };
    },
    async getSummaryDateils() {
      await this.$nextTick();
      this.summaryDateils = null;
      if (typeof this.config?.getSummaryApi === "function") {
        // 单独合计接口请求
        try {
          this.summaryDateils = {};
          const res = await this.config.getSummaryApi(this.config.body); // summaryDateils
          this.summaryDateils = res?.data || res || {};
          if (typeof this.config.getSummaryData === "function") {
            this.summaryDateils = this.config.getSummaryData(res);
          }
          this.$nextTick(() => {
            this.$refs.table?.$refs?.mutipleTable?.doLayout();
          });
        } catch (error) {}
      }
    },
    async getList(load = false) {
      const search = deepCopy(this.config.search);
      const item = search?.find?.(
        (x) =>
          ["daterange", "datetimerange"].includes(x.type) &&
          (typeof x.hide === "function" ? x.hide(search) : true) &&
          !x.notCheckRange &&
          isArray(x.model) &&
          x.model.length === 2 &&
          moment(moment(x.model[1]).format("yyyy-MM-DD")).valueOf() >
            moment(moment(x.model[0]).format("yyyy-MM-DD")).add(6, "month").valueOf()
      );
      if (item) {
        this.$message.error(`${item.label}：查询时间跨度不能大于六个月！`);
        return false;
      }
      if (this.onGetList) return false;
      this.onGetList = true;
      this.config.loading = true;
      await this.setBody(load);
      this.getSummaryDateils();
      await this.$nextTick();
      try {
        const res = await this.config.getListApi(this.config.body);
        if (typeof this.config.getDataKey === "function") {
          const data = this.config.getDataKey(res); // 返回 {list, total} 具体值
          this.config.list = data.list;
          if (!this.config.hidePagination) {
            this.config.pagination = {
              ...this.config.pagination,
              total: data.total || data.length,
            };
          }
        } else {
          const data = {
            list: this.config.listName || "rows",
            total: "total",
            ...this.config.getDataKey,
          };
          // 合并列或行，当handleMultiple存在时，则执行handleMultiple方法
          if (this.config.handleMultiple) {
            this.config.list = this.handleMultiple(res[data.list]);
          } else {
            // 判断是否有:号，若有，则列表处于第三级
            if (data.list.indexOf(":") > -1) {
              this.config.list = res.data[data.list.replace(":", "")];
            } else {
              this.config.list = res[data.list];
            }
          }
          this.config.pagination = {
            ...this.config.pagination,
            total: res[data.total] || res.data?.[data.total] || res.data.length || 0,
          };
          try {
            if (this.config.customTotal) {
              this.$refs.customTotal.getList();
            }
            this.getOtherViewH();
          } catch (error) {}
        }
        try {
          // 更新 check
          const ids = this.config.check.map((x) => x[this.config.rowKey]);
          this.config.check = this.config.list?.filter((x) =>
            ids.includes(x[this.config.rowKey])
          );
        } catch (error) {}
        this.setSummary();
        if (typeof this.config?.setCurrentPageCheck === "function") {
          await this.$nextTick();
          this.config.check = this.config.setCurrentPageCheck();
          if (this.config?.check?.length) {
            await this.setCurrentPageCheck();
          }
        } else {
          // TODO this.setCheck()
        }
        this.getOtherViewH();
      } catch (error) {}
      if (typeof this.config.getTopEchartsOption === "function") {
        try {
          this.myChart = this.$echarts.init(
            document.getElementById(this.config.tableId || "chart-container")
          );
          const option = await this.config.getTopEchartsOption();
          this.myChart.setOption(deepCopy(option));
        } catch (error) {}
      } else if (typeof this.config.getEchartsOption === "function") {
        try {
          this.myChart = this.$echarts.init(
            document.getElementById(this.config.tableId || "chart-container")
          );
          const option = await this.config.getEchartsOption();
          this.myChart.setOption(deepCopy(option));
        } catch (error) {}
      } else if (this.config.isRestTablecolumns) {
        // isRestTablecolumns : true 每次获取表格数据后重置表格列
        this.showTbale = false;
        await this.$nextTick();
        this.showTbale = true;
      }
      this.onGetList = false;
      this.options.loading = false;
    },

    // 合并行和列计算方法
    handleEventRowClass(e = {}) {
      const { row, column, rowIndex, columnIndex } = e;
      if (this.config.handleEventRowClass) return this.config.handleEventRowClass(e);
      if (!this.config.handleMultiple) return;
      if (
        column.property === "sort" ||
        column.property === "shopNo" ||
        column.property === "shopName" ||
        column.property === "tenantNo" ||
        column.property === "tenantName" ||
        column.property === "billDateStr"
      ) {
        const _row = this.tableMergeData[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },

    // 调整行和列，适用于合并el-table
    handleMultiple(list) {
      const { dataName, commonName } = this.config.handleMultiple;
      let newRows = [];
      this.tableMergeData = [];
      let pos = 0;
      //把每一个门店的付款方式拆分出多条数据
      list.forEach((item) => {
        item[dataName].forEach((item_value1) => {
          for (let key in item_value1) {
            item[key] = item_value1[key];
          }
          newRows.push({ ...item });
        });
      });

      newRows.forEach((item, index) => {
        if (index === 0) {
          this.tableMergeData.push(1);
          pos = 0;
        } else {
          if (item[commonName] === newRows[index - 1][commonName]) {
            this.tableMergeData[pos] += 1;
            this.tableMergeData.push(0);
          } else {
            this.tableMergeData.push(1);
            pos = index;
          }
        }
      });

      // 表格序号
      let sort = 0;
      for (let n in this.tableMergeData) {
        if (this.tableMergeData[n] > 0) {
          sort += 1;
          newRows[n].sort = sort;
        }
      }
      return newRows;
    },

    // 更新配置文件请求方法
    async handleClick() {
      this.$emit("handleEvent", "tabClick");
      if (typeof this.config.getListApi === "function") {
        if (this.config.tabsColumns?.length) {
          await this.changeColumns();
          await this.getTableColumn();
        }
        if (this.config.tree || this.config.tableTree) {
          await this.$nextTick();
          this.$refs.tableTree.handleEvent("init", true);
        } else {
          this.getList(true);
        }
      } else {
        this.setBody(true);
      }
    },
    async getAllList() {
      if (this.onGetList) return false;
      this.onGetList = true;
      this.config.loading = true;
      let list = [];
      try {
        const res = await this.config.getListApi({
          ...this.config.body,
          pageNum: 1,
          pageSize: this.config.pagination.total,
        });
        const data = {
          list: "rows",
          total: "total",
          ...this.config.getDataKey,
        };
        list = res[data.list];
      } catch (error) {}
      this.onGetList = false;
      this.config.loading = false;
      return list;
    },
    // 全选后 自动选中数据
    async setCheck(isAll = false) {
      this.clearSelection();
      await this.$nextTick();
      const newIds = deepCopy(this.allSelectData).map((x) => x[this.config.rowKey]);
      this.config.list
        .filter((x) => newIds.includes(x[this.config.rowKey]))
        .forEach((item) => {
          this.$refs.table.$refs.mutipleTable.toggleRowSelection(item);
        });
      this.config.check = this.allSelectData;
      await this.$nextTick();
    },
    // 回显当前页 默认选中数据
    async setCurrentPageCheck() {
      await this.$nextTick();
      const newIds = deepCopy(this.config.check).map((x) => x[this.config.rowKey]);
      this.config.list
        .filter((x) => newIds.includes(x[this.config.rowKey]))
        .forEach((item) => {
          this.$refs.table.$refs.mutipleTable.toggleRowSelection(item, true);
        });
    },
    async select(e) {
      if (e) {
        this.allSelectData = await this.getAllList();
        await this.setCheck(true); // TODO
      } else {
        this.config.check = [];
      }
    },
    clearSelection() {
      this.radioSelect = "";
      this.radioSelectObject = null;
      this.config.radio = this.radioSelect;
      this.config.radioObject = this.radioSelectObject;
      try {
        this.$refs.table.$refs.mutipleTable.clearSelection();
      } catch (error) {}
      this.config.check = [];
    },
    async handleEvent(type, row, rowIndex) {
      switch (type) {
        case "tableIndex":
          this.config.tableIndex = row;
          break;
        case "init": // 选中数据 Array
          break;
        case "rowClick": // 行点击事件
          if (this.config.radioSelect || this.config.mutiSelect) {
            const listIndex = this.config.list.findIndex(
              (y) => y[this.config.rowKey] === row[this.config.rowKey]
            );
            if (
              this.rowClick &&
              listIndex >= 0 &&
              typeof this.config?.selectable === "function"
                ? this.config.selectable(row, listIndex)
                : true
            ) {
              if (!isArray(this.config.check)) {
                this.config.check = [];
              }
              if (this.config.mutiSelect) {
                const index = this.config?.check?.findIndex?.(
                  (y) => y[this.config.rowKey] === row[this.config.rowKey]
                );
                if (index >= 0) {
                  this.config.check.splice(index, 1);
                } else {
                  this.config.check = [...this.config.check, row];
                }
                const curRow = this.config.list.find(
                  (i) => i[this.config.rowKey] === row[this.config.rowKey]
                );
                this.$refs.table.$refs.mutipleTable.toggleRowSelection(curRow);
              } else {
                if (this.radioSelect !== row[this.config.rowKey]) {
                  this.radioSelect = row[this.config.rowKey];
                  this.radioSelectObject = row;
                } else {
                  this.radioSelect = "";
                  this.radioSelectObject = null;
                }
                this.config.radio = this.radioSelect;
                this.config.radioObject = this.radioSelectObject;
                this.config.check = this.radioSelectObject
                  ? [this.radioSelectObject]
                  : [];
              }
            }
          }
          break;
        case "selection": // 选中数据 Array
          if (!isArray(row.row)) {
            row.row = [row.row];
          }
          if (!isArray(this.config.check)) {
            this.config.check = [];
          }
          if (row.selection?.length) {
            this.config.check = row.selection;
          } else {
            row.row.forEach((x) => {
              const index = this.config.check.findIndex(
                (y) => y[this.config.rowKey] === x[this.config.rowKey]
              );
              if (index >= 0) {
                this.config.check.splice(index, 1);
              }
            });
          }
          break;
        case "refresh": // 刷新表格
          this.setHistory();
          break;
        case "search": // 搜索
          this.config.pagination.page = 1; // 页码重置
          this.setHistory();
          break;
        case "reset": // 重置功能")
          this.config.pagination.page = 1; // 页码重置
          this.setOrClearSearch();
          this.$emit("handleEvent", "searchReset", this.config);
          await this.$nextTick();
          this.setHistory();
          type = "getList"; // 刷新页面
          break;
        case "pagination": // 翻页/切换每页显示数
          this.setHistory(row); // 启动url 页面 row.page 页码 row.limit 页数据数
          type = "getList"; // 刷新页面
          break;
        case "seniorSearch": // 高级搜索关闭时需要刷新
          if (!row) {
            this.setHistory(row); // 启动url 页面 row.page 页码 row.limit 页数据数
            type = "getList"; // 刷新页面
          }
          break;
        case "export": //导出功能
          console.log("----1222----");
          if (this.config?.exportOption?.fastExportUrl) {
            try {
              const body = deepCopy(this.config.body);
              delete body.pageNum;
              delete body.pageSize;
              //导出方法
              if (this.config?.exportOption?.ossKeyUrl) {
                const loading = this.$loading({
                  lock: true,
                  text: "Loading",
                  spinner: "el-icon-loading",
                  background: "rgba(0, 0, 0, 0.7)",
                });
                try {
                  const res = await ossExportAPI(
                    this.config?.exportOption?.fastExportUrl,
                    body
                  );
                  const intervalId = setInterval(async () => {
                    const res1 = await ossExportKeyAPI(
                      this.config?.exportOption?.ossKeyUrl,
                      { key: res.data }
                    );
                    if (res1.data?.accessUrl) {
                      clearInterval(intervalId); // 结束循环
                      loading.close();
                      let link = document.createElement("a");
                      link.style.display = "none";
                      link.href = res1.data.accessUrl;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }
                  }, 1000);
                } catch (error) {
                  loading.close();
                }
              } else {
                this.download(
                  this.config?.exportOption?.fastExportUrl,
                  body,
                  `${this.config.exportOption.exportName}导出${getTimeDate()}.xlsx`,
                  this.config.exportOption.timeout
                );
              }
            } catch (error) {
              console.log("error", error);
              this.$message.error("导出失败");
            }
          } else if (exportApi[this.config?.exportOption?.exportApi]) {
            const body = deepCopy(this.config.body);
            delete body.pageNum;
            delete body.pageSize;
            //导出方法
            this.download(
              exportApi[this.config.exportOption.exportApi],
              body,
              `${this.config.exportOption.exportName}导出${getTimeDate()}.xlsx`,
              this.config.exportOption.timeout
            );
          }
          break;
        case "dialogChange":
          console.log("菜单数据", this.$route, this.$router);
          if (row.type == "tableColumnConfig") {
            //修改列配置
            // this.handleSaveColumnConfig(row.data.tableData);
          }
          break;
        case "dialogInit":
          if (row.type == "tableColumnConfig") {
            //console.log(row)
            this.handleSaveColumnConfig(row.data.originData);
          }
          break;
        case "updateTableColumn": // 修改表格列配置
          this.config.columns = deepCopy(row)
            .filter((item) => item.fieldIsDisplay)
            .map((item) => ({
              ...item,
              prop: item.fieldName,
              minWidth: item.fieldCustomWidth,
              label: item.fieldCustomCaption,
              fixed: item.fieldFixedFront
                ? "left"
                : item.fieldFixedBack
                ? "right"
                : undefined,
              option: item.option
                ? this.config.columns.find((x) => x.label === item.fieldCaption)?.option
                : {},
            }));
          this.$nextTick(() => {
            this.$refs.table?.$refs?.mutipleTable?.doLayout();
          });
          break;
        default:
          break;
      }
      if (["refresh", "search", "reset", "getList"].includes(type)) {
        this.clearSelection();
      }
      await this.$nextTick();
      const listFncs = ["init", "getList", "search", "refresh"];
      if (listFncs.includes(type) && typeof this.config.getListApi === "function") {
        await this.getList(["init"].includes(type));
        await this.$nextTick();
        this.$emit("handleEvent", "getListed");
      } else {
        // this.setBody();
        await this.$nextTick();
        this.$emit("handleEvent", type, row, rowIndex);
      }
    },
    // url 记录filter 暂未启用
    setHistory(row) {
      if (this.config.history) {
        if (row) {
          // 更新页码
        } else {
          const search = {};
          this.config.search
            .filter((x) => haveContent(x.value))
            .forEach((x) => {
              search[x.prop] = x.value;
            });
          const { path } = this.$router.history.current;
          const newQuery = { test: JSON.stringify(search) };
          this.$router.push({ path, query: newQuery });
        }
      }
    },
    // 重置 clearSeniorSearch 只清除高级搜索
    setOrClearSearch(clearSeniorSearch = false) {
      this.config.search?.forEach?.((x) => {
        if (
          !["button", "title"].includes(x.type) &&
          !x.noReset &&
          (clearSeniorSearch ? x.seniorSearch : true)
        ) {
          const defaultValue = Object.keys(x).find((y) => y === "defaultValue");
          const isArr = x.type === "all" || x.option?.multiple;
          const value = deepCopy(defaultValue ? x.defaultValue : isArr ? [] : "");
          if (typeof x.keyIndex === "number" && x.keyIndex >= 0) {
            this.config.search[x.keyIndex].model = value;
          } else {
            x.model = value;
          }
        }
      });
    },
    headerDragend(...e) {
      if (typeof this.config.headerDragend === "function") {
        this.config.headerDragend(...e);
      }
    },
    expandChange(...e) {
      if (typeof this.config.expandChange === "function") {
        this.config.expandChange(...e);
      }
    },
    //处理表格列
    handleGetGridColumns(columns, index) {
      this.configId = this.configIdOld;
      if (index) {
        this.configId = this.configIdOld + index;
      }
      let uuid = this.config.uuid;
      if (index && this.config.tabsColumns?.length > 0) {
        uuid = this.config.tabsColumns[index]?.uuid;
      }
      //console.log(this.configId,'configId')
      this.originColumns = [];
      columns?.forEach((item) => {
        this.originColumns.push({
          ...item,
          fieldCaption: item.label,
          fieldCustomCaption: item.label,
          fieldName: item.prop,
          fieldCustomWidth: item.minWidth,
          fieldIsDisplay: true,
        });
      });
      this.originColumnsCopy = arrayObjectCopy(this.originColumns);
      console.log(this.originColumnsCopy);
      this.getColumnConfigDetail(uuid);
    },
    //打开列配置
    handleTableColumnConfig() {
      console.log(this.originColumnsCopy);
      this.dialogOptions = {
        title: "列表配置",
        width: 800,
        show: true,
        type: "tableColumnConfig",
        data: {
          tableData: this.gridColumns,
          originData: this.originColumnsCopy,
          gridUuid: this.config.uuid,
          menuId: this.$route?.query?.menuId,
          columns: this.config.columns,
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.operateDelDisabled {
  color: #cccccc;
  font-size: 18px;
  cursor: default;
}
.show-left-view {
  $treeW: 250px;
  $treeW2: 270px;

  display: flex;
  width: 100%;
  align-items: flex-start;
  .page-right-view {
    width: calc(100% - 258px);
  }

  .page-left-view {
    width: $treeW2;
    min-width: $treeW2;
    max-width: $treeW2;
    margin-right: 8px;
    ::v-deep .cardContent {
      padding-top: 8px !important;
      padding-right: 8px !important;
      padding-left: 8px !important;
    }
  }

  .page-tree-view {
    width: $treeW;
    min-width: $treeW;
    max-width: $treeW;
    border: 1px solid #dfe6ec;
  }
}

.show-left-view2 {
  .page-right-view-2 {
    width: calc(100% - 258px);
  }
}

#table-page {
  padding: 10px;
  padding-bottom: 0;
  height: calc(100vh - 84px);
  background-color: #eaeaea;

  #search-card {
    position: relative;
    .export {
      position: absolute;
      right: 10px;
      top: 0;
      margin: 7px 0;
    }
  }

  ::v-deep .el-card {
    margin-bottom: 0px !important;
  }

  ::v-deep .cardContent {
    // padding: 10px;
    padding: 10px 10px 10px 18px;
    padding-bottom: 0px;
  }

  .pagination-view {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ::v-deep .el-table:not(.el-table--scrollable-x) {
    .el-table__fixed-right,
    .el-table__fixed {
      height: 100% !important;
    }
  }

  ::v-deep .gutter {
    background-color: #f7f7f7;
    border-bottom: 1px solid #dfe6ec;
  }

  ::v-deep .el-radio-group {
    .el-radio {
      margin-right: 8px !important;
    }
  }

  ::v-deep .radioSelect {
    .el-radio__label {
      display: none !important;
    }

    .el-radio__original {
      z-index: 1 !important;
      cursor: pointer;
    }

    // .el-radio__input.is-disabled .el-radio__inner {
    //   cursor: pointer;
    // }

    // .el-radio__inner:hover {
    //   border-color: #409eff !important;
    // }

    // .el-radio__input.is-checked .el-radio__inner {
    //   border-color: #1890ff;
    //   background: #1890ff;
    // }

    // .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
    //   background-color: #ffffff !important;
    // }
  }
}
.grid-btn-box {
  background-color: #f8f8f9;
  padding: 8px 0;
  margin-bottom: 10px;
  min-height: 48px;
  clear: both;
  .grid-btn {
    margin-left: 10px;
  }
}
p {
  margin: 0 !important;
}
/* 针对Chrome和Safari */
::-webkit-scrollbar {
  display: none;
}
</style>
