<template>
  <div class="wrap" v-loading='loading'>
    <topOperatingButton
      :isSubmitAddBtn="false"
      :isAuditBillBtn="false"
      :isQuitBtn="false"
      @submitForm="submitForm"
    >
    </topOperatingButton>
    <cardTitleCom cardTitle="抹零设置">
      <template #cardContent>
        <div class="padd15">
          <el-form :model="form">
            <el-form-item label="单价舍入方式">
              <el-select
                v-model="form.shop_CASHIER_CONFIG_PARAMETERS_ERASE_11"
                size="small"
                placeholder="请选择"
                class="marL15"
                filterable
              >
                <el-option
                  v-for="item in dict.type.round_off_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="x-bc">
                    <span style="">{{ item.label }}</span>
                    <span style="color: #8492a6; font-size: 13px">{{
                      item.labelTOW
                    }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="行金额舍入方式">
              <el-select
                v-model="form.shop_CASHIER_CONFIG_PARAMETERS_ERASE_12"
                size="small"
                placeholder="请选择"
                style="margin-left: 2px"
                filterable
              >
                <el-option
                  v-for="item in dict.type.round_off_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="x-bc">
                    <span style="">{{ item.label }}</span>
                    <span style="color: #8492a6; font-size: 13px">{{
                      item.labelTOW
                    }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收款舍入方式">
              <el-select
                v-model="form.shop_CASHIER_CONFIG_PARAMETERS_ERASE_13"
                size="small"
                placeholder="请选择"
                class="marL15"
                filterable
              >
                <el-option
                  v-for="item in dict.type.round_off_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="x-bc">
                    <span style="">{{ item.label }}</span>
                    <span style="color: #8492a6; font-size: 13px">{{
                      item.labelTOW
                    }}</span>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="最大允许抹零">
              <el-input
                v-model="form.shop_CASHIER_CONFIG_PARAMETERS_ERASE_14"
                placeholder="请输入内容"
                type="number"
                style="width: 230px; padding-left: 15px"
              ></el-input>
              <!-- <el-select
                v-model="form.shop_CASHIER_CONFIG_PARAMETERS_ERASE_14"
                size="small"
                placeholder="请选择"
                class="marL15"
                filterable
              >
                <el-option
                  v-for="item in dict.type.round_off_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="x-bc">
                    <span style="">{{ item.label }}</span>
                    <span style="color: #8492a6; font-size: 13px">{{
                      item.labelTOW
                    }}</span>
                  </div>
                </el-option>
              </el-select> -->
            </el-form-item>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="零售设置">
      <template #cardContent>
        <div class="padd15">
          <div class="x-f marB10">
            <span
              >收银员交班时启用盲交模式(盲交不显示交班的金额明细,要求输入钱箱中的实际金额)</span
            >
            <el-switch
              class="marL20"
              active-value="Y"
              inactive-value="N"
              v-model="form.shop_CASHIER_CONFIG_PARAMETERS_RETAIL_21"
            >
            </el-switch>
          </div>
          <div class="x-f">
            <span
              >收银端同一商品加入购物车显示为1行</span
            >
            <el-switch
              class="marL20"
              active-value="Y"
              inactive-value="N"
              v-model="form.shop_CASHIER_CONFIG_PARAMETERS_SALES_53"
            >
            </el-switch>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="议价商品设置">
      <template #cardContent>
        <div class="padd15">
          <div class="x-f">
            <span>商品售价为0时默认为议价商品</span>
            <el-switch
              class="marL20"
              active-value="Y"
              inactive-value="N"
              v-model="form.shop_CASHIER_GOODS_BARGAINING_81"
            >
            </el-switch>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="前台商品排序">
      <template #cardContent>
        <div class="padd15 y-start">
          <div>
            <div class="x-f">
              <span>前台商品排序</span>
              <el-tooltip
                class="item marL20"
                effect="dark"
                :content="content1"
                placement="top"
              >
              </el-tooltip>
            </div>
            <div class="marT10">
              <el-radio-group
                v-model="form.shop_CASHIER_CONFIG_PARAMETERS_GOODS_SORT_31"
              >
                <el-radio label="1">按编码排序</el-radio>
                <el-radio label="2">按价格排序</el-radio>
                <el-radio label="3">创建时间(新创建时间)</el-radio>
                <el-radio label="4">按商品首字母</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="其他设置" class="bg-Color marT10">
      <template #cardContent>
        <div class="padd15 y-start">
          <div class="x-f">
            <span>显示随机小票号</span>
            <el-switch class="marL20" v-model="form.shop_CASHIER_CONFIG_PARAMETERS_GOODS_SORT_32" active-value="Y"
            inactive-value="N">
            </el-switch>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="找零窗口设置">
      <template #cardContent>
        <div class="padd15">
         <div class="marB10">
          <span class="padd20">找零窗口停留时间</span>
          <el-input
            style="width: 60px"
            size="mini"
            type="number"
            class="input marL5 marR5"
            v-model="form.shop_CASHIER_CONFIG_PARAMETERS_WINDOW_41"
          ></el-input>
          <span>毫秒</span>
          <el-tooltip
            class="item marL20"
            effect="dark"
            :content="content1"
            placement="top"
          >
          </el-tooltip>
         </div>
          <div>
          <span class="padd20">允许收银端手工输入会员卡时长</span>
          <el-input
            style="width: 120px"
            size="mini"
            type="number"
            class="input marL5 marR5"
            v-model="form.shop_CASHIER_CONFIG_PARAMETERS_WINDOW_42 "
          ></el-input>
          <span>毫秒</span>
         </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="启用导购员设置">
      <template #cardContent>
        <div class="padd15 x-f">
          <div class="x-f marR10">
            <span>充值启用导购员</span>
            <el-switch
              class="marL20"
              active-value="Y"
              inactive-value="N"
              v-model="form.shop_CASHIER_CONFIG_PARAMETERS_SALES_51"
            >
            </el-switch>
          </div>
          <div class="x-f">
            <span>销售启用导购员</span>
            <el-switch
              class="marL20"
              active-value="Y"
              inactive-value="N"
              v-model="form.shop_CASHIER_CONFIG_PARAMETERS_SALES_52"
            >
            </el-switch>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="商品畅滞销统计设置(参数调整后次日生效)">
      <template #cardContent>
        <div class="padd15">
          <div class="x-f">
            <div>
              <span>统计周期</span>
              <el-input
                size="mini"
                type="number"
                class="inputWidth underline-input"
                v-model="form.shop_CASHIER_CONFIG_GOODS_SALE_COUNT_61"
              ></el-input>
              <span>天</span>
              <span class="marR5">定时计算时间,每天:</span>
              <el-time-picker
                v-model="form.shop_CASHIER_CONFIG_GOODS_SALE_COUNT_62"
                size="mini"
              >
                <!-- :picker-options="{
                  selectableRange: '18:30:00 - 20:30:00',
                }" -->
              </el-time-picker>
            </div>
          </div>

          <div class="x-f marT15 marB15">
            <span>畅销:销售额前占比</span>
            <el-input
              size="mini"
              type="number"
              class="inputWidth underline-input"
              v-model="form.shop_CASHIER_CONFIG_GOODS_SALE_COUNT_63"
            ></el-input>
            <span>%</span>
          </div>

          <div class="x-f marT15 marB15">
            <span>滞销:销售额后占比</span>
            <el-input
              size="mini"
              type="number"
              class="inputWidth underline-input"
              v-model="form.shop_CASHIER_CONFIG_GOODS_SALE_COUNT_64"
            ></el-input>
            <span>%</span>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="多仓库设置">
      <template #cardContent>
        <div class="padd15">
          <div class="x-f">
            <el-checkbox
              v-model="form.shop_CASHIER_CONFIG_WAREHOUSES_71"
              class="padd10"
              >启用多仓库(仅适用门店POS开单指定多仓库)</el-checkbox
            >
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="条码秤条码参数设置">
      <template #cardContent>
        <div class="padd15">
          <div class="x-f marB20">
            <span class="fS14MR10">条码标识</span>
            <el-input
              size="mini"
              type="number"
              class="inputWidth underline-input"
              placeholder="请输入条码标识"
              v-model="
                form.shop_CASHIER_CONFIG_BARCODE_91.barcodeIdentification
              "
            ></el-input>
          </div>
          <div class="x-f marB20">
            <el-radio-group
              v-model="form.shop_CASHIER_CONFIG_BARCODE_91.yardage"
            >
              <el-radio :label="1">13码</el-radio>
              <el-radio :label="2">18码</el-radio>
            </el-radio-group>
          </div>
          <div class="marB20">
            <el-radio-group
              v-model="form.shop_CASHIER_CONFIG_BARCODE_91.barcodeValue"
              class="marB20"
            >
              <el-radio :label="1">{{
                form.shop_CASHIER_CONFIG_BARCODE_91.yardage == 1
                  ? '取金额（常用）'
                  : '取“重量+金额”（常用）'
              }}</el-radio>
              <el-radio :label="2">{{
                form.shop_CASHIER_CONFIG_BARCODE_91.yardage == 1
                  ? '取重量'
                  : '取“重量+单价”'
              }}</el-radio>
            </el-radio-group>
            <div v-if="form.shop_CASHIER_CONFIG_BARCODE_91.yardage == 1">
              条码格式：标识位2位或1位+货号5位或6位+金额或重量5位+校验位1位
            </div>
            <div v-else>
              条码格式：标识位2位或1位+货号5位或6位+重量5位+单价或金额5位+校验位1位
            </div>
          </div>
          <div class="x-f marB20">
            <span class="fS14MR10">条码金额精度</span>
            <el-radio-group
              v-model="form.shop_CASHIER_CONFIG_BARCODE_91.amountAccuracy"
            >
              <el-radio :label="0">0</el-radio>
              <el-radio :label="1">1</el-radio>
              <el-radio :label="2">2</el-radio>
              <el-radio :label="3">3</el-radio>
            </el-radio-group>
          </div>
          <div class="x-f marB20">
            <span class="fS14MR10">条码重量精度</span>
            <el-radio-group
              v-model="form.shop_CASHIER_CONFIG_BARCODE_91.weightAccuracy"
            >
              <el-radio :label="0">0</el-radio>
              <el-radio :label="1">1</el-radio>
              <el-radio :label="2">2</el-radio>
              <el-radio :label="3">3</el-radio>
            </el-radio-group>
          </div>
          <div class="x-f">
            <span class="fS14MR10">单价重量精度</span>
            <el-radio-group
              v-model="form.shop_CASHIER_CONFIG_BARCODE_91.priceAccuracy"
            >
              <el-radio :label="0">0</el-radio>
              <el-radio :label="1">1</el-radio>
              <el-radio :label="2">2</el-radio>
              <el-radio :label="3">3</el-radio>
            </el-radio-group>
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom'
import CButton from '@/views/components/c-button/index.vue' //卡片标题
import {
  payListAPI,
  paySaveOrUpdateAPI
} from '@/api/shop/setup/posParam/payParam'
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
export default {
  name: 'payParam',
  dicts: ['round_off_type'],
  components: {
    cardTitleCom,
    CButton,
    topOperatingButton
  },
  data () {
    return {
      loading: false,
      content1: '',
      value1: '', //时间
      date: '',
      form: {
        shop_CASHIER_CONFIG_PARAMETERS_WINDOW_42 :'300000',
        shop_CASHIER_CONFIG_PARAMETERS_SALES_53:'Y',
        shop_CASHIER_CONFIG_GOODS_SALE_COUNT_61: '',
        shop_CASHIER_CONFIG_GOODS_SALE_COUNT_62: '',
        shop_CASHIER_CONFIG_GOODS_SALE_COUNT_63: '',
        shop_CASHIER_CONFIG_GOODS_SALE_COUNT_64: '',
        shop_CASHIER_CONFIG_PARAMETERS_ERASE_11: '',
        shop_CASHIER_CONFIG_PARAMETERS_ERASE_12: '',
        shop_CASHIER_CONFIG_PARAMETERS_ERASE_13: '',
        shop_CASHIER_CONFIG_PARAMETERS_ERASE_14: '',
        shop_CASHIER_CONFIG_PARAMETERS_GOODS_SORT_31: 2,
        shop_CASHIER_CONFIG_PARAMETERS_RETAIL_21: 'N',
        shop_CASHIER_CONFIG_PARAMETERS_SALES_51: 'N',
        shop_CASHIER_CONFIG_PARAMETERS_SALES_52: 'N',
        shop_CASHIER_CONFIG_PARAMETERS_WINDOW_41: 1000,
        shop_CASHIER_CONFIG_WAREHOUSES_71: '',
        shop_CASHIER_CONFIG_BARCODE_91: {
          barcodeIdentification: 22, //标识
          yardage: 1, //码数
          barcodeValue: 1, //字段取值
          amountAccuracy: 2, //条码金额精度
          weightAccuracy: 3, //条码重量精度
          priceAccuracy: 0 //条码单价精度
        },

        // mallConfigBody: {
        //   mallConfigurationId: null, // ID
        //   isEnableNoTokeBrowse: false, // 是否无需登录
        //   unitPriceRoundOffType: "", // 单价舍入方式
        //   amountRoundOffType: "", // 金额舍入方式
        //   paymentRoundOffType: "", // 收款舍入方式
        //   isDisableShowStock: false, // 是否展示库存
        //   isShowVirtualStock: false, // 是否展示虚拟库存
        //   inventoryStockTyp: "", // 展示基于进销存的商城可用库存类型
        //   virtualStockDeductTyp: "", // 虚拟库存扣除方式
        //   isShowInventoryStock: false, // 是否展示基于进销存的商城可用库存
        //   isLockOrderStock: false, // 是否商品库存锁库

        //   //基本信息
        //   billId: undefined, //促销方案id
        //   billNo: undefined, //促销方案类型编号
        //   promoType: undefined, //促销方案类型
        //   promoName: undefined, //促销方案名称
        //   promoDate: undefined, //促销日期
        //   begTime: undefined, //促销开始时间
        //   endTime: undefined, //促销结束时间
        //   //适用渠道
        //   AllowOfflineShop: ["0", "1"],
        //   isAllowOfflineShop: false, //适用渠道(线下门店)
        //   isAllowOnlineShop: false, //适用渠道(线上商城)
        //   //活动规则
        //   shopRangeType: "0", //适用门店
        //   shopItem: [], //适用门店表格数据
        //   //发放对象
        //   vipLevelRangeTypeArr: ["0", "3"], //适用会员数组
        //   vipLevelItem: [], //适用会员表格数据
        //   vipBirthdayType: "1", //会员生日时间
        //   useTimeRangeType: "0", //适用时间
        //   specialDateItem: [], //适用时间数据
        //   //参与活动的商品
        //   goodsRangeType: "0", //适用商品
        //   goodsItem: [], //适用商品表格数据
        //   allSpecialPrice: undefined, //商品全部特价
        //   allSpecialDiscount: undefined, //商品全部折扣
        //   billRemark: undefined, //备注
        // },
        stockConfigItems: [
          {
            beginRange: '', // 开始数量
            endRange: '', // 结束数量
            display: '', // 显示的数量
            isDisplayStock: false // 是否展示库存数量
          }
        ],
        wxTemplateConfigItems: [
          {
            isEnable: false,
            wxTemplateId: '123'
          }
        ]
      },
      checked: [],
      radio: '',
      // -------------------------
      tableData: [{}],
      input: '', //日平均周天数
      options1: [
        {
          label: '商城订单确认后扣减库存商',
          value: '1'
        },
        {
          label: '商城订单下单后立即扣减库存',
          value: '2'
        }
      ],
      options2: [
        {
          label: '库存不足无法下单',
          value: '1'
        },
        {
          label: '库存不足不允许下单',
          value: '2'
        }
      ]
    }
  },
  created () {
    this.getDetails()
  },
  methods: {
    getDetails () {
      payListAPI().then(res => {
        res.data.shop_CASHIER_CONFIG_BARCODE_91 = res.data
          ?.shop_CASHIER_CONFIG_BARCODE_91
          ? JSON.parse(res.data.shop_CASHIER_CONFIG_BARCODE_91)
          : {
              barcodeIdentification: 22, //标识
              yardage: 1, //码数
              barcodeValue: 1, //字段取值
              amountAccuracy: 2, //条码金额精度
              weightAccuracy: 3, //条码重量精度
              priceAccuracy: 0 //条码单价精度
            }
        this.form = res.data
        console.log('form', this.form)
      })
    },
    async submitForm () {
      this.loading = true
      if (this.shop_CASHIER_CONFIG_PARAMETERS_WINDOW_42 >30000000)return this.$message.error('允许收银端手工输入会员卡时长上限不能超过30000000毫秒！')
      try {
        this.form.shop_CASHIER_CONFIG_BARCODE_91 = JSON.stringify(
          this.form.shop_CASHIER_CONFIG_BARCODE_91
        )
         await paySaveOrUpdateAPI(this.form)
         this.$message.success('保存成功')
         await this.getDetails()
         this.loading = false
      } catch (error) {
        this.form.shop_CASHIER_CONFIG_BARCODE_91 = this.form
          ?.shop_CASHIER_CONFIG_BARCODE_91
          ? JSON.parse(this.form.shop_CASHIER_CONFIG_BARCODE_91)
          : {
              barcodeIdentification: 22, //标识
              yardage: 1, //码数
              barcodeValue: 1, //字段取值
              amountAccuracy: 2, //条码金额精度
              weightAccuracy: 3, //条码重量精度
              priceAccuracy: 0 //条码单价精度
            }
      this.loading = false
      }

      // console.log("ID：", this.form.mallConfigBody.mallConfigurationId);
      // if (this.form.mallConfigBody.mallConfigurationId == null) {
      //   mallConfigSave(this.form).then((res) => {
      //     console.log("保存成功", res.data);
      //     this.form.mallConfigBody = res.data.mallConfigBody;
      //     this.form.stockConfigItems = res.data.stockConfigItems;
      //     this.form.wxTemplateConfigItems = res.data.wxTemplateConfigItems;
      //     this.$message.success("保存成功");
      //   });
      // } else {
      //   mallConfigUpdate(this.form).then((res) => {
      //     this.form.mallConfigBody = res.data.mallConfigBody;
      //     this.form.stockConfigItems = res.data.stockConfigItems;
      //     this.form.wxTemplateConfigItems = res.data.wxTemplateConfigItems;
      //     this.$message.success("修改成功");
      //   });
      // }
      // this.getDetails()
    },
    row (type, index) {
      if (type == 'push') {
        this.form.stockConfigItems.splice(index + 1, 0, {})
      } else {
        if (this.form.stockConfigItems.length <= 1) return
        this.form.stockConfigItems.splice(index, 1)
      }
    },
    showIcon () {
      this.$nextTick(() => {
        this.$refs.datePicker.$refs.input.$el.querySelector(
          '.el-input__icon'
        ).style.display = 'inline-block'
      })
    },
    hideIcon () {
      this.$refs.datePicker.$refs.input.$el.querySelector(
        '.el-input__icon'
      ).style.display = 'none'
    }
  }
}
</script>

<style scoped>
.wrap {
  padding-top: 30px;
}
.fontColor {
  color: #ababab;
}
.underline-input /deep/ .el-input__inner {
  border: none !important ; /* 移除默认边框 */
  border-bottom: 1px solid #ccc !important; /* 添加下划线边框 */
  border-radius: 0; /* 可选，如果不想有圆角 */
  background-color: transparent; /* 可选，如果不想有背景色 */
}
/* 解决el-input设置类型为number时，中文输入法光标上移问题 */
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/* 解决el-input设置类型为number时，去掉输入框后面上下箭头 */
::v-deep input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.custom-date-picker .el-input__icon {
  display: none; /* 初始隐藏图标 */
}

.custom-date-picker:hover .el-input__icon {
  display: inline-block; /* 移入时显示图标 */
}
</style>
