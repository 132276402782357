<template>
  <div class="wrap" v-loading="loading">
    <!-- 顶部按钮 -->
    <topOperatingButton
      :isSubmitAddBtn="false"
      :isAuditBillBtn="false"
      @submitForm="submitForm"
      @getQuit="getQuit"
    >
    </topOperatingButton>
    <el-form
      ref="form"
      :model="douYinProductForm"
      :rules="rules"
      label-width="130px"
      @submit.native.prevent
    >
      <cardTitleCom cardTitle="基本信息">
        <template slot="cardContent">
          <div class="x-w marT20">
            <el-form-item label="券方案编号" prop="product.productNo">
              <el-input
                size="mini"
                class="inputWidth"
                v-model="douYinProductForm.product.productNo"
                placeholder="券方案编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="券方案名称" prop="product.productName">
              <el-input
                size="mini"
                class="inputWidth"
                v-model="douYinProductForm.product.productName"
                placeholder="请输入券方案名称"
              ></el-input>
            </el-form-item>
            <!-- prop="product.categoryId" -->
            <el-form-item label="抖音品类" prop="product.categoryId">
              <el-cascader
                size="mini"
                class="inputWidth"
                :options="douYinCategory"
                :props="defaultProp"
                v-model="douYinProductForm.product.categoryId"
                filterable
                clearable
                @change="categoryChange"
              >
              </el-cascader>
              <!-- <SelectLocal v-model="douYinProductForm.product.categoryId" style="width: 250px" :option="{
                  data: dict.type.douyin_category,
                  value: 'value',
                  label: 'label',
                }" /> -->
            </el-form-item>
            <el-form-item label="商品类型" prop="product.productType">
              <el-select
                filterable
                v-model="douYinProductForm.product.productType"
                size="mini"
                placeholder="请选择商品类型"
                :disabled="
                  douYinProductForm.product.productId &&
                  douYinProductForm.product.douyinProductIdOld
                "
                @change="changeProductType"
              >
                <el-option label="团购券" :value="1"></el-option>
                <el-option label="代金券" :value="11"></el-option>
                <el-option label="次卡" :value="15"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品来源" prop="product.productSourceType">
              <el-select
                filterable
                v-model="douYinProductForm.product.productSourceType"
                size="mini"
                placeholder="请选择商品来源"
                :disabled="
                  douYinProductForm.product.productId &&
                  douYinProductForm.product.douyinProductIdOld
                "
              >
                <el-option label="系统创建" :value="1"></el-option>
                <el-option label="抖音来客创建" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="抖音商品ID"
              prop="product.douyinProductId"
              v-if="douYinProductForm.product.productSourceType == 2"
            >
              <el-input
                size="mini"
                class="inputWidth"
                v-model="douYinProductForm.product.douyinProductId"
                placeholder="请输入抖音商品ID"
                :disabled="
                  douYinProductForm.product.productId &&
                  douYinProductForm.product.douyinProductIdOld
                "
              ></el-input>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="商品信息">
        <template slot="cardContent">
          <div>
            <el-form-item
              label="建议用餐人数"
              class="recPersonNum marT10"
              v-if="couponType"
            >
              <div class="x-f marT5">
                <el-radio-group
                  v-if="!recPersonRadio"
                  size="mini"
                  v-model="douYinProductForm.product.recPersonNum"
                  class="marR50"
                >
                  <el-radio border :label="1">单人餐</el-radio>
                  <el-radio border :label="2">双人餐</el-radio>
                  <el-radio border :label="3">三人餐</el-radio>
                  <el-radio border :label="4">四人餐</el-radio>
                  <!-- <el-radio border :label="5">自定义</el-radio> -->
                </el-radio-group>
                <el-radio-group
                  v-if="recPersonRadio"
                  size="mini"
                  v-model="recPersonNumVirtual"
                  class="marR50"
                  @change="recPersonNumVirtualChange"
                >
                  <el-radio border :label="1">单人餐</el-radio>
                  <el-radio border :label="2">双人餐</el-radio>
                  <el-radio border :label="3">三人餐</el-radio>
                  <el-radio border :label="4">四人餐</el-radio>
                  <!-- <el-radio border :label="5">自定义</el-radio> -->
                </el-radio-group>
                <el-radio
                  v-model="recPersonRadio"
                  border
                  :label="5"
                  size="mini"
                  @change="recPersonRadioChange"
                  >自定义</el-radio
                >
              </div>

              <div v-if="recPersonRadio" class="marT5">
                <el-select
                  style="width: 90px"
                  v-model="douYinProductForm.product.recPersonNum"
                  placeholder="请选择"
                  size="mini"
                >
                  <el-option
                    v-for="item in numberDinersList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.value >= douYinProductForm.product.recPersonNumMax"
                  >
                  </el-option>
                </el-select>
                <span class="marL10 marR10">至</span>
                <el-select
                  style="width: 90px"
                  v-model="douYinProductForm.product.recPersonNumMax"
                  placeholder="请选择"
                  size="mini"
                >
                  <el-option
                    v-for="item in numberDinersList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.value <= douYinProductForm.product.recPersonNum"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label="* 商品搭配" v-if="couponType">
              <div
                class="regularVouchers"
                v-for="(item, index) in douYinProductForm.groupReqList"
                :key="index"
              >
                <!-- :prop="
                      'douYinProductForm.' +
                      '.groupReqList.' +
                      index +
                      '.groupName'
                    "
                    :rules="rules[`douYinProductForm.groupReqList.groupName`]" -->
                <div class="x-f marB10">
                  <el-form-item
                    label="商品组名称"
                    label-width="100px"
                    :prop="'groupReqList.' + index + '.groupName'"
                    :rules="rules[`groupReqList.groupName`]"
                  >
                    <el-input
                      size="mini"
                      class="inputWidth marR50"
                      v-model="item.groupName"
                      placeholder="请输入商品组名称，最多输入10个字符"
                      maxlength="10"
                    ></el-input>
                  </el-form-item>
                  <!-- :prop="
                      'douYinProductForm.' +
                      'groupReqList.' +
                      index +
                      '.optionCount'
                    "
                    :rules="rules[`douYinProductForm.groupReqList.optionCount`]" -->
                  <el-form-item
                    label="最多可选"
                    label-width="80px"
                    :prop="'groupReqList.' + index + '.optionCount'"
                    :rules="rules[`groupReqList.optionCount`]"
                  >
                    <el-select
                      style="width: 120px"
                      v-model="item.optionCount"
                      placeholder="请选择"
                      size="mini"
                      @change="optionalMostChange"
                    >
                      <el-option
                        v-for="item in item.matchGoodsList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="goodsTips">
                  商品组名称中不可出现“任选N”“X选N”字样，当商品组的可选范围是“几选1”或“全部可选”时，不可配置重复选
                </div>
                <div
                  class="marB10"
                  v-for="(goodsItem, goodsIndex) in item.skuPartsList"
                  :key="goodsIndex"
                >
                  <div class="x-f">
                    <el-form-item
                      label-width="0"
                      :prop="
                        'groupReqList.' +
                        index +
                        '.skuPartsList.' +
                        goodsIndex +
                        '.goodsId'
                      "
                      :rules="rules[`skuPartsList.goodsId`]"
                    >
                      <SelectRemote
                        class="marR10"
                        v-model="goodsItem.goodsId"
                        @selectChange="
                          (v, obj) => selectChangeGoods(v, obj, index, goodsIndex)
                        "
                        style="width: 180px"
                        :option="
                          $select({
                            key: 'listGoods',
                            option: {
                              option: {
                                remoteBody: {
                                  goodsTypes: [1],
                                },
                              },
                            },
                          }).option
                        "
                      />
                    </el-form-item>
                    <el-input-number
                      style="width: 120px"
                      size="mini"
                      v-model="goodsItem.unitQty"
                      controls-position="right"
                      :min="1"
                      :max="99"
                    ></el-input-number>
                    <el-form-item
                      label-width="0"
                      :prop="
                        'groupReqList.' +
                        index +
                        '.skuPartsList.' +
                        goodsIndex +
                        '.unitId'
                      "
                      :rules="rules[`skuPartsList.unitId`]"
                    >
                      <el-select
                        style="width: 120px"
                        class="marR10"
                        v-model="goodsItem.unitId"
                        placeholder="商品单位"
                        size="mini"
                      >
                        <el-option
                          v-for="unitItem in goodsItem.units"
                          :key="unitItem.unitId"
                          :label="unitItem.unitName"
                          :value="unitItem.unitId"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      label-width="0"
                      :prop="
                        'groupReqList.' +
                        index +
                        '.skuPartsList.' +
                        goodsIndex +
                        '.douYinSalePrice'
                      "
                      :rules="rules[`skuPartsList.douYinSalePrice`]"
                      v-if="douYinProductForm.product.productType != 15"
                    >
                      <el-input
                        style="width: 120px"
                        size="mini"
                        v-model="goodsItem.douYinSalePrice"
                        placeholder="请输入价格"
                        @change="optionalMostChange"
                      ></el-input>
                    </el-form-item>
                    <span
                      v-if="douYinProductForm.product.productType != 15"
                      class="marL10 marR20"
                      >元</span
                    >
                    <i
                      @click="deleteRow(item.skuPartsList, goodsIndex, item)"
                      class="el-icon-delete"
                      style="font-size: 12px"
                    ></i>
                  </div>
                  <div class="x-f marT10" v-if="isMeasure">
                    <el-form-item
                      label-width="50px"
                      :prop="
                        'groupReqList.' +
                        index +
                        '.skuPartsList.' +
                        goodsIndex +
                        '.douYinSalePrice'
                      "
                      label="约"
                    >
                      <el-input
                        style="width: 80px"
                        size="mini"
                        v-model="goodsItem.attrList[0].attr_value.num"
                        placeholder="尺寸"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label-width="0"
                      :prop="
                        'groupReqList.' +
                        index +
                        '.skuPartsList.' +
                        goodsIndex +
                        '.unitId'
                      "
                    >
                      <SelectLocal
                        class="marR20"
                        placeholder="单位"
                        v-model="goodsItem.attrList[0].attr_value.unit"
                        style="width: 120px"
                        :option="{
                          data: sizeUnits,
                          value: 'value',
                          label: 'label',
                        }"
                      />
                    </el-form-item>
                    <el-form-item
                      label-width="0"
                      :prop="
                        'groupReqList.' +
                        index +
                        '.skuPartsList.' +
                        goodsIndex +
                        '.unitId'
                      "
                    >
                      <SelectLocal
                        placeholder="请选择"
                        v-model="goodsItem.attrList[1].attr_value.cake_type"
                        :multiple="true"
                        :option="{
                          data: specList,
                          value: 'value',
                          label: 'label',
                        }"
                      />
                    </el-form-item>
                  </div>
                  <div class="x-f marT10" v-if="isWeight">
                    <el-form-item
                      label-width="50px"
                      :prop="
                        'groupReqList.' +
                        index +
                        '.skuPartsList.' +
                        goodsIndex +
                        '.douYinSalePrice'
                      "
                    >
                      <el-input
                        style="width: 80px"
                        size="mini"
                        v-model="goodsItem.attrList[0].attr_value.num"
                        placeholder="每份重量"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label-width="0"
                      :prop="
                        'groupReqList.' +
                        index +
                        '.skuPartsList.' +
                        goodsIndex +
                        '.unitId'
                      "
                    >
                      <SelectLocal
                        class="marR20"
                        placeholder="单位"
                        v-model="goodsItem.attrList[0].attr_value.unit"
                        style="width: 120px"
                        :option="{
                          data: sizeUnitsWeight,
                          value: 'value',
                          label: 'label',
                        }"
                      />
                    </el-form-item>
                    <el-form-item
                      label-width="0"
                      :prop="
                        'groupReqList.' +
                        index +
                        '.skuPartsList.' +
                        goodsIndex +
                        '.unitId'
                      "
                    >
                      <el-select
                        v-model="goodsItem.attrList[1].attr_value"
                        placeholder="包装方式"
                        size="mini"
                      >
                        <el-option
                          v-for="item in specListWeight"
                          :key="item.value"
                          :label="item.label"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="x-bc">
                  <div
                    class="fontS14B cursorP"
                    @click="addMatchGoods(item.skuPartsList, item)"
                  >
                    <i class="el-icon-plus"></i>
                    <span>添加单品</span>
                  </div>
                  <div>
                    <el-button
                      type="text"
                      class="marR20"
                      @click="groupPosition('top', douYinProductForm.groupReqList, index)"
                      :disabled="index == 0"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      class="marR20"
                      @click="
                        groupPosition('bottom', douYinProductForm.groupReqList, index)
                      "
                      :disabled="index >= douYinProductForm.groupReqList.length - 1"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="deleteRow(douYinProductForm.groupReqList, index)"
                      >删除组</el-button
                    >
                    <!-- <el-button type="text" disabled>文字按钮</el-button> -->
                  </div>
                </div>
              </div>
              <div class="collocationGroup x-bc">
                <span>{{
                  `共${groupReqListLength}组，${skuPartsListLength}个单品，总价值 (用户侧划线价) ${skuPartsPriceLength} 元`
                }}</span>
                <el-button type="primary" @click="addGroup">再加一组</el-button>
              </div>
            </el-form-item>
            <!-- <el-form-item
              label="代金券类型"
              v-if="douYinProductForm.product.productType == 11"
            >
              <el-radio-group v-model="douYinProductForm.posImageNa1">
                <el-radio :label="1">常规代金券</el-radio>
                <el-radio :label="2">动态代金券</el-radio>
              </el-radio-group>
              <div class="regularVouchers">
                <div>常规代金券：面值固定不变</div>
                <div>动态代金券：面值可按消费时间段动态配置</div>
              </div>
            </el-form-item>
            <el-form-item
              label="代金券面额"
              class="denomination"
              v-if="
                douYinProductForm.product.productType == 11 &&
                douYinProductForm.posImageNa1 == 1
              "
            >
              <el-radio-group
                size="mini"
                v-model="douYinProductForm.posImageNa2"
              >
                <el-radio border :label="1">10元</el-radio>
                <el-radio border :label="2">30元</el-radio>
                <el-radio border :label="3">50元</el-radio>
                <el-radio border :label="4">100元</el-radio>
                <el-radio border :label="5">自定义</el-radio>
              </el-radio-group>
              <div v-if="douYinProductForm.posImageNa2 == 5">
                <el-input
                  size="mini"
                  style="width: 180px"
                  class="marR10"
                  v-model="douYinProductForm.posImageName3"
                  placeholder="请输入面值范围1-2000"
                ></el-input>
                <span>元</span>
              </div>
            </el-form-item>
            <el-form-item
              label="代金券面额"
              v-if="
                douYinProductForm.product.productType == 11 &&
                douYinProductForm.posImageNa1 == 2
              "
            >
              <div class="regularVouchers">
                <el-checkbox v-model="douYinProductForm.checked"
                  >区分工作日和周末面值</el-checkbox
                >
                <div class="x-f">
                  <el-time-picker
                    size="mini"
                    is-range
                    v-model="douYinProductForm.value1tim"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    class="marR10"
                    value-format="HH:mm"
                    format="HH:mm"
                    step="30"
                  >
                  </el-time-picker>
                  <div class="x-f" v-if="douYinProductForm.checked">
                    <el-input
                      size="mini"
                      style="width: 160px"
                      class="marR10"
                      v-model="douYinProductForm.posImageName3"
                      placeholder="工作日面额范围1-2000"
                    ></el-input>
                    <span class="marR10">元</span>
                  </div>
                  <div class="x-f" v-if="douYinProductForm.checked">
                    <el-input
                      size="mini"
                      style="width: 160px"
                      class="marR10"
                      v-model="douYinProductForm.posImageName3"
                      placeholder="周末面额范围1-2000"
                    ></el-input>
                    <span class="marR10">元</span>
                  </div>
                  <div class="x-f" v-if="!douYinProductForm.checked">
                    <el-input
                      size="mini"
                      style="width: 180px"
                      class="marR10"
                      v-model="douYinProductForm.posImageName3"
                      placeholder="代金券面额范围1-2000"
                    ></el-input>
                    <span class="marR10">元</span>
                  </div>

                  <i class="el-icon-delete"></i>
                </div>
                <div class="fontS14B cursorP">
                  <i class="el-icon-plus"></i>
                  <span>添加时间段</span>
                  <span>（3/6）</span>
                </div>
              </div>
            </el-form-item> -->
            <el-form-item
              :label="couponType ? 'SKU名称' : '代金券名称'"
              prop="sku.skuName"
            >
              <el-input
                size="mini"
                class="inputWidth"
                v-model="douYinProductForm.sku.skuName"
                placeholder="请输入商品名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="库存数量" prop="limitType">
              <el-radio-group v-model="douYinProductForm.sku.limitType">
                <el-radio :label="1">限库存</el-radio>
                <el-radio :label="2">不限库存</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="总库存"
              prop="sku.stockQty"
              v-if="douYinProductForm.sku.limitType == 1"
            >
              <el-input
                size="mini"
                class="inputWidth marR10"
                v-model="douYinProductForm.sku.stockQty"
                placeholder="请输入总库存"
              ></el-input>
              <span>份</span>
            </el-form-item>
            <el-form-item
              label="可用库存"
              prop="sku.availQty"
              v-if="douYinProductForm.sku.limitType == 1"
            >
              <el-input
                size="mini"
                class="inputWidth marR10"
                v-model="douYinProductForm.sku.availQty"
                placeholder="请输入可用库存"
              ></el-input>
              <span>份</span>
            </el-form-item>
            <el-form-item
              label="原价金额"
              prop="sku.originAmount"
              v-if="douYinProductForm.product.productType !== 15"
            >
              <el-input
                size="mini"
                class="inputWidth marR10"
                v-model="douYinProductForm.sku.originAmount"
                placeholder="请输入原价金额"
              ></el-input>
              <span>元</span>
            </el-form-item>
            <el-form-item
              label="顾客实际需支付"
              prop="sku.actualAmount"
              v-if="douYinProductForm.product.productType !== 15"
            >
              <el-input
                size="mini"
                class="inputWidth marR10"
                v-model="douYinProductForm.sku.actualAmount"
                placeholder="请输入顾客实际需支付"
              ></el-input>
              <span>元</span>
            </el-form-item>
            <el-form-item :label="couponType ? '* 套餐图' : '* 代金券头图'">
              <upload-picture-cropper
                :limit="couponType ? 5 : 1"
                v-model="douYinProductForm.product.imageList"
              />
              <!-- <accessory-upload listType="picture-card" :limit="couponType ? 5 : 1"
                title="" :fileList="comboMealFileList" @getFileItems="getFileItemsDataLogo($event, '套餐图')"
                @delFileItems="delFileItemsLogo($event, '套餐图')" /> -->
              <span v-if="couponType" class="sizeColor"
                >第1张建议选用高清优质图提升客流，上传2~5张，每张图片不得超过2M，建议分辨率为375*280px，比例为4:3
              </span>
            </el-form-item>
            <el-form-item label="* 菜品图" v-if="couponType">
              <upload-picture-cropper
                :limit="dishesGoodsLimit"
                v-model="douYinProductForm.product.dishesImageList"
              />
              <!-- <accessory-upload listType="picture-card" :limit="dishesGoodsLimit" title="" :fileList="dishesFileList"
                @getFileItems="getFileItemsDataLogo($event, '菜品图')" @delFileItems="delFileItemsLogo($event, '菜品图')" /> -->
              <span class="sizeColor"
                >美观的菜品图能提升用户的购买欲望。每张图片不得超过2M，建议分辨率为375*280px，图片比例为4:3
              </span>
            </el-form-item>
            <el-form-item label="* 环境图" v-if="couponType">
              <upload-picture-cropper
                :limit="10"
                v-model="douYinProductForm.product.environmentImageList"
              />
              <!-- <accessory-upload listType="picture-card" :limit="10" title="" :fileList="environmentFileList"
                @getFileItems="getFileItemsDataLogo($event, '环境图')" @delFileItems="delFileItemsLogo($event, '环境图')" /> -->
              <span class="sizeColor"
                >良好的就餐环境也是用户的优先选择，最多可上传10张。每张图片不得超过2M，建议分辨率为375*280px，比例为4:3
              </span>
              <div class="collocationGroup">
                <div class="y-start">
                  <span class="sizeColor">
                    1.图片清晰美观，不包含第三方水印、无拉伸变形无白边；图片内有关限制条款文字必须清晰可见，不得过小过偏
                  </span>
                  <span class="sizeColor">
                    2.图片素材中不得涉及和商家无关的第三方主体或品牌信息；不得使用仿冒官方的词汇或logo元素，如“抖音同款”、“官方推荐”等
                  </span>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="* 适用门店">
              <el-radio-group v-model="douYinProductForm.product.shopIdType">
                <el-radio label="0">全部门店</el-radio>
                <el-radio label="1">指定门店</el-radio>
              </el-radio-group>
              <div>
                <div v-if="douYinProductForm.product.shopIdType == '1'">
                  <el-button
                    class="marB10"
                    type="primary"
                    size="mini"
                    @click="getDistribution"
                    >选择门店</el-button
                  >
                  <el-table
                    ref="multipleTable"
                    tooltip-effect="dark"
                    :data="douYinProductForm.product.shops"
                    border
                    max-height="300px"
                    height="300px"
                    style="width: 700px"
                  >
                    <el-table-column
                      label="序号"
                      align="center"
                      prop="userId"
                      width="80"
                      type="index"
                    />
                    <el-table-column label="门店编号" align="center" prop="shopNo" />
                    <el-table-column label="门店名称" align="center" prop="shopName" />
                  </el-table>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="商品备注" v-if="isMeasure && couponType">
              <div class="y-start">
                <el-checkbox v-model="douYinProductForm.product.canFreePacking"
                  >可免费包装</el-checkbox
                >
                <el-checkbox v-model="douYinProductForm.product.canFreeLettering"
                  >可免费在蛋糕上写字</el-checkbox
                >
                <div>
                  <span class="marR20">含基础配件</span>
                  <SelectLocal
                    placeholder="单位"
                    v-model="douYinProductForm.product.cakeBasicPart.accessory_list"
                    style="width: 200px"
                    :multiple="true"
                    :option="{
                      data: cakeBasicPartList,
                      value: 'value',
                      label: 'label',
                    }"
                  />
                </div>
              </div>
            </el-form-item>
            <el-form-item label="赠品信息" v-if="couponType">
              <div class="regularVouchers">
                <el-form-item label="是否包含赠品" style="margin-bottom: 20px">
                  <el-radio-group
                    v-model="douYinProductForm.product.freebieInfo.has_freebie"
                  >
                    <el-radio :label="true">包含</el-radio>
                    <el-radio :label="false">不包含</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div v-if="douYinProductForm.product.freebieInfo.has_freebie">
                  <el-form-item label="赠品名称" style="margin-bottom: 20px">
                    <el-input
                      size="mini"
                      v-model="douYinProductForm.product.freebieInfo.freebie_name"
                      placeholder="请输入"
                      maxlength="60"
                      show-word-limit
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="赠品库存状态" style="margin-bottom: 20px">
                    <el-radio-group
                      v-model="douYinProductForm.product.freebieInfo.has_stock"
                    >
                      <el-radio :label="true">有库存</el-radio>
                      <el-radio :label="false">无库存</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="赠品预留库存总量" style="margin-bottom: 20px">
                    <el-input-number
                      v-model="douYinProductForm.product.freebieInfo.total_stock_num"
                      controls-position="right"
                      :min="1"
                      :max="9999"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="赠品活动期:" style="margin-bottom: 20px">
                    与商品可消费日期一致
                  </el-form-item>
                  <el-form-item label="兑换规则">
                    <el-input
                      :autosize="{ minRows: 2, maxRows: 10 }"
                      type="textarea"
                      placeholder="请输入赠品兑换规则和注意事项"
                      v-model="douYinProductForm.product.freebieInfo.exchange_rule_desc"
                      maxlength="500"
                      show-word-limit
                    />
                  </el-form-item>
                </div>
              </div>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="售卖信息">
        <template slot="cardContent">
          <el-form-item
            label="可用次数"
            prop="sku.consumptionTimes"
            v-if="douYinProductForm.product.productType === 15"
          >
            <el-input-number
              v-model="douYinProductForm.sku.consumptionTimes"
              controls-position="right"
              :min="0"
              :max="50"
              size="mini"
              class="marR10"
              @change="changeValue('consumptionTimes', $event)"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            label="单次原价"
            prop="sku.originAmountOnce"
            v-if="douYinProductForm.product.productType === 15"
          >
            <el-input
              size="mini"
              class="inputWidth marR10"
              v-model="douYinProductForm.sku.originAmountOnce"
              placeholder="请输入单次原价"
              @input="changeValue('originAmountOnce', $event)"
            ></el-input>
            <span>元</span>
          </el-form-item>
          <el-form-item
            label="次卡原价"
            prop="sku.originAmount"
            v-if="douYinProductForm.product.productType === 15"
          >
            <el-input
              size="mini"
              class="inputWidth marR10"
              v-model="douYinProductForm.sku.originAmount"
              placeholder="自动计算"
              disabled
            ></el-input>
            <span>元</span>
          </el-form-item>
          <el-form-item
            label="次卡定价方式"
            v-if="douYinProductForm.product.productType === 15"
          >
            <el-radio-group v-model="douYinProductForm.sku.pricingMode">
              <el-radio :label="1">均摊定价</el-radio>
              <!-- <el-radio :label="2">阶梯定价</el-radio> -->
            </el-radio-group>
            <!-- <div class="byted-Table-Frame">
              <div class="byted-Table-Head">
                <div class="cell" style="width: 60px">阶梯</div>
                <div class="cell" style="width: 329px; justify-content: flex-start">
                  核销次数(上限50次)
                </div>
                <div class="cell" style="width: 172px; justify-content: flex-start">
                  单次价格(元)
                </div>
                <div class="cell" style="width: 60px">操作</div>
              </div>
              <div class="byted-Table-body">
                <div class="row">
                  <div class="cell" style="width: 60px">1</div>
                  <div class="cell" style="width: 329px; justify-content: flex-start">
                    <el-form-item label="" prop="sku.stockQty">
                      <el-input
                        size="mini"
                        v-model="douYinProductForm.sku.stockQty"
                        placeholder="自动计算"
                        disabled
                      ></el-input>
                    </el-form-item>
                    <span style="margin: 0 5px">~</span>
                    <el-form-item label="" prop="sku.stockQty">
                      <el-input
                        size="mini"
                        v-model="douYinProductForm.sku.stockQty"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="cell" style="width: 172px; justify-content: flex-start">
                    <el-form-item label="" prop="sku.stockQty">
                      <el-input
                        size="mini"
                        v-model="douYinProductForm.sku.stockQty"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="cell" style="width: 60px">
                    <span style="color: #1890ff">删除</span>
                  </div>
                </div>
                <div class="row">
                  <div class="cell" style="color: #1890ff">
                    <span>
                      {{ `+ 添加价格阶梯(0/5)` }}
                    </span>
                  </div>
                </div>
              </div>
            </div> -->
          </el-form-item>
          <el-form-item
            label="顾客实际需支付"
            prop="sku.actualAmount"
            v-if="douYinProductForm.product.productType === 15"
          >
            <el-input
              size="mini"
              class="inputWidth marR10"
              v-model="douYinProductForm.sku.actualAmount"
              placeholder="请输入顾客实际需支付"
            ></el-input>
            <span>元</span>
          </el-form-item>
          <el-form-item label="投放渠道">
            <el-radio-group v-model="douYinProductForm.product.showChannel">
              <!-- <el-radio :label="1">线上售卖</el-radio> -->
              <el-radio :label="1">不限制</el-radio>
              <el-radio :label="2">仅直播间售卖</el-radio>
              <!-- <el-radio :label="3">仅新人频道售卖</el-radio>
              <el-radio :label="4">仅线下售卖</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="库存数量">
            <el-radio-group v-model="douYinProductForm. sku.limitType">
              <el-radio :label="1">限库存</el-radio>
              <el-radio :label="2">不限库存</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="商品售卖日期">
            <div class="regularVouchers">
              <el-form-item
                label="售卖日期"
                label-width="80px"
                prop="product.soldDatetime"
              >
                <el-date-picker
                  unlink-panels
                  size="mini"
                  class="marR10"
                  v-model="douYinProductForm.product.soldDatetime"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @change="datetimeChange($event, 'soldStartTime')"
                >
                </el-date-picker>
                <div class="sizeColor">
                  顾客可以在这个时间内购买团购，超过这个日期，团购活动自动下线
                </div>
              </el-form-item>
              <el-form-item label="自动延期" label-width="80px" class="marT15">
                <el-switch v-model="douYinProductForm.product.autoRenew"> </el-switch>
                <div class="sizeColor">
                  售卖时间到期前一天，商品售卖日期和顾客可消费日期自动延长30天（历史已售订单不受影响，可随时关闭)
                </div>
              </el-form-item>
            </div>
          </el-form-item>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="交易规则">
        <template slot="cardContent">
          <el-form-item label="顾客信息设置">
            <el-radio-group
              v-model="douYinProductForm.product.customerReservedInfo.allow_tel"
            >
              <el-radio :label="false">不需要</el-radio>
              <el-radio :label="true">需要顾客手机号</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="顾客可消费日期">
            <el-radio-group v-model="douYinProductForm.product.useDate.use_date_type">
              <el-radio :label="2">指定天数</el-radio>
              <el-radio :label="1">指定日期</el-radio>
            </el-radio-group>
            <div
              class="regularVouchers"
              v-if="douYinProductForm.product.useDate.use_date_type == 2"
            >
              <span class="marR10">自购买次日起</span>
              <el-input-number
                v-model="douYinProductForm.product.useDate.day_duration"
                controls-position="right"
                :min="1"
                :max="360"
                size="mini"
                class="marR10"
              ></el-input-number>
              <span class="marR10">天</span>
              <span>购买当日默认可用</span>
            </div>
            <div
              class="regularVouchers"
              v-if="douYinProductForm.product.useDate.use_date_type == 1"
            >
              <el-date-picker
                unlink-panels
                size="mini"
                class="marR10"
                v-model="douYinProductForm.product.useDate.datetime"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                @change="datetimeChange($event, 'use_start_date')"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="顾客不可消费日期">
            <div>
              <el-checkbox
                v-model="douYinProductForm.product.canNoUseDate.enable"
              ></el-checkbox>
            </div>
            <div class="y-start" v-if="douYinProductForm.product.canNoUseDate.enable">
              <div class="x-f marB10">
                <div style="width: 100px; text-align: right" class="marR10">
                  每周不可用
                </div>
                <SelectLocal
                  v-model="douYinProductForm.product.canNoUseDate.days_of_week"
                  style="width: 250px"
                  :multiple="true"
                  :option="{
                    data: weekList,
                    value: 'value',
                    label: 'label',
                  }"
                />
              </div>
              <div class="x-f marB10">
                <div style="width: 100px; text-align: right" class="marR10">
                  节假日不可用
                </div>
                <SelectLocal
                  v-model="douYinProductForm.product.canNoUseDate.holidays"
                  style="width: 250px"
                  :multiple="true"
                  :option="{
                    data: festivalsList,
                    value: 'value',
                    label: 'label',
                  }"
                />
              </div>
              <div class="x-f marB10">
                <div style="width: 100px; text-align: right" class="marR10">
                  指定某天不可用
                </div>
                <el-date-picker
                  size="mini"
                  v-model="douYinProductForm.product.canNoUseDate.date_list"
                  type="dates"
                  placeholder="选择多个日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="每日消费时段">
            <el-radio-group
              v-model="douYinProductForm.product.useTime.use_time_type"
              class="marB15"
            >
              <div class="y-start marT10">
                <el-radio class="marB20" :label="1">营业时间可用</el-radio>
                <el-radio :label="2">仅指定时间可用</el-radio>
              </div>
            </el-radio-group>
            <div v-if="douYinProductForm.product.useTime.use_time_type == 2">
              <div
                class="x-f"
                v-for="(item, index) in douYinProductForm.product.useTime
                  .time_period_list"
                :key="index"
              >
                <el-time-picker
                  size="mini"
                  is-range
                  v-model="item.datetime"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  class="marR50"
                  value-format="HH:mm"
                  format="HH:mm"
                  step="30"
                  @change="datetimeChange($event, 'time_period_list', index)"
                >
                </el-time-picker>
                <span
                  class="fontS14B cursorP"
                  @click="
                    deleteRow(douYinProductForm.product.useTime.time_period_list, index)
                  "
                  >删除</span
                >
              </div>
              <div class="fontS14B cursorP" @click="addTime">
                <i class="el-icon-plus"></i>
                <span>添加时间段</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="限购规则">
            <el-checkbox v-model="douYinProductForm.sku.limitRule.is_limit"
              >限制购买</el-checkbox
            >
            <div v-if="douYinProductForm.sku.limitRule.is_limit">
              <span class="marR10">每人最多购买</span>
              <el-input-number
                class="marR10"
                size="mini"
                v-model="douYinProductForm.sku.limitRule.total_buy_num"
                controls-position="right"
                :min="1"
                :max="999"
              ></el-input-number>
              <span>张</span>
            </div>
          </el-form-item>
          <el-form-item label="预约规则">
            <el-checkbox v-model="douYinProductForm.product.appointment.need_appointment"
              >到店前需要预约</el-checkbox
            >
            <div v-if="douYinProductForm.product.appointment.need_appointment">
              <span class="marR10">需提前</span>
              <el-input-number
                v-if="douYinProductForm.product.appointment.ahead_time_type == 1"
                class="marR10"
                size="mini"
                v-model="douYinProductForm.product.appointment.ahead_day_num"
                controls-position="right"
                :min="1"
                :max="9999"
              ></el-input-number>
              <el-input-number
                v-else
                class="marR10"
                size="mini"
                v-model="douYinProductForm.product.appointment.ahead_hour_num"
                controls-position="right"
                :min="1"
                :max="9999"
              ></el-input-number>
              <el-select
                v-model="douYinProductForm.product.appointment.ahead_time_type"
                placeholder="请选择"
                style="width: 70px"
                class="marR10"
                size="mini"
              >
                <el-option label="天" :value="1"></el-option>
                <el-option label="小时" :value="2"></el-option>
              </el-select>
              <span>电话预约</span>
            </div>
          </el-form-item>
          <el-form-item label="退款规则">
            ·到店核销：随时可退，过期未核销自动退
          </el-form-item>
          <!-- <el-form-item label="退款政策">
            <el-radio-group v-model="douYinProductForm.product.refundPolicy">
              <el-radio :label="1">允许退款</el-radio>
              <el-radio :label="2">不可退款</el-radio>
              <el-radio :label="3">有条件退</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <!-- <el-form-item
            label="券码限用规则"
            v-if="couponType"
          >
            <el-radio-group v-model="douYinProductForm.ash">
              <el-radio :label="1">不限制</el-radio>
              <el-radio :label="2">按人限制</el-radio>
              <el-radio :label="3">按桌限制</el-radio>
            </el-radio-group>
            <div class="regularVouchers" v-if="douYinProductForm.ash != 1">
              <span class="marR20">{{
                `每${douYinProductForm.ash == 2 ? '人' : '桌'}限用`
              }}</span>
              <el-input
                size="mini"
                class="inputWidth marR10"
                v-model="douYinProductForm.ash2"
                placeholder="请输入"
              ></el-input>
              <span>张</span>
            </div>
          </el-form-item> -->
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="消费规则">
        <template slot="cardContent">
          <el-form-item label="使用规则标题" class="marT20">
            <el-input
              class="inputWidth"
              placeholder="请输入使用规则标题"
              v-model="douYinProductForm.product.notification[0].title"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="使用规则内容">
            <el-input
              class="inputWidth"
              placeholder="请输入使用规则内容"
              v-model="douYinProductForm.product.notification[0].content"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="使用规则">
            <el-checkbox v-model="douYinProductForm.product.superimposedDiscounts"
              >可与店内其它优惠同享，详询商家</el-checkbox
            >
            <el-checkbox v-if="couponType" v-model="douYinProductForm.product.privateRoom"
              >可以使用包间</el-checkbox
            >
          </el-form-item>
          <!-- <el-form-item label="其他规则" v-if="douYinProductForm.product.productType === 15">
            <el-checkbox v-model="douYinProductForm.product.superimposedDiscounts"
              >可与他人共用</el-checkbox
            >
            <el-checkbox v-model="douYinProductForm.product.privateRoom"
              >每次到店可使用多次</el-checkbox
            >
            <el-input-number
              v-model="douYinProductForm.sku.consumptionTimes"
              controls-position="right"
              :min="1"
              :max="50"
              size="mini"
              class="marL10"
            ></el-input-number>
          </el-form-item> -->
          <el-form-item
            label="使用张数限制"
            v-if="douYinProductForm.product.productType == 11"
          >
            <el-radio-group
              v-model="douYinProductForm.product.limitUseRule.is_limit_use"
              class="marT10"
            >
              <el-radio class="marB20" :label="false">不限张数</el-radio>
              <el-radio :label="true">限制张数 </el-radio>
            </el-radio-group>
            <div
              class="regularVouchers"
              v-if="douYinProductForm.product.limitUseRule.is_limit_use"
            >
              <span class="marR10">单次消费最多使用</span>
              <el-input-number
                class="marR10"
                size="mini"
                v-model="douYinProductForm.product.limitUseRule.use_num_per_consume"
                controls-position="right"
                :min="1"
                :max="999"
              ></el-input-number>
              <span>张</span>
            </div>
          </el-form-item>
          <el-form-item label="其他说明信息">
            <el-input
              class="inputWidth"
              type="textarea"
              placeholder="请输入其他规则限制，规则需与其他商品信息前后一致，多条规则请用回车换行区分"
              v-model="douYinProductForm.product.descriptionRichText[0].content"
              maxlength="1000"
              show-word-limit
              :autosize="{ minRows: 4, maxRows: 6 }"
            >
            </el-input>
          </el-form-item>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect"; //树形结构下拉框
import "@riophae/vue-treeselect/dist/vue-treeselect.css"; //树形结构下拉框样式
import uploadPictureCropper from "@/views/components/accessoryUpload/cutOut";
import AccessoryUpload from "@/views/components/accessoryUpload";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import SelectRemote from "@/components/tablePage/select/select-remote";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import SelectLocal from "@/components/tablePage/select/select-local";
import {
  saveDouYinProduct,
  detailDouYinProduct,
  updateDouYinProduct,
  getDouYinCategory,
} from "@/api/O2OThird/douyin/coupon";
import Dialog from "@/components/Dialog";
import { fcount } from "@/utils";
import { deepCopy } from "ali-oss/lib/common/utils/deepCopy";
export default {
  name: "DouyinShopDetail",
  components: {
    Treeselect,
    uploadPictureCropper,
    cardTitleCom,
    topOperatingButton,
    SelectRemote,
    AccessoryUpload,
    SelectLocal,
    Dialog,
  },
  dicts: ["douyin_category"],
  data() {
    return {
      imgA: [],
      specListWeight: [
        {
          value: 1,
          label: "袋装",
        },
        {
          value: 2,
          label: "散装",
        },
      ], //种类
      sizeUnitsWeight: [
        {
          value: "g",
          label: "g",
        },
        {
          value: "kg",
          label: "kg",
        },
        {
          value: "斤",
          label: "斤",
        },
        {
          value: "公斤",
          label: "公斤",
        },
      ], //尺寸
      cakeBasicPartList: [
        {
          value: "刀叉盘",
          label: "刀叉盘",
        },
        {
          value: "普通蜡烛",
          label: "普通蜡烛",
        },
        {
          value: "数字蜡烛",
          label: "数字蜡烛",
        },
        {
          value: "生日帽",
          label: "生日帽",
        },
        {
          value: "火柴",
          label: "火柴",
        },
      ],
      isWeight: false,
      isMeasure: false,
      specList: [
        {
          value: "纯动物奶油",
          label: "纯动物奶油",
        },
        {
          value: "乳脂奶油",
          label: "乳脂奶油",
        },
        {
          value: "植物奶油",
          label: "植物奶油",
        },
        {
          value: "水果",
          label: "水果",
        },
        {
          value: "慕斯",
          label: "慕斯",
        },
        {
          value: "冰淇淋",
          label: "冰淇淋",
        },
        {
          value: "芝士",
          label: "芝士",
        },
        {
          value: "提拉米苏",
          label: "提拉米苏",
        },
      ], //种类
      sizeUnits: [
        {
          value: "寸",
          label: "寸",
        },
        {
          value: "英寸",
          label: "英寸",
        },
        {
          value: "厘米",
          label: "厘米",
        },
        {
          value: "磅",
          label: "磅",
        },
      ], //尺寸
      defaultProp: {
        value: "categoryId",
        label: "name",
        emitPath: false,
      },
      douYinCategory: [], //抖音分类
      //弹窗配置
      dialogOptions: {
        title: "选择门店",
        width: 1250,
        show: false,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "shop" }),
      },
      recPersonRadio: undefined,
      recPersonNumVirtual: undefined,
      comboMealFileList: [], //套餐
      dishesFileList: [], //菜品
      environmentFileList: [], //环境
      matchGoodsList: [
        {
          value: 1,
          label: "全部可选",
        },
        {
          value: 2,
          label: "2选一",
        },
      ],
      numberDinersList: [
        {
          value: 1,
          label: "1人",
        },
        {
          value: 2,
          label: "2人",
        },
        {
          value: 3,
          label: "3人",
        },
        {
          value: 4,
          label: "4人",
        },
        {
          value: 5,
          label: "5人",
        },
        {
          value: 6,
          label: "6人",
        },
        {
          value: 7,
          label: "7人",
        },
        {
          value: 8,
          label: "8人",
        },
        {
          value: 9,
          label: "9人",
        },
        {
          value: 10,
          label: "10人",
        },
        {
          value: 11,
          label: "11人",
        },
        {
          value: 12,
          label: "12人",
        },
        {
          value: 13,
          label: "13人",
        },
        {
          value: 14,
          label: "14人",
        },
        {
          value: 15,
          label: "15人",
        },
        {
          value: 16,
          label: "16人",
        },
        {
          value: 17,
          label: "17人",
        },
        {
          value: 18,
          label: "18人",
        },
        {
          value: 19,
          label: "19人",
        },
        {
          value: 20,
          label: "20人",
        },
      ],
      festivalsList: [
        { label: "元旦", value: 1 },
        { label: "春节", value: 2 },
        { label: "清明节", value: 3 },
        { label: "劳动节", value: 4 },
        { label: "端午节", value: 5 },
        { label: "中秋节", value: 6 },
        { label: "国庆节", value: 7 },
        { label: "情人节", value: 8 },
        { label: "圣诞节", value: 9 },
      ],
      weekList: [
        { label: "周一", value: 1 },
        { label: "周二", value: 2 },
        { label: "周三", value: 3 },
        { label: "周四", value: 4 },
        { label: "周五", value: 5 },
        { label: "周六", value: 6 },
        { label: "周日", value: 7 },
      ],
      //遮罩层
      loading: false,
      //旧的表单
      oldForm: {},
      //plus会员方案表单
      douYinProductForm: {
        groupReqList: [
          {
            optionCount: 1,
            skuPartsList: [
              {
                unitQty: 1,
                douYinSalePrice: "",
                attrList: [
                  {
                    assembly_type: 9,
                    attr_key: "item_size",
                    attr_key_name: "服务单位尺寸",
                    attr_value: {},
                    is_multi: false,
                  },
                  {
                    assembly_type: 0,
                    attr_key: "cake_type",
                    attr_key_name: "",
                    attr_value: { cake_type: [] },
                    is_multi: false,
                  },
                ],
              },
            ],
            matchGoodsList: [
              {
                value: 1,
                label: "全部可选",
              },
            ],
          },
        ], //搭配商品
        product: {
          soldDatetime: undefined, //售卖日期
          recPersonNum: 1, //建议使用人数
          recPersonNumMax: undefined, //建议使用人数
          productType: 1, //抖音类型
          showChannel: 1, //投放渠道
          refundPolicy: 1, //退款政策
          superimposedDiscounts: false, //是否包含赠品
          shopIdType: "0", //适用门店
          shops: [], //适用门店
          imageList: [], //套餐图(JSON)
          useDate: {
            datetime: undefined, //日期转换
            use_date_type: 2, // 1指定日期 2指定天数
            day_duration: 360, // 购买后X天有效，use_date_type=2时有效
            use_start_date: undefined, // yyyy-MM-dd 开始日期，use_date_type=1时有效
            use_end_date: undefined, // yyyy-MM-dd 结束日期，use_date_type=1时有效
          }, //使用日期(JSON)
          useTime: {
            use_time_type: 1, // 1全天可用，2仅指定时间可用
            time_period_list: [
              {
                datetime: undefined,
                use_start_time: undefined,
                use_end_time: undefined,
              },
            ],
          }, //使用时间(JSON)
          canNoUseDate: {
            enable: false, //开关
            days_of_week: [], // 指定周几不可用
            holidays: [], // 指定节假日不可用
            date_list: [], //  yy-MM-dd 指定日期，不可用
          }, //不可使用日期(JSON)
          appointment: {
            need_appointment: false, // 是否需要预约
            ahead_time_type: 1, //DAY = 1,HOUR = 2,MINUTE = 3
            ahead_day_num: 1, // 需要提前X天电话预约
            ahead_hour_num: undefined, // 需要提前X小时电话预约
          }, //预约信息(JSON)
          dishesImageList: [], //菜品图(JSON)
          environmentImageList: [], //环境图(JSON)
          customerReservedInfo: { allow_tel: true }, //留资原因(JSON)
          superimposedDiscounts: true, //是否可与店内其他优惠同享
          privateRoom: true, //是否可以使用包间
          bringOutMeal: true, //是否可以外带餐食
          descriptionRichText: [{ note_type: 1, content: undefined }], //其他说明信息(JSON)
          notification: [{ title: "", content: "" }], //使用规则(JSON)
          limitUseRule: {
            is_limit_use: true, // 是否限用
            use_num_per_consume: 1, // 每人单次消费最多使用代金劵张数
          }, //是否限制、每人单次消费最多使用代金劵张数(JSON)
          canFreePacking: false, //是否可免费备注
          canFreeLettering: false, //是否可免费在蛋糕上写字
          cakeBasicPart: { accessory_list: [] }, //是否含基础配件
          freebieInfo: {
            has_freebie: false, //是否有赠品
            freebie_name: "", //赠品名称
            has_stock: false, //是否赠品库存
            total_stock_num: 1, //库存
            valid_date_desc: "与商品可消费日期一致", //赠品活动期
            exchange_rule_desc: "", //赠品规则
          },
        }, //产品信息
        sku: {
          limitType: 1, //库存上限类型
          limitRule: {
            is_limit: false, // 是否限购
            total_buy_num: 1, // 每人最多购买X张
          }, //限制规则(JSON)
          actualAmountOnce: undefined, //商品单次售卖价
          consumptionTimes: 1, // 可用次数
          enableMultiConsumeOnce: 1, //每次到店可使用多次
          orderSettleRule: undefined, // 结算规则
          originAmountOnce: undefined, // 商品单次原价
          pricingMode: 1, // 次卡定价方式
          // onceSellingPrice: [{}], //次卡阶梯价格规则（JSON）
        },
        onceSellingPriceList: [],
      },
      //plus会员方案表单校验
      rules: {
        "product.productNo": [
          {
            required: true,
            message: "请输入券方案编号",
            trigger: ["blur", "change"],
          },
        ],
        "product.productName": [
          {
            required: true,
            message: "请输入券方案名称",
            trigger: ["blur", "change"],
          },
        ],
        "product.categoryId": [
          {
            required: true,
            message: "请选择抖音品类",
            trigger: ["blur", "change"],
          },
        ],
        "product.productType": [
          {
            required: true,
            message: "请选择商品类型",
            trigger: ["blur", "change"],
          },
        ],
        "product.productSourceType": [
          {
            required: true,
            message: "请选择商品来源",
            trigger: ["blur", "change"],
          },
        ],
        "sku.skuName": [
          {
            required: true,
            message: "请输入SKU名称",
            trigger: ["blur", "change"],
          },
        ],
        "sku.stockQty": [
          {
            required: true,
            message: "请输入总库存",
            trigger: ["blur", "change"],
          },
        ],
        "sku.availQty": [
          {
            required: true,
            message: "请输入可用库存",
            trigger: ["blur", "change"],
          },
        ],
        "sku.originAmount": [
          {
            required: true,
            message: "请输入原价金额",
            trigger: ["blur", "change"],
          },
        ],
        "sku.actualAmount": [
          {
            required: true,
            message: "请输入顾客实际需支付",
            trigger: ["blur", "change"],
          },
        ],
        "sku.consumptionTimes": [
          {
            required: true,
            message: "请输入可用次数",
            trigger: ["blur", "change"],
          },
        ],
        "product.soldDatetime": [
          {
            required: true,
            message: "请选择售卖日期",
            trigger: ["blur", "change"],
          },
        ],
        "groupReqList.groupName": [
          {
            required: true,
            message: "请输入商品组名称",
            trigger: ["blur", "change"],
          },
        ],
        "groupReqList.optionCount": [
          {
            required: true,
            message: "请选择最多可选",
            trigger: ["blur", "change"],
          },
        ],
        "skuPartsList.goodsId": [
          {
            required: true,
            message: "请选择团购商品",
            trigger: ["blur", "change"],
          },
        ],
        "skuPartsList.unitId": [
          {
            required: true,
            message: "请选择团购商品单位",
            trigger: ["blur", "change"],
          },
        ],
        "skuPartsList.douYinSalePrice": [
          {
            required: true,
            message: "请选择团购商品金额",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    couponType() {
      return (
        this.douYinProductForm?.product?.productType == 1 ||
        this.douYinProductForm?.product?.productType == 15
      );
    },
    groupReqListLength() {
      return this.douYinProductForm.groupReqList?.length || 0;
    },
    skuPartsListLength() {
      return (
        this.douYinProductForm.groupReqList.reduce(
          (acc, curr) => acc + curr.skuPartsList?.length,
          0
        ) || 0
      );
    },
    skuPartsPriceLength() {
      const groupReqList = this.douYinProductForm.groupReqList;
      let total =
        groupReqList.reduce((acc, curr) => {
          const topNSalePrice = this.getTopNSalePrice(
            curr.skuPartsList,
            curr.optionCount
          );
          return acc + topNSalePrice;
        }, 0) || 0;
      if (total > 0) {
        total = total.toFixed(2);
      }
      return total;
    },
    dishesGoodsLimit() {
      return (
        this.douYinProductForm.groupReqList.reduce((limit, item) => {
          return item.skuPartsList.reduce((acc, v) => {
            if (v.goodsId) {
              acc += 1;
            }
            return acc;
          }, limit);
        }, 0) || 1
      );
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 重置数据
      vm.reset();
      try {
        const res = await getDouYinCategory();
        vm.douYinCategory = vm.handleTreeList(res.data);
        console.log("douYinCategory", vm.douYinCategory);
        // 访问组件实例 vm 中的数据和方法
        if (vm.$route.query.type === "Update" && vm.$route.query.productId) {
          //获取单据详情
          const { data } = await detailDouYinProduct(vm.$route.query.productId);
          if (
            data.product?.recPersonNumMax > 0 &&
            data.product?.recPersonNum != data.product?.recPersonNumMax
          ) {
            vm.recPersonRadio = 5;
          }
          data.groupReqList = data.groupReqList.map((item) => {
            item.matchGoodsList = vm.matchGoodsListMethod(item.skuPartsList.length);
            item.skuPartsList = item.skuPartsList.map((v) => {
              if (v.attrList) {
                v.attrList = JSON.parse(v.attrList);
              } else {
                if (data.product?.categoryId == 1014004) {
                  v.attrList = [
                    {
                      assembly_type: 9,
                      attr_key: "item_size",
                      attr_key_name: "服务单位尺寸",
                      attr_value: {},
                      is_multi: false,
                    },
                    {
                      assembly_type: 0,
                      attr_key: "cake_type",
                      attr_key_name: "",
                      attr_value: { cake_type: [] },
                      is_multi: false,
                    },
                  ];
                } else if (data.product?.categoryId == 6666666666) {
                  v.attrList = [
                    {
                      assembly_type: 9,
                      attr_key: "item_size",
                      attr_key_name: "服务单位尺寸",
                      attr_value: {},
                      is_multi: false,
                    },
                    {
                      assembly_type: 7,
                      attr_key: "packing_way",
                      attr_key_name: "",
                      attr_value: undefined,
                      is_multi: false,
                    },
                  ];
                } else {
                  v.attrList = undefined;
                }
              }
              return v;
            });
            return item;
          });
          //单据赋值
          vm.douYinProductForm = {
            product: {
              ...data.product,
              imageList: data.product?.imageList
                ? JSON.parse(data.product?.imageList)
                : [],
              useDate: JSON.parse(data.product?.useDate),
              useTime: data.product?.useTime
                ? JSON.parse(data.product?.useTime)
                : {
                    use_time_type: 1, // 1全天可用，2仅指定时间可用
                    time_period_list: [
                      {
                        datetime: undefined,
                        use_start_time: undefined,
                        use_end_time: undefined,
                      },
                    ],
                  },
              canNoUseDate: JSON.parse(data.product?.canNoUseDate),
              appointment: JSON.parse(data.product?.appointment),
              dishesImageList: data.product?.dishesImageList
                ? JSON.parse(data.product?.dishesImageList)
                : [],
              environmentImageList: data.product?.environmentImageList
                ? JSON.parse(data.product?.environmentImageList)
                : [],
              customerReservedInfo: JSON.parse(data.product?.customerReservedInfo),
              descriptionRichText: JSON.parse(data.product?.descriptionRichText),
              notification: data.product?.notification
                ? JSON.parse(data.product?.notification)
                : [{ title: "", content: "" }],
              shopIdType: data.product.shopIds == "0" ? "0" : "1",
              soldDatetime:
                data?.product?.soldStartTime && data?.product?.soldEndTime
                  ? [data?.product?.soldStartTime, data?.product?.soldEndTime]
                  : undefined,
              limitUseRule: data.product?.limitUseRule
                ? JSON.parse(data.product?.limitUseRule)
                : {
                    is_limit_use: false,
                    use_num_per_consume: 1,
                  },
              cakeBasicPart: data.product?.cakeBasicPart
                ? JSON.parse(data.product?.cakeBasicPart)
                : { accessory_list: [] },
              freebieInfo: data.product?.freebieInfo
                ? JSON.parse(data.product?.freebieInfo)
                : {
                    has_freebie: false, //是否有赠品
                    freebie_name: "", //赠品名称
                    has_stock: false, //是否赠品库存
                    total_stock_num: 1, //库存
                    valid_date_desc: "与商品可消费日期一致", //赠品活动期
                    exchange_rule_desc: "", //赠品规则
                  },
            },
            sku: {
              ...data.sku,
              limitRule: JSON.parse(data.sku.limitRule),
            },
            groupReqList: data.groupReqList,
          };
          if (data.product?.categoryId == 1014004) {
            vm.isMeasure = true;
          } else if (data.product?.categoryId == 6666666666) {
            vm.isWeight = true;
          }
          //日期
          if (
            vm.douYinProductForm.product?.useDate?.use_start_date &&
            vm.douYinProductForm?.product?.useDate.use_end_date
          ) {
            vm.douYinProductForm.product.useDate.datetime = [
              vm.douYinProductForm.product?.useDate?.use_start_date,
              vm.douYinProductForm?.product?.useDate.use_end_date,
            ];
          }
          // console.log('11')
          vm.douYinProductForm.product.useTime.time_period_list = vm.douYinProductForm.product.useTime?.time_period_list?.map(
            (item) => {
              if (item.use_start_time && item.use_end_time) {
                item.datetime = [item.use_start_time, item.use_end_time];
              }
              return item;
            }
          );
          vm.douYinProductForm.product.imageList = vm?.douYinProductForm?.product?.imageList?.filter(
            (item) => item != undefined
          );
          //套餐
          vm.comboMealFileList =
            vm.douYinProductForm.product.imageList.length > 0
              ? vm.douYinProductForm.product.imageList.map((item) => ({
                  name: item?.url,
                  url: item?.url,
                }))
              : [];
          //菜品
          vm.dishesFileList =
            vm.douYinProductForm.product.dishesImageList.length > 0
              ? vm.douYinProductForm.product.dishesImageList.map((item) => ({
                  name: item.url,
                  url: item.url,
                }))
              : [];
          //环境
          vm.environmentFileList =
            vm.douYinProductForm.product.environmentImageList.length > 0
              ? vm.douYinProductForm.product.environmentImageList.map((item) => ({
                  name: item.url,
                  url: item.url,
                }))
              : [];
          vm.douYinProductForm.product.douyinProductIdOld = deepCopy(
            vm.douYinProductForm.product.douyinProductId
          );
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.douYinProductForm));
          console.log("详情", deepCopy(vm.douYinProductForm));
        } else {
          //判断是否有以前新增时保存的数据
          if (JSON.stringify(vm.oldForm) != "{}") {
            vm.douYinProductForm = vm.oldForm;
          }
          vm.oldForm = JSON.parse(JSON.stringify(vm.douYinProductForm));
        }
      } finally {
        vm.loading = false;
      }
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.douYinProductForm.productId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.douYinProductForm));
    }
    next();
  },
  methods: {
    changeProductType(val) {
      console.log("类型---", val, this.douYinProductForm);
    },
    //抖音分类
    categoryChange() {
      if (this.douYinProductForm?.product?.categoryId == 1014004) {
        this.isMeasure = true;
        this.isWeight = false;
        this.douYinProductForm.groupReqList = [
          {
            optionCount: 1,
            skuPartsList: [
              {
                unitQty: 1,
                douYinSalePrice: "",
                attrList: [
                  {
                    assembly_type: 9,
                    attr_key: "item_size",
                    attr_key_name: "服务单位尺寸",
                    attr_value: {},
                    is_multi: false,
                  },
                  {
                    assembly_type: 0,
                    attr_key: "cake_type",
                    attr_key_name: "",
                    attr_value: { cake_type: [] },
                    is_multi: false,
                  },
                ],
              },
            ],
            matchGoodsList: [
              {
                value: 1,
                label: "全部可选",
              },
            ],
          },
        ]; //搭配商品
      } else if (this.douYinProductForm?.product?.categoryId == 6666666666) {
        this.isMeasure = false;
        this.isWeight = true;
        this.douYinProductForm.groupReqList = [
          {
            optionCount: 1,
            skuPartsList: [
              {
                unitQty: 1,
                douYinSalePrice: "",
                attrList: [
                  {
                    assembly_type: 9,
                    attr_key: "item_size",
                    attr_key_name: "服务单位尺寸",
                    attr_value: {},
                    is_multi: false,
                  },
                  {
                    assembly_type: 7,
                    attr_key: "packing_way",
                    attr_key_name: "",
                    attr_value: undefined,
                    is_multi: false,
                  },
                ],
              },
            ],
            matchGoodsList: [
              {
                value: 1,
                label: "全部可选",
              },
            ],
          },
        ]; //搭配商品
      } else {
        this.isMeasure = false;
        this.isWeight = false;
        this.douYinProductForm.groupReqList = [
          {
            optionCount: 1,
            skuPartsList: [
              {
                unitQty: 1,
                douYinSalePrice: "",
              },
            ],
            matchGoodsList: [
              {
                value: 1,
                label: "全部可选",
              },
            ],
          },
        ]; //搭配商品
      }
      // this.reset()
      console.log("分类", this.douYinProductForm);
    },
    handleTreeList(list) {
      // 删除第三级children
      for (var i = 0; i < list.length; i++) {
        if (list[i].children.length < 1) {
          // 判断children的数组长度
          list[i].children = undefined;
        } else {
          this.handleTreeList(list[i].children);
        }
      }
      return list;
    },
    //添加使用时间
    addTime() {
      if (
        !this.douYinProductForm.product.useTime.time_period_list ||
        this.douYinProductForm.product.useTime.time_period_list?.length == 0
      ) {
        console.log("ssssss", this.douYinProductForm.product);
        // this.douYinProductForm.product.useTime.time_period_list = [
        //   {
        //     datetime: undefined,
        //     use_start_time: undefined,
        //     use_end_time: undefined
        //   }
        // ]
        this.$set(this.douYinProductForm.product.useTime, "time_period_list", [{}]);
      } else {
        console.log("ssssss2", this.douYinProductForm.product);
        this.douYinProductForm.product.useTime.time_period_list.push({});
      }
    },
    //选择商品多少个
    optionalMostChange() {
      // this.douYinProductForm.sku.originAmount = 0
      // this.douYinProductForm.groupReqList.forEach(item => {
      //   this.douYinProductForm.sku.originAmount += Math.max(
      //     ...item.skuPartsList.map(obj => obj.douYinSalePrice)
      //   )
      // })
    },
    getTopNSalePrice(skuPartsList, n) {
      // 创建skuPartsList的副本，以避免改变原数组
      const sortedSkuPartsList = [...skuPartsList].sort((a, b) => {
        const priceA = a?.douYinSalePrice * a?.unitQty || 0;
        const priceB = b?.douYinSalePrice * b?.unitQty || 0;
        return priceB - priceA; // 降序排序
      });

      // 取出前n个元素的乘积之和
      return sortedSkuPartsList.slice(0, n).reduce((acc, curr) => {
        const price = curr?.douYinSalePrice * curr?.unitQty || 0;
        return acc + price;
      }, 0);
    },
    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          this.douYinProductForm.product.shops = this.dialogOptions.formData.table.check;
          break;
        default:
          break;
      }
    },
    //打开弹窗
    getDistribution() {
      this.dialogOptions.formData = this.$dialog({
        key: "douYinShopList",
      });
      this.dialogOptions.formData.table.echoChecked =
        this.douYinProductForm.product.shops?.map((item) => item.shopId) || [];
      this.dialogOptions.show = true;
    },
    //生成选多数组
    matchGoodsListMethod(skuLen) {
      const data = [];
      for (let i = 1; i <= skuLen; i++) {
        if (skuLen > i) {
          data.push({
            value: i,
            label: `${skuLen}选${i}`,
          });
        }
      }
      data.push({
        value: skuLen,
        label: "全部可选",
      });
      return data;
    },
    // 次卡修改值 可用次数 单次原价
    changeValue(type, value) {
      switch (type) {
        case "consumptionTimes": // 可用次数
          this.$set(
            this.douYinProductForm.sku,
            "originAmount",
            fcount([value, this.douYinProductForm.sku.originAmountOnce], "*")
          );
          break;
        case "originAmountOnce": // 单次原价
          this.$set(
            this.douYinProductForm.sku,
            "originAmount",
            fcount([value, this.douYinProductForm.sku.consumptionTimes], "*")
          );
          break;
        default:
          break;
      }
    },
    //售卖日期
    datetimeChange(value, key, index) {
      switch (key) {
        case "soldStartTime":
          if (value) {
            this.douYinProductForm.product.soldStartTime = value[0];
            this.douYinProductForm.product.soldEndTime = value[1];
          } else {
            this.douYinProductForm.product.soldStartTime = undefined;
            this.douYinProductForm.product.soldEndTime = undefined;
          }
          break;
        case "use_start_date":
          if (value) {
            this.douYinProductForm.product.useDate.use_start_date = value[0];
            this.douYinProductForm.product.useDate.use_end_date = value[1];
          } else {
            this.douYinProductForm.product.useDate.use_start_date = undefined;
            this.douYinProductForm.product.useDate.use_end_date = undefined;
          }
          break;
        case "time_period_list":
          if (value) {
            this.douYinProductForm.product.useTime.time_period_list[
              index
            ].use_start_time = value[0];
            this.douYinProductForm.product.useTime.time_period_list[index].use_end_time =
              value[1];
          } else {
            this.douYinProductForm.product.useTime.time_period_list[
              index
            ].use_start_time = undefined;
            this.douYinProductForm.product.useTime.time_period_list[
              index
            ].use_end_time = undefined;
          }
          break;
        default:
          break;
      }
    },
    //添加分组
    addGroup() {
      let obj = {};
      if (this.douYinProductForm?.product?.categoryId == 1014004) {
        obj = {
          optionCount: 1,
          skuPartsList: [
            {
              unitQty: 1,
              douYinSalePrice: "",
              attrList: [
                {
                  assembly_type: 9,
                  attr_key: "item_size",
                  attr_key_name: "服务单位尺寸",
                  attr_value: {},
                  is_multi: false,
                },
                {
                  assembly_type: 0,
                  attr_key: "cake_type",
                  attr_key_name: "",
                  attr_value: { cake_type: [] },
                  is_multi: false,
                },
              ],
            },
          ],
          matchGoodsList: [
            {
              value: 1,
              label: "全部可选",
            },
          ],
        };
      } else if (this.douYinProductForm?.product?.categoryId == 6666666666) {
        obj = {
          optionCount: 1,
          skuPartsList: [
            {
              unitQty: 1,
              douYinSalePrice: "",
              attrList: [
                {
                  assembly_type: 9,
                  attr_key: "item_size",
                  attr_key_name: "服务单位尺寸",
                  attr_value: {},
                  is_multi: false,
                },
                {
                  assembly_type: 7,
                  attr_key: "packing_way",
                  attr_key_name: "",
                  attr_value: undefined,
                  is_multi: false,
                },
              ],
            },
          ],
          matchGoodsList: [
            {
              value: 1,
              label: "全部可选",
            },
          ],
        };
      } else {
        obj = {
          optionCount: 1,
          skuPartsList: [
            {
              unitQty: 1,
              douYinSalePrice: "",
            },
          ],
          matchGoodsList: [
            {
              value: 1,
              label: "全部可选",
            },
          ],
        };
      }
      this.douYinProductForm.groupReqList.push(obj);
    },
    //移动分组
    groupPosition(type, groupReqList, index) {
      if (type == "top") {
        // 如果 type 是 'top'，则将当前对象向上移动
        if (index > 0) {
          const movedItem = groupReqList.splice(index, 1); // 删除当前对象
          groupReqList.splice(index - 1, 0, movedItem[0]); // 在它上面的位置插入当前对象
        }
      } else if (type == "bottom") {
        // 如果 type 是 'bottom'，则将当前对象向下移动
        if (index < groupReqList.length - 1) {
          const movedItem = groupReqList.splice(index, 1); // 删除当前对象
          groupReqList.splice(index + 1, 0, movedItem[0]); // 在它下面的位置插入当前对象
        }
      }
    },
    //删除元素
    deleteRow(rows, index, row) {
      rows.splice(index, 1);
      if (row) {
        row.matchGoodsList = this.matchGoodsListMethod(row.skuPartsList.length);
      }
    },
    //搭配单品
    addMatchGoods(skuPartsList, row) {
      skuPartsList.push({
        unitQty: 1,
        douYinSalePrice: "",
        attrList: [
          {
            assembly_type: 9,
            attr_key: "item_size",
            attr_key_name: "服务单位尺寸",
            attr_value: {},
            is_multi: false,
          },
          {
            assembly_type: 0,
            attr_key: "cake_type",
            attr_key_name: "",
            attr_value: { cake_type: [] },
            is_multi: false,
          },
        ],
      });
      row.matchGoodsList = this.matchGoodsListMethod(row.skuPartsList.length);
    },
    //商品规格
    selectChangeGoods(val, goods, index, goodsIndex) {
      this.douYinProductForm.groupReqList[index].skuPartsList[goodsIndex].units =
        goods.units;
      this.douYinProductForm.groupReqList[index].skuPartsList[goodsIndex].unitId =
        goods.unitId;
      this.douYinProductForm.groupReqList[index].skuPartsList[
        goodsIndex
      ].douYinSalePrice = goods.salePrice;
    },
    recPersonNumVirtualChange(value) {
      if (value) {
        this.$nextTick(() => {
          this.douYinProductForm.product.recPersonNum = value;
          this.douYinProductForm.product.recPersonNumMax = undefined;
          this.recPersonRadio = undefined;
        });
      }
    },
    recPersonRadioChange(value) {
      if (value) {
        this.$nextTick(() => {
          this.recPersonNumVirtual = undefined;
          this.douYinProductForm.product.recPersonNum = undefined;
        });
      }
    },
    //上传阿里图库 Logo
    getFileItemsDataLogo(value, inUrl) {
      if (inUrl == "套餐图") {
        this.douYinProductForm?.product?.imageList?.push({
          url: value?.accessUrl,
        });
      } else if (inUrl == "菜品图") {
        this.douYinProductForm?.product?.dishesImageList?.push({
          url: value?.accessUrl,
        });
      } else if (inUrl == "环境图") {
        this.douYinProductForm?.product?.environmentImageList?.push({
          url: value?.accessUrl,
        });
      }
    },
    //删除阿里图库 Logo
    delFileItemsLogo(value, inUrl) {
      if (inUrl == "套餐图") {
        this.douYinProductForm.product.imageList = this.douYinProductForm?.product?.imageList?.filter(
          (v) => v.url != value?.url
        );
      } else if (inUrl == "菜品图") {
        this.douYinProductForm.product.dishesImageList = this.douYinProductForm?.product?.dishesImageList?.filter(
          (v) => v.url != value?.url
        );
      } else if (inUrl == "环境图") {
        this.douYinProductForm.product.environmentImageList = this.douYinProductForm?.product?.environmentImageList?.filter(
          (v) => v.url != value?.url
        );
      }
    },
    /** 提交按钮 */
    async submitForm() {
      if (
        !this.douYinProductForm?.product?.soldStartTime ||
        !this.douYinProductForm?.product?.soldEndTime
      )
        return this.$message.error("请选择售卖日期！");
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (
            this.recPersonRadio == 5 &&
            (!this.douYinProductForm.product.recPersonNum ||
              !this.douYinProductForm.product.recPersonNumMax)
          ) {
            return this.$message.error("最小用餐人数或者最大用餐人数不能为0");
          }
          let douYinProductForm = deepCopy(this.douYinProductForm);
          let submitDouYinProductForm = {
            product: {
              ...douYinProductForm.product,
              imageList: JSON.stringify(douYinProductForm.product.imageList),
              useDate: JSON.stringify(douYinProductForm.product.useDate),
              useTime: JSON.stringify(douYinProductForm.product.useTime),
              canNoUseDate: JSON.stringify(douYinProductForm.product.canNoUseDate),
              appointment: JSON.stringify(douYinProductForm.product.appointment),
              dishesImageList: JSON.stringify(douYinProductForm.product.dishesImageList),
              environmentImageList: JSON.stringify(
                douYinProductForm.product.environmentImageList
              ),
              customerReservedInfo: JSON.stringify(
                douYinProductForm.product.customerReservedInfo
              ),
              notification: JSON.stringify(douYinProductForm.product.notification),
              descriptionRichText: JSON.stringify(
                douYinProductForm.product.descriptionRichText
              ),
              limitUseRule: JSON.stringify(douYinProductForm.product.limitUseRule),
              shopIds:
                douYinProductForm.product.shopIdType == "0"
                  ? "0"
                  : douYinProductForm.product.shops.map((item) => item.shopId).join(","),
              cakeBasicPart: JSON.stringify(douYinProductForm.product.cakeBasicPart),
              freebieInfo: JSON.stringify(douYinProductForm.product.freebieInfo),
            },
            sku: {
              ...douYinProductForm.sku,
              limitRule: JSON.stringify(douYinProductForm.sku.limitRule),
            },
            groupReqList: douYinProductForm.groupReqList.map((item) => {
              item.totalCount = item.skuPartsList.filter((v) => v.goodsId).length;
              item.skuPartsList = item.skuPartsList.map((v) => {
                v.attrList = JSON.stringify(v.attrList);
                return v;
              });
              return item;
            }),
          };
          let res = {};
          console.log("提交参数", deepCopy(submitDouYinProductForm));
          console.log("保存回参", deepCopy(douYinProductForm));
          if (douYinProductForm.product.productId) {
            res = await updateDouYinProduct(submitDouYinProductForm);
          } else {
            res = await saveDouYinProduct(submitDouYinProductForm);
          }

          this.$message.success("保存成功");
          // 关闭当前tab页签，打开新页签
          this.getQuit();
        }
      });
    },
    // 表单重置
    reset() {
      this.douYinProductForm = {
        groupReqList: [
          {
            optionCount: 1,
            skuPartsList: [
              {
                unitQty: 1,
                douYinSalePrice: "",
              },
            ],
            matchGoodsList: [
              {
                value: 1,
                label: "全部可选",
              },
            ],
          },
        ], //搭配商品
        product: {
          soldDatetime: undefined, //售卖日期
          recPersonNum: 1, //建议使用人数
          recPersonNumMax: undefined, //建议使用人数
          productType: 1, //抖音类型
          showChannel: 1, //投放渠道
          refundPolicy: 1, //退款政策
          superimposedDiscounts: false, //是否包含赠品
          shopIdType: "0", //适用门店
          shops: [], //适用门店
          imageList: [], //套餐图(JSON)
          useDate: {
            datetime: undefined, //日期转换
            use_date_type: 2, // 1指定日期 2指定天数
            day_duration: 360, // 购买后X天有效，use_date_type=2时有效
            use_start_date: undefined, // yyyy-MM-dd 开始日期，use_date_type=1时有效
            use_end_date: undefined, // yyyy-MM-dd 结束日期，use_date_type=1时有效
          }, //使用日期(JSON)
          useTime: {
            use_time_type: 1, // 1全天可用，2仅指定时间可用
            time_period_list: [
              {
                datetime: undefined,
                use_start_time: undefined,
                use_end_time: undefined,
              },
            ],
          }, //使用时间(JSON)
          canNoUseDate: {
            enable: false, //开关
            days_of_week: [], // 指定周几不可用
            holidays: [], // 指定节假日不可用
            date_list: [], //  yy-MM-dd 指定日期，不可用
          }, //不可使用日期(JSON)
          appointment: {
            need_appointment: false, // 是否需要预约
            ahead_time_type: 1, //DAY = 1,HOUR = 2,MINUTE = 3
            ahead_day_num: 1, // 需要提前X天电话预约
            ahead_hour_num: undefined, // 需要提前X小时电话预约
          }, //预约信息(JSON)
          dishesImageList: [], //菜品图(JSON)
          environmentImageList: [], //环境图(JSON)
          customerReservedInfo: { allow_tel: true }, //留资原因(JSON)
          superimposedDiscounts: true, //是否可与店内其他优惠同享
          privateRoom: true, //是否可以使用包间
          bringOutMeal: true, //是否可以外带餐食
          descriptionRichText: [{ note_type: 1, content: undefined }], //其他说明信息(JSON)
          notification: [{ title: "", content: "" }], //使用规则(JSON)
          limitUseRule: {
            is_limit_use: true, // 是否限用
            use_num_per_consume: 1, // 每人单次消费最多使用代金劵张数
          }, //是否限制、每人单次消费最多使用代金劵张数(JSON)
          canFreePacking: false, //是否可免费备注
          canFreeLettering: false, //是否可免费在蛋糕上写字
          cakeBasicPart: { accessory_list: [] }, //是否含基础配件
          freebieInfo: {
            has_freebie: false, //是否有赠品
            freebie_name: "", //赠品名称
            has_stock: false, //是否赠品库存
            total_stock_num: 1, //库存
            valid_date_desc: "与商品可消费日期一致", //赠品活动期
            exchange_rule_desc: "", //赠品规则
          },
        }, //产品信息
        sku: {
          limitType: 1, //库存上限类型
          limitRule: {
            is_limit: false, // 是否限购
            total_buy_num: 1, // 每人最多购买X张
          }, //限制规则(JSON)
          actualAmountOnce: undefined, //商品单次售卖价
          consumptionTimes: 1, // 可用次数
          enableMultiConsumeOnce: 1, //每次到店可使用多次
          orderSettleRule: undefined, // 结算规则
          originAmountOnce: undefined, // 商品单次原价
          pricingMode: 1, // 次卡定价方式
          // onceSellingPrice: [{}], //次卡阶梯价格规则（JSON）
        },
      };
      this.resetForm("form");
    },
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/O2OThird/douyin/coupon" });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  .regularVouchers {
    width: 700px;
    margin-top: 4px;
    padding: 16px;
    border-radius: 6px;
    background: #f7f9fc;
    font-size: 12px;
    color: #333333;
    .goodsTips {
      color: #6c737a;
    }
  }
  .denomination {
    .el-radio {
      margin-right: 0;
    }
  }
  .collocationGroup {
    width: 700px;
    box-sizing: border-box;
    margin-top: 12px;
    min-height: 68px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f0fbff;
    border-radius: 4px;
  }
  .el-icon-delete {
    border-radius: 6px;
    padding: 10px;
    font-size: 16px;
    background-color: #ffffff;
    cursor: pointer;
  }
  .sizeColor {
    font-size: 12px;
    color: #6c737a;
  }
  //   ::v-deep .el-form-item {
  //     margin: 0;
  //     padding: 0;
  //   }
  //输入框原生属性
  /**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
  ::v-deep .el-input__inner {
    line-height: 1px !important;
  }

  /**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
  ::v-deep input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.byted-Table-Frame {
  width: 629px;
  border: 1px solid #f0f2f5;
  border-bottom: none;
  .byted-Table-Head {
    display: flex;
    background: #f7f9fc;
    height: 55px;
    border-bottom: 1px solid #f0f2f5;
  }
  .byted-Table-body {
    .row {
      display: flex;
      height: 60px;
      border-bottom: 1px solid #f0f2f5;
    }
  }
  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
}
</style>
