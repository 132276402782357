<template>
  <div>
    <div class="bg-Color marB10 x-f-end padd15">
      <el-button type="primary" size="mini" @click="setTransactionSettings">保存</el-button>
    </div>
    <cardTitleCom cardTitle="超时设置" class="bg-Color marT10">
      <template #cardContent>
        <div class="padd15 y-start fontColor">
          <div class="marB20">
            <span class="fontColor">先款后货订单是否开启超时自动取消订单</span>
            <div class="x-f">
              <el-checkbox v-model="form.mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_11.switchConfig">开启超时自动取消</el-checkbox>
              <span>（下单后，系统每半小时检查一次系统待审核订单，若已超过</span>
              <el-select class="marL10 marR10" size="small" style="width: 100px"
                v-model="form.mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_11.valueConfig" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label+'分钟'" :value="item.value">
                </el-option>
              </el-select>
              <span>仍然未完成支付，将自动取消订单）</span>
            </div>
          </div>
          <div class="x-f">
            <span>支持商城订单0元支付</span>
            <el-switch class="marL20" v-model="form.mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_12">
            </el-switch>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="支付设置" class="bg-Color marT10">
      <template #cardContent>
        <div class="padd15 y-start fontColor">
          <div class="x-f">
            <span>会员支付余额不足时，允许剩下部分使用微信支付</span>
            <el-switch class="marL20" v-model="form.mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_13" active-value="Y"
            inactive-value="N">
            </el-switch>
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom/index.vue'
import {
  saveUpMallTransactionSettingsConfigDetail,
  getMallTransactionSettingsConfigDetail
} from '@/api/O2OMall/config/sysConfig'

export default {
  name: 'dealSetting',
  components: {
    cardTitleCom,
  },
  data() {
    return {
      checked: '',
      value: '',
      options: [
        { value: 30, label: '30' },
        { value: 60, label: '60' },
      ],
      form: {
        mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_12: false,
        mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_11: {
          switchConfig: false,
          valueConfig: ''
        }
      }
    }
  },
  created() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      getMallTransactionSettingsConfigDetail().then(res => {
        console.log('交易：', res)
        this.form = res.data
        this.form.mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_11 = res.data.mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_11 != null ? res.data.mall_SYSTEM_CONFIG_TRANSACTION_SETTINGS_11 : {}
      })
    },
    setTransactionSettings() {
      saveUpMallTransactionSettingsConfigDetail(this.form).then(res => {
        this.$message.success('提交成功')
        this.getDetails()
      })
    }
  }

}
</script>

<style scoped>
</style>
