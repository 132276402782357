import request from '@/utils/request'

// 新增修改商城开屏广告
export function saveOrUpdate(data) {
  return request({
    url: '/api/system/mall/config/screen/ad/saveOrUpdate',
    method: 'post',
    data
  })
}
// 获取商城开屏广告详情
export function getDetail(params) {
  return request({
    url: '/api/system/mall/config/screen/ad/getDetail',
    method: 'get',
    params
  })
}