<template>
  <div>
    <div style="margin-bottom: 20px;">
      魔方密度:
      <el-select size="mini" v-model="density" @change="onChangeDensity">
        <el-option v-for="(item,index) in densityList" :value='item' :key="index">{{item + ' x ' + item}}</el-option>
      </el-select>
    </div>

    <div class="decorate-cube">
      <ul class="cube-col" v-for="(n,ni) in densityNum" :key="ni">
        <li :class="['cube-item', {'item-selecting': isSelecting(n,i), 'item-selected':isSelected(n,i)}]"
          v-for="(i,ii) in densityNum" :style="{'width':cubeCellWidth+'px','height':cubeCellHeight+'px'}" :data-x="n"
          :data-y="i" @click="onClickCubeItem($event)" @mouseenter="onEnterCubeItem($event)" :key="ii">
          <!-- <van-icon name="plus" color="#bbbbb" :style="{'line-height':cubeCellHeight+'px'}" /> -->
        </li>
      </ul>

      <div class="cube-selected" v-for="(item, index) in selectedList"
        :style="{'width':getSelectedWidth(item)+'px','height':getSelectedHeight(item)+'px','top':getSelectedTop(item)+'px','left':getSelectedLeft(item)+'px'}"
        @mouseenter="onEnterSelected(index)" :key="index" :class="{'active':active == index}"
        @click="changeActive(index)">
        <img :src="item.imageUrl" v-if="item.imageUrl" :height="`${getSelectedHeight(item) - 2}px`"
          :width="`${getSelectedWidth(item) - 2}px`" />
        <div class="cube-selected-text" v-else>
          {{ Math.round(750/density*((parseInt(item.end.x) - parseInt(item.start.x) + 1))) }}
          x
          {{ Math.round(750/density*((parseInt(item.end.y) - parseInt(item.start.y) + 1))) }}
          像素
        </div>
        <div class="del" v-if="active == index" @click.stop="delSelectItem(index)">
          <i class="el-icon-error" />
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  name: 'cubeMatrix',
  data() {
    return {
      active: -1,
      'densityList': ['4', '5', '6', '7'],
      'density': '4', //密度。
      'cubeWidth': 320, //魔方宽度。
      'cubeHeight': 320, //魔方高度。
      'selectingItem': {
        'tempStart': null,
        'tempEnd': null,
        'start': null,
        'end': null
      },
    }
  },
  model: { prop: 'value', event: 'modelValue' },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    densityCom: {
      type: String,
      default: '4'
    }
  },
  computed: {
    //选中列表。
    selectedList: {
      get() {
        return this.value
      },
      set(e) {
        this.$emit('modelValue', e)
      }
    },
    //密度值。
    densityNum: function () {
      return parseInt(this.density);
    },
    //单元魔方高度。
    cubeCellHeight: function () {
      return this.cubeHeight / this.density;
    },
    //单元魔方宽度。
    cubeCellWidth: function () {
      return this.cubeWidth / this.density;
    }
  },
  watch: {
    densityCom: {
      handler(val) {
        this.density = val;
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    changeActive(index) {
      this.active = index;
      this.$emit('activeChange', index)
    },
    delSelectItem(index) {
      this.selectedList.splice(index, 1);
      this.active = -1;
      this.$emit('activeChange', index)
    },
    updateSelecting() {
      //获取开始和结束之间的所有魔方单元。
      var tempStart = this.selectingItem.tempStart;
      var tempEnd = this.selectingItem.tempEnd;

      this.selectingItem.start = {
        x: Math.min(tempStart.x, tempEnd.x)
        , y: Math.min(tempStart.y, tempEnd.y)
      };
      this.selectingItem.end = {
        x: Math.max(tempStart.x, tempEnd.x)
        , y: Math.max(tempStart.y, tempEnd.y)
      };
    },
    //清除正在选择的。
    clearSelecting() {
      this.selectingItem.tempStart = null;
      this.selectingItem.tempEnd = null;
      this.selectingItem.start = null;
      this.selectingItem.end = null;
    },
    coordFromCubeEvent(event) {
      var el = event.currentTarget;
      var x = el.getAttribute('data-x');
      var y = el.getAttribute('data-y');
      console.log('track_x_y_[' + x + ', ' + y + ']');
      return { x: x, y: y };
    },
    isContain(x, y, item) {
      return (item.start.x <= x
        && x <= item.end.x
        && item.start.y <= y
        && y <= item.end.y);
    },
    //魔方点击事件。
    onClickCubeItem: function (event) {
      var domclass = event.currentTarget.getAttribute('class');
      if (-1 !== domclass.indexOf('item-selected')) {
        console.log("已经被占用");
        return;
      }

      var coord = this.coordFromCubeEvent(event);

      if (null == this.selectingItem.tempStart) {
        this.selectingItem.tempStart = coord;
        this.selectingItem.tempEnd = coord;
        this.selectingItem.start = coord;
        this.selectingItem.end = coord;
        return;
      }

      this.selectingItem.tempEnd = coord;
      this.updateSelecting();

      //加入选中的。
      var selectedItem = {
        'start': this.selectingItem.start,
        'end': this.selectingItem.end,
        'width': this.wxWidth(this.selectingItem),
        'height': this.wxHeight(this.selectingItem),
        'top': this.getSelectedTop2(this.selectingItem),
        'left': this.getSelectedLeft2(this.selectingItem),
        'xCount': parseInt(this.selectingItem.end.x) - parseInt(this.selectingItem.start.x) + 1,
        'yCount': parseInt(this.selectingItem.end.y) - parseInt(this.selectingItem.start.y) + 1
      }
      this.selectedList.push(selectedItem);
      this.clearSelecting();
      console.log('selectedItem', this.selectedList);
    },
    // 计算相对宽度
    wxWidth(item) {
      return Math.round(750 / this.density * ((parseInt(item.end.x) - parseInt(item.start.x) + 1)))
    },
    // 计算相对高度
    wxHeight(item) {
      return Math.round(750 / this.density * ((parseInt(item.end.y) - parseInt(item.start.y) + 1)))
    },
    onEnterCubeItem: function (event) {
      console.log("track_onEnterCube");
      if (this.selectingItem.tempStart) {
        var coord = this.coordFromCubeEvent(event);
        this.selectingItem.tempEnd = coord;
        this.updateSelecting();
      }
    },
    onEnterSelected: function (index) {
      if (this.selectingItem.tempStart != null) {
        this.clearSelecting();
        // splice() 会先从原数组中添加/删除项目 然后返回被删除的项目。
        this.selectedList.splice(index, 1);
      }
    },
    onChangeDensity: function () {
      this.clearSelecting();
      this.selectedList = [];
      this.$emit('onChangeDensity', this.density);
    },
    //判断是否正在选择
    isSelecting: function (x, y) {
      var item = this.selectingItem;
      if (item.tempStart) {
        return this.isContain(x, y, item);
      }
      return false;
    },
    //判断是否已经选择。
    isSelected: function (x, y) {
      var list = this.selectedList;
      for (var i = 0; i < list.length; i++) {
        if (this.isContain(x, y, list[i])) {
          return true;
        }
      }
      return false;
    },
    //计算选中层的宽度。
    getSelectedWidth: function (item) {
      return (parseInt(item.end.x) - parseInt(item.start.x) + 1) * this.cubeCellWidth;
    },
    //计算选中层的高度。
    getSelectedHeight: function (item) {
      return (parseInt(item.end.y) - parseInt(item.start.y) + 1) * this.cubeCellHeight;
    },
    //计算选中层的右边距离。
    getSelectedTop: function (item) {
      return (item.start.y - 1) * this.cubeCellHeight;
    },
    //计算选中层的左边距离。
    getSelectedLeft: function (item) {
      return (item.start.x - 1) * this.cubeCellWidth;
    },
    //计算选中层的右边距离。
    getSelectedTop2: function (item) {
      return (item.start.y - 1) * ( 375 /this.density);
    },
    //计算选中层的左边距离。
    getSelectedLeft2: function (item) {
      return (item.start.x - 1) * ( 375 /this.density);
    }
  }
}
</script>
<style lang='scss' scoped>
.decorate-cube {
  width: 320px;
  height: 320px;
  position: relative;
}

.decorate-cube .cube-item {
  border-right: 1px solid #e5e5e5;
}

.decorate-cube .cube-selected {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ebedf0;
  text-align: center;
  color: #7d7e80;
  cursor: pointer;
  box-sizing: border-box;
  .del {
    position: absolute;
    font-size: 18px;
    opacity: 0.5;
    top: -9px;
    right: -9px;
    z-index: 2;
  }
  img {
    // height: inherit;
    vertical-align: baseline;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.decorate-cube .active {
  border: 1px solid #155bd4;
  z-index: 2;
  background: #e0edff;
}
.decorate-cube .cube-selected.cube-selected-click {
  background: #e0edff;
  border: 1px solid #155bd4;
  color: #155bd4;
  z-index: 2;
  cursor: auto;
}

.decorate-cube .cube-selected-text {
  font-size: 12px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.decorate-cube .cube-col {
  float: left;
  list-style: none;
  padding: 0;
  margin: 0;
}

.decorate-cube .cube-item:first-child {
  border-top: 1px solid #e5e5e5;
}

.decorate-cube .cube-item {
  background: #f8f8f8;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
}

.decorate-cube .cube-item.item-selecting {
  background: #e0edff;
}

.decorate-cube .cube-item.item-selected {
  background: #e0edff;
  visibility: hidden;
}

.moreSettings {
  padding: 10px 0 10px 15px;
  margin-top: 20px;
  background-color: #f7f8fa;
  .choosedImageItem {
    position: relative;
    width: 80px;
    height: 80px;
    border: 1px solid #e5e5e5;
    text-align: center;
    margin-right: 10px;
    .thumbImage {
      min-height: 80px;
      box-sizing: border-box;
      vertical-align: bottom;
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto;
    }
    .modifyImage {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 80px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
    .el-icon-circle-close {
      position: absolute;
      top: 0;
      right: 0;
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer;
      font-size: 22px;
    }
  }
}
</style>