var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrap x-bc",
      style: {
        paddingTop:
          (_vm.data.style.pagePaddingTop
            ? _vm.data.style.pagePaddingTop
            : "0") + "px",
        paddingRight:
          (_vm.data.style.pagePaddingRight
            ? _vm.data.style.pagePaddingRight
            : "0") + "px",
        paddingBottom:
          (_vm.data.style.pagePaddingBottom
            ? _vm.data.style.pagePaddingBottom
            : "0") + "px",
        paddingLeft:
          (_vm.data.style.pagePaddingLeft
            ? _vm.data.style.pagePaddingLeft
            : "0") + "px",
      },
    },
    [
      _vm.data.styleImage == "fixed"
        ? _c(
            "div",
            {
              staticClass: "x-bc",
              staticStyle: { width: "100%" },
              style: {
                backgroundColor: _vm.data.style.bgColor,
                borderRadius: _vm.data.style.pageImgRadius + "px",
              },
            },
            _vm._l(_vm.data.immageNavList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "y-c",
                  staticStyle: { padding: "10px" },
                  style: { width: 100 / _vm.data.immageNavList.length + "%" },
                },
                [
                  _vm.data.textFormworkNav == "imageFormwork"
                    ? _c(
                        "div",
                        [
                          item.imgUrl != ""
                            ? _c("el-image", {
                                style: {
                                  width: (_vm.data.style.fontSize || 38) + "px",
                                  height:
                                    (_vm.data.style.fontSize || 38) + "px",
                                },
                                attrs: { src: item.imgUrl },
                              })
                            : _c(
                                "div",
                                [
                                  _c("el-image", {
                                    style: {
                                      width:
                                        (_vm.data.style.fontSize || 38) + "px",
                                      height:
                                        (_vm.data.style.fontSize || 38) + "px",
                                    },
                                    attrs: {
                                      src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  item.title
                    ? _c(
                        "span",
                        {
                          staticClass: "title x-c",
                          style: { color: _vm.data.style.textColor },
                        },
                        [_vm._v(_vm._s(item.title))]
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          )
        : _vm.data.styleImage == "roll"
        ? _c(
            "div",
            {
              staticClass: "swipe x-bc",
              staticStyle: { width: "100%" },
              style: {
                backgroundColor: _vm.data.style.bgColor,
                borderRadius: _vm.data.style.pageImgRadius + "px",
              },
            },
            [
              _c(
                "el-scrollbar",
                _vm._l(_vm.data.immageNavList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "y-c",
                      staticStyle: { padding: "10px" },
                      style: [{ width: "200px" }],
                    },
                    [
                      _vm.data.textFormworkNav == "imageFormwork"
                        ? _c(
                            "div",
                            [
                              item.imgUrl != ""
                                ? _c("el-image", {
                                    style: {
                                      width:
                                        (_vm.data.style.fontSize || 38) + "px",
                                      height:
                                        (_vm.data.style.fontSize || 38) + "px",
                                    },
                                    attrs: { src: item.imgUrl },
                                  })
                                : _c(
                                    "div",
                                    { staticStyle: { padding: "7px" } },
                                    [
                                      _c("el-image", {
                                        style: {
                                          width:
                                            (_vm.data.style.fontSize || 38) +
                                            "px",
                                          height:
                                            (_vm.data.style.fontSize || 38) +
                                            "px",
                                        },
                                        attrs: {
                                          src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass: "title x-c",
                          staticStyle: { padding: "0 7px" },
                          style: { color: _vm.data.style.textColor },
                        },
                        [_vm._v(_vm._s(item.title))]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }