<template>
  <div class="warp">
    <el-form
      v-loading="loading"
      :model="supplierForm"
      :rules="rules"
      ref="supplierForm"
      label-width="100px"
    >
      <topOperatingButton
        :isSubmitAddBtn="false"
        :isAuditBillBtn="false"
        @submitForm="submitForm"
        @getQuit="getQuit"
        id="topOperatingButton"
      >
        <template slot="specialDiyBtn">
          <el-button @click="cancel" size="mini">重置</el-button>
        </template>
      </topOperatingButton>
      <cardTitleCom :hideCard="true" style="margin-bottom: 0px">
        <template slot="cardContent">
          <!-- 基本信息模块 -->
          <div class="navTitle">基本信息</div>
          <el-row :span="10">
            <el-col :span="6">
              <el-form-item
                label="供应商编码"
                :label-width="formLabelWidth"
                prop="partnerNo"
              >
                <el-input
                  size="mini"
                  class="supplierInput"
                  v-model="supplierForm.partnerNo"
                  placeholder="请输入供应商编码"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="供应商名称"
                :label-width="formLabelWidth"
                prop="partnerName"
              >
                <el-input
                  size="mini"
                  class="supplierInput"
                  v-model="supplierForm.partnerName"
                  placeholder="请输入供应商名称"
                  maxlength="40"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="供应商区域"
                :label-width="formLabelWidth"
                prop="areaId"
              >
                <treeselect
                  size="mini"
                  class="treese"
                  v-model="supplierForm.areaId"
                  :options="treeArea"
                  :show-count="true"
                  placeholder="请输入供应商区域"
                  :normalizer="normalizer"
                  @input="inputSelectArea"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="供应商组别"
                :label-width="formLabelWidth"
                prop="groupId"
              >
                <treeselect
                  size="mini"
                  class="treese"
                  v-model="supplierForm.groupId"
                  :options="treeGroup"
                  :show-count="true"
                  placeholder="请输入供应商组别"
                  :normalizer="normalizer"
                  @input="inputSelectGroup"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 基本信息第二行 -->
          <el-row>
            <el-col :span="6">
              <el-form-item label="价格方案">
                <el-select
                  size="mini"
                  style="width: 220px"
                  placeholder="请选择价格方案"
                  v-model="supplierForm.priceCaseId"
                >
                  <el-option
                    v-for="item in priceCaseList"
                    :label="item.priceCaseName"
                    :value="item.priceCaseId"
                    :key="item.priceCaseId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="结算期限">
                <el-select
                  size="mini"
                  placeholder="请选择结算期限"
                  v-model="supplierForm.settlementTermId"
                >
                  <el-option
                    v-for="item in saleEmployeeList"
                    :label="item.settlementTermName"
                    :value="item.settlementTermId"
                    :key="item.settlementTermId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item
                label="结算方式"
                :label-width="formLabelWidth"
                prop="payType"
              >
                <el-radio-group v-model="supplierForm.payType">
                  <el-radio
                    v-for="dict in dict.type.pay_type"
                    :key="dict.value"
                    :label="parseInt(dict.value)"
                    >{{ dict.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item
                label="状态"
                :label-width="formLabelWidth"
                prop="status"
              >
                <el-radio-group v-model="supplierForm.status">
                  <el-radio
                    v-for="dict in dict.type.common_status"
                    :key="dict.value"
                    :label="parseInt(dict.value)"
                    >{{ dict.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="供应商类型"
                :label-width="formLabelWidth"
                prop="partnerType"
              >
                <el-radio-group v-model="supplierForm.partnerType">
                  <el-radio :label="0">客供</el-radio>
                  <el-radio :label="2">供应商</el-radio>
                </el-radio-group>
              </el-form-item></el-col
            >
          </el-row>

          <!-- 账款信息模块 -->
          <div class="navTitle">账款信息</div>
          <!-- 账款信息第一行 -->
          <el-row>
            <el-col :span="6">
              <el-form-item
                label="授信额度(元)"
                :label-width="formLabelWidth"
                prop="creditAmount"
              >
                <el-input
                  size="mini"
                  class="supplierInput"
                  v-model="supplierForm.creditAmount"
                  placeholder="请输入授信额度"
                  maxlength="20"
                  @input="
                    limitInputlength(
                      supplierForm.creditAmount,
                      'supplierForm.creditAmount'
                    )
                  "
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="已用额度(元)"
                :label-width="formLabelWidth"
                prop="usedAmount"
              >
                <el-input
                  size="mini"
                  class="supplierInput"
                  v-model="supplierForm.usedAmount"
                  placeholder="请输入已用额度"
                  maxlength="20"
                  @input="
                    limitInputlength(
                      supplierForm.usedAmount,
                      'supplierForm.usedAmount'
                    )
                  "
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="剩余额度(元)"
                :label-width="formLabelWidth"
                prop="usableAmount"
              >
                <el-input
                  size="mini"
                  class="supplierInput"
                  v-model="supplierForm.usableAmount"
                  placeholder="请输入剩余额度"
                  maxlength="20"
                  @input="
                    limitInputlength(
                      supplierForm.usableAmount,
                      'supplierForm.usableAmount'
                    )
                  "
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 更多信息模块 -->
          <div class="navTitle">更多信息</div>
          <el-row>
            <el-col :span="6">
              <el-form-item
                label="开户银行"
                :label-width="formLabelWidth"
                prop="invoiceDepositBank"
              >
                <el-input
                  size="mini"
                  class="supplierInput"
                  v-model="supplierForm.invoiceDepositBank"
                  placeholder="请输入开户银行"
                  maxlength="40"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="开户名称"
                :label-width="formLabelWidth"
                prop="invoiceBankName"
              >
                <el-input
                  size="mini"
                  class="supplierInput"
                  v-model="supplierForm.invoiceBankName"
                  placeholder="请输入开户名称"
                  maxlength="40"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="银行账户"
                :label-width="formLabelWidth"
                prop="invoiceBankNo"
              >
                <el-input
                  size="mini"
                  class="supplierInput"
                  v-model="supplierForm.invoiceBankNo"
                  placeholder="请输入银行账户"
                  maxlength="40"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="税率(%)"
                :label-width="formLabelWidth"
                prop="taxRate"
              >
                <el-input
                  size="mini"
                  class="supplierInput"
                  v-model="supplierForm.taxRate"
                  placeholder="请输入税率"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">
              <el-form-item
                label="发票抬头"
                :label-width="formLabelWidth"
                prop="invoiceTitle"
              >
                <el-input
                  size="mini"
                  class="supplierInput"
                  v-model="supplierForm.invoiceTitle"
                  placeholder="请输入发票抬头"
                  maxlength="40"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="纳税人识别号"
                :label-width="formLabelWidth"
                prop="invoiceLicenseNo"
              >
                <el-input
                  size="mini"
                  class="supplierInput"
                  v-model="supplierForm.invoiceLicenseNo"
                  placeholder="请输入纳税人识别号"
                  maxlength="40"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="邮编"
                :label-width="formLabelWidth"
                prop="postalCode"
              >
                <el-input
                  size="mini"
                  class="supplierInput"
                  v-model="supplierForm.postalCode"
                  placeholder="请输入邮编"
                  maxlength="20"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="地址"
                :label-width="formLabelWidth"
                prop="regionId"
              >
                <el-cascader
                  size="mini"
                  class="address"
                  :options="provinceList"
                  :props="defaultProp"
                  v-model="supplierForm.regionId"
                  maxlength="80"
                  placeholder="请选择地址"
                  filterable
                  remote
                  reserve-keyword
                  clearable
                >
                </el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12"
              ><el-form-item
                label="详细地址"
                :label-width="formLabelWidth"
                prop="address"
              >
                <el-input
                  size="mini"
                  v-model="supplierForm.address"
                  placeholder="请输入详细地址"
                  maxlength="80"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item
              label="备注"
              :label-width="formLabelWidth"
              prop="remark"
            >
              <el-input
                size="mini"
                class="remark"
                v-model="supplierForm.remark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 2, maxRows: 4 }"
              ></el-input>
            </el-form-item>
          </el-row>
          <!-- 联系方式模块 -->
          <div class="navTitle">联系方式</div>
          <el-form-item label-width="10px">
            <el-table
              class="table"
              ref="dragTable"
              :data="supplierForm.linkmanList"
              row-key="columnId"
              height="500"
              max-height="500"
              border
              @cell-mouse-enter="cellMouseEnter"
              @cell-mouse-leave="cellMouseLeave"
            >
              <el-table-column
                align="center"
                :label="tableCellLabel"
                width="80"
              >
                <template v-slot="scope">
                  <i
                    v-if="scope.row.hoverRow"
                    :disabled="disabled"
                    @click="row('push', scope.$index)"
                    class="el-icon-circle-plus operatePush"
                  ></i>
                  <i
                    v-if="scope.row.hoverRow"
                    :disabled="disabled"
                    @click="row('del', scope.$index)"
                    class="el-icon-remove operateDel"
                  ></i>
                  <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column label="联系人名称" align="center">
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    class="contactName"
                    v-model="scope.row.linkMan"
                    maxlength="40"
                  ></el-input>
                </template>
              </el-table-column>

              <el-table-column label="联系人电话" align="center">
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    class="contactNo"
                    v-model="scope.row.linkManTel"
                    maxlength="20"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="联系人地址" align="center">
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    class="contactAddress"
                    v-model="scope.row.linkAddress"
                    maxlength="80"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="备注" align="center">
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    class="contactAddress"
                    v-model="scope.row.remark"
                    maxlength="80"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
      </cardTitleCom>
    </el-form>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { getAreaTree } from '@/api/goods/area.js'
import { listTree } from '@/api/goods/group.js'
import {
  addSupplier,
  updateSupplier,
  getSupplierDetail
} from '@/api/goods/supplier.js'
import { getAllRegion } from '@/api/goods/region' //地址
import { priceCaseListAPI } from '@/api/system/goodsPrice/priceCase' //商品价格方案
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import { limitInputlength } from '@/utils/index.js' //保留几位小数
import { listSettlementTerm } from "@/api/system/settlementTerm/index";//结算期限
export default {
  name: 'addSupplier',
  components: { Treeselect, cardTitleCom, topOperatingButton },
  dicts: ['common_status', 'pay_type'],
  data () {
    return {
      saleEmployeeList: [], //结算期限
      priceCaseList: [], //商品价格
      tableCellLabel: '序号',
      loading: false, //加载
      provinceList: [], //全部行政地区
      dialogFormVisible: false, //对话框显示
      treeArea: [], //区域tree
      treeGroup: [], //组别tree
      defaultProp: {
        value: 'id',
        label: 'label'
      }, //地址
      supplierForm: {
        linkmanList: [{ linkMan: '', linkManTel: '', linkAddress: '' }],
        status: 0, //状态
        partnerType: 2, //客户类型
        payType: 0 //结算方式
      }, //整体表单
      //旧的表单
      oldForm: {},
      //整体表单的校验
      rules: {
        // invoiceDepositBank: [
        //   { pattern: /^[0-9]{1,40}$/, message: '请输入1 ~ 40个数值类型的字符' }
        // ], //开户银行
        // invoiceBankName: [
        //   { pattern: /^[0-9]{1,40}$/, message: '请输入1 ~ 40个数值类型的字符' }
        // ], //开户名称
        // invoiceLicenseNo: [
        //   { pattern: /^[0-9]{1,40}$/, message: '请输入1 ~ 40个数值类型的字符' }
        // ], //纳税人识别号
        // invoiceBankNo: [
        //   { pattern: /^[0-9]{1,20}$/, message: '请输入1 ~ 20个数值类型的字符' }
        // ], //银行账号
        // invoiceTitle: [
        //   { pattern: /^[0-9]{1,40}$/, message: '请输入1 ~ 40个数值类型的字符' }
        // ], //发票抬头
        // taxRate: [
        //   { pattern: /^[0-9]{1,10}$/, message: '请输入1 ~ 10个数值类型的字符' }
        // ], //税率
        partnerName: [
          {
            required: true,
            message: '供应商名称不能为空',
            trigger: ['blur', 'change']
          },
          { pattern: /^.{1,40}$/, message: '请输入1 ~ 40个字符' }
        ],
        partnerNo: [
          {
            required: true,
            message: '供应商编码不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[^\u4e00-\u9fa5\s]+$/,
            message: '供应商编码不能包含中文和空格',
            trigger: ['blur', 'change']
          }
        ],
        areaId: [
          {
            required: true,
            message: '请选择客户区域',
            trigger: 'input'
          }
        ],
        groupId: [
          {
            required: true,
            message: '请选择客户组别',
            trigger: 'input'
          }
        ]
      },
      formLabelWidth: '100px'
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.loading = true
      // 重置数据
      vm.reset()
      //初始化数据
      vm.getTree() //渲染区域分组
      const res = await priceCaseListAPI({ pageNum: 1, pageSize: 100000 })
      vm.priceCaseList = res.rows
      listSettlementTerm({pageNum: 1, pageSize: 100}).then(res => {
        vm.saleEmployeeList = res.rows
      })
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.id) {
          //单据赋值
          await vm.getDetail() //渲染列表
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.supplierForm))
        }
      } else {
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.supplierForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.supplierForm))
      }
      vm.loading = false
    })
  },
  async beforeRouteLeave (to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.supplierForm.partnerId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.supplierForm))
    }
    next()
  },
  methods: {
    limitInputlength,
    //表格行hover时,显示操作加减号
    cellMouseEnter (row) {
      let eleIndex = this.supplierForm.linkmanList.indexOf(row)
      for (
        let index = 0;
        index < this.supplierForm.linkmanList.length;
        index++
      ) {
        const element = this.supplierForm.linkmanList[index]
        if (eleIndex == index) {
          element['hoverRow'] = true
        } else {
          element['hoverRow'] = false
        }
      }
      this.tableCellLabel = '操作'
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave () {
      // 移除hover的事件
      for (
        let index = 0;
        index < this.supplierForm.linkmanList.length;
        index++
      ) {
        const element = this.supplierForm.linkmanList[index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    },
    //退出
    getQuit () {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$router.push('/goods/partner/supplier')
    },
    // 区域下拉框校验方法
    inputSelectArea () {
      this.$refs.supplierForm.validateField('areaId')
    },
    // 组别下拉框校验方法
    inputSelectGroup () {
      this.$refs.supplierForm.validateField('groupId')
    },
    //表格增加/减少一行方法
    row (name, index) {
      if (name === 'push') {
        this.supplierForm.linkmanList.splice(index + 1, 0, {})
      } else {
        if (this.supplierForm.linkmanList.length === 1) {
          return
        }
        this.supplierForm.linkmanList.splice(index, 1)
      }
    },
    //下拉树结构数据转换
    normalizer (node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },
    // 表单重置
    reset () {
      this.supplierForm = {
        linkmanList: [{ linkMan: '', linkManTel: '', linkAddress: '' }],
        //整体表单
        status: 0, //状态
        partnerType: 2, //客户类型
        payType: 0 //结算方式
      }
      this.resetForm('supplierForm')
    },
    //获取区域和分组tree
    async getTree () {
      this.loading = true
      const res1 = await getAreaTree()
      this.treeArea = res1.data

      const res2 = await listTree()
      this.treeGroup = res2.data
      //获取全部行政地区事件
      getAllRegion().then(response => {
        this.provinceList = response.data
        this.loading = false
      })
    },
    //重置按钮
    cancel () {
      this.reset()
      this.$modal.msgSuccess('重置成功')
    },
    //获取供商资料详情
    async getDetail () {
      console.log(this.$route.query.id)
      getSupplierDetail(this.$route.query.id).then(response => {
        this.supplierForm = response.data
        console.log(response.data)
        if (this.supplierForm.linkmanList.length === 0) {
          this.supplierForm.linkmanList = [
            { linkMan: '', linkManTel: '', linkAddress: '' }
          ]
        }
      })
    },
    /** 提交按钮 */

    submitForm () {
      if (this.supplierForm.regionId) {
        this.supplierForm.regionId =
          this.supplierForm.regionId[this.supplierForm.regionId.length - 1]
      }

      this.$refs['supplierForm'].validate(async valid => {
        if (valid) {
          if (this.supplierForm.partnerId != undefined) {
            await updateSupplier(this.supplierForm)
            this.$modal.msgSuccess('修改供应商成功')
          } else {
            await addSupplier(this.supplierForm)
            this.$modal.msgSuccess('新增供应商成功')
          }
          this.$store.state.tagsView.visitedViews.splice(
            this.$store.state.tagsView.visitedViews.findIndex(
              item => item.path === this.$route.path
            ),
            1
          )
          this.$router.push(
            this.$store.state.tagsView.visitedViews[
              this.$store.state.tagsView.visitedViews.length - 1
            ].path
          )
          this.$destroy()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//全部
.warp {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  //备注
  .remark {
    width: 30%;
  }
  //详细地址
  .address {
    width: 40%;
  }
  .supplierInput {
    width: 95%;
  }
  //联系人联系电话联系地址
  .contactName,
  .contactNo,
  .contactAddress {
    width: 100%;
    align-items: center;
  }

  //三个标题属性
  .navTitle {
    font-weight: 700;
    line-height: 50px;
    height: 3%;
    border-bottom: 1px solid #e8eaf1;
    padding-left: 2%;
    margin-bottom: 2%;
  }

  //输入框属性
  //下拉框属性
  //地址属性
  .el-input,
  .el-cascader,
  .el-select {
    width: 250px;
  }
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #303133 !important;
}
::v-deep .vue-treeselect__control {
  height: 28px;
  line-height: 28px;
  width: 250px;
}
::v-deep .vue-treeselect__menu-container {
  height: 28px;
  line-height: 28px;
  width: 250px;
}
::v-deep .vue-treeselect__placeholder,
.vue-treeselect__single-value {
  line-height: 28px;
}
::v-deep .vue-treeselect__single-value {
  line-height: 28px;
}
</style>
