<template>
  <div class="warp padT15">
    <div class="typeList">
      <div class="title">选择模版</div>
      <div class="x-w">
        <div class="y-c item cursorP" v-for="(item, index) in typeList" :key="index">
          <div class="iconBox x-c" :class="[data.style.type == item.type ? 'iconBox2' : '']"
            @click="clickType(item.type)">
            <img :src="item.name" style="width:20px;height:20px" />
          </div>
          <span class="marT10"
            :style="{ color: data.style.type == item.type ? '#1458cc' : '#333333' }">{{ item.title }}</span>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div style="margin-bottom:10px">
      <span>魔方布局：</span>
      <span class="annotateGrey">{{rubikCubeNotes}}</span>
    </div>
    <CubeMatrix2 :layout='data.style.type' v-model="data.style.selectedList" v-if="data.style.type != 8"
      @activeChange='activeChange' />
    <CubeMatrix v-else v-model="data.style.selectedList" @templateMsg='templateMsg' @onChangeDensity='onChangeDensity'
      :densityCom="data.style.density" @activeChange='activeChange' />
    <div class='moreSettings x-x' v-if="active >= 0 && data.style.selectedList.length > 0">
      <div class="choosedImageItem x-fc" @click="getOpenImg()">
        <i v-if="!data.style.selectedList[active].imageUrl" class="el-icon-plus" style="font-size: 32px"></i>
        <img v-else class="thumbImage" :src="data.style.selectedList[active].imageUrl" alt="广告图" />
        <span class="modifyImage"
          v-if="!disabled">{{`${data.style.selectedList[active].imageUrl ? '更换' : '上传'}图片`}}</span>
        <i v-if="data.style.selectedList[active].imageUrl" class="el-icon-circle-close" @click.stop="delImg()"></i>
      </div>
      <div style="margin-right:20px">
        <el-button type="text"
          @click="openLink = true">{{`${data.style.selectedList[active].linkPath ? '修改' : '选择'}链接`}}</el-button>
        <div class="x-f">
          <span style="margin-right:10px">{{`链接：${data.style.selectedList[active].linkPathName || ''}`}}</span>
          <span v-if="data.style.selectedList[active].linkPath" class="el-icon-close operateDel"
            @click="delLink()"></span>
        </div>
      </div>
    </div>
    <el-form  label-width="60px" style="margin-top:20px">
    <el-form-item label="上边距">
      <div class="x-f">
        <el-slider style="width: 300px" :max="200" v-model="data.style.marginTop"
          @change="templateMsg" show-input></el-slider>
      </div>
    </el-form-item>
    <el-form-item label="下边距">
      <div class="x-f">
        <el-slider style="width: 300px" :max="200" v-model="data.style.marginBottom"
          @change="templateMsg" show-input></el-slider>
      </div>
    </el-form-item>
    <!-- <el-form-item label="左边距">
      <div class="x-f">
        <el-slider style="width: 300px" :max="30" v-model="data.style.marginLeft"
          @change="templateMsg" show-input></el-slider>
      </div>
    </el-form-item>
    <el-form-item label="右边距">
      <div class="x-f">
        <el-slider style="width: 300px" :max="30" v-model="data.style.marginRight"
          @change="templateMsg" show-input></el-slider>
      </div>
    </el-form-item> -->
    </el-form>
    <el-dialog v-dialogDrag title="素材库" :visible.sync="showDialog" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
    <SelectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
  </div>
</template>

<script>
import CubeMatrix2 from "@/views/O2OMall/config/template/decoratePage/components/rightTemplate/components/seniorCubeCom.vue";
import CubeMatrix from "@/views/O2OMall/config/template/decoratePage/components/rightTemplate/components/cubeMatrix.vue";
import SelectLink from '@/views/components/selectLink/index.vue' //链接库
import Material from '@/views/components/material/index.vue' //素材库
export default {
  name: 'seniorCube',
  components: {
    Material,
    SelectLink,
    CubeMatrix,
    CubeMatrix2
  },
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      openLink: false,
      accessUrlList: [],
      showDialog: false,
      active: -1,
      typeList: [
        {
          name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-15/4881d719d8a5462d9818df4ced592c7d.png', title: '一行两个', type: 1
        },
        {
          name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-15/f6af8c2eb3e64548894dbff6eba3df88.png', title: '一行三个', type: 2
        },
        { name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-15/e01e478d0aec401ba4637c24d868c8d1.png', title: '一行四个', type: 3 },
        { name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-15/b69cf7a45c864dcca48c69ad94c96690.png', title: '两左两右', type: 4 },
        { name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-15/a7fd5c5dd1fe4603bd1baab16b98db22.png', title: '一左两右', type: 5 },
        { name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-15/0425a07537454e8885b805acde99a926.png', title: '一上二下', type: 6 },
        { name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-15/8dab03335c344c598f38d5b188faa501.png', title: '一左三右', type: 7 },
        { name: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2025-01-15/71a2a9b5e4e64ab6a35a8a5f578546f9.png', title: '自定义', type: 8 },
      ]
    }
  },
  computed: {
    rubikCubeNotes() {
      let tempEnd = ''
      switch (this.data.style.type) {
        case 1:
        case 2:
        case 3:
          tempEnd = '选定布局区域，在下方添加图片，建议添加比例一致的图片'
          break;
        case 4:
        case 5:
        case 6:
        case 7:
          tempEnd = '选定布局区域，在下方添加图片'
          break;
        case 8:
          tempEnd = '移动鼠标选定布局区域大小'
          break;
        default:
          break;
      }
      return tempEnd
    }
  },
  methods: {
    activeChange(val) {
      this.active = val
    },
    delLink() {
      this.$set(this.data.style.selectedList[this.active], 'linkPath', '')
      this.$set(this.data.style.selectedList[this.active], 'linkPathName', '')
      this.$forceUpdate()
    },
    delImg() {
      this.$set(this.data.style.selectedList[this.active], 'imageUrl', '')
      this.$forceUpdate()
    },
    getOpenImg() {
      this.showDialog = true
    },
    // 选择图片素材
    getAccessUrlList(value, row) {
      this.accessUrlList = row
    },
    // 选择图片弹框确定
    imgUpdateSubmit() {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      this.$set(this.data.style.selectedList[this.active], 'imageUrl', this.accessUrlList[0]?.accessUrl)
      this.accessUrlList = []
      this.showDialog = false
      this.$forceUpdate()
      this.$emit('templateMsg')
    },
    //上传图片弹窗取消
    imgUpdateCancel() {
      this.accessUrlList = []
      this.showDialog = false
    },
    // 选择链接
    selectLinkObj(value) {
      this.data.style.selectedList[this.active].linkPath = value.url
      this.data.style.selectedList[this.active].linkPathName = value.name
    },
    onChangeDensity(val) {
      this.data.style.density = val
    },
    templateMsg() {
      this.$emit('setTemp', this.data)
    },
    clickType(type) {
      this.data.style.type = type
      switch (type) {
        case 1:
          this.data.style.selectedList = [
            {
              label: '宽度375像素',
            },
            {
              label: '宽度375像素',
            }
          ];
          break;
        case 2:
          this.data.style.selectedList = [
            {
              label: '宽度250像素',
            },
            {
              label: '宽度250像素',
            },
            {
              label: '宽度250像素',
            }
          ];
          break;
        case 3:
          this.data.style.selectedList = [
            {
              label: '宽度188像素',
            },
            {
              label: '宽度188像素',
            },
            {
              label: '宽度188像素',
            },
            {
              label: '宽度188像素'
            }
          ];
          break;
        case 4:
          this.data.style.selectedList = [
            {
              label: '375x375像素',
            },
            {
              label: '375x375像素',
            },
            {
              label: '375x375像素',
            },
            {
              label: '375x375像素',
            }
          ];
          break;
        case 5:
          this.data.style.selectedList = [
            {
              label: '375x750像素',
            },
            {
              label: '375x375像素',
            },
            {
              label: '375x375像素',
            }
          ];
          break;
        case 6:
          this.data.style.selectedList = [
            {
              label: '750x375像素',
            },
            {
              label: '375x375像素',
            },
            {
              label: '375x375像素',
            }
          ];
          break
        case 7:
          this.data.style.selectedList = [
            {
              label: '375x750像素',
            },
            {
              label: '375x375像素',
            },
            {
              label: '188x375像素',
            },
            {
              label: '188x375像素',
            }
          ];
          break;
        case 8:
          this.data.style.selectedList = [];
          break;
        default:
          return [];
      }
      this.templateMsg()
    },
  }
}
</script>

<style lang="scss" scoped>
.warp {
  .typeList {
    width: 100%;
    padding: 0 30px;
    color: #969799;
    .title {
      margin-bottom: 20px;
      font-size: 14px;
    }
    .item {
      color: #989898;
      margin-right: 20px;
      margin-bottom: 10px;
      .iconBox {
        width: 60px;
        height: 60px;
        border: 1px solid #e5e5e5;
        padding: 20px;
        background: #f5f5f5;
      }

      .iconBox2 {
        border: 1px solid #1458cc;
        background: #d5e2f3;
      }

      span {
        width: 50px;
      }
    }
  }
  .moreSettings {
    padding: 10px 0 10px 15px;
    margin-top: 20px;
    background-color: #f7f8fa;
    .choosedImageItem {
      position: relative;
      width: 80px;
      height: 80px;
      border: 1px solid #e5e5e5;
      text-align: center;
      margin-right: 10px;
      .thumbImage {
        min-height: 80px;
        box-sizing: border-box;
        vertical-align: bottom;
        max-height: 100%;
        max-width: 100%;
        height: auto;
        width: auto;
      }
      .modifyImage {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }
      .el-icon-circle-close {
        position: absolute;
        top: 0;
        right: 0;
        color: rgba(0, 0, 0, 0.8);
        cursor: pointer;
        font-size: 22px;
      }
    }
  }
}
</style>
