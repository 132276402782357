var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "im-box",
        style: {
          marginBottom: "-" + _vm.data.coverage + "px",
          padding: _vm.data.type > 1 ? "40px 10px 0px 10px" : "",
          background:
            _vm.data.type === 2
              ? "linear-gradient(to bottom, " +
                _vm.data.contentList[_vm.active].title +
                " 0%, #f2f2f2 40%)"
              : "",
        },
      },
      [
        _vm.data.type === 3
          ? _c("div", {
              style: {
                backgroundColor: _vm.data.contentList[_vm.active].title,
                borderBottomLeftRadius: _vm.data.type === 3 ? "15px" : "",
                borderBottomRightRadius: _vm.data.type === 3 ? "15px" : "",
                position: "absolute",
                top: 0,
                left: 0,
                height: "200px",
                width: "100%",
              },
            })
          : _vm._e(),
        _c(
          "div",
          {
            style: {
              margin:
                _vm.data.marT +
                "px " +
                _vm.data.marR +
                "px " +
                _vm.data.marB +
                "px " +
                _vm.data.marL +
                "px",
              overflow: "hidden",
              borderRadius: _vm.data.borRadius + "px",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "warp x-fc",
                style: {
                  borderRadius: _vm.data.borRadius + "px",
                  // backgroundColor: data.style.bgColor
                },
              },
              [
                _c("div", { staticClass: "carousel-container" }, [
                  _c("div", { staticClass: "carousel-inner" }, [
                    _vm.data.carouselType == 2
                      ? _c(
                          "div",
                          { staticStyle: { position: "relative" } },
                          [
                            _c(
                              "div",
                              { staticClass: "qm-banner-bgImage uperUderImg" },
                              _vm._l(_vm.underImgList, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "aaaa",
                                    style: { opacity: item.opacity },
                                  },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100%",
                                        display: "block",
                                      },
                                      style: { height: _vm.carouselHeight },
                                      attrs: {
                                        mode: "widthFix",
                                        "lazy-load": "",
                                        src: item.icon,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _c(
                              "el-carousel",
                              {
                                staticClass: "v-qm-banner",
                                attrs: {
                                  height: _vm.carouselHeight,
                                  interval: _vm.data.style.interval,
                                },
                                on: { change: _vm.ontransition },
                              },
                              _vm._l(
                                _vm.data.contentList,
                                function (item, index) {
                                  return _c(
                                    "el-carousel-item",
                                    { key: index, staticClass: "swiper-item" },
                                    [
                                      _c("el-image", {
                                        staticStyle: { display: "block" },
                                        style: {
                                          height: _vm.carouselHeight,
                                          width: "100%",
                                        },
                                        attrs: { src: item.bgIcon },
                                        on: {
                                          load: function (event) {
                                            return _vm.updateCarouselHeight(
                                              event,
                                              index
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            this.data.isIcon
                              ? _c(
                                  "el-carousel",
                                  {
                                    attrs: { height: _vm.carouselHeight },
                                    on: { change: _vm.changeImg },
                                  },
                                  _vm._l(
                                    _vm.data.contentList,
                                    function (item, index) {
                                      return _c(
                                        "el-carousel-item",
                                        { key: index },
                                        [
                                          _c("el-image", {
                                            style: {
                                              borderRadius:
                                                _vm.data.borRadius + "px",
                                            },
                                            attrs: {
                                              src: item.icon,
                                              fit: "cover",
                                            },
                                            on: {
                                              load: function (event) {
                                                return _vm.updateCarouselHeight(
                                                  event,
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            !this.data.isIcon
                              ? _c(
                                  "el-carousel",
                                  {
                                    attrs: { height: _vm.carouselHeight },
                                    on: { change: _vm.changeImg },
                                  },
                                  _vm._l(
                                    _vm.data.contentList,
                                    function (item, index) {
                                      return _c(
                                        "el-carousel-item",
                                        {
                                          key: index,
                                          attrs: { label: index + 1 },
                                        },
                                        [
                                          _c("el-image", {
                                            style: {
                                              borderRadius:
                                                _vm.data.borRadius + "px",
                                            },
                                            attrs: {
                                              src: item.icon,
                                              fit: "cover",
                                            },
                                            on: {
                                              load: function (event) {
                                                return _vm.updateCarouselHeight(
                                                  event,
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }