<template>
  <div class="wrap" v-loading="loading" id="wrap">
    <div class="content">
      <el-tabs v-model="activeName" @tab-click="handleEvent('tabClick')" id="tabs">
        <el-tab-pane label="微信会员设置" name="set">
          <TableButtons
            :buttons="options.tabsColumns[0].buttons"
            :tableConfig="options"
            @handleEvent="handleEvent"
          />
          <el-form ref="form" :model="wxVipcardForm" label-width="180px" :rules="rules">
            <div class="x-x">
              <TableTree
                ref="tableTree"
                slot="cardContent"
                v-model="options.tableTree.body"
                :option.sync="options.tableTree"
                class="page-tree-view"
                style="height: 100%; width: 300px"
                @change="(e) => handleEvent('search', e)"
              />
              <div class="tableContent x-x">
                <div class="physicalCard x-center">
                  <div
                    class="vipCard y-b"
                    :style="{
                      backgroundColor:
                        wxVipcardForm.backgroundColorType == 1
                          ? cardColorName
                          : wxVipcardForm.backgroundPicUrl,
                      backgroundImage:
                        wxVipcardForm.backgroundColorType == 2
                          ? 'url(' + wxVipcardForm.backgroundPicUrl + ')'
                          : 'none',
                    }"
                  >
                    <div class="x-f">
                      <el-image
                        style="width: 42px; height: 42px; border-radius: 21px"
                        :src="
                          wxVipcardForm.cardLogoUrl
                            ? wxVipcardForm.cardLogoUrl
                            : 'https://cdn.jdy.com/v7file/images/pc/emotion/emotional_personal_center_100_100.png?v=0.5'
                        "
                      />
                      <div class="y-start marL15">
                        <span class="fontSW16">{{
                          wxVipcardForm.brandName ? wxVipcardForm.brandName : "商家名称"
                        }}</span>
                        <span class="fontSW14">{{
                          wxVipcardForm.cardName ? wxVipcardForm.cardName : "会员卡名称"
                        }}</span>
                      </div>
                    </div>
                    <div>
                      <span class="fontSW16 marR15">2098</span>
                      <span class="fontSW16 marR15">8900</span>
                      <span class="fontSW16">2739</span>
                    </div>
                  </div>
                </div>
                <div>
                  <el-form-item label="会员卡创建方式" class="creationMode">
                    <el-radio-group
                      v-model="wxVipcardForm.cardCreateMode"
                      :disabled="wxVipcardForm.wxCardId != undefined"
                    >
                      <el-radio :label="1">创建新卡</el-radio>
                      <el-radio :label="2">绑定旧卡</el-radio>
                    </el-radio-group>
                    <el-form-item
                      label-width="10px"
                      :prop="wxVipcardForm.cardCreateMode == 2 ? 'wxCardId' : ''"
                    >
                      <el-input
                        v-show="wxVipcardForm.cardCreateMode == 2"
                        class="inputWidth marR10"
                        v-model="wxVipcardForm.wxCardId"
                        placeholder="请输入微信会员卡ID"
                      ></el-input>
                    </el-form-item>
                    <el-button
                      v-show="wxVipcardForm.cardCreateMode == 2"
                      type="primary"
                      @click="wxCardInspect"
                      :loading="verifyBtnloading"
                      size="mini"
                    >
                      <span v-if="!verifyBtnloading">校验</span>
                      <span v-else>校 验 中</span>
                    </el-button>
                  </el-form-item>
                  <el-form-item label="商家名称" prop="brandName">
                    <el-input
                      :disabled="!multiple"
                      class="inputWidth marR10"
                      v-model="wxVipcardForm.brandName"
                      placeholder="商家信息"
                    ></el-input>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="该内容保存后不可修改，谨慎填写"
                      placement="top"
                    >
                      <i class="iconfont icon-wenhao annotateGrey"></i>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item label="会员卡名称" prop="cardName">
                    <el-input
                      class="inputWidth marR10"
                      v-model="wxVipcardForm.cardName"
                      placeholder="会员卡名称"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="* 商家头像">
                    <div>
                      <span class="fontS14">(请参考 </span>
                      <el-button type="text" @click="getImageNorm">图片规范 </el-button>
                      <span class="fontS14"> 上传)</span>
                    </div>
                    <accessory-upload
                      class="upload"
                      listType="picture-card"
                      :limit="1"
                      title=""
                      :fileList="fileListLogo"
                      @getFileItems="getFileItemsDataLogo"
                      @delFileItems="delFileItemsLogo"
                      :fileSize="1"
                    />
                  </el-form-item>
                  <el-form-item label="会员卡底色">
                    <el-radio-group
                      v-model="wxVipcardForm.backgroundColorType"
                      class="marR50"
                    >
                      <el-radio :label="1">颜色</el-radio>
                      <el-radio :label="2">图片</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="* 默认会员级别">
                    <SelectRemote
                      style="width: 250px"
                      v-model="wxVipcardForm.vipLevelId"
                      :option="
                        $select({
                          key: 'viplevel',
                          option: {
                            option: {
                              remoteBody: {
                                vipBrandId: vipBrandId,
                                isCanNotBind: false,
                                pageNum: 1,
                                pageSize: 9999,
                              },
                            },
                          },
                        }).option
                      "
                    />
                  </el-form-item>
                  <el-form-item label="* 选择底色">
                    <WxBackground
                      v-show="wxVipcardForm.backgroundColorType == 1"
                      v-model="wxVipcardForm.cardColor"
                      @wxBGColorObj="wxBGColorObj"
                    />
                    <accessory-upload
                      v-show="wxVipcardForm.backgroundColorType == 2"
                      class="marL10"
                      listType="picture-card"
                      :limit="1"
                      title=""
                      :fileList="fileList"
                      @getFileItems="getFileItemsData"
                      @delFileItems="delFileItems"
                      :fileSize="1"
                    />
                  </el-form-item>
                  <el-form-item label="显示微信支付">
                    <el-checkbox v-model="wxVipcardForm.isDisplayWxPay" />
                  </el-form-item>
                  <el-form-item label="会员码方式">
                    <el-radio-group v-model="wxVipcardForm.codeType">
                      <el-radio label="CODE_TYPE_QRCODE">二维码</el-radio>
                      <el-radio label="CODE_TYPE_BARCODE">条形码</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="是否显示会员卡动态码">
                    <el-switch v-model="wxVipcardForm.isDisplayDynamicCode"> </el-switch>
                  </el-form-item>
                  <el-form-item label="是否显示会员卡中部会员码">
                    <el-switch v-model="wxVipcardForm.isDisplayMiddleQrcode"> </el-switch>
                  </el-form-item>
                  <el-form-item label="首页入口设置">
                    <el-checkbox
                      v-model="wxVipcardForm.isDisplayVipBalanceDetail"
                      :disabled="
                        !isCheckCardSetting && !wxVipcardForm.isDisplayVipBalanceDetail
                      "
                      >余额明细</el-checkbox
                    >
                    <el-checkbox
                      v-model="wxVipcardForm.isDisplayVipCenter"
                      :disabled="!isCheckCardSetting && !wxVipcardForm.isDisplayVipCenter"
                      >会员中心</el-checkbox
                    >
                    <el-checkbox
                      v-model="wxVipcardForm.isDisplayDrawCoupon"
                      :disabled="
                        !isCheckCardSetting && !wxVipcardForm.isDisplayDrawCoupon
                      "
                      >领券中心</el-checkbox
                    >
                    <el-checkbox v-model="wxVipcardForm.isDisplayVipPay"
                      >会员付款码</el-checkbox
                    >
                    <el-checkbox
                      v-model="wxVipcardForm.isDisplayVipFill"
                      :disabled="!isCheckCardSetting && !wxVipcardForm.isDisplayVipFill"
                      >在线充值</el-checkbox
                    >
                    <el-checkbox
                      v-model="wxVipcardForm.isDisplayScoreCoupon"
                      :disabled="
                        !isCheckCardSetting && !wxVipcardForm.isDisplayScoreCoupon
                      "
                      >积分兑券</el-checkbox
                    >
                    <el-checkbox
                      v-model="wxVipcardForm.isDisplayO2omall"
                      :disabled="!isCheckCardSetting && !wxVipcardForm.isDisplayO2omall"
                      >商城小程序</el-checkbox
                    >
                  </el-form-item>
                  <el-form-item label="卡包链接">
                    <span>{{ wxVipcardForm.receiveWxCardLink }}</span>
                    <el-button class="marL20" type="text" @click="copyUrl(1)"
                      >复制</el-button
                    >
                  </el-form-item>
                  <el-form-item label="系统预设特权说明" prop="cardPresetPrerogative">
                    <el-input
                      class="textareaWidth"
                      type="textarea"
                      placeholder="请输入内容"
                      v-model="wxVipcardForm.cardPresetPrerogative"
                      :autosize="{ minRows: 3, maxRows: 4 }"
                      maxlength="255"
                      show-word-limit
                    />
                  </el-form-item>
                  <el-form-item label="商家特权说明" prop="cardPrerogative">
                    <el-input
                      class="textareaWidth"
                      type="textarea"
                      placeholder="请输入内容"
                      v-model="wxVipcardForm.cardPrerogative"
                      :autosize="{ minRows: 3, maxRows: 4 }"
                      maxlength="255"
                      show-word-limit
                    />
                  </el-form-item>
                  <el-form-item label="使用须知">
                    <el-input
                      class="textareaWidth"
                      type="textarea"
                      placeholder="请输入内容"
                      v-model="wxVipcardForm.cardUseRules"
                      :autosize="{ minRows: 3, maxRows: 4 }"
                      maxlength="16"
                      show-word-limit
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="微信会员投放设置" name="putInSet">
          <div class="putInSet" id="putInSet" v-if="activeName == 'putInSet'">
            <TablePage
              ref="tablePage"
              v-model="options.tabsColumns[1]"
              @handleEvent="handleEvent"
            >
            </TablePage>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 弹窗 -->
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import AccessoryUpload from "@/views/components/accessoryUpload";
import WxBackground from "@/views/components/wxBackground"; //微信背景组件
import {
  wxVipcardAddAPI,
  wxVipcardDetailAPI,
  wxVipcardRemoveAPI,
  wxVipcardInspectAPI,
  vipCardwxAddCreateQrcode,
  vipCardWxAddCreateLink,
  vipBrandList,
  vipBrandSave,
  vipBrandUpdate,
  vipBrandDetail,
  vipBrandRemove,
  restoreVipCardBrand,
  getCardBrandDetail,
  cardCaseSave,
  cardCaseUpdate,
} from "@/api/vip/wxService/wxVipcard"; //微信会员包
import { listlevel } from "@/api/vip/base/level.js";
import { listShopInfo } from "@/api/shop/base/shopInfo";
import { getTenantWxApp } from "@/api/vip/wxService/authorize"; //微信公众号授权
import TableButtons from "@/components/tablePage/tableButtons";
import TablePage from "@/components/tablePage"; //表格页面组件
export default {
  name: "wxVipcard",
  components: {
    cardTitleCom,
    AccessoryUpload,
    WxBackground,
    TableButtons,
    TablePage,
    Dialog,
    TableTree: () => import("@/components/tablePage/table-tree"),
    SelectRemote: () => import("@/components/tablePage/select/select-remote/index.vue"),
  },
  data() {
    return {
      vipBrandId: null,
      activeName: "set",
      options: {
        tableTree: {
          body: {
            vipBrandId: "",
          },
          defaultExpandAll: true,
          title: "会员品牌",
          value: "vipBrandId",
          label: "vipBrandName",
          setData: (e) => ({ vipBrandId: e.vipBrandId || "" }), // 后端要求全部分类为空,
          getListApi: vipBrandList,
          defaultBody: {
            isWxVipCard: true,
          },
        },
        tabsColumns: [
          {
            mutiSelect: true,
            listNo: true, // 序号
            check: [], // 选中数据
            getListApi: vipBrandList,
            rowKey: "vipBrandId",
            id: "vipBrandId",
            getDataKey: { list: "data" },
            pagination: {
              onlySelectTotal: true,
            },
            buttons: [
              {
                click: "add",
                label: "保存",
                type: "primary",
              },
              // {
              //   click: 'handleDelete',
              //   label: '删除',
              //   type: 'danger',
              //   icon: 'el-icon-delete',
              //   // alertText: '确认要删除选中的品牌吗？',
              //   btnType: 'dropdown',
              //   trigger: 'click', // 要求
              //   noRule: true,
              //   other: [
              //     {
              //       label: '回收站',
              //       collectionDialog: {
              //         restoreListApi: restoreVipCardBrand,
              //         dcollectionBody: {
              //           delFlag: '1'
              //         }
              //       }
              //     }
              //   ]
              // }
            ],
            columns: [
              {
                prop: "vipBrandNo",
                label: "会员品牌编号",
                minWidth: 100,
              },
              {
                prop: "vipBrandName",
                label: "会员品牌名称",
                minWidth: 100,
              },
            ],
            search: [
              {
                type: "filters",
                isRestore: true,
                model: "",
                filters: [
                  { filter: "query", label: "全部" },
                  { filter: "vipBrandNos", label: "会员品牌编号" },
                  { filter: "vipBrandNames", label: "会员品牌名称" },
                ],
              },
              {
                type: "button",
                tip: "查询",
                btnType: "primary",
                click: "search",
                isRestore: true,
              },
            ],
          },
          {
            radioSelect: true,
            rowClick: true,
            loading: false,
            pagination: {
              total: 0,
              page: 1,
              size: 15,
            },
            // height: "500",
            getListApi: listShopInfo,
            listNo: true, // 序号
            list: [],
            check: [], // 选中数据
            rowKey: "shopId",
            tableTree: {
              body: {
                vipBrandId: "",
              },
              defaultExpandAll: true,
              title: "会员品牌",
              value: "vipBrandId",
              label: "vipBrandName",
              setData: (e) => ({ vipBrandId: e.vipBrandId || "" }), // 后端要求全部分类为空,
              getListApi: vipBrandList,
              defaultBody: {
                isWxVipCard: true,
              },
            },
            buttons: [
              {
                click: "KBQRcode",
                label: "生成投放二维码",
                type: "primary",
                noRule: true,
              },
              {
                click: "KBQLink",
                label: "生成链接",
                type: "primary",
                noRule: true,
              },
              {
                click: "KBQCUrl",
                label: "",
                type: "text",
                noRule: true,
              },
              {
                click: "refresh",
                right: true,
                label: "刷新",
                icon: "el-icon-refresh",
                type: "",
              },
            ],
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 180,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 180,
              },
              {
                prop: "shopTypeName",
                label: "门店类型",
                minWidth: 100,
              },
              {
                prop: "useArea",
                label: "门店面积",
                minWidth: 100,
              },
              {
                prop: "linkEmployeeName",
                label: "联系人",
                minWidth: 120,
              },
              {
                prop: "linkEmployeeTel",
                label: "联系电话",
                minWidth: 120,
              },
              {
                prop: "totalAddress",
                label: "门店地址",
                minWidth: 120,
              },
              {
                prop: "zjm",
                label: "助记码",
                minWidth: 120,
              },
              {
                prop: "sortNo",
                label: "排序",
                minWidth: 120,
              },
              {
                prop: "remark",
                label: "备注",
                minWidth: 120,
              },
            ],
          },
        ],
      },
      //生成链接文案
      textLink: "",
      //弹窗配置
      dialogOptions: {
        title: "投放二维码",
        width: 500,
        show: false,
        type: "WXVipCardQRCode",
      },
      //旧卡是否校验成功
      isCheckSuccess: false,
      //校验按钮loading
      verifyBtnloading: false,
      //禁用删除按钮
      multiple: true,
      //商家头像回显
      fileListLogo: [],
      //背景头像回显
      fileList: [],
      // 遮罩层
      loading: false,
      //会员卡表单
      wxVipcardForm: {
        cardCreateMode: 1, //创建方式
        backgroundColorType: 1, //底色型
        isDisplayWxPay: true, //是否显示微信支付
        isDisplayDynamicCode: false, // 是否显示会员卡动态码
        isDisplayCoupon: false, //优惠券
        isDisplayVipBalanceDetail: false, //余额明细
        isDisplayVipCenter: false, // 会员中心
        isDisplayDrawCoupon: false, //领券中心
        isDisplayVipPay: false, //会员付款码
        isDisplayVipFill: false, // 在线充值
        // isDisplayVipPayDetail: false, //消费记录
        isDisplayScoreCoupon: false, //积分兑券
        codeType: "CODE_TYPE_QRCODE", //卡类型
        cardPresetPrerogative: "不同会员级别享受不同优惠政策", //系统预设特权说明
        cardPrerogative: "其他说明", //商家特权说明
        cardUseRules: "该会员卡解释权归商家所有", //使用须知
        receiveWxCardLink: "", // 卡包链接
        cardColor: "Color010", //卡颜色
      },
      cardColorName: "#63b359", //卡颜色名称
      //表单校验
      rules: {
        cardName: [{ required: true, message: "请输入会员卡名称", trigger: "blur" }],
        brandName: [{ required: true, message: "请输入商家名称", trigger: "blur" }],
        cardPresetPrerogative: [
          { required: true, message: "请输入系统预设特权说明", trigger: "blur" },
        ],
        cardPrerogative: [
          { required: true, message: "请输入商家特权说明", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    isCheckCardSetting() {
      const {
        isDisplayVipCenter,
        isDisplayDrawCoupon,
        isDisplayVipFill,
        isDisplayScoreCoupon,
        isDisplayO2omall,
        isDisplayVipBalanceDetail,
      } = this.wxVipcardForm;
      const isCheck =
        [
          isDisplayVipCenter,
          isDisplayDrawCoupon,
          isDisplayVipBalanceDetail,
          isDisplayVipFill,
          isDisplayScoreCoupon,
          isDisplayO2omall,
        ].filter((x) => Boolean(x)).length < 4;
      return isCheck;
    },
  },
  async created() {
    await this.handleResize();
  },
  async mounted() {
    const awaitTime = (t = 100) => new Promise((resolve) => setTimeout(resolve, t));
    await awaitTime(500);
    await this.$nextTick();
    this.$refs?.tableTree?.handleEvent("init", true);
  },
  methods: {
    async getCardBrandDetail() {
      if (!this.vipBrandId) return;
      this.loading = true;
      let res = await getCardBrandDetail(this.vipBrandId);
      if (res.data?.wxCardId) {
        if (!res.data.cardColor) {
          res.data.cardColor = "Color010";
        }
        this.wxVipcardForm = res.data;
        this.fileListLogo = this.wxVipcardForm.cardLogoUrl
          ? [{ name: "卡片Logo", url: this.wxVipcardForm.cardLogoUrl }]
          : [];
        this.fileList = this.wxVipcardForm.backgroundPicUrl
          ? [{ name: "背景图片", url: this.wxVipcardForm.backgroundPicUrl }]
          : [];
        //删除
        this.multiple = false;
        this.wxVipcardForm.vipBrandId = this.vipBrandId;
      } else {
        this.$message.warning("找不到对应的卡包信息，请尽快设置！");
        //删除
        this.multiple = true;
        this.wxVipcardForm = {
          cardCreateMode: 1, //创建方式
          backgroundColorType: 1, //底色型
          isDisplayWxPay: true, //是否显示微信支付
          isDisplayDynamicCode: false, // 是否显示会员卡动态码
          isDisplayCoupon: false, //优惠券
          isDisplayVipBalanceDetail: false, //余额明细
          isDisplayVipCenter: false, // 会员中心
          isDisplayDrawCoupon: false, //领券中心
          isDisplayVipPay: false, //会员付款码
          isDisplayVipFill: false, // 在线充值
          // isDisplayVipPayDetail: false, //消费记录
          isDisplayScoreCoupon: false, //积分兑券
          codeType: "CODE_TYPE_QRCODE", //卡类型
          cardPresetPrerogative: "不同会员级别享受不同优惠政策", //系统预设特权说明
          cardPrerogative: "其他说明", //商家特权说明
          cardUseRules: "该会员卡解释权归商家所有", //使用须知
          receiveWxCardLink: "", // 卡包链接
          vipBrandId: this.vipBrandId,
          cardColor: "Color010", //卡颜色
        };
        (this.cardColorName = "#63b359"), //卡颜色名称
          (this.fileListLogo = []);
        this.fileList = [];
      }
      this.loading = false;
    },
    //自适应获取表格高度
    async handleResize() {
      setTimeout(() => {
        let pageH = document.getElementById("putInSet").offsetHeight;
        this.options.tabsColumns[1].height = pageH - 70 - 40;
      }, 10);
    },
    async handleEvent(type, row) {
      console.log("type", type, row);
      switch (type) {
        case "handleDelete":
          if (!this.vipBrandId) return this.$message.error("请选择会员品牌!");
          this.$modal.confirm("是否确认删除此分类？").then(async () => {
            await vipBrandRemove([this.vipBrandId]);
            this.$refs.tableTree.handleEvent("init", true);
            this.$message.success("删除分类成功！");
          });
          break;
        case "getList":
          this.$refs.tableTree.handleEvent("init", true);
          break;
        case "dialogChange":
          if (this.dialogChange.type == "VipBand") {
            await this.$refs?.tableTree?.handleEvent("init", true);
          }
          break;
        case "add":
          this.submitForm();
          break;
        // case 'del':
        //   this.handDelete()
        //   break
        case "tabClick":
          if (this.activeName == "putInSet") this.handleResize();
          this.options.tabsColumns[1].buttons[2].label = "";
          this.textLink = "";
          break;
        case "KBQRcode": //投放二维码
          if (!this.options.tabsColumns[1].tableTree.body.vipBrandId)
            return this.$message.error("请选择会员品牌");
          if (!this.options.tabsColumns[1].radioObject)
            return this.$message.error("请选择门店");
          try {
            const { msg } = await vipCardwxAddCreateQrcode({
              shopId: this.options.tabsColumns[1].radioObject.shopId,
              vipBrandId: this.options.tabsColumns[1].tableTree.body.vipBrandId,
            });
            this.dialogOptions = {
              title: "投放二维码",
              width: 500,
              show: true,
              type: "WXVipCardQRCode",
              formData: {
                QRCodeImg: msg,
              },
            };
          } catch (err) {}
          break;
        case "KBQLink":
          if (!this.options.tabsColumns[1].check.length)
            return this.$message.warning("请选择要执行的数据");
          try {
            const { msg } = await vipCardWxAddCreateLink({
              shopId: this.options.tabsColumns[1].radioObject.shopId,
              vipBrandId: this.options.tabsColumns[1].tableTree.body.vipBrandId,
            });
            this.textLink = msg;
            this.options.tabsColumns[1].buttons[2].label = msg + "  " + "复制";
          } catch (error) {}
          break;
        case "KBQCUrl":
          this.copyUrl(0);
          break;
        case "search":
          console.log("search", row);
          this.vipBrandId = row.vipBrandId;
          await this.getCardBrandDetail();
          break;
        default:
          break;
      }
    },
    //校验微信会员卡是否正确
    async wxCardInspect() {
      this.verifyBtnloading = true;
      try {
        await wxVipcardInspectAPI(this.wxVipcardForm.wxCardId);
        this.isCheckSuccess = true;
      } catch (error) {}
      this.verifyBtnloading = false;
    },
    //删除方法
    async handDelete() {
      try {
        await wxVipcardRemoveAPI();
        //删除
        this.multiple = true;
        this.wxVipcardForm = {
          cardCreateMode: 1, //创建方式
          backgroundColorType: 1, //底色型
          isDisplayWxPay: true, //是否显示微信支付
          isDisplayCoupon: false, //优惠券
          isDisplayVipFillDetail: false, //充值记录
          // isDisplayVipPayDetail: false, //消费记录
          codeType: "CODE_TYPE_QRCODE", //卡类型
          cardPresetPrerogative: "不同会员级别享受不同优惠政策", //系统预设特权说明
          cardPrerogative: "其他说明", //商家特权说明
          cardUseRules: "该会员卡解释权归商家所有", //使用须知
          isDisplayDynamicCode: false, // 是否显示会员卡动态码
          isDisplayVipBalanceDetail: false, //余额明细
          isDisplayVipCenter: false, // 会员中心
          isDisplayDrawCoupon: false, //领券中心
          isDisplayVipPay: false, //会员付款码
          isDisplayVipFill: false, // 在线充值
          isDisplayScoreCoupon: false, //积分兑券
        };
        this.fileListLogo = [];
        this.fileList = [];
        this.$message({
          message: "删除成功",
          type: "success",
        });
      } catch (err) {}
    },
    //微信背景颜色
    wxBGColorObj(value) {
      this.cardColorName = value.color; //卡颜色名称
    },
    /** 提交按钮 */
    async submitForm() {
      if (!this.vipBrandId) return this.$message.error("请选择会员品牌！");
      if (!this.wxVipcardForm.vipLevelId)
        return this.$message.error("请选择默认会员级别！");
      if (
        this.wxVipcardForm.backgroundColorType == 2 &&
        !this.wxVipcardForm.backgroundPicUrl
      )
        return this.$message.error("请选择卡包底色！");
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          try {
            const { data } = await await getTenantWxApp();
            if (data) {
              let res = {};
              if (!this.wxVipcardForm.cardId) {
                res = await cardCaseSave(this.wxVipcardForm);
                this.wxVipcardForm = res.data;
              } else {
                res = await cardCaseUpdate(this.wxVipcardForm);
                this.wxVipcardForm = res.data;
              }
              this.multiple = false;
              this.$message.success("保存成功");
            } else this.$message.error("请先绑定微信公众号");
          } catch (err) {}
          this.loading = false;
        } else this.$message.error("请把带有红星的必填项输入完整!");
      });
    },
    //上传阿里图库 Logo
    getFileItemsDataLogo(value) {
      this.$set(this.wxVipcardForm, "cardLogoUrl", value.accessUrl);
    },
    //删除阿里图库 Logo
    delFileItemsLogo(value) {
      this.$set(this.wxVipcardForm, "cardLogoUrl", undefined);
    },
    //上传阿里图库  卡背景
    getFileItemsData(value) {
      this.$set(this.wxVipcardForm, "backgroundPicUrl", value.accessUrl);
      console.log("backgroundPicUrl12", this.wxVipcardForm.backgroundPicUrl);
    },
    //删除阿里图库
    delFileItems(value) {
      this.$set(this.wxVipcardForm, "backgroundPicUrl", undefined);
    },
    //图片规范
    getImageNorm() {
      window.open(
        "https://mp.weixin.qq.com/cgi-bin/readtemplate?t=cardticket/card_cover_tmpl&type=info&lang=zh_CN",
        "_blank"
      );
    },
    // 复制
    copyUrl(type) {
      let copyValue;
      if (type === 1) {
        if (!this.wxVipcardForm.receiveWxCardLink) return;
        copyValue = this.wxVipcardForm.receiveWxCardLink;
      } else {
        if (!this.textLink) return;
        copyValue = this.textLink;
      }
      var textarea = document.createElement("textarea");
      document.body.appendChild(textarea);
      // 隐藏此输入框
      textarea.style.position = "fixed";
      textarea.style.clip = "rect(0 0 0 0)";
      textarea.style.top = "10px";
      // 赋值
      textarea.value = copyValue;
      // 选中
      textarea.select();
      // 复制
      document.execCommand("copy", true);
      // 移除输入框
      document.body.removeChild(textarea);
      this.$message.success("复制成功");
    },
  },
};
</script>

<style scoped lang="scss">
//整体盒子
.wrap {
  width: 100%;
  padding: 10px;
  background-color: #eaeaea;
  min-height: calc(100vh - 84px);
  .content {
    background-color: #fff;
    padding: 10px;
    min-height: calc(100vh - 110px);
  }
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }

  //基本信息
  .tableContent {
    margin: 6px 10px;
    //实体卡整体
    .physicalCard {
      // height: 694px;
      padding-top: 200px;
      width: 388px;
      background-color: #fafafa;
      //实体卡
      .vipCard {
        width: 340px;
        height: 232px;
        background-color: rgb(99, 179, 89);
        font-size: 14px;
        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(218, 218, 218);
        background-size: 100% 100%;
        padding: 18px;
      }
    }

    //创建方式
    .creationMode {
      ::v-deep .el-form-item__content {
        display: flex;
        height: 36px;
      }
      ::v-deep .el-radio-group {
        display: flex;
        align-items: center;
      }
    }

    //会员卡名称
    .vipName {
      margin-left: 30px;
    }

    //商家信息
    .merchantInfo {
      margin-left: 45px;
    }

    //会员卡商家图片
    .vipBusinessImg {
      margin-left: 45px;

      .upload {
        margin-left: 93px;
      }
    }

    //会员卡底色
    .vipBColor {
      margin-left: 36px;
    }

    //选择底色
    .selectBackgroundColor {
      margin-left: 51px;
    }

    //显示微信支付
    .showWXPay {
      margin-left: 24px;
    }

    //textarea输入框
    .textareaWidth {
      width: 628px;
    }
  }
  .putInSet {
    height: calc(100vh - 160px);
  }
}

//输入框的高度
::v-deep .el-input--medium .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
}

#table-page {
  background-color: #fff;
  padding: 0px;
  ::v-deep .el-card {
    border: none;
  }
  ::v-deep .cardContent {
    padding: 0;
  }
  ::v-deep .el-card.is-always-shadow {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}
::v-deep .el-table__body-wrapper {
  height: 100% !important;
}
::v-deep .show-left-view .page-tree-view {
  width: 250px !important;
  min-width: 250px !important;
  max-width: 250px !important;
  border: 1px solid #dfe6ec !important;
}
::v-deep .show-left-view2 .page-right-view-2 {
  width: calc(100% - 258px) !important;
  margin-left: 8px !important;
}
</style>
