<!-- 助力瓜分优惠券 -->
<template>
  <div class="wrap">
    <TablePage
      ref="tablePage"
      v-show="showList"
      v-model="options"
      @handleEvent="handleEvent"
    />
    <TablePage
      ref="tablePageDetail"
      v-if="!showList"
      class="detail"
      v-model="optionsDetail"
      @handleEvent="handleEventDetail"
    />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  couponFissionList,
  couponFissionEnable,
  couponFissionAudit,
  couponFissionDelete,
  couponFissionCopy,
  getMarketingCouponFissionCaseLog,
} from "@/api/O2OMall/marketing/couponFission"; //接口api
export default {
  name: "StockQuery",
  components: { TablePage },
  dicts: ["bill_status"],
  data() {
    return {
      showList: true,
      openStockQuery: false,
      goodsDetailData: {},
      options: {
        uuid: "8897acfc-70b2-94d4-3df8-b454268e1ddc",
        radioSelect: true,
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        rowKey: "billId",
        check: [],
        title: "优惠券裂变",
        tableTitle: "优惠券裂变",
        search: [
          {
            label: "创建时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ queryBegTime: a, queryEndTime: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/活动编号/活动名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "活动编号" },
              { filter: "billNames", label: "活动名称" },
            ],
          },
          this.$select({
            key: "executionStatus",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "isStopState",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "bill_status",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "recon",
            label: "启用",
            type: "warning",
            btnType: "dropdown",
            other: [
              {
                click: "redoRecon",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
            alertText: '确认要删除选中的活动吗？',
          },
          {
            click: "oepnDetail",
            label: "裂变优惠券领用记录",
            type: "primary",
            right: true,
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        getListApi: couponFissionList,
        columns: [
          {
            prop: "billNo",
            label: "活动编号",
            type: "link",
            click: "routerLink",
            minWidth: 155,
          },
          {
            prop: "billName",
            label: "活动名称",
            minWidth: 155,
          },
          {
            prop: "billStatus",
            label: "活动状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
            // formatter: (val) => {
            //   console.log(val, 'val')
            //   let obj = {
            //     0 : '0 ',
            //     1 : '0',
            //     2 : '0',
            //   }
            //   return [0, 1, 2].includes(val)? obj[val] : '';
            // },
          },
          {
            prop: "executionStatusName",
            label: "执行状态",
            minWidth: 155,
            formatter: (v, row) => {
              let currentTime = new Date().getTime();
              let startTime = new Date(row.begTime).getTime();
              let endTime = new Date(row.endTime).getTime();
              return currentTime < startTime
                ? "未开始"
                : currentTime > endTime
                ? "已失效"
                : "进行中";
            },
          },
          {
            prop: "isStop",
            label: "是否停用",
            formatter: (v, row) => (row.isStop ? "禁用" : "启用"),
            minWidth: 120,
          },
          {
            prop: "begTime",
            label: "生效时间",
            width: 170,
          },
          {
            prop: "endTime",
            label: "失效时间",
            width: 170,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 120,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
        list: [
          {
            goodsNo: "000",
          },
        ],
      },
      optionsDetail: {
        uuid: "012743b9-6729-ad38-f81d-cffaa880b598",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "优惠券裂变明细",
        tableTitle: "优惠券裂变明细",
        rowKey: "billId",
        // list: [],
        defaultBody: {
          billId: "",
        },
        getListApi: getMarketingCouponFissionCaseLog,
        search: [
          {
            label: "领取时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ queryBegTime: a, queryEndTime: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/活动编号/活动名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "couponNos", label: "优惠券编号" },
              { filter: "couponNames", label: "优惠券名称" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
            ],
          },
          {
            label: "使用时间",
            type: "datetimerange",
            model: "",
            seniorSearch: true,
            filterFnc: ([a, b] = []) => ({ beginApplyTime: a, endApplyTime: b }),
          },
          this.$select({
            key: "isCouponStatus",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "close",
            label: "关闭",
            type: "primary",
            right: true,
            // disabled: () => !this.optionsDetail?.check?.length,
          },
        ],
        columns: [
          { label: "会员卡号", prop: "vipNo", fixed: true, minWidth: 120 },
          { label: "会员名称", prop: "vipName", minWidth: 100 },
          { label: "手机号", prop: "tel", minWidth: 120 },
          { label: "优惠券编号", prop: "couponNo", minWidth: 100 },
          { label: "优惠券名称", prop: "couponName", minWidth: 80 },
          { label: "领取时间", prop: "createTime", minWidth: 80 },
          { label: "使用时间", prop: "applyTime", minWidth: 80 },
          {
            label: "使用状态",
            prop: "couponStatus",
            formatter: (v, row) => {
              let obj = {
                0: "未使用",
                1: "已使用",
              };
              return obj[row.couponStatus];
            },
            minWidth: 80,
          },
        ],
        list: [{}],
      },
    };
  },
  methods: {
    async handleEventDetail(type, row) {
      switch (type) {
        case "close": // 关闭二级弹窗
          this.showList = true;
          break;
      }
    },
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "close":
          this.showList = true;
          this.optionsDetail.list = [];
          break;
        case "oepnDetail":
          if (this.options.check.length == 0) return this.$message.warning("请选择活动");
          this.optionsDetail.defaultBody.billId = this.options.check[0].billId;
          this.showList = false;
          break;
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: "CouponFissionDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push({
              name: "CouponFissionDetail",
              query: {
                type: "Add",
              },
            });
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            const billId = selectData
              .filter((i) => i.billStatus != 2)
              .map((i) => i.billId);
            if (billId.length == 0) {
              return this.$message.warning("请选择未审核的数据");
            }
            couponFissionAudit({ billIds: billId, auditStatus: 2 }).then((res) => {
              this.$message.success("审核成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            const billIds = selectData.map((i) => i.billId);
            couponFissionDelete(billIds).then((res) => {
              this.$message.success("删除成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        case "copy":
          {
            if (!selectData.length) return;
            const billIds = selectData.map((i) => i.billId);
            couponFissionCopy(billIds).then((res) => {
              this.$message.success("复制成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        case "recon":
          {
            if (!selectData.length) return;
            const billId = selectData.map((i) => i.billId);
            if (selectData[0].billStatus != 2)
              return this.$message.warning("只能操作已审核的数据");
            couponFissionEnable({ billIds: billId, isStop: false }).then((res) => {
              this.$message.success("启用成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        case "redoRecon":
          {
            if (!selectData.length) return;
            const billId = selectData.map((i) => i.billId);
            if (selectData[0].billStatus != 2)
              return this.$message.warning("只能操作已审核的数据");
            couponFissionEnable({ billIds: billId, isStop: true }).then((res) => {
              this.$message.success("禁用成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  ::v-deep .el-table {
    height: 500px !important;
    max-height: 500px !important;
    overflow: visible !important;
  }
}
</style>
