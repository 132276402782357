<template>
  <img
    :width="widthMM"
    :height="heightMM"
    :fontSize="fontSize"
    :displayValue="displayValue"
    :margin="margin"
    ref="barcode"
  />
</template>

<script>
import JsBarcode from "jsbarcode";

export default {
  // model: { prop: 'value', event: 'modelValue' },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    //线宽
    width: {
      type: Number,
      default: 25.4,
    },
    //条码高度
    height: {
      type: Number,
      default: 6,
    },
    //设置文本的大小
    fontSize: {
      type: Number,
      default: 10,
    },
    //设置条形码周围的空白边距
    margin: {
      type: Number,
      default: 0,
    },
    //是否在条形码下方显示文字
    displayValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      widthMM: 100,
      heightMM: 20,
    };
  },
  watch: {
    width: {
      handler(newVal) {
        this.change();
      },
      immediate: true,
      deep: true,
    },
    height: {
      handler(newVal) {
        this.change();
      },
      immediate: true,
      deep: true,
    },
    value: {
      handler(newVal) {
        this.change();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.widthMM = (this.width * 96) / 25.4
    //   this.heightMM = (this.height * 96) / 25.4
    //   JsBarcode(this.$refs.barcode, this.value, {
    //     width: this.widthMM,
    //     height: this.heightMM,
    //     fontSize: this.fontSize,
    //     displayValue: this.displayValue,
    //     margin: this.margin
    //   })
    // })
  },
  methods: {
    change() {
      this.widthMM = (this.width * 96) / 25.4;
      this.heightMM = (this.height * 96) / 25.4;
      this.$nextTick(() => {
        JsBarcode(this.$refs.barcode, this.value, {
          width: this.widthMM,
          height: this.heightMM,
          fontSize: this.fontSize,
          displayValue: this.displayValue,
          margin: this.margin,
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
