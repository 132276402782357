import request from '@/utils/request'

// 采购订单列表
export function listBill(query) {
  return request({
    url: '/api/system/purchase/bill/list',
    method: 'get',
    params: query
  })
}

// 采购订单列表汇总
export function sumlistBill(query) {
  return request({
    url: '/api/system/purchase/bill/sumList',
    method: 'get',
    params: query
  })
}

//新增采购订单单据
export function addOrder(data) {
  return request({
    url: '/api/system/purchase/bill/save',
    method: 'POST',
    data
  })
}

//获取采购订单详情
export function getDetailBill(billId) {
  return request({
    url: `/api/system/purchase/bill/getDetail?billId=${billId}`
  })
}

//修改采购订单单据
export function updateOrder(data) {
  return request({
    url: '/api/system/purchase/bill/update',
    method: 'PUT',
    data
  })
}

//删除采购订单单据
export function removeOrder(billIds) {
  return request({
    url: '/api/system/purchase/bill/remove',
    method: 'delete',
    data: billIds
  })
}

//审核/反审核采购订单
export function updateOrderStatus(billIds, billStatus) {
  return request({
    url: `/api/system/purchase/bill/updateBillStatus`,
    method: 'POST',
    data: {
      billIds,
      billStatus
    }
  })
}

//终止采购订单
export function closeOrder(billIds) {
  return request({
    url: '/api/system/purchase/bill/closeBillOrder',
    method: 'PUT',
    data: billIds
  })
}

