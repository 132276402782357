<template>
    <div class="wrap" v-loading="loading" id="wrap" ref="wrap">
        <el-form ref="form" :model="options" :rules="rules" label-width="106px">
            <topOperatingButton 
                :isExamineBtn="![2 , '2'].includes(options.billStatus)"
                :showAudit="isShowAudit"
                :disabled='disableOperate'
                id="topOperatingButton"
                ref="topOperatingButton"
                @submitForm="submitForm(false)"
                @addBill="submitForm(true)"
                @auditBill="auditForm"
                @getQuit="getQuit">
            </topOperatingButton>
            <cardTitleCom cardTitle="基本信息" id="basicInformation" ref="basicInformation" :billStatus="options.billStatus">
                <template slot="cardContent">
                    <div class="x-f marT10">
                        <el-form-item label="单据编号" required>
                            <el-input class="inputWidth" size="mini" :disabled="true" v-model="options.billNo"
                                placeholder="单据编号"></el-input>
                        </el-form-item>
                        <el-form-item label="单据日期" required>
                            <el-date-picker class="inputWidth" size="mini" v-model="options.billDate" type="date"
                                placeholder="单据日期" :disabled='disableOperate'
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="核销类型" required>
                            <el-select @change="changeBillSubType" :disabled='disableOperate' v-model="options.billSubType" clearable filterable size="mini"
                                placeholder="请选择">
                                <el-option label="预收冲应收" :value="1" />
                                <el-option label="预付冲应付" :value="2" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="客户" required  v-if="options.billSubType == 1">
                            <el-select @change="changePartner" v-model="options.partnerId" :disabled='disableOperate' clearable filterable size="mini"
                                placeholder="请选择">
                                <el-option v-for="item in customerData" :key="item.customerName" :label="item.customerName"
                                    :value="item.customerId" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="供应商" required v-if="options.billSubType == 2">
                            <el-select @change="changePartner" v-model="options.partnerId" :disabled='disableOperate' clearable filterable size="mini"
                                placeholder="请选择">
                                <el-option v-for="item in partnerData" :key="item.supplierId" :label="item.supplierName"
                                    :value="item.supplierId" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="部门">
                            <el-select v-model="options.deptId" :disabled='disableOperate' clearable filterable size="mini" placeholder="请选择">
                                <el-option v-for="item in deptData" :key="item.deptId" :label="item.deptName"
                                    :value="item.deptId" />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="x-f">
                        <el-form-item label="业务员">
                            <el-select v-model="options.saleEmployeeId" :disabled='disableOperate' clearable filterable size="mini" placeholder="请选择">
                                <el-option v-for="item in employeeData" :key="item.employeeId" :label="item.employeeName"
                                    :value="item.employeeId" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="备注" prop="billRemark">
                            <el-input size="mini" class="inputWidth"  v-model="options.billRemark" type="textarea"
                                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 1 }"
                                :disabled='disableOperate' />
                        </el-form-item>
                    </div>
                </template>
            </cardTitleCom>
            <cardTitleCom :cardTitle="options.billSubType == 1? '预收单据' : '预付单据'" class="options">

                <template slot="leftCardTitle">
                    <div class="leftContent x-fsa marL15">
                        <el-button class="but" size="mini" :disabled='disableOperate' @click="selectSource(1)">选择源单</el-button>
                        <span class="text">优惠金额：</span>
                        <el-input class="fl"
                            :disabled='disableOperate'
                            @input="limitInputlength(options.discountMoney, options,'discountMoney', false, 4 , true , 99999999)"
                            v-model="options.discountMoney" placeholder="请输入优惠金额"></el-input>
                        <el-tooltip class="fl" effect="dark" content="计算优惠金额" placement="top">
                            <i class="el-icon-question" style="color: #a4a4a4"></i>
                        </el-tooltip>
                    </div>
                </template>
                <template slot="cardContent">
                    <div class="table">
                        <!-- 可编辑表格 -->
                        <EditTable :options="options" @handleEvent="handleEvent" />
                    </div>
                </template>
            </cardTitleCom>
            <cardTitleCom :cardTitle="options.billSubType == 1? '应收单据' : '应付单据'" class="sourceOptions">
                <template slot="leftCardTitle">
                    <div style="padding: 5px;">
                        <el-button size="mini" :disabled='disableOperate' @click="selectSource(2)">选择源单</el-button>
                        <el-button type="primary" @click="verification()" size="mini" :disabled="disableOperate">自动核销</el-button>
                    </div>
                </template>
                <template slot="cardContent">
                    <div class="table">
                        <!-- 可编辑表格 -->
                        <EditTable :options="sourceOptions" @handleEvent="handleEventSourceOptions" />
                    </div>
                </template>
            </cardTitleCom>
            <cardTitleCom cardTitle="附件">
                <template slot="cardContent">
                <!-- 附件上传组件 -->
                <accessoryUpload
                    listType="img"
                    class="accessoryUpload"
                    :disabled="disableOperate"
                    :fileList="options.fileItems"
                    @getFileItems="getFileItemsData"
                    @delFileItems="deleteList"
                ></accessoryUpload>
                </template>
            </cardTitleCom>
        </el-form>
        <!-- 弹窗 -->
        <Dialog ref="tablePageDialog" :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </div>
</template>
  
<script>
import { addManualSettleBill, manualSettleModifyManualSettleBill, getManualSettleBillByBillId, manualSettleModifyBillStatus } from '@/api/arap/index'
import { listDept } from '@/api/system/dept'
import { allCustomerList, allPartnerList } from '@/api/partner/partner'
import { allEmployeeList } from '@/api/system/employee' //业务员
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import EditTable from '@/components/editTable' //可编辑表格
import Dialog from '@/components/Dialog'
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import { getBillNo } from '@/api/codeRule'
import { uniqWith } from 'lodash' //去重
import { limitInputlength, processSourceOptionsList, fcount } from '@/utils'
import accessoryUpload from '@/views/components/accessoryUpload' //附件上传组件

export default {
    name: 'FillDetail',
    components: {
        topOperatingButton,
        EditTable,
        cardTitleCom,
        Dialog,
        accessoryUpload,
    },
    watch: {
        'options.billStatus': {
            handler(val) {
                this.disableOperate = ['2', '3', '4', 2, 3, 4].includes(val)
                this.isShowAudit = ['', 4, '4'].includes(val)

                this.options.columns[7].disabled = this.disableOperate
                this.options.columns[8].disabled = this.disableOperate
                
                this.sourceOptions.columns[5].disabled = this.disableOperate
                this.sourceOptions.columns[6].disabled = this.disableOperate
                this.sourceOptions.columns[7].disabled = this.disableOperate
                this.sourceOptions.columns[8].disabled = this.disableOperate
                this.options.status = this.disableOperate
            },
            immediate: true
        },
    },
    data() {
        return {
            disableOperate: false,
            isShowAudit: '',
            type: '',
            isAdd: '',
            customerData: [],
            partnerData: [],
            deptData: [],
            employeeData: [],
            loading: false, //遮罩层
            //表格配置
            options: {
                fileItems: [],
                billNo: undefined,
                billDate: undefined,
                billSubType: 1,
                partnerId: undefined,
                deptId: undefined,
                saleEmployeeId: undefined,
                discountMoney: undefined,
                tableIndex: 0,
                retainColumn: false,
                curListIndex: -1, //当前表格行
                mutiSelect: false, //是否多选
                list: [],
                curListIndex: -1, //当前表格下标
                tableCellLabel: '序号',
                tableHeight: 200, //表格高度
                status: undefined, //单据状态
                isNoAddBtn: true, //不显示表格增加
                hideTotal: true,
                columns: [
                    {
                        prop: 'billTypeName',
                        label: '源单类型',
                        minWidth: 120,
                        align: 'center'
                    },
                    {
                        prop: 'billNo',
                        label: '源单编号',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'billDate',
                        label: '源单日期',
                        minWidth: 120,
                        align: 'center'
                    },
                    {
                        prop: 'billRemark',
                        label: '源单备注',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'billTaxMoney',
                        label: '源单金额',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'settleMoney',
                        label: '源单已核销金额',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'unverified',
                        label: '源单未核销金额',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'nowWriteOffMoney',
                        label: '本次核销金额',
                        minWidth: 100,
                        align: 'center',
                        type: 'input',
                        input: 'nowWriteOffMoney',
                        disabled: false,
                        isMinus: true,
                        inputNum: 4
                    },
                    {
                        prop: 'remark',
                        label: '备注',
                        minWidth: 100,
                        type: 'my-input',
                        align: 'center',
                        maxlength:80
                    },
                ],
                summary: ['billTaxMoney','settleMoney','unverified','nowWriteOffMoney'],
            },
            sourceOptions: {
                curListIndex: -1, //当前表格行
                mutiSelect: false, //是否多选
                list: [],
                curListIndex: -1, //当前表格下标
                tableCellLabel: '序号',
                tableHeight: 300, //表格高度
                status: undefined, //单据状态
                isNoAddBtn: true, //不显示表格增加
                hideTotal: true,
                retainColumn: false,
                columns: [
                    {
                        prop: 'billTypeName',
                        label: '源单类型',
                        minWidth: 120,
                        align: 'center'
                    },
                    {
                        prop: 'billNo',
                        label: '源单编号',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'billDate',
                        label: '源单日期',
                        minWidth: 120,
                        align: 'center'
                    },
                    {
                        prop: 'billRemark',
                        label: '源单备注',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'billTaxMoney',
                        label: '源单金额',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'settleMoney',
                        label: '源单已核销金额',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'unverified',
                        label: '源单未核销金额',
                        minWidth: 100,
                        align: 'center'
                    },
                    {
                        prop: 'nowWriteOffMoney',
                        label: '本次核销金额',
                        minWidth: 100,
                        align: 'center',
                        type: 'input',
                        input: 'nowWriteOffMoney',
                        disabled: false,
                        isMinus: true,
                        inputNum: 4
                    },
                    {
                        prop: 'remark',
                        label: '备注',
                        minWidth: 100,
                        type: 'my-input',
                        align: 'center',
                        disabled: false,
                        maxlength:80
                    },
                ],
                summary: ['billTaxMoney', 'settleMoney', 'unverified', 'nowWriteOffMoney'],
            },
            //弹窗配置
            dialogOptions: {
                title: '选择',
                width: 1250,
                show: false,
                type: 'TreeAndTable'
            },
            rules: {
                billNo: [
                    {
                        required: true,
                        message: '请输入方案编号',
                        trigger: ['blur', 'change']
                    }
                ],
                billDate: [
                    { required: true, message: '请输入制单日期', trigger: 'blur' }
                ]
            },
            ceshi: undefined,
            pageH: 0, //页面高度
            btnsH: 0, //搜索框高度
            basicH: 0, //基础框高度
            paginationH: 50 //分页高度
        }
    },
    activated() {
        this.getDetail()
    },
    mounted() {
        this.getDetail()
    },
    beforeDestroy() {
    },
    created() {
        this.allCustomerListFun()
        this.allallPartnerListFun()
        this.listDeptFun()
        this.allEmployeeListFun()
    },
    computed: {},
    methods: {
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/arap/CAwriteOff/handWriteOff" });
    },
        verification () {
            let optionList = this.options.list.filter(item => item.nowWriteOffMoney !='' && item.nowWriteOffMoney != undefined)
            if (optionList.length == 0) return this.$message.error(`请先录入${this.options.billSubType == 1? '预收单据' : '预付单据'}`)
            if (this.sourceOptions.list.length == 0) return this.$message.error(`请先录入${this.options.billSubType == 1? '应收单据' : '应付单据'}`)
            this.sourceOptions = processSourceOptionsList(this.options, this.sourceOptions, {payField: 'nowWriteOffMoney', negativeDiscountMoney: this.options.billSubType == 2 })
        },
        filterData (item) {
            let obj = {}
            // 
            if ([110102, 120102, '110102', '120102', 140312, '140312', 140303, '140303'].includes(item.billType)) {
                obj = {
                    ...item,
                    // billDetailId: item.billId,
                    unverified: fcount([item.billTaxMoney, item.settleMoney],'-'),
                    nowWriteOffMoney: fcount([item.billTaxMoney, item.settleMoney],'-'),
                    sourceBillType: item.billType,
                    sourceBillId: item.billId,
                    sourceBillWrittenOffMoney: item.settleMoney,
                    remark: item.remark,
                    billRemark: item.billRemark,
                }
                return obj
            } else if ([120103, '120103'].includes(item.billType)) {
                // 销售退货单
                obj = {
                    ...item,
                    unverified: fcount([fcount([item.billTaxMoney, -1],'*'),fcount([item.settleMoney, -1],'*')],'-'),
                    nowWriteOffMoney: fcount([ fcount([item.billTaxMoney, -1],'*'), fcount([item.settleMoney, -1],'*')],'-'),
                    billTaxMoney: fcount([item.billTaxMoney, -1],'*'),
                    settleMoney: fcount([item.settleMoney, -1],'*'),
                    sourceBillType: item.billType,
                    sourceBillId: item.billId,
                    sourceBillWrittenOffMoney: fcount([item.settleMoney, -1],'*'),
                    remark: item.remark,
                    billRemark: item.billRemark,
                }
                return obj
            } else if ([100203, '100203'].includes(item.billType)) {
                // 期初预收
                obj = {
                    ...item,
                    billDate: item.bizDate,
                    unverified: fcount([item.arAmount, item.arWriteOffMoney? item.arWriteOffMoney : 0],'-'),
                    nowWriteOffMoney: fcount([item.arAmount, item.arWriteOffMoney? item.arWriteOffMoney : 0],'-'),
                    billTaxMoney: item.arAmount,
                    settleMoney: item.arWriteOffMoney? item.arWriteOffMoney : 0,
                    sourceBillType: item.billType,
                    sourceBillId: item.billId,
                    sourceBillWrittenOffMoney: item.arWriteOffMoney? item.arWriteOffMoney : 0,
                    remark: item.remark,
                    billRemark: item.billRemark,
                }
                return obj
                
            } else if ([190105, '190105'].includes(item.billType)) {
                // 其他收入
                obj = {
                    ...item,
                    billDate: item.billDate,
                    unverified: fcount([item.billWriteOffMoney, item.settleMoney? item.settleMoney : 0],'-'),
                    nowWriteOffMoney: fcount([item.billWriteOffMoney, item.settleMoney? item.settleMoney : 0],'-'),
                    billTaxMoney: item.billWriteOffMoney,
                    settleMoney: item.settleMoney,
                    sourceBillType: item.billType,
                    sourceBillId: item.billId,
                    sourceBillWrittenOffMoney: item.settleMoney,
                    remark: item.remark,
                    billRemark: item.billRemark,
                }
                return obj
            } else if ([190106, '190106'].includes(item.billType)) {
                // 其他收入退款
                obj = {
                    ...item,
                    billDate: item.billDate,
                    unverified: fcount([fcount([item.billWriteOffMoney, -1],'*'), item.settleMoney? fcount([item.settleMoney, -1],'*') : 0],'-'),
                    nowWriteOffMoney: fcount([fcount([item.billWriteOffMoney, -1],'*'), item.settleMoney? fcount([item.settleMoney, -1],'*') : 0],'-'),
                    billTaxMoney: fcount([item.billWriteOffMoney, -1],'*'),
                    settleMoney: fcount([item.settleMoney, -1],'*'),
                    sourceBillType: item.billType,
                    sourceBillId: item.billId,
                    sourceBillWrittenOffMoney: item.settleMoney,
                    remark: item.remark,
                    billRemark: item.billRemark,
                }
                return obj
            } else if ([110103, '110103', 140318, '140318'].includes(item.billType)) {
                // 采购退货单
                obj = {
                    ...item,
                    billTaxMoney: fcount([item.billTaxMoney, -1],'*'),
                    settleMoney: fcount([item.settleMoney, -1],'*'),
                    unverified: fcount([fcount([item.billTaxMoney, -1],'*'), fcount([item.settleMoney, -1],'*')],'-'),
                    nowWriteOffMoney: fcount([fcount([item.billTaxMoney, -1],'*'),fcount([item.settleMoney, -1],'*')],'-'),
                    sourceBillType: item.billType,
                    sourceBillId: item.billId,
                    sourceBillWrittenOffMoney: fcount([item.settleMoney, -1],'*') ,
                    remark: item.remark,
                    billRemark: item.billRemark,
                }
                return obj
            } else if ([100202, '100202'].includes(item.billType)) {
                obj = {
                    ...item,
                    billDate: item.bizDate,
                    unverified: fcount([item.apAmount, item.apWriteOffMoney? item.apWriteOffMoney : 0],'-'),
                    nowWriteOffMoney: item.apAmount,
                    billTaxMoney: item.apAmount,
                    settleMoney: item.apWriteOffMoney? item.apWriteOffMoney : 0,
                    sourceBillType: item.billType,
                    sourceBillId: item.billId,
                    sourceBillWrittenOffMoney: item.apWriteOffMoney? item.apWriteOffMoney : 0,
                    remark: item.remark,
                    billRemark: item.billRemark,
                }
                return obj
            } else if ([190205, '190205'].includes(item.billType)) {
                obj = {
                    ...item,
                    billDate: item.billDate,
                    unverified: fcount([item.billWriteOffMoney, item.settleMoney? item.settleMoney : 0],'-'),
                    nowWriteOffMoney: fcount([item.billWriteOffMoney, item.settleMoney? item.settleMoney : 0],'-'),
                    billTaxMoney: item.billWriteOffMoney,
                    settleMoney: item.settleMoney,
                    sourceBillType: item.billType,
                    sourceBillId: item.billId,
                    sourceBillWrittenOffMoney: item.settleMoney,
                    remark: item.remark,
                    billRemark: item.billRemark,
                }
                return obj
            } else if ([190206, '190206'].includes(item.billType)) {
                obj = {
                    ...item,
                    billDate: item.billDate,
                    unverified: fcount([item.billWriteOffMoney, item.settleMoney? item.settleMoney : 0],'-') * -1,
                    nowWriteOffMoney: fcount([item.billWriteOffMoney, item.settleMoney? item.settleMoney : 0],'-') * -1,
                    billTaxMoney: item.billWriteOffMoney * -1,
                    settleMoney: item.settleMoney * -1,
                    sourceBillType: item.billType,
                    sourceBillId: item.billId,
                    sourceBillWrittenOffMoney: item.settleMoney,
                    remark: item.remark,
                    billRemark: item.billRemark,
                }
                return obj
            }
        },
        changeBillSubType () {
            this.options.partnerId = ''
            this.sourceOptions.list = []
            this.options.list = []
        },
        allCustomerListFun() {
            allCustomerList().then(response => {
                this.customerData = response.data
            })
        },
        allallPartnerListFun() {
            allPartnerList().then(response => {
                this.partnerData = response.data
            })
        },
    deleteList(val) {
      this.options.fileItems = this.options.fileItems.filter(v => v.url != val.url)
    },
        //获取附件上传的信息数据
        getFileItemsData (data) {
            console.log(data, 'data')
            this.options.fileItems.push({
                accessUrl: data.accessUrl,
                domain: data.domain,
                filePath:data.filePath,
                fileName: data.fileName,
                fileSize: data.fileSize,
                name: data.fileName,
                url: data.accessUrl
            })
        },
        selectSource(type) {
            if (!this.options.billSubType) return this.$message.error(`请选择核销类型`)
            if (!this.options.partnerId) return this.$message.error(`请选择${this.options.billSubType == 1? '客户' : '供应商'}`)
            this.type = type
            this.dialogOptions = {
                show: true,
                type: "SelectSource",
                click: 'SelectSource',
                data: { billType: "190301", type: type, billSubType: this.options.billSubType, partnerId: this.options.partnerId },
                title: "选择源单",
            };
        },
        //保留小数位
        limitInputlength,
        listDeptFun() {
            listDept({
                pageNum: 1,
                pageSize: 1000
            }).then(response => {
                this.deptData = response.data
            })
        },
        allEmployeeListFun() {
            allEmployeeList({}).then(response => {
                this.employeeData = response.data
            })
        },
        //获取详情
        async getDetail() {
            this.reset()
            if (this.$route.query.type === 'Update' && this.$route.query.billId) {
                this.loading = true
                try {
                    const { data } = await getManualSettleBillByBillId({ billId: this.$route.query.billId })
                    //方案赋值
                    this.options = {
                        ...this.options,
                        ...data,
                        list: []
                    }
                    this.options.list = data.preRpDetails.map((item) => (
                        {
                            ...item,
                            billTypeName: item.sourceBillTypeName,
                            billNo: item.sourceBillNo,
                            billDate: item.sourceBillDateStr,
                            billRemark: item.sourceBillRemark,
                            billTaxMoney: item.sourceBillMoney,
                            settleMoney: item.sourceBillWrittenOffMoney,
                            unverified: fcount([item.sourceBillMoney, item.sourceBillWrittenOffMoney],'-'),
                            nowWriteOffMoney: item.nowWriteOffMoney,
                            remark: item.remark,
                        }
                    ))
                    // this.sourceOptions.list = (data.billSubType == 1?  data.arDetails : data.apDetails).map((item) => (
                 this.sourceOptions.list = data.businessDetails.map((item) => {
                  const negative = [110103, '110103', 140318, '140318', 190206, '190206', 120103, '120103', 190106, '190106'].includes(item.billType || item.sourceBillType)
                  return(
                        {
                            ...item,
                            billTypeName: item.sourceBillTypeName,
                            billNo: item.sourceBillNo,
                            billDate: item.sourceBillDateStr,
                            billRemark: item.sourceBillRemark,
                            billTaxMoney: negative ? fcount([item.sourceBillMoney, -1],'*') : item.sourceBillMoney,
                            settleMoney:  negative ? fcount([item.sourceBillWrittenOffMoney, -1],'*') : item.sourceBillWrittenOffMoney,
                            unverified: negative ? fcount([fcount([item.sourceBillMoney, item.sourceBillWrittenOffMoney],'-'), -1],'*') : fcount([item.sourceBillMoney, item.sourceBillWrittenOffMoney],'-'),
                            nowWriteOffMoney: negative ? fcount([item.nowWriteOffMoney, -1],'*') : item.nowWriteOffMoney,
                            remark: item.remark,
                        }
                    )})
                    if (this.options.fileItems?.length > 0) {
                        this.options.fileItems = this.options.fileItems.map((item)=> (
                        {
                            ...item,
                            name: item.fileName,
                            url: item.accessUrl,
                        }
                        ))
                    }
                } catch (err) { }
                this.loading = false
            } else {
                this.getBillno()
            }
        },
        changePartner (val) {
            this.sourceOptions.list = []
            this.options.list = []
        },
        async handleEventSourceOptions(type, row) {
            //获取元素在数组的下标
            let contains = function (list, row) {
                var i = list.length
                while (i--) {
                    if (list[i] === row) {
                        return i
                    }
                }
                return false
            }
            switch (type) {
                case 'nowWriteOffMoney':
                  // console.log('nowWriteOffMoney1',row);
                  //   let index = contains(this.sourceOptions.list, row)
                  //   if (['120103', '190106', 120103, 190106].includes(this.sourceOptions.list[index].sourceBillType) && this.sourceOptions.list[index].nowWriteOffMoney > 0) {
                  // console.log('nowWriteOffMoney2',this.sourceOptions.list[index].nowWriteOffMoney);
                  //     this.sourceOptions.list[index].nowWriteOffMoney = ''
                  //       return
                  //   }
                  //   if (this.sourceOptions.list[index].nowWriteOffMoney) {
                  // console.log('nowWriteOffMoney3',this.sourceOptions.list[index].nowWriteOffMoney);
                  //       this.limitInputlength(
                  //           this.sourceOptions.list[index].nowWriteOffMoney,
                  //           this.sourceOptions.list[index],
                  //           'nowWriteOffMoney',
                  //           (['120103', '190106', 120103, 190106].includes(this.sourceOptions.list[index].sourceBillType))? false : true
                  //       )
                  //   }
                    break
                case 'tableIndex':
                    this.options.tableIndex = row
                    break
                default:
                    break
            }
        },
        async handleEvent(type, row) {
            //获取元素在数组的下标
            let contains = function (list, row) {
                var i = list.length
                while (i--) {
                    if (list[i] === row) {
                        return i
                    }
                }
                return false
            }
            switch (type) {
                case 'SourceDialogChange':
                    console.log(row, 'row');
                    this.dialogOptions.show = false
                    console.log(this.options.billSubType, 'billSubType')
                    console.log(this.type, 'type')
                    if (this.type == 1) {
                        // billSubType 1 预收款单 billSubType2预付款单
                        // if (this.options.billSubType == 1) {
                          const isBol = row.tabColumnType == 'saleOrder' || row.tabColumnType == 'purchaseOrder'
                        row.list = row.list.map((item) => (
                            {
                                ...item,
                                // billDetailId: item.billId,
                                billTaxMoney: isBol?  item.billRpMoney : 
                                    ['1', 1].includes(this.options.billSubType) ? item.preArAmount : item.preApAmount,
                                settleMoney: isBol?  item.settleMoney : 
                                    ['1', 1].includes(this.options.billSubType) ? item.preArWriteOffMoney : item.preApWriteOffMoney,
                                unverified: isBol? fcount([item.billRpMoney, item.settleMoney],'-') 
                                    : ['1', 1].includes(this.options.billSubType) ? fcount([item.preArAmount , item.preArWriteOffMoney],'-') 
                                    : fcount([item.preApAmount, item.preApWriteOffMoney],'-'),
                                nowWriteOffMoney: isBol?  fcount([item.billRpMoney, item.settleMoney],'-')
                                    : ['1', 1].includes(this.options.billSubType) ? fcount([item.preArAmount, item.preArWriteOffMoney],'-') 
                                    : fcount([item.preApAmount, item.preApWriteOffMoney],'-'),
                                sourceBillType: item.billType,
                                sourceBillId: item.billId,
                                sourceBillWrittenOffMoney: isBol?  item.settleMoney : 
                                    ['1', 1].includes(this.options.billSubType) ? item.preArWriteOffMoney : item.preApWriteOffMoney,
                                remark: item.remark,
                                billRemark: item.billRemark,
                                billDate: isBol?  item.billDate : item.bizDate,
                            }
                        ))
                        this.options.list = [...this.options.list, ...row.list]
                        this.options.list = uniqWith(this.options.list, (x, y) => x.sourceBillId== y.sourceBillId).filter(x => x.sourceBillId)
                    } if (this.type == 2) {
                        row.list = row.list.map((item) => (
                            {   
                                ...this.filterData(item)
                                // ...item,
                                // billDetailId: item.billId,
                                // unverified: fcount([item.billTaxMoney, item.settleMoney],'-'),
                                // nowWriteOffMoney: fcount([item.billTaxMoney, item.settleMoney],'-'),
                                // sourceBillType: item.billType,
                                // sourceBillId: item.billId,
                                // sourceBillWrittenOffMoney: item.settleMoney,
                                // remark: item.remark,
                                // billRemark: item.billRemark,
                            }
                        ))
                        this.sourceOptions.list = [...this.sourceOptions.list, ...row.list]
                        this.sourceOptions.list = uniqWith(this.sourceOptions.list, (x, y) => x.sourceBillId== y.sourceBillId).filter(x => x.sourceBillId)
                    }
                    break;
                // case 'nowWriteOffMoney':
                //     // let index = contains(this.sourceOptions.list, row)
                //     // if (this.sourceOptions.list[index].nowWriteOffMoney) {
                //     //   this.limitInputlength(
                //     //     this.sourceOptions.list[index].nowWriteOffMoney,
                //     //     this.sourceOptions.list[index],
                //     //     'nowWriteOffMoney',
                //     //     this.sourceOptions.list[index].billTypeName == '销售退货单'? false : true
                //     //   )
                //     // }
                //     break
                case 'tableIndex':
                    this.options.tableIndex = row
                    break
                default:
                    break
            }
        },
        async userSubmit(isAdd) {
            this.loading = true
            let form = {
                billNo: this.options.billNo,
                billId: this.options.billId,
                partnerId: this.options.partnerId,
                deptId: this.options.deptId,
                saleEmployeeId: this.options.saleEmployeeId,
                billRemark: this.options.billRemark,
                discountMoney: this.options.discountMoney,
                billDate: this.options.billDate
                    ? this.options.billDate.split(' ')[0]
                    : '', // 方案时间
                preRpDetails: this.options.list,
                billSubType: this.options.billSubType,
                fileItems : this.options.fileItems,
            }
            // if (this.options.billSubType == 1) {
            //     form.arDetails = this.sourceOptions.list
            // } else if (this.options.billSubType == 2) {
            //     form.apDetails = this.sourceOptions.list
            // }
            form.businessDetails = this.sourceOptions.list
            if (form.billId) {
                manualSettleModifyManualSettleBill(form).then((res) => {
                    this.options = {
                        ...this.options,
                        billStatus: res.data.billStatus
                    }
                    // let obj = this.filterDataFun(res.data.preRpDetails, res.data.billSubType == 1?  res.data.arDetails : res.data.apDetails)
                    let obj = this.filterDataFun(res.data.preRpDetails, res.data.businessDetails)
                    console.log(obj, 'obj')
                    this.options.list = obj.optionsArr
                    this.sourceOptions.list = obj.sourceArr
                    this.$message.success('修改成功')
                    //新增
                    if (isAdd) {
                        this.reset()
                        this.getBillno()
                    }
                    this.loading = false
                }).catch((err) => {
                    console.log(err, 'err')
                    this.loading = false
                })

            } else {
                addManualSettleBill(form).then((res) => {
                    this.options = {
                        ...this.options,
                        billStatus: res.data.billStatus,
                        billId: res.data.billId
                    }
                    let obj = this.filterDataFun(res.data.preRpDetails, res.data.businessDetails)
                    console.log(obj, 'obj')
                    this.options.list = obj.optionsArr
                    this.sourceOptions.list = obj.sourceArr
                    this.$message.success('新增成功')
                    //新增
                    if (isAdd) {
                        this.reset()
                        this.getBillno()
                    }
                    this.loading = false
                }).catch((err) => {
                    console.log(err, 'err')
                    this.loading = false
                })
            }

        },
        filterDataFun (optionsList, sourceList) {
            let obj = {
                optionsArr: [],
                sourceArr: [],
            }
            obj.optionsArr = optionsList.map((item) => (
                {
                    ...item,
                    billTypeName: item.sourceBillTypeName,
                    billNo: item.sourceBillNo,
                    billDate: item.sourceBillDateStr,
                    billRemark: item.sourceBillRemark,
                    billTaxMoney: item.sourceBillMoney,
                    settleMoney: item.sourceBillWrittenOffMoney,
                    unverified: fcount([item.sourceBillMoney, item.sourceBillWrittenOffMoney],'-'),
                    nowWriteOffMoney: item.nowWriteOffMoney,
                    remark: item.remark,
                }
            ))
            obj.sourceArr = sourceList.map((item) => {
          const negative = [110103, '110103', 140318, '140318', 190206, '190206', 120103, '120103', 190106, '190106'].includes(item.billType || item.sourceBillType)
              return(
                {
                    ...item,
                    billTypeName: item.sourceBillTypeName,
                    billNo: item.sourceBillNo,
                    billDate: item.sourceBillDateStr,
                    billRemark: item.sourceBillRemark,
                    billTaxMoney: negative ? fcount([item.sourceBillMoney, -1],'*') : item.sourceBillMoney,
                    settleMoney: negative ? fcount([item.sourceBillWrittenOffMoney, -1],'*') : item.sourceBillWrittenOffMoney,
                    unverified: negative ? fcount([fcount([item.sourceBillMoney, item.sourceBillWrittenOffMoney],'-'), -1],'*') : fcount([item.sourceBillMoney, item.sourceBillWrittenOffMoney],'-'),
                    nowWriteOffMoney: negative ? fcount([item.nowWriteOffMoney, -1],'*') : item.nowWriteOffMoney,
                    remark: item.remark,
                }
            )})
            return obj
        },
        // 提交保存
        async submitForm(isAdd) {
            this.isAdd = isAdd
            this.$refs['form'].validate(async valid => {
                if (valid) {
                    // 优惠金额 = 本次核销金额 - (收款金额 + 手续费)
                    let payMoneyTotal = 0; // 本次收款统计
                    let nowWriteOffMoneyTotal = 0; // 本次核销金额统计
                    payMoneyTotal = this.options.list.reduce((prev, next) => { return prev + Number(next.nowWriteOffMoney) }, 0);
                    nowWriteOffMoneyTotal = this.sourceOptions.list.reduce((prev, next) => { return prev + Number(next.nowWriteOffMoney) }, 0);
                    let discountMoney = this.options.discountMoney ? (this.options.billSubType == 1 ? this.options.discountMoney : this.options.discountMoney * -1) : 0
                    console.log('payMoneyTotal', payMoneyTotal, discountMoney, nowWriteOffMoneyTotal);
                    if ((Number(payMoneyTotal) + Number(discountMoney)) != Number(nowWriteOffMoneyTotal)) return this.$message.error(`${this.options.billSubType == 1? '预收' : '预付'}单据本次核销金额${this.options.billSubType == 1? '+' : '-'}优惠金额≠${this.options.billSubType == 1? '应收' : '应付'}单据本次核销金额!`)
                    this.userSubmit(isAdd)
                } else this.$message.error('请输入必填项!')
            })
        },
        //初始化数据
        reset() {
            //表格配置
            this.options = {
                fileItems: [],
                ...this.options,
                billNo: undefined,
                billDate: undefined,
                partnerId: undefined,
                billSubType: 1,
                deptId: undefined,
                saleEmployeeId: undefined,
                discountMoney: undefined,
            }
            this.options.list = [],
            this.sourceOptions.list = [],
            this.resetForm('form')
        },
        // 获取订单编号
        async getBillno() {
            this.options.billNo = await getBillNo(190301)
            this.options.billDate = new Date()
                .toLocaleDateString()
                .replace(/\//g, '-')
        },
        // 审核/反审核  按钮
        async auditForm() {
            if (this.options.billStatus == '0') {
                this.loading = true
                this.$confirm(`确定审核该方案`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(async () => {
                        try {
                            const { data } = await manualSettleModifyBillStatus({
                                billIds: [this.options.billId],
                                billStatus: 2
                            })
                            this.options = {
                                ...this.options,
                                billStatus: data.billStatus
                            }
                            this.$message.success(`审核成功`)
                        } catch (err) { }
                    })
                    .catch(() => { })
                this.loading = false
            } else {
                this.$message.warning('请操作未审核的方案')
            }
        },
    }
}
</script>
  
<style lang="scss" scoped>
.wrap {
    padding: 40px 10px 10px 10px;
    background-color: #eaeaea;
    height: calc(100vh - 84px);
    overflow-y: scroll;
    .accessoryUpload {
        margin: 6px 10px;
        min-height: 150px;
    }
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 250px;
}

.table {
    padding: 10px;
}

.leftContent {
    .text {
        width: 70px;
        font-size: 14px;
        line-height: 40px;
    }
    .but {
        margin-top: 5px;
        margin-right: 10px;
    }
    .el-icon-question {
        margin-top: 10px;
    }

    ::v-deep .el-input--medium {
        width: 140px;
    }

    ::v-deep .el-input__inner {
        border-radius: 0;
        border: none;
        border-bottom: 2px solid #ccc;
    }
}
</style>