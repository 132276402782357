var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "el-form" },
    _vm._l(_vm.newOptions, function (item) {
      return _c(
        "el-form-item",
        {
          key: item.model,
          attrs: {
            label: item.label,
            rules: item.rules,
            prop: item.model,
            "label-width": item.labelWidth || "80px",
          },
        },
        [
          item.type === "input"
            ? _c("el-input", {
                style:
                  "width: " + (item.style ? item.style.width : "220") + "px",
                attrs: {
                  psasdsad: "item.label",
                  disabled: item.disabled,
                  placeholder: item.tip || "请输入" + item.label,
                  size: "small",
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "checkbox-one"
            ? _vm._l(item.option.data, function (check, i) {
                return _c(
                  "el-checkbox",
                  {
                    key: i,
                    staticClass: "checkbox-one",
                    attrs: {
                      size: "small",
                      "true-label":
                        check[item.option.value || "value"] === 0
                          ? "0"
                          : check[item.option.value || "value"],
                      "false-label": "",
                      checked:
                        _vm.value[item.model] === 0
                          ? String(check[item.option.value || "value"]) === "0"
                          : check[item.option.value || "value"] ===
                            _vm.value[item.model],
                    },
                    on: {
                      change: function (e) {
                        return _vm.checkboxOne(e, item)
                      },
                    },
                    model: {
                      value: _vm.checkboxOneObj[item.model],
                      callback: function ($$v) {
                        _vm.$set(_vm.checkboxOneObj, item.model, $$v)
                      },
                      expression: "checkboxOneObj[item.model]",
                    },
                  },
                  [_vm._v(_vm._s(check[item.option.label || "label"]) + " ")]
                )
              })
            : item.type === "checkbox-more"
            ? _c(
                "el-checkbox-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.value[item.model],
                    callback: function ($$v) {
                      _vm.$set(_vm.value, item.model, $$v)
                    },
                    expression: "value[item.model]",
                  },
                },
                _vm._l(item.option.data, function (check, i) {
                  return _c(
                    "el-checkbox",
                    {
                      key: check[item.option.value] + "." + i,
                      attrs: { label: check[item.option.value || "value"] },
                    },
                    [_vm._v(_vm._s(check[item.option.label || "label"]) + " ")]
                  )
                }),
                1
              )
            : item.type === "radio-button"
            ? _c(
                "el-radio-group",
                {
                  attrs: { size: "small", disabled: item.disabled },
                  model: {
                    value: _vm.value[item.model],
                    callback: function ($$v) {
                      _vm.$set(_vm.value, item.model, $$v)
                    },
                    expression: "value[item.model]",
                  },
                },
                _vm._l(item.optionData, function (optionItem, i) {
                  return _c(
                    "el-radio-button",
                    {
                      key: optionItem.value + i,
                      attrs: { label: optionItem.value },
                    },
                    [_vm._v(_vm._s(optionItem.label) + " ")]
                  )
                }),
                1
              )
            : item.type === "radio"
            ? _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: {
                    input: function (val) {
                      return _vm.handleEvent(
                        item.input ? item.input : "radioInput",
                        val
                      )
                    },
                  },
                  model: {
                    value: _vm.value[item.model],
                    callback: function ($$v) {
                      _vm.$set(_vm.value, item.model, $$v)
                    },
                    expression: "value[item.model]",
                  },
                },
                _vm._l(item.optionData, function (radio, i) {
                  return _c(
                    "el-radio",
                    {
                      key: radio.value + "." + i,
                      attrs: { label: radio.value },
                    },
                    [_vm._v(_vm._s(radio.label) + " ")]
                  )
                }),
                1
              )
            : item.type === "text-append"
            ? _c(
                "div",
                {
                  staticStyle: {
                    height: "36px",
                    "line-height": "36px",
                    padding: "0 15px",
                    "font-size": "14px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "font-size": "28px", color: "red" } },
                    [_vm._v(_vm._s(_vm.value[item.model]))]
                  ),
                ]
              )
            : ["daterange", "datetimerange", "datetime", "date"].includes(
                item.type
              )
            ? _c("el-date-picker", {
                staticClass: "date-picker",
                attrs: {
                  size: "small",
                  "unlink-panels": "",
                  disabled: item.disabled
                    ? typeof item.disabled == "function"
                      ? item.disabled()
                      : item.disabled
                    : false,
                  "range-separator": item.option
                    ? item.option.rangeSeparato
                    : "-",
                  placeholder: item.option
                    ? item.option.placeholder
                    : "选择日期",
                  "start-placeholder": item.option
                    ? item.option.startPlaceholder
                    : "开始日期",
                  "end-placeholder": item.option
                    ? item.option.endPlaceholder
                    : "结束日期",
                  "default-time": item.option
                    ? item.option.defaultTime
                    : _vm.defaultTime[item.type],
                  type: item.type,
                  format: _vm.forMats[item.type],
                  "value-format": _vm.forMats[item.type],
                },
                on: {
                  selectChange: function (val) {
                    return _vm.change(item, val)
                  },
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "timeRange"
            ? _c("timeRange", {
                attrs: {
                  format: item.format,
                  defaultValue: item.defaultValue,
                  defaultChange: item.defaultChange,
                  data: item.data,
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "input-number"
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "flex-view" },
                  [
                    _c("el-input-number", {
                      class: { inputNumber: item.disabled },
                      style:
                        "width: " +
                        (item.style ? item.style.width : 140) +
                        "px",
                      attrs: {
                        size: "small",
                        disabled: item.disabled,
                        min: item.min,
                        max: item.max,
                        precision: item.precision || 0,
                      },
                      model: {
                        value: _vm.value[item.model],
                        callback: function ($$v) {
                          _vm.$set(_vm.value, item.model, $$v)
                        },
                        expression: "value[item.model]",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : item.type === "tel"
            ? _c("el-input", {
                style: "width: " + (item.style ? item.style.width : "") + "px",
                attrs: {
                  size: "small",
                  disabled: item.disabled,
                  type: "number",
                  placeholder: item.tip || "请输入" + item.label,
                },
                on: {
                  input: function (v) {
                    return _vm.changeNumber(v, item.model)
                  },
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "divider"
            ? _c("el-divider")
            : item.type === "textarea"
            ? _c("el-input", {
                style:
                  "width: " + (item.style ? item.style.width : "220") + "px",
                attrs: {
                  size: "small",
                  disabled:
                    typeof item.disabled === "function"
                      ? item.disabled()
                      : item.disabled,
                  type: "textarea",
                  rows: 2,
                  autosize: { minRows: 1, maxRows: 1 },
                  placeholder: item.option
                    ? item.option.placeholder
                    : "请输入内容",
                  maxlength: item.option ? item.option.maxlength : "80",
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "remote"
            ? _c("SelectRemote", {
                style:
                  "width: " + (item.style ? item.style.width : "220") + "px",
                attrs: {
                  option: item.option,
                  filterable: item.option.filterable || false,
                  multiple: item.option.multiple || false,
                  clearable: item.option.clearable || false,
                  "collapse-tags": item.option["collapse-tags"] || false,
                  placeholder: item.placeholder || "选择" + item.label,
                  disabled: item.disabled,
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "tree"
            ? _c("SelectTree", {
                attrs: {
                  option: item.option,
                  filterable: item.option.filterable || false,
                  multiple: item.option.multiple || false,
                  clearable: item.option.clearable || false,
                  "collapse-tags": item.option["collapse-tags"] || false,
                  disabled: item.disabled,
                  placeholder: item.placeholder || "选择" + item.label,
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "local"
            ? _c("SelectLocal", {
                style: { width: item.width },
                attrs: {
                  disabled:
                    typeof item.disabled === "function"
                      ? item.disabled()
                      : item.disabled,
                  option: item.option,
                  filterable: item.option.filterable || false,
                  multiple: item.option.multiple || false,
                  clearable: item.option.clearable || false,
                  "collapse-tags": item.option["collapse-tags"] || false,
                  placeholder: item.placeholder || "选择" + item.label,
                },
                on: {
                  selectChange: function (val) {
                    return _vm.change(item, val)
                  },
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "switch"
            ? _c("el-switch", {
                attrs: {
                  size: "small",
                  "active-value": item.open,
                  "inactive-value": item.close,
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "cascader"
            ? _c("el-cascader", {
                staticStyle: { width: "100%" },
                attrs: {
                  size: "small",
                  placeholder: item.placeholder || "选择" + item.label,
                  options: item.option ? item.option.data : [],
                  clearable: item.option.clearable || false,
                  filterable: item.option.filterable || false,
                  value: item.option.value,
                  label: item.option.label,
                  props: {
                    value: item.option.value,
                    label: item.option.label,
                  },
                },
                model: {
                  value: _vm.value[item.model],
                  callback: function ($$v) {
                    _vm.$set(_vm.value, item.model, $$v)
                  },
                  expression: "value[item.model]",
                },
              })
            : item.type === "editor"
            ? _c(
                "div",
                [
                  _c("el-upload", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "avatar-uploader",
                    attrs: {
                      size: "small",
                      action: _vm.uploadUrl,
                      "show-file-list": false,
                      "on-success": _vm.uploadSuccess,
                      "before-upload": _vm.beforeUpload,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }