<template>
  <div class="wrap">
    <el-form :model="form" :rules="rules" ref="form" label-width="100px">
      <cardTitleCom cardTitle="开屏广告设置" id="search-card">
        <template slot="cardContent">
          <div class="tableContent">
            <el-form-item label="开屏广告">
              <el-switch v-model="form.isUseAd" />
              <div class="annotateGrey">开启进入店铺首页时，出现一段品牌图片/视频，等待加载完成之后进入小程序。</div>
            </el-form-item>
            <el-form-item label="添加素材">
              <el-radio-group v-model="form.adMediaType" @change="changeAdMediaType" :disabled='!form.isUseAd'>
                <el-radio :label="1">图片</el-radio>
                <el-radio :label="2">视频</el-radio>
              </el-radio-group>
              <div>
                <div class="choosedImageItem x-fc" @click="getOpenImg(1)">
                  <i v-if="!form.commonImageResp.imageUrl" class="el-icon-plus" style="font-size: 32px"></i>
                  <img v-else-if="form.adMediaType == 1" class="thumbImage" :src="form.commonImageResp.imageUrl"
                    alt="广告图" />
                  <video v-else-if="form.adMediaType == 2" class="thumbImage" ref="videoElement"
                    :src="form.commonImageResp.imageUrl" />
                  <span
                    class="modifyImage">{{`${form.commonImageResp.imageUrl ? '更换' : '上传'}${form.adMediaType == 1 ? '图片' : '视频'}`}}</span>
                  <i v-if="form.commonImageResp.imageUrl" class="el-icon-circle-close" @click.stop="delImg()"></i>
                </div>
                <div class="annotateGrey" v-if="form.adMediaType == 1">建议尺寸1080x1920，格式jpg/png/gif，单张图片文件大小限制5M以内</div>
                <div class="annotateGrey" v-else>建议尺寸1080x1920，格式mp4，单个视频文件大小限制20M以内</div>
              </div>
            </el-form-item>
            <el-form-item label="最大等待时长" prop="adWaitSecond" label-width="120px">
              <div class="x-f">
                <span style="margin-right:5px">播放</span>
                <el-input-number style="margin-right:5px" size="mini" v-model="form.adWaitSecond"
                  controls-position="right" :min="3" :max="60" :disabled='!form.isUseAd'/>
                <span>秒后自动进入店铺首页</span>
              </div>
              <div class="annotateGrey">自定义等待时长生效，最少需要等待页面加载完成。</div>
            </el-form-item>
            <el-form-item label="跳转">
              <el-radio-group v-model="form.adJumpType" :disabled='!form.isUseAd'>
                <el-radio :label="0">不跳转</el-radio>
                <el-radio :label="1">整体跳转</el-radio>
              </el-radio-group>
              <div v-if="form.adJumpType == 1">
                <el-button type="text"
                  @click="getOpenLink()">{{`${form.commonImageResp.linkPath ? '修改' : '选择'}链接`}}</el-button>
                <div class="x-f">
                  <span style="margin-right:10px">{{`跳转路径：${form.commonImageResp.linkPathName || ''}`}}</span>
                  <span v-if="form.commonImageResp.linkPath" class="el-icon-close operateDel" @click="delLink()"></span>
                </div>
              </div>
            </el-form-item>
            <div style="margin-left:65px">
              <el-button size="mini" type="primary" @click="submitForm()">保存</el-button>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <el-dialog v-dialogDrag title="素材库" :visible.sync="showDialog" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" :imgFileSize='5'/>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
    <selectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
  </div>
</template>

<script>
import { saveOrUpdate, getDetail } from "@/api/O2OMall/config/startScreenAd";
export default {
  name: 'startScreenAd',
  components: {
    cardTitleCom: () => import("@/views/components/cardTitleCom"),
    Material: () => import("@/views/components/material"),
    selectLink: () => import("@/views/components/selectLink"),
  },
  data() {
    return {
      accessUrlList: [], // 图片素材列表
      openLink: false, // 选择链接弹窗
      showDialog: false, // 是否显示图库弹框
      form: { isUseAd: false, adMediaType: 1, adWaitSecond: 5, adJumpType: 0, commonImageResp: {} },
      rules: {
        adWaitSecond: [
          {
            required: true,
            message: "最大等待时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
      }
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    changeAdMediaType() {
      this.form.commonImageResp.imageUrl = undefined
      this.form.commonImageResp.fileName = undefined
      this.form.commonImageResp.fileSize = undefined
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          saveOrUpdate(this.form).then((res) => {
            this.$message.success('保存成功')
            if (res.data) {
              this.form = res.data
            }
          })
        }
      })
    },
    delImg() {
      if (!this.form.isUseAd) return this.$message.error('未开启开屏广告功能，请先打开启动状态')
      this.$set(this.form.commonImageResp, "imageUrl", undefined);
      this.$forceUpdate()
    },
    getOpenLink() {
      if (!this.form.isUseAd) return this.$message.error('未开启开屏广告功能，请先打开启动状态')
      this.openLink = true;
    },
    delLink() {
      if (!this.form.isUseAd) return this.$message.error('未开启开屏广告功能，请先打开启动状态')
      this.$set(this.form.commonImageResp, "linkPath", undefined);
      this.$set(this.form.commonImageResp, "linkPathName", undefined);
      this.$forceUpdate()
    },
    getOpenImg(type = 1) {
      if (!this.form.isUseAd) return this.$message.error('未开启开屏广告功能，请先打开启动状态')
      this.selectImgType = type
      this.showDialog = true
    },
    // 选择图片素材
    getAccessUrlList(value, row) {
      this.accessUrlList = row
    },
    // 选择图片弹框确定
    imgUpdateSubmit() {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      console.log('accessUrlList', this.accessUrlList);
      this.form.commonImageResp.imageUrl = this.accessUrlList[0]?.accessUrl
      this.form.commonImageResp.fileName = this.accessUrlList[0]?.fileName
      this.form.commonImageResp.fileSize = this.accessUrlList[0]?.fileSize
      this.$forceUpdate()
      this.accessUrlList = []
      this.showDialog = false
      if (this.form.adMediaType == 2) {
        this.$nextTick(() => {
          this.resetVideo()
        })
      }
    },
    //上传图片弹窗取消
    imgUpdateCancel() {
      this.accessUrlList = []
      this.showDialog = false
    },
    resetVideo() {
      // 获取video元素
      const videoElement = this.$refs.videoElement;
      // 暂停视频
      videoElement.pause();
      // 加载新的视频源（如果需要）
      videoElement.load();
      // 如果需要，可以在这里设置currentTime为0来重置播放位置
      videoElement.currentTime = 0;
    },
    // 选择链接
    selectLinkObj(value) {
      this.form.commonImageResp.linkPath = value.url
      this.form.commonImageResp.linkPathName = value.name
    },
    async getDetail() {
      const res = await getDetail()
      if (res?.data) {
        this.form = res.data
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  min-height: calc(100vh - 84px);
  .tableContent {
    margin: 6px 10px 10px 10px;
    .choosedImageItem {
      position: relative;
      width: 80px;
      height: 80px;
      border: 1px solid #e5e5e5;
      text-align: center;
      margin-right: 10px;
      .thumbImage {
        min-height: 80px;
        box-sizing: border-box;
        vertical-align: bottom;
        max-height: 100%;
        max-width: 100%;
        height: auto;
        width: auto;
      }
      .modifyImage {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }
      .el-icon-circle-close {
        position: absolute;
        top: 0;
        right: 0;
        color: rgba(0, 0, 0, 0.8);
        cursor: pointer;
        font-size: 22px;
      }
    }
  }
}
</style>
