<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  getBillList,
  getBillSumList,
  updateBillStatus,
  getDetailBill,
  billCopy,
} from "@/api/shop/bill/stockInBack.js";
import { updateReconStatus } from "@/api/purchase/index";
import { listSupplier } from "@/api/goods/supplier"; //供应商
import { allEmployeeList } from "@/api/system/employee"; //员工
import { allStoreList } from "@/api/system/store"; //仓库
export default {
  components: { TablePage },
  dicts: ["bill_status"],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == "/index") {
        vm.options.defaultBody = { billStatus: to.query.billStatus };
        vm.options.search[4].model = to.query.billStatus;
        vm.options.search[1].defaultValue = "";
        let cloneData = cloneDeep(vm.options);
        vm.options = null;
        vm.options = cloneData;
      }
    });
  },
  data() {
    return {
      options: {
        uuid: "d699be01-acf3-5628-48db-5421ef8782eb",
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        exportOption: {
          show: true,
          exportApi: "shopPurchaseExportList",
          exportName: "门店采购列表",
          timeout: 60 * 5 * 1000,
        },
        getListApi: getBillList,
        getSummaryApi: getBillSumList,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "billId",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            label: "单据编号",
            seniorSearch: true,
            type: "input",
            model: "",
            filter: "billNo",
          },
          {
            label: "供应商",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "partnerId",
            option: {
              remote: listSupplier,
              dataKey: "rows",
              label: "partnerName",
              value: "partnerId",
              filterable: true,
            },
          },
          {
            label: "单据状态",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "billStatus",
            option: {
              remote: this.getDicts,
              remoteBody: "bill_status",
              dataKey: "data",
              label: "dictLabel",
              value: "dictValue",
              filterable: true,
            },
          },
          {
            label: "仓库",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "storeId",
            option: {
              remote: allStoreList,
              dataKey: "data",
              label: "storeName",
              value: "storeId",
              filterable: true,
            },
          },
          {
            label: "采购员",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "purEmployeeId",
            option: {
              remote: allEmployeeList,
              dataKey: "data",
              label: "employeeName",
              value: "employeeId",
              filterable: true,
            },
          },
          {
            label: "创建人",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "createEmployeeId",
            option: {
              remote: allEmployeeList,
              dataKey: "data",
              label: "employeeName",
              value: "employeeId",
              filterable: true,
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            btnType: "dropdown",
            alertText: "确认要审核选中的数据项吗？",
            other: [
              {
                click: "redoAudit",
                label: "反审核",
                alertText: "确认要反审核当前选中的数据项吗？",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "recon",
            label: "对账",
            type: "success",
            btnType: "dropdown",
            other: [
              {
                click: "redoRecon",
                label: "反对账",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "stop",
            label: "终止",
            icon: "el-icon-remove-outline",
            type: "primary",
            alertText: "确认要终止选中的数据项吗？",
            disabled: () => !this.options?.check?.length,
          },
          {
            label: "打印",
            type: "primary",
            btnType: "dropdown",
            print: {},
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            alertText: "确认要删除选中的数据项吗？",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 120,
          },
          {
            prop: "billStatus",
            label: "单据状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "billQty",
            label: "单据数量",
            minWidth: 100,
          },
          {
            prop: "billTaxMoney",
            label: "单据金额(元)",
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "供应商",
            minWidth: 150,
          },
          {
            prop: "storeName",
            label: "仓库",
            minWidth: 150,
          },
          {
            prop: "handBillNo",
            label: "手工单据号",
            minWidth: 150,
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 150,
          },
          {
            prop: "purEmployeeName",
            label: "采购员",
            minWidth: 150,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 155,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 155,
          },
          {
            prop: "checkAccountStatus",
            label: "对账状态",
            minWidth: 120,
            formatter: e => (e == '0' ? '未对账' : '已对账')
          },
          {
            prop: "checkAccountBy",
            label: "对账人",
            minWidth: 120,
          },
          {
            prop: "checkAccountTime",
            label: "对账时间",
            minWidth: 155,
          },
          {
            prop: "settleStatusName",
            label: "退款状态",
            minWidth: 120,
          },
          {
            prop: "settleMoney",
            label: "已退款金额",
            minWidth: 120,
          },
          {
            prop: "notSettleMoney",
            label: "未退款金额",
            minWidth: 120,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 155,
          },
        ],
        summary: ["billTaxMoney"],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: "StockInBackDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push({
              name: "StockInBackDetail",
              query: {
                type: "Add",
              },
            });
          }
          break;
        case "copy":
          try {
            const billIds = this.options.check?.map((i) => i.billId);
            await billCopy({
              billIds,
            });
            this.options.check = [];
            this.$message.success("操作成功");
            this.$refs.tablePage.getList();
          } catch (err) { }
          break;
        case "audit":
        case "redoAudit":
        case "del":
        case "stop":
          try {
            const billIds = this.options.check?.map((i) => i.billId);
            const status = {
              audit: "2",
              redoAudit: "0",
              del: "3",
              stop: "4",
            };
            await updateBillStatus({
              billIds,
              billStatus: status[type],
              billType: 140318,
            });
            this.options.check = [];
            this.$message.success("操作成功");
            this.$refs.tablePage.getList();
          } catch (err) { }
          break;
        case "recon":
          {
            this.handleBillStatus("对账", this.options.check, '/api/system/shop/bill/purchase/return/modifyCheckAccountStatus', 140318);
          }
          break;
        case "redoRecon":
          {
            this.handleBillStatus("反对账", this.options.check, '/api/system/shop/bill/purchase/return/modifyCheckAccountStatus', 140318);
          }
          break;
        default:
          break;
      }
    },
    handleBillStatus(command, selectData, url, BillType, type) {
      let name = ""; //要执行的状态名字
      let checkAccountStatus = "";
      if (command === "对账") {
        name = "对账";
        checkAccountStatus = "1";
      } else if (command === "反对账") {
        name = "反对账";
        checkAccountStatus = "0";
      }
      const billId = selectData.map((i) => i.billId);
      const billNo = selectData.map((i) => i.billNo);
      const $this = this;
      this.$modal
        .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
        .then(function () {
          updateReconStatus({ billIds: [...billId], checkAccountStatus: checkAccountStatus, url: url }).then(() => {
            $this.$nextTick(() => {
              $this.options.check = [];
            });
            $this.$modal.msgSuccess(`${name}成功`);
            setTimeout(() => {
              $this.$refs.tablePage.getList();
            }, 500);
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
