var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        paddingTop:
          (_vm.data.style.pagePaddingTop
            ? _vm.data.style.pagePaddingTop
            : "0") + "px",
        paddingRight:
          (_vm.data.style.pagePaddingRight
            ? _vm.data.style.pagePaddingRight
            : "0") + "px",
        paddingBottom:
          (_vm.data.style.pagePaddingBottom
            ? _vm.data.style.pagePaddingBottom
            : "0") + "px",
        paddingLeft:
          (_vm.data.style.pagePaddingLeft
            ? _vm.data.style.pagePaddingLeft
            : "0") + "px",
      },
    },
    [
      _c("video", {
        ref: "videoElement",
        style: {
          borderRadius: _vm.data.style.chamfer == "circle" ? "10px" : "",
        },
        attrs: {
          muted: "",
          loop: _vm.data.style.loop == undefined ? true : _vm.data.style.loop,
          src: _vm.data.style.videoUrl,
          width: "100%",
          autoplay: _vm.data.style.voluntarilyPlay,
          poster:
            _vm.data.style.videoSource != "none" ? _vm.data.style.coverImg : "",
          preload: "",
          controls: false,
        },
        domProps: { muted: true },
        on: { loadedmetadata: _vm.loadedmetadata },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }