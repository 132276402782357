<!-- 手动核销 -->
<template>
    <div class="wrap">
        <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { listManualSettleBills } from "@/api/arap/index";
import { updateArbillStatus, updateReconStatus } from "@/api/arap/index";

export default {
    components: { TablePage },
    dicts: ["bill_status"],
    data() {
        return {
            options: {
                uuid:'2b6023cf-f58f-e87f-ed24-514074f5b002',
                mutiSelect: true,
                loading: true,
                pagination: {
                    total: 0,
                    page: 1,
                    size: 10,
                },
                getListApi: listManualSettleBills,
                body: {},
                listNo: true,
                title: "单据信息",
                check: [],
                rowKey: "billId",
                search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
                    {
                        type: "filters",
                        tip: "全部/单据编号/单据备注/收款账号/收款方式",
                        isRestore: true,
                        model: "",
                        filters: [
                            { filter: "query", label: "全部" },
                            { filter: "billNos", label: "单据编号" },
                            { filter: "billRemarks", label: "单据备注" },
                            { filter: "customerNames", label: "客户名称" },
                            { filter: "supplierNames", label: "供应商名称" },
                        ],
                    },
                    this.$select({ key: "handSupplierId", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "handCustomerId", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "handBillSubType", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listSimpleQuery", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listSaleEmployee", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listCheckAccountEmployee", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listPurCreateEmployee", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listPurAuditEmployee", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listUpdateEmployee", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({
                        key: "dataBillStatus", option: {
                            seniorSearch: true, label: '审核状态', option: {
                                dataKey: (e) => {
                                    let data = e.data.filter(item => ['未审核', '已审核'].includes(item.dictLabel))
                                    return data
                                }, multiple: true
                            }
                        }
                    }),
                    { type: "button", tip: "查询", btnType: "primary", click: "search" },
                    {
                        type: "button",
                        tip: "高级查询",
                        btnType: "primary",
                        click: "seniorSearch",
                    },
                    { type: "button", tip: "重置", click: "reset" },
                ],
                buttons: [
                    {
                        click: "add",
                        label: "新增",
                        icon: "el-icon-plus",
                        type: "primary",
                        disabled: false,
                    },
                    {
                        click: "audit",
                        label: "审核",
                        type: "success",
                        btnType: "dropdown",
                        other: [
                        {
                            click: "redoAudit",
                            label: "反审核",
                        },
                        ],
                        disabled: () => !this.options?.check?.length,
                    },
                    // {
                    //   click: "stop",
                    //   label: "终止",
                    //   icon: "el-icon-remove-outline",
                    //   type: "primary",
                    //   disabled: () => !this.options?.check?.length,
                    // },
                    {
                        click: "recon",
                        label: "对账",
                        type: "success",
                        btnType: "dropdown",
                        other: [
                        {
                            click: "redoRecon",
                            label: "反对账",
                        },
                        ],
                        disabled: () => !this.options?.check?.length,
                    },
                    {
                        label: "打印",
                        type: "primary",
                        btnType: "dropdown",
                        print: {},
                    },
                    {
                        click: "del",
                        label: "删除",
                        type: "danger",
                        icon: "el-icon-delete",
                        disabled: () => !this.options?.check?.length,
                    },
                    {
                        click: "refresh",
                        right: true,
                        label: "刷新",
                        icon: "el-icon-refresh",
                        type: "",
                    },
                ],
                columns: [
                    {
                        prop: "billNo",
                        label: "单据编号",
                        type: "link",
                        click: "routerLink",
                        minWidth: 180,
                    },
                    {
                        prop: "billDate",
                        label: "单据日期",
                        minWidth: 155,
                    },
                    {
                        prop: "billStatus",
                        label: "审核状态",
                        type: "dict",
                        minWidth: 120,
                        dict: "bill_status",
                    },
                    {
                        prop: "billSubType",
                        label: "核销类型",
                        minWidth: 120,
                        formatter: e => (e == '1' ? '预收冲应收' : '预付冲应付')
                    },
                    {
                        prop: "areaName",
                        label: "客户编号",
                        formatter: (v, row) => row.billSubType ==1? row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : '' : '',
                        minWidth: 120,
                    },
                    {
                        prop: "partnerNo",
                        label: "客户名称",
                        formatter: (v, row) => row.billSubType ==1? row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : '' : '',
                        minWidth: 120,
                    },
                    {
                        prop: "partnerNo",
                        label: "供应商编号",
                        formatter: (v, row) =>  row.billSubType ==2? row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : '' : '',
                        minWidth: 120,
                    },
                    {
                        prop: "partnerName",
                        label: "供应商名称",
                        formatter: (v, row) =>  row.billSubType ==2? row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : '' : '',
                        minWidth: 120,
                    },
                    {
                        prop: "actualRpMoney",
                        label: "本次核销总金额",
                        minWidth: 160,
                    },
                    {
                        prop: "discountMoney",
                        label: "优惠金额",
                        minWidth: 140,
                    },
                    {
                        prop: "billRemark",
                        label: "单据备注",
                        minWidth: 140,
                    },
                    {
                        prop: "employeeName",
                        label: "业务员",
                        formatter: (v, row) => row.saleEmployeeInfo?.employeeName ? row.saleEmployeeInfo?.employeeName : '',
                        minWidth: 160,
                    },
                    {
                        prop: "deptName",
                        label: "部门",
                        minWidth: 160,
                        formatter: (v, row) => row.deptInfo?.deptName ? row.deptInfo?.deptName : '',
                    },
                    {
                        prop: "checkAccountStatus",
                        label: "对账状态",
                        minWidth: 120,
                        formatter: e => (e == '0' ? '未对账' : '已对账')
                    },
                    {
                        prop: "checkAccountBy",
                        label: "对账人",
                        minWidth: 160,
                    },
                    {
                        prop: "checkAccountTime",
                        label: "对账时间",
                        minWidth: 160,
                    },
                    {
                        prop: "createBy",
                        label: "创建人",
                        minWidth: 160,
                    },
                    {
                        prop: "createTime",
                        label: "创建时间",
                        minWidth: 160,
                    },
                    {
                        prop: "auditBy",
                        label: "审核人",
                        minWidth: 160,
                    },
                    {
                        prop: "auditTime",
                        label: "审核时间",
                        minWidth: 160,
                    },
                    {
                        prop: "updateBy",
                        label: "修改人",
                        minWidth: 160,
                    },
                    {
                        prop: "updateTime",
                        label: "修改时间",
                        minWidth: 160,
                    },
                ],
                list: [],
            },
        };
    },
    methods: {
        async handleEvent(type, row) {
            const selectData = this.options.check;
            let url = "/api/system/finance/manual/settle/bill/modifyBillStatus";
            let billType = "190301";
            switch (type) {
                case "routerLink":
                    {
                        const billId = row.billId;
                        this.$router.push({
                            name: "handWriteOffDetail",
                            query: {
                                billId: billId,
                                type: "Update",
                            },
                        });
                    }
                    break;
                case "add":
                    {
                        this.$router.push({
                            name: "handWriteOffDetail",
                            query: {
                                type: "Add",
                            },
                        });
                    }
                    break;
                case "audit":
                    {
                        if (!selectData.length) return;
                        this.handleBillStatus("审核", selectData, url, billType);
                    }
                    break;
                case "redoAudit":
                    {
                        if (!selectData.length) return;
                        this.handleBillStatus("反审核", selectData, url, billType);
                    }
                    break;
                case "del":
                    {
                        if (!selectData.length) return;
                        this.handleBillStatus("删除", selectData, url, billType);
                    }
                    break;
                case "stop":
                    {
                        if (!selectData.length) return;
                        this.handleBillStatus("关闭", selectData, url, billType);
                    }
                    break;
                case "recon":
                    {
                        if (!selectData.length) return;
                        this.handleBillStatus("对账", selectData, '/api/system/finance/receive/bill/modifyCheckAccountStatus', billType);
                    }
                    break;
                case "redoRecon":
                    {
                        if (!selectData.length) return;
                        this.handleBillStatus("反对账", selectData, '/api/system/finance/receive/bill/modifyCheckAccountStatus', billType);
                    }
                    break;
                default:
                    break;
            }
        },
        async handleBillStatus(command, selectData, url, BillType) {
            let name = "";
            let billStatus = "";
            let checkAccountStatus = "";
            if (command === "反审核") {
                name = "反审核";
                billStatus = "0";
            } else {
                if (command === "审核") {
                name = "审核";
                billStatus = "2";
                } else if (command === "删除") {
                name = "删除";
                billStatus = "3";
                } else if (command === "关闭") {
                name = "关闭";
                billStatus = "4";
                } else if (command === "对账") {
                name = "对账";
                checkAccountStatus = "1";
                } else if (command === "反对账") {
                name = "反对账";
                checkAccountStatus = "0";
                }
            }
            const billId = selectData.map((i) => i.billId);
            const $this = this;
            let operate = await this.$modal.confirm(`是否确认${name}`)
            let params = ['对账', '反对账'].includes(name)? {  billIds: [...billId], checkAccountStatus: checkAccountStatus, url: url } : {  billIds: [...billId], billStatus: billStatus, url: url, BillType: BillType }
            let requestUrl = ['对账', '反对账'].includes(name)? updateReconStatus : updateArbillStatus
            requestUrl(params).then(() => {
                $this.$nextTick(() => { $this.options.check = [] });
                $this.$modal.msgSuccess(`${name}成功`);
                setTimeout(() => {
                $this.$refs.tablePage.getList();
                }, 500);
            });
        },
    },
};
</script>
<style lang=