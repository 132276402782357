var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { model: _vm.data, rules: _vm.rules } },
        [
          _c("el-form-item", [
            _c(
              "div",
              { staticClass: "imageNavText" },
              [
                _vm._l(_vm.data.immageNavList, function (item, index) {
                  return _c(
                    "el-card",
                    { key: index, staticClass: "product-item marB10" },
                    [
                      _c("div", { staticClass: "x-bc" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.data.textFormworkNav == "imageFormwork",
                                expression:
                                  "data.textFormworkNav == 'imageFormwork'",
                              },
                            ],
                            staticClass: "imgBox marR10",
                            on: {
                              click: function ($event) {
                                return _vm.getImgLibrary(index)
                              },
                            },
                          },
                          [
                            !item.imgUrl
                              ? _c("div", { staticClass: "y-c" }, [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c("span", [_vm._v("添加图片")]),
                                ])
                              : _c("el-image", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: { src: item.imgUrl, alt: "#" },
                                }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "imgBoxTitle" }, [
                          _c(
                            "div",
                            { staticClass: "marB15 x-x" },
                            [
                              _c("span", { staticClass: "marR10" }, [
                                _vm._v("标题"),
                              ]),
                              _c("el-input", {
                                attrs: {
                                  maxlength: "4",
                                  placeholder: "导航标题最多四个字",
                                },
                                model: {
                                  value: item.title,
                                  callback: function ($$v) {
                                    _vm.$set(item, "title", $$v)
                                  },
                                  expression: "item.title",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "x-x" },
                            [
                              _c("span", { staticClass: "marR10" }, [
                                _vm._v("链接"),
                              ]),
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  size: "mini",
                                  placeholder:
                                    item.path == "" ? "选择链接" : "修改",
                                  readonly: "",
                                },
                                on: {
                                  focus: function ($event) {
                                    return _vm.handleChange(index)
                                  },
                                },
                                model: {
                                  value: item.link,
                                  callback: function ($$v) {
                                    _vm.$set(item, "link", $$v)
                                  },
                                  expression: "item.link",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "product-actions" }, [
                        _c("i", {
                          staticClass: "el-icon-error",
                          on: {
                            click: function ($event) {
                              return _vm.deleteProduct(index)
                            },
                          },
                        }),
                      ]),
                    ]
                  )
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.data.immageNavList.length < 10,
                        expression: "data.immageNavList.length < 10",
                      },
                    ],
                    staticClass: "addImageNav",
                    on: { click: _vm.getAddImageNav },
                  },
                  [
                    _c("i", { staticClass: "el-icon-plus marL10" }),
                    _c("span", { staticClass: "addImageNavText" }, [
                      _vm._v("添加图文导航"),
                    ]),
                  ]
                ),
              ],
              2
            ),
          ]),
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("选择模板"),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.data.textFormworkNav == "imageFormwork"
                        ? "图片导航"
                        : "文字导航"
                    )
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.data.textFormworkNav,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "textFormworkNav", $$v)
                        },
                        expression: "data.textFormworkNav",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "imageFormwork" } }, [
                        _vm._v("图片导航"),
                      ]),
                      _c("el-radio", { attrs: { label: "textFormwork" } }, [
                        _vm._v("文字导航"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("图片样式"),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.data.styleImage == "fixed" ? "固定" : "横向滚动")
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c("iconpark-icon", {
                    staticClass: "iconImageStyle",
                    class: { iconImageSty: _vm.isIconImageSty },
                    attrs: { name: "pin", size: "16px" },
                    on: {
                      click: function ($event) {
                        return _vm.getIconImageSty("fixed")
                      },
                    },
                  }),
                  _c("iconpark-icon", {
                    staticClass: "iconImageStyle",
                    class: { iconImageSty: !_vm.isIconImageSty },
                    attrs: { name: "sliding-horizontal", size: "16px" },
                    on: {
                      click: function ($event) {
                        return _vm.getIconImageSty("roll")
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "图片大小" } },
            [
              _c("el-input", {
                staticStyle: { width: "180px", "margin-right": "5px" },
                attrs: { size: "mini", placeholder: "默认宽高都是38px" },
                model: {
                  value: _vm.data.style.fontSize,
                  callback: function ($$v) {
                    _vm.$set(_vm.data.style, "fontSize", $$v)
                  },
                  expression: "data.style.fontSize",
                },
              }),
              _vm._v(" px "),
            ],
            1
          ),
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("文字颜色"),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.data.style.textColor))]),
              ]),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.data.style.textColor = "#000000"
                        },
                      },
                    },
                    [_vm._v("重置 ")]
                  ),
                  _c("el-color-picker", {
                    on: { change: _vm.selectbgColorChange },
                    model: {
                      value: _vm.data.style.textColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "textColor", $$v)
                      },
                      expression: "data.style.textColor",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("el-form-item", [
            _c("div", { staticClass: "x-bc" }, [
              _c("div", { staticClass: "x-f" }, [
                _c("span", { staticClass: "marR20 label" }, [
                  _vm._v("背景颜色"),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.data.style.bgColor))]),
              ]),
              _c(
                "div",
                { staticClass: "x-f btnListBox" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.data.style.bgColor = "#ffffff"
                        },
                      },
                    },
                    [_vm._v("重置 ")]
                  ),
                  _c("el-color-picker", {
                    on: { change: _vm.selecttextColorChange },
                    model: {
                      value: _vm.data.style.bgColor,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "bgColor", $$v)
                      },
                      expression: "data.style.bgColor",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "上边距", "label-width": "" } },
            [
              _c(
                "div",
                { staticClass: "x-f" },
                [
                  _c("el-slider", {
                    staticStyle: { width: "300px" },
                    attrs: { max: 30, "show-input": "" },
                    on: {
                      change: function ($event) {
                        return _vm.selectpagebackGauge(
                          _vm.data.style.pagePaddingTop,
                          "top"
                        )
                      },
                    },
                    model: {
                      value: _vm.data.style.pagePaddingTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "pagePaddingTop", $$v)
                      },
                      expression: "data.style.pagePaddingTop",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下边距", "label-width": "" } },
            [
              _c(
                "div",
                { staticClass: "x-f" },
                [
                  _c("el-slider", {
                    staticStyle: { width: "300px" },
                    attrs: { max: 30, "show-input": "" },
                    on: {
                      change: function ($event) {
                        return _vm.selectpagebackGauge(
                          _vm.data.style.pagePaddingBottom,
                          "bottom"
                        )
                      },
                    },
                    model: {
                      value: _vm.data.style.pagePaddingBottom,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "pagePaddingBottom", $$v)
                      },
                      expression: "data.style.pagePaddingBottom",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "左边距", "label-width": "" } },
            [
              _c(
                "div",
                { staticClass: "x-f" },
                [
                  _c("el-slider", {
                    staticStyle: { width: "300px" },
                    attrs: { max: 30, "show-input": "" },
                    on: {
                      change: function ($event) {
                        return _vm.selectpagebackGauge(
                          _vm.data.style.pagePaddingLeft,
                          "left"
                        )
                      },
                    },
                    model: {
                      value: _vm.data.style.pagePaddingLeft,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "pagePaddingLeft", $$v)
                      },
                      expression: "data.style.pagePaddingLeft",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "右边距", "label-width": "" } },
            [
              _c(
                "div",
                { staticClass: "x-f" },
                [
                  _c("el-slider", {
                    staticStyle: { width: "300px" },
                    attrs: { max: 30, "show-input": "" },
                    on: {
                      change: function ($event) {
                        return _vm.selectpagebackGauge(
                          _vm.data.style.pagePaddingRight,
                          "right"
                        )
                      },
                    },
                    model: {
                      value: _vm.data.style.pagePaddingRight,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "pagePaddingRight", $$v)
                      },
                      expression: "data.style.pagePaddingRight",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片圆角", "label-width": "" } },
            [
              _c(
                "div",
                { staticClass: "x-f" },
                [
                  _c("el-slider", {
                    staticStyle: { width: "300px" },
                    attrs: { max: 30, "show-input": "" },
                    on: { change: _vm.selectImgRadius },
                    model: {
                      value: _vm.data.style.pageImgRadius,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "pageImgRadius", $$v)
                      },
                      expression: "data.style.pageImgRadius",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.materialOpen,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.materialOpen = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("selectLink", {
        attrs: { OpenLink: _vm.openLink },
        on: {
          "update:OpenLink": function ($event) {
            _vm.openLink = $event
          },
          "update:open-link": function ($event) {
            _vm.openLink = $event
          },
          selectLinkObj: _vm.selectLinkObj,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }