<template>
  <div class="dialog-content">
    <div class="content">
      <Table
        ref="table"
        reserve-selection
        :muti-select="false"
        :list="tableList || []"
        :loading="loading"
        row-key="vipId"
        :border="true"
        height="500"
      >
        <template v-slot:table-column v-if="columns">
          <el-table-column
            width="50"
            label="序号"
            align="center"
            header-align="center"
            :resizable="false"
          >
            <template slot-scope="{ $index }">
              <span>{{ $index + 1 }}</span>
            </template>
          </el-table-column>
          <TableColumns v-for="(item, i) in columns" :key="item.prop + i" :column="item">
            <slot v-if="item.type === 'slots'" :name="item.prop" :slot="item.prop" />
            <template :slot="'slot-' + item.prop" slot-scope="scope">
              <slot :name="item.prop" v-bind="scope" />
            </template>
          </TableColumns>
        </template>
      </Table>
      <TablePagination
        v-if="pagination"
        :page.sync="pagination.pageNum"
        :limit.sync="pagination.pageSize"
        :total="pagination.total"
        @pagination="(obj) => handleEvent('pagination', obj)"
      />
    </div>
  </div>
</template>

<script>
import TablePagination from "@/components/tablePage/tablePagination";
import { listEmitCard } from "@/api/vip/base/emitCard.js";
export default {
  name: "CardIssuingRecord",
  components: {
    Table: () => import("@/components/tablePage/table"),
    TableColumns: () => import("@/components/tablePage/tableColumns"),
    TablePagination,
  },
  props: {
    dialog: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      tableList: [],
      pagination: {
        pageNum: 1,
        pageSize: 15,
        total: 0,
      },
      columns: [
        {
          prop: "vipNo",
          label: "会员卡号",
          minWidth: 150,
          align: "center",
        },
        {
          prop: "icPhysicalNo",
          label: "实体卡号",
          minWidth: 150,
          align: "center",
          formartter: (v, row) => row.icPhysicalNo || row.idPhysicalNo,
        },
        {
          prop: "createTime",
          label: "发放时间",
          minWidth: 150,
          align: "center",
        },
        {
          prop: "createBy",
          label: "发放人员",
          minWidth: 150,
          align: "center",
        },
        {
          prop: "isCardEmited",
          label: "发放",
          align: "center",
          formatter: (propValue) => {
            return propValue == true ? "是" : "否";
          },
        },
      ],
    };
  },
  watch: {
    "dialog.show": {
      handler(val) {
        if (val) this.getList();
      },
      immediate: true,
    },
  },
  methods: {
    async getList() {
      this.loading = true;
      try {
        const res = await listEmitCard(this.pagination);
        this.tableList = res.rows;
        this.pagination.total = res.total;
      } catch (err) {}
      this.loading = false;
    },
    async handleEvent(type, row) {
      switch (type) {
        case "pagination": // 搜索
          // this.pagination.pageNum = 1; // 页码重置
          this.getList();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
