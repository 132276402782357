<!-- 预付款 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { listPrePaymentBill } from "@/api/arap/index";
import { updateArbillStatus, updateReconStatus } from "@/api/arap/index";
import { fcount } from '@/utils'
export default {
    components: { TablePage },
    dicts: ["bill_status"],
    data() {
        return {
            options: {
                mutiSelect: true,
                loading: true,
                pagination: {
                    total: 0,
                    page: 1,
                    size: 10,
                },
                getListApi: listPrePaymentBill,
                body: {},
                listNo: true,
                title: "单据信息",
                check: [],
                rowKey: "billId",
                search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
                    {
                        type: "filters",
                        tip: "全部/单据编号/单据备注/收款账号/收款方式",
                        isRestore: true,
                        model: "",
                        filters: [
                            { filter: "query", label: "全部" },
                            { filter: "billNos", label: "单据编号" },
                            { filter: "billRemarks", label: "单据备注" },
                            { filter: "rpAccountNames", label: "付款账号" },
                            { filter: "rpModeNames", label: "付款方式" },
                        ],
                    },
                    this.$select({ key: "listSupplier", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listSimpleQuery", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listSaleEmployee", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listCheckAccountEmployee", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listPurCreateEmployee", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listPurAuditEmployee", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({ key: "listUpdateEmployee", option: { seniorSearch: true, option: { multiple: true } } }),
                    this.$select({
                        key: "dataBillStatus", option: {
                            seniorSearch: true, label: '审核状态', option: {
                                dataKey: (e) => {
                                    let data = e.data.filter(item => ['未审核', '已审核'].includes(item.dictLabel))
                                    return data
                                }, multiple: true
                            }
                        }
                    }),
                    this.$select({ key: "includeSettleStatu", option: { seniorSearch: true, labelWidth: '150px', option: { multiple: true } } }),
                    { type: "button", tip: "查询", btnType: "primary", click: "search" },
                    {
                        type: "button",
                        tip: "高级查询",
                        btnType: "primary",
                        click: "seniorSearch",
                    },
                    { type: "button", tip: "重置", click: "reset" },
                ],
                buttons: [
                    {
                        click: "add",
                        label: "新增",
                        icon: "el-icon-plus",
                        type: "primary",
                        disabled: false,
                    },
                    {
                        click: "audit",
                        label: "审核",
                        type: "success",
                        btnType: "dropdown",
                        other: [
                            {
                                click: "redoAudit",
                                label: "反审核",
                            },
                        ],
                        disabled: () => !this.options?.check?.length,
                    },
                    // {
                    //     click: "stop",
                    //     label: "终止",
                    //     icon: "el-icon-remove-outline",
                    //     type: "primary",
                    //     disabled: () => !this.options?.check?.length,
                    // },
                    {
                        click: "recon",
                        label: "对账",
                        type: "success",
                        btnType: "dropdown",
                        other: [
                        {
                            click: "redoRecon",
                            label: "反对账",
                        },
                        ],
                        disabled: () => !this.options?.check?.length,
                    },
                    // {
                    //     label: "打印",
                    //     type: "primary",
                    //     btnType: "dropdown",
                    //     print: {},
                    // },
                    {
                        click: "del",
                        label: "删除",
                        type: "danger",
                        icon: "el-icon-delete",
                        disabled: () => !this.options?.check?.length,
                    },
                    {
                        click: "refresh",
                        right: true,
                        label: "刷新",
                        icon: "el-icon-refresh",
                        type: "",
                    },
                ],
                columns: [
                    {
                        prop: "billNo",
                        label: "单据编号",
                        type: "link",
                        click: "routerLink",
                        minWidth: 180,
                    },
                    {
                        prop: "billDate",
                        label: "单据日期",
                        minWidth: 155,
                    },
                    {
                        prop: "billStatus",
                        label: "审核状态",
                        type: "dict",
                        minWidth: 120,
                        dict: "bill_status",
                    },
                    {
                        prop: "areaName",
                        label: "客供区域",
                        formatter: (v, row) => row.partnerInfo?.areaName ? row.partnerInfo?.areaName : '',
                        minWidth: 120,
                    },
                    {
                        prop: "groupName",
                        label: "客供分组",
                        formatter: (v, row) => row.partnerInfo?.groupName ? row.partnerInfo?.groupName : '',
                        minWidth: 120,
                    },
                    {
                        prop: "partnerNo",
                        label: "供应商编号",
                        formatter: (v, row) => row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : '',
                        minWidth: 120,
                    },
                    {
                        prop: "partnerName",
                        label: "供应商名称",
                        formatter: (v, row) => row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : '',
                        minWidth: 120,
                    },
                    {
                        prop: "actualRpMoney",
                        label: "付款金额合计",
                        minWidth: 140,
                    },
                    {
                        prop: "discountMoney",
                        label: "优惠金额",
                        minWidth: 140,
                    },
                    {
                        prop: "billWriteOffMoney",
                        label: "预付款总金额",
                        minWidth: 160,
                        formatter: (v, row) => fcount([row.actualRpMoney, row.discountMoney],'+') || 0,
                        // 预付款总金额 = 付款金额合计+优惠金额；
                    },
                    {
                        prop: "actualRpMoney",
                        label: "付款金额",
                        minWidth: 140,
                    },
                    {
                        prop: "settleStatus",
                        label: "单据核销状态",
                        minWidth: 140,
                        formatter: (v) => v == 0 ? '未核销' : v == 1 ? '部分核销' : '已核销',
                    },
                    {
                        prop: "settleMoney",
                        label: "已核销总金额",
                        minWidth: 140,
                    },
                  {
                        prop: "notSettleMoney",
                        label: "未核销总金额",
                        minWidth: 160,
                        // 未核销总金额 = 预付款总金额-已核销总金额
                        formatter: (v, row) => fcount([fcount([row.actualRpMoney, row.discountMoney],'+'), row.settleMoney],'-') || 0,
                    },
                    {
                        prop: "billRemark",
                        label: "单据备注",
                        minWidth: 140,
                    },
                    {
                        prop: "sourceBillNo",
                        label: "关联单据",
                        minWidth: 160,
                    },
                    {
                        prop: "employeeName",
                        label: "业务员",
                        minWidth: 160,
                        formatter: (v, row) => row.saleEmployeeInfo?.employeeName?  row.saleEmployeeInfo?.employeeName: '',
                    },
                    {
                        prop: "deptName",
                        label: "部门",
                        formatter: (v, row) => row.deptInfo?.deptName?  row.deptInfo?.deptName: '',
                    },
                    {
                        prop: "checkAccountStatus",
                        label: "对账状态",
                        minWidth: 160,
                        formatter: e => (e == '0'? '未对账' :  e == '1'? '已对账' : '')
                    },
                    {
                        prop: "checkAccountBy",
                        label: "对账人",
                        minWidth: 160,
                    },
                    {
                        prop: "checkAccountTime",
                        label: "对账时间",
                        minWidth: 160,
                    },
                    {
                        prop: "createBy",
                        label: "创建人",
                        minWidth: 160,
                    },
                    {
                        prop: "createTime",
                        label: "创建时间",
                        minWidth: 160,
                    },
                    {
                        prop: "auditBy",
                        label: "审核人",
                        minWidth: 160,
                    },
                    {
                        prop: "auditTime",
                        label: "审核时间",
                        minWidth: 160,
                    },
                    {
                        prop: "updateBy",
                        label: "修改人",
                        minWidth: 160,
                    },
                    {
                        prop: "updateTime",
                        label: "修改时间",
                        minWidth: 160,
                    },
                ],
                list: [],
            },
        };
    },
    methods: {
        async handleEvent(type, row) {
            const selectData = this.options.check;
            let url = "/api/system/finance/pre/payment/bill/modifyBillStatus";
            let billType = "190203";
            switch (type) {
                case "routerLink":
                    {
                        const billId = row.billId;
                        this.$router.push({
                            name: "apbillPrepareReceiptDetail",
                            query: {
                                billId: billId,
                                type: "Update",
                            },
                        });
                    }
                    break;
                case "sourceLink":
                    {
                        console.log('row', row)
                        // const billId = row.billId;
                        // this.$router.push({
                        //   name: "receiptDetail",
                        //   query: {
                        //     billId: billId,
                        //     type: "Update",
                        //   },
                        // });
                    }
                    break;
                case "add":
                    {
                        this.$router.push({
                            name: "apbillPrepareReceiptDetail",
                            query: {
                                type: "Add",
                            },
                        });
                    }
                    break;
                case "audit":
                    {
                        if (!selectData.length) return;
                        this.handleBillStatus("审核", selectData, url, billType);
                    }
                    break;
                case "redoAudit":
                    {
                        if (!selectData.length) return;
                        this.handleBillStatus("反审核", selectData, url, billType);
                    }
                    break;
                case "del":
                    {
                        if (!selectData.length) return;
                        this.handleBillStatus("删除", selectData, url, billType);
                    }
                    break;
                case "stop":
                    {
                        if (!selectData.length) return;
                        this.handleBillStatus("关闭", selectData, url, billType);
                    }
                    break;
                case "recon":
                    {
                        if (!selectData.length) return;
                        this.handleBillStatus("对账", selectData, '/api/system/finance/pre/payment/bill/modifyCheckAccountStatus', billType);
                    }
                    break;
                case "redoRecon":
                    {
                        if (!selectData.length) return;
                        this.handleBillStatus("反对账", selectData, '/api/system/finance/pre/payment/bill/modifyCheckAccountStatus', billType);
                    }
                    break;
                default:
                    break;
            }
        },
        async handleBillStatus(command, selectData, url, BillType) {
            let name = "";
            let billStatus = "";
            let checkAccountStatus = "";
            if (command === "反审核") {
                name = "反审核";
                billStatus = "0";
            } else {
                if (command === "审核") {
                name = "审核";
                billStatus = "2";
                } else if (command === "删除") {
                name = "删除";
                billStatus = "3";
                } else if (command === "关闭") {
                name = "关闭";
                billStatus = "4";
                } else if (command === "对账") {
                name = "对账";
                checkAccountStatus = "1";
                } else if (command === "反对账") {
                name = "反对账";
                checkAccountStatus = "0";
                }
            }
            const billId = selectData.map((i) => i.billId);
            const $this = this;
            let operate = await this.$modal.confirm(`是否确认${name}`)
            let params = ['对账', '反对账'].includes(name)? {  billIds: [...billId], checkAccountStatus: checkAccountStatus, url: url } : {  billIds: [...billId], billStatus: billStatus, url: url, BillType: BillType }
            let requestUrl = ['对账', '反对账'].includes(name)? updateReconStatus : updateArbillStatus
            requestUrl(params).then(() => {
                $this.$nextTick(() => { $this.options.check = [] });
                $this.$modal.msgSuccess(`${name}成功`);
                setTimeout(() => {
                $this.$refs.tablePage.getList();
                }, 500);
            });
        },
    },
};
</script>
<style lang="scss" scoped></style>
