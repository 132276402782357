var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { "margin-bottom": "20px" } },
      [
        _vm._v(" 魔方密度: "),
        _c(
          "el-select",
          {
            attrs: { size: "mini" },
            on: { change: _vm.onChangeDensity },
            model: {
              value: _vm.density,
              callback: function ($$v) {
                _vm.density = $$v
              },
              expression: "density",
            },
          },
          _vm._l(_vm.densityList, function (item, index) {
            return _c("el-option", { key: index, attrs: { value: item } }, [
              _vm._v(_vm._s(item + " x " + item)),
            ])
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "decorate-cube" },
      [
        _vm._l(_vm.densityNum, function (n, ni) {
          return _c(
            "ul",
            { key: ni, staticClass: "cube-col" },
            _vm._l(_vm.densityNum, function (i, ii) {
              return _c("li", {
                key: ii,
                class: [
                  "cube-item",
                  {
                    "item-selecting": _vm.isSelecting(n, i),
                    "item-selected": _vm.isSelected(n, i),
                  },
                ],
                style: {
                  width: _vm.cubeCellWidth + "px",
                  height: _vm.cubeCellHeight + "px",
                },
                attrs: { "data-x": n, "data-y": i },
                on: {
                  click: function ($event) {
                    return _vm.onClickCubeItem($event)
                  },
                  mouseenter: function ($event) {
                    return _vm.onEnterCubeItem($event)
                  },
                },
              })
            }),
            0
          )
        }),
        _vm._l(_vm.selectedList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "cube-selected",
              class: { active: _vm.active == index },
              style: {
                width: _vm.getSelectedWidth(item) + "px",
                height: _vm.getSelectedHeight(item) + "px",
                top: _vm.getSelectedTop(item) + "px",
                left: _vm.getSelectedLeft(item) + "px",
              },
              on: {
                mouseenter: function ($event) {
                  return _vm.onEnterSelected(index)
                },
                click: function ($event) {
                  return _vm.changeActive(index)
                },
              },
            },
            [
              item.imageUrl
                ? _c("img", {
                    attrs: {
                      src: item.imageUrl,
                      height: _vm.getSelectedHeight(item) - 2 + "px",
                      width: _vm.getSelectedWidth(item) - 2 + "px",
                    },
                  })
                : _c("div", { staticClass: "cube-selected-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          Math.round(
                            (750 / _vm.density) *
                              (parseInt(item.end.x) -
                                parseInt(item.start.x) +
                                1)
                          )
                        ) +
                        " x " +
                        _vm._s(
                          Math.round(
                            (750 / _vm.density) *
                              (parseInt(item.end.y) -
                                parseInt(item.start.y) +
                                1)
                          )
                        ) +
                        " 像素 "
                    ),
                  ]),
              _vm.active == index
                ? _c(
                    "div",
                    {
                      staticClass: "del",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.delSelectItem(index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-error" })]
                  )
                : _vm._e(),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }