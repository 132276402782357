import request from '@/utils/request'

//更新单据状态
export function updateBillStatus (data) {
  return request({
    url:'/api/system/shop/bill/purchase/return/updateBillStatus',
    method: 'post',
    data
  })
}

// 分页列表
export function getBillList (params) {
  return request({
    url:'/api/system/shop/bill/purchase/return/list',
    method: 'get',
    params
  })
}

// 分页列表合计
export function getBillSumList (params) {
    return request({
      url:'/api/system/shop/bill/purchase/return/sumList',
      method: 'get',
      params
    })
  }


  // 复制
export function billCopy (data) {
    return request({
      url:'/api/system/shop/bill/purchase/return/copy',
      method: 'post',
      data
    })
  }

//获取详情
export function getDetailBill (billId) {
  return request({
    url: `/api/system/shop/bill/purchase/return/getDetail?billId=${billId}`
  })
}
