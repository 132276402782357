import request from '@/utils/request'
// 查询生产计划明细列表
export function listDetailsAPI (params) {
  return request({
    url: '/api/system/produce/plan/report/listDetails',
    method: 'get',
    params
  })
}

// 查询生产计划明细列表合计
export function sumListDetailsAPI (params) {
  return request({
    url: '/api/system/produce/plan/report/sumListDetails',
    method: 'get',
    params
  })
}

// 生产计划单按商品查询
export function listGoodsAPI (params) {
  return request({
    url: '/api/system/produce/plan/report/listGoods',
    method: 'get',
    params
  })
}

// 生产计划单按商品查询合计
export function sumListGoodsAPI (params) {
  return request({
    url: '/api/system/produce/plan/report/sumListGoods',
    method: 'get',
    params
  })
}

// 生产计划单按部门查询
export function listShopAPI (params) {
  return request({
    url: '/api/system/produce/plan/report/listShop',
    method: 'get',
    params
  })
}

// 生产计划单按部门查询合计
export function sumListShopAPI (params) {
  return request({
    url: '/api/system/produce/plan/report/sumListShop',
    method: 'get',
    params
  })
}