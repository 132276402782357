var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.column.children ? "vxe-colgroup" : "vxe-column",
    {
      tag: "component",
      attrs: {
        title: _vm.column.label,
        width: _vm.column.width,
        "min-width": _vm.column.minWidth,
        field: _vm.column.prop,
        fixed: _vm.column.fixed || undefined,
        align: _vm.column.align || "center",
        "show-overflow-tooltip": !["html", "slot", "slots", "tag"].includes(
          _vm.column.type
        ),
        "edit-render": _vm.column.type
          ? { autofocus: ".vxe-input--inner", autoselect: true }
          : undefined,
      },
      scopedSlots: _vm._u(
        [
          !_vm.column.children
            ? {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm.column.formatter
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.column.formatter(row[_vm.column.prop], row)
                            )
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.column.showProp
                                ? row[_vm.column.showProp]
                                : row[_vm.column.prop]
                            )
                          ),
                        ]),
                  ]
                },
              }
            : null,
          !_vm.column.children
            ? {
                key: "edit",
                fn: function (ref) {
                  var row = ref.row
                  var rowIndex = ref.rowIndex
                  return [
                    _vm.column.type == "input"
                      ? _c("el-input", {
                          ref:
                            _vm.column.prop +
                            "_" +
                            rowIndex +
                            "_" +
                            _vm.column.columnIndex,
                          attrs: {
                            disabled: _vm.column.disabled,
                            size: "small",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.onInput(
                                _vm.column.input,
                                row,
                                rowIndex,
                                _vm.column.prop,
                                _vm.column.notVerifyNum
                              )
                            },
                          },
                          nativeOn: {
                            keydown: function ($event) {
                              return _vm.handleKeyboardEvents({
                                key: $event.key,
                                prop: _vm.column.prop,
                                rowIndex: rowIndex,
                                columnIndex: _vm.column.columnIndex,
                              })
                            },
                          },
                          model: {
                            value: row[_vm.column.prop],
                            callback: function ($$v) {
                              _vm.$set(row, _vm.column.prop, $$v)
                            },
                            expression: "row[column.prop]",
                          },
                        })
                      : _vm.column.type === "timePicker"
                      ? _c("el-time-picker", {
                          staticClass: "time-picker",
                          attrs: {
                            format: "HH:mm",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            placeholder: "任意时间点",
                            size: "small",
                            disabled: _vm.column.disabled,
                          },
                          model: {
                            value: row[_vm.column.prop],
                            callback: function ($$v) {
                              _vm.$set(row, _vm.column.prop, $$v)
                            },
                            expression: "row[column.prop]",
                          },
                        })
                      : _vm.column.type === "localSelect"
                      ? _c("SelectLocal", {
                          ref:
                            _vm.column.prop +
                            "_" +
                            rowIndex +
                            "_" +
                            _vm.column.columnIndex,
                          attrs: {
                            row: row,
                            option: _vm.column.option,
                            rowIndex: rowIndex,
                            columnIndex: _vm.column.columnIndex,
                            filterable: _vm.column.option.filterable || false,
                            multiple: _vm.column.option.multiple || false,
                            disabled: _vm.column.disabled,
                            clearable: _vm.column.option.clearable || false,
                            "collapse-tags": _vm.column.option["collapse-tags"],
                            placeholder:
                              _vm.column.placeholder ||
                              "选择" + _vm.column.label,
                          },
                          on: {
                            "update:row": function ($event) {
                              row = $event
                            },
                            selectChange: function ($event) {
                              return _vm.selectChange(
                                $event,
                                _vm.column.change,
                                rowIndex,
                                _vm.column.prop
                              )
                            },
                            keydown: function ($event) {
                              return _vm.handleKeyboardEvents({
                                key: $event,
                                prop: _vm.column.prop,
                                rowIndex: rowIndex,
                                columnIndex: _vm.column.columnIndex,
                              })
                            },
                          },
                          model: {
                            value: row[_vm.column.prop],
                            callback: function ($$v) {
                              _vm.$set(row, _vm.column.prop, $$v)
                            },
                            expression: "row[column.prop]",
                          },
                        })
                      : _vm.column.type === "remoteSelect"
                      ? _c("SelectRemote", {
                          ref:
                            _vm.column.prop +
                            "_" +
                            rowIndex +
                            "_" +
                            _vm.column.columnIndex,
                          attrs: {
                            option: Object.assign(_vm.column.option, {
                              curTableIndex: rowIndex,
                              disabled: _vm.column.disabled,
                            }),
                            rowIndex: rowIndex,
                            columnIndex: _vm.column.columnIndex,
                            filterable: _vm.column.option.filterable || false,
                            multiple: _vm.column.option.multiple || false,
                            clearable: _vm.column.option.clearable || false,
                            "collapse-tags": _vm.column.option["collapse-tags"],
                            placeholder:
                              _vm.column.placeholder ||
                              "选择" + _vm.column.label,
                            disabled: _vm.column.disabled,
                          },
                          on: {
                            handleEvent: function (type, option) {
                              _vm.$emit("handleEvent", type, option)
                            },
                            keydown: function ($event) {
                              return _vm.handleKeyboardEvents({
                                key: $event,
                                prop: _vm.column.prop,
                                rowIndex: rowIndex,
                                columnIndex: _vm.column.columnIndex,
                              })
                            },
                          },
                          model: {
                            value: row[_vm.column.prop],
                            callback: function ($$v) {
                              _vm.$set(row, _vm.column.prop, $$v)
                            },
                            expression: "row[column.prop]",
                          },
                        })
                      : [
                          _vm._v(
                            _vm._s(
                              _vm.column.formatter
                                ? _vm.column.formatter(
                                    row[_vm.column.prop],
                                    row,
                                    _vm.columnObj
                                  )
                                : row[_vm.column.prop]
                            )
                          ),
                        ],
                  ]
                },
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm.column.children
        ? _vm._l(
            typeof _vm.column.children === "function"
              ? _vm.column.children()
              : _vm.column.children,
            function (item, i) {
              return _c("vxe-column", {
                key: item.prop + "." + i,
                attrs: {
                  title: item.label,
                  field: item.prop,
                  width: item.width,
                  "min-width": item.minWidth,
                  align: item.align || "center",
                  "edit-render": item.type
                    ? { autofocus: ".vxe-input--inner", autoselect: true }
                    : undefined,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                item.showProp
                                  ? row[item.showProp]
                                  : row[item.prop]
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "edit",
                      fn: function (ref) {
                        var row = ref.row
                        var rowIndex = ref.rowIndex
                        return [
                          item.type == "input"
                            ? _c("vxe-input", {
                                ref:
                                  item.prop +
                                  "_" +
                                  rowIndex +
                                  "_" +
                                  item.columnIndex,
                                refInFor: true,
                                attrs: {
                                  disabled: _vm.column.disabled,
                                  size: "small",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.onInput(
                                      item.input,
                                      row,
                                      rowIndex,
                                      item.prop,
                                      item.notVerifyNum
                                    )
                                  },
                                },
                                nativeOn: {
                                  keydown: function ($event) {
                                    return _vm.handleKeyboardEvents({
                                      key: $event.key,
                                      prop: item.prop,
                                      rowIndex: rowIndex,
                                      columnIndex: item.columnIndex,
                                    })
                                  },
                                },
                                model: {
                                  value: row[item.prop],
                                  callback: function ($$v) {
                                    _vm.$set(row, item.prop, $$v)
                                  },
                                  expression: "row[item.prop]",
                                },
                              })
                            : item.type === "localSelect"
                            ? _c("SelectLocal", {
                                attrs: {
                                  row: row,
                                  option: item.option,
                                  rowIndex: rowIndex,
                                  columnIndex: item.columnIndex,
                                  filterable: item.option.filterable || false,
                                  multiple: item.option.multiple || false,
                                  disabled: item.disabled,
                                  clearable: item.option.clearable || false,
                                  "collapse-tags": item.option["collapse-tags"],
                                  placeholder:
                                    item.placeholder || "选择" + item.label,
                                },
                                on: {
                                  "update:row": function ($event) {
                                    row = $event
                                  },
                                  selectChange: function ($event) {
                                    return _vm.selectChange(
                                      $event,
                                      item.change,
                                      rowIndex,
                                      item.prop
                                    )
                                  },
                                },
                                model: {
                                  value: row[item.prop],
                                  callback: function ($$v) {
                                    _vm.$set(row, item.prop, $$v)
                                  },
                                  expression: "row[item.prop]",
                                },
                              })
                            : item.type === "remoteSelect"
                            ? _c("SelectRemote", {
                                attrs: {
                                  option: Object.assign(item.option, {
                                    curTableIndex: rowIndex,
                                    disabled: item.disabled,
                                  }),
                                  filterable: item.option.filterable || false,
                                  multiple: item.option.multiple || false,
                                  clearable: item.option.clearable || false,
                                  "collapse-tags": item.option["collapse-tags"],
                                  placeholder:
                                    item.placeholder || "选择" + item.label,
                                  disabled: item.disabled,
                                },
                                on: {
                                  handleEvent: function (type, option) {
                                    _vm.$emit("handleEvent", type, option)
                                  },
                                },
                                model: {
                                  value: row[item.prop],
                                  callback: function ($$v) {
                                    _vm.$set(row, item.prop, $$v)
                                  },
                                  expression: "row[item.prop]",
                                },
                              })
                            : [
                                _vm._v(
                                  _vm._s(
                                    item.formatter
                                      ? item.formatter(
                                          row[item.prop],
                                          row,
                                          _vm.columnObj
                                        )
                                      : row[item.prop]
                                  )
                                ),
                              ],
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }
          )
        : _vm._e(),
      _vm.column.type === "slot" ? _vm._t("default") : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogImageVisible, "append-to-body": true },
          on: {
            "update:visible": function ($event) {
              _vm.dialogImageVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticClass: "image",
            attrs: { src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }