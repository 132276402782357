<template>
  <el-form ref="form" :model="form" label-width="120px" :rules="rules" class="wrap">
    <cardTitleCom cardTitle="社群码设置" style="height:100%">
      <template #cardContent>
        <div class="padd15">
          <el-steps :active="active" simple finish-status="success" style="margin-bottom:50px">
            <el-step title="基本设置"></el-step>
            <el-step title="添加二维码"></el-step>
            <el-step :title="`${form.caseDetailId ? '修改' : '创建'}成功`"></el-step>
          </el-steps>
          <div class="y-c">
            <div v-if="active == 0" style="margin-bottom:50px">
              <el-form-item label="活码名称" prop="caseCodeName">
                <el-input v-model="form.caseCodeName" size="mini" maxlength="15" placeholder="将显示在活动页面标题栏"
                  class="inputWidth" />
              </el-form-item>
              <el-form-item label="门店" prop="caseShopId" v-if="caseType == 2">
                <SelectRemote v-model="form.caseShopId" style="width: 250px"
                  :option="$select({ key: 'listShop' }).option" />
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input v-model="form.remark" size="mini" maxlength="15" placeholder="活动备注信息，用户不可见"
                  class="inputWidth" />
              </el-form-item>
              <el-form-item label="联系客服微信号" prop="caseWeixinNo">
                <el-input v-model="form.caseWeixinNo" size="mini" maxlength="15" placeholder="异常⻚⾯，引导⽤户联系微信客服"
                  class="inputWidth" />
              </el-form-item>
            </div>
            <div v-else-if="active == 1" style="margin-bottom:50px;width: 100%">
              <el-button type="primary" size="small" style="margin-bottom:20px"
                @click="addCode">{{ form.caseCodeType == 1 ? '添加个人微信码' : '添加群二维码'}}</el-button>
              <el-table :data="form.detailCodeList" tooltip-effect="dark" border v-loading="loadingTable"
                ref="multipleTable" style="width: 100%;height:calc(100vh - 450px);max-height:calc(100vh - 450px)">
                <el-table-column align="center" type="index" width="80" label="序号" />
                <el-table-column prop="qrCodeName" :label="form.caseCodeType == 1 ? '微信名称' : '群名称'" align="center" />
                <el-table-column prop="imageUrl" :label="form.caseCodeType == 1 ? '微信二维码' : '微信群二维码'" align="center">
                  <template slot-scope="scope">
                    <img v-if="scope.row.commonImageResp" :src="scope.row.commonImageResp.imageUrl"
                      style="width:60px;height:60px" />
                  </template>
                </el-table-column>
                <el-table-column prop="qrCodeSwitchRate" label="每日自动切换频率" align="center"
                  v-if="form.caseCodeType == 1" />
                <el-table-column prop="qrCodeValidDate" label="二维码有效期" align="center" v-if="form.caseCodeType == 2" />
                <el-table-column prop="qrCodeLimitNumber" label="自动切换人数上限" align="center"
                  v-if="form.caseCodeType == 2" />
                <el-table-column label="操作" align="center" prop="controls">
                  <template slot-scope="scope">
                    <el-button type="text" @click="editCode(scope.$index)">编辑</el-button>
                    <el-button type="text" @click="delCode(scope.$index)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-else-if="active == 2" style="margin-bottom:50px;">
              <el-result icon="success"
                :title="`${form.caseCodeType == 1 ? '个人' : '群'}活码${form.caseDetailId ? '修改' : '创建'}成功`" />
            </div>
            <div class="x-f">
              <el-button type="primary" size="small" @click="lastStep" v-if="active === 1">上一步</el-button>
              <el-button type="primary" size="small" @click="nextStep" v-if="active !== 2">下一步</el-button>
              <el-button type="primary" size="small" @click="submitForm" v-if="active === 2">完成</el-button>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <c-dialog :title="title" :width="550" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-form :model="dialogForm" :rules="dialogRules" ref="dialogForm" label-width="120px">
          <div class="x-x" style="margin-left:30px">
            <i style="color: #ff4949;margin-top:10px">* </i>
            <el-form-item label="上传二维码" prop="imageUrl" label-width="88px">
              <div>
                <div class="choosedImageItem x-fc" @click="getOpenImg()">
                  <i v-if="!dialogForm.commonImageResp.imageUrl" class="el-icon-plus" style="font-size: 32px"></i>
                  <img v-else class="thumbImage" :src="dialogForm.commonImageResp.imageUrl" alt="社群图" />
                  <span class="modifyImage"
                    v-if="!disabled">{{`${dialogForm.commonImageResp.imageUrl ? '更换' : '上传'}图片`}}</span>
                  <i v-if="dialogForm.commonImageResp.imageUrl && !disabled" class="el-icon-circle-close"
                    @click.stop="delImg()"></i>
                </div>
                <div class="annotateGrey">仅支持jpg、png两种格式，推荐600*600px尺寸，大小不超过1MB。</div>
              </div>
            </el-form-item>
          </div>
          <el-form-item :label="form.caseCodeType == 1 ? '微信名称' : '群名称'" prop="qrCodeName">
            <el-input v-model="dialogForm.qrCodeName" :placeholder="`请输入${form.caseCodeType == 1 ? '微信' : '群'}名称`"
              maxlength="15" size="mini" class="inputWidth" />
          </el-form-item>
          <el-form-item label="二维码有效期" prop="qrCodeValidDate" v-if="form.caseCodeType == 2">
            <el-date-picker v-model="dialogForm.qrCodeValidDate" type="date" placeholder="选择二维码有效期"
              value-format="yyyy-MM-dd" size="mini" :picker-options="forbiddenTime">
            </el-date-picker>
            <div class="annotateGrey" style="margin-top:10px">
              有限期在群二维码图片底部有小字提示，如7天内群未加满200人，请及时更新二维码，避免过期无法进群。</div>
          </el-form-item>
          <el-form-item label="设置切换频率" prop="qrCodeSwitchRate" v-if="form.caseCodeType == 1">
            <el-input-number v-model="dialogForm.qrCodeSwitchRate" controls-position="right" :min="1" :max="200" />
            <div class="annotateGrey" style="margin-top:10px">
              每日长按识别同一个二维码人数达到上限后，自动切换到下一个二维码，第二天会重新从第一个码开始投放。并且由于微信限制每个微信号每天最多可被500人添加，所以建议你根据现有人数填写200以内的值。</div>
          </el-form-item>
          <el-form-item label="设置人数上限" prop="qrCodeLimitNumber" v-else>
            <el-input-number v-model="dialogForm.qrCodeLimitNumber" controls-position="right" :min="1" :max="200" />
            <div class="annotateGrey" style="margin-top:10px">
              每多少人长按识别了同一个二维码，自动切换到下一个二维码。由于微信群限制200人内可自动进群，所以建议你根据群现有人数填写200以内的值。</div>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitDialogForm">确 定</el-button>
      </template>
    </c-dialog>
    <el-dialog v-dialogDrag title="素材库" :visible.sync="showDialog" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
  </el-form>
</template>

<script>
import { communityCodeSave, communityCodeDetail, communityCodeUpdate } from "@/api/O2OMall/marketing/wxCommunityCode";
import { deepCopy } from '@/utils';
export default {
  name: 'wxCommunityCodeDetail',
  components: {
    cardTitleCom: () => import("@/views/components/cardTitleCom"),
    SelectRemote: () => import("@/components/tablePage/select/select-remote/index.vue"),
    CDialog: () => import("@/components/dialogTemplate/dialog/index.vue"),
    Material: () => import("@/views/components/material/index.vue")
  },
  /**
   * 组件的数据对象
   *
   * @returns {Object} 包含多个属性和对象的数据对象
   */
  data() {
    return {
      forbiddenTime: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      caseType: 1,
      selectImgIndex: null,
      accessUrlList: [],
      showDialog: false,
      disabled: false,
      dialogForm: {
        qrCodeSwitchRate: 100,
        qrCodeLimitNumber: 200,
        commonImageResp: {}
      },
      dialogRules: {
        qrCodeName: [
          {
            required: true,
            message: "请输入微信名称",
            trigger: ["blur", "change"],
          },
        ],
        qrCodeSwitchRate: [
          {
            required: true,
            message: "请设置切换频率",
            trigger: ["blur", "change"],
          },
        ],
        qrCodeLimitNumber: [
          {
            required: true,
            message: "请设置人数上限",
            trigger: ["blur", "change"],
          },
        ],
        qrCodeValidDate: [
          {
            required: true,
            message: "请选择二维码有效期",
            trigger: ["blur", "change"],
          },
        ],
      },
      dialogFormVisible: false,
      title: "添加个人微信码",
      active: 0,
      form: {
        detailCodeList: []
      },
      rules: {
        caseCodeName: [
          {
            required: true,
            message: "请输入活码名称",
            trigger: ["blur", "change"],
          },
        ],
        caseShopId: [
          {
            required: true,
            message: "请选择门店",
            trigger: ["blur", "change"],
          },
        ],
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 重置数据
      vm.reset();
      vm.caseType = vm.$route.query.caseType
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type == "Update") {
        if (vm.$route.query.caseDetailId) {
          //获取单据详情
          const { data } = await communityCodeDetail(vm.$route.query.caseDetailId);
          //单据赋值
          vm.form = data;
        }
      } else {
        vm.form.caseCodeType = vm.$route.query.caseCodeType
        vm.form.caseId = vm.$route.query.caseId
      }
      vm.loading = false;
    });
  },
  methods: {
    reset() {
      this.active = 0;
      this.form = {
        detailCodeList: []
      }
    },
    async submitForm() {
      if (this.form.caseDetailId) {
        await communityCodeUpdate(this.form)
      } else {
        await communityCodeSave(this.form)
      }
      this.$message.success('保存成功')
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/O2OMall/marketing/wxCommunityCode" });
    },
    editCode(index) {
      this.dialogReset("dialogForm");
      this.selectImgIndex = index;
      this.title = this.form.caseCodeType == 1 ? "编辑个人微信码" : '编辑群二维码';
      const item = deepCopy(this.form.detailCodeList[index]);
      this.dialogForm = { ...item };
      this.dialogFormVisible = true;

    },
    delCode(index) {
      this.$confirm('确定删除该二维码？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(async () => {
        this.form.detailCodeList.splice(index, 1);
        this.$message.success('删除成功')
      })
    },
    submitDialogForm() {
      if (!this.dialogForm?.commonImageResp?.imageUrl) return this.$message.error('请上传二维码图片')
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          if (this.selectImgIndex != undefined) {
            this.$set(this.form.detailCodeList, this.selectImgIndex, { ...this.dialogForm });
          } else {
            this.form.detailCodeList.push({ ...this.dialogForm });
          }
          this.dialogFormVisible = false;
          this.dialogReset("dialogForm");
        }
      });
    },
    cancel() {
      this.dialogFormVisible = false
      this.dialogReset("dialogForm");
    },
    delImg() {
      if (this.disabled) return
      this.$set(this.dialogForm, 'commonImageResp', {})
    },
    getOpenImg() {
      if (this.disabled) return
      this.showDialog = true
    },
    // 选择图片素材
    getAccessUrlList(value, row) {
      this.accessUrlList = row
    },
    // 选择图片弹框确定
    imgUpdateSubmit() {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      this.$set(this.dialogForm.commonImageResp, 'imageUrl', this.accessUrlList[0]?.accessUrl)
      this.$set(this.dialogForm.commonImageResp, 'fileName', this.accessUrlList[0]?.fileName)
      this.$set(this.dialogForm.commonImageResp, 'fileSize', this.accessUrlList[0]?.fileSize)
      this.accessUrlList = []
      this.showDialog = false
    },
    //上传图片弹窗取消
    imgUpdateCancel() {
      this.showDialog = false
      this.accessUrlList = []
    },
    dialogReset() {
      this.dialogForm = {
        qrCodeSwitchRate: 100,
        qrCodeLimitNumber: 200,
        commonImageResp: {}
      };
      this.resetForm("dialogForm");
    },
    addCode() {
      this.dialogReset();
      this.title = this.form.caseCodeType == 1 ? "添加个人微信码" : '添加群二维码';
      this.selectImgIndex = null;
      this.dialogFormVisible = true;
    },
    nextStep() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.active === 1 && this.form.detailCodeList.length === 0) {
            return this.$message.error(`${this.form.caseCodeType == 1 ? '请添加个人微信码' : '添加群二维码'}`)
          }
          this.active++
        }
      });
    },
    lastStep() {
      this.active--
    }
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
}
.choosedImageItem {
  position: relative;
  width: 80px;
  height: 80px;
  border: 1px solid #e5e5e5;
  text-align: center;
  margin-right: 10px;
  .thumbImage {
    min-height: 80px;
    box-sizing: border-box;
    vertical-align: bottom;
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
  }
  .modifyImage {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  .el-icon-circle-close {
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    font-size: 22px;
  }
}
.annotateGrey {
  line-height: 2;
  font-size: 12px;
}
</style>
