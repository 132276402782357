<template>
  <el-form ref="form" :model="form" label-width="96px" :rules="rules">
    <div class="warp" v-loading="loading">
      <topOperatingButton :showIsStop='form.billStatus != 2' :isExamineBtn='true' :isStopBtn='true'
        :isSubmitAddBtn='false' :disabled="disabled" :showAudit="showAudit" @isStop='auditBill' @submitForm="submitForm"
        @auditBill="auditBill" @getQuit="getQuit" />
      <cardTitleCom cardTitle="基本信息" :billStatus="form.billStatus">
        <template slot="cardContent">
          <div class="headTable x-x">
            <div class="right">
              <div class="bgWarp">
                <div class="rightImg">
                  <div class="close"></div>
                  <img v-if="form.imagePlayType == 1"
                    :src="form.commonImageRespList[0].imageUrl || 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-26/82d75f0f44df40d6bdfb05b32cd44ca8.png'"
                    alt="商城弹窗广告" />
                  <el-carousel :interval="3000" v-else trigger="click" :height="`${screenheight}px`">
                    <el-carousel-item v-for="(item,index) in form.commonImageRespList" :key="item">
                      <img :style="{height:index != 0 ? `${screenheight}px`:''}" :id="`screenheight${index}`"
                        :src="item.imageUrl || 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-26/82d75f0f44df40d6bdfb05b32cd44ca8.png'"
                        alt="商城弹窗广告" />
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div>
            <div class="flex1 left">
              <div class="x-w">
                <el-form-item label="广告编号：" prop="billNo">
                  <el-input class="inputWidth" :disabled="true" v-model="form.billNo" placeholder="广告编号" size="mini" />
                </el-form-item>
                <el-form-item label="广告名称：" prop="billName">
                  <el-input :disabled='disabled' class="inputWidth" v-model="form.billName" placeholder="请输入广告名称"
                    size="mini" />
                </el-form-item>
              </div>
              <el-form-item label="图片数量：" prop="imagePlayType">
                <el-radio-group @change="changeImageType" :disabled='disabled' v-model="form.imagePlayType">
                  <el-radio :label="1">单张图片</el-radio>
                  <el-radio :label="2">轮播海报 <el-tooltip style="color: black;margin-left: 10px;" content="最多上传3张"
                      placement="top">
                      <i class="el-icon-question"></i>
                    </el-tooltip></el-radio>
                </el-radio-group>
              </el-form-item>
              <div class="x-x">
                <i style="color: #ff4949;margin-top:10px">* </i>
                <el-form-item label="广告图片：" prop="commonImageRespList" label-width="88px">
                  <el-button type="text" @click="addImg()"
                    v-if="form.imagePlayType == 2 && form.commonImageRespList.length < 3">添加图片</el-button>
                  <div>
                    <div class="x-x">
                      <div v-for="(item,index) in form.commonImageRespList" :key="item.imageUrl"
                        class="choosedImageItem x-fc" @click="getOpenImg(index)">
                        <i v-if="!item.imageUrl" class="el-icon-plus" style="font-size: 32px"></i>
                        <img v-else class="thumbImage" :src="item.imageUrl" alt="广告图" />
                        <span class="modifyImage" v-if="!disabled">{{`${item.imageUrl ? '更换' : '上传'}图片`}}</span>
                        <i v-if="(item.imageUrl || form.commonImageRespList.length > 1) && !disabled"
                          class="el-icon-circle-close" @click.stop="delImg(index)"></i>
                      </div>
                    </div>
                    <div class="annotateGrey">建议宽度600像素，高度800像素</div>
                  </div>
                </el-form-item>
              </div>
              <el-form-item label="跳转路径：" prop="linkPath">
                <div class="x-x">
                  <div v-for="(item, index) in form.commonImageRespList" :key="index" style="margin-right:20px">
                    <el-button :disabled='disabled' type="text"
                      @click="getOpenLink(index)">{{`${item.linkPath ? '修改' : '选择'}链接`}}</el-button>
                    <div class="x-f">
                      <span style="margin-right:10px">{{`图片${index + 1}路径：${item.linkPathName || ''}`}}</span>
                      <span v-if="item.linkPath" class="el-icon-close operateDel" @click="delLink(index)"></span>
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="投放时间：" prop="activityTimes">
                <el-date-picker size="mini" :picker-options="forbiddenTime" :disabled='disabled' unlink-panels
                  v-model="form.activityTimes" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="投放时段：" prop="useTimeRangeType">
                <el-radio-group v-model="form.useTimeRangeType" :disabled="disabled">
                  <el-radio :label="parseInt(dict.value)" v-for="dict in dict.type.use_time_range_type"
                    :key="dict.value" :value="dict.value">{{ dict.label }}
                  </el-radio>
                </el-radio-group>
                <div class="timeTable" v-if="form.useTimeRangeType == 2">
                  <div class="btn x-f marB10">
                    <el-button size="mini" @click="handleEvent('openTimeDateDialog')" :disabled="disabled">选择时间
                    </el-button>
                  </div>
                  <EditTable :options="timeDateOptions" :disabled="disabled"/>
                </div>
              </el-form-item>
              <div class="x-x">
                <el-form-item label="发放对象：" prop="vipLevelRangeTypeArr">
                  <el-checkbox-group v-model="form.vipLevelRangeTypeArr" :disabled="disabled" @change="handleCheckVip">
                    <el-checkbox label="0">全部会员</el-checkbox>
                    <el-checkbox label="3">非会员</el-checkbox>
                    <el-checkbox label="1">全部会员+排除</el-checkbox>
                    <el-checkbox label="2">指定会员</el-checkbox>
                    <el-checkbox label="4">生日会员</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-select v-model="form.vipBirthdayType" size="mini" class="selectVipBD" v-if="showVipBD" :disabled="disabled">
                  <el-option :label="dict.label" v-for="dict in dict.type.vip_birthday_type" :key="dict.value"
                    :value="parseInt(dict.value)">
                  </el-option>
                </el-select>
              </div>
              <div v-if="vipTable">
                <div class="marB10">
                  <el-button size="mini" @click="handleEvent('openVipDialog')" :disabled="disabled">选择会员
                  </el-button>
                  <el-button size="mini" @click="handleEvent('openVipLevelDialog')" :disabled="disabled">选择会员等级
                  </el-button>
                </div>
                <!-- 会员表格 -->
                <comTable :columnLabel="vipTableLable" :tableData="form.popupAdCaseVipLevelList"
                  @delTableRow="delTableRow($event, 'vip')" :disabled="disabled">
                  <!-- 会员表格插入类型 -->
                  <template #tableMiddle>
                    <el-table-column label="类型" align="center" prop="lineType" width="123">
                      <template slot-scope="scope">
                        <dict-tag :options="dict.type.member_type" :value="scope.row.lineType" />
                      </template>
                    </el-table-column>
                  </template>
                </comTable>
              </div>
              <div class="x-x">
                <i style="color: #ff4949;margin-top:10px">* </i>
                <el-form-item label="应用页面：" label-width="88px">
                  <el-checkbox :disabled='disabled' v-model="form.isPageHome">店铺首页</el-checkbox>
                  <el-checkbox :disabled='disabled' v-model="form.isPageGoodsCategory">分类页</el-checkbox>
                  <el-checkbox :disabled='disabled' v-model="form.isPagePaySuccess">支付成功页</el-checkbox>
                  <el-checkbox :disabled='disabled' v-model="form.isPageCenter">我的页面</el-checkbox>
                </el-form-item>
              </div>
              <el-form-item label="推送频次：" prop="pushRateType">
                <el-radio-group :disabled='disabled' v-model="form.pushRateType">
                  <el-radio :label="3">每天一次</el-radio>
                  <el-radio :label="1">永久一次</el-radio>
                  <el-radio :label="2">每次进入</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <el-dialog v-dialogDrag title="素材库" :visible.sync="showDialog" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
    <selectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
    <Dialog ref="tablePageDialog" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </el-form>
</template>

<script>
import { getBillNo } from "@/api/codeRule"; //单据号
import {
  popupCaseUpdateGroupSave,
  popupCaseGetDetail,
  popupCaseUpdate,
  popupCaseAudit,
  popupCaseUpdateIsEnable
} from "@/api/O2OMall/marketing/popupAdCase"; //接口api
import comTable from "@/views/components/com-table"; //广告方案规则表格组件
import EditTable from "@/components/editTable"; //可编辑表格
import { getDateChangeDetail } from "@/utils/public"; //适用时间转换
import Dialog from "@/components/Dialog";
import selectLink from '@/views/components/selectLink/index.vue' //链接库
import Material from '@/views/components/material/index.vue' //素材库
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
export default {
  name: 'popupAdCaseDetail',
  dicts: [
    "use_time_range_type", //适用时间范围
    "vip_birthday_type", //会员生日范围
    "member_type", //适用会员
  ],
  components: { topOperatingButton, cardTitleCom, selectLink, Material, Dialog, EditTable, comTable },
  data() {
    return {
      screenheight: 300,
      //旧的表单
      oldForm: {},
      dialogType: "time",
      //会员表格字段
      vipTableLable: [
        { label: "编号", prop: "lineNo" },
        { label: "名称", prop: "lineName" },
      ],
      // 开启会员表格开关
      vipTable: false,
      //开启会员生日下拉框开关
      showVipBD: false,
      dialogOptions: {
        curType: "", //当前类型(门店/会员/商品/类别/品牌)
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      timeDateOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        echoList: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "dateTime",
            label: "时间范围",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 200,
            align: "center",
          },
        ],
      },
      forbiddenTime: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      accessUrlList: [],
      selectImgIndex: 0,
      linkIndex: 0,
      openLink: false, // 选择链接弹窗
      showDialog: false, // 是否显示图库弹框
      showAudit: true, //审核按钮禁用状态
      disabled: false, //表单禁用
      loading: false,// 遮罩层
      form: {
        pushRateType: 3,
        useTimeRangeType: 0,
        popupAdCaseVipLevelList: [], //适用会员表格数据
        vipLevelRangeTypeArr: ["0", "3"],
        linkPathType: 1,
        imagePlayType: 1,
        billStatus: '',
        commonImageRespList: [
          { imageUrl: '' },
        ],
        specialDateItems: [],
        isPageHome: true,
        isPageGoodsCategory: true,
        isPagePaySuccess: true,
        isPageCenter: true,
      },//表单数据
      rules: { // 表单校验
        billNo: [
          {
            required: true,
            message: "请到编码规则配置单据类型",
            trigger: ["blur", "change"],
          },
        ],
        billName: [
          {
            required: true,
            message: "请输入广告名称",
            trigger: ["blur", "change"],
          },
        ],
        imagePlayType: [
          {
            required: true,
            message: "请选择图片数量",
            trigger: ["blur", "change"],
          },
        ],
        useTimeRangeType: [
          {
            required: true,
            message: "请选择投放时段",
            trigger: ["blur", "change"],
          },
        ],
        pushRateType: [
          {
            required: true,
            message: "请选择推送频次",
            trigger: ["blur", "change"],
          },
        ],
        activityTimes: [
          {
            required: true,
            message: "请选择投放时间",
            trigger: ["blur", "change"],
          },
        ],
        vipLevelRangeTypeArr: [
          {
            required: true,
            message: "请选择发放对象",
            trigger: ["blur", "change"],
          },
        ],
      },
    }
  },
  computed: {
    rightUrl() {
      let imageUrl = this?.form?.commonImageRespList?.[0]?.imageUrl || 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-26/82d75f0f44df40d6bdfb05b32cd44ca8.png'
      return imageUrl || 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-26/82d75f0f44df40d6bdfb05b32cd44ca8.png';
    },
  },
  watch: {
    //侦听单据状态
    "form.billStatus": {
      handler(newVal) {
        if (this.form.billStatus == "2") {
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
        } else if (this.form.billStatus == "3") {
          //禁用审核按钮
          this.showAudit = true;
          //禁止全部输入按钮
          this.disabled = true;
        } else if (this.form.billStatus == "0") {
          //禁用审核按钮
          this.showAudit = false;
          //恢复全部输入
          this.disabled = false;
        } else if (this.form.billStatus == "4") {
          //禁用审核按钮
          this.showAudit = false;
          //恢复全部输入
          this.disabled = false;
        } else if (this.form.billStatus == "") {
          //禁用审核按钮
          this.showAudit = true;
          //恢复全部输入
          this.disabled = false;
        }
      },
      immediate: true,
    },
    "form.vipLevelRangeTypeArr": {
      handler(val) {
        if (val) {
          //当选中全部会员+排除/指定会员时 显示会员表格 反之隐藏
          const bool = val.some((v) => v === "1" || v === "2");
          this.vipTable = bool ? true : false;
          //当选中生日会员  显示会员生日下拉框 反之隐藏
          const vipBD = val.some((v) => v === "4");
          this.showVipBD = vipBD ? true : false;
        }
      },
      immediate: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        // 重置数据
        vm.reset();
        if (vm.$route.query.billId) {
          //获取单据详情
          const { data } = await popupCaseGetDetail(vm.$route.query.billId);
          //单据赋值
          data.activityTimes = [data.begTime, data.endTime]
          if (!data.specialDateItems) {
            data.specialDateItems = []
          }
          if (data.useTimeRangeType == 2) {
            vm.timeDateOptions.list = await getDateChangeDetail(
              data.specialDateItems
            );
            vm.timeDateOptions.echoList = data.specialDateItems;
          }
          if (!data.popupAdCaseVipLevelList) {
            data.popupAdCaseVipLevelList = []
          }
          vm.form = data;
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.form));
        }
      } else {
        // 重置数据
        vm.reset();
        await vm.getOrderBillNo();
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.form = vm.oldForm;
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.form));
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.form.billId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.form));
    }
    next();
  },
  mounted() {
    this.$nextTick(() => {
      this.screenheight = document.getElementById("screenheight0")?.clientHeight;
    })
  },
  methods: {
    delTableRow(row, type) {
      if (type === "vip") {
        //会员
        //过滤掉删除的数据行
        this.form.popupAdCaseVipLevelList = this.form.popupAdCaseVipLevelList.filter(
          (v) => v.lineId !== row.lineId
        );
      }
    },
    getOpenLink(val) {
      this.linkIndex = val;
      this.openLink = true;
    },
    delLink(index) {
      if (this.disabled) return
      this.$set(this.form.commonImageRespList[index], "linkPath", undefined);
      this.$set(this.form.commonImageRespList[index], "linkPathName", undefined);
      this.$forceUpdate()
    },
    delImg(index) {
      if (this.disabled) return
      if (this.form.commonImageRespList.length <= 1) {
        this.form.commonImageRespList = [{}]
      } else {
        this.form.commonImageRespList.splice(index, 1);
      }
      this.$nextTick(() => {
        this.screenheight = document.getElementById("screenheight0")?.clientHeight;
      })
    },
    // 更改图片类型时触发的方法
    changeImageType(val) {
      if (val == 1 && this.form.commonImageRespList.length > 1) {
        // 如果图片数量大于1，则只保留第一张图片
        this.form.commonImageRespList.splice(1); // 移除第一张图片之后的所有图片
      }
      this.$nextTick(() => {
        this.screenheight = document.getElementById("screenheight0")?.clientHeight;
      })
    },
    addImg() {
      this.form.commonImageRespList.push({ imageUrl: "" });
    },
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该广告方案, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.form.billStatus != "0") {
            this.$message.error("此广告方案不是未审核的状态哦~");
            return;
          }
          await this.submitForm(true, "audit");
          const obj = {
            billIds: [this.form.billId],
            billStatus: "2",
          };
          //发送审核api
          const res = await popupCaseAudit(obj);
          this.form = res.data?.[0] || {};
          this.form.activityTimes = [this.form.begTime, this.form.endTime]
          if (!this.form.popupAdCaseVipLevelList) {
            this.form.popupAdCaseVipLevelList = []
          }
          if (!this.form.specialDateItems) {
            this.form.specialDateItems = []
          }
          if (this.form.useTimeRangeType == 2) {
            this.timeDateOptions.list = await getDateChangeDetail(
              this.form.specialDateItems
            );
            this.timeDateOptions.echoList = this.form.specialDateItems;
          }
        } else if (["启用", "禁用"].includes(name)) {
          const res = await popupCaseUpdateIsEnable({
            billIds: [this.form.billId],
            isStop: name == "禁用" ? true : false,
          })
          this.$message.success(`${name}成功`);
          this.form = res.data?.[0] || {};
          this.form.activityTimes = [this.form.begTime, this.form.endTime]
          if (!this.form.popupAdCaseVipLevelList) {
            this.form.popupAdCaseVipLevelList = []
          }
          if (!this.form.specialDateItems) {
            this.form.specialDateItems = []
          }
          if (this.form.useTimeRangeType == 2) {
            this.timeDateOptions.list = await getDateChangeDetail(
              this.form.specialDateItems
            );
            this.timeDateOptions.echoList = this.form.specialDateItems;
          }
        }
      } catch { }
    },
    async submitForm(isBool, audit) {
      const valid = await this.$refs["form"].validate();
      try {
        this.loading = true;
        if (valid) {
          let bolIndex = this.form.commonImageRespList.findIndex(v => !v.imageUrl)
          if (bolIndex > -1) {
            this.loading = false;
            return this.$message.error(`请上传第${bolIndex + 1}张图片`);
          }
          if (!this.form.isPageHome && !this.form.isPageGoodsCategory && !this.form.isPagePaySuccess && !this.form.isPageCenter) {
            this.loading = false;
            return this.$message.error("请选择应用页面");
          }
          if (this.form.useTimeRangeType == 2 && this.form.specialDateItems.length == 0) {
            this.loading = false;
            return this.$message.error("请选择指定时间");
          }
          if (this.form.billId) {
            const { data } = await popupCaseUpdate({ ...this.form, begTime: this.form.activityTimes[0], endTime: this.form.activityTimes[1] });
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              data.activityTimes = [data.begTime, data.endTime]
              if (data.useTimeRangeType == 2) {
                this.timeDateOptions.list = await getDateChangeDetail(
                  data.specialDateItems
                );
                this.timeDateOptions.echoList = data.specialDateItems;
              }
              this.form = data;
              if (!this.form.popupAdCaseVipLevelList) {
                this.form.popupAdCaseVipLevelList = []
              }
              if (!this.form.specialDateItems) {
                this.form.specialDateItems = []
              }
              let text = audit ? "审核成功" : "修改方案成功";
              this.$message.success(text);
            } else {
              await this.reset();
              await this.getOrderBillNo();
              this.$message.success("保存并新增方案成功");
            }
          } else {
            const { data } = await popupCaseUpdateGroupSave({ ...this.form, begTime: this.form.activityTimes[0], endTime: this.form.activityTimes[1] });
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              data.activityTimes = [data.begTime, data.endTime]
              if (data.useTimeRangeType == 2) {
                this.timeDateOptions.list = await getDateChangeDetail(
                  data.specialDateItems
                );
                this.timeDateOptions.echoList = data.specialDateItems;
              }
              this.form = data;
              if (!this.form.popupAdCaseVipLevelList) {
                this.form.popupAdCaseVipLevelList = []
              }
              if (!this.form.specialDateItems) {
                this.form.specialDateItems = []
              }
              let text = audit ? "审核成功" : "新增方案成功";
              this.$message.success(text);
            } else {
              await this.reset();
              await this.getOrderBillNo();
              this.$message.success("保存并修改方案成功");
            }
          }
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    reset() {
      this.form = {
        pushRateType: 3,
        useTimeRangeType: 0,
        popupAdCaseVipLevelList: [], //适用会员表格数据
        vipLevelRangeTypeArr: ["0", "3"],
        linkPathType: 1,
        imagePlayType: 1,
        billStatus: '',
        commonImageRespList: [
          { imageUrl: '' },
        ],
        specialDateItems: [],
        isPageHome: true,
        isPageGoodsCategory: true,
        isPagePaySuccess: true,
        isPageCenter: true,
      };
      this.resetForm("form");
    },
    //单据编号
    async getOrderBillNo() {
      this.form.billNo = await getBillNo(180209);
    },
    //选中适用对象改变触发(选中全部会员去除指定会员,选中指定会员去除全部会员)
    handleCheckVip() {
      if (this.form.vipLevelRangeTypeArr.length > 1) {
        // 拿到最后一个选择的数据  要以最后一个选择的为判断
        if (
          this.form.vipLevelRangeTypeArr[this.form.vipLevelRangeTypeArr.length - 1] ===
          "2"
        ) {
          //当最后一个数据是指定会员 去除全部会员/全部会员+排除
          this.form.vipLevelRangeTypeArr = this.form.vipLevelRangeTypeArr.filter(
            (v) => v !== "0" && v !== "1"
          );
        } else if (
          this.form.vipLevelRangeTypeArr[this.form.vipLevelRangeTypeArr.length - 1] ===
          "0"
        ) {
          //当最后一个数据是全部会员 去除指定会员/全部会员+排除/会员生日
          this.form.vipLevelRangeTypeArr = this.form.vipLevelRangeTypeArr.filter(
            (v) => v !== "2" && v !== "4" && v !== "1"
          );
        } else if (
          this.form.vipLevelRangeTypeArr[this.form.vipLevelRangeTypeArr.length - 1] ===
          "1"
        ) {
          //当最后一个数据是全部会员+排除 去除指定会员/全部会员/会员生日
          this.form.vipLevelRangeTypeArr = this.form.vipLevelRangeTypeArr.filter(
            (v) => v !== "2" && v !== "4" && v !== "0"
          );
        } else if (
          this.form.vipLevelRangeTypeArr[this.form.vipLevelRangeTypeArr.length - 1] ===
          "4"
        ) {
          //当最后一个数据是会员生日 去除全部会员/全部会员+排除
          this.form.vipLevelRangeTypeArr = this.form.vipLevelRangeTypeArr.filter(
            (v) => v !== "1"
          );
        }
      }
    },
    async handleEvent(type, row) {
      switch (type) {
        case "openVipDialog":
          this.dialogType = "vipItem";
          this.dialogOptions = {
            curType: "vipId",
            title: "选择会员",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vip" }),
          };
          break;
        case "openVipLevelDialog":
          this.dialogType = "vipRank";
          this.dialogOptions = {
            curType: "vipLevelId",
            title: "选择会员级别",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vipLevel" }),
          };
          break;
        case "openTimeDateDialog":
          this.dialogType = "time";
          //弹窗配置
          this.dialogOptions = {
            curType: "timeDate",
            title: "选择时间日期",
            width: 600,
            show: true,
            type: "TimeDateSelect",
            formData: {
              DateTimeList: this.timeDateOptions.echoList, // 日期
            },
          };
          break;
        case "dialogChange":
          if (this.dialogType == "time") {
            this.timeDateOptions.echoList = this.dialogOptions.formData.DateTimeList; //方便适用时间回显
            this.timeDateOptions.list = await getDateChangeDetail(this.dialogOptions.formData.DateTimeList);
            this.form.specialDateItems = this.timeDateOptions.list;
          } else {
            this.getPitchData(this.dialogOptions.formData.table.check, this.dialogType)
          }
          break;
        default:
          break;
      }
    },
    getPitchData(data, type) {
      let tableData = []; //定义一个数组变量 (存放不同表格)
      let id; //定义一个变量存放不同id名称
      if (type === "vipItem" || type === "vipRank") {
        //(会员/会员级别)
        tableData = this.form.popupAdCaseVipLevelList;
        id = "lineId"; //(会员/会员级别)公用id
      }
      //过滤选中数组跟表格里重复的数据
      if (tableData.length >= 1) {
        //当表格数据存在时
        for (let i = 0; tableData.length > i; i++) {
          data = data.filter((v) => {
            //商品表格/会员表格需要添加上类型
            if (type === "vipItem") {
              //会员
              v.lineType = 1;
              v.lineId = v.vipId; //会员表格行统一id
              v.lineNo = v.vipNo; //统一编号字段
              v.lineName = v.vipName; //统一名称字段
            } else if (type === "vipRank") {
              //会员级别
              v.lineType = 2;
              v.lineId = v.vipLevelId;
              v.lineNo = v.vipLevelNo; //统一编号字段
              v.lineName = v.vipLevelName; //统一名称字段
            }
            if (type !== "goodsItem") {
              return v[id] !== tableData[i][id]; //选中的数据(门店/商品/会员) 过滤掉已经在表格中已经存在的数据
            } else {
              return v;
            }
          });
        }
        //当是数量特价广告方案时,选择商品是单选,直接赋值,并添加上一行空规则数组
        if (this.form.promoType === "1") {
          console.log(data);
          this.$set(this.form.goodsItem, this.clickGoodsIndex, data[0]);
          return this.$set(
            this.form.goodsItem[this.clickGoodsIndex],
            "goodsBuyFullItem",
            [{}]
          );
        }
        tableData.push(...data); //再追加在表格里
      } else {
        //当表格数据为空时,直接追加表格里
        data.forEach((v) => {
          //商品表格/会员表格需要添加上类型
          if (type === "vipItem") {
            //会员
            v.lineType = 1;
            v.lineId = v.vipId; //会员表格行统一id
            v.lineNo = v.vipNo; //统一编号字段
            v.lineName = v.vipName; //统一名称字段
          } else if (type === "vipRank") {
            //会员级别
            v.lineType = 2;
            v.lineId = v.vipLevelId;
            v.lineNo = v.vipLevelNo; //统一编号字段
            v.lineName = v.vipLevelName; //统一名称字段
          }
          tableData.push(v);
        });
      }
    },
    getOpenImg(index) {
      if (this.disabled) return
      this.selectImgIndex = index
      this.showDialog = true
    },
    // 选择图片素材
    getAccessUrlList(value, row) {
      this.accessUrlList = row
    },
    // 选择图片弹框确定
    imgUpdateSubmit() {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      this.form.commonImageRespList[this.selectImgIndex].imageUrl = this.accessUrlList[0]?.accessUrl
      this.form.commonImageRespList[this.selectImgIndex].fileName = this.accessUrlList[0]?.fileName
      this.form.commonImageRespList[this.selectImgIndex].fileSize = this.accessUrlList[0]?.fileSize
      this.$nextTick(() => {
        this.screenheight = document.getElementById("screenheight0")?.clientHeight;
      })
      this.accessUrlList = []
      this.showDialog = false
    },
    //上传图片弹窗取消
    imgUpdateCancel() {
      this.accessUrlList = []
      this.showDialog = false
    },
    // 选择链接
    selectLinkObj(value) {
      this.form.commonImageRespList[this.linkIndex].linkPath = value.url
      this.form.commonImageRespList[this.linkIndex].linkPathName = value.name
    },
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/O2OMall/marketing/popupAdCase" });
    },
  }
}
</script>

<style lang="scss" scoped>
.warp {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  min-height: calc(100vh - 84px);
  .headTable {
    padding: 6px 10px 6px 30px;
    .right {
      width: 320px;
      height: 693px;
      margin-right: 12px;
      .bgWarp {
        width: 100%;
        background-image: url("https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-12-19/c5f9e6bb11314f94acc29e5b4360b73c.jpg");
        background-size: 100%;
        height: 100%;
        position: relative;
        .rightImg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 255px;
          .close {
            position: absolute;
            width: 29px;
            bottom: -64px;
            left: 50%;
            transform: translateX(-50%);
            height: 29px;
            background-image: url("https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-12-19/6a98b847e88d41008d9740c5559f277b.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          img {
            width: 100%; // 设置图片宽度为100%
            height: auto; // 设置图片高度为自动，以便自适应宽度
          }
        }
      }
    }
    .left {
      .choosedImageItem {
        position: relative;
        width: 80px;
        height: 80px;
        border: 1px solid #e5e5e5;
        text-align: center;
        margin-right: 10px;
        .thumbImage {
          min-height: 80px;
          box-sizing: border-box;
          vertical-align: bottom;
          max-height: 100%;
          max-width: 100%;
          height: auto;
          width: auto;
        }
        .modifyImage {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 80px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          color: #fff;
          background: rgba(0, 0, 0, 0.5);
          cursor: pointer;
        }
        .el-icon-circle-close {
          position: absolute;
          top: 0;
          right: 0;
          color: rgba(0, 0, 0, 0.8);
          cursor: pointer;
          font-size: 22px;
        }
      }
      //会员生日下拉框宽度
      .selectVipBD {
        margin-left: 5px;
        width: 140px;
      }
    }
  }
}
::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 0;
}
::v-deep .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: rgba(0, 0, 0, 1);
  border-radius: 50%;
  padding: 0;
}
</style>
