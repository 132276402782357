var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "开屏广告设置", id: "search-card" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContent" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "开屏广告" } },
                      [
                        _c("el-switch", {
                          model: {
                            value: _vm.form.isUseAd,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isUseAd", $$v)
                            },
                            expression: "form.isUseAd",
                          },
                        }),
                        _c("div", { staticClass: "annotateGrey" }, [
                          _vm._v(
                            "开启进入店铺首页时，出现一段品牌图片/视频，等待加载完成之后进入小程序。"
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "添加素材" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: !_vm.form.isUseAd },
                            on: { change: _vm.changeAdMediaType },
                            model: {
                              value: _vm.form.adMediaType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "adMediaType", $$v)
                              },
                              expression: "form.adMediaType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("图片"),
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("视频"),
                            ]),
                          ],
                          1
                        ),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "choosedImageItem x-fc",
                              on: {
                                click: function ($event) {
                                  return _vm.getOpenImg(1)
                                },
                              },
                            },
                            [
                              !_vm.form.commonImageResp.imageUrl
                                ? _c("i", {
                                    staticClass: "el-icon-plus",
                                    staticStyle: { "font-size": "32px" },
                                  })
                                : _vm.form.adMediaType == 1
                                ? _c("img", {
                                    staticClass: "thumbImage",
                                    attrs: {
                                      src: _vm.form.commonImageResp.imageUrl,
                                      alt: "广告图",
                                    },
                                  })
                                : _vm.form.adMediaType == 2
                                ? _c("video", {
                                    ref: "videoElement",
                                    staticClass: "thumbImage",
                                    attrs: {
                                      src: _vm.form.commonImageResp.imageUrl,
                                    },
                                  })
                                : _vm._e(),
                              _c("span", { staticClass: "modifyImage" }, [
                                _vm._v(
                                  _vm._s(
                                    "" +
                                      (_vm.form.commonImageResp.imageUrl
                                        ? "更换"
                                        : "上传") +
                                      (_vm.form.adMediaType == 1
                                        ? "图片"
                                        : "视频")
                                  )
                                ),
                              ]),
                              _vm.form.commonImageResp.imageUrl
                                ? _c("i", {
                                    staticClass: "el-icon-circle-close",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.delImg()
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm.form.adMediaType == 1
                            ? _c("div", { staticClass: "annotateGrey" }, [
                                _vm._v(
                                  "建议尺寸1080x1920，格式jpg/png/gif，单张图片文件大小限制5M以内"
                                ),
                              ])
                            : _c("div", { staticClass: "annotateGrey" }, [
                                _vm._v(
                                  "建议尺寸1080x1920，格式mp4，单个视频文件大小限制20M以内"
                                ),
                              ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "最大等待时长",
                          prop: "adWaitSecond",
                          "label-width": "120px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "x-f" },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "5px" } },
                              [_vm._v("播放")]
                            ),
                            _c("el-input-number", {
                              staticStyle: { "margin-right": "5px" },
                              attrs: {
                                size: "mini",
                                "controls-position": "right",
                                min: 3,
                                max: 60,
                                disabled: !_vm.form.isUseAd,
                              },
                              model: {
                                value: _vm.form.adWaitSecond,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "adWaitSecond", $$v)
                                },
                                expression: "form.adWaitSecond",
                              },
                            }),
                            _c("span", [_vm._v("秒后自动进入店铺首页")]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "annotateGrey" }, [
                          _vm._v(
                            "自定义等待时长生效，最少需要等待页面加载完成。"
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "跳转" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: !_vm.form.isUseAd },
                            model: {
                              value: _vm.form.adJumpType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "adJumpType", $$v)
                              },
                              expression: "form.adJumpType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("不跳转"),
                            ]),
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("整体跳转"),
                            ]),
                          ],
                          1
                        ),
                        _vm.form.adJumpType == 1
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getOpenLink()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        (_vm.form.commonImageResp.linkPath
                                          ? "修改"
                                          : "选择") + "链接"
                                      )
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "x-f" }, [
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-right": "10px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          "跳转路径：" +
                                            (_vm.form.commonImageResp
                                              .linkPathName || "")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm.form.commonImageResp.linkPath
                                    ? _c("span", {
                                        staticClass: "el-icon-close operateDel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.delLink()
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-left": "65px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.submitForm()
                              },
                            },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.showDialog,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true, imgFileSize: 5 },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("selectLink", {
        attrs: { OpenLink: _vm.openLink },
        on: {
          "update:OpenLink": function ($event) {
            _vm.openLink = $event
          },
          "update:open-link": function ($event) {
            _vm.openLink = $event
          },
          selectLinkObj: _vm.selectLinkObj,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }