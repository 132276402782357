<template>
  <div class="videoTmp">
    <el-form :model="data" @submit.native.prevent>
      <el-form-item label="视频:">
        <div class="x-bc">
          <div class="x-f"></div>
          <div class="x-f btnListBox">
            <!-- <div
                            class="item x-c"
                            v-for="(item, index) in vadeoSource"
                            :key="index"
                            @click="setSource(item.source, index)"
                            :class="{ borderColor: sourceItem == index }"
                        >
                            <span>{{ item.name }}</span>
                        </div> -->
            <OssUpload v-model="data.style.videoUrl" :type="['mp4']" :showList="false" :tip="false" buttonName="上传视频"
              size="10mb" />
          </div>
        </div>
        <div class="y-start marT10">
          <el-input v-model="data.style.videoUrl" placeholder="请输入地址链接"></el-input>
          <span style="color: #969799" class="fS14MR10">小程序 v2.15 及以上版本支持仅支持.mp4格式的视频源地址</span>
          <video style="background: #000000" :src="data.style.videoUrl" height="200px" width="95%"
            :poster="data.style.videoSource != 'none' ? data.style.coverImg : ''">
          </video>
        </div>
      </el-form-item>
      <el-form-item label="跳转链接:">
        <div class="x-bc">
          <div></div>
          <div>
            <el-input placeholder="请选择链接" class="inputWidth" size="mini" v-model="data.style.linkName" readonly>
            </el-input>
            <span class="marL10 fontS14B cursorP" @click="openLink = true">{{
          data.style.linkName ? "修改" : "选择链接"
        }}</span>
            <span class="marL10 fontS14B cursorP" @click="emptied" v-if="data.style.linkUrl">清空</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="封面:">
        <div class="x-bc">
          <div class="x-f"></div>
          <div class="x-f btnListBox fS14MR10">
            <div class="item x-c" v-for="(item, index) in coverImgList" :key="index"
              @click="setCover(item.style, index)" :class="{ borderColor: data.style.videoSource == item.style }">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </el-form-item>
      <div v-if="data.style.videoSource == 'select'">
        <div class="addImgBox x-c" @click="addCoverImg">
          <i class="el-icon-plus" v-if="!data.style.coverImg" style="font-size: 26px; color: #bababa"></i>
          <img v-else :src="data.style.coverImg" :style="{width:'100%',height:'auto'}" />
          <div v-if="data.style.coverImg" class="product-actions">
            <i class="el-icon-error" @click.stop="data.style.coverImg = ''"></i>
          </div>
        </div>
        <span style="color: #969799" class="fontS12">建议图宽高比例与视频宽高比一致</span>
      </div>
      <el-divider></el-divider>
      <div>
        <div class="x-bc">
          <span class="title">播放设置</span>
          <i :class="jiantou1" class="fontS16 pointer" @click="openDownFn"></i>
        </div>
        <div v-if="openDown" class="marT10">
          <!-- <el-form-item>
            <div class="x-bc">
              <div class="x-f">
                <span class="marR20 label">进度条</span>
                <span>{{ data.style.progressBar ? "显示" : "不显示" }}</span>
              </div>
              <div class="x-f btnListBox">
                <el-switch v-model="data.style.progressBar" size="mini"></el-switch>
              </div>
            </div>
          </el-form-item> -->
          <el-form-item>
            <div class="x-bc">
              <div class="x-f">
                <span class="marR20 label">循环播放</span>
                <span>{{ data.style.loop ? "已开启" : "已关闭" }}</span>
              </div>
              <div class="x-f btnListBox">
                <el-switch v-model="data.style.loop" size="mini"></el-switch>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="x-bc">
              <div class="x-f">
                <span class="marR20 label">自动播放</span>
                <span>{{ data.style.voluntarilyPlay ? "已开启" : "已关闭" }}</span>
              </div>
              <div class="x-f btnListBox">
                <el-switch v-model="data.style.voluntarilyPlay" size="mini"></el-switch>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
      <el-divider></el-divider>
      <div>
        <div class="x-bc">
          <span class="title">样式设置</span>
          <i :class="jiantou2" class="fontS16 pointer" @click="open"></i>
        </div>
        <div v-if="openChamfer" class="marT10">
          <el-form-item>
            <div class="x-bc">
              <div class="x-f">
                <span class="marR20 label">视频倒角</span>
                <!--                                <span>{{data.style.progressBar ? '显示' : '不显示'}}</span>-->
              </div>
              <div class="x-f btnListBox">
                <div class="item x-c" v-for="(item, index) in chamferList" :key="index"
                  @click="setChamfer(item.style, index)" :class="{ borderColor: chamferItem == index }">
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="上边距" label-width="">
            <div class="x-f">
              <el-slider style="width: 300px" :max="30" v-model="data.style.pagePaddingTop"
                @change="selectpagebackGauge(data.style.pagePaddingTop, 'top')" show-input></el-slider>
            </div>
          </el-form-item>
          <el-form-item label="下边距" label-width="">
            <div class="x-f">
              <el-slider style="width: 300px" :max="30" v-model="data.style.pagePaddingBottom"
                @change="selectpagebackGauge(data.style.pagePaddingBottom, 'bottom')" show-input></el-slider>
            </div>
          </el-form-item>
          <el-form-item label="左边距" label-width="">
            <div class="x-f">
              <el-slider style="width: 300px" :max="30" v-model="data.style.pagePaddingLeft"
                @change="selectpagebackGauge(data.style.pagePaddingLeft, 'left')" show-input></el-slider>
            </div>
          </el-form-item>
          <el-form-item label="右边距" label-width="">
            <div class="x-f">
              <el-slider style="width: 300px" :max="30" v-model="data.style.pagePaddingRight"
                @change="selectpagebackGauge(data.style.pagePaddingRight, 'right')" show-input></el-slider>
            </div>
          </el-form-item>
          <!-- <el-form-item label="页面边距" label-width="">
            <div class="x-f">
              <el-slider
                style="width: 300px"
                :max="30"
                v-model="data.style.pagebackGauge"
                show-input
              ></el-slider>
            </div>
          </el-form-item> -->
        </div>
      </div>
    </el-form>
    <el-dialog v-dialogDrag title="素材库" :visible.sync="showDialog" width="80%" :destroy-on-close="true">
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
    <selectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
  </div>
</template>

<script>
import selectLink from "@/views/components/selectLink/index.vue"; //链接库
import Material from '@/views/components/material/index.vue' //素材库
import OssUpload from "@/components/oss-upload/index.vue";
export default {
  name: "videoTmp",
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  components: {
    Material,
    OssUpload,
    selectLink
  },
  data() {
    return {
      openLink: false,
      accessUrlList: [], // 选择的图片
      showDialog: false, // 是否显示图库弹框
      // 视频来源
      vadeoSource: [
        // { source: 'update', name: '选择视频' },
        { source: "url", name: "视频地址" },
      ],
      coverImgList: [
        { style: "none", name: "默认", src: "" },
        { style: "select", name: "选择图片", src: "" },
      ],
      chamferList: [
        { style: "rightAngle", name: "直角" },
        { style: "circle", name: "圆角" },
      ],

      jiantou1: "el-icon-arrow-down",
      jiantou2: "el-icon-arrow-down",
      openDown: true,
      openChamfer: true,

      sourceItem: 0,
      coverImgItem: 0,
      chamferItem: 0,
    };
  },
  methods: {
    emptied() {
      this.data.style.linkUrl = '';
      this.$set(this.data.style, 'linkName', '')
      this.templateMsg();
    },
    //链接赋值
    selectLinkObj(value) {
      this.data.style.linkUrl = value.url;
      this.data.style.linkName = value.name;
      this.templateMsg();
    },
    setImageWidth() {
      let img = new Image(); // 创建一个新的Image对象
      img.onload = () => {
        let drawWidth = 100; // 默认绘制宽度
        let drawHeight = 100; // 默认绘制高度
        const imageWidth = img.width;
        const imageHeight = img.height;
        // 计算等比例缩放的尺寸
        const aspectRatio = imageWidth / imageHeight;
        if (aspectRatio > 1) {
          // 图片更宽，高度等比例缩放
          drawHeight = drawWidth / aspectRatio;
        } else {
          // 图片更高或正方形，宽度等比例缩放
          drawWidth = drawHeight * aspectRatio;
        }
        this.data.style.coverImgWidth = drawWidth
        this.data.style.coverImgHeight = drawHeight
        console.log('=======', this.data.style.coverImgWidth, this.data.style.coverImgHeight);
      };
      img.onerror = (error) => {
        console.error('=======1', error);
      };
      img.src = this.data.style.coverImg
      console.log('=======2', img, this.data.style.coverImgWidth, this.data.style.coverImgHeight);
    },
    // 选择图片弹框确定
    imgUpdateSubmit() {
      if (this.accessUrlList.length > 1) {
        return this.$message.error('图片信息只能单选')
      }
      this.data.style.coverImg = this.accessUrlList[0]
      this.accessUrlList = []
      this.showDialog = false
      this.templateMsg()
    },
    //上传图片弹窗取消
    imgUpdateCancel() {
      this.accessUrlList = []
      this.showDialog = false
    },
    getAccessUrlList(value) {
      this.accessUrlList = value
    },
    selectpagebackGauge(value, type) {
      this.templateMsg()
    },
    templateMsg() {
      this.$emit("setTemp", this.data);
    },
    //
    setSource(style, index) {
      this.coverImgItem = index;
    },
    //
    setCover(style, index) {
      this.sourceItem = index;
      this.coverImgItem = index;
      this.data.style.videoSource = style;
      this.templateMsg();
    },
    // 打开素材库选择封面
    addCoverImg() {
      this.showDialog = true;
    },
    // 视频倒角
    setChamfer(style, index) {
      this.chamferItem = index;
      this.data.style.chamfer = style;
      this.templateMsg();
    },

    // 切换箭头
    openDownFn() {
      this.openDown = !this.openDown;
      if (this.openDown) {
        this.jiantou1 = "el-icon-arrow-up";
      } else {
        this.jiantou1 = "el-icon-arrow-down";
      }
    },
    open() {
      this.openChamfer = !this.openChamfer;
      if (this.openChamfer) {
        this.jiantou2 = "el-icon-arrow-up";
      } else {
        this.jiantou2 = "el-icon-arrow-down";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.videoTmp {
}

.lable {
  margin-right: 16px;
  font-size: 14px;
  color: #d3d3d3;
  line-height: 18px;
  white-space: nowrap;
}

.btnListBox {
  border-radius: 5px;
  font-size: 12px;

  .item {
    //width: 38px;
    height: 30px;
    border: 1px solid #ebedf0;
    padding: 0 20px;
    cursor: pointer;
  }

  .borderColor {
    border: 1px solid #1c75f4;
  }
}

.addImgBox {
  width: 100px;
  height: 100px;
  border: 1px solid #bababa;
  cursor: pointer;
  position: relative;
  .product-actions {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
  }
}
/* 鼠标移入删除图标隐藏 */
.addImgBox:hover .product-actions {
  visibility: visible;
}

.el-icon-error {
  font-size: 18px;
  color: #999999;
  cursor: pointer;
}
.title {
  font-size: 18px;
  font-weight: 550;
}
</style>
