<template>
  <div class="wrap" v-loading="loading" id="wrap" ref="wrap">
    <el-form ref="form" :model="options" :rules="rules" label-width="106px">
      <topOperatingButton :isExamineBtn="![2 , '2'].includes(options.billStatus)" :showAudit="isShowAudit"
        :isStopBtn="false" :disabled='disableOperate' id="topOperatingButton" ref="topOperatingButton"
        @submitForm="submitForm(false)" @addBill="submitForm(true)" @auditBill="auditForm" @getQuit="getQuit">
      </topOperatingButton>
      <cardTitleCom cardTitle="基本信息" id="basicInformation" ref="basicInformation" :billStatus="options.billStatus">
        <template slot="cardContent">
          <div class="x-f marT10">
            <el-form-item label="单据编号" required>
              <el-input class="inputWidth" size="mini" :disabled="true" v-model="options.billNo"
                placeholder="单据编号"></el-input>
            </el-form-item>
            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker class="inputWidth" size="mini" v-model="options.billDate" type="date" placeholder="单据日期"
                :disabled="disableOperate" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结算日期" prop="settlementDate">
              <el-date-picker class="inputWidth" size="mini" v-model="options.settlementDate" type="date"
                placeholder="单据日期" :disabled="disableOperate" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="客户" prop="partnerId">
              <el-select :disabled="disableOperate" @change="changePartner" v-model="options.partnerId" clearable
                filterable size="mini" placeholder="请选择">
                <el-option v-for="item in customerData" :key="item.customerId" :label="item.customerName"
                  :value="item.customerId" />
              </el-select>
            </el-form-item>
            <el-form-item label="部门" prop="deptId">
              <el-select :disabled="disableOperate" v-model="options.deptId" clearable filterable size="mini"
                placeholder="请选择">
                <el-option v-for="item in deptData" :key="item.deptId" :label="item.deptName" :value="item.deptId" />
              </el-select>
            </el-form-item>

          </div>
          <div class="x-f">
            <el-form-item label="业务员" prop="saleEmployeeId">
              <el-select :disabled="disableOperate" v-model="options.saleEmployeeId" clearable filterable size="mini"
                placeholder="请选择">
                <el-option v-for="item in employeeData" :key="item.employeeId" :label="item.employeeName"
                  :value="item.employeeId" />
              </el-select>
            </el-form-item>
            <el-form-item label="应收款余额">
              <el-input size="mini" :disabled="true" class="inputWidth" v-model="options.accountsBalance" type="text"
                placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="结算期限信息">
              <el-select :disabled="disableOperate" @change="changeSaleEmployee" v-model="options.settlementTermId"
                clearable filterable size="mini" placeholder="请选择">
                <el-option v-for="item in saleEmployeeData" :key="item.settlementTermId"
                  :label="item.settlementTermName" :value="item.settlementTermId" />
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="billRemark">
              <el-input size="mini" class="inputWidth" v-model="options.billRemark" type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 1 }"
                :disabled="disableOperate" />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="收款信息" class="options">
        <template slot="cardContent">
          <div class="table">
            <EditTable :options="options" @handleEvent="handleEvent" />
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="应收信息" class="sourceOptions">
        <template slot="cardContent">
          <div class="table">
            <el-form ref="formList" :model="sourceOptions" :rules="rulesOptions" label-width="106px">
              <EditTable :options="sourceOptions" @handleEvent="handleEventOptions" />
            </el-form>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="附件">
        <template slot="cardContent">
          <!-- 附件上传组件 -->
          <accessoryUpload listType="img" class="accessoryUpload" :disabled="disableOperate"
            :fileList="options.fileItems" @getFileItems="getFileItemsData" @delFileItems="deleteList"></accessoryUpload>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import {
  otherReceiveAddBill,
  getShouldReceiveSaleFunds,
  otherReceiveModifyBill,
  otherReceiveGetBillByBillId,
  otherReceiveModifyBillStatus
} from '@/api/arap/index'
import { listDept } from '@/api/system/dept'
import { allCustomerList } from '@/api/partner/partner'
import { allEmployeeList } from '@/api/system/employee' //业务员
import { listSettlementTerm } from "@/api/system/settlementTerm/index";//结算期限
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import EditTable from '@/components/editTable' //可编辑表格
import Dialog from '@/components/Dialog'
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import { getBillNo } from '@/api/codeRule'
import { uniqWith } from 'lodash' //去重
import { limitInputlength, fcount, deepCopy, calculateTaxes } from '@/utils'
import accessoryUpload from '@/views/components/accessoryUpload' //附件上传组件

export default {
  name: 'otherReceiptDetail',
  components: {
    topOperatingButton,
    EditTable,
    cardTitleCom,
    Dialog,
    accessoryUpload,
  },
  watch: {
    'options.billStatus': {
      handler(val) {
        this.disableOperate = ['2', '3', '4', 2, 3, 4].includes(val)
        this.isShowAudit = ['', 4, '4'].includes(val)
        this.options.columns[0].disabled = this.disableOperate
        this.options.columns[1].disabled = this.disableOperate
        this.options.columns[2].disabled = this.disableOperate
        this.options.columns[3].disabled = this.disableOperate
        this.options.columns[4].disabled = this.disableOperate
        this.options.columns[5].disabled = this.disableOperate

        this.sourceOptions.columns[0].disabled = this.disableOperate
        this.sourceOptions.columns[1].disabled = this.disableOperate
        this.sourceOptions.columns[2].disabled = this.disableOperate
        this.sourceOptions.columns[3].disabled = this.disableOperate
        this.sourceOptions.columns[4].disabled = this.disableOperate
        this.sourceOptions.columns[5].disabled = this.disableOperate
        this.options.status = this.disableOperate
      },
      immediate: true
    },
    'options.list': {
      handler(newVal, oldVal) {
        let isSwitch = this.options.list.filter(item => item.bankAccountNo
          || item.payModeNo ||
          item.payMoney || item.payPoundage || item.payBillNo || item.remark)
        if (isSwitch.length > 0) {
          this.options.columns[0].rules = true
          this.options.columns[1].rules = true
          this.options.columns[2].rules = true
        } else {
          this.options.columns = this.options.columns.map(item => (
            {
              ...item,
              rules: false,
            }
          ))
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      payMoneyTotal: '',
      nowWriteOffMoneyTotal: '',
      disableOperate: false,
      isShowAudit: '',
      SourceOperationData: '',
      isAdd: '',
      customerData: [],
      deptData: [],
      employeeData: [],
      saleEmployeeData: [],
      loading: false, //遮罩层
      //表格配置
      options: {
        fileItems: [],
        billNo: undefined,
        billDate: undefined,
        partnerId: undefined,
        deptId: undefined,
        saleEmployeeId: undefined,
        accountsBalance: undefined, // 应收款余额 不传的
        discountMoney: undefined,
        billStatus: '',

        tableIndex: 0,
        retainColumn: true,
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [{}],
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        // tableHeight: 200, //表格高度
        status: undefined, //单据状态
        // isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: 'bankAccountName',
            label: '收款账号',
            type: 'remoteSelect',
            click: 'clickAccount',
            Width: 200,
            align: 'center',
            option: this.$select({
              key: 'account',
              option: {
                option: {
                  label: 'bankAccountName',
                  value: 'bankAccountName',
                  modal: 'bankAccountName',
                  change: (propValue, row) => {
                    let tableIndex = this.options.tableIndex
                    if (row && this.options.tableIndex != undefined) {
                      this.$set(this.options.list[tableIndex], 'bankAccountId', row.bankAccountId)
                      this.$set(this.options.list[tableIndex], 'bankAccountNo', row.bankAccountNo)
                      this.$set(this.options.list[tableIndex], 'bankAccountName', row.bankAccountName)
                    }
                  },
                  tableChange: (propValue, row) => {
                    this.handleEvent('getSelectData', row)
                  },
                  buttons: [
                    {
                      type: 'more',
                      option: {
                        title: '选择收款账号',
                        width: 1250,
                        type: 'TreeAndTable',
                        formData: {
                          ...this.$dialog({ key: 'account' }),
                          table: {
                            ...this.$dialog({ key: 'account' }).table,
                            mutiSelect: true
                          }
                        }
                      }
                    }
                  ]
                }
              }
            }).option,
            disabled: false,
            rules: true,
          },
          {
            prop: 'payModeNo',
            label: '收款方式',
            type: 'remoteSelect',
            click: 'clickAccount',
            Width: 200,
            align: 'center',
            option: this.$select({
              key: 'paymode',
              option: {
                option: {
                  label: 'payModeName',
                  value: 'payModeNo',
                  modal: 'payModeNo',
                  tableChange: (propValue, row) => {
                    this.handleEvent('getSelectDataPay', row)
                  },
                  change: (propValue, row) => {
                    let tableIndex = this.options.tableIndex
                    if (row && this.options.tableIndex != undefined) {
                      this.$set(this.options.list[tableIndex], 'payModeId', row.payModeId)
                      this.$set(this.options.list[tableIndex], 'payModeName', row.payModeName)
                      this.$set(this.options.list[tableIndex], 'payModeNo', row.payModeNo)
                    }
                  },
                  buttons: [
                    {
                      type: 'more',
                      option: {
                        title: '选择收款方式',
                        width: 1250,
                        type: 'TreeAndTable',
                        formData: {
                          ...this.$dialog({ key: 'paymode' }),
                          table: {
                            ...this.$dialog({ key: 'paymode' }).table,
                            mutiSelect: true
                          }
                        }
                      }
                    }
                  ]
                }
              }
            }).option,
            disabled: false,
            rules: true
          },
          {
            prop: 'payMoney',
            label: '收款金额',
            minWidth: 120,
            align: 'center',
            type: 'input',
            disabled: false,
            rules: true,
            isMinus: false,
            inputNum: 4
          },
          {
            prop: 'payPoundage',
            label: '手续费',
            minWidth: 120,
            align: 'center',
            type: 'input',
            disabled: false,
            // rules: true,
            isMinus: false,
            inputNum: 4
          },
          {
            prop: 'payBillNo',
            label: '交易单号',
            minWidth: 120,
            align: 'center',
            type: 'input',
            disabled: false,
            // rules: true,
            isMinus: false,
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 120,
            align: 'center',
            type: 'my-input',
            maxlength: 80,
            disabled: false
          }
        ],
        summary: ['payMoney','payPoundage'],
      },
      sourceOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [{}],
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        // tableHeight: 300, //表格高度
        status: undefined, //单据状态
        // isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        retainColumn: true,
        tableIndex: 0,
        columns: [
          {
            prop: 'rpTypeName',
            label: '收入类别',
            type: 'remoteSelect',
            click: 'clickAccount',
            Width: 200,
            align: 'center',
            option: this.$select({
              key: 'rpType',
              option: {
                option: {
                  label: 'rpTypeName',
                  value: 'rpTypeName',
                  modal: 'rpTypeId',
                  remoteBody: { rpType: 1 },
                  change: (propValue, row) => {
                    let tableIndex = this.sourceOptions.tableIndex
                    // return
                    if (row && this.sourceOptions.tableIndex != undefined) {
                      this.$set(this.sourceOptions.list[tableIndex], 'rpTypeName', row.rpTypeName)
                      this.$set(this.sourceOptions.list[tableIndex], 'rpTypeNo', row.rpTypeNo)
                      this.$set(this.sourceOptions.list[tableIndex], 'rpTypeId', row.rpTypeId)
                    }
                  },
                  tableChange: (propValue, row) => {
                    this.handleEventOptions('getRpTypeData', row)
                  },
                  buttons: [
                    {
                      type: 'more',
                      option: {
                        title: '选择类别',
                        width: 1250,
                        type: 'TreeAndTable',
                        formData: {
                          ...this.$dialog({ key: 'listRpType' }),
                          queryParams: {
                            rpType: 1,
                          },
                          search: {
                            tip: "全部/收入编码/收入名称",
                            model: "",
                            filters: [
                              { filter: "query", label: "全部" },
                              { filter: "rpTypeNos", label: "收入编码" },
                              { filter: "rpTypeNames", label: "收入名称" },
                            ],
                          },
                          table: {
                            ...this.$dialog({ key: 'listRpType' }).table,
                            mutiSelect: true,
                            columns: [
                              { prop: "rpTypeNo", label: "收入编码", minWidth: 180, },
                              { prop: "rpTypeName", label: "收入名称", minWidth: 155, },
                              { prop: "rpTypeName", label: "收入分类名称", minWidth: 180, },
                              { prop: "rpTypeCategoryId", label: "收入分类编码", minWidth: 155, },
                              { prop: "rpTypeCategoryName", label: "分类名称", formatter: (v, row) => row.rpTypeCategoryInfo ? row.rpTypeCategoryInfo.rpTypeCategoryName : '', minWidth: 120, },
                              { prop: "isStop", label: "状态", formatter: (v, row) => row.isStop ? '禁用' : '启用', minWidth: 120, },
                            ]
                          }
                        }
                      }
                    }
                  ]
                }
              }
            }).option,
            disabled: false,
            rules: true,
          },
          {
            prop: 'rpMoney',
            label: '应收金额',
            minWidth: 120,
            align: 'center',
            type: 'input',
            blurInput: 'rpMoney',
            disabled: false,
            rules: true,
            isMinus: true,
            inputNum: 4
          },
          {
            prop: 'taxRate',
            label: '税率',
            minWidth: 120,
            align: 'center',
            type: 'input',
            blurInput: 'taxRate',
            disabled: false,
            // rules: true,
            isMinus: true,
            inputNum: 2
          },
          {
            prop: 'notaxMoney',
            label: '不含税金额',
            minWidth: 120,
            align: 'center',
            type: 'input',
            blurInput: 'notaxMoney',
            disabled: false,
            // rules: true,
            isMinus: true,
            inputNum: 4
          },
          {
            prop: 'taxMoney',
            label: '税额',
            minWidth: 120,
            align: 'center',
            type: 'input',
            blurInput: 'taxMoney',
            disabled: false,
            // rules: true,
            isMinus: true,
            inputNum: 4
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 120,
            align: 'center',
            type: 'my-input',
            maxlength: 80,
            disabled: false,
          }
        ],
        summary: ['rpMoney', 'notaxMoney', 'taxMoney'],
      },
      //弹窗配置
      dialogOptions: {
        title: '选择',
        width: 1250,
        show: false,
        type: 'TreeAndTable'
      },
      rules: {
        billNo: [
          {
            required: true,
            message: '请输入方案编号',
            trigger: ['blur', 'change']
          }
        ],
        billDate: [
          { required: true, message: '请输入单据日期', trigger: ['blur', 'change'] }
        ],
        settlementDate: [
          { required: true, message: '请输入结算日期', trigger: ['blur', 'change'] }
        ],
        partnerId: [
          { required: true, message: '请输入客户', trigger: ['blur', 'change'] }
        ],
        deptId: [
          { required: true, message: '请输入部门', trigger: ['blur', 'change'] }
        ],
        saleEmployeeId: [
          { required: true, message: '请输入业务员', trigger: ['blur', 'change'] }
        ],
      },
      rulesOptions: {
        saleEmployeeId: [
          { required: true, message: '请输入业务员', trigger: ['blur', 'change'] }
        ],
      },
      ceshi: undefined,
      pageH: 0, //页面高度
      btnsH: 0, //搜索框高度
      basicH: 0, //基础框高度
      paginationH: 50 //分页高度
    }
  },
  activated() {
    this.getDetail()
  },
  mounted() {
    this.getDetail()
  },
  beforeDestroy() {
  },
  created() {
    this.allCustomerListFun()
    this.listDeptFun()
    this.allEmployeeListFun()
    this.listSettlementTermFun()
  },
  computed: {},
  methods: {
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/arap/arbill/otherReceipt" });
    },
    deleteList(val) {
      this.options.fileItems = this.options.fileItems.filter(v => v.url != val.url)
    },
    //获取附件上传的信息数据
    getFileItemsData(data) {
      this.options.fileItems.push({
        accessUrl: data.accessUrl,
        domain: data.domain,
        filePath: data.filePath,
        fileName: data.fileName,
        fileSize: data.fileSize,
        name: data.fileName,
        url: data.accessUrl
      })
    },
    changeSaleEmployee(val) {
      if (this.options.billDate) {
        let dateTime = new Date(this.options.billDate);
        let settlementTermDays = this.saleEmployeeData.filter(v => v.settlementTermId == val)[0].settlementTermDays
        let settlementDate = new Date(dateTime);
        settlementDate.setDate(dateTime.getDate() + settlementTermDays);
        let time = `${settlementDate.getFullYear()}-${settlementDate.getMonth() + 1}-${settlementDate.getDate()}`
        this.options.settlementDate = time
      }
    },
    changePartner(val) {
      if (!val) return
      let id = this.customerData.filter(v => v.customerId == val)[0].settlementTermId
      if (id && this.options.billDate) {
        let dateTime = new Date(this.options.billDate);
        let settlementTermDays = this.saleEmployeeData.filter(v => v.settlementTermId == id)[0].settlementTermDays
        let settlementDate = new Date(dateTime);
        settlementDate.setDate(dateTime.getDate() + settlementTermDays);
        let time = `${settlementDate.getFullYear()}-${settlementDate.getMonth() + 1}-${settlementDate.getDate()}`
        this.$set(this.options, 'settlementDate', time)
        this.options.settlementTermId = id
      }
      getShouldReceiveSaleFunds({ partnerId: val }).then(res => {
        this.options.accountsBalance = res.data.shouldSettleMoney
      })
    },
    //保留小数位
    limitInputlength,
    allCustomerListFun() {
      allCustomerList().then(response => {
        this.customerData = response.data
      })
    },
    listDeptFun() {
      listDept({
        pageNum: 1,
        pageSize: 1000
      }).then(response => {
        this.deptData = response.data
      })
    },
    allEmployeeListFun() {
      allEmployeeList({}).then(response => {
        this.employeeData = response.data
      })
    },
    listSettlementTermFun() {
      listSettlementTerm({}).then(response => {
        this.saleEmployeeData = response.rows
      })
    },
    //获取详情
    async getDetail() {
      this.reset()
      if (this.$route.query.type === 'Update' && this.$route.query.billId) {
        this.loading = true
        try {
          const { data } = await otherReceiveGetBillByBillId({ billId: this.$route.query.billId })
          //方案赋值
          this.options = {
            ...this.options,
            ...data,
            list: []
          }
          this.options.list = data.payDetails ? data.payDetails : [{}]
          let arrlist = data.otherRpDetails.map((item) => (
            {
              ...item,
              ...item.rpTypeInfo
            }
          ))
          if (this.options.fileItems?.length > 0) {
            this.options.fileItems = this.options.fileItems.map((item) => (
              {
                ...item,
                name: item.fileName,
                url: item.accessUrl,
              }
            ))
          }
          this.sourceOptions.list = arrlist
          getShouldReceiveSaleFunds({ partnerId: this.options.partnerId }).then(res => {
            this.options.accountsBalance = res.data.shouldSettleMoney
          })
        } catch (err) { }
        this.loading = false
      } else {
        this.getBillno()
      }
    },

    async handleEvent(type, row) {
      //获取元素在数组的下标
      let contains = function (list, row) {
        var i = list.length
        while (i--) {
          if (list[i] === row) {
            return i
          }
        }
        return false
      }
      switch (type) {
        case 'clickAccount':
          this.options.curListIndex = contains(this.options.list, row)
          this.dialogOptions.show = true
          //弹窗配置
          this.dialogOptions = {
            title: '选择账户',
            width: 1250,
            show: true,
            type: 'TreeAndTable',
            formData: this.$dialog({ key: 'product' })
          }
          break
        case 'getSelectDataPay':
        case 'getSelectData':
          if (!row) return
          let { check } = row
          if (check.length <= 0) return
          if (type == 'getSelectData') {
            let tableIndex = this.options.tableIndex
            this.$set(this.options.list[tableIndex], 'bankAccountId', check[0].bankAccountId)
            this.$set(this.options.list[tableIndex], 'bankAccountNo', check[0].bankAccountNo)
            this.$set(this.options.list[tableIndex], 'bankAccountName', check[0].bankAccountName)
          } else {
            let tableIndex = this.options.tableIndex
            this.$set(this.options.list[tableIndex], 'payModeId', check[0].payModeId)
            this.$set(this.options.list[tableIndex], 'payModeName', check[0].payModeName)
            this.$set(this.options.list[tableIndex], 'payModeNo', check[0].payModeNo)
          }
          break
        case 'tableIndex':
          this.options.tableIndex = row
          break
        default:
          break
      }
    },
    async handleEventOptions(type, row) {
      switch (type) {
        case "rpMoney":
        case 'taxRate':
        case 'taxMoney':
        case 'notaxMoney':
          {
            //税计算公式:应收金额/((100+税率)/100)=不含税金额， notaxMoney / ((100+taxRate)/100) = notaxMoney；应收金额 - 不含税金额 = 含税金额，notaxMoney-notaxMoney=taxMoney
            let obj = deepCopy(this.sourceOptions.list[this.sourceOptions.tableIndex])
            obj = calculateTaxes(obj, type);
            this.$set(this.sourceOptions.list, this.sourceOptions.tableIndex ,{...obj})
          }
          break;
        case 'tableIndex':
          this.sourceOptions.tableIndex = row
          break
        case 'getRpTypeData':
          if (!row) return
          let { check } = row
          if (check.length <= 0) return
          let tableIndex = this.options.tableIndex
          this.$set(this.sourceOptions.list[tableIndex], 'rpTypeName', check[0].rpTypeName)
          this.$set(this.sourceOptions.list[tableIndex], 'rpTypeNo', check[0].rpTypeNo)
          this.$set(this.optionsourceOptionss.list[tableIndex], 'rpTypeId', check[0].rpTypeId)
          break
        default:
      }
    },
    countFun(list, nowWriteOffMoneyTotal) {
      let array = JSON.parse(JSON.stringify(list))
      let idx = null;
      for (const [i, v] of array.entries()) {
        nowWriteOffMoneyTotal = fcount([nowWriteOffMoneyTotal, v.payMoney], '-')
        if (nowWriteOffMoneyTotal === 0) {
          idx = i + 1;
          break;
        } else if (nowWriteOffMoneyTotal < 0) {
          idx = i;
          array[idx].payMoney = Math.abs(nowWriteOffMoneyTotal);
          break;
        }
      }
      array = array.slice(idx !== null ? idx : array.length)
      return array
    },
    filterDataFun(list) {
      let arrlist = list.map((item) => (
        {
          ...item,
          billDate: item.sourceBillDateStr,
        }
      ))
      return arrlist
    },
    // 提交保存
    async submitForm(isAdd) {
      this.isAdd = isAdd
      const valid = await this.$refs.form.validate()
      const isvalid = await this.$refs.formList.validate()
      if (valid && isvalid) {
        let form = {
          billNo: this.options.billNo,
          billId: this.options.billId,
          billDate: this.options.billDate
            ? this.options.billDate.split(' ')[0]
            : '',
          partnerId: this.options.partnerId,
          deptId: this.options.deptId,
          saleEmployeeId: this.options.saleEmployeeId,
          billRemark: this.options.billRemark,
          settlementTermId: this.options.settlementTermId,
          settlementDate: this.options.settlementDate,

          payDetails: '',
          otherRpDetails: this.sourceOptions.list,
          fileItems: this.options.fileItems,
        }
        if (this.options.list.length) {
          let list = this.options.list.filter(item => item.payModeNo && item.payMoney && item.bankAccountNo)
          form.payDetails = list
        }
        if (form.billId) {
          otherReceiveModifyBill(form).then((res) => {
            this.options = {
              ...this.options,
              billStatus: res.data.billStatus
            }
            // let arrlist = this.filterDataFun(res.data.arDetails)
            // this.sourceOptions.list = arrlist
            this.$message.success('修改成功')
            //新增
            if (isAdd) {
              this.reset()
              this.getBillno()
            }
            this.loading = false
            return true
          }).catch((err) => {
            this.loading = false
            return false
          })
        } else {
          otherReceiveAddBill(form).then((res) => {
            this.options = {
              ...this.options,
              billStatus: res.data.billStatus,
              billId: res.data.billId
            }
            // let arrlist = this.filterDataFun(res.data.arDetails)
            // this.sourceOptions.list = arrlist
            this.$message.success('新增成功')
            //新增
            if (isAdd) {
              this.reset()
              this.getBillno()
            }
            this.loading = false
            return true
          }).catch((err) => {
            this.loading = false
            return false
          })
        }
      } else {
        this.$message.error('请输入必填项!')
        return false
      }
      // this.$refs['form'].validate(async valid => {
      //   if (valid) {
      //   } 
      // })
    },
    //初始化数据
    reset() {
      //表格配置
      this.options = {
        ...this.options,
        settlementDate: undefined,
        billNo: undefined,
        billDate: undefined,
        partnerId: undefined,
        deptId: undefined,
        saleEmployeeId: undefined,
        accountsBalance: undefined,
        // discountMoney: undefined,
        settlementTermId: undefined,
        billStatus: '',
        billRemark: '',
        fileItems: [],
        billId: undefined
      }
      this.options.list = [{}],
        this.sourceOptions.list = [{}],
        this.resetForm('form')
    },
    // 获取订单编号
    async getBillno() {
      this.options.billNo = await getBillNo(190105)
      this.options.billDate = new Date()
        .toLocaleDateString()
        .replace(/\//g, '-')
    },
    async auditForm(type) {
      if ([2, '2'].includes(this.options.billStatus) && type == '审核') return this.$message.warning('此单据不是未审核的状态哦~')
      if (['审核', '反审核'].includes(type)) {
        await this.$confirm(`确定${type}该方案`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.loading = true
        if (type == '审核') await this.submitForm(false)
        const { data } = await otherReceiveModifyBillStatus({
          billIds: [this.options.billId],
          billStatus: type == '审核' ? 2 : 0
        })
        this.options = {
          ...this.options,
          billStatus: data.billStatus
        }
        const res = await getShouldReceiveSaleFunds({ partnerId: this.options.partnerId })
        this.options.accountsBalance = res.data.shouldSettleMoney
        this.$message.success(`${type}成功`)
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  overflow-y: scroll;
  //附件
  .accessoryUpload {
    margin: 6px 10px;
    min-height: 150px;
  }
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 250px;
}
.table {
  padding: 10px;
}
.leftContent {
  .text {
    width: 70px;
    font-size: 14px;
    line-height: 40px;
  }
  .el-icon-question {
    margin-top: 10px;
  }
  ::v-deep .el-input--medium {
    width: 140px;
  }
  ::v-deep .el-input__inner {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #ccc;
  }
}
</style>
