var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "wrap",
      attrs: { model: _vm.form, "label-width": "120px", rules: _vm.rules },
    },
    [
      _c("cardTitleCom", {
        staticStyle: { height: "100%" },
        attrs: { cardTitle: "社群码设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15" },
                  [
                    _c(
                      "el-steps",
                      {
                        staticStyle: { "margin-bottom": "50px" },
                        attrs: {
                          active: _vm.active,
                          simple: "",
                          "finish-status": "success",
                        },
                      },
                      [
                        _c("el-step", { attrs: { title: "基本设置" } }),
                        _c("el-step", { attrs: { title: "添加二维码" } }),
                        _c("el-step", {
                          attrs: {
                            title:
                              (_vm.form.caseDetailId ? "修改" : "创建") +
                              "成功",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "y-c" }, [
                      _vm.active == 0
                        ? _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "活码名称",
                                    prop: "caseCodeName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "inputWidth",
                                    attrs: {
                                      size: "mini",
                                      maxlength: "15",
                                      placeholder: "将显示在活动页面标题栏",
                                    },
                                    model: {
                                      value: _vm.form.caseCodeName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "caseCodeName", $$v)
                                      },
                                      expression: "form.caseCodeName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.caseType == 2
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "门店",
                                        prop: "caseShopId",
                                      },
                                    },
                                    [
                                      _c("SelectRemote", {
                                        staticStyle: { width: "250px" },
                                        attrs: {
                                          option: _vm.$select({
                                            key: "listShop",
                                          }).option,
                                        },
                                        model: {
                                          value: _vm.form.caseShopId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "caseShopId",
                                              $$v
                                            )
                                          },
                                          expression: "form.caseShopId",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注", prop: "remark" } },
                                [
                                  _c("el-input", {
                                    staticClass: "inputWidth",
                                    attrs: {
                                      size: "mini",
                                      maxlength: "15",
                                      placeholder: "活动备注信息，用户不可见",
                                    },
                                    model: {
                                      value: _vm.form.remark,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "remark", $$v)
                                      },
                                      expression: "form.remark",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "联系客服微信号",
                                    prop: "caseWeixinNo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "inputWidth",
                                    attrs: {
                                      size: "mini",
                                      maxlength: "15",
                                      placeholder:
                                        "异常⻚⾯，引导⽤户联系微信客服",
                                    },
                                    model: {
                                      value: _vm.form.caseWeixinNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "caseWeixinNo", $$v)
                                      },
                                      expression: "form.caseWeixinNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.active == 1
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "50px",
                                width: "100%",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-bottom": "20px" },
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.addCode },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.caseCodeType == 1
                                        ? "添加个人微信码"
                                        : "添加群二维码"
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loadingTable,
                                      expression: "loadingTable",
                                    },
                                  ],
                                  ref: "multipleTable",
                                  staticStyle: {
                                    width: "100%",
                                    height: "calc(100vh - 450px)",
                                    "max-height": "calc(100vh - 450px)",
                                  },
                                  attrs: {
                                    data: _vm.form.detailCodeList,
                                    "tooltip-effect": "dark",
                                    border: "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      type: "index",
                                      width: "80",
                                      label: "序号",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "qrCodeName",
                                      label:
                                        _vm.form.caseCodeType == 1
                                          ? "微信名称"
                                          : "群名称",
                                      align: "center",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "imageUrl",
                                      label:
                                        _vm.form.caseCodeType == 1
                                          ? "微信二维码"
                                          : "微信群二维码",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.commonImageResp
                                              ? _c("img", {
                                                  staticStyle: {
                                                    width: "60px",
                                                    height: "60px",
                                                  },
                                                  attrs: {
                                                    src: scope.row
                                                      .commonImageResp.imageUrl,
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm.form.caseCodeType == 1
                                    ? _c("el-table-column", {
                                        attrs: {
                                          prop: "qrCodeSwitchRate",
                                          label: "每日自动切换频率",
                                          align: "center",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.form.caseCodeType == 2
                                    ? _c("el-table-column", {
                                        attrs: {
                                          prop: "qrCodeValidDate",
                                          label: "二维码有效期",
                                          align: "center",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.form.caseCodeType == 2
                                    ? _c("el-table-column", {
                                        attrs: {
                                          prop: "qrCodeLimitNumber",
                                          label: "自动切换人数上限",
                                          align: "center",
                                        },
                                      })
                                    : _vm._e(),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      align: "center",
                                      prop: "controls",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editCode(
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("编辑")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delCode(
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.active == 2
                        ? _c(
                            "div",
                            { staticStyle: { "margin-bottom": "50px" } },
                            [
                              _c("el-result", {
                                attrs: {
                                  icon: "success",
                                  title:
                                    (_vm.form.caseCodeType == 1
                                      ? "个人"
                                      : "群") +
                                    "活码" +
                                    (_vm.form.caseDetailId ? "修改" : "创建") +
                                    "成功",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _vm.active === 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.lastStep },
                                },
                                [_vm._v("上一步")]
                              )
                            : _vm._e(),
                          _vm.active !== 2
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.nextStep },
                                },
                                [_vm._v("下一步")]
                              )
                            : _vm._e(),
                          _vm.active === 2
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.submitForm },
                                },
                                [_vm._v("完成")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: {
          title: _vm.title,
          width: 550,
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "dialogForm",
                    attrs: {
                      model: _vm.dialogForm,
                      rules: _vm.dialogRules,
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "x-x",
                        staticStyle: { "margin-left": "30px" },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticStyle: {
                              color: "#ff4949",
                              "margin-top": "10px",
                            },
                          },
                          [_vm._v("* ")]
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "上传二维码",
                              prop: "imageUrl",
                              "label-width": "88px",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "choosedImageItem x-fc",
                                  on: {
                                    click: function ($event) {
                                      return _vm.getOpenImg()
                                    },
                                  },
                                },
                                [
                                  !_vm.dialogForm.commonImageResp.imageUrl
                                    ? _c("i", {
                                        staticClass: "el-icon-plus",
                                        staticStyle: { "font-size": "32px" },
                                      })
                                    : _c("img", {
                                        staticClass: "thumbImage",
                                        attrs: {
                                          src: _vm.dialogForm.commonImageResp
                                            .imageUrl,
                                          alt: "社群图",
                                        },
                                      }),
                                  !_vm.disabled
                                    ? _c(
                                        "span",
                                        { staticClass: "modifyImage" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              (_vm.dialogForm.commonImageResp
                                                .imageUrl
                                                ? "更换"
                                                : "上传") + "图片"
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.dialogForm.commonImageResp.imageUrl &&
                                  !_vm.disabled
                                    ? _c("i", {
                                        staticClass: "el-icon-circle-close",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.delImg()
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c("div", { staticClass: "annotateGrey" }, [
                                _vm._v(
                                  "仅支持jpg、png两种格式，推荐600*600px尺寸，大小不超过1MB。"
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.form.caseCodeType == 1 ? "微信名称" : "群名称",
                          prop: "qrCodeName",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            placeholder:
                              "请输入" +
                              (_vm.form.caseCodeType == 1 ? "微信" : "群") +
                              "名称",
                            maxlength: "15",
                            size: "mini",
                          },
                          model: {
                            value: _vm.dialogForm.qrCodeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.dialogForm, "qrCodeName", $$v)
                            },
                            expression: "dialogForm.qrCodeName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.form.caseCodeType == 2
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "二维码有效期",
                              prop: "qrCodeValidDate",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择二维码有效期",
                                "value-format": "yyyy-MM-dd",
                                size: "mini",
                                "picker-options": _vm.forbiddenTime,
                              },
                              model: {
                                value: _vm.dialogForm.qrCodeValidDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dialogForm,
                                    "qrCodeValidDate",
                                    $$v
                                  )
                                },
                                expression: "dialogForm.qrCodeValidDate",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "annotateGrey",
                                staticStyle: { "margin-top": "10px" },
                              },
                              [
                                _vm._v(
                                  " 有限期在群二维码图片底部有小字提示，如7天内群未加满200人，请及时更新二维码，避免过期无法进群。"
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.caseCodeType == 1
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "设置切换频率",
                              prop: "qrCodeSwitchRate",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                min: 1,
                                max: 200,
                              },
                              model: {
                                value: _vm.dialogForm.qrCodeSwitchRate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dialogForm,
                                    "qrCodeSwitchRate",
                                    $$v
                                  )
                                },
                                expression: "dialogForm.qrCodeSwitchRate",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "annotateGrey",
                                staticStyle: { "margin-top": "10px" },
                              },
                              [
                                _vm._v(
                                  " 每日长按识别同一个二维码人数达到上限后，自动切换到下一个二维码，第二天会重新从第一个码开始投放。并且由于微信限制每个微信号每天最多可被500人添加，所以建议你根据现有人数填写200以内的值。"
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "设置人数上限",
                              prop: "qrCodeLimitNumber",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                "controls-position": "right",
                                min: 1,
                                max: 200,
                              },
                              model: {
                                value: _vm.dialogForm.qrCodeLimitNumber,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dialogForm,
                                    "qrCodeLimitNumber",
                                    $$v
                                  )
                                },
                                expression: "dialogForm.qrCodeLimitNumber",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "annotateGrey",
                                staticStyle: { "margin-top": "10px" },
                              },
                              [
                                _vm._v(
                                  " 每多少人长按识别了同一个二维码，自动切换到下一个二维码。由于微信群限制200人内可自动进群，所以建议你根据群现有人数填写200以内的值。"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.submitDialogForm },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.showDialog,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }