<!-- 应付账款预警表 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  apEarlyWarningReportSubTotal,
  apEarlyWarningReportByPartnerId,
  financeWarningSumApEarlyWarningReport,
} from "@/api/arap/index";
import { getUid } from "@/utils";
export default {
  name: "arAlertDetail",
  components: { TablePage },
  data() {
    return {
      options: {
        uuid: "0c5cfa08-1f9c-1e06-532e-d7aab2533f63",
        mutiSelect: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        treeTableLoadOptions: {
          lazy: true,
          partnerId: 'partnerId',
          rowKey: "rowKey",
          loadApi: apEarlyWarningReportByPartnerId
        },
        getListApi: apEarlyWarningReportSubTotal,
        getSummaryApi: financeWarningSumApEarlyWarningReport,
        getDataKey: (res) => {
          let rows = res.rows.map((x, index) => ({ ...x, parentIndex: index + 1, hasChildren: true,rowKey:getUid() }))
          return {
            list: rows,
            total: res.total,
          };
        },
        exportOption: {
          show: true,
          exportApi: "exportApEarlyWarningReport",
          exportName: "应付账款预警表",
          timeout: 60 * 5 * 1000,
        },
        body: {},
        // listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "rowKey",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          this.$select({
            key: "listSupplier",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSimpleQuery",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSaleEmployee",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "parentIndex",
            label: "序号",
            align: "left",
            minWidth: 100,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 100,
          },
          {
            prop: "billTypeName",
            label: "单据类型",
            minWidth: 120,
          },
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 180,
          },
          {
            prop: "partnerNo",
            label: "供应商编号",
            formatter: (v, row) =>
              row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : "",
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "供应商名称",
            formatter: (v, row) =>
              row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : "",
            minWidth: 180,
          },
          {
            prop: "deptNo",
            label: "部门编码",
            formatter: (v, row) => (row.deptInfo?.deptNo ? row.deptInfo?.deptNo : ""),
            minWidth: 120,
          },
          {
            prop: "deptName",
            label: "部门名称",
            formatter: (v, row) => (row.deptInfo?.deptName ? row.deptInfo?.deptName : ""),
            minWidth: 120,
          },
          {
            prop: "employeeNo",
            label: "业务员编码",
            formatter: (v, row) =>
              row.saleEmployeeInfo?.employeeNo ? row.saleEmployeeInfo?.employeeNo : "",
            minWidth: 120,
          },
          {
            prop: "employeeName",
            label: "业务员名称",
            formatter: (v, row) =>
              row.saleEmployeeInfo?.employeeName
                ? row.saleEmployeeInfo?.employeeName
                : "",
            minWidth: 120,
          },
          {
            prop: "billMoney",
            label: "应付金额",
            minWidth: 120,
          },
          {
            prop: "settleMoney",
            label: "已核销金额",
            minWidth: 120,
          },
          {
            prop: "notSettleMoney",
            label: "未核销金额",
            minWidth: 120,
          },
          {
            prop: "settlementDate",
            label: "结算日期",
            minWidth: 100,
          },
          {
            prop: "overDays",
            label: "超期天数",
            minWidth: 100,
          },
        ],
        list: [],
        summary: ["billMoney", "settleMoney", "notSettleMoney"],
      },
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
