import request from '@/utils/request'

// 列表
export function listCostModify (params) {
  return request({
    url: '/api/system/store/bill/cost/modify/listCostModify',
    method: 'get',
    params
  })
}

// 新增
export function saveCostModify (data) {
  return request({
    url: '/api/system/store/bill/cost/modify/addCostModify',
    method: 'post',
    data
  })
}

// 修改
export function updateCostModify (data) {
  return request({
    url: '/api/system/store/bill/cost/modify/modifyCostModify',
    method: 'post',
    data
  })
}

// 详情
export function detailCostModify (billId) {
  return request({
    url: `/api/system/store/bill/cost/modify/getCostModifyInfo?billId=${billId}`,
  })
}

// 更新状态
export function updateStatusCostModify (data) {
  return request({
    url: '/api/system/store/bill/cost/modify/modifyBillStatus',
    method: 'post',
    data
  })
}

// 获取商品数据 (根据仓库)
export function getGoods (params) {
  return request({
    url: '/api/system/goods/info/listStoreGoods',
    method: 'get',
    params
  })
}