var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cropper-wrapper" },
    [
      [
        _c(
          "el-upload",
          {
            ref: "upload",
            staticClass: "upload-demo",
            class: { hide: _vm.fileList.length >= _vm.limit },
            attrs: {
              action: "",
              drag: "",
              "on-change": _vm.handleChangeUpload,
              disabled: _vm.disabled,
              "file-list": _vm.fileList,
              "list-type": "picture-card",
              limit: _vm.limit,
              "on-preview": _vm.handlePreview,
              "on-remove": _vm.handleRemove,
              "on-exceed": _vm.handleExceed,
            },
          },
          [
            _c("i", { staticClass: "el-icon-plus upload-demo-icon" }),
            _c(
              "div",
              {
                staticClass: "el-upload__tip tip",
                attrs: { slot: "tip" },
                slot: "tip",
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      "最多上传" +
                        _vm.limit +
                        "张," +
                        _vm.explain +
                        _vm.otherTip
                    ) +
                    " "
                ),
              ]
            ),
          ]
        ),
      ],
      _c(
        "el-dialog",
        {
          staticClass: "crop-dialog",
          attrs: {
            title: "图片剪裁",
            visible: _vm.dialogVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "cropper-content" }, [
            _c(
              "div",
              {
                staticClass: "cropper",
                staticStyle: { "text-align": "center" },
              },
              [
                _c("VueCropper", {
                  ref: "cropper",
                  attrs: {
                    img: _vm.option.img,
                    "output-size": _vm.option.size,
                    "output-type": _vm.option.outputType,
                    info: true,
                    full: _vm.option.full,
                    "can-move": _vm.option.canMove,
                    "can-move-box": _vm.option.canMoveBox,
                    original: _vm.option.original,
                    "auto-crop": _vm.option.autoCrop,
                    fixed: _vm.option.fixed,
                    "fixed-number": _vm.option.fixedNumber,
                    "center-box": _vm.option.centerBox,
                    "info-true": _vm.option.infoTrue,
                    "fixed-box": _vm.option.fixedBox,
                    "auto-crop-width": _vm.option.autoCropWidth,
                    "auto-crop-height": _vm.option.autoCropHeight,
                  },
                  on: { cropMoving: _vm.cropMoving },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "action-box" },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    action: "",
                    "show-file-list": false,
                    "on-change": _vm.handleChangeUpload,
                  },
                },
                [
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v("更换图片"),
                  ]),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.rotateLeftHandle },
                },
                [_vm._v(" 左旋转 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.rotateRightHandle },
                },
                [_vm._v(" 右旋转 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.changeScaleHandle(1)
                    },
                  },
                },
                [_vm._v(" 放大 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.changeScaleHandle(-1)
                    },
                  },
                },
                [_vm._v(" 缩小 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.option.fixed = !_vm.option.fixed
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.option.fixed ? "固定比例" : "自由比例") +
                      " "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.downloadHandle("blob")
                    },
                  },
                },
                [_vm._v(" 下载 ")]
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { height: "30px" } }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.finish },
                },
                [_vm._v(" 确认 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisiblePrw },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisiblePrw = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }