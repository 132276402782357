import request from '@/utils/request'

// 获取微信社群活动详情
export function setGetDetail(data) {
  return request({
    url: '/api/system/mall/marketing/wx/community/getDetail',
    method: 'post',
    data
  })
}

// 修改微信社群活动
export function setUpdate(data) {
  return request({
    url: '/api/system/mall/marketing/wx/community/update',
    method: 'post',
    data
  })
}

// 微信社群活动活码分页列表
export function communityCodeList(params) {
  return request({
    url: '/api/system/mall/marketing/wx/community/detail/list',
    method: 'get',
    params
  })
}

// 删除微信社群活动活码
export function communityCodeDelete(data) {
  return request({
    url: '/api/system/mall/marketing/wx/community/detail/delete',
    method: 'delete',
    data
  })
}

// 新增
export function communityCodeSave(data) {
  return request({
    url: '/api/system/mall/marketing/wx/community/detail/save',
    method: 'post',
    data
  })
}

// 获取微信社群活动活码详情
export function communityCodeDetail(caseDetailId) {
  return request({
    url: '/api/system/mall/marketing/wx/community/detail/getDetail?caseDetailId=' + caseDetailId,
    method: 'get',
  })
}

// 修改微信社群活动活码
export function communityCodeUpdate(data) {
  return request({
    url: '/api/system/mall/marketing/wx/community/detail/update',
    method: 'put',
    data
  })
}