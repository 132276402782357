var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warp padT15" },
    [
      _c("div", { staticClass: "typeList" }, [
        _c("div", { staticClass: "title" }, [_vm._v("选择模版")]),
        _c(
          "div",
          { staticClass: "x-w" },
          _vm._l(_vm.typeList, function (item, index) {
            return _c("div", { key: index, staticClass: "y-c item cursorP" }, [
              _c(
                "div",
                {
                  staticClass: "iconBox x-c",
                  class: [_vm.data.style.type == item.type ? "iconBox2" : ""],
                  on: {
                    click: function ($event) {
                      return _vm.clickType(item.type)
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "20px", height: "20px" },
                    attrs: { src: item.name },
                  }),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "marT10",
                  style: {
                    color:
                      _vm.data.style.type == item.type ? "#1458cc" : "#333333",
                  },
                },
                [_vm._v(_vm._s(item.title))]
              ),
            ])
          }),
          0
        ),
      ]),
      _c("el-divider"),
      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
        _c("span", [_vm._v("魔方布局：")]),
        _c("span", { staticClass: "annotateGrey" }, [
          _vm._v(_vm._s(_vm.rubikCubeNotes)),
        ]),
      ]),
      _vm.data.style.type != 8
        ? _c("CubeMatrix2", {
            attrs: { layout: _vm.data.style.type },
            on: { activeChange: _vm.activeChange },
            model: {
              value: _vm.data.style.selectedList,
              callback: function ($$v) {
                _vm.$set(_vm.data.style, "selectedList", $$v)
              },
              expression: "data.style.selectedList",
            },
          })
        : _c("CubeMatrix", {
            attrs: { densityCom: _vm.data.style.density },
            on: {
              templateMsg: _vm.templateMsg,
              onChangeDensity: _vm.onChangeDensity,
              activeChange: _vm.activeChange,
            },
            model: {
              value: _vm.data.style.selectedList,
              callback: function ($$v) {
                _vm.$set(_vm.data.style, "selectedList", $$v)
              },
              expression: "data.style.selectedList",
            },
          }),
      _vm.active >= 0 && _vm.data.style.selectedList.length > 0
        ? _c("div", { staticClass: "moreSettings x-x" }, [
            _c(
              "div",
              {
                staticClass: "choosedImageItem x-fc",
                on: {
                  click: function ($event) {
                    return _vm.getOpenImg()
                  },
                },
              },
              [
                !_vm.data.style.selectedList[_vm.active].imageUrl
                  ? _c("i", {
                      staticClass: "el-icon-plus",
                      staticStyle: { "font-size": "32px" },
                    })
                  : _c("img", {
                      staticClass: "thumbImage",
                      attrs: {
                        src: _vm.data.style.selectedList[_vm.active].imageUrl,
                        alt: "广告图",
                      },
                    }),
                !_vm.disabled
                  ? _c("span", { staticClass: "modifyImage" }, [
                      _vm._v(
                        _vm._s(
                          (_vm.data.style.selectedList[_vm.active].imageUrl
                            ? "更换"
                            : "上传") + "图片"
                        )
                      ),
                    ])
                  : _vm._e(),
                _vm.data.style.selectedList[_vm.active].imageUrl
                  ? _c("i", {
                      staticClass: "el-icon-circle-close",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.delImg()
                        },
                      },
                    })
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              { staticStyle: { "margin-right": "20px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        _vm.openLink = true
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        (_vm.data.style.selectedList[_vm.active].linkPath
                          ? "修改"
                          : "选择") + "链接"
                      )
                    ),
                  ]
                ),
                _c("div", { staticClass: "x-f" }, [
                  _c("span", { staticStyle: { "margin-right": "10px" } }, [
                    _vm._v(
                      _vm._s(
                        "链接：" +
                          (_vm.data.style.selectedList[_vm.active]
                            .linkPathName || "")
                      )
                    ),
                  ]),
                  _vm.data.style.selectedList[_vm.active].linkPath
                    ? _c("span", {
                        staticClass: "el-icon-close operateDel",
                        on: {
                          click: function ($event) {
                            return _vm.delLink()
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "el-form",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "label-width": "60px" },
        },
        [
          _c("el-form-item", { attrs: { label: "上边距" } }, [
            _c(
              "div",
              { staticClass: "x-f" },
              [
                _c("el-slider", {
                  staticStyle: { width: "300px" },
                  attrs: { max: 200, "show-input": "" },
                  on: { change: _vm.templateMsg },
                  model: {
                    value: _vm.data.style.marginTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.style, "marginTop", $$v)
                    },
                    expression: "data.style.marginTop",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("el-form-item", { attrs: { label: "下边距" } }, [
            _c(
              "div",
              { staticClass: "x-f" },
              [
                _c("el-slider", {
                  staticStyle: { width: "300px" },
                  attrs: { max: 200, "show-input": "" },
                  on: { change: _vm.templateMsg },
                  model: {
                    value: _vm.data.style.marginBottom,
                    callback: function ($$v) {
                      _vm.$set(_vm.data.style, "marginBottom", $$v)
                    },
                    expression: "data.style.marginBottom",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.showDialog,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectLink", {
        attrs: { OpenLink: _vm.openLink },
        on: {
          "update:OpenLink": function ($event) {
            _vm.openLink = $event
          },
          "update:open-link": function ($event) {
            _vm.openLink = $event
          },
          selectLinkObj: _vm.selectLinkObj,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }