var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("TablePage", {
        ref: "tablePage",
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
      _c("Dialog", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
      _c("c-dialog", {
        attrs: {
          title: "生产完成",
          width: 1000,
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    { staticClass: "left", staticStyle: { width: "600px" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%", height: "500px" },
                          attrs: { data: _vm.produceList, border: "" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              width: "50",
                              fixed: "",
                              label: "选择",
                              align: "center",
                              "class-name": "radioSelect",
                              "header-align": "center",
                              type: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { label: $index },
                                        model: {
                                          value: _vm.produceIndex,
                                          callback: function ($$v) {
                                            _vm.produceIndex = $$v
                                          },
                                          expression: "produceIndex",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s("") + " ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsNo",
                              label: "商品编码",
                              width: "120",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsName",
                              label: "商品名称",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "barcode",
                              label: "商品条码",
                              width: "120",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "unitQty",
                              label: "待产数量",
                              width: "100",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "orderQty",
                              label: "订货数量",
                              width: "100",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right", staticStyle: { width: "400px" } },
                    [
                      _c(
                        "div",
                        { staticClass: "workerBox" },
                        _vm._l(_vm.workerList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "li",
                              class: _vm.workerIndex == index ? "activeLi" : "",
                              on: {
                                click: function ($event) {
                                  return _vm.workerClick(index)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.workerName) + " ")]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "ruleForm",
                              attrs: { model: _vm.form, rules: _vm.rules },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "合格数量",
                                    "label-width": _vm.formLabelWidth,
                                    prop: "freshlyBakedFinishQty",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "brandInput",
                                    attrs: {
                                      maxlength: "20",
                                      autocomplete: "off",
                                      placeholder: "请输入合格数量",
                                    },
                                    model: {
                                      value:
                                        _vm.produceList[_vm.produceIndex]
                                          .freshlyBakedFinishQty,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.produceList[_vm.produceIndex],
                                          "freshlyBakedFinishQty",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "produceList[produceIndex].freshlyBakedFinishQty",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "报损数量",
                                    "label-width": _vm.formLabelWidth,
                                    prop: "freshlyBakedScrapQty",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "brandInput",
                                    attrs: {
                                      maxlength: "40",
                                      autocomplete: "off",
                                      placeholder: "请输入报损数量",
                                    },
                                    model: {
                                      value:
                                        _vm.produceList[_vm.produceIndex]
                                          .freshlyBakedScrapQty,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.produceList[_vm.produceIndex],
                                          "freshlyBakedScrapQty",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "produceList[produceIndex].freshlyBakedScrapQty",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "生产数量",
                                    "label-width": _vm.formLabelWidth,
                                    prop: "",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.produceList[_vm.produceIndex]
                                          .freshlyBakedFinishQty +
                                          _vm.produceList[_vm.produceIndex]
                                            .freshlyBakedScrapQty
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "待产数量",
                                    "label-width": _vm.formLabelWidth,
                                    prop: "unitQty",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.produceList[_vm.produceIndex]
                                          .unitQty
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.addBrand(true)
                      },
                    },
                  },
                  [_vm._v("不再生产")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.addBrand(false)
                      },
                    },
                  },
                  [_vm._v("生产完成")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: {
          title:
            _vm.options.body.freshlyBakedProduceStatus == 1
              ? "订货详情"
              : "调出详情",
          width: 1100,
          showDialog: _vm.openOrderGoodsDetail,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.openOrderGoodsDetail = $event
          },
          "update:show-dialog": function ($event) {
            _vm.openOrderGoodsDetail = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%", height: "500px" },
                        attrs: { data: _vm.orderGoodsDetailList, border: "" },
                      },
                      _vm._l(
                        _vm.orderGoodsDetailColumns[
                          Number(_vm.options.body.freshlyBakedProduceStatus)
                        ],
                        function (item) {
                          return _c("el-table-column", {
                            key: item.prop,
                            attrs: {
                              prop: item.prop,
                              label: item.label,
                              width: item.width,
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.openOrderGoodsDetail = false
                      },
                    },
                  },
                  [_vm._v("关 闭")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: {
          title: "调至门店",
          width: 1250,
          showDialog: _vm.openTransferShop,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.openTransferShop = $event
          },
          "update:show-dialog": function ($event) {
            _vm.openTransferShop = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.dialogLoading,
                        expression: "dialogLoading",
                      },
                    ],
                    staticClass: "content",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "780px", height: "500px" } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "780px", height: "100%" },
                            attrs: { data: _vm.transferList, border: "" },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                width: "50",
                                fixed: "",
                                label: "选择",
                                align: "center",
                                "class-name": "radioSelect",
                                "header-align": "center",
                                type: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: $index },
                                          model: {
                                            value: _vm.transferIndex,
                                            callback: function ($$v) {
                                              _vm.transferIndex = $$v
                                            },
                                            expression: "transferIndex",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s("") + " ")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "goodsNo",
                                label: "商品编码",
                                width: "120",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "goodsName",
                                label: "商品名称",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "barcode",
                                label: "商品条码",
                                width: "120",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "orderQty",
                                label: "订货数量",
                                width: "100",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "freshlyBakedFinishQty",
                                label: "合格数量",
                                width: "100",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "transferQty",
                                label: "调拨数量",
                                width: "100",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { width: "420px", height: "500px" } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "420px", height: "100%" },
                            attrs: {
                              data:
                                _vm.transferList[_vm.transferIndex] &&
                                _vm.transferList[_vm.transferIndex].orderDetail
                                  ? _vm.transferList[_vm.transferIndex]
                                      .orderDetail
                                  : [],
                              border: "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "shopName",
                                label: "门店名称",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "orderQty",
                                label: "订货数",
                                width: "120",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "transferQty",
                                label: "调拨数",
                                width: "120",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    var columnObj = ref.column
                                    return [
                                      _c("el-input", {
                                        attrs: { size: "mini" },
                                        model: {
                                          value: row.transferQty,
                                          callback: function ($$v) {
                                            _vm.$set(row, "transferQty", $$v)
                                          },
                                          expression: "row.transferQty",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.openTransferShop = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.handleEvent("clickTransferShopBtn")
                      },
                    },
                  },
                  [_vm._v("调至门店")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }