var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["mofang", "mofang-type" + _vm.newData.style.type],
      style: _vm.edit
        ? ""
        : "margin-top:" +
          _vm.newData.style.marT +
          "px;margin-bottom:" +
          _vm.newData.style.marB +
          "px;margin-left:" +
          _vm.newData.style.marL +
          "px;margin-right:" +
          _vm.newData.style.marR +
          "px;width: " +
          (_vm.width - _vm.newData.style.marL - _vm.newData.style.marR) +
          "px;",
    },
    [
      _vm.newData.style.type === 1
        ? _vm._l(_vm.newData.imgList, function (item, index) {
            return _c(
              "el-image",
              {
                key: index,
                style: item.imgStyle,
                attrs: { src: item.image, mode: "scaleToFill" },
                on: {
                  click: function ($event) {
                    return _vm.toClick(index, item)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "x-c",
                    staticStyle: { height: "100%" },
                    attrs: { slot: "error" },
                    on: {
                      click: function ($event) {
                        return _vm.toClick(index, item)
                      },
                    },
                    slot: "error",
                  },
                  [
                    _vm.edit
                      ? _c("p", { staticClass: "text" }, [
                          _vm._v("建议宽度 375px"),
                        ])
                      : _c("i", {
                          staticClass: "el-icon-picture-outline",
                          staticStyle: {
                            "font-size": "40px",
                            color: "#ababab",
                          },
                        }),
                  ]
                ),
              ]
            )
          })
        : _vm.newData.style.type === 2
        ? [
            _vm._l(_vm.newData.imgList.slice(0, 1), function (item, index) {
              return _c(
                "el-image",
                {
                  key: index,
                  style:
                    item.imgStyle +
                    (_vm.edit
                      ? ""
                      : "argin-right: " + _vm.newData.style.marN / 2),
                  attrs: { src: item.image, mode: "scaleToFill" },
                  on: {
                    click: function ($event) {
                      return _vm.toClick(index, item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "x-c",
                      staticStyle: { height: "100%" },
                      attrs: { slot: "error" },
                      on: {
                        click: function ($event) {
                          return _vm.toClick(index, item)
                        },
                      },
                      slot: "error",
                    },
                    [
                      _vm.edit
                        ? _c("p", { staticClass: "text" }, [
                            _vm._v("建议宽高 375 * 375px"),
                          ])
                        : _c("i", {
                            staticClass: "el-icon-picture-outline",
                            staticStyle: {
                              "font-size": "40px",
                              color: "#ababab",
                            },
                          }),
                    ]
                  ),
                ]
              )
            }),
            _c(
              "div",
              {
                style:
                  _vm.newData.imgList[0].imgStyle +
                  (_vm.edit
                    ? ""
                    : "margin-left: " + _vm.newData.style.marN / 2 + "px;"),
              },
              _vm._l(_vm.newData.imgList.slice(1), function (item, index) {
                return _c(
                  "el-image",
                  {
                    key: index,
                    style: item.imgStyle,
                    attrs: { src: item.image, mode: "scaleToFill" },
                    on: {
                      click: function ($event) {
                        return _vm.toClick(index + 1, item)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "x-c",
                        staticStyle: { height: "100%" },
                        attrs: { slot: "error" },
                        on: {
                          click: function ($event) {
                            return _vm.toClick(index + 1, item)
                          },
                        },
                        slot: "error",
                      },
                      [
                        _vm.edit
                          ? _c("p", { staticClass: "text" }, [
                              _vm._v("建议宽高 375 * 375px"),
                            ])
                          : _c("i", {
                              staticClass: "el-icon-picture-outline",
                              staticStyle: {
                                "font-size": "40px",
                                color: "#ababab",
                              },
                            }),
                      ]
                    ),
                  ]
                )
              }),
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }