<template>
    <el-form-item prop="videoPath">
        <el-upload
            :action="url"
            name="video"
            ref="video"
            class="upload-demo"
            v-model="form.videoPath"
            drag
            :multiple="false"
            :before-upload="beforeUploadVideo"
            :on-success="handleUploadSuccess"
        >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
            </div>
            <div slot="tip" class="el-upload__tip">注：视频格式仅支持mp4、ogg、flv、avi、wmv、rmvb，且大小不能超过20MB</div>
        </el-upload>

        <el-progress
            v-show="progressFlag"
            :percentage="loadProgress"
        ></el-progress>
    </el-form-item>
</template>

<script>
export default {
    name: 'index',
    data(){
        return {
            form:{
                videoPath: ''
            },
            progressFlag: false,
            loadProgress: 0,
            videoTypeList: [
                "video/mp4",
                "video/ogg",
                "video/flv",
                "video/avi",
                "video/wmv",
                "video/rmvb",
            ],
            url: process.env.VUE_APP_BASE_API + '/api/open/oss/video/upload',
        }
    },
    methods:{
        // 上传成功
        handleUploadSuccess(res){
            console.log('上传视频成功', res)
            this.$emit('getFileItems', res.data)
        },
        beforeUploadVideo(file) {
            console.log('上传前', file)
            const isLt30M = file.size / 1024 / 1024 < 20;
            if (this.videoTypeList.indexOf(file.type) == -1) {
                this.$message.error("请上传正确的视频格式");
                return false;
            }
            if (!isLt30M) {
                this.$message.error("上传视频大小不能超过20MB哦!");
                return false;
            }
        },

    }
}
</script>

<style scoped>

</style>
