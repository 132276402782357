<template>
  <div
    :style="{paddingTop: `${data.style.pagePaddingTop? data.style.pagePaddingTop : '0'}px`, paddingRight: `${data.style.pagePaddingRight? data.style.pagePaddingRight : '0'}px`, paddingBottom: `${data.style.pagePaddingBottom? data.style.pagePaddingBottom : '0'}px`, paddingLeft: `${data.style.pagePaddingLeft? data.style.pagePaddingLeft : '0'}px`}">
    <video ref="videoElement" muted :loop='data.style.loop == undefined ? true : data.style.loop' :src="data.style.videoUrl" width="100%"
      :style="{borderRadius: data.style.chamfer == 'circle' ? '10px' : ''}" :autoplay="data.style.voluntarilyPlay"
      :poster="data.style.videoSource != 'none' ? data.style.coverImg : ''" preload @loadedmetadata='loadedmetadata'
      :controls='false'>
    </video>
  </div>
</template>

<script>
export default {
  name: 'videoTmp',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
    }
  },
  watch: {
    // 监听props中的data变化
    data: {
      handler(newVal, oldVal) {
        // 如果data变化，则重置video元素
       this.$nextTick(()=>{
        this.resetVideo();
       })
      },
      deep: true, // 深度监听对象变化
      immediate: true // 组件加载时立即执行一次
    }
  },
  methods: {
    loadedmetadata(e) {
      console.log('视频组件22', e)
      this.data.style.height = e.target.videoHeight
      this.data.style.width = e.target.videoWidth
      this.$emit('setTemp', this.data)
    },
    resetVideo() {
      // 获取video元素
      const videoElement = this.$refs.videoElement;
      // 暂停视频
      videoElement.pause();
      // 加载新的视频源（如果需要）
      videoElement.load();
      // 如果需要，可以在这里设置currentTime为0来重置播放位置
      videoElement.currentTime = 0;
    }
  },
}
</script>

<style scoped>
</style>
