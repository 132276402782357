<template>
  <div class="wrap" v-loading="loading" id="wrap" ref="wrap">
    <el-form ref="form" :model="options" :rules="rules" label-width="106px">
      <topOperatingButton
        :isExamineBtn="![2 , '2'].includes(options.billStatus)"
        :showAudit="isShowAudit"
        :isStopBtn="false"
        :disabled= 'disableOperate'
        id="topOperatingButton"
        ref="topOperatingButton"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditForm"
        @getQuit="getQuit"
      >
      </topOperatingButton>
      <cardTitleCom cardTitle="客户信息" class="options">
        <template slot="cardContent">
          <div class="table">
            <EditTable :options="options" @handleEvent="handleEvent" />
          </div>
        </template>
      </cardTitleCom>
    </el-form>
  </div>
</template>

<script>
import {
  addInitialAp,
  modifyInitialAp,
  modifyBillStatusAp,
  getInitialDataPartnerApByBillId,
} from '@/api/system/initialData/ar';

import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import EditTable from '@/components/editTable' //可编辑表格
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import { getBillNo } from '@/api/codeRule'
import { fcount } from '@/utils'
export default {
  name: 'arDetail',
  components: {
    topOperatingButton,
    EditTable,
    cardTitleCom,
  },
  watch: {
    'options.billStatus': {
      handler (val) {
          this.disableOperate = ['2', '3', '4', 2, 3, 4].includes(val)
          this.isShowAudit = ['', 4, '4'].includes(val)
          this.options.columns = this.options.columns.map(v => ({...v, disabled: this.disableOperate}))
      },
      immediate: true
    },
  },
  data () {
    return {
      isSubmit: false,
      disableOperate: false,
      isShowAudit: '',
      
      loading: false, //遮罩层
      //表格配置
      options: {
        billNo: undefined,
        billId: undefined,
        billStatus: ''  ,
        tableIndex: 0,
        retainColumn: true,
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [{}],
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        tableHeight: 720, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: false, //不显示表格增加
        isNoDelBtn: false, //不显示表格删除
        hideTotal: true,
        columns: [
          {
            prop: 'billNo',
            label: '单据编号',
            width: 160,
            align: 'center',
          },
          {
            prop: 'partnerNo',
            label: '供应商',
            type: 'remoteSelect',
            // click: 'clickAccount',
            Width: 200,
            align: 'center',
            option: this.$select({
              key: 'listSupplier',
              option: {
                option: {
                  label: 'partnerName',
                  value: 'partnerNo',
                  modal: 'partnerId',
                  change: (propValue, row) => {
                    let tableIndex = this.options.tableIndex
                    console.log(row, 'row')
                    if (row && this.options.tableIndex != undefined) {
                      this.$set(this.options.list[tableIndex], 'partnerId', row.partnerId)
                      this.$set(this.options.list[tableIndex], 'partnerNo', row.partnerNo)
                      this.$set(this.options.list[tableIndex], 'partnerName', row.partnerName)
                    } 
                  },
                  // tableChange: (propValue, row) => {
                  //   this.handleEvent('partnerData', row)
                  // },
                  // buttons: [
                  //   {
                  //     type: 'more',
                  //     option: {
                  //       title: '选择客户',
                  //       width: 1250,
                  //       type: 'TreeAndTable',
                  //       formData: {
                  //         ...this.$dialog({ key: 'customer' }),
                  //         table: {
                  //           ...this.$dialog({ key: 'customer' }).table,
                  //           mutiSelect: true
                  //         }
                  //       }
                  //     }
                  //   }
                  // ]
                }
              }
            }).option,
            disabled: false,
            rules: true,
          },
          {
            prop: 'deptId',
            label: '部门',
            type: 'localSelect',
            Width: 200,
            align: 'center',
            option: this.$select({
              key: 'listSimpleQuery',
              option: {
                option: {
                  change: (propValue, row) => {
                    let tableIndex = this.options.tableIndex
                    if (row && this.options.tableIndex != undefined) {
                      this.$set(this.options.list[tableIndex], 'deptName', row.deptName)
                      this.$set(this.options.list[tableIndex], 'deptNo', row.deptNo)
                      this.$set(this.options.list[tableIndex], 'deptId', row.deptId)
                    } 
                  },
                }
              }
            }).option,
            disabled: false,
            rules: true
          },
          {
            prop: 'employeeNo',
            label: '采购员',
            type: 'remoteSelect',
            Width: 200,
            align: 'center',
            option: this.$select({
              key: 'listPurEmployee',
              option: {
                option: {
                  label: 'employeeName',
                  value: 'employeeNo',
                  modal: 'employeeId',
                  change: (propValue, row) => {
                    let tableIndex = this.options.tableIndex
                    console.log(row, 'row')
                    if (row && this.options.tableIndex != undefined) {
                      console.log(row.employeeName, 'row.employeeName');
                      this.$set(this.options.list[tableIndex], 'employeeName', row.employeeName)
                      this.$set(this.options.list[tableIndex], 'employeeNo', row.employeeNo)
                      this.$set(this.options.list[tableIndex], 'purEmployeeId', row.employeeId)
                      this.$set(this.options.list[tableIndex], 'employeeId', row.employeeId)
                    } 
                  },
                }
              }
            }).option,
            disabled: false,
            rules: true,
          },
          {
            prop: 'apAmount',
            label: '应付金额',
            width: 120,
            align: 'center',
            type: 'input',
            input: 'apAmount',
            disabled: false,
            rules: true,
            isMinus: false,
          },
          {
            prop: 'preApAmount',
            label: '预付金额',
            width: 120,
            align: 'center',
            type: 'input',
            input: 'preApAmount',
            disabled: false,
            rules: true,
            isMinus: false,
          },
          {
            prop: 'openAmount',
            label: '期初余额',
            width: 120,
            align: 'center',
          },
          {
            prop: 'bizDate',
            label: '业务日期',
            width: 160,
            align: 'center',
            rules: true,
            type: 'selectTime',
            disabled: false,
          },
          {
            prop: 'settleDate',
            label: '结算日期',
            width: 160,
            align: 'center',
            rules: true,
            type: 'selectTime',
            disabled: false,
          },
        ],
        summary: ['payMoney'],
      },
      //弹窗配置
      dialogOptions: {
        title: '选择',
        width: 1250,
        show: false,
        type: 'TreeAndTable'
      },
      rules: {
        billNo: [
          {
            required: true,
            message: '请输入方案编号',
            trigger: ['blur', 'change']
          }
        ],
      },
    }
  },
  activated () {
    this.getDetail()
  },
  mounted () {
    this.getDetail()
  },
  beforeDestroy () {
  },
  created () {
  },
  computed: {},
  methods: {
 //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/goods/initialData/ap" });
    },
    //获取详情
    async getDetail () {
      this.reset()
      if (this.$route.query.type === 'Update' && this.$route.query.billId) {
        this.loading = true
        try {
          const { data } = await getInitialDataPartnerApByBillId({billId: this.$route.query.billId})
          let list = [
            {
              partnerId: data.partnerInfo.partnerId,
              partnerName: data.partnerInfo.partnerName,
              partnerNo: data.partnerInfo.partnerNo,
              
              deptName: data.deptInfo.deptName,
              deptId: data.deptInfo.deptId,
              deptNo: data.deptInfo.deptNo,
              billId: data.billId,
              billNo: data.billNo,

              employeeName: data.purEmployeeInfo.employeeName,
              employeeId: data.purEmployeeInfo.employeeId,
              employeeNo: data.purEmployeeInfo.employeeNo,
              purEmployeeId: data.purEmployeeInfo.employeeId,
              apAmount: data.apAmount,
              preApAmount: data.preApAmount,
              openAmount: fcount([data.apAmount, data.preApAmount],'-'),
              bizDate: data.bizDate,
              settleDate: data.settleDate,
            }
          ]
          this.options = {
            ...this.options,
            list: []
          }
          this.options.billNo = data.billNo
          this.options.billId = data.billId
          this.options.billStatus = data.billStatus
          this.options.list = list
          this.options.isNoAddBtn = true
          this.options.isNoDelBtn = true
          console.log(this.options, 'this.options')
          this.loading = false
        } catch (err) {}
        this.loading = false
      } else {
        this.getBillno()
        this.options.columns = this.options.columns.filter(item => item.prop !== 'billNo')
      }
    },
    async handleEvent (type, row) {
      //获取元素在数组的下标
      let contains = function (list, row) {
        var i = list.length
        while (i--) {
          if (list[i] === row) {
            return i
          }
        }
        return false
      }
      switch (type) {

        case 'handleEventPush':
          this.$set(this.options.list[row + 1], 'billNo', this.options.billNo)
          break
        case 'handleEventDel':
          console.log(this.options.list.length, 'this.options.list', this.options.billNo)
          if (this.options.list.length == 1) {
            this.$set(this.options.list[0], 'billNo', this.options.billNo)
          }
          break
        case 'apAmount':
        case 'preApAmount':
          console.log(type, row, 'type, row')
          let apAmount = this.options.list[row.index].apAmount
          let preApAmount = this.options.list[row.index].preApAmount
          console.log(apAmount, 'apAmount');
          console.log(preApAmount, 'preApAmount');
          if (!(apAmount == 0? true : apAmount ?? false) || !(preApAmount == 0? true : preApAmount ?? false)) {
            this.$set(this.options.list[row.index], 'openAmount', '')
          } else {
            let openAmount = fcount([apAmount, preApAmount],'-')
            this.$set(this.options.list[row.index], 'openAmount', openAmount)
          }
          break
        case 'partnerData':
          if (!row) return
          let { check } = row
          if (check.length <= 0) return
          if (type == 'partnerData') {
            let tableIndex = this.options.tableIndex
            this.$set(this.options.list[tableIndex], 'partnerId', check[0].partnerId)
            this.$set(this.options.list[tableIndex], 'partnerNo', check[0].partnerNo)
            this.$set(this.options.list[tableIndex], 'partnerName', check[0].partnerName)
          } else {
            let tableIndex = this.options.tableIndex
            this.$set(this.options.list[tableIndex], 'payModeId', check[0].payModeId)
            this.$set(this.options.list[tableIndex], 'payModeName', check[0].payModeName)
            this.$set(this.options.list[tableIndex], 'payModeNo', check[0].payModeNo)
          }
          break
       
        case 'tableIndex':
          this.options.tableIndex = row
          break
        default:
          break
      }
    },
    // 提交保存
    async submitForm (isBool) {
      const valid = await this.$refs.form.validate()
      if (this.isSubmit) return
      this.isSubmit = true
      let form = this.options.list
      if (valid) {
        if (!this.options.billId) {
          try {
            const res = await addInitialAp(form)
            if (!isBool) {
              this.options.billStatus = res.data[0].billStatus
              this.options.billId = res.data[0].billId
              this.options.list = res.data.map((data) => (
                {
                  ...data,
                  partnerId: data.partnerInfo.partnerId,
                  partnerName: data.partnerInfo.partnerName,
                  partnerNo: data.partnerInfo.partnerNo,
                  deptName: data.deptInfo.deptName,
                  deptId: data.deptInfo.deptId,
                  deptNo: data.deptInfo.deptNo,
                  billId: data.billId,
                  billNo: data.billNo,
                  employeeName: data.purEmployeeInfo.employeeName,
                  employeeId: data.purEmployeeInfo.employeeId,
                  employeeNo: data.purEmployeeInfo.employeeNo,
                  purEmployeeId: data.purEmployeeInfo.employeeId,
                  apAmount: data.apAmount,
                  preApAmount: data.preApAmount,
                  openAmount: fcount([data.apAmount, data.preApAmount],'-'),
                  bizDate: data.bizDate,
                  settleDate: data.settleDate,
                }
              ))
              this.$modal.msgSuccess('新增单据成功')
            } else {
              this.reset()
              this.getBillno()
              this.$modal.msgSuccess('保存并新增单据成功')
            }
            this.isSubmit = false
          } catch (error) {
            this.isSubmit = false
          }
        } else {
          try {
            const res = await modifyInitialAp(form)
            console.log(res)
            if (!isBool) {
              this.$modal.msgSuccess('编辑单据成功')
            } else {
              this.reset()
              this.getBillno()
              this.$modal.msgSuccess('编辑单据并新增单据成功')
            }
            this.options.isNoAddBtn = true
            this.options.isNoDelBtn = true
            this.isSubmit = false
          } catch (error) {
            this.isSubmit = false
          }
        }
      }
    },
    //初始化数据
    reset () {
      // //表格配置
      this.options = {
        ...this.options,
        billNo: undefined,
        billId: undefined,
        billStatus: '',
      }
      this.options.list = [{}],
      this.resetForm('form')
    },
    // 获取订单编号
    async getBillno () {
      this.options.billNo = await getBillNo(100202)
      this.$set(this.options.list[0], 'billNo', this.options.billNo)
    },
    // 审核/反审核  按钮
    async auditForm (type) {
      if ([2, '2'].includes(this.options.billStatus) && type == '审核') return this.$message.warning('此单据不是未审核的状态哦~')
      if (['审核', '反审核'].includes(type)) {
        await this.$confirm(`确定${type}该方案`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.loading = true
        const { data } = await modifyBillStatusAp({
          billIds: this.options?.list?.map(x => x.billId),
          billStatus: type == '审核'? 2 : 0
        })
        this.options = {
          ...this.options,
          billStatus: type == '审核'? 2 : 0
        }
        this.$message.success(`${type}成功`)
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  overflow-y: scroll;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 250px;
}
.table {
  padding: 10px;
}
</style>
