<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  listBill,
  listSourceReceiveBill,
  preArSourceList,
  receiveListSourceReceiveBill,
  listSourcePaymentBill,
  purchaseBillPreApSourceList,
  paymentListSourcePaymentBill,
  listSourceInitialPartnerArBill,
  listSourceInitialPartnerApBill,
  financeReceiveListSourceBill,
  financeReceiveRefundListSourceBill,
  financePaymentListSourceBill,
  financePaymentRefundListSourceBill,
} from '@/api/arap/index'
import { mixin } from "@/components/Dialog/components/mixin.js";

import { fcount } from '@/utils'


export default {
  name: "SelectSource",
  mixins: [mixin],
  dicts: ['bill_status'],
  components: { TablePage },
  data() {
    return {
      billType: '',
      partnerId: '',
      options: {},
    };
  },
  created() {
    this.billType = this.data.billType
    console.log(this.data, 'data')
    let tabsArray = [
      {
        billType: ['190204', '190301'],
        title: "预付款单",
        getListApi: paymentListSourcePaymentBill,
        defaultBody: { partnerIds: [this.data.partnerId] },
        type: "purchaseOrder",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "areaName",
            label: "客供区域",
            formatter: (v, row) => row.partnerInfo?.areaName ? row.partnerInfo?.areaName : '',
            minWidth: 120,
          },
          {
            prop: "groupName",
            label: "客供分组",
            formatter: (v, row) => row.partnerInfo?.groupName ? row.partnerInfo?.groupName : '',
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "供应商编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "供应商名称",
            formatter: (v, row) => row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : '',
            minWidth: 120,
          },
          {
            prop: "actualRpMoney",
            label: "付款金额合计",
            minWidth: 120,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 120,
          },
          {
            prop: "billRpMoney",
            label: "预付款总金额",
            minWidth: 120,
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 180,
          },
          {
            prop: "actualRpMoney",
            label: "付款金额",
            minWidth: 120,
          },
          {
            prop: "settleStatus",
            label: "单据核销状态",
            minWidth: 120,
            formatter: (v) => v == 0 ? '未核销' : v == 1 ? '部分核销' : '已核销）'
          },
          {
            prop: "settleMoney",
            label: "已核销总金额",
            minWidth: 120,
          },
          {
            prop: "noSettleMoney",
            label: "未核销总金额",
            minWidth: 120,
            formatter: (v, row) => (row.billRpMoney || row.settleMoney) ? fcount([row.billRpMoney, row.settleMoney], '-') : 0
          },
          {
            prop: "sourceBillNo",
            label: "关联单据",
            minWidth: 150,
          },
          {
            prop: "saleEmployeeName",
            label: "业务员",
            minWidth: 120,
            formatter: (v, row) => row?.saleEmployeeInfo?.employeeName
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 120,
            formatter: (v, row) => row?.deptInfo?.deptName
          },
          {
            prop: "checkAccountBy",
            label: "对账人",
            minWidth: 120,
          },
          {
            prop: "checkAccountTime",
            label: "对账时间",
            minWidth: 155,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 155,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 155,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 155,
          },
        ],
      },
      {
        billType: ['190203'],
        title: "采购订单",
        getListApi: purchaseBillPreApSourceList,
        defaultBody: { settleStatus: [0, 1], partnerId: this.data.partnerId },
        type: "payment",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "partnerName",
            label: "供应商名称",
            minWidth: 150,
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 150,
          },
          {
            prop: "purEmployeeName",
            label: "采购员",
            minWidth: 150,
          },
          {
            prop: "billTaxMoney",
            label: "单据金额",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190202'],
        title: "付款单",
        getListApi: listSourcePaymentBill,
        defaultBody: { partnerIds: [this.data.partnerId], includeBillStatus: [2, 4] },
        type: "payment",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "areaName",
            label: "客供区域",
            formatter: (v, row) => row.partnerInfo?.areaName ? row.partnerInfo?.areaName : '',
            minWidth: 120,
          },
          {
            prop: "groupName",
            label: "客供分组",
            formatter: (v, row) => row.partnerInfo?.groupName ? row.partnerInfo?.groupName : '',
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "供应商编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "供应商名称",
            formatter: (v, row) => row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : '',
            minWidth: 120,
          },
          {
            prop: "actualRpMoney",
            label: "付款金额合计",
            minWidth: 150,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 150,
          },
          {
            prop: "billWriteOffMoney",
            label: "付款核销总金额",
            minWidth: 150,
          },
          {
            prop: "settleStatus",
            label: "退款状态",
            minWidth: 150,
            formatter: (v) => v == 0 ? '未退款' : v == 1 ? '部分退款' : '已退款'
          },
          {
            prop: "refundAuditStatus",
            label: "退款审核状态",
            minWidth: 150,
            formatter: (v) => v == 0 ? '已退款未审核' : v == 1 ? '已退款部分审核' : v == 2 ? '已退款已审核' : ''
          },
          {
            prop: "settleMoney",
            label: "已退款总金额",
            minWidth: 150,
          },
          {
            prop: "noSettleMoney",
            label: "未退款总金额",
            minWidth: 150,
            formatter: (v, row) => (row.billRpMoney || row.settleMoney) ? fcount([row.billRpMoney, row.settleMoney], '-') : ''
          },
          {
            prop: "actualRpMoney",
            label: "付款金额",
            minWidth: 150,
          },
          {
            prop: "saleEmployeeName",
            label: "业务员",
            minWidth: 150,
            formatter: (v, row) => row?.saleEmployeeInfo?.employeeName
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 150,
            formatter: (v, row) => row?.deptInfo?.deptName
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 150,
          },
          {
            minWidth: 180,
            prop: "sourceBillNo",
            label: "源单单号",
            formatter: (v, row) => row?.apDetails?.map(x => x.sourceBillNo)?.join(',') || '',
          },
          {
            prop: "preRpBillNo",
            label: "关联预付款单",
            minWidth: 150,
          },
          {
            prop: "preRpBillMoney",
            label: "转预付款金额",
            minWidth: 150,
          },
          {
            prop: "checkAccountStatus",
            label: "对账状态",
            minWidth: 120,
            formatter: (v) => v == 0 ? '未对账' : '已对账'
          },
          {
            prop: "checkAccountBy",
            label: "对账人",
            minWidth: 120,
          },
          {
            prop: "checkAccountTime",
            label: "对账时间",
            minWidth: 155,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 155,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 155,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 155,
          },
        ],
      },
      {
        billType: ['190201', '190301'],
        title: "采购入库单",
        getListApi: listBill,
        defaultBody: { settleStatus: [0, 1], isBuildPartnerName: true, isArApSourceBillQuery: true, billType: '110102', partnerId: this.data.partnerId, includeBillStatus: [2, 4] },
        type: "purchaseReceipt",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "供应商",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "settleStatus",
            label: "付款状态",
            minWidth: 150,
            formatter: (v) => v == 0 ? '未付款' : v == 1 ? '部分付款' : '已付款'
          },
          {
            prop: "billTaxMoney",
            label: "成交金额",
            minWidth: 150,
          },
          {
            prop: "settleMoney",
            label: "已付款金额",
            minWidth: 150,
          },
          {
            prop: "notThisAccounts",
            label: "未付款金额",
            minWidth: 150,
            formatter: (v, row) => row.billTaxMoney ? fcount([row.billTaxMoney, row.settleMoney], '-') : row.billTaxMoney ? row.billTaxMoney : 0
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => row.billTaxMoney ? fcount([row.billTaxMoney, row.billNotaxMoney], '-') : 0
          },
          {
            prop: "billTaxMoney",
            label: "价税合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190201', '190301'],
        title: "门店采购入库单",
        getListApi: listBill,
        defaultBody: { settleStatus: [0, 1], isBuildPartnerName: true, isArApSourceBillQuery: true, billType: '140303', partnerId: this.data.partnerId, includeBillStatus: [2, 4] },
        type: "shopPurchaseReceipt",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "供应商",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "settleStatus",
            label: "付款状态",
            minWidth: 150,
            formatter: (v) => v == 0 ? '未付款' : v == 1 ? '部分付款' : '已付款'
          },
          {
            prop: "billTaxMoney",
            label: "成交金额",
            minWidth: 150,
          },
          {
            prop: "settleMoney",
            label: "已付款金额",
            minWidth: 150,
          },
          {
            prop: "notThisAccounts",
            label: "未付款金额",
            minWidth: 150,
            formatter: (v, row) => row.billTaxMoney ? fcount([row.billTaxMoney, row.settleMoney], '-') : row.billTaxMoney ? row.billTaxMoney : 0
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => row.billTaxMoney ? fcount([row.billTaxMoney, row.billNotaxMoney], '-') : 0
          },
          {
            prop: "billTaxMoney",
            label: "价税合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190201', '190202', '190301'],
        title: "采购退货单",
        getListApi: listBill,
        defaultBody: { settleStatus: [0, 1], isBuildPartnerName: true, isArApSourceBillQuery: true, billType: '110103', partnerId: this.data.partnerId, includeBillStatus: [2, 4] },
        type: "purchaseReturn",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "供应商",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "settleStatus",
            label: '退款状态',
            minWidth: 150,
            formatter: (v) => {
              return v == 0 ? `未退款` : v == 1 ? `部分退款` : `已退款`
            }
          },
          {
            prop: "billTaxMoney",
            label: "成交金额",
            minWidth: 150,
          },
          {
            prop: "settleMoney",
            label: '已退款金额',
            minWidth: 150,
          },
          {
            prop: "notThisAccounts",
            label: '未退款金额',
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.settleMoney) ? fcount([row.billTaxMoney, row.settleMoney], '-') : 0
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.billNotaxMoney) ? fcount([row.billTaxMoney, row.billNotaxMoney], '-') : 0
          },
          {
            prop: "billTaxMoney",
            label: "价税合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190201', '190202', '190301'],
        title: "门店采购退货单",
        getListApi: listBill,
        defaultBody: { settleStatus: [0, 1], isBuildPartnerName: true, isArApSourceBillQuery: true, billType: '140318', partnerId: this.data.partnerId, includeBillStatus: [2, 4] },
        type: "shopPurchaseReturn",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "供应商",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "settleStatus",
            label: '退款状态',
            minWidth: 150,
            formatter: (v) => {
              return v == 0 ? `未退款` : v == 1 ? `部分退款` : `已退款`
            }
          },
          {
            prop: "billTaxMoney",
            label: "成交金额",
            minWidth: 150,
          },
          {
            prop: "settleMoney",
            label: '已退款金额',
            minWidth: 150,
          },
          {
            prop: "notThisAccounts",
            label: '未退款金额',
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.settleMoney) ? fcount([row.billTaxMoney, row.settleMoney], '-') : 0
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.billNotaxMoney) ? fcount([row.billTaxMoney, row.billNotaxMoney], '-') : 0
          },
          {
            prop: "billTaxMoney",
            label: "价税合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190104', '190301'],
        title: "预收款单",
        getListApi: receiveListSourceReceiveBill,
        defaultBody: { partnerIds: [this.data.partnerId] },
        type: "saleOrder",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "areaName",
            label: "客供区域",
            formatter: (v, row) => row.partnerInfo?.areaName ? row.partnerInfo?.areaName : '',
            minWidth: 120,
          },
          {
            prop: "groupName",
            label: "客供分组",
            formatter: (v, row) => row.partnerInfo?.groupName ? row.partnerInfo?.groupName : '',
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "客户编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) => row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : '',
            minWidth: 120,
          },
          {
            prop: "actualRpMoney",
            label: "收款金额合计",
            minWidth: 150,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 150,
          },
          {
            prop: "billRpMoney",
            label: "预收款总金额",
            minWidth: 150,
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 150,
          },
          {
            prop: "actualRpMoney",
            label: "收款金额",
            minWidth: 150,
          },
          {
            prop: "settleStatus",
            label: "单据核销状态",
            minWidth: 150,
            formatter: (v) => v == 0 ? '未核销' : v == 1 ? '部分核销' : '已核销'
          },
          {
            prop: "settleMoney",
            label: "已核销总金额",
            minWidth: 150,
          },
          {
            prop: "noSettleMoney",
            label: "未核销总金额",
            minWidth: 150,
            formatter: (v, row) => (row.billRpMoney || row.settleMoney) ? fcount([row.billRpMoney, row.settleMoney], '-') : 0
          },
          {
            prop: "sourceBillNo",
            label: "关联单据",
            minWidth: 150,
          },
          {
            prop: "saleEmployeeName",
            label: "业务员",
            minWidth: 150,
            formatter: (v, row) => row?.saleEmployeeInfo?.employeeName
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 150,
            formatter: (v, row) => row?.deptInfo?.deptName
          },
          {
            prop: "checkAccountBy",
            label: "对账人",
            minWidth: 150,
          },
          {
            prop: "checkAccountTime",
            label: "对账时间",
            minWidth: 150,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 150,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 150,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 150,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 150,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 150,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190301', '190101'],
        title: ['190301'].includes(this.billType) ? this.data.type == 1 ? '期初预收' : '期初应收' : "期初应收款",
        getListApi: listSourceInitialPartnerArBill,
        defaultBody: {
          partnerIds: [this.data.partnerId],
          isArMoneySettle: ['190101'].includes(this.billType) ? true : this.data.type == 1 ? false : true,
          isPreArMoneySettle: ['190101'].includes(this.billType) ? false : this.data.type == 1 ? true : false
        },
        type: "putAway",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "areaName",
            label: "客户编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) => row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : '',
            minWidth: 150,
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 120,
          },
          {
            prop: "arAmount",
            label: "应收金额",
            minWidth: 120,
          },
          {
            prop: "preArAmount",
            label: "预收金额",
            minWidth: 120,
          },
          {
            prop: "arWriteOffMoney",
            label: `${['190101', '190301'].includes(this.billType) ? '已核销应收金额' : '已核销应付金额'}`,
            minWidth: 140,
          },
          {
            prop: "noAmount",
            label: `${['190101', '190301'].includes(this.billType) ? '未核销应收金额' : '未核销应付金额'}`,
            minWidth: 140,
            formatter: (v, row) => fcount([row.arAmount, row.arWriteOffMoney], '-'),
          },
          {
            prop: "balance",
            label: "期初余额",
            formatter: (v, row) => fcount([row.arAmount, row.preArAmount], '-'),
            minWidth: 120,
          },
          {
            prop: "bizDate",
            label: "业务日期",
            minWidth: 120,
          },
          {
            prop: "settleDate",
            label: "结算日期",
            minWidth: 120,
          },
          {
            prop: "deptNo",
            label: "部门编号",
            minWidth: 150,
            formatter: (v, row) => row.deptInfo?.deptNo ? row.deptInfo?.deptNo : '',
          },
          {
            prop: "deptName",
            label: "部门名称",
            minWidth: 150,
            formatter: (v, row) => row.deptInfo?.deptName ? row.deptInfo?.deptName : '',
          },
          {
            prop: "employeeNo",
            label: "业务员编号",
            minWidth: 140,
            formatter: (v, row) => row.saleEmployeeInfo?.employeeNo ? row.saleEmployeeInfo?.employeeNo : '',
          },
          {
            prop: "employeeName",
            label: "业务员名称",
            minWidth: 140,
            formatter: (v, row) => row.saleEmployeeInfo?.employeeName ? row.saleEmployeeInfo?.employeeName : '',
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
      },
      {
        billType: ['190301', '190201'],
        title: ['190301'].includes(this.billType) ? this.data.type == 1 ? '期初预付' : '期初应付' : "期初应付款",
        getListApi: listSourceInitialPartnerApBill,
        defaultBody: {
          partnerIds: [this.data.partnerId],
          isApMoneySettle: ['190201'].includes(this.billType) ? true : this.data.type == 1 ? false : true,
          isPreApMoneySettle: ['190201'].includes(this.billType) ? false : this.data.type == 1 ? true : false
        },
        type: "pay",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "areaName",
            label: "供应商编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "供应商名称",
            formatter: (v, row) => row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : '',
            minWidth: 150,
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 120,
          },
          {
            prop: "apAmount",
            label: '应付金额',
            minWidth: 120,
          },
          {
            prop: "preApAmount",
            label: '预付金额',
            minWidth: 120,
          },
          {
            prop: "apWriteOffMoney",
            label: `${['190201'].includes(this.billType) ? '已核销应付金额' : '已核销预付金额'}`,
            minWidth: 140,
          },
          {
            prop: "noAmount",
            label: `${['190201'].includes(this.billType) ? '未核销应付金额' : '未核销预付金额'}`,
            minWidth: 140,
            formatter: (v, row) => fcount([row.apAmount, row.apWriteOffMoney], '-'),
          },
          {
            prop: "balance",
            label: "期初余额",
            formatter: (v, row) => fcount([row.apAmount, row.preApAmount], '-'),
            minWidth: 120,
          },
          {
            prop: "bizDate",
            label: "业务日期",
            minWidth: 120,
          },
          {
            prop: "settleDate",
            label: "结算日期",
            minWidth: 120,
          },
          {
            prop: "deptNo",
            label: "部门编号",
            minWidth: 150,
            formatter: (v, row) => row.deptInfo?.deptNo ? row.deptInfo?.deptNo : '',
          },
          {
            prop: "deptName",
            label: "部门名称",
            minWidth: 150,
            formatter: (v, row) => row.deptInfo?.deptName ? row.deptInfo?.deptName : '',
          },
          {
            prop: "employeeNo",
            label: "采购员编号",
            minWidth: 140,
            formatter: (v, row) => row.purEmployeeInfo?.employeeNo ? row.purEmployeeInfo?.employeeNo : '',
          },
          {
            prop: "employeeName",
            label: "采购员名称",
            minWidth: 140,
            formatter: (v, row) => row.purEmployeeInfo?.employeeName ? row.purEmployeeInfo?.employeeName : '',
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
      },
      {
        billType: ['190103'],
        title: "销售订单",
        getListApi: preArSourceList,
        defaultBody: { settleStatus: [0, 1], partnerId: this.data.partnerId, billType: '120101' },
        type: "saleOrder",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "saleEmployeeName",
            label: "业务员",
            minWidth: 150,
            formatter: (v, row) => row?.saleEmployeeInfo?.employeeName
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 150,
            formatter: (v, row) => row?.deptInfo?.deptName
          },
          {
            prop: "billTaxMoney",
            label: "成交金额",
            minWidth: 150,
          },
          {
            prop: "finishUnitMoney",
            label: "已出库金额",
            minWidth: 150,
          },
          {
            prop: "noUnitMoney",
            label: "未出库金额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.finishUnitMoney) ? fcount([row.billTaxMoney, row.finishUnitMoney], '-') : ''
          },
          {
            prop: "settleMoney",
            label: "已核销金额",
            minWidth: 150,
          },
          // 未核销金额 = 成交金额 - 已核销金额；
          {
            prop: "noSettleMoney",
            label: "未核销金额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.settleMoney) ? fcount([row.billTaxMoney, row.settleMoney], '-') : ''
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.billNotaxMoney) ? fcount([row.billTaxMoney, row.billNotaxMoney], '-') : ''
          },
          {
            prop: "billTaxMoney",
            label: "税价合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190103'],
        title: "门店销售订单",
        getListApi: preArSourceList,
        defaultBody: { settleStatus: [0, 1], partnerId: this.data.partnerId, billType: '140313' },
        type: "shopSaleOrder",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "saleEmployeeName",
            label: "业务员",
            minWidth: 150,
            formatter: (v, row) => row?.saleEmployeeInfo?.employeeName
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 150,
            formatter: (v, row) => row?.deptInfo?.deptName
          },
          {
            prop: "billTaxMoney",
            label: "成交金额",
            minWidth: 150,
          },
          {
            prop: "finishUnitMoney",
            label: "已出库金额",
            minWidth: 150,
          },
          {
            prop: "noUnitMoney",
            label: "未出库金额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.finishUnitMoney) ? fcount([row.billTaxMoney, row.finishUnitMoney], '-') : ''
          },
          {
            prop: "settleMoney",
            label: "已核销金额",
            minWidth: 150,
          },
          // 未核销金额 = 成交金额 - 已核销金额；
          {
            prop: "noSettleMoney",
            label: "未核销金额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.settleMoney) ? fcount([row.billTaxMoney, row.settleMoney], '-') : ''
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.billNotaxMoney) ? fcount([row.billTaxMoney, row.billNotaxMoney], '-') : ''
          },
          {
            prop: "billTaxMoney",
            label: "税价合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190102'],
        title: "收款单",
        getListApi: listSourceReceiveBill,
        defaultBody: { partnerIds: [this.data.partnerId] },
        type: "receipt",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "partnerName",
            label: "客户编号",
            minWidth: 150,
            formatter: (v, row) => row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : ''
          },
          {
            prop: "partnerName",
            label: "客户名称",
            minWidth: 150,
            formatter: (v, row) => row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : ''
          },
          {
            prop: "actualRpMoney",
            label: "收款金额合计",
            minWidth: 150,
          },
          {
            prop: "billWriteOffMoney",
            label: "收款核销总金额",
            minWidth: 150,
          },
          {
            prop: "discountMoney",
            label: "优惠金额",
            minWidth: 150,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 150,
          },
          {
            prop: "billWriteOffMoney",
            label: "本次核销总金额",
            minWidth: 150,
          },
          {
            prop: "preRpBillNo",
            label: "关联预收款单",
            minWidth: 150,
          },
          {
            prop: "preRpBillMoney",
            label: "转预收款金额",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190101', '190301'],
        title: "销售出库单",
        getListApi: listBill,
        defaultBody: { settleStatus: [0, 1], isBuildPartnerName: true, isArApSourceBillQuery: true, billType: '120102', partnerId: this.data.partnerId, includeBillStatus: [2, 4] },
        type: "salesOrder",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "settleStatus",
            label: "收款状态",
            minWidth: 150,
            formatter: (v) => v == 0 ? '未收款' : v == 1 ? '部分收款' : '已收款'
          },
          {
            // prop: "thisAccounts",
            prop: "billTaxMoney",
            label: "本次应收账款",
            minWidth: 150,
            // formatter: (v, row) => v == fcount([row.billTaxMoney, row.settleMoney],'-')
          },
          {
            prop: "settleMoney",
            label: "已收款金额",
            minWidth: 150,
          },
          {
            prop: "notThisAccounts",
            label: "未收款金额",
            minWidth: 150,
            formatter: (v, row) => fcount([row.billTaxMoney, row.settleMoney], '-')
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.billNotaxMoney) ? fcount([row.billTaxMoney, row.billNotaxMoney], '-') : ''
          },
          {
            prop: "billTaxMoney",
            label: "价税合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190101', '190301'],
        title: "门店销售出库单",
        getListApi: listBill,
        defaultBody: { settleStatus: [0, 1], isBuildPartnerName: true, isArApSourceBillQuery: true, billType: '140312', partnerId: this.data.partnerId, includeBillStatus: [2, 4] },
        type: "shopSalesOrder",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "settleStatus",
            label: "收款状态",
            minWidth: 150,
            formatter: (v) => v == 0 ? '未收款' : v == 1 ? '部分收款' : '已收款'
          },
          {
            // prop: "thisAccounts",
            prop: "billTaxMoney",
            label: "本次应收账款",
            minWidth: 150,
            // formatter: (v, row) => v == fcount([row.billTaxMoney, row.settleMoney],'-')
          },
          {
            prop: "settleMoney",
            label: "已收款金额",
            minWidth: 150,
          },
          {
            prop: "notThisAccounts",
            label: "未收款金额",
            minWidth: 150,
            formatter: (v, row) => fcount([row.billTaxMoney, row.settleMoney], '-')
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.billNotaxMoney) ? fcount([row.billTaxMoney, row.billNotaxMoney], '-') : ''
          },
          {
            prop: "billTaxMoney",
            label: "价税合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190101', '190102', '190301'],
        title: "销售退货单",
        getListApi: listBill,
        defaultBody: { settleStatus: [0, 1], isBuildPartnerName: true, isArApSourceBillQuery: true, billType: '120103', partnerId: this.data.partnerId, includeBillStatus: [2, 4] },
        type: "cancelGoods",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 150,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 150,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            minWidth: 150,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "settleStatus",
            label: "退款状态",
            minWidth: 150,
            formatter: (v) => v == 0 ? '未退款' : v == 1 ? '部分退款' : '已退款'
          },
          {
            prop: "billTaxMoney",
            label: "成交金额",
            minWidth: 150,
          },
          {
            prop: "settleMoney",
            label: "已退款金额",
            minWidth: 150,
          },
          {
            prop: "notThisAccounts",
            label: "未退款金额",
            minWidth: 150,
            formatter: (v, row) => fcount([row.billTaxMoney, row.settleMoney], '-')
          },
          {
            prop: "taxAmount",
            label: "税额",
            minWidth: 150,
            formatter: (v, row) => (row.billTaxMoney || row.billNotaxMoney) ? fcount([row.billTaxMoney, row.billNotaxMoney], '-') : ''
          },
          {
            prop: "billTaxMoney",
            label: "价税合计",
            minWidth: 150,
          },
        ],
      },
      {
        billType: ['190101', '190301'],
        title: "其他收入",
        getListApi: financeReceiveListSourceBill,
        defaultBody: { partnerIds: [this.data.partnerId] },
        type: "receipt",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "areaName",
            label: "客供区域",
            formatter: (v, row) => row.partnerInfo?.areaName ? row.partnerInfo?.areaName : '',
            minWidth: 120,
          },
          {
            prop: "groupName",
            label: "客供分组",
            formatter: (v, row) => row.partnerInfo?.groupName ? row.partnerInfo?.groupName : '',
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "客户编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) => row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : '',
            minWidth: 120,
          },
          {
            prop: "billWriteOffMoney",
            label: "本单应收账款",
            minWidth: 140,
          },
          {
            prop: `${['190101'].includes(this.billType) ? 'settleMoney' : 'billRpMoney'}`,
            label: "本单已收款金额",
            minWidth: 160,
          },
          {
            prop: "settleStatus",
            label: "核销状态",
            minWidth: 120,
            formatter: (val) => {
              console.log(val, 'val')
              let obj = {
                0: '未核销',
                1: '部分核销',
                2: '已核销',
              }
              return [0, 1, 2].includes(val) ? obj[val] : '';
            },
          },
          {
            prop: "employeeName",
            label: "业务员",
            minWidth: 160,
            formatter: (v, row) => row.saleEmployeeInfo?.employeeName ? row.saleEmployeeInfo?.employeeName : '',
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 160,
            formatter: (v, row) => row.deptInfo?.deptName ? row.deptInfo?.deptName : '',
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 160,
          },
          {
            prop: "checkAccountStatus",
            label: "对账状态",
            minWidth: 160,
            formatter: e => (e == '0' ? '未对账' : e == '1' ? '已对账' : '')
          },
          {
            prop: "checkAccountBy",
            label: "对账人",
            minWidth: 160,
          },
          {
            prop: "checkAccountTime",
            label: "对账时间",
            minWidth: 160,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
      },
      {
        billType: ['190101', '190102', '190301'],
        title: "其他收入退款",
        getListApi: financeReceiveRefundListSourceBill,
        defaultBody: { partnerIds: [this.data.partnerId] },
        type: "receipt",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "areaName",
            label: "客供区域",
            formatter: (v, row) => row.partnerInfo?.areaName ? row.partnerInfo?.areaName : '',
            minWidth: 120,
          },
          {
            prop: "groupName",
            label: "客供分组",
            formatter: (v, row) => row.partnerInfo?.groupName ? row.partnerInfo?.groupName : '',
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "客户编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) => row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : '',
            minWidth: 120,
          },
          {
            prop: "billWriteOffMoney",
            label: '本单应退账款',
            minWidth: 140,
          },
          {
            prop: `${['190101'].includes(this.billType) ? 'settleMoney' : 'billRpMoney'}`,
            label: '本单已退款金额',
            minWidth: 160,
          },
          {
            prop: "settleStatus",
            label: "核销状态",
            minWidth: 120,
            formatter: (val) => {
              console.log(val, 'val')
              let obj = {
                0: '未核销',
                1: '部分核销',
                2: '已核销',
              }
              return [0, 1, 2].includes(val) ? obj[val] : '';
            },
          },
          {
            prop: "employeeName",
            label: "业务员",
            minWidth: 160,
            formatter: (v, row) => row.saleEmployeeInfo?.employeeName ? row.saleEmployeeInfo?.employeeName : '',
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 160,
            formatter: (v, row) => row.deptInfo?.deptName ? row.deptInfo?.deptName : '',
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 160,
          },
          {
            prop: "checkAccountStatus",
            label: "对账状态",
            minWidth: 160,
            formatter: e => (e == '0' ? '未对账' : e == '1' ? '已对账' : '')
          },
          {
            prop: "checkAccountBy",
            label: "对账人",
            minWidth: 160,
          },
          {
            prop: "checkAccountTime",
            label: "对账时间",
            minWidth: 160,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
      },
      {
        billType: ['190201', '190301'],
        title: "其他支出",
        getListApi: financePaymentListSourceBill,
        defaultBody: { partnerIds: [this.data.partnerId] },
        type: "receipt",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "areaName",
            label: "客供区域",
            formatter: (v, row) => row.partnerInfo?.areaName ? row.partnerInfo?.areaName : '',
            minWidth: 120,
          },
          {
            prop: "groupName",
            label: "客供分组",
            formatter: (v, row) => row.partnerInfo?.groupName ? row.partnerInfo?.groupName : '',
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "客户编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) => row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : '',
            minWidth: 120,
          },
          {
            prop: "billWriteOffMoney",
            label: `${['190201','190301'].includes(this.billType) ? '本单应付账款' : '本单应收账款'}`,
            minWidth: 140,
          },
          {
            prop: this.billType == '190201' ? 'settleMoney' : "billRpMoney",
            label: `${['190201','190301'].includes(this.billType) ? '本单已付款金额' : '本单已收款金额'}`,
            minWidth: 140,
          },
          {
            prop: "settleStatus",
            label: "核销状态",
            minWidth: 120,
            formatter: (val) => {
              console.log(val, 'val')
              let obj = {
                0: '未核销',
                1: '部分核销',
                2: '已核销',
              }
              return [0, 1, 2].includes(val) ? obj[val] : '';
            },
          },
          {
            prop: "employeeName",
            label: "业务员",
            minWidth: 160,
            formatter: (v, row) => row.saleEmployeeInfo?.employeeName ? row.saleEmployeeInfo?.employeeName : '',
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 160,
            formatter: (v, row) => row.deptInfo?.deptName ? row.deptInfo?.deptName : '',
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 160,
          },
          {
            prop: "checkAccountStatus",
            label: "对账状态",
            minWidth: 160,
            formatter: e => (e == '0' ? '未对账' : e == '1' ? '已对账' : '')
          },
          {
            prop: "checkAccountBy",
            label: "对账人",
            minWidth: 160,
          },
          {
            prop: "checkAccountTime",
            label: "对账时间",
            minWidth: 160,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
      },
      {
        billType: ['190201', '190202', '190301'],
        title: "其他支出退款",
        getListApi: financePaymentRefundListSourceBill,
        defaultBody: { partnerIds: [this.data.partnerId] },
        type: "receipt",
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
          },
          {
            prop: "billStatus",
            label: "审核状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "areaName",
            label: "客供区域",
            formatter: (v, row) => row.partnerInfo?.areaName ? row.partnerInfo?.areaName : '',
            minWidth: 120,
          },
          {
            prop: "groupName",
            label: "客供分组",
            formatter: (v, row) => row.partnerInfo?.groupName ? row.partnerInfo?.groupName : '',
            minWidth: 120,
          },
          {
            prop: "partnerNo",
            label: "客户编号",
            formatter: (v, row) => row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : '',
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "客户名称",
            formatter: (v, row) => row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : '',
            minWidth: 120,
          },
          {
            prop: "billWriteOffMoney",
            label: `本单应${(['190201', '190202', '190301'].includes(this.billType)) ? '退' : '收'}账款`,
            minWidth: 140,
          },
          {
            prop: `${this.billType == '190202' || this.billType == '190201' ? 'settleMoney' : 'billRpMoney'}`,
            label: `本单${['190201', '190202', '190301'].includes(this.billType) ? '已退款' : '已收款'}金额`,
            minWidth: 160,
          },
          {
            prop: "settleStatus",
            label: "核销状态",
            minWidth: 120,
            formatter: (val) => {
              console.log(val, 'val')
              let obj = {
                0: '未核销',
                1: '部分核销',
                2: '已核销',
              }
              return [0, 1, 2].includes(val) ? obj[val] : '';
            },
          },
          {
            prop: "employeeName",
            label: "业务员",
            minWidth: 160,
            formatter: (v, row) => row.saleEmployeeInfo?.employeeName ? row.saleEmployeeInfo?.employeeName : '',
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 160,
            formatter: (v, row) => row.deptInfo?.deptName ? row.deptInfo?.deptName : '',
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 160,
          },
          {
            prop: "checkAccountStatus",
            label: "对账状态",
            minWidth: 160,
            formatter: e => (e == '0' ? '未对账' : e == '1' ? '已对账' : '')
          },
          {
            prop: "checkAccountBy",
            label: "对账人",
            minWidth: 160,
          },
          {
            prop: "checkAccountTime",
            label: "对账时间",
            minWidth: 160,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
      },
    ]
    let list = tabsArray.filter((x) => x.billType.includes(this.billType))
    if (this.data.type == 1) {
      let text = this.data.billSubType == 1 ? '预收款单' : '预付款单'
      let textOpen = this.data.billSubType == 1 ? '期初预收' : '期初预付'
      // list = list.filter((x) => x.title == text)
      list = list.filter((x) => [text, textOpen].includes(x.title))
    } else if (this.data.type == 2) {
      // let text = this.data.billSubType == 1? '销售出库单' : '采购入库单'
      // let listData = []
      if (this.data.billSubType == 1) {
        let listData = [
          // '销售出库单', '销售退货单', '期初预收', '其他收入', '其他收入退款', 
          '销售出库单', '门店销售出库单', '销售退货单', '期初应收', '其他收入', '其他收入退款',
        ]
        list = list.filter((x) => listData.includes(x.title))
      } else {
        let listData = [
          // '采购入库单', '采购退货单', '期初预付', '其他支出', '其他支出退款', 
          '采购入库单', '门店采购入库单', '采购退货单', '门店采购退货单', '期初应付', '其他支出', '其他支出退款',
        ]
        list = list.filter((x) => listData.includes(x.title))
      }
      // list = list.filter((x) => text.includes(x.title))
    }

    this.options = {
      summary: "",
      listNo: true,
      loading: true,
      pagination: {
        total: 0,
        pageNum: 1,
        pageSize: 15,
      },
      body: {},
      mutiSelect: true, // 多选
      check: [], // 选中数据
      rowKey: "billId",
      title: "",
      tableTitle: '',
      labelWidth: "80",
      tabsColumns: list
    }
  }
};
</script>


<style lang="scss" scoped>
::v-deep #table-page {
  height: 500px !important;
  width: 1000px;
}
</style>
