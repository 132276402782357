<template>
  <div>
    <el-form class="el-form-myde" :inline="true">
      <template v-for="(item, index) in buttons">
        <el-form-item v-if="item.left" :key="item.label + index">
          <el-dropdown
            v-if="item.btnType === 'dropdown'"
            split-button
            :type="item.type || 'warning'"
            :icon="item.icon || 'el-icon-circle-check'"
            @click="handleEvent(item)"
            @command="handleEvent"
            :disabled="
              item.disabled
                ? typeof item.disabled == 'function'
                  ? item.disabled()
                  : item.disabled
                : false
            "
            size="mini"
          >
            {{ item.label }}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="btn"
                v-for="(btn, i) in item.other"
                :key="i + btn.click"
              >
                {{ btn.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            v-else
            :type="item.type"
            :plain="item.plain"
            :icon="item.icon"
            :roun="item.round"
            @click="handleEvent(item)"
            :disabled="
              item.disabled
                ? typeof item.disabled == 'function'
                  ? item.disabled()
                  : item.disabled
                : false
            "
            size="mini"
          >
            {{ item.label }}
          </el-button>
        </el-form-item>
      </template>
      <div v-if="buttons" style="float: right; height: 35px">
        <template v-for="item in buttons" style="display: flex; align-items: center">
          <el-form-item v-if="!item.left" :key="item.label">
            <el-dropdown
              v-if="item.btnType === 'dropdown'"
              split-button
              :type="item.type || ''"
              :icon="item.icon || 'el-icon-circle-check'"
              @click="handleEvent(item)"
              @command="handleEvent"
              :disabled="
                item.disabled
                  ? typeof item.disabled == 'function'
                    ? item.disabled()
                    : item.disabled
                  : false
              "
              size="mini"
            >
              {{ item.label }}
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="btn"
                  v-for="(btn, i) in item.other"
                  :key="i + btn.click"
                >
                  {{ btn.label }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              v-else
              :type="item.type"
              :plain="item.plain"
              :icon="item.icon"
              :roun="item.round"
              @click="handleEvent(item)"
              :disabled="
                item.disabled
                  ? typeof item.disabled == 'function'
                    ? item.disabled()
                    : item.disabled
                  : false
              "
              size="mini"
            >
              {{ item.label }}
            </el-button>
          </el-form-item>
        </template>
      </div>
    </el-form>
    <!-- <Dialog :options.sync="dialogOptions" /> -->
  </div>
</template>
<script>
export default {
  name: "DetailPageDetailTopButton",
  // components: { Dialog: () => import("@/components/dialog") },
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    tableConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialogOptions: {
        width: 700,
        show: false,
        title: "打印配置",
        type: "PrintSet",
      }, // 一些弹窗配置
    };
  },
  methods: {
    dialogEvent(type) {
      if (type === "dialogChange") {
        this.$emit("handleEvent", "getList"); // 刷新列表
      }
    },
    handleEvent(e = {}) {
      const { click, alertText } = e;
      if (alertText) {
        const content = typeof alertText === "function" ? alertText() : alertText;
        return this.$confirm(content, "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.$emit("handleEvent", click, e);
          })
          .catch(() => {});
      }
      if (click === "printSet") {
        this.dialogOptions.show = true;
        return;
      }
      this.$emit("handleEvent", e?.click ?? e, e);
    },
  },
};
</script>
<style lang="scss">
.table-button-popper {
  padding: 0;
  .table-button-popper-content {
    text-align: center;
    margin: 0;
    padding: 4px 0;
    .table-button-popper-item {
      cursor: pointer;
      height: 30px;
      line-height: 30px;
      &:hover {
        background-color: #e8f4ff;
        color: #46a6ff;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.el-form-myde {
  margin-bottom: 5px;
  min-height: 35px;
  clear: both;
  text-align: left;

  ::v-deep .el-form-item {
    &:last-child {
      margin-right: 0;
    }
  }
}
::v-deep .el-form-item__content {
  margin-bottom: 0;
  height: 28px;
}
::v-deep .el-dropdown {
  margin: 4px 0;
}
</style>
