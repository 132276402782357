import request from '@/utils/request'

// 门店收银日报
export function listPayAnalysisAPI(query) {
  return request({
    url: '/api/system/shop/report/pay/daily/getShopDailyList',
    method: 'get',
    timeout: 30000,
    params: query
  })
}

// 门店收银日报合计
export function listPayAnalysisSummaryAPI(query) {
  return request({
    url: '/api/system/shop/report/pay/daily/getShopDailyListSummary',
    method: 'get',
    timeout: 30000,
    params: query
  })
}
