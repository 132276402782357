<template>
  <div class="store-detail">
    <div class="card">
      <topOperatingButton
        :disabled="disabled && (disabledBtn === undefined || disabledBtn === true)"
        :showAudit="showAudit"
        :isAddBtn="showAdd"
        :isExamineBtn="
          ['130101', '140309', '170309', '170409'].includes(billType) ? true : false
        "
        :isAuditBillBtn="
          ['130101', '140309', '170309', '170409'].includes(billType) ? false : true
        "
        @submitForm="submitForm(false, '仓库')"
        @addBill="AddBill"
        @auditBill="changeBillStatus"
        @handleAdd="handleAdd"
        @getQuit="getQuit"
        id="topOperatingButton"
      >
        <template slot="specialDiyBtn">
          <el-button
            size="mini"
            v-if="billType === '130105' || billType === '130106'"
            @click="storeSelectBills"
            :disabled="disabled"
            >引入源单
          </el-button>
          <!-- icon="el-icon-switch-button" -->
          <el-button
            icon="el-icon-remove-outline"
            size="mini"
            :disabled="single"
            @click="changeBillStatus('关闭')"
            >终止单据
          </el-button>
        </template>
      </topOperatingButton>
      <!-- 填写明细表单区域 -->
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        label-position="right"
        class="ken-detail-form"
        :rules="rules"
        size="mini"
      >
        <cardTitleCom
          cardTitle="基本信息"
          :billStatus="form.billStatus"
          id="basicInformation"
        >
          <template slot="cardContent">
            <div class="x-f marT10">
              <el-form-item label="单据编号" prop="billNo">
                <el-input
                  v-model="form.billNo"
                  placeholder="单据编号"
                  disabled
                  size="mini"
                ></el-input>
              </el-form-item>

              <el-form-item label="单据日期" prop="billDate">
                <el-date-picker
                  v-model="form.billDate"
                  type="date"
                  placeholder="选择日期"
                  :disabled="disabled"
                  value-format="yyyy-MM-dd"
                  size="mini"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                label="业务类型"
                v-if="['130105', '130106'].includes(billType)"
                prop="billCustomType"
              >
                <el-select
                  style="width: 250px"
                  size="mini"
                  :disabled="billType == 130106 ? true : disabled"
                  v-model="form.billCustomType"
                  placeholder="请选择业务类型"
                >
                  <el-option label="同价调拨" :value="1"></el-option>
                  <el-option label="异价调拨" :value="2"></el-option>
                </el-select>
              </el-form-item>

              <!-- 插槽暴露的数据 -->
              <slot
                name="selectOneArea"
                :form="form"
                :disabled="disabled"
                :storeData="storeData"
                :getStore="getStore"
                :deptData="deptData"
                :normalizerStore="normalizerStore"
                :getDiyselectId="getDiyselectId"
              ></slot>
            </div>
            <div class="x-f">
              <slot name="selectTwoArea" :form="form" :disabled="disabled"></slot>
            </div>
          </template>
        </cardTitleCom>

        <!-- 表格商品信息卡片 -->
        <cardTitleCom cardTitle="商品信息">
          <template slot="rightCardTitle">
            <div class="marR10">
              <el-button
                type="primary"
                :disabled="disabled"
                v-if="['130102', '130103'].includes(billType)"
                size="mini"
                @click="getDistribution(0)"
                >批量修改仓库</el-button
              >
              <el-button
                type="primary"
                :disabled="disabled"
                v-if="['130104'].includes(billType)"
                size="mini"
                @click="getDistribution(2)"
                >批量修改调入仓库</el-button
              >
              <el-button
                type="primary"
                :disabled="disabled"
                v-if="['130104'].includes(billType)"
                size="mini"
                @click="getDistribution(1)"
                >批量修改调出仓库</el-button
              >
              <el-button
                type="primary"
                size="mini"
                :disabled="form.billStatus != '0'"
                @click="handleOpenImport"
                class="marL10"
                >导入</el-button
              >
            </div>
          </template>
          <template slot="cardContent">
            <div class="store-table">
              <el-row>
                <el-col
                  :span="24"
                  v-if="['130101', '140309', '170309', '170409'].includes(billType)"
                >
                  <vxe-table
                    class="table"
                    ref="dragTable"
                    :data="form.details"
                    border
                    show-overflow
                    max-height="600px"
                    height="600px"
                    show-header-overflow
                    :checkbox-config="{ trigger: 'row' }"
                    :row-config="{ isHover: true }"
                    :sort-config="{ trigger: 'cell' }"
                    :scroll-y="{ enabled: true }"
                    :footer-method="footerMethod"
                    show-footer
                  >
                    <!-- show-summary -->
                    <!-- @selection-change="handleSelectionChange" -->
                    <!-- :summary-method="getStoreSummaries" -->
                    <!-- @cell-mouse-enter="cellMouseEnter" -->
                    <!-- @cell-mouse-leave="cellMouseLeave" -->
                    <!-- <vxe-column
                      align="center"
                      width="80"
                      v-if="!['130106'].includes(billType)"
                      :title="tableCellLabel"
                    >
                      <template slot-scope="scope">
                        <i
                          v-if="scope.row.hoverRow && !disabled"
                          @click="row('push', scope.$index)"
                          class="el-icon-circle-plus operatePush"
                        ></i>
                        <i
                          v-if="scope.row.hoverRow && !disabled"
                          class="el-icon-remove operateDel"
                          @click="row('del', scope.$index)"
                        ></i>
                        <div v-if="!scope.row.hoverRow">
                          {{ scope.$index + 1 }}
                        </div>
                      </template>
                    </vxe-column> -->
                    <!-- <vxe-column
                      align="center"
                      width="80"
                      v-if="['130106'].includes(billType)"
                      :title="tableCellLabel"
                    >
                      <template slot-scope="scope">
                        <div>
                          {{ scope.$index + 1 }}
                        </div>
                      </template>
                    </vxe-column> -->
                    <vxe-column
                      align="center"
                      :title="tableCellLabel"
                      width="80"
                      fixed="left"
                    >
                      <template #default="scope">
                        <div
                          @mouseenter="cellMouseEnter(scope.row)"
                          @mouseleave="cellMouseLeave(scope.row)"
                        >
                          <i
                            v-show="scope.row.hoverRow && !disabled"
                            @click="row('push', scope.rowIndex)"
                            class="el-icon-circle-plus operatePush"
                          ></i>
                          <i
                            v-show="scope.row.hoverRow && !disabled"
                            class="el-icon-remove operateDel"
                            @click="row('del', scope.rowIndex)"
                          ></i>
                          <div v-show="!scope.row.hoverRow">
                            {{ scope.rowIndex + 1 }}
                          </div>
                        </div>
                      </template>
                    </vxe-column>
                    <vxe-column
                      title="商品编码"
                      width="160"
                      align="center"
                      field="goodsNo"
                      fixed="left"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        商品编码
                      </template>
                      <template #default="scope">
                        <el-form-item
                          :prop="`details[${scope.rowIndex}].goodsNo`"
                          :rules="rules[`details.goodsNo`]"
                        >
                          <SelectRemote
                            size="mini"
                            :disabled="['130106'].includes(billType) ? true : disabled"
                            v-model="scope.row.goodsNo"
                            :option="optionGoods"
                            :filterable="true"
                            placeholder="选择商品"
                            :ref="
                              'goodsNo' +
                              '_' +
                              scope.rowIndex +
                              '_' +
                              canKeyTableColumnList[0].columnindex
                            "
                            @handleSelectKeydown="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'goodsNo',
                                scope.rowIndex,
                                canKeyTableColumnList[0].columnindex
                              )
                            "
                          />
                        </el-form-item>
                      </template>
                    </vxe-column>
                    <vxe-column
                      title="商品名称"
                      width="160"
                      align="center"
                      field="goodsName"
                      fixed="left"
                    >
                    </vxe-column>
                    <vxe-column
                      title="条码"
                      width="160"
                      align="center"
                      field="barcode"
                      fixed="left"
                    >
                    </vxe-column>
                    <slot name="tableFront"></slot>
                    <vxe-column title="规格" width="120" align="center" field="goodsSpec">
                      <template v-slot="scope">
                        <el-form-item
                          :prop="`details[${scope.rowIndex}].unitId`"
                          :rules="rules[`details.unitId`]"
                        >
                          <SelectLocal
                            v-model="scope.row.unitId"
                            :disabled="
                              disabled || (!scope.row.unitId && scope.row.unitId != 0)
                            "
                            :ref="
                              'goodsSpec' +
                              '_' +
                              scope.rowIndex +
                              '_' +
                              canKeyTableColumnList[1].columnindex
                            "
                            @handleSelectKeydown="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'goodsSpec',
                                scope.rowIndex,
                                canKeyTableColumnList[1].columnindex
                              )
                            "
                            :option="{
                              data: scope.row.units,
                              label: 'goodsSpec',
                              value: 'unitId',
                            }"
                            @blur="
                              $refs.form.validateField(
                                `details[${scope.rowIndex}].unitId`
                              )
                            "
                            @selectChange="
                              (v, row) =>
                                ['130101', '140309', '170309', '170409'].includes(
                                  billType
                                )
                                  ? checkTableChange(
                                      'unitId',
                                      scope.rowIndex,
                                      row,
                                      scope.row
                                    )
                                  : changeUnitPrice(
                                      scope.row.unitId,
                                      scope.row.units,
                                      scope.rowIndex,
                                      false
                                    )
                            "
                          />
                        </el-form-item>
                      </template>
                    </vxe-column>

                    <vxe-column
                      title="账面数"
                      align="center"
                      width="120"
                      field="basUnitAccountQty"
                    >
                    </vxe-column>
                    <vxe-column
                      title="基本单位盘点数"
                      align="center"
                      width="120"
                      field="basUnitQty"
                    >
                    </vxe-column>
                    <vxe-column
                      v-if="!['130105'].includes(billType)"
                      title="商品批号"
                      width="160"
                      align="center"
                    >
                      <template v-slot="scope">
                        <el-input
                          v-model="scope.row.batchNo"
                          size="mini"
                          :disabled="['130106'].includes(billType) ? true : disabled"
                          @blur="watchScroll"
                          :ref="
                            'batchNo' +
                            '_' +
                            scope.rowIndex +
                            '_' +
                            canKeyTableColumnList[2].columnindex
                          "
                          @keydown.native="
                            handleTableKeydown(
                              $event,
                              scope.row,
                              'batchNo',
                              scope.rowIndex,
                              canKeyTableColumnList[2].columnindex
                            )
                          "
                        ></el-input>
                      </template>
                    </vxe-column>

                    <slot name="tableMiddleSpecial"></slot>
                    <vxe-column title="单位" align="center" width="120">
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        单位
                      </template>
                      <template v-slot="scope">
                        <el-form-item
                          :prop="`details[${scope.rowIndex}].unitId`"
                          :rules="rules[`details.unitId`]"
                        >
                          <SelectLocal
                            v-model="scope.row.unitId"
                            :disabled="
                              disabled || (!scope.row.unitId && scope.row.unitId != 0)
                            "
                            :ref="
                              'unitId' +
                              '_' +
                              scope.rowIndex +
                              '_' +
                              canKeyTableColumnList[3].columnindex
                            "
                            @handleSelectKeydown="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitId',
                                scope.rowIndex,
                                canKeyTableColumnList[3].columnindex
                              )
                            "
                            :option="{
                              data: scope.row.units,
                              label: 'unitName',
                              value: 'unitId',
                            }"
                            @blur="
                              $refs.form.validateField(
                                `details[${scope.rowIndex}].unitId`
                              )
                            "
                            @selectChange="
                              (v, row) =>
                                ['130101', '140309', '170309', '170409'].includes(
                                  billType
                                )
                                  ? checkTableChange('unitId', scope.rowIndex, row)
                                  : changeUnitPrice(
                                      scope.row.unitId,
                                      scope.row.units,
                                      scope.rowIndex,
                                      false
                                    )
                            "
                          />
                        </el-form-item>
                      </template>
                    </vxe-column>
                    <vxe-column
                      v-if="
                        TypeBill != '1000' &&
                        billType != '130101' &&
                        billType != '140309' &&
                        billType != '170309' &&
                        billType != '170409'
                      "
                      :title="qtyLabel"
                      align="center"
                      width="120"
                      field="unitQty"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>

                        {{ qtyLabel }}
                      </template>
                      <template v-slot="scope">
                        <el-form-item
                          :prop="`details[${scope.rowIndex}].unitQty`"
                          :rules="rules[`details.unitQty`]"
                        >
                          <el-input
                            v-model="scope.row.unitQty"
                            :disabled="disabled"
                            oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^([0-9-, -]\d*\.?\d{0,8})?.*$/,'$1').replace(/^\./g, '').replace(/^0+(?!\.|$)/g, ''),value = Number(value) >= 9999999 ? 9999999 : value"
                            @blur="onkeyupUnitQty(scope.row)"
                            :sunyunFormat="(v) => $syInput(1, v)"
                            :ref="
                              'unitQty' +
                              '_' +
                              scope.rowIndex +
                              '_' +
                              canKeyTableColumnList[4].columnindex
                            "
                            @keydown.native.up.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty',
                                scope.rowIndex,
                                canKeyTableColumnList[4].columnindex
                              )
                            "
                            @keydown.native.down.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty',
                                scope.rowIndex,
                                canKeyTableColumnList[4].columnindex
                              )
                            "
                            @keydown.native.left.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty',
                                scope.rowIndex,
                                canKeyTableColumnList[4].columnindex
                              )
                            "
                            @keydown.native.right.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty',
                                scope.rowIndex,
                                canKeyTableColumnList[4].columnindex
                              )
                            "
                            @keydown.native.enter.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty',
                                scope.rowIndex,
                                canKeyTableColumnList[4].columnindex
                              )
                            "
                          ></el-input>
                        </el-form-item>
                      </template>
                    </vxe-column>

                    <vxe-column
                      v-if="
                        TypeBill != '1000' &&
                        billType != '130105' &&
                        billType != '130101' &&
                        billType != '170409' &&
                        billType != '140309' &&
                        billType != '170309'
                      "
                      :title="`${
                        billType == 130107
                          ? '盘盈采购单价(元)'
                          : billType == 130108
                          ? '盘亏采购单价(元)'
                          : '单价(元)'
                      }`"
                      align="center"
                      width="140"
                      field="unitPrice"
                    ></vxe-column>
                    <vxe-column
                      v-if="
                        TypeBill != '1000' &&
                        billType != '130105' &&
                        billType != '130101' &&
                        billType != '170409' &&
                        billType != '140309' &&
                        billType != '170309'
                      "
                      :title="moneyLabel"
                      align="center"
                      field="unitMoney"
                      width="140"
                    ></vxe-column>
                    <!-- 盘点 -->
                    <!-- <template v-if="['130101', '140309'].includes(billType)"> -->
                    <vxe-column align="center" width="120" field="unitQty">
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        盘点数
                      </template>
                      <template v-slot="scope">
                        <el-form-item
                          :prop="`details[${scope.rowIndex}].unitQty`"
                          :rules="rules[`details.unitQty`]"
                        >
                          <el-input
                            v-model="scope.row.unitQty"
                            :disabled="disabled"
                            oninput="value=value.replace(/[^\d.-]/g, '').replace(/^-?\d*\.?\d{0,8}.*$/, '$&').replace(/\.{2,}/g, '.').replace(/(?!^)-/g, '').replace(/^(\d+)(\.\d{0,8})?$/, '$1$2').replace(/^0+(?!\.|$)/g, ''),value = Number(value) < -9999999 ? -9999999 : (Number(value) > 9999999 ? 9999999 : value)"
                            @blur="checkTableChange('unitQty', scope.rowIndex)"
                            :sunyunFormat="(v) => $syInput(1, v)"
                            :ref="
                              'unitQty1' +
                              '_' +
                              scope.rowIndex +
                              '_' +
                              canKeyTableColumnList[5].columnindex
                            "
                            @keydown.native.up.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty1',
                                scope.rowIndex,
                                canKeyTableColumnList[5].columnindex
                              )
                            "
                            @keydown.native.down.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty1',
                                scope.rowIndex,
                                canKeyTableColumnList[5].columnindex
                              )
                            "
                            @keydown.native.left.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty1',
                                scope.rowIndex,
                                canKeyTableColumnList[5].columnindex
                              )
                            "
                            @keydown.native.right.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty1',
                                scope.rowIndex,
                                canKeyTableColumnList[5].columnindex
                              )
                            "
                            @keydown.native.enter.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty1',
                                scope.rowIndex,
                                canKeyTableColumnList[5].columnindex
                              )
                            "
                          ></el-input>
                        </el-form-item>
                      </template>
                    </vxe-column>
                    <vxe-colgroup title="盘点进价金额" align="center">
                      <!-- <vxe-column
                        title="单位进价(元)"
                        align="center"
                        field="basUnitPurPrice"
                        width="150"
                      >
                        <template v-slot="scope">
                          <span>{{ $syInput(3, scope.row.basUnitPurPrice) }}</span>
                        </template>
                      </vxe-column> -->
                      <vxe-column
                        title="单位进价(元)"
                        align="center"
                        field="unitPurPrice"
                        width="150"
                      >
                        <template v-slot="scope">
                          <span>{{ $syInput(3, scope.row.unitPurPrice) }}</span>
                        </template>
                      </vxe-column>
                      <!-- <vxe-column
                        title="进价金额(元)"
                        align="center"
                        field="basUnitPurMoney"
                        width="150"
                      >
                        <template v-slot="scope">
                          <span>{{ $syInput(3, scope.row.basUnitPurMoney) }}</span>
                        </template>
                      </vxe-column> -->
                      <vxe-column
                        title="进价金额(元)"
                        align="center"
                        field="unitPurMoney"
                        width="150"
                      >
                        <template v-slot="scope">
                          <span>{{ $syInput(3, scope.row.unitPurMoney) }}</span>
                        </template>
                      </vxe-column>
                    </vxe-colgroup>
                    <!--  -->
                    <vxe-colgroup title="盘点售价金额" align="center">
                      <vxe-column
                        title="单位售价(元)"
                        align="center"
                        field="unitSalePrice"
                        width="150"
                      >
                        <template v-slot="scope">
                          <span>{{ $syInput(3, scope.row.unitSalePrice) }}</span>
                        </template>
                      </vxe-column>
                      <vxe-column
                        title="售价金额(元)"
                        align="center"
                        field="unitSaleMoney"
                        width="150"
                      >
                        <template v-slot="scope">
                          <span>{{ $syInput(3, scope.row.unitSaleMoney) }}</span>
                        </template>
                      </vxe-column>
                    </vxe-colgroup>
                    <vxe-column
                      title="盘盈数量"
                      align="center"
                      width="120"
                      field="basUnitAdjustQty"
                    >
                    </vxe-column>
                    <vxe-colgroup title="盘盈金额" align="center">
                      <vxe-column
                        title="进价金额(元)"
                        align="center"
                        field="basUnitAdjustPurPrice"
                        width="150"
                      >
                        <template v-slot="scope">
                          <span>{{ $syInput(3, scope.row.basUnitAdjustPurPrice) }}</span>
                        </template>
                      </vxe-column>
                      <vxe-column
                        title="售价金额(元)"
                        align="center"
                        field="basUnitAdjustSalePrice"
                        width="150"
                      >
                        <template v-slot="scope">
                          <span>{{ $syInput(3, scope.row.basUnitAdjustSalePrice) }}</span>
                        </template>
                      </vxe-column>
                    </vxe-colgroup>
                    <vxe-column
                      title="盘亏数量"
                      align="center"
                      width="120"
                      field="basUnitLoseQty"
                    >
                    </vxe-column>
                    <vxe-colgroup title="盘亏金额" align="center">
                      <vxe-column
                        title="进价金额(元)"
                        align="center"
                        field="basUnitLosePurPrice"
                        width="150"
                      >
                        <template v-slot="scope">
                          <span>{{ $syInput(3, scope.row.basUnitLosePurPrice) }}</span>
                        </template>
                      </vxe-column>
                      <vxe-column
                        title="售价金额(元)"
                        align="center"
                        field="basUnitLoseSalePrice"
                        width="150"
                      >
                        <template v-slot="scope">
                          <span>{{ $syInput(3, scope.row.basUnitLoseSalePrice) }}</span>
                        </template>
                      </vxe-column>
                    </vxe-colgroup>
                    <!-- </template> -->
                    <slot name="tableSpecial"></slot>
                    <vxe-column title="备注" align="center" min-width="200">
                      <template v-slot="scope">
                        <el-input
                          v-if="!['130106'].includes(billType)"
                          v-model="scope.row.remark"
                          :ref="
                            'remark' +
                            '_' +
                            scope.rowIndex +
                            '_' +
                            canKeyTableColumnList[6].columnindex
                          "
                          @keydown.native="
                            handleTableKeydown(
                              $event,
                              scope.row,
                              'remark',
                              scope.rowIndex,
                              canKeyTableColumnList[6].columnindex
                            )
                          "
                          :disabled="
                            disabled &&
                            (disabledRemark === undefined || disabledRemark === true)
                          "
                        ></el-input>
                        <span v-if="['130106'].includes(billType)">{{
                          scope.row.remark
                        }}</span>
                      </template>
                    </vxe-column>
                  </vxe-table>
                </el-col>
                <el-col
                  :span="24"
                  v-if="!['130101', '140309', '170309', '170409'].includes(billType)"
                >
                  <el-table
                    class="table"
                    ref="dragTable"
                    :data="form.details"
                    row-key="columnId"
                    height="500"
                    border
                    show-summary
                    @selection-change="handleSelectionChange"
                    :summary-method="getStoreSummaries"
                    @cell-mouse-enter="cellMouseEnter"
                    @cell-mouse-leave="cellMouseLeave"
                  >
                    <el-table-column
                      v-if="['130102', '130103', '130104'].includes(billType)"
                      type="selection"
                      width="60"
                      align="center"
                    />
                    <el-table-column
                      align="center"
                      width="80"
                      v-if="!['130106'].includes(billType)"
                      :label="tableCellLabel"
                    >
                      <template slot-scope="scope">
                        <i
                          v-if="scope.row.hoverRow && !disabled"
                          @click="row('push', scope.$index)"
                          class="el-icon-circle-plus operatePush"
                        ></i>
                        <i
                          v-if="scope.row.hoverRow && !disabled"
                          class="el-icon-remove operateDel"
                          @click="row('del', scope.$index)"
                        ></i>
                        <div v-if="!scope.row.hoverRow">
                          {{ scope.$index + 1 }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      width="80"
                      v-if="['130106'].includes(billType)"
                      :label="tableCellLabel"
                    >
                      <template slot-scope="scope">
                        <div>
                          {{ scope.$index + 1 }}
                        </div>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column
                      label="商品编码"
                      width="160"
                      align="center"
                      prop="goodsNo"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        商品编码
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="`details[${scope.$index}].goodsNo`"
                          :rules="rules[`details.goodsNo`]"
                        >
                          <el-input
                            v-model="scope.row.goodsNo"
                            :disabled="['130106'].includes(billType) ? true : disabled"
                            @keyup.enter.native="keyup('goodsNo', $event, scope.$index)"
                            @blur="watchScroll"
                            @focus="onGoodsNoFocus"
                          >
                            <i
                              slot="suffix"
                              class="el-icon-more more"
                              v-if="!['130106'].includes(billType)"
                              @click="openSelectGoods(false)"
                            ></i>
                          </el-input>
                        </el-form-item>
                      </template>
                    </el-table-column> -->
                    <el-table-column
                      label="商品编码"
                      width="160"
                      align="center"
                      prop="goodsNo"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        商品编码
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="`details[${scope.$index}].goodsNo`"
                          :rules="rules[`details.goodsNo`]"
                        >
                          <SelectRemote
                            size="mini"
                            :disabled="['130106'].includes(billType) ? true : disabled"
                            v-model="scope.row.goodsNo"
                            :option="optionGoods"
                            :filterable="true"
                            placeholder="选择商品"
                            :ref="
                              'goodsNo' +
                              '_' +
                              scope.$index +
                              '_' +
                              canKeyTableColumnList[0].columnindex
                            "
                            @handleSelectKeydown="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'goodsNo',
                                scope.$index,
                                canKeyTableColumnList[0].columnindex
                              )
                            "
                          />
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="商品名称"
                      align="center"
                      prop="goodsName"
                      show-overflow-tooltip
                      width="120"
                    ></el-table-column>
                    <el-table-column
                      label="条码"
                      width="160"
                      align="center"
                      prop="barcode"
                      show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                      v-if="['130103', '130102'].includes(billType)"
                      label="仓库"
                      align="center"
                      width="180"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        仓库
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="`details[${scope.$index}].storeId`"
                          :rules="rules[`details.storeId`]"
                        >
                          <SelectRemote
                            :disabled="disabled"
                            v-model="scope.row.storeId"
                            style="width: 150px"
                            :option="$select({ key: 'listStore' }).option"
                            :ref="
                              'storeId' +
                              '_' +
                              scope.$index +
                              '_' +
                              canKeyTableColumnList[1].columnindex
                            "
                            @selectChange="dataUpdate(scope, 'storeId')"
                            @handleSelectKeydown="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'storeId',
                                scope.$index,
                                canKeyTableColumnList[1].columnindex
                              )
                            "
                          />
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      v-if="['130104'].includes(billType)"
                      label="调入仓库"
                      align="center"
                      width="180"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        调入仓库
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="`details[${scope.$index}].inStoreId`"
                          :rules="rules[`details.inStoreId`]"
                        >
                          <SelectRemote
                            :disabled="disabled"
                            v-model="scope.row.inStoreId"
                            style="width: 150px"
                            :option="$select({ key: 'listStore' }).option"
                            :ref="
                              'inStoreId' +
                              '_' +
                              scope.$index +
                              '_' +
                              canKeyTableColumnList[2].columnindex
                            "
                            @handleSelectKeydown="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'inStoreId',
                                scope.$index,
                                canKeyTableColumnList[2].columnindex
                              )
                            "
                          />
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      v-if="['130104'].includes(billType)"
                      label="调出仓库"
                      align="center"
                      width="180"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        调出仓库
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="`details[${scope.$index}].outStoreId`"
                          :rules="rules[`details.outStoreId`]"
                        >
                          <SelectRemote
                            :disabled="disabled"
                            v-model="scope.row.outStoreId"
                            style="width: 150px"
                            :option="$select({ key: 'listStore' }).option"
                            :ref="
                              'outStoreId' +
                              '_' +
                              scope.$index +
                              '_' +
                              canKeyTableColumnList[3].columnindex
                            "
                            @handleSelectKeydown="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'outStoreId',
                                scope.$index,
                                canKeyTableColumnList[3].columnindex
                              )
                            "
                          />
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <slot name="tableFront"></slot>
                    <el-table-column
                      label="规格"
                      width="120"
                      align="center"
                      prop="goodsSpec"
                      show-overflow-tooltip
                    >
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="`details[${scope.$index}].unitId`"
                          :rules="rules[`details.unitId`]"
                        >
                          <SelectLocal
                            v-model="scope.row.unitId"
                            :disabled="
                              disabled || (!scope.row.unitId && scope.row.unitId != 0)
                            "
                            :ref="
                              'goodsSpec' +
                              '_' +
                              scope.$index +
                              '_' +
                              canKeyTableColumnList[4].columnindex
                            "
                            @handleSelectKeydown="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'goodsSpec',
                                scope.$index,
                                canKeyTableColumnList[4].columnindex
                              )
                            "
                            :option="{
                              data: scope.row.units,
                              label: 'goodsSpec',
                              value: 'unitId',
                            }"
                            @blur="
                              $refs.form.validateField(`details[${scope.$index}].unitId`)
                            "
                            @selectChange="
                              (v, row) =>
                                ['130101', '140309', '170309', '170409'].includes(
                                  billType
                                )
                                  ? checkTableChange('unitId', scope.$index, row)
                                  : changeUnitPrice(
                                      scope.row.unitId,
                                      scope.row.units,
                                      scope.$index,
                                      false,
                                      scope.row
                                    )
                            "
                          />
                        </el-form-item>
                      </template>
                    </el-table-column>

                    <el-table-column
                      v-if="!['130105'].includes(billType)"
                      label="商品批号"
                      width="160"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.batchNo"
                          size="mini"
                          :disabled="['130106'].includes(billType) ? true : disabled"
                          @blur="watchScroll(scope.row, scope.$index, 'batchNo')"
                          :ref="
                            'batchNo' +
                            '_' +
                            scope.$index +
                            '_' +
                            canKeyTableColumnList[5].columnindex
                          "
                          @keydown.native="
                            handleTableKeydown(
                              $event,
                              scope.row,
                              'batchNo',
                              scope.$index,
                              canKeyTableColumnList[5].columnindex
                            )
                          "
                        ></el-input>
                      </template>
                    </el-table-column>

                    <slot name="tableMiddleSpecial"></slot>
                    <el-table-column label="单位" align="center" width="120">
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        单位
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="`details[${scope.$index}].unitId`"
                          :rules="rules[`details.unitId`]"
                        >
                          <!-- <el-select
                            v-model="scope.row.unitId"
                            :disabled="['130106'].includes(billType) ? true : disabled"
                            @blur="
                              $refs.form.validateField(`details[${scope.$index}].unitId`)
                            "
                            @change="
                              changeUnitPrice(
                                scope.row.unitId,
                                scope.row.units,
                                scope.$index,
                                false
                              )
                            "
                          >
                            <el-option
                              v-for="item in scope.row.units"
                              :key="item.unitId"
                              :label="item.unitName"
                              :value="item.unitId"
                            />
                          </el-select> -->
                          <SelectLocal
                            v-model="scope.row.unitId"
                            :disabled="
                              disabled || (!scope.row.unitId && scope.row.unitId != 0)
                            "
                            :ref="
                              'unitId' +
                              '_' +
                              scope.$index +
                              '_' +
                              canKeyTableColumnList[6].columnindex
                            "
                            @handleSelectKeydown="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitId',
                                scope.$index,
                                canKeyTableColumnList[6].columnindex
                              )
                            "
                            :option="{
                              data: scope.row.units,
                              label: 'unitName',
                              value: 'unitId',
                            }"
                            @blur="
                              $refs.form.validateField(`details[${scope.$index}].unitId`)
                            "
                            @selectChange="
                              (v, row) =>
                                ['130101', '140309', '170309', '170409'].includes(
                                  billType
                                )
                                  ? checkTableChange('unitId', scope.$index, row)
                                  : changeUnitPrice(
                                      scope.row.unitId,
                                      scope.row.units,
                                      scope.$index,
                                      false,
                                      scope.row
                                    )
                            "
                          />
                        </el-form-item>
                      </template>
                    </el-table-column>

                    <el-table-column
                      v-if="
                        TypeBill != '1000' && billType != '130101' && billType != '140309'
                      "
                      :label="qtyLabel"
                      align="center"
                      width="120"
                      prop="unitQty"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>

                        {{ qtyLabel }}
                      </template>
                      <template slot-scope="scope">
                        <el-form-item
                          :prop="`details[${scope.$index}].unitQty`"
                          :rules="rules[`details.unitQty`]"
                        >
                          <el-input
                            v-model="scope.row.unitQty"
                            :disabled="disabled"
                            oninput="value=value.replace(/[^\d.-]/g, '').replace(/^-?\d*\.?\d{0,8}.*$/, '$&').replace(/\.{2,}/g, '.').replace(/(?!^)-/g, '').replace(/^(\d+)(\.\d{0,8})?$/, '$1$2').replace(/^0+(?!\.|$)/g, ''),value = Number(value) < -9999999 ? -9999999 : (Number(value) > 9999999 ? 9999999 : value)"
                            @blur="onkeyupUnitQty(scope.row)"
                            :sunyunFormat="(v) => $syInput(1, v)"
                            :ref="
                              'unitQty' +
                              '_' +
                              scope.$index +
                              '_' +
                              canKeyTableColumnList[7].columnindex
                            "
                            @keydown.native.up.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty',
                                scope.$index,
                                canKeyTableColumnList[7].columnindex
                              )
                            "
                            @keydown.native.down.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty',
                                scope.$index,
                                canKeyTableColumnList[7].columnindex
                              )
                            "
                            @keydown.native.left.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty',
                                scope.$index,
                                canKeyTableColumnList[7].columnindex
                              )
                            "
                            @keydown.native.right.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty',
                                scope.$index,
                                canKeyTableColumnList[7].columnindex
                              )
                            "
                            @keydown.native.enter.prevent="
                              handleTableKeydown(
                                $event,
                                scope.row,
                                'unitQty',
                                scope.$index,
                                canKeyTableColumnList[7].columnindex
                              )
                            "
                          ></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      v-if="['130102', '130103'].includes(billType)"
                      label="库存数"
                      align="center"
                      min-width="120"
                      prop="stockQtyText"
                    >
                    </el-table-column>
                    <el-table-column
                      v-if="
                        TypeBill != '1000' &&
                        billType != '130105' &&
                        billType != '130101' &&
                        billType != '140309'
                      "
                      :label="`${
                        billType == 130107
                          ? '盘盈采购单价(元)'
                          : billType == 130108
                          ? '盘亏采购单价(元)'
                          : '单价(元)'
                      }`"
                      align="center"
                      width="140"
                      prop="unitPrice"
                      show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                      v-if="
                        TypeBill != '1000' &&
                        billType != '130105' &&
                        billType != '130101' &&
                        billType != '140309'
                      "
                      :label="moneyLabel"
                      align="center"
                      prop="unitMoney"
                      width="140"
                    ></el-table-column>
                    <el-table-column
                      prop="unitSalePrice"
                      v-if="['130107', '130108'].includes(billType)"
                      :label="`${
                        billType == 130107
                          ? '盘盈销售单价(元)'
                          : billType == 130108
                          ? '盘亏销售单价(元)'
                          : ''
                      }`"
                      align="center"
                      width="200"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.unitSalePrice }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="unitSaleMoney"
                      v-if="['130107', '130108'].includes(billType)"
                      :label="`${
                        billType == 130107
                          ? '盘盈销售金额'
                          : billType == 130108
                          ? '盘亏销售金额'
                          : ''
                      }`"
                      align="center"
                      width="200"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.unitSaleMoney }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="调拨单价金额"
                      align="center"
                      min-width="150"
                      v-if="['130105'].includes(billType) && form.billCustomType == 2"
                    >
                      <el-table-column
                        label="调拨单价(元)"
                        align="center"
                        width="120"
                        prop="unitPrice"
                      >
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="'details.' + scope.$index + '.unitPrice'"
                            :rules="rules[`details.unitPrice`]"
                          >
                            <el-input
                              @mousewheel.native.prevent
                              @DOMMouseScroll.native.prevent
                              type="number"
                              @keyup.native="onkeyupUnitQty(scope.row)"
                              v-model="scope.row.unitPrice"
                              :disabled="disabled"
                              maxlength="7"
                              :sunyunFormat="(v) => $syInput(2, v)"
                              :ref="
                                'unitPrice' +
                                '_' +
                                scope.$index +
                                '_' +
                                canKeyTableColumnList[8].columnindex
                              "
                              @keydown.native.up.prevent="
                                handleTableKeydown(
                                  $event,
                                  scope.row,
                                  'unitPrice',
                                  scope.$index,
                                  canKeyTableColumnList[8].columnindex
                                )
                              "
                              @keydown.native.down.prevent="
                                handleTableKeydown(
                                  $event,
                                  scope.row,
                                  'unitPrice',
                                  scope.$index,
                                  canKeyTableColumnList[8].columnindex
                                )
                              "
                              @keydown.native.left.prevent="
                                handleTableKeydown(
                                  $event,
                                  scope.row,
                                  'unitPrice',
                                  scope.$index,
                                  canKeyTableColumnList[8].columnindex
                                )
                              "
                              @keydown.native.right.prevent="
                                handleTableKeydown(
                                  $event,
                                  scope.row,
                                  'unitPrice',
                                  scope.$index,
                                  canKeyTableColumnList[8].columnindex
                                )
                              "
                              @keydown.native.enter.prevent="
                                handleTableKeydown(
                                  $event,
                                  scope.row,
                                  'unitPrice',
                                  scope.$index,
                                  canKeyTableColumnList[8].columnindex
                                )
                              "
                            ></el-input>
                            <!-- @input="
                                limitInputlength(
                                  scope.row.unitPrice,
                                  scope.row,
                                  'unitPrice',
                                  true
                                )
                              " -->
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="调拨金额(元)"
                        align="center"
                        width="120"
                        prop="unitMoney"
                      >
                      </el-table-column>
                    </el-table-column>
                    <!-- 盘点 -->
                    <template
                      v-if="['130101', '140309', '170309', '170409'].includes(billType)"
                    >
                      <el-table-column align="center" width="120" prop="unitQty">
                        <template slot="header">
                          <i style="color: #ff4949">*</i>
                          盘点数
                        </template>
                        <template slot-scope="scope">
                          <el-form-item
                            :prop="`details[${scope.$index}].unitQty`"
                            :rules="rules[`details.unitQty`]"
                          >
                            <el-input
                              v-model="scope.row.unitQty"
                              :disabled="disabled"
                              oninput="value=value.replace(/[^\d.-]/g, '').replace(/^-?\d*\.?\d{0,8}.*$/, '$&').replace(/\.{2,}/g, '.').replace(/(?!^)-/g, '').replace(/^(\d+)(\.\d{0,8})?$/, '$1$2').replace(/^0+(?!\.|$)/g, ''),value = Number(value) < -9999999 ? -9999999 : (Number(value) > 9999999 ? 9999999 : value)"
                              @blur="checkTableChange('unitQty', scope.$index)"
                              :sunyunFormat="(v) => $syInput(1, v)"
                              :ref="
                                'unitQty1' +
                                '_' +
                                scope.$index +
                                '_' +
                                canKeyTableColumnList[9].columnindex
                              "
                              @keydown.native.up.prevent="
                                handleTableKeydown(
                                  $event,
                                  scope.row,
                                  'unitQty1',
                                  scope.$index,
                                  canKeyTableColumnList[9].columnindex
                                )
                              "
                              @keydown.native.down.prevent="
                                handleTableKeydown(
                                  $event,
                                  scope.row,
                                  'unitQty1',
                                  scope.$index,
                                  canKeyTableColumnList[9].columnindex
                                )
                              "
                              @keydown.native.left.prevent="
                                handleTableKeydown(
                                  $event,
                                  scope.row,
                                  'unitQty1',
                                  scope.$index,
                                  canKeyTableColumnList[9].columnindex
                                )
                              "
                              @keydown.native.right.prevent="
                                handleTableKeydown(
                                  $event,
                                  scope.row,
                                  'unitQty1',
                                  scope.$index,
                                  canKeyTableColumnList[9].columnindex
                                )
                              "
                              @keydown.native.enter.prevent="
                                handleTableKeydown(
                                  $event,
                                  scope.row,
                                  'unitQty1',
                                  scope.$index,
                                  canKeyTableColumnList[9].columnindex
                                )
                              "
                            ></el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="盘点进价金额"
                        align="center"
                        min-width="150"
                      >
                        <el-table-column
                          label="基本单位进价(元)"
                          align="center"
                          width="150"
                          prop="basUnitPurPrice"
                        />
                        <el-table-column
                          label="进价金额(元)"
                          align="center"
                          width="120"
                          prop="basUnitPurMoney"
                        />
                      </el-table-column>
                      <el-table-column
                        label="盘点售价金额"
                        align="center"
                        min-width="150"
                      >
                        <el-table-column
                          label="基本单位售价(元)"
                          align="center"
                          width="150"
                          prop="basUnitSalePrice"
                        />
                        <el-table-column
                          label="售价金额(元)"
                          align="center"
                          width="120"
                          prop="basUnitSaleMoney"
                        />
                      </el-table-column>
                      <el-table-column
                        label="盘盈数量"
                        align="center"
                        prop="basUnitAdjustQty"
                        width="120"
                        show-overflow-tooltip
                      />
                      <el-table-column label="盘盈金额" align="center" min-width="150">
                        <el-table-column
                          label="进价金额(元)"
                          align="center"
                          width="150"
                          prop="basUnitAdjustPurPrice"
                        />
                        <el-table-column
                          label="售价金额(元)"
                          align="center"
                          width="120"
                          prop="basUnitAdjustSalePrice"
                        />
                      </el-table-column>
                      <el-table-column
                        label="盘亏数量"
                        align="center"
                        prop="basUnitLoseQty"
                        width="120"
                        show-overflow-tooltip
                      />
                      <el-table-column label="盘亏金额" align="center" min-width="150">
                        <el-table-column
                          label="进价金额(元)"
                          align="center"
                          width="150"
                          prop="basUnitLosePurPrice"
                        />
                        <el-table-column
                          label="售价金额(元)"
                          align="center"
                          width="120"
                          prop="basUnitLoseSalePrice"
                        />
                      </el-table-column>
                    </template>
                    <el-table-column
                      v-if="['130104'].includes(billType)"
                      label="成本金额"
                      align="center"
                      min-width="150"
                    >
                      <el-table-column
                        label="单位成本价"
                        align="center"
                        prop="unitCostPrice"
                        show-overflow-tooltip
                        width="120"
                      >
                        <template v-slot="scope">
                          <span>{{ $syInput(2, scope.row.unitCostPrice) }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="单位成本额"
                        align="center"
                        prop="unitCostMoney"
                        show-overflow-tooltip
                        width="120"
                      >
                        <template v-slot="scope">
                          <span>{{ $syInput(3, scope.row.unitCostMoney) }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>

                    <slot name="tableSpecial"></slot>
                    <!-- <el-table-column
                      v-if="['130105'].includes(billType)"
                      label="售价金额"
                      align="center"
                      min-width="150"
                    >
                      <el-table-column
                        label="单位售价(元)"
                        align="center"
                        width="120"
                        prop="unitSalePrice"
                      >
                      </el-table-column>
                      <el-table-column
                        label="售价金额(元)"
                        align="center"
                        width="120"
                        prop="unitSaleMoney"
                      >
                      </el-table-column>
                    </el-table-column>
                    <el-table-column
                      v-if="['130105'].includes(billType)"
                      label="进价金额"
                      align="center"
                      min-width="150"
                    >
                      <el-table-column
                        label="单位进价(元)"
                        align="center"
                        width="120"
                        prop="unitPurPrice"
                      >
                      </el-table-column>
                      <el-table-column
                        label="进价金额(元)"
                        align="center"
                        width="120"
                        prop="unitPurMoney"
                      >
                      </el-table-column>
                    </el-table-column> -->
                    <el-table-column label="备注" align="center" min-width="200">
                      <template slot-scope="scope">
                        <el-input
                          v-if="!['130106'].includes(billType)"
                          v-model="scope.row.remark"
                          :ref="
                            'remark' +
                            '_' +
                            scope.$index +
                            '_' +
                            canKeyTableColumnList[10].columnindex
                          "
                          @keydown.native="
                            handleTableKeydown(
                              $event,
                              scope.row,
                              'remark',
                              scope.$index,
                              canKeyTableColumnList[10].columnindex
                            )
                          "
                          :disabled="
                            disabled &&
                            (disabledRemark === undefined || disabledRemark === true)
                          "
                        ></el-input>
                        <span v-if="['130106'].includes(billType)">{{
                          scope.row.remark
                        }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </div>
          </template>
        </cardTitleCom>

        <div class="store-footer">
          <accessoryUpload
            :disabled="disabled && (disabledBtn === undefined || disabledBtn === true)"
            :fileList="form.fileItems"
            @getFileItems="getFileItemsData"
            @delFileItems="delFileItems"
          ></accessoryUpload>
          <div class="right-remark">
            <span>单据备注:</span>
            <el-input
              class="input"
              placeholder="备注"
              :disabled="
                disabled && (disabledRemark === undefined || disabledRemark === true)
              "
              type="textarea"
              :rows="3"
              v-model="form.billRemark"
            ></el-input>
          </div>
        </div>
      </el-form>
      <!-- 选择商品信息框 -->
      <SelectGoods
        :OpenGoods.sync="OpenGoods"
        :queryCode="queryCode"
        @getGoodsIds="getGoods"
        :isStore="isNewFetchGoods"
        :extraParams="selectGoodsExtraParams"
      ></SelectGoods>
      <!-- 引入源单框 -->
      <SelectBills
        :title="title"
        :NoFields="NoFields"
        :tableColumnFields="tableColumnFields"
        :billList="billList"
        :openDialog.sync="openBillDia"
        :loadingDia="loadingDia"
        :billTotal="billTotal"
        @getSelect="storeSelectBills"
        :allBillList="allBillList"
        @getAllBills="getSelectBills"
        @getBillsIds="getSelectIds($event, true)"
        :billType="billType"
        @search="(searchObj) => storeSelectBills(searchObj)"
      ></SelectBills>
      <!-- 弹窗 -->
      <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
      <!-- 导入 -->
      <to-channel
        :visible.sync="openToChannel"
        :downloadFile="downloadFile"
        @getFileUrl="getFileUrl"
        @upload="uploadFile"
      ></to-channel>
      <importError :errVisible.sync="errVisible" :errorList="errorList" />
    </div>
    <div
      class="footer"
      v-if="['130101', '140309', '170309', '170409'].includes(billType)"
    >
      <div class="left"></div>
      <div class="right">
        <div class="item">
          <div class="label">单据金额 :</div>
          <div class="value">{{ billMoney }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import { storeDetailLayout } from "@/utils/mixin/storeModuleMixin.js";
import {
  delFileItems,
  watchScroll,
  scroll,
  destroyScroll,
  scrollEvent,
  savescroll,
  getRegion,
  getCurTime,
  getBasicData,
  getSelectBills,
  reset,
  row,
  getDiyselectId,
  AddBill,
  submitForm,
  onGoodsNoFocus,
  getFileItemsData,
  onkeyupUnitQty,
  getStore,
  getStoreSummaries,
  changeUnitPrice,
  goodsGetStock,
} from "@/views/components/bill/public-layout.js"; //方法统一存放的地方
import { showFormatting, getNewBillNo } from "@/utils/numFormatting";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import SelectGoods from "@/views/components/SelectGoods/SelectGoods";
import SelectBills from "@/views/components/SelectBills/SelectBills";
import accessoryUpload from "@/views/components/accessoryUpload"; //附件上传组件
import { updateInstoreStatus } from "@/api/purchase";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import { httpStoreListCheckGoods } from "@/api/store/index";
import Dialog from "@/components/Dialog";
import { deepCopy, fcount } from "@/utils";
import { getBillNo } from "@/api/codeRule";
import { getNewDate } from "@/utils/newDate"; //单据日期
import tableKeydown from "@/utils/mixin/tableKeydown";
import ToChannel from "@/components/dialogTemplate/toChannel/index.vue";
import importError from "@/views/components/importError"; //错误信息

import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
export default {
  name: "StoreDetail",
  components: {
    SelectLocal,
    Dialog,
    SelectGoods,
    SelectBills,
    Treeselect,
    accessoryUpload,
    cardTitleCom,
    topOperatingButton,
    SelectRemote,
    ToChannel,
    importError,
  },
  mixins: [storeDetailLayout, tableKeydown],
  dicts: ["bill_status"],
  props: {
    billType: {
      required: true,
      type: String,
    },
    orderInfo: {
      type: Object,
      default: undefined,
    },
    shopType: {
      type: Number,
      default: 1,
    },
    tableMiddleSpecialCount: {
      type: Number,
      default: 0,
    },
    tableSpecialCount: {
      type: Number,
      default: 0,
    },
    //导入的相关信息
    importInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    //自定义校验规则
    const setoodsNo = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error("请输入商品编码"));
        // this.$message.error("请输入商品编码");
      } else {
        callback();
      }
    };
    const setUnitId = (rule, value, callback) => {
      if (value === undefined) {
        // callback(new Error("请选择单位"));
        // this.$message.error("请选择单位");
      } else {
        callback();
      }
    };
    const setUnitQty = (rule, value, callback) => {
      if (value === undefined) {
        callback(new Error("请输入数量"));
        this.$message.error("请输入数量");
      } else {
        callback();
      }
    };
    return {
      optionGoods: this.$select({
        key: "listGoods",
        option: {
          option: {
            noEcho: true,
            remoteBody: { excludeGoodsTypes: [-1] },
            change: (propValue, row) => {
              if (propValue) {
                const findIndex = this.form.details.findIndex(
                  (item) => item.goodsNo === propValue
                );
                let rows = JSON.parse(JSON.stringify(row));
                rows.findIndex = findIndex;
                this.handleEvent("getSelectGoods", [rows]);
              } else {
                this.$nextTick(() => {
                  console.log("11");
                });
              }
            },
            tableChange: (propValue, row) => {
              console.log("propValue,", propValue);
              console.log("row,", row);
              this.handleEvent("getSelectGoods", row.check);
            },
            buttons: [
              {
                type: "more",
                option: {
                  title: "选择商品",
                  width: 1250,
                  type: "TreeAndTable",
                  formData: this.$dialog({
                    key: "goods",
                    option: {
                      // treeApi: undefined,
                      queryParams: { excludeGoodsTypes: [-1] },
                      table: {
                        ...this.$dialog({ key: "goods" }).table,
                      },
                    },
                  }),
                },
              },
            ],
          },
        },
      }).option,
      ids: [],
      storeIndex: "",
      dialogOptions: {
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
        formData: null,
      },
      disabledBtn: undefined,
      disabledRemark: undefined,
      fileList: [], // 显示的文件
      isNewFetchGoods: false,
      selectGoodsExtraParams: {},
      tableCellLabel: "序号",
      //打印
      PrintObj: {
        id: "print",
      },
      //数量字段
      qtyLabel: undefined,
      //金额字段
      moneyLabel: undefined,
      //单据类型
      TypeBill: undefined,
      //单据业务类型
      BillSaleType: undefined,
      //区分单价取进价/售价
      unitPriceType: undefined,
      //获取单据详情url
      urlDetail: undefined,
      //修改单据url
      urlUpdate: undefined,
      //新增单据url
      urlAdd: undefined,
      //更新单据状态url
      urlBillStatus: undefined,
      //引入单据url
      urlSourceBill: undefined,
      //单据id
      billId: undefined,
      //更新订单明细数据变动
      iskey: false,
      //选择商品信息开关
      OpenGoods: false,
      //传送商品参数
      queryCode: undefined,
      //遮罩层
      loading: false,
      //禁用标识
      disabled: false,
      //标识已审核
      auditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识已删除
      deleteOrder: false,
      //是否启用审核按钮
      showAudit: true,
      //区别(采购/销售)/仓库时,仓库不需要收货地址
      diySelectType: false,
      //自定义搜索框字段转换 (仓库)
      normalizerStore: {
        Id: "storeId",
        No: "storeNo",
        Name: "storeName",
      },
      //当前表格宽度
      scrollLeft: null,
      //引入源单对话框表格列
      tableColumnFields: [],
      //引入源单框编号字段
      NoFields: [
        { label: "单据编号", name: "billNo" },
        { label: "单据状态", name: "billStatus" },
      ],
      //引入源单框单据数据
      billList: [],
      //引入源单对话框标题
      title: "",
      //引入源单对话框开关
      openBillDia: false,
      //引入源单对话框遮罩层
      loadingDia: false,
      //引入源单单据总数
      billTotal: undefined,
      //引入单据全部数据
      allBillList: [],
      //选择的类型(商品/引入源单)
      selectType: undefined,
      //行政地址类型转换
      defaultProp: {
        value: "id",
        label: "label",
      },
      //仓库数据
      storeData: [],
      //员工数据
      employeeData: [],
      //部门数据
      deptData: [],
      i: {},
      //input保留的小数位数
      decimalNum: 3,
      //需要进行input转换的数据
      showForm: {
        details: [{}],
      },
      //表单数据
      form: {
        //单据id
        billId: undefined,
        //制单日期
        billDate: undefined,
        //单据编号
        billNo: undefined,
        //盘点名称
        checkName: undefined,
        billCustomType: 1,
        //仓库
        storeId: "",
        inStoreId: "",
        outStoreId: "",
        //单据状态
        billStatus: "",
        //制单人
        createEmployeeId: undefined,
        //审核人
        auditEmployeeId: undefined,
        //审核日期
        auditTime: undefined,
        //单据金额
        billMoney: "00:000",
        //备注
        remark: undefined,
        //单据表格-订单明细
        details: [{}],
        //上传的附件
        fileItems: [],
      },
      //表单校验
      rules: {
        billNo: [{ required: true, message: "请到编码规则设置", trigger: "blur" }],
        billDate: [{ required: true, message: "请输入单据日期", trigger: "blur" }],
        billMoney: [{ required: true, message: "请输入单据金额", trigger: "blur" }],
        billNotaxMoney: [
          { required: true, message: "请输入单据不含税金额", trigger: "blur" },
        ],
        billQty: [{ required: true, message: "请输入单据编号", trigger: "blur" }],
        checkName: [{ required: true, message: "请输入盘点名称", trigger: "blur" }],
        storeId: [{ required: true, message: "请选择仓库", trigger: "change" }],
        inStoreId: [{ required: true, message: "请选择仓库", trigger: "change" }],
        outStoreId: [{ required: true, message: "请选择仓库", trigger: "change" }],
        deptId: [{ required: true, message: "请选择部门", trigger: ["change", "blur"] }],
        billPostType: [
          {
            required: true,
            message: "请选择出入库方式",
            trigger: ["change", "blur"],
          },
        ],
        scrapTypeId: [
          { required: true, message: "请选择原因", trigger: ["change", "blur"] },
        ],
        "details.storeId": [
          { required: true, message: "请选择仓库", trigger: ["change", "blur"] },
        ],
        "details.outStoreId": [
          { required: true, message: "请选择调出仓库", trigger: ["change", "blur"] },
        ],
        "details.inStoreId": [
          { required: true, message: "请选择调入仓库", trigger: ["change", "blur"] },
        ],
        "details.goodsNo": [{ validator: setoodsNo, trigger: ["blur", "change"] }],
        "details.unitId": [{ validator: setUnitId, trigger: "change" }],
        "details.unitQty": [{ validator: setUnitQty, trigger: "blur" }],
      },
      keepStatus: false,
      //关闭单据
      single: true,
      //新增按钮
      showAdd: false,
      canKeyTableColumnList: [
        { key: "goodsNo", columnindex: 1, componentRefName: "remoteSelect" },
        { key: "storeId", columnindex: 4, componentRefName: "remoteSelect" },
        { key: "inStoreId", columnindex: 5, componentRefName: "remoteSelect" },
        { key: "outStoreId", columnindex: 6, componentRefName: "remoteSelect" },
        { key: "goodsSpec", columnindex: 7, componentRefName: "remoteSelect" },
        { key: "batchNo", columnindex: 8 },
        { key: "unitId", columnindex: 9, componentRefName: "remoteSelect" },
        { key: "unitQty", columnindex: 10 },
        { key: "unitPrice", columnindex: 15 },
        { key: "unitQty1", columnindex: 17 },
        { key: "remark", columnindex: 32 },
      ],
      canKeyTableColumnList1: [
        { key: "goodsNo", columnindex: 1, componentRefName: "remoteSelect" },
        { key: "goodsSpec", columnindex: 4, componentRefName: "remoteSelect" },
        { key: "batchNo", columnindex: 7 },
        { key: "unitId", columnindex: 8, componentRefName: "remoteSelect" },
        { key: "unitQty", columnindex: 9 },
        { key: "unitQty1", columnindex: 12 },
        { key: "remark", columnindex: 23 },
      ], //可以键盘导航的表格列详情
      canKeyTableColumnList2: [
        { key: "goodsNo", columnindex: 1, componentRefName: "remoteSelect" },
        { key: "storeId", columnindex: 4, componentRefName: "remoteSelect" },
        { key: "inStoreId", columnindex: 5, componentRefName: "remoteSelect" },
        { key: "outStoreId", columnindex: 6, componentRefName: "remoteSelect" },
        { key: "goodsSpec", columnindex: 7, componentRefName: "remoteSelect" },
        { key: "batchNo", columnindex: 8 },
        { key: "unitId", columnindex: 9, componentRefName: "remoteSelect" },
        { key: "unitQty", columnindex: 10 },
        { key: "unitPrice", columnindex: 15 },
        { key: "unitQty1", columnindex: 17 },
        { key: "remark", columnindex: 32 },
      ], //可以键盘导航的表格列详情
      openToChannel: false, // 打开导入弹框
      // 下载模板
      downloadFile: {
        fileName: "盘点导入模板.xlsx", // 文件名
        downloadUrl:
          "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/customFiles/%E7%9B%98%E7%82%B9%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls?timestamp=" +
          new Date(), // 下载地址
      },
      //错误信息
      errVisible: false,
      //错误信息
      errorList: [],
    };
  },
  watch: {
    //侦听传过来的单据类型
    billType: {
      handler(newVal) {
        // if (this.TypeBill === '140303') {
        //   this.BillSaleType = true
        // }
        if (this.$route.query.type === "Update") {
          if (this.$route.query.billId) {
            this.billId = this.$route.query.billId;
          }
        } else {
          this.$nextTick(() => {
            this.getNewBillNo(this.form, "billNo", Number(this.TypeBill));
          });
        }
        if (this.$route.query.type === "Add" && this.billType == "130106") {
          if (this.$route.query.billId) {
            this.billId = this.$route.query.billId;
          }
        }
        console.log("shopType2", this.shopType);
        this.getTableList(newVal, this.shopType);
        let decideFields = ["130101", "140309", "170309", "170409"];
        if (decideFields.includes(this.billType)) {
          this.isNewFetchGoods = true;
          // if (this.form.checkListShowType === 2) {
          //   this.getCheckList();
          // }
        } else {
          this.isNewFetchGoods = false;
        }
        if (
          (!this.billId || this.billId == null || this.billId === "") &&
          this.$route.query.addType &&
          this.$route.query.addType === "moveDiffsProcess"
        ) {
          this.form = this.$route.params.adjustBillInfo;
          this.disabled = true;
          this.disabledRemark = false;
          this.disabledBtn = false;
        }
      },
      immediate: true,
    },
    //侦听是否开启关闭按钮
    "form.billStatus": {
      handler(newVal) {
        if (this.form.billStatus === "2") {
          //新增按钮
          this.showAdd = true;
          //打开关闭按钮
          this.single = false;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
          //仓库调整单禁用备注字段
          this.disabledRemark = true;
          //仓库调整单禁用保存按钮
          this.disabledBtn = true;
        } else if (this.form.billStatus === "3") {
          //新增按钮
          this.showAdd = false;
          //禁用关闭按钮
          this.single = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //禁用审核按钮
          this.showAudit = true;
        } else if (this.form.billStatus === "0") {
          //新增按钮
          this.showAdd = false;
          //打开输入按钮、保存按钮
          this.disabled = false;
          //禁用关闭按钮
          this.single = true;

          //禁用审核按钮
          this.showAudit = false;
          //仓库调整单
          if (this.form.billType === "130102") {
            for (let index = 0; index < this.form.details.length; index++) {
              if (
                this.form.details[index].sourceBillDetailId &&
                this.form.details[index].sourceBillDetailId != null &&
                this.form.details[index].sourceBillDetailId != ""
              ) {
                this.disabled = true;
                //仓库调整单打开备注字段
                this.disabledRemark = false;
                //仓库调整单打开保存按钮
                this.disabledBtn = false;
              }
            }
          }
        } else if (this.form.billStatus === "4") {
          //新增按钮
          this.showAdd = false;

          //禁用关闭按钮
          this.single = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //禁用审核按钮
          this.showAudit = true;
        } else if (this.form.billStatus === "") {
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //禁用关闭按钮
          this.single = true;
          //打开输入按钮、保存按钮
          this.disabled = false;
        }
      },
      immediate: true,
    },
    orderInfo: {
      handler(newVal) {
        if (
          (this.billType == "130106" ||
            this.billType == "130103" ||
            this.billType == "130104") &&
          newVal
        ) {
          this.form = newVal;
          console.log("form", deepCopy(this.form));
        }
      },
      deep: true,
      immediate: true,
    },
  },
  deactivated() {
    this.reset();
    this.disabled = false;
    this.auditOrder = false;
    this.stopOrder = false;
    this.deleteOrder = false;
    this.getCurTime();
    this.getNewBillNo(this.form, "billNo", Number(this.TypeBill));
  },
  activated() {
    this.handleCanKeyTableColumnList();
    if (this.keepStatus) return;
    // console.log(this.billType, 'this.billType')
    if (this.$route.query.type === "Add" && this.billType == "130106") {
      if (this.$route.query.billId) {
        this.billId = this.$route.query.billId;
        this.getBillDetail();
      }
    }
    if (this.$route.query.type === "Update") {
      if (this.$route.query.billId) {
        this.billId = this.$route.query.billId;
        if (this.urlDetail) {
          this.getBillDetail();
        }
      }
    } else {
      this.getCurTime();
    }
  },
  beforeDestroy() {
    //页面销毁移除监听表格滚动条事件
    this.destroyScroll();
  },
  mounted() {
    // console.log(this.billType, 'billType')
    // this.optionGoods =
    this.getCurTime();
    this.scroll(); // 监听表格滚动条滚动事件
  },
  computed: {
    isUpdate() {
      return this.$route.query.type === "Update";
    },
    //单据金额
    billMoney() {
      let data = this.form.details.filter((i) => {
        return i.unitMoney;
      });
      let res = data.reduce((prev, curr) => {
        return prev + Number(curr.unitMoney);
      }, 0);
      const total = res ? res.toFixed(this.decimalNum) : "00:000";
      this.form.billMoney = total;
      return total;
    },
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs && this.$refs.dragTable) this.$refs.dragTable.doLayout();
    });
  },
  methods: {
    // 行数据修改
    async dataUpdate({ $index, row }) {
      if (["130102", "130103"].includes(this.billType)) {
        const list = await goodsGetStock([row]);
        this.$set(this.form.details, $index, {
          ...this.form.details[$index],
          stockQty: list[0].stockQty,
          stockQtyText: list[0].stockQtyText,
        });
      }
    },
    countAllNum(index, str) {
      let count = 0;
      count = this.form.details.reduce((prev, next) => {
        let num = 0;
        if (index == 9) {
          let unitQty = Number(next.unitQty) ? Number(next.unitQty) : 0;
          num = fcount([prev, unitQty], "+");
        } else if (index == 11) {
          let basUnitPurMoney = Number(next.basUnitPurMoney)
            ? Number(next.basUnitPurMoney)
            : 0;
          num = fcount([prev, basUnitPurMoney], "+");
        } else if (index == 13) {
          let basUnitSaleMoney = Number(next.basUnitSaleMoney)
            ? Number(next.basUnitSaleMoney)
            : 0;
          num = fcount([prev, basUnitSaleMoney], "+");
        } else if (index == 14) {
          let basUnitAdjustQty = Number(next.basUnitAdjustQty)
            ? Number(next.basUnitAdjustQty)
            : 0;
          num = fcount([prev, basUnitAdjustQty], "+");
        } else if (index == 16) {
          let basUnitAdjustSalePrice = Number(next.basUnitAdjustSalePrice)
            ? Number(next.basUnitAdjustSalePrice)
            : 0;
          num = fcount([prev, basUnitAdjustSalePrice], "+");
        } else if (index == 17) {
          let basUnitLoseQty = Number(next.basUnitLoseQty)
            ? Number(next.basUnitLoseQty)
            : 0;
          num = fcount([prev, basUnitLoseQty], "+");
        } else if (index == 19) {
          let basUnitLoseSalePrice = Number(next.basUnitLoseSalePrice)
            ? Number(next.basUnitLoseSalePrice)
            : 0;
          num = fcount([prev, basUnitLoseSalePrice], "+");
        }
        return num;
      }, 0);
      return count;
    },
    footerMethod({ columns, data }) {
      console.log(columns, data, "columns");
      return [
        columns.map((column, columnIndex) => {
          // console.log(column, columnIndex, 'columnIndex')
          if (columnIndex === 0) {
            return "合计";
          }
          if ([9, 11, 13, 14, 16, 17, 19].includes(columnIndex)) {
            return `${this.countAllNum(columnIndex, "unitQty")}`;
          }
          return "";
        }),
      ];
    },
    //处理表格中可键盘导航的数据
    handleCanKeyTableColumnList() {
      //第一个表格
      if (["130105"].includes(this.billType)) {
        //不包含批号
        this.canKeyTableColumnList1.forEach((item, index) => {
          if (index > 2) {
            this.$set(item, "columnindex", item.columnindex - 1);
          }
          if (item.key == "batchNo") {
            this.$set(this.canKeyTableColumnList1[index], "isDeleted", true);
          }
        });
      }
      if (
        !(
          this.TypeBill != "1000" &&
          this.billType != "130101" &&
          this.billType != "140309" &&
          this.billType != "170309" &&
          this.billType != "170409"
        )
      ) {
        //不包含数量
        this.canKeyTableColumnList1.forEach((item, index) => {
          if (index > 4) {
            this.$set(item, "columnindex", item.columnindex - 1);
          }
          if (item.key == "unitQty") {
            this.$set(this.canKeyTableColumnList1[index], "isDeleted", true);
          }
        });
      }
      if (
        !(
          this.TypeBill != "1000" &&
          this.billType != "130105" &&
          this.billType != "130101" &&
          this.billType != "170409" &&
          this.billType != "140309"
        )
      ) {
        this.canKeyTableColumnList1.forEach((item, index) => {
          if (index > 4) {
            this.$set(item, "columnindex", item.columnindex - 2);
          }
        });
      }
      if (["130106"].includes(this.billType)) {
        this.canKeyTableColumnList1.forEach((item, index) => {
          if (item.key == "remark") {
            this.$set(this.canKeyTableColumnList1[index], "isDeleted", true);
          }
        });
      }

      //第二个表格
      // if(['130102', '130103', '130104'].includes(this.billType)){
      //   this.canKeyTableColumnList2.forEach(item=>{
      //       this.$set(item,'columnindex',item.columnindex+1);
      //   })
      // }
      if (!["130103", "130102"].includes(this.billType)) {
        //没有仓库
        this.canKeyTableColumnList2.forEach((item, index) => {
          if (index > 1) {
            this.$set(item, "columnindex", item.columnindex - 1);
          }
          if (item.key == "storeId") {
            this.$set(this.canKeyTableColumnList2[index], "isDeleted", true);
          }
        });
      }
      if (!["130104"].includes(this.billType)) {
        //没有调入仓库
        this.canKeyTableColumnList2.forEach((item, index) => {
          if (index > 2) {
            this.$set(item, "columnindex", item.columnindex - 1);
          }
          if (item.key == "inStoreId") {
            this.$set(this.canKeyTableColumnList2[index], "isDeleted", true);
          }
        });
      }
      if (!["130104"].includes(this.billType)) {
        //没有调出仓库
        this.canKeyTableColumnList2.forEach((item, index) => {
          if (index > 3) {
            this.$set(item, "columnindex", item.columnindex - 1);
          }
          if (item.key == "outStoreId") {
            this.$set(this.canKeyTableColumnList2[index], "isDeleted", true);
          }
        });
      }
      if (["130105"].includes(this.billType)) {
        //没有商品批号
        this.canKeyTableColumnList2.forEach((item, index) => {
          if (index > 5) {
            this.$set(item, "columnindex", item.columnindex - 1);
          }
          if (item.key == "batchNo") {
            this.$set(this.canKeyTableColumnList2[index], "isDeleted", true);
          }
        });
      }
      if (
        !(
          this.TypeBill != "1000" &&
          this.billType != "130101" &&
          this.billType != "140309"
        )
      ) {
        //没有数量
        this.canKeyTableColumnList2.forEach((item, index) => {
          if (index > 7) {
            this.$set(item, "columnindex", item.columnindex - 1);
          }
          if (item.key == "unitQty") {
            this.$set(this.canKeyTableColumnList2[index], "isDeleted", true);
          }
        });
      }
      if (
        !(
          this.TypeBill != "1000" &&
          this.billType != "130105" &&
          this.billType != "130101" &&
          this.billType != "140309"
        )
      ) {
        this.canKeyTableColumnList2.forEach((item, index) => {
          if (index > 7) {
            this.$set(item, "columnindex", item.columnindex - 2);
          }
        });
      }
      if (
        !(
          this.TypeBill != "1000" &&
          this.billType != "130105" &&
          this.billType != "130101" &&
          this.billType != "140309"
        )
      ) {
        this.canKeyTableColumnList2.forEach((item, index) => {
          if (index > 7) {
            this.$set(item, "columnindex", item.columnindex - 1);
          }
        });
      }
      if (!["130107", "130108"].includes(this.billType)) {
        this.canKeyTableColumnList2.forEach((item, index) => {
          if (index > 7) {
            this.$set(item, "columnindex", item.columnindex - 2);
          }
        });
      }
      if (!(["130105"].includes(this.billType) && this.form.billCustomType == 2)) {
        this.canKeyTableColumnList2.forEach((item, index) => {
          if (index > 8) {
            this.$set(item, "columnindex", item.columnindex - 1);
          }
          if (item.key == "unitPrice") {
            this.$set(this.canKeyTableColumnList2[index], "isDeleted", true);
          }
        });
      }
      if (!["130101", "140309", "170309", "170409"].includes(this.billType)) {
        this.canKeyTableColumnList2.forEach((item, index) => {
          if (index > 9) {
            this.$set(item, "columnindex", item.columnindex - 1);
          }
          if (item.key == "unitQty1") {
            this.$set(this.canKeyTableColumnList2[index], "isDeleted", true);
          }
        });
      }
      if (!["130105"].includes(this.billType)) {
        this.canKeyTableColumnList2.forEach((item, index) => {
          if (index > 9) {
            this.$set(item, "columnindex", item.columnindex - 4);
          }
        });
      }
      if (["130106"].includes(this.billType)) {
        this.canKeyTableColumnList2.forEach((item, index) => {
          if (item.key == "remark") {
            this.$set(this.canKeyTableColumnList2[index], "isDeleted", true);
          }
        });
      }
      //插槽影响
      if (this.tableMiddleSpecialCount > 0) {
        // this.canKeyTableColumnList1.forEach((item,index)=>{
        //   if(index>2){
        //     this.$set(item,'columnindex',item.columnindex+this.tableMiddleSpecialCount);
        //   }
        // })
        // this.canKeyTableColumnList2.forEach((item,index)=>{
        //   if(index>5){
        //     this.$set(item,'columnindex',item.columnindex+this.tableMiddleSpecialCount);
        //   }
        // })
      }
      if (this.tableSpecialCount > 0) {
        if (!["130106"].includes(this.billType)) {
          //备注存在
          this.canKeyTableColumnList1.forEach((item, index) => {
            if (item.key == "remark") {
              this.$set(item, "columnindex", item.columnindex + this.tableSpecialCount);
            }
          });
          this.canKeyTableColumnList2.forEach((item, index) => {
            if (item.key == "remark") {
              this.$set(item, "columnindex", item.columnindex + this.tableSpecialCount);
            }
          });
        }
      }

      if (["130101", "140309", "170309", "170409"].includes(this.billType)) {
        this.canKeyTableColumnList = this.canKeyTableColumnList1;
      }
      if (!["130101", "140309", "170309", "170409"].includes(this.billType)) {
        this.canKeyTableColumnList = this.canKeyTableColumnList2;
      }
      console.log(this.canKeyTableColumnList, 234243243234234234234);
    },
    handleSelectionChange(selection) {
      this.ids = selection
        .map((item) => item.goodsId)
        .filter((ite) => typeof ite !== "undefined"); //单据id
      this.multiple = !this.ids.length;
    },
    getDistribution(index) {
      this.storeIndex = index;
      if (this.multiple) return this.$message.warning("请选择商品");
      this.dialogOptions.title = "选择仓库";
      this.dialogOptions.formData = this.$dialog({ key: "store" });
      this.dialogOptions.show = true;
    },
    handleOpenImport() {
      this.openToChannel = true;
    },
    async getCheckList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      try {
        let details = [];
        await this.$nextTick();
        let params = {
          pageSize: 100,
          pageNum: 1,
          ...this.$route.query.data,
          checkName: "",
        };
        const res = await this.dialogOptions.formData.table.list(params);
        details = res.rows;
        let ohterTotal = res.total - params.pageSize;
        if (ohterTotal > 0) {
          for (let index = 0; index < ohterTotal / params.pageSize; index++) {
            params.pageNum = index + 2;
            const otherRes = await this.dialogOptions.formData.table.list(params);
            details = [...details, ...otherRes.rows];
          }
        }
        console.log("num mmmmmm", details);
        this.setTableData(details);
      } catch (error) {
        console.log("load check goods err", error);
      }
      try {
        loading.close();
      } catch (error) {}
    },
    async checkTableChange(key, index, row, originRow) {
      const detail = this.form.details[index];
      console.log("checkTableChange --", detail);
      if (key === "unitQty") {
        detail.basUnitQty = fcount([detail.useBaseUnitQty, detail.unitQty], "*");
      } else if (key === "unitId") {
        console.log("rowrowrowrowrowrowrowrowrow", row);
        detail.useBaseUnitQty = row.basUnitQty;
        detail.barcode = row.barcode;
        detail.basUnitQty = fcount([detail.useBaseUnitQty, detail.unitQty], "*");
        this.$set(this.form.details[index], "goodsSpec", row.goodsSpec);
        //if(row.noConvertGoodsId){
        //重新获取账面数
        let params = {
          storeId: this.form.storeId,
          isLockStore: this.form.isLockStore,
          isIncludeZeroStock: this.form.isIncludeZeroStock,
          goodsSource: this.form.checkGoodsSourceType,
          goodsIds: [row.noConvertGoodsId || originRow.goodsId],
        };
        httpStoreListCheckGoods(params).then((response) => {
          let data = response.rows;
          if (data.length > 0) {
            if (data[0]?.stockInfo) {
              this.$set(
                this.form.details[index],
                "basUnitAccountQty",
                data[0]?.stockInfo[0]?.finalQty
              );
            } else {
              this.$set(this.form.details[index], "basUnitAccountQty", 0);
              this.$set(this.form.details[index], "basUnitQty", 0);
            }
          }
        });
        //}
      }
      this.$set(this.form.details, index, detail);

      // let arr = JSON.parse(JSON.stringify(this.form.details));
      // this.form.details = [];
      // this.setTableData(arr);

      await this.$nextTick();
      this.checkDataSum(index);
    },
    checkDataSum(index, inObj = false) {
      // 计算相关金额
      const detail = inObj ? inObj : this.form.details[index];
      console.log("checkDataSum", detail);
      const {
        unitQty, // 盘点数
        useBaseUnitQty, // 初始化 为 basUnitQty 值
        basUnitAccountQty, // 账面数
        purPrice, // 进价
        salePrice, // 售价
        basUnitPurPrice,
        basUnitSalePrice,
        unitPurPrice,
        unitSalePrice,
      } = detail;
      let basUnitQty = fcount([unitQty, useBaseUnitQty], "*"); // 盘点数
      let basUnitAdjustQty = 0; // 盈 数量
      let basUnitAdjustPurPrice = 0; // 盈 进价
      let basUnitAdjustSalePrice = 0; // 盈 售价
      let basUnitLoseQty = 0; // 亏 数量
      let basUnitLosePurPrice = 0; // 亏 进价
      let basUnitLoseSalePrice = 0; // 亏 售价
      let basUnitPurMoney = 0; // 进价金额(元)
      let basUnitSaleMoney = 0; // 售价金额(元)
      let unitPurMoney = 0; // 进价金额(元)
      let unitSaleMoney = 0; // 售价金额(元)

      if (typeof Number(unitQty) === "number") {
        let num = fcount([basUnitQty, basUnitAccountQty], "-");
        if (num >= 0) {
          // 盘盈
          basUnitAdjustQty = num;
          basUnitAdjustPurPrice = fcount([basUnitPurPrice, num], "*", { type: "round" });
          basUnitAdjustSalePrice = fcount([basUnitSalePrice, num], "*", {
            type: "round",
          });
        } else {
          // 盘亏
          num = fcount([0, num], "-");
          basUnitLoseQty = num;
          basUnitLosePurPrice = fcount([basUnitPurPrice, num], "*", { type: "round" });
          basUnitLoseSalePrice = fcount([basUnitSalePrice, num], "*", { type: "round" });
        }
        basUnitPurMoney = fcount([basUnitQty, basUnitPurPrice], "*", { type: "round" });
        basUnitSaleMoney = fcount([basUnitQty, basUnitSalePrice], "*", { type: "round" });
        unitPurMoney = fcount([unitQty, unitPurPrice], "*", { type: "round" });
        unitSaleMoney = fcount([unitQty, unitSalePrice], "*", { type: "round" });
      }
      const obj = {
        basUnitQty,
        basUnitAdjustQty,
        basUnitAdjustPurPrice,
        basUnitAdjustSalePrice,
        basUnitLoseQty,
        basUnitLosePurPrice,
        basUnitLoseSalePrice,
        basUnitPurMoney,
        basUnitSaleMoney,
        unitPurMoney,
        unitSaleMoney,
      };
      console.log("obj", { unitQty, basUnitQty }, obj);
      if (inObj) {
        return obj;
      } else {
        this.$set(this.form.details, index, { ...this.form.details[index], ...obj });
      }
      console.log("价格没变", this.form.details[index]);
    },
    async handleEvent(type, row) {
      console.log(type, row, "rowhandleEvent");
      console.log(this.billType, "this.billType");
      if (
        type === "dialogChange" &&
        ["130102", "130103", "130104"].includes(this.billType)
      ) {
        console.log(
          this.dialogOptions.formData.table.check[0],
          "this.dialogOptions.formData.table.check[0]"
        );
        if (!this.dialogOptions.formData.table.check[0])
          return this.$message.error("请选择仓库");
        if (["130103", "130102"].includes(this.billType)) {
          this.form.details = this.form.details.map((item, index) => ({
            ...item,
            storeId: this.ids.includes(item.goodsId)
              ? this.dialogOptions.formData.table.check[0].storeId
              : item.storeId,
          }));
          const list = await goodsGetStock(this.form.details, this.ids);
          this.form.details = this.form.details.map((item, index) => ({
            ...item,
            stockQty: list[index].stockQty,
            stockQtyText: list[index].stockQtyText,
          }));
        } else if (["130104"].includes(this.billType)) {
          if (this.storeIndex == 1) {
            this.form.details = this.form.details.map((item) => ({
              ...item,
              outStoreId: this.ids.includes(item.goodsId)
                ? this.dialogOptions.formData.table.check[0].storeId
                : item.outStoreId,
            }));
          }
          if (this.storeIndex == 2) {
            this.form.details = this.form.details.map((item) => ({
              ...item,
              inStoreId: this.ids.includes(item.goodsId)
                ? this.dialogOptions.formData.table.check[0].storeId
                : item.inStoreId,
            }));
          }
        }
        return;
      }

      if (
        ["130102", "130103", "130104", "130105", "130106", "130107", "130108"].includes(
          this.billType
        )
      ) {
        this.getGoods(row);
        return;
      }
      if (["130101", "140309", "170309", "170409"].includes(this.billType)) {
        console.log("checkName", row);
        this.setTableData(row);
      }
    },
    openTableData(index, open = false) {
      this.dialogOptions.StoreDetailIndex = index;
      this.dialogOptions.formData = {
        ...this.$dialog({ key: "goods" }),
        queryParams: {
          storeId: this.form.storeId,
          isLockStore: this.form.isLockStore,
          isIncludeZeroStock: this.form.isIncludeZeroStock,
          goodsSource: this.form.checkGoodsSourceType,
        },
        table: {
          ...this.$dialog({ key: "goods" }).table,
          list: httpStoreListCheckGoods,
          pagination: { total: 0, pageNum: 1, pageSize: 15 },
        },
      };
      if (open) {
        this.dialogOptions.show = true;
      }
    },
    setTableData(arr, update = false) {
      // 先拆分 stockInfo
      const data = [];
      arr?.forEach?.((x) => {
        const newArr = (x.stockInfo?.length
          ? x.stockInfo.map((y) => ({ ...x, ...y }))
          : [x]
        ).map((y) => {
          const { basUnitQty = 0, purPrice, salePrice } =
            y.units?.find((z) => z.unitId === y.unitId) || {};
          const basUnit = y.units?.find((z) => z.unitId === 0) || {};
          const {
            finalQty = 0,
            unitQty,
            basUnitPurPrice = 0,
            basUnitSalePrice = 0,
            unitPurPrice = 0,
            unitSalePrice = 0,
            basUnitQty: basUnitQty2 = 0,
            // basUnitAccountQty = 0,
            basUnitAccountQty,
          } = y;

          // const zmNum = basUnitAccountQty || finalQty || 0;
          let zmNum = "";

          // if (
          //   basUnitAccountQty != "" &&
          //   basUnitAccountQty != null &&
          //   basUnitAccountQty != undefined
          // ) {
          //   zmNum = basUnitAccountQty;
          // } else if (
          //   basUnitAccountQty != "" &&
          //   basUnitAccountQty != null &&
          //   basUnitAccountQty != undefined
          // ) {
          //   zmNum = finalQty;
          // } else {
          //   zmNum = 0;
          // }
          if (
            basUnitAccountQty != "" &&
            basUnitAccountQty != null &&
            basUnitAccountQty != undefined
          ) {
            zmNum = basUnitAccountQty;
          } else {
            zmNum = finalQty || 0;
          }
          console.log(y.basUnitAccountQty, "zmNumzmNumzmNum//???");
          console.log(zmNum, basUnitAccountQty, finalQty, "zmNumzmNumzmNum//");

          const obj = {
            ...x,
            ...y,
            basUnitAccountQty: zmNum,
            useBaseUnitQty: basUnitQty || basUnitQty2,
            basUnitPurPrice: basUnit.purPrice || basUnitPurPrice,
            basUnitSalePrice: basUnit.salePrice || basUnitSalePrice,
            unitPurPrice: purPrice,
            unitSalePrice: salePrice,
            unitQty: unitQty || unitQty === 0 ? unitQty : zmNum, // 默认账面数
          };
          console.log("num mmmmmm", obj);
          return { ...obj, ...this.checkDataSum(0, obj) };
        });
        data.push(...newArr);
      });
      // 先去重复的 arr
      const nowIds = this.form.details?.map?.((x) => `${x.goodsId}.${x.batchNo}`) || [];
      const setArr = data.filter((x) => !nowIds.includes(`${x.goodsId}.${x.batchNo}`));
      let details = JSON.parse(JSON.stringify(this.form.details));
      details.splice(this.dialogOptions.StoreDetailIndex + 1, 0, ...setArr);
      details = details.filter((x) => x.goodsId);
      this.form.details = details;
      if (
        !this.form.details?.length &&
        !["2", "3", "4", ""].includes(this.form.billStatus)
      ) {
        this.form.details = [{}];
      }
      console.log("setArrsetArr", details);
    },
    async handleAdd() {
      // console.log(this.form.billStatus, 'form.billStatus ', this.billType)
      const res = await getBillNo(this.billType);
      const data = await getNewDate();

      this.form.billNo = res;
      this.form.billDate = data;
      console.log(res, "res ", this.billType);
      console.log(data, "data ", this.billType);
      this.form = {
        //单据id
        billId: undefined,
        //制单日期
        billDate: data,
        //单据编号
        billNo: res,
        //盘点名称
        checkName: undefined,
        billCustomType: 1,
        //仓库
        storeId: "",
        inStoreId: "",
        outStoreId: "",
        //单据状态
        billStatus: "",
        //制单人
        createEmployeeId: undefined,
        //审核人
        auditEmployeeId: undefined,
        //审核日期
        auditTime: undefined,
        //单据金额
        billMoney: "00:000",
        //备注
        remark: undefined,
        //单据表格-订单明细
        details: [{}],
        //上传的附件
        fileItems: [],
      };
    },
    //退出
    getQuit() {
      store.dispatch("tagsView/delView", router.currentRoute);
      history.back();
    },
    callUnitTaxMoney(row) {
      if (row.taxRate) {
        return this.$options.filters.numFilter(
          row.unitPrice * row.unitQty * (row.taxRate * 0.01)
        );
      } else {
        return 0;
      }
    },
    calUnitPriceTaxMoney(row) {
      return this.$options.filters.numFilter(row.unitTaxPrice * row.unitQty);
    },
    delFileItems,
    //编码获取焦点事件
    onGoodsNoFocus,
    //获取附件上传的信息数据
    getFileItemsData,
    //表格滚动条位置
    //监听数据变化失去焦点时  滚动条的位置
    watchScroll,
    //监听滚动事件
    scroll,
    //移除监听滚动事件
    destroyScroll,
    // 获取滚动的宽度
    scrollEvent,
    // 数据刷新后设置滚动条当前滚动的位置
    savescroll,
    //获取全部行政地区事件
    getRegion,
    //新增时获取当前时间
    getCurTime,
    //新增时获取单据编号
    getNewBillNo,
    //订单明细表格合计
    getStoreSummaries,
    //计算金额,数量
    onkeyupUnitQty,
    //格式化金额保留n位小数
    showFormatting,
    //单价根据单位改变并显示保留3位小数
    changeUnitPrice,
    //表格单元格回车事件
    //获取下拉框数据
    getBasicData,
    //获取选中的仓库
    getStore,
    //获取引入源单全部数据
    getSelectBills,
    //获取自定义下拉框选中的id
    getDiyselectId,
    //表单重置
    reset,
    //表格增加/减少一行方法
    row,
    //新增单据
    AddBill,
    /** 提交按钮 */
    submitForm,
    //表格行hover时,显示操作加减号

    cellMouseEnter(row) {
      //获取当前行下标
      let eleIndex = this.form.details.indexOf(row);
      this.form.details[eleIndex].hoverRow = true;
      this.tableCellLabel = "操作";
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.form.details.length; index++) {
        const element = this.form.details[index];
        element["hoverRow"] = false;
      }
      this.tableCellLabel = "序号";
    },
    async changeBillStatus(name, bool) {
      try {
        await this.$confirm(`确定${name}该单据, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });
        if (name === "审核") {
          if (this.form.billStatus != "0") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          await this.submitForm();
          let response = await updateInstoreStatus(
            [this.form.billId],
            "2",
            this.urlBillStatus,
            this.TypeBill
          );
          this.form.billStatus = response.data.billStatus;
          if (bool) {
            //有收货地址才进入  初始化收货地址
            if (
              response.data.storeAddress === null ||
              response.data.storeAddress === undefined
            ) {
              response.data.storeAddress = this.resetAddress();
            }
            this.getAddress();
          }
          // 仓库调出/仓库调拨 审核后 获取详情 显示单位成本价/单位成本额
          if (["130105", "130104"].includes(this.billType)) {
            this.billId = this.form.billId;
            this.getBillDetail();
          }
          this.disabled = true; //是否禁用
          this.auditOrder = this.form.billStatus; //审核图标
        } else if (name === "反审核") {
          let response = await updateInstoreStatus(
            [this.form.billId],
            "0",
            this.urlBillStatus,
            this.TypeBill
          );
          this.form.billStatus = response.data.billStatus;
          if (bool) {
            //有收货地址才进入   初始化收货地址
            if (
              response.data.storeAddress === null ||
              response.data.storeAddress === undefined
            ) {
              response.data.storeAddress = this.resetAddress();
            }
            this.getAddress();
          }
          this.disabled = false;
          this.auditOrder = false;
        } else if (name === "终止") {
          updateInstoreStatus(
            [this.form.billId],
            "4",
            this.urlBillStatus,
            this.TypeBill
          ).then((response) => {
            this.form = response.data;
            if (bool) {
              //有收货地址才进入   初始化收货地址
              if (
                response.data.storeAddress === null ||
                response.data.storeAddress === undefined
              ) {
                response.data.storeAddress = this.resetAddress();
              }
              this.getAddress();
            }
            this.disabled = true;
            this.showAudit = true;
            this.stopOrder = true;
          });
        }
        // 弹出提示
        this.$message.success(`${name}单据成功`);
      } catch {}
    },
    //导入
    getFileUrl(file) {
      console.log("上传成功回调", file);
    },
    uploadFile(fromData) {
      this.importInfo
        .uploadApi(fromData, this.billId)
        .then((res) => {
          this.$message.success("上传成功");
          this.openToChannel = false;
          this.getBillDetail();
        })
        .catch((error) => {
          console.log("error", error);
          this.$message.error("导入门店盘点资料失败");
          this.errorList = error.data.errorList.map((item) => {
            return { errorInfo: item };
          });
          this.errVisible = true;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  /* ::v-deep .is-scrolling-left {
      height: 366px !important;
    } */
}
.store-detail {
  background-color: #f3f3f3;

  ::v-deep .el-table--border {
    border: 1px solid #fff;
  }

  .card {
    min-height: 90vh;
    //顶部区域
    .bill-nav {
      width: 100%;
      .info {
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        .searchItem {
          display: flex;
          align-items: center;
          text-align: left;
          &.Address {
            width: 50%;
            .itemAddress {
              width: 100%;
            }
          }
          .vue-treeselect,
          .el-select,
          .el-input,
          ::v-deep.el-date-editor {
            width: 160px !important;
          }
          .label-name {
            min-width: 4vw;
            margin-right: 0.52vw;
            text-align: right;
            text-align-last: justify;
          }
        }
      }
    }
    //未审核图标
    .nav-noAudit-icon {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/noAudit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }
    //已审核图标
    .nav-audit-icon {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/audit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }
    //已审核图标
    .nav-delete-icon {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/delete.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }
    //已关闭图标
    .nav-stop-icon {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/stop.png) no-repeat;
        background-size: 100px 43px;
        top: calc(28% - 32px);
        right: calc(8% - 80px);
      }
    }
    // 按钮区域
    .store-btn {
      display: flex;
      justify-content: space-between;
      .noAuditBtn {
        position: absolute;
        z-index: 2022;
        bottom: -30px;
        transform: translateX(70%);
        display: none;
        &.show {
          display: block;
        }
      }
    }
    // 表格区域
    .store-table {
      ::v-deep.el-form-item {
        margin-bottom: 0;
      }
      ::v-deep.el-form-item__content {
        margin-left: 0 !important;
      }
      .table-icon {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          height: 64px;
          width: 165px;
          background: url(~@/assets/images/audit.png) no-repeat;
          background-size: 164px 63px;
          top: calc(48% - 32px);
          left: calc(50% - 80px);
        }
      }
    }
    .store-footer {
      display: flex;
      width: 100%;
      font-size: 14px;
      color: #606266;
      font-weight: 700;
      margin-top: 15px;
      padding-bottom: 49px;

      .left-accessory {
        display: flex;
        width: 50%;
        .btn {
          margin: 0 5px;
        }
      }
      .right-remark {
        width: 50%;
        display: flex;
        span {
          width: 10%;
        }
        .input {
          width: 90%;
        }
      }
    }
  }
  .footer {
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-top: 1px solid #d6d8da;
    display: flex;
    .left {
      flex: 5;
    }
    .right {
      flex: 2;
      display: flex;
      .item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
        .label {
          font-size: 14px;
        }
      }
    }
  }
}

.ken-detail-form {
  padding: 40px 10px 0 10px;
}

// ::v-deep .el-form-item {
//   margin: 0;
//   padding: 0;
// }
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* ::v-deep .is-scrolling-left {
  height: 366px !important;
} */

::v-deep.el-table {
  ::v-deep.el-table__body-wrapper::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  ::v-deep.el-table__body-wrapper::-webkit-scrollbar-thnmb {
    background-color: #ccc !important;
    border-radius: 5px;
  }

  ::v-deep.el-table-fixed .el-table__body {
    padding-bottom: 12px;
  }
}
</style>
