<template>
  <div class="wrap" v-loading="loading" id="wrap" ref="wrap">
    <el-form ref="form" :model="options" :rules="rules" label-width="106px">
      <topOperatingButton :isExamineBtn="![2 , '2'].includes(options.billStatus)" :showAudit="isShowAudit"
        :isStopBtn="false" :disabled='disableOperate' id="topOperatingButton" ref="topOperatingButton"
        @submitForm="submitForm(false)" @addBill="submitForm(true)" @auditBill="auditForm" @getQuit="getQuit">
      </topOperatingButton>
      <cardTitleCom cardTitle="基本信息" id="basicInformation" ref="basicInformation" :billStatus="options.billStatus">
        <template slot="cardContent">
          <div class="x-f marT10">
            <el-form-item label="单据编号" required>
              <el-input class="inputWidth" size="mini" :disabled="true" v-model="options.billNo"
                placeholder="单据编号"></el-input>
            </el-form-item>
            <el-form-item label="单据日期" required>
              <el-date-picker class="inputWidth" size="mini" v-model="options.billDate" type="date" placeholder="单据日期"
                :disabled="disableOperate" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="客户" required>
              <el-select :disabled="disableOperate" @change="changePartner" v-model="options.partnerId" clearable
                filterable size="mini" placeholder="请选择">
                <el-option v-for="item in customerData" :key="item.customerId" :label="item.customerName"
                  :value="item.customerId" />
              </el-select>
            </el-form-item>
            <el-form-item label="部门">
              <el-select :disabled="disableOperate" v-model="options.deptId" clearable filterable size="mini"
                placeholder="请选择">
                <el-option v-for="item in deptData" :key="item.deptId" :label="item.deptName" :value="item.deptId" />
              </el-select>
            </el-form-item>
            <el-form-item label="业务员">
              <el-select :disabled="disableOperate" v-model="options.saleEmployeeId" clearable filterable size="mini"
                placeholder="请选择">
                <el-option v-for="item in employeeData" :key="item.employeeId" :label="item.employeeName"
                  :value="item.employeeId" />
              </el-select>
            </el-form-item>
          </div>
          <div class="x-f">
            <el-form-item label="应收款余额">
              <el-input size="mini" :disabled="true" class="inputWidth" v-model="options.accountsBalance" type="text"
                placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="billRemark">
              <el-input size="mini" class="inputWidth" v-model="options.billRemark" type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 1 }"
                :disabled="options.preRpBillNo?  ['2', '3', '4', 2, 3, 4].includes(options.billStatus) : disableOperate" />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="收款信息" class="options">

        <template slot="leftCardTitle">
          <div class="leftContent x-fsa marL15">
            <span class="text">优惠金额：</span>
            <el-input class="fl" :disabled="disableOperate"
              @input="limitInputlength(options.discountMoney, options,'discountMoney', true, 4, true)"
              v-model="options.discountMoney" placeholder="请输入优惠金额"></el-input>
            <el-tooltip class="fl" effect="dark" content="用于记录收款时的优惠或折扣" placement="top">
              <i class="el-icon-question" style="color: #a4a4a4"></i>
            </el-tooltip>
          </div>
        </template>
        <template slot="cardContent">
          <div class="table">
            <!-- 可编辑表格 -->
            <EditTable :options="options" @handleEvent="handleEvent" />
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="源单信息" class="sourceOptions">
        <template slot="leftCardTitle">
          <div style="padding: 5px;">
            <el-button size="mini" :disabled="disableOperate" @click="selectSource()">选择源单</el-button>
            <el-button size="mini" :disabled="disableOperate" type="primary" @click="verification()">自动核销</el-button>
          </div>
        </template>
        <template slot="cardContent">
          <div class="table">
            <!-- 可编辑表格 -->
            <EditTable :options="sourceOptions" @handleEvent="handleEvent" />
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="附件">
        <template slot="cardContent">
          <!-- 附件上传组件 -->
          <accessoryUpload listType="img" class="accessoryUpload" :disabled="disableOperate"
            :fileList="options.fileItems" @getFileItems="getFileItemsData" @delFileItems="deleteList"></accessoryUpload>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import { getShouldReceiveSaleFunds, addReceiveBill, modifyReceiveBill, getReceiveBillByBillId, modifyBillStatus } from '@/api/arap/index'
import { listDept } from '@/api/system/dept'
import { allCustomerList } from '@/api/partner/partner'
import { allEmployeeList } from '@/api/system/employee' //业务员
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import EditTable from '@/components/editTable' //可编辑表格
import Dialog from '@/components/Dialog'
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import { getBillNo } from '@/api/codeRule'
import { uniqWith } from 'lodash' //去重
import { limitInputlength, processSourceOptionsList, fcount } from '@/utils'
import accessoryUpload from '@/views/components/accessoryUpload' //附件上传组件

export default {
  name: 'FillDetail',
  components: {
    topOperatingButton,
    EditTable,
    cardTitleCom,
    Dialog,
    accessoryUpload,
  },
  watch: {
    'options.billStatus': {
      handler(val) {
        console.log(this.options, 'this.options')
        console.log(this.options.preRpBillNo, 'this.options.preRpBillNo')
        this.disableOperate = this.options.preRpBillNo ? true : ['2', '3', '4', 2, 3, 4].includes(val)
        this.isShowAudit = ['', 4, '4'].includes(val)

        this.options.columns[0].disabled = this.disableOperate
        this.options.columns[1].disabled = this.disableOperate
        this.options.columns[2].disabled = this.disableOperate
        this.options.columns[3].disabled = this.disableOperate

        this.sourceOptions.columns[7].disabled = this.disableOperate
        this.sourceOptions.columns[8].disabled = this.disableOperate
        this.options.status = this.disableOperate
      },
      immediate: true
    },
    // 'options.list': {
    //   handler (newVal, oldVal) {
    //     let isSwitch = this.options.list.filter(item => item.bankAccountNo 
    //     || item.payModeNo || 
    //     item.payMoney || item.payPoundage || item.payBillNo || item.remark)
    //     console.log('isSwitch', isSwitch);
    //     if (isSwitch.length > 0) {
    //       this.options.columns[0].rules = true
    //       this.options.columns[1].rules = true
    //       this.options.columns[2].rules = true
    //     } else {
    //       this.options.columns = this.options.columns.map(item => (
    //         {
    //           ...item,
    //           rules: false,
    //         }
    //       ))
    //     }
    //   },
    //   deep: true,
    // },
  },
  data() {
    return {
      payMoneyTotal: '',
      nowWriteOffMoneyTotal: '',
      disableOperate: false,
      isShowAudit: '',
      SourceOperationData: '',
      isAdd: '',
      customerData: [],
      deptData: [],
      employeeData: [],
      loading: false, //遮罩层
      //表格配置
      options: {
        fileItems: [],
        billNo: undefined,
        billDate: undefined,
        partnerId: undefined,
        deptId: undefined,
        saleEmployeeId: undefined,
        accountsBalance: undefined, // 应收款余额 不传的
        discountMoney: undefined,
        billStatus: '',

        tableIndex: 0,
        retainColumn: true,
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [{}],
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        // tableHeight: 200, //表格高度
        status: undefined, //单据状态
        // isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: 'bankAccountName',
            label: '收款账号',
            type: 'remoteSelect',
            click: 'clickAccount',
            Width: 200,
            align: 'center',
            option: this.$select({
              key: 'account',
              option: {
                option: {
                  label: 'bankAccountName',
                  value: 'bankAccountName',
                  modal: 'bankAccountName',
                  change: (propValue, row) => {
                    let tableIndex = this.options.tableIndex
                    if (row && this.options.tableIndex != undefined) {
                      this.$set(this.options.list[tableIndex], 'bankAccountId', row.bankAccountId)
                      this.$set(this.options.list[tableIndex], 'bankAccountNo', row.bankAccountNo)
                      this.$set(this.options.list[tableIndex], 'bankAccountName', row.bankAccountName)
                    }
                  },
                  tableChange: (propValue, row) => {
                    this.handleEvent('getSelectData', row)
                  },
                  buttons: [
                    {
                      type: 'more',
                      option: {
                        title: '选择收款账号',
                        width: 1250,
                        type: 'TreeAndTable',
                        formData: {
                          ...this.$dialog({ key: 'account' }),
                          table: {
                            ...this.$dialog({ key: 'account' }).table,
                            mutiSelect: true
                          }
                        }
                      }
                    }
                  ]
                }
              }
            }).option,
            disabled: false,
            rules: true,
          },
          {
            prop: 'payModeNo',
            label: '收款方式',
            type: 'remoteSelect',
            click: 'clickAccount',
            Width: 200,
            align: 'center',
            option: this.$select({
              key: 'paymode',
              option: {
                option: {
                  label: 'payModeName',
                  value: 'payModeNo',
                  modal: 'payModeNo',
                  tableChange: (propValue, row) => {
                    this.handleEvent('getSelectDataPay', row)
                  },
                  change: (propValue, row) => {
                    let tableIndex = this.options.tableIndex
                    if (row && this.options.tableIndex != undefined) {
                      this.$set(this.options.list[tableIndex], 'payModeId', row.payModeId)
                      this.$set(this.options.list[tableIndex], 'payModeName', row.payModeName)
                      this.$set(this.options.list[tableIndex], 'payModeNo', row.payModeNo)
                    }
                  },
                  buttons: [
                    {
                      type: 'more',
                      option: {
                        title: '选择收款方式',
                        width: 1250,
                        type: 'TreeAndTable',
                        formData: {
                          ...this.$dialog({ key: 'paymode' }),
                          table: {
                            ...this.$dialog({ key: 'paymode' }).table,
                            mutiSelect: true
                          }
                        }
                      }
                    }
                  ]
                }
              }
            }).option,
            disabled: false,
            rules: true
          },
          {
            prop: 'payMoney',
            label: '收款金额',
            minWidth: 120,
            align: 'center',
            type: 'input',
            disabled: false,
            rules: true,
            isMinus: true,
            inputNum: 4
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 120,
            align: 'center',
            type: 'my-input',
            maxlength: 80,
            disabled: false
          }
        ],
        summary: ['payMoney'],
      },
      sourceOptions: {
        isNoAddBtn: true, //不显示表格增加
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        status: undefined, //单据状态
        hideTotal: true,
        retainColumn: true,
        isCanBeBlank: true,
        columns: [
          {
            prop: 'billDate',
            label: '源单日期',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'billTypeName',
            label: '源单类型',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'billNo',
            label: '源单编号',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'billRemark',
            label: '源单备注',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'billTaxMoney',
            label: '源单金额',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'settleMoney',
            label: '源单已核销金额',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'unverified',
            label: '源单未核销金额',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'nowWriteOffMoney',
            label: '本次核销金额',
            minWidth: 100,
            align: 'center',
            type: 'input',
            input: 'nowWriteOffMoney',
            disabled: false,
            isMinus: true,
            inputNum: 4
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 120,
            align: 'center',
            type: 'my-input',
            maxlength: 80,
            disabled: false
          }
        ],
        summary: ['billTaxMoney', 'settleMoney', 'unverified', 'nowWriteOffMoney'],
      },
      //弹窗配置
      dialogOptions: {
        title: '选择',
        width: 1250,
        show: false,
        type: 'TreeAndTable'
      },
      rules: {
        billNo: [
          {
            required: true,
            message: '请输入方案编号',
            trigger: ['blur', 'change']
          }
        ],
        billDate: [
          { required: true, message: '请输入制单日期', trigger: 'blur' }
        ]
      },
      ceshi: undefined,
      pageH: 0, //页面高度
      btnsH: 0, //搜索框高度
      basicH: 0, //基础框高度
      paginationH: 50 //分页高度
    }
  },
  activated() {
    this.getDetail()
  },
  mounted() {
    this.getDetail()
  },
  beforeDestroy() {
  },
  created() {
    this.allCustomerListFun()
    this.listDeptFun()
    this.allEmployeeListFun()
  },
  computed: {},
  methods: {
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/arap/arbill/receipt" });
    },
    filterData(item) {
      let obj = {}
      if ([120103, 120102, '120103', '120102', 140312, '140312'].includes(item.billType)) {
        obj = {
          ...item,
          unverified: fcount([item.billType == '120103' ? fcount([item.billTaxMoney, -1], '*') : item.billTaxMoney, item.billType == '120103' ? fcount([item.settleMoney, -1], '*') : item.settleMoney], '-'),
          nowWriteOffMoney: fcount([item.billType == '120103' ? fcount([item.billTaxMoney, -1], '*') :
            item.billTaxMoney, item.billType == '120103' ? fcount([item.settleMoney, -1], '*') :
            item.settleMoney], '-'),

          billTaxMoney: item.billType == '120103' ? fcount([item.billTaxMoney, -1], '*') : item.billTaxMoney,
          settleMoney: item.billType == '120103' ? fcount([item.settleMoney, -1], '*') : item.settleMoney,

          sourceBillType: item.billType,
          sourceBillId: item.billId,
          sourceBillWrittenOffMoney: item.billType == '120103' ? fcount([item.settleMoney, -1], '*') : item.settleMoney,
          remark: item.remark,
          billRemark: item.billRemark,
        }
        return obj
      } else if ([100203, '100203'].includes(item.billType)) {
        obj = {
          ...item,
          billDate: item.bizDate,
          unverified: fcount([item.arAmount, item.arWriteOffMoney ? item.arWriteOffMoney : 0], '-'),
          nowWriteOffMoney: fcount([item.arAmount, item.arWriteOffMoney ? item.arWriteOffMoney : 0], '-'),
          billTaxMoney: item.arAmount,
          settleMoney: item.arWriteOffMoney ? item.arWriteOffMoney : 0,
          sourceBillType: item.billType,
          sourceBillId: item.billId,
          sourceBillWrittenOffMoney: item.arWriteOffMoney ? item.arWriteOffMoney : 0,
          remark: item.remark,
          billRemark: item.billRemark,
        }
        return obj
      } else if ([190105, '190105'].includes(item.billType)) {
        obj = {
          ...item,
          billDate: item.billDate,
          unverified: fcount([item.billWriteOffMoney, item.settleMoney ? item.settleMoney : 0], '-'),
          nowWriteOffMoney: fcount([item.billWriteOffMoney, item.settleMoney ? item.settleMoney : 0], '-'),
          billTaxMoney: item.billWriteOffMoney,
          settleMoney: item.settleMoney,
          sourceBillType: item.billType,
          sourceBillId: item.billId,
          sourceBillWrittenOffMoney: item.settleMoney,
          remark: item.remark,
          billRemark: item.billRemark,
        }
        return obj
      } else if ([190106, '190106'].includes(item.billType)) {
        obj = {
          ...item,
          billDate: item.billDate,
          unverified: fcount([fcount([item.billWriteOffMoney, -1], '*'), item.settleMoney ? fcount([item.settleMoney, -1], '*') : 0], '-'),
          nowWriteOffMoney: fcount([fcount([item.billWriteOffMoney, -1], '*'), item.settleMoney ? fcount([item.settleMoney, -1], '*') : 0], '-'),
          billTaxMoney: fcount([item.billWriteOffMoney, -1], '*'),
          settleMoney: fcount([item.settleMoney, -1], '*'),
          sourceBillType: item.billType,
          sourceBillId: item.billId,
          sourceBillWrittenOffMoney: item.settleMoney,
          remark: item.remark,
          billRemark: item.billRemark,
        }
        return obj
      }
    },
    deleteList(val) {
      this.options.fileItems = this.options.fileItems.filter(v => v.url != val.url)
    },
    //获取附件上传的信息数据
    getFileItemsData(data) {
      console.log(data, 'data')
      this.options.fileItems.push({
        accessUrl: data.accessUrl,
        domain: data.domain,
        filePath: data.filePath,
        fileName: data.fileName,
        fileSize: data.fileSize,
        name: data.fileName,
        url: data.accessUrl
      })
    },
    verification() {
      this.sourceOptions = processSourceOptionsList(this.options, this.sourceOptions)
    },
    // sourceCountFun (list, nowWriteOffMoneyTotal) {
    //   let array = JSON.parse(JSON.stringify(list))
    //   let idx = null;
    //   for (const [i, v] of array.entries()) {
    //     nowWriteOffMoneyTotal = fcount([nowWriteOffMoneyTotal, v.payMoney],'-')
    //     if (nowWriteOffMoneyTotal === 0) {
    //       idx = i + 1;
    //       break;
    //     } else if (nowWriteOffMoneyTotal < 0) {
    //       idx = i;
    //       array[idx].payMoney = Math.abs(nowWriteOffMoneyTotal);
    //       break;
    //     }
    //   }
    //   array = array.slice(idx !== null ? idx : array.length)
    //   return array
    // },
    changePartner(val) {
      this.sourceOptions.list = []
      if (!val) return
      getShouldReceiveSaleFunds({ partnerId: val }).then(res => {
        this.options.accountsBalance = res.data.shouldSettleMoney
      })
    },
    selectSource() {
      if (!this.options.partnerId) return this.$message.error('请选择客户')
      this.dialogOptions = {
        show: true,
        type: "SelectSource",
        click: 'SelectSource',
        data: { billType: "190101", partnerId: this.options.partnerId },
        title: "选择源单",
      };
    },
    selectOptions() {
      this.dialogOptions = {
        show: true,
        type: "SourceOperation",
        click: 'SourceOperation',
        data: { billType: "190101" },
        title: "操作确认",
      };
    },
    //保留小数位
    limitInputlength,
    allCustomerListFun() {
      allCustomerList().then(response => {
        this.customerData = response.data
      })
    },
    listDeptFun() {
      listDept({
        pageNum: 1,
        pageSize: 1000
      }).then(response => {
        this.deptData = response.data
      })
    },
    allEmployeeListFun() {
      allEmployeeList({}).then(response => {
        this.employeeData = response.data
      })
    },
    //获取详情
    async getDetail() {
      this.reset()
      if (this.$route.query.type === 'Update' && this.$route.query.billId) {
        this.loading = true
        try {
          const { data } = await getReceiveBillByBillId({ billId: this.$route.query.billId })
          //方案赋值
          this.options = {
            ...this.options,
            ...data,
            list: []
          }
          this.options.list = data.payDetails
          let arrlist = data.arDetails.map((item) => (
            {
              ...item,
              billDate: item.sourceBillDateStr,
              billTypeName: item.sourceBillTypeName,
              billNo: item.sourceBillNo,
              remark: item.remark,
              billRemark: item.sourceBillRemark,
              billTaxMoney: ['120103', 120103, '190106', 190106].includes(item.sourceBillType) ? fcount([item.sourceBillMoney, -1], '*') : item.sourceBillMoney,
              settleMoney: ['120103', 120103, '190106', 190106].includes(item.sourceBillType) ? fcount([item.sourceBillWrittenOffMoney, -1], '*') : item.sourceBillWrittenOffMoney,
              unverified: fcount([['120103', 120103, '190106', 190106].includes(item.sourceBillType) ? fcount([item.sourceBillMoney, -1], '*') : item.sourceBillMoney, ['120103', 120103, '190106', 190106].includes(item.sourceBillType) ? fcount([item.sourceBillWrittenOffMoney, -1], '*') :
                item.sourceBillWrittenOffMoney], '-'),
              nowWriteOffMoney: ['120103', 120103, '190106', 190106].includes(item.sourceBillType) ? fcount([item.nowWriteOffMoney, -1], '*') : item.nowWriteOffMoney
            }
          ))
          if (this.options.fileItems?.length > 0) {
            this.options.fileItems = this.options.fileItems.map((item) => (
              {
                ...item,
                name: item.fileName,
                url: item.accessUrl,
              }
            ))
          }
          console.log(this.options.fileItems, 'this.options.fileItems')
          this.sourceOptions.list = arrlist
          getShouldReceiveSaleFunds({ partnerId: this.options.partnerId }).then(res => {
            this.options.accountsBalance = res.data.shouldSettleMoney
          })
        } catch (err) { }
        this.loading = false
      } else {
        this.getBillno()
      }
    },

    async handleEvent(type, row) {
      //获取元素在数组的下标
      let contains = function (list, row) {
        var i = list.length
        while (i--) {
          if (list[i] === row) {
            return i
          }
        }
        return false
      }
      switch (type) {
        case 'SourceCollection':
          console.log(type, 'SourceCollection')
          console.log(row, 'row')
          this.dialogOptions.show = false
          this.SourceOperationData = row
          this.userSubmit(this.isAdd, 3)
          break;
        case 'SourceOperation':
          console.log(type, 'SourceOperation')
          console.log(row, 'row')
          this.dialogOptions.show = false
          if (row == 1) {
            this.userSubmit(this.isAdd, 1)
          } else {
            let discountMoney = this.options.discountMoney ? this.options.discountMoney : 0
            let arr = JSON.parse(JSON.stringify(this.options.list))
            arr[0].payMoney = fcount([arr[0].payMoney, discountMoney], '+')
            let list = this.countFun(arr, this.options.nowWriteOffMoneyTotal)
            if (this.options.list.length == 1) {
              // 当收款信息只有一条  直接计算
              this.SourceOperationData = {
                list: list,
                discountMoney: 0,
              }
              this.userSubmit(this.isAdd, 3)
              return
            }
            let exceedAmount = fcount([fcount([this.options.payMoneyTotal, discountMoney], '+'), this.options.nowWriteOffMoneyTotal], '-')
            list[0].payMoney = fcount([list[0].payMoney, discountMoney], '+'),
              this.dialogOptions = {
                show: true,
                type: "SourceCollection",
                click: 'SourceCollection',
                data: {
                  billType: "190101",
                  exceedAmount: exceedAmount,
                  list: list
                },
                title: "录入收款信息",
              };
          }
          break;
        case 'SourceDialogChange':
          this.dialogOptions.show = false
          row.list = row.list.map((item) => (
            {
              ...this.filterData(item)
              // ...item,
              // unverified: fcount([item.billType == '120103'? fcount([item.billTaxMoney, -1],'*') : item.billTaxMoney, item.billType == '120103'? fcount([item.settleMoney, -1],'*') : item.settleMoney],'-'),
              // nowWriteOffMoney: fcount([item.billType == '120103'? fcount([item.billTaxMoney, -1],'*') : 
              // item.billTaxMoney, item.billType == '120103'? fcount([item.settleMoney, -1],'*') :
              // item.settleMoney],'-'),
              // billTaxMoney: item.billType == '120103'? fcount([item.billTaxMoney, -1],'*') : item.billTaxMoney,
              // settleMoney: item.billType == '120103'? fcount([item.settleMoney, -1],'*') : item.settleMoney,

              // sourceBillType: item.billType,
              // sourceBillId: item.billId,
              // sourceBillWrittenOffMoney: item.billType == '120103'? fcount([item.settleMoney, -1],'*') : item.settleMoney,
              // remark: item.remark,
              // billRemark: item.billRemark,
            }
          ))

          this.sourceOptions.list = [...this.sourceOptions.list, ...row.list]
          this.sourceOptions.list = uniqWith(this.sourceOptions.list, (x, y) => x.sourceBillId == y.sourceBillId).filter(x => x.sourceBillId)
          break;
        case 'clickAccount':
          this.options.curListIndex = contains(this.options.list, row)
          this.dialogOptions.show = true
          //弹窗配置
          this.dialogOptions = {
            title: '选择账户',
            width: 1250,
            show: true,
            type: 'TreeAndTable',
            formData: this.$dialog({ key: 'product' })
          }
          break
        case 'nowWriteOffMoney':
          let index = contains(this.sourceOptions.list, row)
          // if (this.sourceOptions.list[index].sourceBillType == '120103' && this.sourceOptions.list[index].nowWriteOffMoney > 0) {
          //   this.sourceOptions.list[index].nowWriteOffMoney = ''
          //   return
          // }
          // if (['120103', '190106', 120103, 190106].includes(this.sourceOptions.list[index].sourceBillType) && this.sourceOptions.list[index].nowWriteOffMoney > 0) {
          //   this.sourceOptions.list[index].nowWriteOffMoney = ''
          //   return
          // }
          if (this.sourceOptions.list[index].nowWriteOffMoney) {
            this.limitInputlength(
              this.sourceOptions.list[index].nowWriteOffMoney,
              this.sourceOptions.list[index],
              'nowWriteOffMoney',
              this.sourceOptions.list[index].sourceBillType == '120103' ? false : true,
              8,
              true
            )
          }
          break
        case 'getSelectDataPay':
        case 'getSelectData':
          if (!row) return
          let { check } = row
          if (check.length <= 0) return
          if (type == 'getSelectData') {
            let tableIndex = this.options.tableIndex
            this.$set(this.options.list[tableIndex], 'bankAccountId', check[0].bankAccountId)
            this.$set(this.options.list[tableIndex], 'bankAccountNo', check[0].bankAccountNo)
            this.$set(this.options.list[tableIndex], 'bankAccountName', check[0].bankAccountName)
          } else {
            let tableIndex = this.options.tableIndex
            this.$set(this.options.list[tableIndex], 'payModeId', check[0].payModeId)
            this.$set(this.options.list[tableIndex], 'payModeName', check[0].payModeName)
            this.$set(this.options.list[tableIndex], 'payModeNo', check[0].payModeNo)
          }
          break
        case 'tableIndex':
          this.options.tableIndex = row
          break
        default:
          break
      }
    },
    countFun(list, nowWriteOffMoneyTotal) {
      let array = JSON.parse(JSON.stringify(list))
      let idx = null;
      for (const [i, v] of array.entries()) {
        nowWriteOffMoneyTotal = fcount([nowWriteOffMoneyTotal, v.payMoney], '-')
        if (nowWriteOffMoneyTotal === 0) {
          idx = i + 1;
          break;
        } else if (nowWriteOffMoneyTotal < 0) {
          idx = i;
          array[idx].payMoney = Math.abs(nowWriteOffMoneyTotal);
          break;
        }
      }
      array = array.slice(idx !== null ? idx : array.length)
      return array
    },
    filterDataFun(list) {
      let arrlist = list.map((item) => (
        {
          ...item,
          billDate: item.sourceBillDateStr,
          billTypeName: item.sourceBillTypeName,
          billNo: item.sourceBillNo,
          remark: item.remark,
          billRemark: item.sourceBillRemark,
          billTaxMoney: ['120103', 120103, '190106', 190106].includes(item.sourceBillType) ? fcount([item.sourceBillMoney, -1], '*') : item.sourceBillMoney,
          settleMoney: ['120103', 120103, '190106', 190106].includes(item.sourceBillType) ? fcount([item.sourceBillWrittenOffMoney, -1], '*') : item.sourceBillWrittenOffMoney,
          unverified: fcount([['120103', 120103, '190106', 190106].includes(item.sourceBillType) ? fcount([item.sourceBillMoney, -1], '*') : item.sourceBillMoney, ['120103', 120103, '190106', 190106].includes(item.sourceBillType) ? fcount([item.sourceBillWrittenOffMoney, -1], '*') :
            item.sourceBillWrittenOffMoney], '-'),
          nowWriteOffMoney: ['120103', 120103, '190106', 190106].includes(item.sourceBillType) ? fcount([item.nowWriteOffMoney, -1], '*') : item.nowWriteOffMoney
        }
      ))
      return arrlist
    },
    async userSubmit(isAdd, type) {
      // 1代表 计入优惠金额 2转预收款 3 转预收款确认传的参数
      this.loading = true
      let form = {
        billNo: this.options.billNo,
        billId: this.options.billId,
        partnerId: this.options.partnerId,
        deptId: this.options.deptId,
        saleEmployeeId: this.options.saleEmployeeId,
        billRemark: this.options.billRemark,
        discountMoney: this.options.discountMoney,
        billDate: this.options.billDate
          ? this.options.billDate.split(' ')[0]
          : '',
        payDetails: this.options.list,
        arDetails: this.sourceOptions.list,
        fileItems: this.options.fileItems,
      }
      if (type === 1) {
        console.log(this.options.payMoneyTotal, 'payMoneyTotal')
        console.log(this.options.nowWriteOffMoneyTotal, 'payMoneyTotal')
        this.options.discountMoney = fcount([this.options.nowWriteOffMoneyTotal, this.options.payMoneyTotal], '-')
        form.discountMoney = this.options.discountMoney
      }
      if (type === 3) {
        let tranPreBill = {
          discountMoney: this.SourceOperationData.discountMoney ? this.SourceOperationData.discountMoney : 0,
          payDetails: this.SourceOperationData.list,
        }
        form.tranPreBill = tranPreBill
      }
      if (form.billId) {
        modifyReceiveBill(form).then((res) => {
          this.options = {
            ...this.options,
            billStatus: res.data.billStatus,
            preRpBillNo: res.data.preRpBillNo,
          }
          console.log(res, 'res')
          let arrlist = this.filterDataFun(res.data.arDetails)
          this.sourceOptions.list = arrlist
          this.$message.success('修改成功')
          //新增
          if (isAdd) {
            this.reset()
            this.getBillno()
          }
          this.loading = false
        }).catch((err) => {
          this.loading = false
        })
      } else {
        addReceiveBill(form).then((res) => {
          this.options = {
            ...this.options,
            billStatus: res.data.billStatus,
            preRpBillNo: res.data.preRpBillNo,
            billId: res.data.billId
          }
          let arrlist = this.filterDataFun(res.data.arDetails)
          this.sourceOptions.list = arrlist
          this.$message.success('新增成功')
          //新增
          if (isAdd) {
            this.reset()
            this.getBillno()
          }
          this.loading = false
        }).catch((err) => {
          this.loading = false
        })
      }
    },
    // 提交保存
    async submitForm(isAdd) {
      this.isAdd = isAdd
      this.$refs['form'].validate(async valid => {
        if (valid) {
          let sourceIndex = this.sourceOptions.list.findIndex(item => item.nowWriteOffMoney == '' || item.nowWriteOffMoney == undefined)
          if (sourceIndex != '-1' || this.sourceOptions.list.length == 0) return this.$message.error('请先录入完整的源单信息')
          // 优惠金额 = 本次核销金额 - (收款金额 + 手续费)
          let payMoneyTotal = 0; // 本次收款统计
          let nowWriteOffMoneyTotal = 0; // 本次核销金额统计
          payMoneyTotal = this.options.list.reduce((prev, next) => {
            return fcount([prev, next.payMoney], '+');
          }, 0);
          nowWriteOffMoneyTotal = this.sourceOptions.list.reduce((prev, next) => {
            return fcount([prev, next.nowWriteOffMoney], '+');
          }, 0);
          this.options.payMoneyTotal = payMoneyTotal
          this.options.nowWriteOffMoneyTotal = nowWriteOffMoneyTotal
          console.log(payMoneyTotal);
          console.log(nowWriteOffMoneyTotal);

          let discountMoney = this.options.discountMoney ? this.options.discountMoney : 0
          if (fcount([fcount([discountMoney, payMoneyTotal], '+'), nowWriteOffMoneyTotal], '-') > 0) {
            this.selectOptions()
          } else if (fcount([fcount([discountMoney, payMoneyTotal], '+'), nowWriteOffMoneyTotal], '-') < 0) {
            this.$message.error('本次核销总金额不等于收款核销总金额!')
          } else {
            this.userSubmit(isAdd, 0)
          }
        } else this.$message.error('请输入必填项!')
      })
    },
    //初始化数据
    reset() {
      //表格配置
      this.options = {
        ...this.options,
        billNo: undefined,
        billDate: undefined,
        partnerId: undefined,
        deptId: undefined,
        saleEmployeeId: undefined,
        accountsBalance: undefined, // 应收款余额 不传的
        discountMoney: undefined,
        billStatus: '',
        billRemark: '',
        fileItems: [],
      }
      this.options.list = [{}],
        this.sourceOptions.list = [],
        this.resetForm('form')
    },
    // 获取订单编号
    async getBillno() {
      this.options.billNo = await getBillNo(190101)
      this.options.billDate = new Date()
        .toLocaleDateString()
        .replace(/\//g, '-')
    },
    // 审核/反审核  按钮
    async auditForm(type) {
      if ([2, '2'].includes(this.options.billStatus) && type == '审核') return this.$message.warning('此单据不是未审核的状态哦~')
      if (['审核', '反审核'].includes(type)) {
        await this.$confirm(`确定${type}该方案`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.loading = true
        const { data } = await modifyBillStatus({
          billIds: [this.options.billId],
          billStatus: type == '审核' ? 2 : 0
        })
        this.options = {
          ...this.options,
          billStatus: data.billStatus
        }
        const res = await getShouldReceiveSaleFunds({ partnerId: this.options.partnerId })
        this.options.accountsBalance = res.data.shouldSettleMoney
        this.$message.success(`${type}成功`)
        this.loading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  overflow-y: scroll;
  //附件
  .accessoryUpload {
    margin: 6px 10px;
    min-height: 150px;
  }
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 250px;
}
.table {
  padding: 10px;
}
.leftContent {
  .text {
    width: 70px;
    font-size: 14px;
    line-height: 40px;
  }
  .el-icon-question {
    margin-top: 10px;
  }
  ::v-deep .el-input--medium {
    width: 140px;
  }
  ::v-deep .el-input__inner {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #ccc;
  }
}
</style>
