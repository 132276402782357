var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._b(
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
          {
            name: "select-load",
            rawName: "v-select-load",
            value: _vm.downLoad,
            expression: "downLoad",
          },
        ],
        ref: "remoteSelect",
        class: ["sys-remote", _vm.loading ? "remote-hide" : ""],
        attrs: {
          filterable: "",
          "no-data-text": "无可选项",
          placeholder: _vm.option.placeholder || _vm.$attrs.placeholder,
          "popper-class":
            "load-remote my-select-dropdown local-" +
            (_vm.option.type || "") +
            " remote-" +
            (_vm.multiple ? "multiple" : "radio"),
          "element-loading-text": "加载中",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(255, 255, 255,1)",
          remote: _vm.visible,
          loading: _vm.remoteLoad,
          "value-key": _vm.option.valueKey,
          "collapse-tags": _vm.option.collapseTags || false,
          "remote-method": _vm.debounceMethod,
          "reserve-keyword": _vm.option.reserveKeyword || true,
          "multiple-limit": _vm.option.multipleLimit || 0,
          clearable: _vm.option.clearable || false,
          disabled:
            _vm.loading ||
            (_vm.option.disabled && typeof _vm.option.disabled === "function"
              ? _vm.option.disabled()
              : _vm.option.disabled) ||
            _vm.$attrs.disabled,
          sunyunSelect: _vm.sunyunSelect,
          size: _vm.size,
        },
        on: { change: _vm.change, "visible-change": _vm.visibleChange },
        nativeOn: {
          keydown: function ($event) {
            return _vm.handleSelectKeydown($event)
          },
        },
        model: {
          value: _vm.selModel,
          callback: function ($$v) {
            _vm.selModel = $$v
          },
          expression: "selModel",
        },
      },
      "el-select",
      _vm.$attrs,
      false
    ),
    [
      _vm.option.labels
        ? [
            _c(
              "div",
              { staticClass: "static", class: { sticky: _vm.isKeying } },
              _vm._l(_vm.option.labels, function (item, i) {
                return _c(
                  "span",
                  {
                    key: item.title + i,
                    staticClass: "headTitle",
                    style:
                      (i !== 0 ? "color: #8492a6;font-size: 13px;" : "") +
                      "min-width: " +
                      (item.width || "150") +
                      "px;width: " +
                      (item.width || "150") +
                      "px;max-width: " +
                      (item.width || "150") +
                      "px;",
                  },
                  [_vm._v(" " + _vm._s(item.title) + " ")]
                )
              }),
              0
            ),
          ]
        : _vm._e(),
      _vm.showNoData
        ? _c("el-option", {
            style: _vm.showStyle,
            attrs: { label: "无可选项", value: "v@404@^", disabled: "" },
          })
        : !_vm.remoteLoad
        ? [
            _vm.multiple
              ? _c(
                  "el-checkbox-group",
                  {
                    model: {
                      value: _vm.selModel,
                      callback: function ($$v) {
                        _vm.selModel = $$v
                      },
                      expression: "selModel",
                    },
                  },
                  _vm._l(_vm.data, function (obj, i) {
                    return _c(
                      "el-option",
                      {
                        key: obj[_vm.keyName] + "." + i,
                        attrs: {
                          label: obj[_vm.option.label],
                          disabled: obj.disabled,
                          value: _vm.isValuekey ? obj : obj[_vm.keyName],
                        },
                      },
                      [
                        _c(
                          "div",
                          { class: ["label"] },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticStyle: { "pointer-events": "none" },
                                attrs: {
                                  label: _vm.isValuekey
                                    ? obj
                                    : obj[_vm.keyName],
                                },
                              },
                              [_vm._v(_vm._s())]
                            ),
                            _vm.option.labels
                              ? _vm._l(
                                  _vm.option.labels,
                                  function (item, index) {
                                    return _c(
                                      "span",
                                      {
                                        key: item.label + index,
                                        staticClass: "headTitle",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(obj[item.label]) + " "
                                        ),
                                      ]
                                    )
                                  }
                                )
                              : _c("span", { staticClass: "headTitle" }, [
                                  _vm._v(
                                    " " + _vm._s(obj[_vm.option.label]) + " "
                                  ),
                                ]),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  1
                )
              : _vm._l(_vm.data, function (obj, i) {
                  return _c(
                    "el-option",
                    {
                      key: obj[_vm.keyName] + "." + i,
                      attrs: {
                        label: obj[_vm.option.label],
                        disabled: obj.disabled,
                        value: _vm.isValuekey ? obj : obj[_vm.keyName],
                      },
                    },
                    [
                      _vm.option.labels
                        ? _c(
                            "div",
                            {
                              class: [
                                _vm.data.findIndex(function (x) {
                                  return x[_vm.keyName] === obj[_vm.keyName]
                                }) === i
                                  ? "label"
                                  : "label2",
                              ],
                            },
                            _vm._l(_vm.option.labels, function (item, index) {
                              return _c(
                                "span",
                                {
                                  key: item.label + "." + index + "." + i,
                                  staticClass: "headTitle",
                                },
                                [_vm._v(" " + _vm._s(obj[item.label]) + " ")]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  )
                }),
          ]
        : _vm._e(),
      _vm.downLoading
        ? _c("el-option", {
            style: _vm.loadStyle,
            attrs: { disabled: "", label: "加载中...", value: "v@loading@^" },
          })
        : _vm._e(),
      _vm.buttons
        ? _c(
            "div",
            {
              staticClass: "static static-bottom",
              class: { sticky: _vm.isKeying },
            },
            _vm._l(_vm.buttons, function (item, i) {
              return _c(
                "div",
                { key: "" + item.title + i, staticClass: "content" },
                [
                  _c(
                    "el-link",
                    {
                      attrs: { icon: item.icon },
                      on: {
                        click: function ($event) {
                          return _vm.handleEvent(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c("Dialog", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: function ($event) {
            return _vm.handleEvent({ click: "getSelectData" })
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }