<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import {
  franchiseSingleListAPI,
  franchiseSingleListSummaryAPI,
  franchiseBillListAPI,
  franchiseBillListSummaryAPI,
  franchiseCashierListAPI,
  franchiseCashierListSummaryAPI,
  franchiseCategoryListAPI,
  franchiseCategoryListSummaryAPI,
  franchiseTimeListAPI,
  franchiseTimeListSummaryAPI,
  franchiseListShopSummaryAPI,
  franchiseListShopSummarySummaryAPI,
  franchiseListMachineSummaryAPI,
  franchiseListMachineSummarySummaryAPI,
  franchiseListDateSummaryAPI,
  franchiseListDateSummarySummaryAPI,
} from "@/api/joinClient/report/saleTotal"; // 门店销售列表
import TablePage from "@/components/tablePage";
import {
  singleListAPI,
  singleListSummaryAPI,
  billListAPI,
  billListSummaryAPI,
  cashierListAPI,
  cashierListSummaryAPI,
  categoryListAPI,
  categoryListSummaryAPI,
  timeListAPI,
  timeListSummaryAPI,
  listShopSummaryAPI,
  listShopSummarySummaryAPI,
  listMachineSummaryAPI,
  listMachineSummarySummaryAPI,
  listDateSummaryAPI,
  listDateSummarySummaryAPI,
} from "@/api/shop/report/saleTotal"; // 门店销售列表
export default {
  name: "SaleTotal",
  components: {
    TablePage,
  },
  props: {
    shopType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "门店销售汇总",
        rowKey: "shopId",
        search: [
          {
            label: "销售时间",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "goodsNos", label: "商品编号" },
              { filter: "goodsNames", label: "商品名称" },
              { filter: "categoryNos", label: "类别编号" },
              { filter: "categoryNames", label: "类别名称" },
              { filter: "shopNos", label: "门店编号" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              seniorSearch: true,
              // hide: () =>
              //   this.options?.tabColumnType == "goods" ||
              //   this.options?.tabColumnType == "billDateStr",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCategory",
            option: {
              filter: "categoryIds",
              seniorSearch: true,
              // hide: () =>
              //   this.options?.tabColumnType == "category" ||
              //   this.options?.tabColumnType == "billDateStr",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品类别",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "category" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              // hide: () => this.options?.tabColumnType != "cashier",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCashier",
            option: {
              filter: "cashierIds",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "cashier",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收银员",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "cashier" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            type: "input",
            label: "小票查询",
            model: "",
            hide: () => this.options?.tabColumnType == "note",
            filter: "billNo",
            seniorSearch: true,
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "shopGoods",
        tabsColumns: [
          {
            title: "门店商品汇总查询",
            type: "shopGoods",
            getSummaryApi:
              this.shopType == 2 ? franchiseSingleListSummaryAPI : singleListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseSingleListAPI : singleListAPI,
            uuid: "047f99b2-2b90-18f0-eea3-40e010f191d0",
            defaultBody: { isSummary: false },
            exportOption: {
              fastExportUrl:
                this.shopType == 2
                  ? "/api/system/franchise/shop/report/sale/summary/listSingleItemExport"
                  : "/api/system/shop/report/sale/summary/listSingleItemExport",
              exportName: "门店商品汇总查询",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 180,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 100,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 100,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 150,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "优惠金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率",
                minWidth: 100,
                align: "center"
              }
            ],
            summary: ["unitQty", "unitMoney", "agioMoney", 'unitOldMoney'],
          },
          {
            title: "商品汇总查询",
            type: "goods",
            getSummaryApi:
              this.shopType == 2 ? franchiseSingleListSummaryAPI : singleListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseSingleListAPI : singleListAPI,
            uuid: "7fb903ad-40db-6a82-e849-c72f82eb1ad1",
            defaultBody: { isSummary: true },
            exportOption: {
              fastExportUrl:
                this.shopType == 2
                  ? "/api/system/franchise/shop/report/sale/summary/listSingleItemExport"
                  : "/api/system/shop/report/sale/summary/listSingleItemExport",
              exportName: "商品汇总查询",
            },
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 180,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 100,
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 100,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 150,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "优惠金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率",
                minWidth: 100,
                align: "center"
              }
            ],
            summary: ["unitQty", "unitMoney", "agioMoney", 'unitOldMoney'],
          },
          {
            title: "门店分类汇总查询",
            type: "shopCategory",
            getSummaryApi:
              this.shopType == 2
                ? franchiseCategoryListSummaryAPI
                : categoryListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseCategoryListAPI : categoryListAPI,
            uuid: "88812ac1-8249-d5c6-fc72-012c946fb9b8",
            defaultBody: { isSummary: false },
            exportOption: {
              fastExportUrl:
                this.shopType == 2
                  ? "/api/system/franchise/shop/report/sale/summary/listCategorySummaryExport"
                  : "/api/system/shop/report/sale/summary/listCategorySummaryExport",
              exportName: "门店分类汇总查询",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 150,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "优惠金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率",
                minWidth: 100,
                align: "center"
              }
            ],
            summary: ["unitQty", "unitMoney", "agioMoney", 'unitOldMoney'],
          },
          {
            title: "分类汇总查询",
            type: "category",
            getSummaryApi:
              this.shopType == 2
                ? franchiseCategoryListSummaryAPI
                : categoryListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseCategoryListAPI : categoryListAPI,
            uuid: "5b594c2b-e5f0-361c-82ac-bdf52e213316",
            defaultBody: { isSummary: true },
            exportOption: {
              fastExportUrl:
                this.shopType == 2
                  ? "/api/system/franchise/shop/report/sale/summary/listCategorySummaryExport"
                  : "/api/system/shop/report/sale/summary/listCategorySummaryExport",
              exportName: "分类汇总查询",
            },
            columns: [
              {
                prop: "categoryName3",
                label: "上上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryName2",
                label: "上级类别",
                minWidth: 120,
              },
              {
                prop: "categoryNo",
                label: "类别编号",
                minWidth: 120,
              },
              {
                prop: "categoryName",
                label: "类别名称",
                minWidth: 120,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 150,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "优惠金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率",
                minWidth: 100,
                align: "center"
              }
            ],
            summary: ["unitQty", "unitMoney", "agioMoney", 'unitOldMoney'],
          },
          {
            title: "门店汇总查询",
            type: "shop",
            getSummaryApi:
              this.shopType == 2
                ? franchiseListShopSummarySummaryAPI
                : listShopSummarySummaryAPI,
            getListApi:
              this.shopType == 2 ? franchiseListShopSummaryAPI : listShopSummaryAPI,
            uuid: "9367e398-02e3-0afa-3374-cfb686a5f810",
            // defaultBody: { searchType: 'shop' },
            exportOption: {
              fastExportUrl:
                this.shopType == 2
                  ? "/api/system/franchise/shop/report/sale/summary/listShopSummaryExport"
                  : "/api/system/shop/report/sale/summary/listShopSummaryExport",
              exportName: "门店汇总查询",
            },
            columns: [
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 150,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "优惠金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率",
                minWidth: 100,
                align: "center"
              }
            ],
            summary: ["unitQty", "unitMoney", "agioMoney", 'unitOldMoney'],
          },
          {
            title: "时间段汇总查询",
            type: "time",
            getSummaryApi:
              this.shopType == 2 ? franchiseTimeListSummaryAPI : timeListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseTimeListAPI : timeListAPI,
            uuid: "65bb6c41-ebaa-c2d9-309a-996cdf385817",
            // defaultBody: { searchType: 'time' },
            exportOption: {
              fastExportUrl:
                this.shopType == 2
                  ? "/api/system/franchise/shop/report/sale/summary/listTimeSlotSummaryExport"
                  : "/api/system/shop/report/sale/summary/listTimeSlotSummaryExport",
              exportName: "时间段汇总查询",
            },
            columns: [
              {
                prop: "timeSlot",
                label: "时间段",
                minWidth: 100,
              },
              {
                prop: "customerUnitPrice",
                label: "客单价",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "customerUnitQty",
                label: "客单数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 150,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "总优惠金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率",
                minWidth: 100,
                align: "center"
              }
            ],
            summary: ["customerUnitQty", "unitQty", "unitMoney", "agioMoney", 'unitOldMoney'],
          },
          {
            title: "收银员汇总查询",
            type: "cashier",
            getSummaryApi:
              this.shopType == 2 ? franchiseCashierListSummaryAPI : cashierListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseCashierListAPI : cashierListAPI,
            uuid: "5e905864-6365-7414-95c1-c065d2fab3e8",
            // defaultBody: { searchType: 'cashier' },
            exportOption: {
              fastExportUrl:
                this.shopType == 2
                  ? "/api/system/franchise/shop/report/sale/summary/listCashierSummaryExport"
                  : "/api/system/shop/report/sale/summary/listCashierSummaryExport",
              exportName: "收银员汇总查询",
            },
            columns: [
              {
                prop: "cashierNo",
                label: "收银员编号",
                minWidth: 155,
              },
              {
                prop: "cashierName",
                label: "收银员名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 150,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "总优惠金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率",
                minWidth: 100,
                align: "center"
              }
            ],
            summary: ["customerUnitQty", "unitQty", "unitMoney", "agioMoney", 'unitOldMoney'],
          },
          {
            title: "小票汇总查询",
            type: "note",
            getSummaryApi:
              this.shopType == 2 ? franchiseBillListSummaryAPI : billListSummaryAPI,
            getListApi: this.shopType == 2 ? franchiseBillListAPI : billListAPI,
            uuid: "7abc9cd7-0c65-01ed-5f27-eaca6e7adfe2",
            // defaultBody: { searchType: 'note' },
            exportOption: {
              fastExportUrl:
                this.shopType == 2
                  ? "/api/system/franchise/shop/report/sale/summary/listBillNoExport"
                  : "/api/system/shop/report/sale/summary/listBillNoExport",
              exportName: "小票汇总查询",
            },
            columns: [
              {
                prop: "billNo",
                label: "销售小票号",
                type: "link",
                click: "update",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "billDate",
                label: "销售时间",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "cashierNo",
                label: "收银员编号",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员名称",
                minWidth: 150,
              },
              {
                prop: "billSaleMoney",
                label: "销售金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 150,
                align: "center",
                sortable: true,
              },
              {
                prop: "billFactMoney",
                label: "收款金额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "payTypes",
                align: "center",
                label: "收款方式",
                children: () =>
                  this.options.list?.[0]?.payTypes?.map?.((x) => ({
                    ...x,
                    label: x.value,
                    prop: `payDetailList.${x.payMode}.payModeId.payMoney`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.payDetailList?.find((y) => y.payModeId === x.payMode)
                        ?.payMoney || 0,
                  })) || [],
              },
              {
                prop: "agioRateSum",
                label: "折扣率",
                minWidth: 100,
                align: "center"
              }
            ],
            summary: ["billSaleMoney", "billFactMoney", 'unitOldMoney'],
          },
          {
            title: "收银机汇总查询",
            type: "machine",
            getSummaryApi:
              this.shopType == 2
                ? franchiseListMachineSummarySummaryAPI
                : listMachineSummarySummaryAPI,
            getListApi:
              this.shopType == 2 ? franchiseListMachineSummaryAPI : listMachineSummaryAPI,
            uuid: "85fe7089-803e-9f11-dc95-a4990bdbdbfa",
            // defaultBody: { searchType: 'machine' },
            exportOption: {
              fastExportUrl:
                this.shopType == 2
                  ? "/api/system/franchise/shop/report/sale/summary/listMachineSummaryExport"
                  : "/api/system/shop/report/sale/summary/listMachineSummaryExport",
              exportName: "收银机汇总查询",
            },
            columns: [
              {
                prop: "machineNo",
                label: "收银机编号",
                minWidth: 150,
              },
              {
                prop: "machineName",
                label: "收银机名称",
                minWidth: 150,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 150,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "优惠金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率",
                minWidth: 100,
                align: "center"
              }
            ],
            summary: ["unitQty", "unitMoney", "agioMoney", 'unitOldMoney'],
          },
          {
            title: "日期汇总查询",
            type: "billDateStr",
            getSummaryApi:
              this.shopType == 2
                ? franchiseListDateSummarySummaryAPI
                : listDateSummarySummaryAPI,
            getListApi:
              this.shopType == 2 ? franchiseListDateSummaryAPI : listDateSummaryAPI,
            uuid: "ef07b5fe-f815-0309-cda4-bea18d649de4",
            // defaultBody: { searchType: 'billDateStr' },
            exportOption: {
              fastExportUrl:
                this.shopType == 2
                  ? "/api/system/franchise/shop/report/sale/summary/listDateSummaryExport"
                  : "/api/system/shop/report/sale/summary/listDateSummaryExport",
              exportName: "日期汇总查询",
            },
            columns: [
              {
                prop: "billDateStr",
                label: "销售日期",
                minWidth: 155,
              },
              {
                prop: "unitQty",
                label: "数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "unitMoney",
                label: "销售额",
                minWidth: 150,
                sortable: true,
              },
              {
                prop: "unitOldMoney",
                label: "原价金额",
                minWidth: 150,
                align: "center",
                sortable: true,
              },
              {
                prop: "agioMoney",
                label: "优惠金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "agioRateSum",
                label: "折扣率",
                minWidth: 100,
                align: "center"
              }
            ],
            summary: ["unitQty", "unitMoney", "agioMoney", 'unitOldMoney'],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          if (this.options.tabColumnType === "note") {
            this.$router.push({
              name: "saleTotalDetail",
              query: {
                billNo: row.billNo,
                type: "Update",
                shopType: this.shopType,
              },
            });
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
