<template>
  <div class="editTable">
    <vxe-table
      :key="config.tableKey"
      border
      show-overflow
      ref="xTable"
      id="xTable"
      :data="config.list"
      :height="config.tableHeight"
      :row-config="{ isHover: true, isCurrent: true }"
      :scroll-y="{ enabled: true }"
      :footer-method="footerMethod"
      :show-footer="Boolean(this.config.summary)"
      :edit-rules="config.rules"
      :edit-config="
        !['2', '3', '4'].includes(config.billStatus)
          ? { trigger: 'click', mode: 'row', autoClear: false }
          : undefined
      "
      :mouse-config="{ selected: true }"
      keep-source
      :keyboard-config="{
        isArrow: true,
        isEsc: true,
        isDel: true,
        isTab: true,
        isEdit: true,
      }"
    >
      <!-- 
            :keyboard-config="{
        isArrow: true,
        isEsc: true,
        isDel: true,
        isEnter: true,
        isTab: true,
        isEdit: true,
      }"
     -->
      <!-- @cell-click="cellClick"
      @cell-mouseenter="mouseenter"
      @cell-mouseleave="mouseleave" -->

      <vxe-column align="center" width="50" title="序号" fixed="left">
        <template #default="{ rowIndex }">
          <span
            v-if="config.pagination && config.pagination.page && config.pagination.size"
            >{{
              rowIndex + 1 + (config.pagination.page - 1) * config.pagination.size
            }}</span
          >
          <span v-else>{{ rowIndex + 1 }}</span>
        </template>
      </vxe-column>
      <vxe-column
        align="center"
        width="70"
        title="操作"
        field="com_opera_column"
        v-if="!config.notOperaColumn"
        fixed="left"
      >
        <template #default="{ rowIndex }">
          <span class="operaBtn">
            <i
              v-if="!config.notPushBtn"
              @click.stop.self="handleTableRow('push', rowIndex)"
              class="el-icon-circle-plus operatePush"
              :class="{ disabled: config.disabled }"
            ></i>
            <i
              v-if="!config.notDelBtn"
              @click.stop.self="handleTableRow('del', rowIndex)"
              class="el-icon-remove operateDel"
              :class="{ disabled: config.disabled }"
            ></i>
          </span>
        </template>
      </vxe-column>
      <TableColumns
        v-for="(item, i) in config.columns"
        :key="`${item.prop}.${i}`"
        :column="{ ...item, columnIndex: i }"
        :columns="config.columns"
        @handleEvent="handleEvent"
        ref="TableColumns"
        @handleKeyboardEvents="handleKeyboardEvents"
      >
      </TableColumns>
    </vxe-table>
    <div class="left-view" v-show="!config.hideTotal">
      <template>
        <span>共</span>
        <span class="num" style="color: #333">{{
          (config.list && config.list.length) || 0
        }}</span>
        <span>条</span>
        <template v-if="config.mutiSelect">
          <span>，已选</span>
          <span class="num">{{ config.check.length }}</span>
          <span>条</span>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import { fcount } from "@/utils";
/** 组件使用说明
 * @param {Array} list 列表数据
 * @param {Boolean} loading 是否添加表格loading加载动画
 * @param {Boolean} mutiSelect 是否显示多选框
 * @param {Boolean} reserveSelection 仅对 type=selection 的列有效，类型为 Boolean，为 true 则会在数据更新之后保留之前选中的数据（需指定 row-key）
 */
export default {
  // 组件
  name: "ComponentTable",
  components: {
    TableColumns: () =>
      import("@/components/billDetailPage/tableColumns/detailVxeTableColumns.vue"),
  },
  model: { prop: "options", event: "Device" },
  props: {
    options: { type: Object, required: true },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  // 数据
  data() {
    return {
      oldRowIndex: undefined,
    };
  },
  watch: {
    config: {
      handler(val) {
        this.$emit("Device", val);
      },
      deep: true,
    },
  },
  computed: {
    // 是否 显示按钮操作组
    operatesShow() {
      return typeof this.operates === "object" && this.operates?.list?.length > 0;
    },
    config: {
      get() {
        return this.options;
      },
      set(val) {
        // this.$emit('Device', val)
      },
    },
    editColumn() {
      console.log("进来更新一下");
      return this.options.columns
        .map((item, index) => ({ ...item, columnIndex: index }))
        .filter((item) => item.type);
    },
  },
  async activated() {
    console.log("进来了嘛1000", this.config.list?.length);
    await this.$nextTick();
    //默认表格第一行高亮+可编辑
    setTimeout(() => {
      if (this.config.list?.length === 1) {
        const $table = this.$refs.xTable;
        $table.setEditRow($table.getData()[0]);
        $table.setCurrentRow($table.getData()[0]);
      }
    }, 300);
  },
  methods: {
    //  async cellClick({ row, rowIndex, column }) {
    //     console.log('点击')
    //     if (column.field !== "com_opera_column") {
    //       await this.$nextTick();
    //       const $table = this.$refs.xTable;
    //       console.log('点击',$table)
    //       const editRow = $table.getEditRecord()
    //       console.log(row,'点击1')
    //       if (editRow && editRow.row._X_ROW_KEY == row._X_ROW_KEY) return
    //       $table.setEditRow(row);
    //     }
    //     const $table = this.$refs.xTable;
    //     const editRow = $table.getEditRecord()
    //     if (editRow && editRow.row._X_ROW_KEY == row._X_ROW_KEY) return
    //     $table.setEditRow(row);
    //     row.isHover = false
    //     this.$set(row,'isHover',true)
    //   },
    async cellClick({ row, rowIndex, column }) {
      if (column.field !== "com_opera_column") {
        const $table = this.$refs.xTable;
        // if (!$table.isEditByRow(row)) {
        //   const res = await $table.setEditCell(row, column);
        //   if (typeof res !== "undefined") {
        //     $table.setEditRow(row, "unitQty");
        //   }
        // }
      }
    },
    //表格鼠标移入
    mouseenter({ row, rowIndex }) {
      // this.oldRowIndex = rowIndex
      // const $table = this.$refs.xTable
      // // $table.setEditRow(row)
      // const isActivate = $table.isEditByRow(row)
      // if (isActivate) return
      // // row.isHover = true
      // // this.$refs.xTable.updateData()
      // this.$set(row,'isHover',true)
    },
    //表格鼠标移出
    mouseleave({ row, rowIndex }) {
      // if(this.oldRowIndex == rowIndex) return
      // const $table = this.$refs.xTable
      //   $table.clearEdit().then(() => {
      //   // 还原行数据
      //   $table.revertData(row)
      // })
      // this.config.list = this.config.list.map(item => ({...item,isHover:false}))
      // row.isHover = false
      // this.$refs.xTable.updateData()
      // if(row.isHover) this.$set(row,'isHover',false)
    },
    //表格增加/减少一行方法
    async handleTableRow(type, index) {
      if (this.config.disabled) return;
      const $table = this.$refs.xTable;
      // console.log("表格7777", $table);
      if (type === "push") {
        this.config.list.splice(index + 1, 0, {});
        //新增行显示可编辑
        await this.$nextTick();
        $table.setEditRow($table.getData()[index + 1]);
        $table.setCurrentRow($table.getData()[index + 1]);
        // $table.getData().isHover = true;
      } else {
        if (this.config.list.length <= 1 && !this.config.notPushBtn) {
          this.$set(this.config.list, index, {});
          await this.$nextTick();
          $table.setEditRow($table.getData()[0]);
          $table.setCurrentRow($table.getData()[0]);
          return;
        }
        this.config.list.splice(index, 1);
        await this.$nextTick();

        let highlightIndex = $table.getData().length == index ? index - 1 : index;
        $table.setEditRow($table.getData()[highlightIndex]);
        $table.setCurrentRow($table.getData()[highlightIndex]);
      }
      this.$refs.xTable.updateData();
    },
    async handleEvent(type, row) {
      this.$emit("handleEvent", type, row);
      if (type == "updateData") {
        this.updateFooterEvent();
      }
    },
    // 在值发生改变时更新表尾合计
    updateFooterEvent() {
      const $table = this.$refs.xTable;
      $table.updateFooter();
    },
    footerMethod({ columns, data }) {
      const footerData = [
        columns.map((column, _columnIndex) => {
          if (_columnIndex === 0) {
            return "合计";
          }
          let summaryItem = this.config.summary.find((item) => item == column.field);
          if (summaryItem) return this.sumNum(data, summaryItem);
          return null;
        }),
      ];
      return footerData;
    },
    // 进行合计
    sumNum(costForm, type) {
      let total = 0;
      for (let i = 0; i < costForm.length; i++) {
        total = fcount([total, costForm[i][type]], "+");
      }
      return this.moneyFilter(total);
    },
    //去除.
    moneyFilter(value) {
      if (!value) return "0.00";
      value = value - 0;
      return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    },
    // 操作键盘事件
    async handleKeyboardEvents({ key, prop, rowIndex, columnIndex }) {
      try {
        console.log("进来了吗键盘");
        await this.$nextTick();
        // 上下左右回车键
        if (!["ArrowUp", "ArrowDown", "ArrowRight", "Enter", "ArrowLeft"].includes(key)) {
          return;
        }
        setTimeout(async () => {
          let $table = this.$refs.xTable;
          let curRef = "";
          let nextRef = "";
          let newRowIndex = rowIndex;
          let newProp = prop;
          const curColumn = this.editColumn.find(
            (item) => item.columnIndex === columnIndex
          );
          const curTableRef = this.$refs.TableColumns?.find(
            (item) => item.column.columnIndex == columnIndex
          );
          // 当前编辑框 失去焦点
          if (["localSelect", "remoteSelect"].includes(curColumn.type)) {
            // curRef = this.$refs.TableColumns[columnIndex].$refs[
            //   newProp + "_" + newRowIndex + "_" + columnIndex
            // ].$refs.remoteSelect;
            curRef = curTableRef
              ? curTableRef.$refs[newProp + "_" + newRowIndex + "_" + columnIndex].$refs
                  .remoteSelect
              : "";
          } else {
            curRef =
              ["ArrowRight", "Enter", "ArrowLeft"].includes(key) && curTableRef
                ? curTableRef.$refs[newProp + "_" + newRowIndex + "_" + columnIndex].$refs
                    .remoteSelect
                : "";
            // curRef = ["ArrowRight", "Enter", "ArrowLeft"].includes(key)
            //   ? this.$refs.TableColumns[columnIndex].$refs[
            //       newProp + "_" + newRowIndex + "_" + columnIndex
            //     ]
            //   : "";
          }
          let curColumnIndex = this.editColumn.findIndex(
            (item) => item.columnIndex === columnIndex
          );
          if (["ArrowUp", "ArrowDown"].includes(key)) {
            //上 下
            let index = key === "ArrowUp" ? newRowIndex - 1 : newRowIndex + 1;
            if (index === this.config.list.length && key === "ArrowDown") {
              // 最后一列时 下键事件定位下一行第一列编辑框 没有下一行增加一行
              this.handleTableRow("push", newRowIndex);
              await this.$nextTick();
              curColumnIndex = 0;
            } else {
              $table.setEditRow($table.getData()[index]);
              $table.setCurrentRow($table.getData()[index]);
              await this.$nextTick();
            }
            const nextColumn = this.editColumn[curColumnIndex];
            const nextColumnIndex = nextColumn.columnIndex;
            const nextColumnField = nextColumn.prop;
            console.log("有没有ref");
            const nextTableRef = this.$refs.TableColumns?.find(
              (item) => item.column.columnIndex == nextColumnIndex
            );
            console.log("怎么回事1", nextTableRef.$refs);
            console.log(
              "怎么回事2",
              nextColumnField + "_" + index + "_" + nextColumnIndex
            );
            setTimeout(() => {
              if (["localSelect", "remoteSelect"].includes(nextColumn.type)) {
                nextRef = nextTableRef
                  ? nextTableRef.$refs[
                      nextColumnField + "_" + index + "_" + nextColumnIndex
                    ].$refs.remoteSelect
                  : "";
              } else {
                nextRef = nextTableRef
                  ? nextTableRef.$refs[
                      nextColumnField + "_" + index + "_" + nextColumnIndex
                    ]
                  : "";
              }
              console.log("怎么回事3", nextRef);
            }, 50);
          } else if (["ArrowLeft", "ArrowRight", "Enter"].includes(key)) {
            if (columnIndex === 0 && key === "ArrowLeft") return; // 第一个列时不处理 向左不处理
            // const curColumnIndex = this.editColumn.findIndex(
            //   (item) => item.columnIndex === columnIndex
            // );
            let index = key === "ArrowLeft" ? curColumnIndex - 1 : curColumnIndex + 1;
            if (index === this.editColumn.length && key === "ArrowRight") return; // 最后一列时 向右不处理
            if (index === this.editColumn.length && key === "Enter") {
              // 最后一列时 回车事件定位下一行第一列编辑框 没有下一行增加一行
              if (newRowIndex + 1 === this.config.list.length) {
                this.handleTableRow("push", newRowIndex);
                await this.$nextTick();
              } else {
                $table.setEditRow($table.getData()[newRowIndex + 1]);
                $table.setCurrentRow($table.getData()[newRowIndex + 1]);
              }
              newRowIndex = newRowIndex + 1;
              index = 0;
              curRef = "";
            }
            const nextColumn = this.editColumn[index];
            const nextColumnIndex = nextColumn.columnIndex;
            const nextColumnField = nextColumn.prop;
            const nextTableRef = this.$refs.TableColumns?.find(
              (item) => item.column.columnIndex == nextColumnIndex
            );
            setTimeout(() => {
              if (["localSelect", "remoteSelect"].includes(nextColumn.type)) {
                // nextRef = this.$refs.TableColumns[nextColumnIndex].$refs[
                //   nextColumnField + "_" + newRowIndex + "_" + nextColumnIndex
                // ].$refs.remoteSelect;
                nextRef = nextTableRef
                  ? nextTableRef.$refs[
                      nextColumnField + "_" + newRowIndex + "_" + nextColumnIndex
                    ].$refs.remoteSelect
                  : "";
              } else {
                nextRef = nextTableRef
                  ? nextTableRef.$refs[
                      nextColumnField + "_" + newRowIndex + "_" + nextColumnIndex
                    ]
                  : "";
                // nextRef = this.$refs.TableColumns[nextColumnIndex].$refs[
                //   nextColumnField + "_" + newRowIndex + "_" + nextColumnIndex
                // ];
              }
            }, 0);
          }
          // 当前编辑框失去焦点
          if (curRef) {
            curRef?.blur();
          }
          setTimeout(() => {
            console.log("有没有", nextRef);
            // 下一个编辑框获取焦点
            if (nextRef) {
              nextRef?.focus();
              if (nextRef?.select) nextRef?.select();
            }
          }, 100);
        }, 0);
      } catch (err) {
        //
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .vxe-header--column {
  padding: 6px 0 !important;
}
::v-deep .vxe-footer--column {
  padding: 6px 0 !important;
}
.operaBtn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.operatePush {
  width: 30px;
  height: 100%;
  color: #388be2;
  font-size: 25px;
  cursor: pointer;
  opacity: 1; /* 默认完全不透明 */
  transition: opacity 0.3s ease-in-out; /* 添加渐变过渡效果 */
  flex-grow: 1;
}

.operatePush:hover {
  color: #5ba9f9;
  opacity: 0.8; /* 悬停时透明度变为80% */
}

.operateDel {
  height: 100%;
  width: 30px;
  color: #ff3753;
  font-size: 25px;
  cursor: pointer;
  opacity: 1; /* 默认完全不透明 */
  transition: opacity 0.3s ease-in-out; /* 添加渐变过渡效果 */
  flex-grow: 1;
}

.operateDel:hover {
  color: #ff4355;
  opacity: 0.8; /* 悬停时透明度变为80% */
}
.disabled {
  opacity: 0.5;
  cursor: default;
}
::v-deep .vxe-table--render-default .vxe-cell {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
::v-deep .vxe-cell--valid-msg {
  padding: 5px !important;
}
</style>
