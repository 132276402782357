var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        marginTop: _vm.data.style.marginTop + "px",
        marginBottom: _vm.data.style.marginBottom + "px",
      },
    },
    [
      _vm.data.style.selectedList && _vm.data.style.selectedList.length > 0
        ? _c(
            "div",
            [
              [1, 2, 3, 4, 5, 6, 7].includes(_vm.data.style.type)
                ? _c("CubeMatrix2", {
                    attrs: { layout: _vm.data.style.type },
                    model: {
                      value: _vm.data.style.selectedList,
                      callback: function ($$v) {
                        _vm.$set(_vm.data.style, "selectedList", $$v)
                      },
                      expression: "data.style.selectedList",
                    },
                  })
                : _vm.data.style.type == 8
                ? _c(
                    "div",
                    {
                      staticClass: "decorate-cube",
                      style: { height: _vm.mainHeight },
                    },
                    _vm._l(_vm.data.style.selectedList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "cube-selected",
                          style: {
                            width: item.width / 2 + "px",
                            height: item.height / 2 + "px",
                            top: item.top + "px",
                            left: item.left - 1 + "px",
                          },
                        },
                        [
                          _c("img", {
                            style: {
                              height: item.height / 2 + "px",
                              width: item.width / 2 + "px",
                            },
                            attrs: { src: item.imageUrl },
                          }),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("img", {
            staticStyle: { width: "100%", height: "auto" },
            attrs: {
              src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png",
            },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }