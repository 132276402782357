var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "是否启用" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.isEnable,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isEnable", $$v)
                  },
                  expression: "form.isEnable",
                },
              }),
            ],
            1
          ),
          _vm.form.isEnable
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-transfer", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingShopTable,
                            expression: "loadingShopTable",
                          },
                        ],
                        attrs: {
                          titles: ["未选门店", "已选门店"],
                          data: _vm.shopList,
                        },
                        on: { change: _vm.transferChange },
                        model: {
                          value: _vm.form.shopIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "shopIds", $$v)
                          },
                          expression: "form.shopIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "配送类型" } }, [
                    _c("div", { staticClass: "distribution" }, [
                      _c(
                        "div",
                        { staticClass: "x-x" },
                        _vm._l(_vm.cityExpress, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "distributionData x-x" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "x-fc expressItem",
                                  class: { active: _vm.isActive == item.type },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handDistribution(item.type)
                                    },
                                  },
                                },
                                [
                                  _c("el-checkbox", {
                                    on: {
                                      change: function ($event) {
                                        return _vm.checkChange(item)
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                    model: {
                                      value: item.isStop,
                                      callback: function ($$v) {
                                        _vm.$set(item, "isStop", $$v)
                                      },
                                      expression: "item.isStop",
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "marL10",
                                    attrs: { src: item.imgUrl },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "user",
                          style: { padding: _vm.isActive == 7 ? "0" : "16px" },
                        },
                        [
                          _vm.isActive == 1
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "AppId" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入AppId",
                                            disabled: "",
                                          },
                                          model: {
                                            value: _vm.formLogistics.appId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formLogistics,
                                                "appId",
                                                $$v
                                              )
                                            },
                                            expression: "formLogistics.appId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "AppSecret" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入AppSecret",
                                            disabled: "",
                                          },
                                          model: {
                                            value: _vm.formLogistics.appSecret,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formLogistics,
                                                "appSecret",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formLogistics.appSecret",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "平台应用商户号",
                                          "label-width": "120px",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入平台应用商户号",
                                            disabled: "",
                                          },
                                          model: {
                                            value: _vm.formLogistics.merchantNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formLogistics,
                                                "merchantNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formLogistics.merchantNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "还没有达达账户",
                                          "label-width": "130px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleEvent("ddOpen")
                                              },
                                            },
                                          },
                                          [_vm._v("前往快速申请")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleEvent("dd")
                                              },
                                            },
                                          },
                                          [_vm._v("前往授权")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm.isActive == 2
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "AppId" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入AppId",
                                          },
                                          model: {
                                            value: _vm.uuForm.appId,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.uuForm, "appId", $$v)
                                            },
                                            expression: "uuForm.appId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "AppSecret" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入AppSecret",
                                          },
                                          model: {
                                            value: _vm.uuForm.appSecret,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.uuForm,
                                                "appSecret",
                                                $$v
                                              )
                                            },
                                            expression: "uuForm.appSecret",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "OpenId" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入OpenId",
                                          },
                                          model: {
                                            value: _vm.uuForm.merchantNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.uuForm,
                                                "merchantNo",
                                                $$v
                                              )
                                            },
                                            expression: "uuForm.merchantNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "还没有绑定UU门店",
                                          "label-width": "140px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: { click: _vm.bindDlaong },
                                          },
                                          [_vm._v("立即绑定")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm.isActive == 3
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "AppId" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入AppId",
                                          },
                                          model: {
                                            value: _vm.fnForm.appId,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.fnForm, "appId", $$v)
                                            },
                                            expression: "fnForm.appId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "code" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入code",
                                          },
                                          model: {
                                            value: _vm.fnForm.code,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.fnForm, "code", $$v)
                                            },
                                            expression: "fnForm.code",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "merchantId" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入merchantId",
                                          },
                                          model: {
                                            value: _vm.fnForm.merchantId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.fnForm,
                                                "merchantId",
                                                $$v
                                              )
                                            },
                                            expression: "fnForm.merchantId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "secretKey" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入secretKey",
                                          },
                                          model: {
                                            value: _vm.fnForm.secretKey,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.fnForm,
                                                "secretKey",
                                                $$v
                                              )
                                            },
                                            expression: "fnForm.secretKey",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm.isActive == 4
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "AppId" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入AppId",
                                          },
                                          model: {
                                            value: _vm.sfForm.appId,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.sfForm, "appId", $$v)
                                            },
                                            expression: "sfForm.appId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "AppSecret" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入AppSecret",
                                          },
                                          model: {
                                            value: _vm.sfForm.appSecret,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfForm,
                                                "appSecret",
                                                $$v
                                              )
                                            },
                                            expression: "sfForm.appSecret",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "开发者Id" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请输入开发者Id",
                                          },
                                          model: {
                                            value: _vm.sfForm.merchantNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.sfForm,
                                                "merchantNo",
                                                $$v
                                              )
                                            },
                                            expression: "sfForm.merchantNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "还没有绑定顺丰门店",
                                          "label-width": "140px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: { click: _vm.bindDlaong },
                                          },
                                          [_vm._v("立即绑定")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm.isActive == 5
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "fontS14 marR20" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.isSanSong
                                                ? "闪送物流已授权"
                                                : "闪送物流未授权"
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEvent("ss")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.isSanSong
                                                ? "重新授权"
                                                : "前往授权"
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送时间：" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#188ffe",
                          "inactive-color": "#919396",
                        },
                        model: {
                          value: _vm.form.enableSelfDeliveryTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "enableSelfDeliveryTime", $$v)
                          },
                          expression: "form.enableSelfDeliveryTime",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "marL15 fontColor",
                          staticStyle: { "font-size": "12px" },
                        },
                        [
                          _vm._v(
                            "关闭后，买家不需要选择配送时间，商家根据自己时间配送"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.form.enableSelfDeliveryTime
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "50px" } },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _c("span", { staticClass: "fontColor" }, [
                                _vm._v("可选择日期：开始日期为下单"),
                              ]),
                              _c("el-input", {
                                staticClass: "inputW100 marL15 marR15",
                                attrs: { size: "mini", placeholder: "0" },
                                model: {
                                  value: _vm.form.beginOrderDay,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "beginOrderDay", $$v)
                                  },
                                  expression: "form.beginOrderDay",
                                },
                              }),
                              _c("span", { staticClass: "fontColor" }, [
                                _vm._v("天至"),
                              ]),
                              _c("el-input", {
                                staticClass: "inputW100 marL15 marR15",
                                attrs: { size: "mini", placeholder: "7" },
                                model: {
                                  value: _vm.form.endOrderDay,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "endOrderDay", $$v)
                                  },
                                  expression: "form.endOrderDay",
                                },
                              }),
                              _c("span", { staticClass: "fontColor" }, [
                                _vm._v("天内结束"),
                              ]),
                              _c("span", { staticClass: "annotateGrey" }, [
                                _vm._v("（0代表当天）"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "50px" } },
                            [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("*"),
                              ]),
                              _c("span", { staticClass: "fontColor" }, [
                                _vm._v("可选择时间："),
                              ]),
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.form.enableSelectTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "enableSelectTime",
                                        $$v
                                      )
                                    },
                                    expression: "form.enableSelectTime",
                                  },
                                },
                                [_vm._v("勾选后买家先需要选择具体的时间")]
                              ),
                              _vm.form.enableSelectTime
                                ? _c("div", { staticClass: "y-start" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "y-start",
                                        staticStyle: { "margin-left": "85px" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "x-f timeChoose" },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                staticClass: "radioWidth",
                                                attrs: { label: 3 },
                                                model: {
                                                  value: _vm.form.timeChoose,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "timeChoose",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.timeChoose",
                                                },
                                              },
                                              [_vm._v("全天")]
                                            ),
                                            _vm.form.timeChoose == 3
                                              ? _c(
                                                  "div",
                                                  { staticClass: "x-fmarB10" },
                                                  [
                                                    _vm._v(" 下单 "),
                                                    _c("el-input", {
                                                      staticClass:
                                                        "inputW100 marL15 marR15 inputH",
                                                      attrs: {
                                                        size: "mini",
                                                        placeholder: "请输入",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.limitInputlength(
                                                            _vm.form
                                                              .afterOrderHour,
                                                            _vm.form,
                                                            "afterOrderHour",
                                                            true,
                                                            1,
                                                            false,
                                                            999
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .afterOrderHour,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "afterOrderHour",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.afterOrderHour",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "fontColor",
                                                      },
                                                      [_vm._v("小时后")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "x-f timeChoose" },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                staticClass: "radioWidth",
                                                attrs: { label: 2 },
                                                model: {
                                                  value: _vm.form.timeChoose,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "timeChoose",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.timeChoose",
                                                },
                                              },
                                              [_vm._v("营业时间段")]
                                            ),
                                            _vm.form.timeChoose == 2
                                              ? _c(
                                                  "div",
                                                  { staticClass: "x-fmarB10" },
                                                  [
                                                    _vm._v(" 下单 "),
                                                    _c("el-input", {
                                                      staticClass:
                                                        "inputW100 marL15 marR15 inputH",
                                                      attrs: {
                                                        size: "mini",
                                                        placeholder: "请输入",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.limitInputlength(
                                                            _vm.form
                                                              .afterOrderHour,
                                                            _vm.form,
                                                            "afterOrderHour",
                                                            true,
                                                            1,
                                                            false,
                                                            999
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .afterOrderHour,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "afterOrderHour",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.afterOrderHour",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "fontColor",
                                                      },
                                                      [_vm._v("小时后")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "x-f timeChoose" },
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                staticClass: "radioWidth",
                                                attrs: { label: 1 },
                                                model: {
                                                  value: _vm.form.timeChoose,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "timeChoose",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.timeChoose",
                                                },
                                              },
                                              [_vm._v("自定义时间段 ")]
                                            ),
                                            _vm.form.timeChoose == 1
                                              ? _c(
                                                  "div",
                                                  { staticClass: "x-f marB10" },
                                                  [
                                                    _vm._v(" 下单 "),
                                                    _c("el-input", {
                                                      staticClass:
                                                        "inputW100 marL15 marR15 inputH",
                                                      attrs: {
                                                        size: "mini",
                                                        placeholder: "1",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .afterOrderHour,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "afterOrderHour",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.afterOrderHour",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "fontColor",
                                                      },
                                                      [_vm._v("小时后")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm.form.timeChoose == 1
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                staticClass: "marT10",
                                                attrs: {
                                                  label: "时间段：",
                                                  "label-width": "100px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "box" },
                                                  [
                                                    _c(
                                                      "el-table",
                                                      {
                                                        staticStyle: {
                                                          width: "600px",
                                                        },
                                                        attrs: {
                                                          data: _vm.timeSlots,
                                                          border: "",
                                                        },
                                                      },
                                                      [
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            type: "index",
                                                            label: "序号",
                                                            align: "center",
                                                            width: "55",
                                                          },
                                                        }),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            label: "操作",
                                                            align: "center",
                                                            width: "150",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-circle-plus operatePush",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.row(
                                                                              "push",
                                                                              scope.$index
                                                                            )
                                                                          },
                                                                      },
                                                                    }),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-remove operateDel",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.row(
                                                                              "del",
                                                                              scope.$index
                                                                            )
                                                                          },
                                                                      },
                                                                    }),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            901523703
                                                          ),
                                                        }),
                                                        _c("el-table-column", {
                                                          attrs: {
                                                            align: "center",
                                                            prop: "address",
                                                            label: "时间段",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function (
                                                                  scope
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "el-time-picker",
                                                                      {
                                                                        attrs: {
                                                                          "is-range":
                                                                            "",
                                                                          "range-separator":
                                                                            "至",
                                                                          "value-format":
                                                                            "HH:mm:ss",
                                                                          "start-placeholder":
                                                                            "开始时间",
                                                                          "end-placeholder":
                                                                            "结束时间",
                                                                          placeholder:
                                                                            "选择时间范围",
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.timepicker(
                                                                                scope.$index
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            scope
                                                                              .row
                                                                              .times,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                scope.row,
                                                                                "times",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "scope.row.times",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            1961754162
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("comCard", {
                    attrs: { title: "运费收费标准" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "140",
                                    label: "配送距离计算:",
                                    prop: "isEnableDeliveryDistance",
                                  },
                                },
                                [
                                  _c("span", [_vm._v("配送距离计算方式启用")]),
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "marL15",
                                      attrs: { label: 1 },
                                      model: {
                                        value:
                                          _vm.form.isEnableDeliveryDistance,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isEnableDeliveryDistance",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.isEnableDeliveryDistance",
                                      },
                                    },
                                    [_vm._v("骑行距离方案 ")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "marL15",
                                      attrs: { label: 2 },
                                      model: {
                                        value:
                                          _vm.form.isEnableDeliveryDistance,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isEnableDeliveryDistance",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.isEnableDeliveryDistance",
                                      },
                                    },
                                    [_vm._v("直线距离方案 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "配送费配置：" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: { change: _vm.changeDistanceCosts },
                                      model: {
                                        value: _vm.form.distanceCostsRuleType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "distanceCostsRuleType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.distanceCostsRuleType",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("统一标准设置"),
                                      ]),
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("自定义配送费用配置"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.form.distanceCostsRuleType == 0
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "46px" } },
                                        [
                                          _c("span", [_vm._v("订单满")]),
                                          _c("el-input", {
                                            staticClass:
                                              "inputW100 marL15 marR15 inputH",
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请输入",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.limitInputlength(
                                                  _vm.form.startingAmount,
                                                  _vm.form,
                                                  "startingAmount",
                                                  true,
                                                  4,
                                                  false,
                                                  99999999
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.form.startingAmount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "startingAmount",
                                                  $$v
                                                )
                                              },
                                              expression: "form.startingAmount",
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v(
                                              "元起送，以订单实付金额为准"
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "费用配置:" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "marB10" },
                                            [
                                              _c("el-input", {
                                                staticClass:
                                                  "inputW100 marR15 inputH",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "请输入",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.limitInputlength(
                                                      _vm.form
                                                        .distanceCostsInnerKm,
                                                      _vm.form,
                                                      "distanceCostsInnerKm",
                                                      true,
                                                      4,
                                                      false,
                                                      99999999
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .distanceCostsInnerKm,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "distanceCostsInnerKm",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.distanceCostsInnerKm",
                                                },
                                              }),
                                              _vm._v(" km 内 按 "),
                                              _c("el-input", {
                                                staticClass:
                                                  "inputW100 marL15 marR15 inputH",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "请输入",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.limitInputlength(
                                                      _vm.form
                                                        .distanceInnerKmCosts,
                                                      _vm.form,
                                                      "distanceInnerKmCosts",
                                                      true,
                                                      4,
                                                      false,
                                                      99999999
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .distanceInnerKmCosts,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "distanceInnerKmCosts",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.distanceInnerKmCosts",
                                                },
                                              }),
                                              _vm._v(" 元收取配送费，每超出"),
                                              _c("el-input", {
                                                staticClass:
                                                  "inputW100 marL15 marR15 inputH",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "0",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.limitInputlength(
                                                      _vm.form
                                                        .distanceCostsOutKm,
                                                      _vm.form,
                                                      "distanceCostsOutKm",
                                                      true,
                                                      4,
                                                      false,
                                                      99999999
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.distanceCostsOutKm,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "distanceCostsOutKm",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.distanceCostsOutKm",
                                                },
                                              }),
                                              _vm._v(" km 费用增加 "),
                                              _c("el-input", {
                                                staticClass:
                                                  "inputW100 marL15 marR15 inputH",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "请输入",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.limitInputlength(
                                                      _vm.form
                                                        .distanceOutKmCosts,
                                                      _vm.form,
                                                      "distanceOutKmCosts",
                                                      true,
                                                      4,
                                                      false,
                                                      99999999
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.distanceOutKmCosts,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "distanceOutKmCosts",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.distanceOutKmCosts",
                                                },
                                              }),
                                              _vm._v(" 元。 "),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.distanceCostsRuleType == 1
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "46px" } },
                                        [
                                          _c("span", [_vm._v("订单满")]),
                                          _c("el-input", {
                                            staticClass:
                                              "inputW100 marL15 marR15 inputH",
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请输入",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.limitInputlength(
                                                  _vm.form.startingAmount,
                                                  _vm.form,
                                                  "startingAmount",
                                                  true,
                                                  4,
                                                  false,
                                                  99999999
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.form.startingAmount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "startingAmount",
                                                  $$v
                                                )
                                              },
                                              expression: "form.startingAmount",
                                            },
                                          }),
                                          _c("span", [
                                            _vm._v("元起送，0km 至"),
                                          ]),
                                          _c("el-input", {
                                            staticClass:
                                              "inputW100 marL15 marR15 inputH",
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请输入",
                                            },
                                            on: {
                                              input:
                                                _vm.inputDistanceCostsInnerKm,
                                            },
                                            model: {
                                              value:
                                                _vm.form.distanceCostsInnerKm,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "distanceCostsInnerKm",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.distanceCostsInnerKm",
                                            },
                                          }),
                                          _c("span", [_vm._v("km，配送费")]),
                                          _c("el-input", {
                                            staticClass:
                                              "inputW100 marL15 marR15 inputH",
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请输入",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.limitInputlength(
                                                  _vm.form.distanceInnerKmCosts,
                                                  _vm.form,
                                                  "distanceInnerKmCosts",
                                                  true,
                                                  4,
                                                  true,
                                                  99999999
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.form.distanceInnerKmCosts,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "distanceInnerKmCosts",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.distanceInnerKmCosts",
                                            },
                                          }),
                                          _c("span", [_vm._v("元")]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "75px" } },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "marB20",
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                              },
                                              on: { click: _vm.addDistance },
                                            },
                                            [_vm._v("添加距离（最多添加三个）")]
                                          ),
                                          _vm._l(
                                            _vm.form
                                              .deliveryIntraCityDistanceCostsRules,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "marB10",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "x-f" },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("el-input", {
                                                            staticClass:
                                                              "inputW100 marR15 inputH",
                                                            attrs: {
                                                              disabled: "",
                                                              size: "mini",
                                                              placeholder:
                                                                "请输入",
                                                            },
                                                            model: {
                                                              value:
                                                                item.distanceCostsBegKm,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "distanceCostsBegKm",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.distanceCostsBegKm",
                                                            },
                                                          }),
                                                          _c("span", [
                                                            _vm._v("至"),
                                                          ]),
                                                          _c("el-input", {
                                                            staticClass:
                                                              "inputW100 marL15 marR15 inputH",
                                                            attrs: {
                                                              size: "mini",
                                                              placeholder:
                                                                "请输入",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.inputDistanceOutKmCosts(
                                                                  $event,
                                                                  index,
                                                                  item
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item.distanceCostsEndKm,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "distanceCostsEndKm",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.distanceCostsEndKm",
                                                            },
                                                          }),
                                                          _c("span", [
                                                            _vm._v(
                                                              " km，每增加 1km 配送费"
                                                            ),
                                                          ]),
                                                          _c("el-input", {
                                                            staticClass:
                                                              "inputW100 marL15 marR15 inputH",
                                                            attrs: {
                                                              size: "mini",
                                                              placeholder:
                                                                "请输入",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.limitInputlength(
                                                                  item.distanceOutKmCosts,
                                                                  item,
                                                                  "distanceOutKmCosts",
                                                                  true,
                                                                  4,
                                                                  true,
                                                                  99999999
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item.distanceOutKmCosts,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "distanceOutKmCosts",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.distanceOutKmCosts",
                                                            },
                                                          }),
                                                          _c("span", [
                                                            _vm._v(
                                                              "元，不满1km按1km计算"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "marL15 el-icon-delete",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delDistance(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "150px",
                                    label: "免配送费条件：",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.form.buyFullRuleType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "buyFullRuleType",
                                            $$v
                                          )
                                        },
                                        expression: "form.buyFullRuleType",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("统一标准设置"),
                                      ]),
                                      _vm.form.distanceCostsRuleType == 1
                                        ? _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("分段设置")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.form.buyFullRuleType == 0
                                ? _c(
                                    "el-form-item",
                                    { attrs: { "label-width": "150px" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "marB10" },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  "若对所有配送区域都是统一的免配送费条件，则使用这里的设置",
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-question",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" 消费超过 "),
                                          _c("el-input", {
                                            staticClass:
                                              "inputW150 marL15 marR15 inputH",
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请输入",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.limitInputlength(
                                                  _vm.form.buyFullAmount,
                                                  _vm.form,
                                                  "buyFullAmount",
                                                  true,
                                                  4,
                                                  false,
                                                  99999999
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.form.buyFullAmount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "buyFullAmount",
                                                  $$v
                                                )
                                              },
                                              expression: "form.buyFullAmount",
                                            },
                                          }),
                                          _vm._v(" 元，免配送费 "),
                                          _c("el-input", {
                                            staticClass:
                                              "inputW200 marL15 marR15 inputH",
                                            attrs: {
                                              size: "mini",
                                              type: "number",
                                              placeholder:
                                                "不填写表示收取所有配送费",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.limitInputlength(
                                                  _vm.form.buyFullReduceFee,
                                                  _vm.form,
                                                  "buyFullReduceFee",
                                                  true,
                                                  4,
                                                  true,
                                                  99999999
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.form.buyFullReduceFee,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "buyFullReduceFee",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.buyFullReduceFee",
                                            },
                                          }),
                                          _vm._v(" 元 "),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.form.buyFullRuleType == 1
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "150px" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                "0km 至 " +
                                                  _vm.form
                                                    .distanceCostsInnerKm +
                                                  "km，消费超过"
                                              )
                                            ),
                                          ]),
                                          _c("el-input", {
                                            staticClass:
                                              "inputW150 marL15 marR15 inputH",
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请输入",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.limitInputlength(
                                                  _vm.form.buyFullAmount,
                                                  _vm.form,
                                                  "buyFullAmount",
                                                  true,
                                                  4,
                                                  false,
                                                  99999999
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.form.buyFullAmount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "buyFullAmount",
                                                  $$v
                                                )
                                              },
                                              expression: "form.buyFullAmount",
                                            },
                                          }),
                                          _c("span", [_vm._v("元，免配送费")]),
                                          _c("el-input", {
                                            staticClass:
                                              "inputW200 marL15 marR15 inputH",
                                            attrs: {
                                              size: "mini",
                                              type: "number",
                                              placeholder:
                                                "不填写表示收取所有配送费",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.limitInputlength(
                                                  _vm.form.buyFullReduceFee,
                                                  _vm.form,
                                                  "buyFullReduceFee",
                                                  true,
                                                  4,
                                                  true,
                                                  99999999
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.form.buyFullReduceFee,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "buyFullReduceFee",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.buyFullReduceFee",
                                            },
                                          }),
                                          _c("span", [_vm._v("元")]),
                                        ],
                                        1
                                      ),
                                      _vm.form.buyFullRuleType == 1
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              _vm.form
                                                .deliveryIntraCityDistanceCostsRules,
                                              function (item, index) {
                                                return _c(
                                                  "el-form-item",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      "label-width": "150px",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.distanceCostsBegKm +
                                                            "km 至 " +
                                                            item.distanceCostsEndKm +
                                                            "km，消费超过"
                                                        )
                                                      ),
                                                    ]),
                                                    _c("el-input", {
                                                      staticClass:
                                                        "inputW150 marL15 marR15 inputH",
                                                      attrs: {
                                                        size: "mini",
                                                        placeholder: "请输入",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.limitInputlength(
                                                            item.buyFullAmount,
                                                            item,
                                                            "buyFullAmount",
                                                            true,
                                                            4,
                                                            false,
                                                            99999999
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          item.buyFullAmount,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "buyFullAmount",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.buyFullAmount",
                                                      },
                                                    }),
                                                    _c("span", [
                                                      _vm._v("元，免配送费"),
                                                    ]),
                                                    _c("el-input", {
                                                      staticClass:
                                                        "inputW200 marL15 marR15 inputH",
                                                      attrs: {
                                                        size: "mini",
                                                        type: "number",
                                                        placeholder:
                                                          "不填写表示收取所有配送费",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.limitInputlength(
                                                            item.buyFullReduceFee,
                                                            item,
                                                            "buyFullReduceFee",
                                                            true,
                                                            4,
                                                            true,
                                                            99999999
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          item.buyFullReduceFee,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "buyFullReduceFee",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.buyFullReduceFee",
                                                      },
                                                    }),
                                                    _c("span", [_vm._v("元")]),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1782722810
                    ),
                  }),
                  _c("comCard", {
                    attrs: { title: "自动呼叫配置" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _c(
                                "el-form-item",
                                { attrs: { label: "是否自动呼叫" } },
                                [
                                  _c("el-switch", {
                                    model: {
                                      value: _vm.form.isAutoCall,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "isAutoCall", $$v)
                                      },
                                      expression: "form.isAutoCall",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.form.isAutoCall
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "优先呼叫平台",
                                            prop: "autoCallFirstDeliveryAppType",
                                            "label-width": "110px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value:
                                                  _vm.form
                                                    .autoCallFirstDeliveryAppType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "autoCallFirstDeliveryAppType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.autoCallFirstDeliveryAppType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.cityExpress,
                                              function (item, index) {
                                                return _c(
                                                  "el-radio",
                                                  {
                                                    key: index,
                                                    attrs: { label: item.type },
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "备选呼叫平台",
                                            prop: "autoCallSpareDeliveryAppType",
                                            "label-width": "110px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.onRadioChange(
                                                    $event,
                                                    "autoCallSpareDeliveryAppType"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .autoCallSpareDeliveryAppType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "autoCallSpareDeliveryAppType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.autoCallSpareDeliveryAppType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.cityExpress,
                                              function (item, index) {
                                                return _c(
                                                  "el-radio",
                                                  {
                                                    key: index,
                                                    attrs: { label: item.type },
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "呼叫时间",
                                            prop: "callTimeType",
                                            "label-width": "110px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value: _vm.form.callTimeType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "callTimeType",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.callTimeType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.callTimeType,
                                              function (item, index) {
                                                return _c(
                                                  "el-radio",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      label: item.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.label))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.form.callTimeType == 1
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "接单成功后",
                                                "label-width": "140px",
                                                prop: "callTimeRecOrderAfter",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass:
                                                  "inputW100 marL15 marR15 inputH",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "请输入",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.limitInputlength(
                                                      _vm.form
                                                        .callTimeRecOrderAfter,
                                                      _vm.form,
                                                      "callTimeRecOrderAfter",
                                                      true,
                                                      0,
                                                      false,
                                                      99999999
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .callTimeRecOrderAfter,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "callTimeRecOrderAfter",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.callTimeRecOrderAfter",
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v("分钟后呼叫"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.form.callTimeType == 4
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "取货成功后",
                                                "label-width": "140px",
                                                prop: "callTimeOutOrderAfter",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass:
                                                  "inputW100 marL15 marR15 inputH",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "请输入",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.limitInputlength(
                                                      _vm.form
                                                        .callTimeOutOrderAfter,
                                                      _vm.form,
                                                      "callTimeOutOrderAfter",
                                                      true,
                                                      0,
                                                      false,
                                                      99999999
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .callTimeOutOrderAfter,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "callTimeOutOrderAfter",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.callTimeOutOrderAfter",
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v("分钟后呼叫"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.form.callTimeType == 2
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "预订送达开始时间，提前",
                                                "label-width": "220px",
                                                prop: "callTimeFetchBegBefore",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass:
                                                  "inputW100 marL15 marR15 inputH",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "请输入",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.limitInputlength(
                                                      _vm.form
                                                        .callTimeFetchBegBefore,
                                                      _vm.form,
                                                      "callTimeFetchBegBefore",
                                                      true,
                                                      0,
                                                      false,
                                                      99999999
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .callTimeFetchBegBefore,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "callTimeFetchBegBefore",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.callTimeFetchBegBefore",
                                                },
                                              }),
                                              _c("span", [_vm._v("分钟呼叫")]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.form.callTimeType == 3
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "预订送达结束时间，提前",
                                                "label-width": "220px",
                                                prop: "callTimeFetchEndBefore",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass:
                                                  "inputW100 marL15 marR15 inputH",
                                                attrs: {
                                                  size: "mini",
                                                  placeholder: "请输入",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.limitInputlength(
                                                      _vm.form
                                                        .callTimeFetchEndBefore,
                                                      _vm.form,
                                                      "callTimeFetchEndBefore",
                                                      true,
                                                      0,
                                                      false,
                                                      99999999
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .callTimeFetchEndBefore,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "callTimeFetchEndBefore",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.callTimeFetchEndBefore",
                                                },
                                              }),
                                              _c("span", [_vm._v("分钟呼叫")]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1157540561
                    ),
                  }),
                  _c(
                    "el-form-item",
                    { staticClass: "x-start" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("c-dialog", {
        attrs: {
          title: "申请达达账户",
          width: 600,
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "y-start" }, [
                  _c("span", { staticClass: "marB10" }, [
                    _vm._v(
                      "1、服务方是达达，订单自动传到达达骑手app,由达达骑手派送，具体收费标准，以您和达达协商为准，配送费都将从你注册的达达账户里进行扣减，充值请登录达达后台"
                    ),
                  ]),
                  _c("span", { staticClass: "marB10" }, [
                    _vm._v(
                      "2、通过此链接注册生成的达达账户初始密码会以短信形式发生给注册手机号"
                    ),
                  ]),
                  _c("span", { staticClass: "marB10" }, [
                    _vm._v("3、连锁门店只需注册一个达达账号，切勿重复注册！"),
                  ]),
                ]),
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.dialogLoading,
                        expression: "dialogLoading",
                      },
                    ],
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.formDistribution,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手机号码", prop: "mobile" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            maxlength: "11",
                            autocomplete: "off",
                            placeholder: "请输入手机号码",
                          },
                          model: {
                            value: _vm.formDistribution.mobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.formDistribution, "mobile", $$v)
                            },
                            expression: "formDistribution.mobile",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "企业全称", prop: "enterpriseName" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            maxlength: "80",
                            autocomplete: "off",
                            placeholder: "请输入企业全称",
                          },
                          model: {
                            value: _vm.formDistribution.enterpriseName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formDistribution,
                                "enterpriseName",
                                $$v
                              )
                            },
                            expression: "formDistribution.enterpriseName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "企业地址", prop: "enterpriseName" } },
                      [
                        _c(
                          "div",
                          { staticClass: "x-x" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择城市",
                                  filterable: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.formDistribution.cityName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formDistribution,
                                      "cityName",
                                      $$v
                                    )
                                  },
                                  expression: "formDistribution.cityName",
                                },
                              },
                              _vm._l(_vm.cityList, function (item) {
                                return _c("el-option", {
                                  key: item.cityCode,
                                  attrs: {
                                    label: item.cityName,
                                    value: item.cityName,
                                  },
                                })
                              }),
                              1
                            ),
                            _c("el-input", {
                              staticClass: "inputWidth marL10",
                              attrs: {
                                maxlength: "80",
                                autocomplete: "off",
                                placeholder: "请输入具体地址",
                              },
                              model: {
                                value: _vm.formDistribution.enterpriseAddress,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formDistribution,
                                    "enterpriseAddress",
                                    $$v
                                  )
                                },
                                expression:
                                  "formDistribution.enterpriseAddress",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "姓名", prop: "contactName" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            maxlength: "80",
                            autocomplete: "off",
                            placeholder: "请输入姓名",
                          },
                          model: {
                            value: _vm.formDistribution.contactName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formDistribution, "contactName", $$v)
                            },
                            expression: "formDistribution.contactName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系电话", prop: "contactPhone" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            maxlength: "80",
                            autocomplete: "off",
                            placeholder: "请输入姓名",
                          },
                          model: {
                            value: _vm.formDistribution.contactPhone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formDistribution,
                                "contactPhone",
                                $$v
                              )
                            },
                            expression: "formDistribution.contactPhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "邮箱地址" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            maxlength: "80",
                            autocomplete: "off",
                            placeholder: "请输入邮箱地址",
                          },
                          model: {
                            value: _vm.formDistribution.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.formDistribution, "email", $$v)
                            },
                            expression: "formDistribution.email",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.submitDistribution },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: {
          title: "绑定门店",
          width: 700,
          showDialog: _vm.dialogFormTable,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormTable = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormTable = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.dialogLoading,
                        expression: "dialogLoading",
                      },
                    ],
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.formDistribution,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingTable,
                            expression: "loadingTable",
                          },
                        ],
                        ref: "multipleTable",
                        attrs: {
                          data: _vm.uuData,
                          border: "",
                          "max-height": "320",
                          height: "300",
                          "row-key": "",
                        },
                        on: {
                          "cell-mouse-enter": _vm.cellMouseEnter,
                          "cell-mouse-leave": _vm.cellMouseLeave,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.tableCellLabel,
                            width: "80",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.rowChange(
                                              "push",
                                              scope.$index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.rowChange(
                                              "del",
                                              scope.$index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  !scope.row.hoverRow
                                    ? _c("div", [
                                        _vm._v(_vm._s(scope.$index + 1)),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm.isActive == 2 || _vm.isActive == 4
                          ? _c("el-table-column", {
                              attrs: {
                                label: "门店",
                                align: "center",
                                prop: "shopId",
                                "min-width": "160",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          { attrs: { "label-width": "0" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  clearable: "",
                                                  placeholder: "请选择",
                                                  filterable: "",
                                                  size: "mini",
                                                },
                                                model: {
                                                  value: scope.row.shopId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "shopId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.shopId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.shopListFil,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.shopId,
                                                    attrs: {
                                                      value: item.shopId,
                                                      label: item.shopName,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2242480477
                              ),
                            })
                          : _vm._e(),
                        _vm.isActive == 2 || _vm.isActive == 4
                          ? _c("el-table-column", {
                              attrs: {
                                label:
                                  _vm.isActive == 2 ? "uu门店" : "顺丰门店",
                                align: "center",
                                prop: "thirdShopId",
                                "min-width": "160",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          { attrs: { "label-width": "0" } },
                                          [
                                            _vm.isActive == 2
                                              ? _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder: "请选择",
                                                      filterable: "",
                                                      size: "mini",
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.thirdShopId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "thirdShopId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.thirdShopId",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.uuShopData,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.thirdShopId,
                                                        attrs: {
                                                          value:
                                                            item.thirdShopId,
                                                          label: item.shopName,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.isActive == 4
                                              ? _c("el-input", {
                                                  attrs: { size: "mini" },
                                                  model: {
                                                    value:
                                                      scope.row.thirdShopId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "thirdShopId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.thirdShopId",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3610678290
                              ),
                            })
                          : _vm._e(),
                        _vm.isActive == 7
                          ? _c("el-table-column", {
                              attrs: {
                                label: "是否绑定",
                                align: "center",
                                prop: "type",
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          { attrs: { "label-width": "0" } },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                size: "mini",
                                                "active-value": "bind",
                                                "inactive-value": "unbind",
                                              },
                                              model: {
                                                value: scope.row.type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.type",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2099614516
                              ),
                            })
                          : _vm._e(),
                        _vm.isActive == 7
                          ? _c("el-table-column", {
                              attrs: {
                                label: "bizId",
                                align: "center",
                                prop: "bizId",
                                "min-width": "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          { attrs: { "label-width": "0" } },
                                          [
                                            _c("el-input", {
                                              attrs: { size: "mini" },
                                              model: {
                                                value: scope.row.bizId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "bizId",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.bizId",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3858909384
                              ),
                            })
                          : _vm._e(),
                        _vm.isActive == 7
                          ? _c("el-table-column", {
                              key: "6",
                              attrs: {
                                label: "快递公司",
                                align: "center",
                                prop: "deliveryId",
                                "min-width": "120",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          { attrs: { "label-width": "0" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  clearable: "",
                                                  placeholder: "请选择",
                                                  filterable: "",
                                                  size: "mini",
                                                },
                                                model: {
                                                  value: scope.row.deliveryId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "deliveryId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.deliveryId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.uuShopData,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.deliveryId,
                                                    attrs: {
                                                      value: item.deliveryId,
                                                      label: item.deliveryName,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                826451148
                              ),
                            })
                          : _vm._e(),
                        _vm.isActive == 7
                          ? _c("el-table-column", {
                              key: "7",
                              attrs: {
                                label: "快递公司客户密码",
                                align: "center",
                                prop: "password",
                                "min-width": "140",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          { attrs: { "label-width": "0" } },
                                          [
                                            _c("el-input", {
                                              attrs: { size: "mini" },
                                              model: {
                                                value: scope.row.password,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "password",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.password",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3576621707
                              ),
                            })
                          : _vm._e(),
                        _vm.isActive == 7
                          ? _c("el-table-column", {
                              key: "8",
                              attrs: {
                                label: "备注内容",
                                align: "center",
                                prop: "remarkContent",
                                "min-width": "260",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-form-item",
                                          { attrs: { "label-width": "0" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder:
                                                  "格式要求：电话：xxxxx 联系人：xxxxx 服务类型：xxxxx 发货地址：xxxx\\n",
                                                size: "mini",
                                              },
                                              model: {
                                                value: scope.row.remarkContent,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "remarkContent",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.remarkContent",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                631414801
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.shopCancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.shopSubmitDistribution },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }