var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "bg-Color marB10 x-f-end padd15" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.configSave },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c("cardTitleCom", {
        attrs: { cardTitle: "商城浏览模式设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15 y-start" },
                  [
                    _c("span", { staticClass: "marB10" }, [
                      _vm._v(
                        "客户无需先登录，就能进入商城浏览商品信息，请勾选"
                      ),
                    ]),
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.form.mall_SYSTEM_CONFIG_MALL_BROWSE_21,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "mall_SYSTEM_CONFIG_MALL_BROWSE_21",
                              $$v
                            )
                          },
                          expression: "form.mall_SYSTEM_CONFIG_MALL_BROWSE_21",
                        },
                      },
                      [_vm._v("客户未登录可先浏览商城")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "商城商品单价及金额的取值方法" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15" },
                  [
                    _c(
                      "el-form",
                      { attrs: { model: _vm.form } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "单价舍入方式" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { size: "small", placeholder: "请选择" },
                                model: {
                                  value:
                                    _vm.form
                                      .mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_31,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_31",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_31",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.round_off_type,
                                function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "x-bc" }, [
                                        _c("span", {}, [
                                          _vm._v(_vm._s(item.label)),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#8492a6",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.labelTOW))]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "金额舍入方式" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { size: "small", placeholder: "请选择" },
                                model: {
                                  value:
                                    _vm.form
                                      .mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_32,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_32",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_32",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.round_off_type,
                                function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "x-bc" }, [
                                        _c("span", {}, [
                                          _vm._v(_vm._s(item.label)),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#8492a6",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.labelTOW))]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "收款舍入方式" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { size: "small", placeholder: "请选择" },
                                model: {
                                  value:
                                    _vm.form
                                      .mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_33,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_33",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.mall_SYSTEM_CONFIG_MALL_GOODS_MONEY_33",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.round_off_type,
                                function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "x-bc" }, [
                                        _c("span", {}, [
                                          _vm._v(_vm._s(item.label)),
                                        ]),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#8492a6",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.labelTOW))]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "库存模糊展示" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15" },
                  [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          border: "",
                          data: _vm.form.mall_SYSTEM_CONFIG_MALL_STOCK_VAGUE_41,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "center",
                            width: "180",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus operatePush",
                                    on: {
                                      click: function ($event) {
                                        return _vm.row("push", scope.$index)
                                      },
                                    },
                                  }),
                                  _c("i", {
                                    staticClass: "el-icon-remove operateDel",
                                    on: {
                                      click: function ($event) {
                                        return _vm.row("del", scope.$index)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "数量（从）",
                            align: "center",
                            width: "180",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: scope.row.beginRange,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "beginRange", $$v)
                                      },
                                      expression: "scope.row.beginRange",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "数量（到）",
                            align: "center",
                            width: "180",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: scope.row.endRange,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "endRange", $$v)
                                      },
                                      expression: "scope.row.endRange",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "显示",
                            align: "center",
                            width: "180",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: scope.row.display,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "display", $$v)
                                      },
                                      expression: "scope.row.display",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "是否展示库存数量",
                            align: "center",
                            width: "180",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-switch", {
                                    staticClass: "marL10",
                                    model: {
                                      value: scope.row.isDisplayStock,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "isDisplayStock",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.isDisplayStock",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", { attrs: { label: "" } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "收货地址设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "padd15 y-start" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_61,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_61",
                              $$v
                            )
                          },
                          expression:
                            "form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_61",
                        },
                      },
                      [_vm._v("详细地址不允许手工输入")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("cardTitleCom", {
        attrs: { cardTitle: "库存展示设置" },
        scopedSlots: _vm._u([
          {
            key: "cardContent",
            fn: function () {
              return [
                _c("div", { staticClass: "padd15" }, [
                  _c("div", { staticClass: "marT10" }, [
                    _c(
                      "div",
                      { staticClass: "y-start marB10" },
                      [
                        _c("span", { staticClass: "marB10  fontColor" }, [
                          _vm._v(
                            "若无需给下游订货客户展示商品具体库存，请勾选"
                          ),
                        ]),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: true },
                            model: {
                              value:
                                _vm.form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_51,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_51",
                                  $$v
                                )
                              },
                              expression:
                                "form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_51",
                            },
                          },
                          [_vm._v("商城不展示库存")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "y-start marB10" }, [
                      _c("span", { staticClass: "marB10 fontColor" }, [
                        _vm._v(
                          "若需提供下游订货客户近一时期商品的大致可售卖库存量，请勾选"
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: true },
                              model: {
                                value:
                                  _vm.form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_52
                                    .switchConfig,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form
                                      .mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_52,
                                    "switchConfig",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_52.switchConfig",
                              },
                            },
                            [_vm._v("展示虚拟库存，虚拟库存扣减方式")]
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value:
                                  _vm.form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_52
                                    .valueConfig,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form
                                      .mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_52,
                                    "valueConfig",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_52.valueConfig",
                              },
                            },
                            _vm._l(_vm.options1, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "y-start marB10" }, [
                      _c("span", { staticClass: "marB10 fontColor" }, [
                        _vm._v(
                          "若需提供下游订货客户在进销存管理中的商品可用库存量，请勾选"
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: true },
                              model: {
                                value:
                                  _vm.form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_53
                                    .switchConfig,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form
                                      .mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_53,
                                    "switchConfig",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_53.switchConfig",
                              },
                            },
                            [_vm._v("展示基于进销存的商城可用库存")]
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value:
                                  _vm.form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_53
                                    .valueConfig,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form
                                      .mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_53,
                                    "valueConfig",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_53.valueConfig",
                              },
                            },
                            _vm._l(_vm.options2, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "y-start marB10" },
                      [
                        _c("span", { staticClass: "marB10 fontColor" }, [
                          _vm._v(
                            "计算方式:商城可用库存=进销存预计可用库存-商城未审核订单占用"
                          ),
                        ]),
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value:
                                _vm.form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_54,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_54",
                                  $$v
                                )
                              },
                              expression:
                                "form.mall_SYSTEM_CONFIG_MALL_STOCK_SHOW_54",
                            },
                          },
                          [
                            _vm._v(
                              " 商城订单下推成已审核的销售订单时，是否将商品库存锁库，以便精准管理库存 "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }