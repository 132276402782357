<!-- 期初应付款 -->
<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";

import {
  updateArbillStatus,
  listInitialDataPartnerAp,
} from "@/api/system/initialData/ar";
import { fcount } from "@/utils";

export default {
  components: { TablePage },
  dicts: ["bill_status"],
  data() {
    return {
      //弹窗配置
      dialogOptions: {
        title: "选择",
        width: 1100,
        show: false,
        type: "TreeAndTable",
      },
      options: {
        mutiSelect: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        getListApi: listInitialDataPartnerAp,
        body: {},
        listNo: true,
        title: "单据信息",
        check: [],
        rowKey: "billId",
        uuid: "2c7962f4-ac33-9501-f3eb-90369b1945e7",
        search: [
          {
            type: "filters",
            tip:
              "全部/单据编号/供应商编号/供应商名称/部门编号/部门名称/采购员编号/采购员名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
              { filter: "partnerNos", label: "供应商编号" },
              { filter: "partnerNames", label: "供应商名称" },
              { filter: "deptNos", label: "部门编号" },
              { filter: "deptNames", label: "部门名称" },
              { filter: "purEmployeeNos", label: "采购员编号" },
              { filter: "purEmployeeNames", label: "采购员名称" },
            ],
          },
          {
            label: "业务日期",
            type: "datetimerange",
            model: "",
            seniorSearch: true,
            filterFnc: ([a, b] = []) => ({ beginBizDate: a, endBizDate: b }),
          },
          {
            label: "结算日期",
            type: "datetimerange",
            model: "",
            seniorSearch: true,
            filterFnc: ([a, b] = []) => ({ beginSettleDate: a, endSettleDate: b }),
          },
          this.$select({
            key: "listSupplier",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSimpleQuery",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listPurEmployee",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          // this.$select({ key: "dataBillStatus",option: { seniorSearch: true, label: '审核状态', option: {dataKey: (e) => {
          //   let data = e.data.filter(item => ['未审核', '已审核'].includes(item.dictLabel))
          //   return data
          // },multiple: true }}}),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            btnType: "dropdown",
            other: [
              {
                click: "redoAudit",
                label: "反审核",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "partnerNo",
            label: "供应商编号",
            formatter: (v, row) =>
              row.partnerInfo?.partnerNo ? row.partnerInfo?.partnerNo : "",
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "供应商名称",
            formatter: (v, row) =>
              row.partnerInfo?.partnerName ? row.partnerInfo?.partnerName : "",
            minWidth: 120,
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 120,
          },
          {
            prop: "apAmount",
            label: "应付金额",
            minWidth: 140,
          },
          {
            prop: "preApAmount",
            label: "预付金额",
            minWidth: 140,
          },
          {
            prop: "apWriteOffMoney",
            label: "已核销应付金额",
            minWidth: 140,
          },
          {
            prop: "noAmount",
            label: "未核销应付金额",
            minWidth: 140,
            formatter: (v, row) => (row.apAmount || row.apWriteOffMoney)? fcount([row.apAmount, row.apWriteOffMoney],'-') : '',
          },
          {
            prop: "balance",
            label: "期初余额",
            minWidth: 140,
            formatter: (v, row) =>
              row.apAmount || row.preApAmount
                ? fcount([row.apAmount, row.preApAmount], "-")
                : "",
          },
          {
            prop: "bizDate",
            label: "业务日期",
            minWidth: 140,
          },
          {
            prop: "settleDate",
            label: "结算日期",
            minWidth: 140,
          },
          {
            prop: "deptNo",
            label: "部门编号",
            minWidth: 140,
            formatter: (v, row) => (row.deptInfo?.deptNo ? row.deptInfo?.deptNo : ""),
          },
          {
            prop: "deptName",
            label: "部门名称",
            minWidth: 140,
            formatter: (v, row) => (row.deptInfo?.deptName ? row.deptInfo?.deptName : ""),
          },
          {
            prop: "employeeNo",
            label: "采购员编号",
            minWidth: 140,
            formatter: (v, row) =>
              row.purEmployeeInfo?.employeeNo ? row.purEmployeeInfo?.employeeNo : "",
          },
          {
            prop: "employeeName",
            label: "采购员名称",
            minWidth: 140,
            formatter: (v, row) =>
              row.purEmployeeInfo?.employeeName ? row.purEmployeeInfo?.employeeName : "",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      let url = "/api/system/finance/initial/partner/ap/modifyBillStatus";
      let billType = "100202";
      switch (type) {
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: "apDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push({
              name: "apDetail",
              query: {
                type: "Add",
              },
            });
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("审核", selectData, url, billType);
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("反审核", selectData, url, billType);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData, url, billType);
          }
          break;
        case "stop":
          {
            if (!selectData.length) return;
            this.handleBillStatus("关闭", selectData, url, billType);
          }
          break;
        default:
          break;
      }
    },
    async handleBillStatus(command, selectData, url, BillType) {
      let name = "";
      let billStatus = "";
      if (command === "反审核") {
        name = "反审核";
        billStatus = "0";
      } else {
        if (command === "审核") {
          name = "审核";
          billStatus = "2";
        } else if (command === "删除") {
          name = "删除";
          billStatus = "3";
        }
      }
      const billId = selectData.map((i) => i.billId);
      const $this = this;
      let operate = await this.$modal.confirm(`是否确认${name}`);
      let params = {
        billIds: [...billId],
        billStatus: billStatus,
        url: url,
        BillType: BillType,
      };
      updateArbillStatus(params).then(() => {
        $this.$nextTick(() => {
          $this.options.check = [];
        });
        $this.$modal.msgSuccess(`${name}成功`);
        setTimeout(() => {
          $this.$refs.tablePage.getList();
        }, 500);
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
