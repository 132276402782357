<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  paymentDetailsList,
  paymentDetailsListSummary,
  shopPaymentShopCashierList,
  shopPaymentShopCashierListSummary,
  shopPaymentList,
  shopPaymentListSummary,
  paymentList,
  paymentListSummary,
  shopPaymentCashierList,
  shopPaymentCashierListSummary,
  shopPaymentDateList,
  shopPaymentDateListSummary,
  paymentGoodsDetailsList,
  paymentGoodsDetailsListSummary,
  shopPaymentShopList,
  shopPaymentShopListSummary,
  dateShopPaymentShopList,
  dateShopPaymentShopListSummary
} from "@/api/shop/analyse/posGather";
export default {
  name: "PosGather",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "门店收款分析",
        listNo: true, // 序号
        //搜索
        search: [
          {
            label: "收款时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "门店编号/门店名称/小票号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "shopNos", label: "门店编号" },
              { filter: "shopNames", label: "门店名称" },
              { filter: "billNos", label: "小票号" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "payDetail" ||
                this.options?.tabColumnType == "payShopPayTpesCashier" ||
                this.options?.tabColumnType == "payShop" ||
                this.options?.tabColumnType == "payDetailGood" ||
                this.options?.tabColumnType == "payShopPayTpes" ||
                this.options?.tabColumnType == "payShopPayTpesPayDate",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择门店",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "shop" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listPayClass",
            option: {
              filter: "payModeIds",
              label: "收款方式",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "payDetail" ||
                this.options?.tabColumnType == "payShopPayTpesCashier" ||
                this.options?.tabColumnType == "payPayTpes" ||
                this.options?.tabColumnType == "payPayTpesCashier" ||
                this.options?.tabColumnType == "payDetailGood" ||
                this.options?.tabColumnType == "payShopPayTpes"||
                this.options?.tabColumnType == "payShopPayTpesPayDate",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收款方式",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "paymode" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCashier",
            option: {
              filter: "cashierIds",
              label: "收银员",
              seniorSearch: true,
              hide: () =>
                this.options?.tabColumnType == "payDetail" ||
                this.options?.tabColumnType == "payShopPayTpesCashier" ||
                this.options?.tabColumnType == "payPayTpesCashier",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择收银员",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "cashier" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              label: "商品",
              seniorSearch: true,
              hide: () => this.options?.tabColumnType == "payDetailGood",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            label: "是否营收",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "isRevenue",
            option: {
              clearable: true,
              data: [
                { label: "是", value: true },
                { label: "否", value: false },
              ],
              label: "label",
              value: "value",
              filterable: true,
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "payDetail",
        tabsColumns: [
          {
            title: "收款汇总明细分析",
            type: "payDetail",
            getListApi: paymentDetailsList,
            getSummaryApi: paymentDetailsListSummary,
            uuid: "695bb5ce-5c9a-67f4-7f36-2dd1606bfc71",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/pay/analysis/paymentDetailsListExport",
              exportName: "收款汇总明细分析",
            },
            //表格
            columns: [
              {
                prop: "billDate",
                label: "销售日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "billNo",
                label: "小票号",
                minWidth: 180,
                align: "center",
                type: "link",
                click: "update",
              },
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "payType",
                label: "收款类型",
                minWidth: 120,
              },
              {
                prop: "billFactMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payTypse",
                align: "center",
                label: "收款方式",
                children: () =>
                  this.options.list?.[0]?.payTypes?.map?.((x) => ({
                    ...x,
                    label: x.payModeName,
                    prop: `payDetailList.${x.payModeId}.payModeId.payMoney`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.payDetailList?.find((y) => y.payModeId === x.payModeId)
                        ?.payMoney || 0,
                  })) || [],
              },
            ],
            summary: ["billFactMoney"],
          },
          {
            title: "收款商品明细分析",
            type: "payDetailGood",
            getListApi: paymentGoodsDetailsList,
            getSummaryApi: paymentGoodsDetailsListSummary,
            uuid: "ea4033e6-8b9c-d7bf-5cf1-a4549512b8cd",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/pay/analysis/paymentGoodsDetailsListExport",
              exportName: "收款商品明细分析",
            },
            //表格
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "payModeName",
                label: "收款方式",
                minWidth: 120,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "unitName",
                label: "单位",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "saleQty",
                label: "销售数量",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "saleMoney",
                label: "销售金额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["saleQty", "saleMoney"],
          },
          {
            title: "收款汇总查询(按门店)",
            type: "payShop",
            getListApi: shopPaymentList,
            getSummaryApi: shopPaymentListSummary,
            uuid: "5b701063-b02b-ea3d-4476-7334ea1cd79e",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/pay/analysis/shopPaymentListExport",
              exportName: "收款汇总查询(按门店)",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "销售日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "payCount",
                label: "收款笔数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["payCount", "payMoney"],
          },
          {
            title: "收款汇总查询(按收款方式)",
            type: "payPayTpes",
            getListApi: paymentList,
            getSummaryApi: paymentListSummary,
            uuid: "8450c328-37c1-e971-9e94-3c24918963ed",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl: "/api/system/shop/report/pay/analysis/paymentListExport",
              exportName: "收款汇总查询(按收款方式)",
            },
            //表格
            columns: [
              {
                prop: "payModeName",
                label: "收款方式",
                minWidth: 120,
              },
              {
                prop: "payCount",
                label: "收款笔数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["payCount", "payMoney"],
          },
          {
            title: "收款汇总查询(按收款方式+收银员)",
            type: "payPayTpesCashier",
            getListApi: shopPaymentCashierList,
            getSummaryApi: shopPaymentCashierListSummary,
            uuid: "1a87c093-8811-7c04-10a1-5f256e30d8fa",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/pay/analysis/shopPaymentCashierListExport",
              exportName: "收款汇总查询(按收款方式+收银员)",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "销售日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "payModeName",
                label: "收款方式",
                minWidth: 120,
              },
              {
                prop: "payCount",
                label: "收款笔数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["payCount", "payMoney"],
          },
          {
            title: "收款汇总查询(按门店+收款方式)",
            type: "payShopPayTpes",
            getListApi: shopPaymentShopList,
            getSummaryApi: shopPaymentShopListSummary,
            uuid: "19c214c4-f8b1-6b88-bf55-4f48eae8cde6",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/pay/analysis/shopPaymentShopListExport",
              exportName: "收款汇总查询(按门店+收款方式)",
            },
            //表格
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "payModeName",
                label: "收款方式",
                minWidth: 120,
              },
              {
                prop: "payCount",
                label: "收款笔数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["payCount", "payMoney"],
          },
          {
            title: "收款汇总查询(按门店+收款方式+收银员)",
            type: "payShopPayTpesCashier",
            getListApi: shopPaymentShopCashierList,
            getSummaryApi: shopPaymentShopCashierListSummary,
            uuid: "19c214c4-f8b1-6b88-bf55-4f48eae8cde6",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/pay/analysis/shopPaymentShopCashierListExport",
              exportName: "收款汇总查询(按门店+收款方式+收银员)",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "销售日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "payModeName",
                label: "收款方式",
                minWidth: 120,
              },
              {
                prop: "payCount",
                label: "收款笔数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["payCount", "payMoney"],
          },
          {
            title: "收款汇总查询(按日期)",
            type: "payDate",
            getListApi: shopPaymentDateList,
            getSummaryApi: shopPaymentDateListSummary,
            uuid: "9ae17179-03ef-14c1-1995-9e6ffaf6bfd0",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/pay/analysis/shopPaymentDateListExport",
              exportName: "收款汇总查询(按日期)",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "销售日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "payCount",
                label: "收款笔数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["payCount", "payMoney"],
          },
          {
            title: "收款汇总查询(按日期+门店+收款方式)",
            type: "payShopPayTpesPayDate",
            getListApi: dateShopPaymentShopList,
            getSummaryApi: dateShopPaymentShopListSummary,
            uuid: "19c214c4-f8b1-6b88-bf55-4f48eae8cde6",
            // defaultBody: { rankingType: 1 },
            exportOption: {
              fastExportUrl:
                "/api/system/shop/report/pay/analysis/dateShopPaymentShopListExport",
              exportName: "收款汇总查询(按日期+门店+收款方式)",
            },
            //表格
            columns: [
              {
                prop: "billDateStr",
                label: "销售日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "payModeName",
                label: "收款方式",
                minWidth: 120,
              },
              {
                prop: "payCount",
                label: "收款笔数",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payMoney",
                label: "收款金额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["payCount", "payMoney"],
          }
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "update":
          if (row?.payTypeId != 1 && row?.payTypeId != 4) {
            this.$router.push({
              name: "saleTotalDetail",
              query: {
                billNo: row.billNo,
                type: "Update",
              },
            });
          } else {
            this.$router.push({
              name: "saleTotalDetail",
              query: {
                billId: row.billId,
                type: "Update",
              },
            });
          }

          break;
        default:
          break;
      }
    },
  },
};
</script>
