var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "phoneBox",
      staticStyle: { "overflow-y": "auto" },
      attrs: { id: "phoneBox" },
    },
    [
      _c(
        "div",
        {
          staticClass: "phone",
          staticStyle: { position: "relative" },
          style: {
            minHeight: _vm.scrollContainer + "px",
            backgroundColor: _vm.isImmersiveTop
              ? _vm.pageDataObj.bgColor || "#f5f5f5"
              : "#f5f5f5",
            backgroundImage:
              _vm.isImmersiveTop && _vm.pageDataObj.bgUrl
                ? "url(" + _vm.pageDataObj.bgUrl + ")"
                : "",
          },
          attrs: { id: "preview" },
        },
        [
          _c(
            "div",
            {
              staticClass: "phoneStartBox",
              class: { phoneStartBox2: _vm.isImmersiveTop },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.pageSetup($event)
                },
              },
            },
            [
              _c("el-image", {
                staticStyle: { width: "100%", height: "100%" },
                attrs: { src: _vm.topUrl },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "navBar x-f",
              class: { navBar2: _vm.isImmersiveTop },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.pageSetup($event)
                },
              },
            },
            [
              _c("i", {
                staticClass: "x-f-start",
                staticStyle: { "font-size": "25px", width: "90px" },
              }),
              _c("span", { staticClass: "title" }, [
                _vm._v(
                  _vm._s(
                    !_vm.isImmersiveTop
                      ? _vm.pageDataObj.title || _vm.headline
                      : ""
                  )
                ),
              ]),
              _c("el-image", {
                staticClass: "capsule x-f-end",
                attrs: { src: _vm.topUrl2 },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "main",
              style: {
                backgroundColor: !_vm.isImmersiveTop
                  ? _vm.pageDataObj.bgColor || ""
                  : "",
                backgroundImage:
                  !_vm.isImmersiveTop && _vm.pageDataObj.bgUrl
                    ? "url(" + _vm.pageDataObj.bgUrl + ")"
                    : "",
              },
              attrs: { id: "main" },
            },
            [
              _c(
                "draggable",
                {
                  attrs: {
                    list: _vm.$store.state.template.tempData,
                    move: _vm.draggableOnMove,
                    filter: ".unmover",
                  },
                },
                [
                  _vm._l(
                    _vm.$store.state.template.tempData,
                    function (item, index) {
                      return [
                        item && item.name
                          ? _c(
                              "div",
                              {
                                key: index + "." + item.name,
                                staticClass: "item",
                                class: {
                                  unmover:
                                    item.name === "dibudaohang" ||
                                    item.name === "navigation",
                                  tipsBox: item.name === "tanchuang",
                                  kefuBox: item.name === "kefu",
                                  sosuoBox:
                                    item.name === "sousuo" &&
                                    _vm.$store.state.template.tempData.findIndex(
                                      function (e) {
                                        return e && e.name == "sousuo"
                                      }
                                    ) != "-1"
                                      ? _vm.$store.state.template.tempData[
                                          _vm.$store.state.template.tempData.findIndex(
                                            function (e) {
                                              return e && e.name == "sousuo"
                                            }
                                          )
                                        ].style.isFixed
                                      : false,
                                },
                                style: {
                                  border:
                                    _vm.$store.state.template.selectIndex ==
                                    index
                                      ? "1px solid #63a1ff"
                                      : "",
                                  zIndex: item.name == "immersive" ? 0 : "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.tempClick(index)
                                  },
                                },
                              },
                              [
                                _vm.$store.state.template.selectIndex == index
                                  ? _c(
                                      "div",
                                      { staticClass: "cardBtns cursorP" },
                                      [
                                        !["navigation", "immersive"].includes(
                                          item.name
                                        )
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "cardBtns-item",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.changeItem(
                                                      index,
                                                      -1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-up",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        !["navigation", "immersive"].includes(
                                          item.name
                                        )
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "cardBtns-item",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.changeItem(
                                                      index,
                                                      1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-arrow-down",
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "cardBtns-item",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.deleteItem(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(item.name, {
                                  tag: "component",
                                  attrs: { keys: "" + new Date(), data: item },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }