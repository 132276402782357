<template>
  <!-- 门店采购 -->
  <div class="Instore-detail">
    <detail :billType="billType" :tableBillSourceCout="1" :tableSpecialCount="1">
      <!-- 插槽 -->
      <template #tableBillSource>
        <el-table-column
          label="单据源"
          width="160"
          align="center"
          prop="billNo"
          show-overflow-tooltip
        ></el-table-column>
      </template>
      <template #tableSpecial>
        <el-table-column label="可退数量" align="center" prop="importUnitQty">
        </el-table-column>
        <el-table-column label="可退数量" align="center" prop="importUnitQty">
        </el-table-column>
      </template>
    </detail>
  </div>
</template>

<script>
import detail from "@/views/components/bill/detail-layout.vue";
export default {
  name: "StockInBackDetail",
  components: { detail },
  data() {
    return {
      billType: "140318",
    };
  },
};
</script>
