<template>
  <div class="pagination-view">
    <div class="left-view">
      <!-- <template v-if="allSelect && totals > 0">
        <span>已选</span>
        <span class="num">{{ check.length }}</span>
        <span>条</span>
        <span class="checkAll" v-if="check.length !== totals" @click="selectAll(true)"
          >选择全部</span
        >
        <span class="checkAll" v-else @click="selectAll(false)">取消选择</span>
      </template> -->
      <template>
        <span>共</span>
        <span class="num" style="color: #333">{{ totals }}</span>
        <span>条</span>
        <template v-if="isSelect">
          <span>，已选</span>
          <span class="num">{{ check.length }}</span>
          <span>条</span>
        </template>
        <template v-if="isAllSelect">
          <span
            style="color: #1890ff; margin-left: 5px; cursor: pointer"
            v-if="allSelectbtn"
            @click="handleAllSelect(true)"
            >选择全部</span
          >
          <span
            style="color: #1890ff; margin-left: 5px; cursor: pointer"
            v-else
            @click="handleAllSelect(false)"
            >取消选择</span
          >
        </template>
      </template>
    </div>
    <el-pagination
      v-if="!onlySelectTotal"
      ref="pagination"
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="totals"
      :pager-count="pageCount || 5"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
      <span
        class="el-pagination__total"
        style="
          display: inline-block;
          font-size: 13px;
          min-width: 35.5px;
          height: 28px;
          line-height: 28px;
          vertical-align: top;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        "
        >{{ `共 ${Math.ceil(totals / pageSize)} 页` }}</span
      >
    </el-pagination>
  </div>
</template>
<script>
import { scrollTo } from "@/utils/scroll-to.js";
export default {
  name: "ComponentPagination",
  props: {
    check: {
      type: Array,
      default: () => [],
    },
    // 是否全选
    allSelect: {
      type: Boolean,
      default: false,
    },
    // 总条目数 Number类型即为总条目数; Function类型即为需要去请求的api * 必传 filter 参数*，监听该筛选条件去无极获取 total
    total: {
      type: [Number, String, Function],
    },
    // 表格显示数据长度
    listLength: {
      type: Number,
      default: 0,
    },
    // 页码 第 {{page}} 页
    page: {
      type: Number,
      default: 1,
    },
    // 每页显示条目个数，支持 .sync 修饰符
    limit: {
      type: Number,
      default: 15,
    },
    // 每页显示个数选择器的选项设置
    pageSizes: {
      type: Array,
      default: () => [15, 30, 50, 100],
    },
    // 组件布局，子组件名用逗号分隔total,
    layout: {
      type: String,
      default: "slot, sizes, prev, pager, next, jumper",
    },
    // 是否为分页按钮添加背景色
    background: {
      type: Boolean,
      default: true,
    },
    // 每页条数/页码 改变时页面是否滚动到顶部
    autoScroll: {
      type: Boolean,
      default: true,
    },
    // 是否只显示总数
    onlySelectTotal: {
      type: Boolean,
      default: false,
    },
    // 是否能选择
    isSelect: {
      type: Boolean,
      default: false,
    },
    // 是否能全选
    isAllSelect: {
      type: Boolean,
      default: false,
    },
    pageCount: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      totals: null,
      allSelectbtn: true,
    };
  },
  computed: {
    noTotal() {
      return typeof this.totals !== "number";
    },
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    pageSize: {
      get() {
        return !this.pageSizes.includes(this.limit) ? this.pageSizes[0] : this.limit;
      },
      set(val) {
        this.$emit("update:limit", val);
      },
    },
  },
  watch: {
    total: {
      handler(val) {
        let total = Number(val);
        if (!isNaN(total) && typeof total === "number") {
          this.totals = total;
        } else {
          this.totals = null;
        }
      },
      immediate: true,
    },
    // 监听filter变化
    "$attrs.filter": {
      handler(val) {
        if (typeof this.total === "function" && typeof val === "string") {
          this.total({ data: { count: 1, filter: val } })
            .then((res) => {
              this.totals = res.data.total;
            })
            .catch(() => {
              this.totals = null;
            });
        }
      },
      immediate: true,
    },
  },
  methods: {
    selectAll(select = false) {
      this.$emit("select", select);
    },
    handleSizeChange(val) {
      // 如果 当前页面之前的页数 * 改变之后的每页数量 > 总数量 时（这里会导致页码变小，触发 current-page），就不用触发事件
      if ((this.currentPage - 1) * val >= this.totals) {
        return;
      }
      this.$emit("pagination", { page: this.currentPage, limit: val });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange(val) {
      this.$emit("pagination", { page: val, limit: this.pageSize });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleAllSelect(e) {
      if (e && this.totals > 2000) {
        return this.$message.warning("全选最多只能选择2000条数据！");
      }
      this.allSelectbtn = !e;
      this.$emit("handleEvent", "allSelect", { isAllSelect: e });
    },
  },
};
</script>
<style lang="scss" scoped>
.pagination-view {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-view {
    height: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;

    .num {
      color: #1890ff;
      margin: 0 2px;
    }

    .checkAll {
      margin-left: 5px;
      color: #1890ff;
      cursor: pointer;
    }
  }

  .el-pagination {
    & span:not([class*="suffix"]) {
      line-height: 26px;
      text-align: center;
    }

    button {
      border: 1px solid #dcdfe6;
    }
  }
}
</style>
