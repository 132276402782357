<template>
  <div class="clearfix" :style="{height:`${heightWarp}px`}">
    <!-- 这里可以放置您的内容，例如多个子组件或元素 -->
    <div v-for="(item, index) in selectedList" :key="index" class="cube-selected" :class="styleClass"
      @click="changeActive(index)" :style="{border: `${item.imageUrl ? 0 : 1}px solid ${active == index ? '#1890ff' : '#ebedf0'}`}">
      <img :src="item.imageUrl" v-if="item.imageUrl" height="100%" width="100%" />
      <div class="cube-selected-text" v-else>{{ item.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DynamicStyleComponent',
  data() {
    return {
      active: -1,
    }
  },
  model: { prop: 'value', event: 'modelValue' },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    layout: {
      type: [Number, String],
      required: true,
      validator: function (value) {
        return [1, 2, 3, 4, 5, 6, 7].includes(value);
      }
    }
  },
  computed: {
    heightWarp() {
      switch (this.layout) {
        case 1:
          return 186;
        case 2:
          return 123;
        case 3:
          return 92.5;
        case 4:
        case 5:
        case 6:
        case 7:
          return 373;
        default:
          return;
      }
    },
    styleClass() {
      switch (this.layout) {
        case 1:
          return 'two-per-row';
        case 2:
          return 'three-per-row';
        case 3:
          return 'four-per-row';
        case 4:
          return 'two-left-two-right';
        case 5:
          return 'one-left-two-right';
        case 6:
          return 'one-top-two-bottom';
        case 7:
          return 'one-left-three-right';
        default:
          return '';
      }
    },
    //选中列表。
    selectedList: {
      get() {
        return this.value
      },
      set(e) {
        this.$emit('modelValue', e)
      }
    },
  },
  methods: {
    changeActive(index) {
      this.active = index;
      this.$emit('activeChange', index)
    },
  },
};
</script>

<style lang='scss' scoped>
//一行两个
.two-per-row {
  width: 186px;
  height: 186px;
}
.two-per-row:first-child {
  top: 0;
  left: 0;
}
.two-per-row:nth-child(2) {
  top: 0;
  left: 186px;
}

//一行三个
.three-per-row {
  width: 123px;
  height: 123px;
}
.three-per-row:first-child {
  top: 0;
  left: 0;
}
.three-per-row:nth-child(2) {
  top: 0;
  left: 123px;
}
.three-per-row:nth-child(3) {
  top: 0;
  left: 246px;
}

//一行四个
.four-per-row {
  width: 92.5px;
  height: 92.5px;
}
.four-per-row:first-child {
  top: 0;
  left: 0;
}
.four-per-row:nth-child(2) {
  top: 0;
  left: 92.5px;
}
.four-per-row:nth-child(3) {
  top: 0;
  left: 185px;
}
.four-per-row:nth-child(4) {
  top: 0;
  left: 277.5px;
}

//两左两右
.two-left-two-right {
  width: 186px;
  height: 186px;
}
.two-left-two-right:first-child {
  top: 0;
  left: 0;
}
.two-left-two-right:nth-child(2) {
  top: 0;
  left: 186px;
}
.two-left-two-right:nth-child(3) {
  top: 186px;
  left: 0;
}
.two-left-two-right:nth-child(4) {
  top: 186px;
  left: 186px;
}

//一左两右
.one-left-two-right {
  width: 186px;
}
.one-left-two-right:first-child {
  height: 372px;
  top: 0;
  left: 0;
}
.one-left-two-right:nth-child(2) {
  height: 186px;
  top: 0;
  left: 186px;
}
.one-left-two-right:nth-child(3) {
  height: 186px;
  top: 186px;
  left: 186px;
}

//一上两下
.one-top-two-bottom {
  height: 186px;
}
.one-top-two-bottom:first-child {
  width: 372px;
  top: 0;
  left: 0;
}
.one-top-two-bottom:nth-child(2) {
  width: 186px;
  top: 186px;
  left: 0;
}
.one-top-two-bottom:nth-child(3) {
  width: 186px;
  top: 186px;
  left: 186px;
}

//一左三右
.one-left-three-right:first-child {
  height: 373px;
  width: 186px;
  top: 0;
  left: 0;
}
.one-left-three-right:nth-child(2) {
  height: 186px;
  width: 186px;
  top: 0;
  left: 186px;
}
.one-left-three-right:nth-child(3) {
  height: 186px;
  width: 92.5px;
  top: 186px;
  left: 186px;
}
.one-left-three-right:nth-child(4) {
  height: 186px;
  width: 92.5px;
  top: 186px;
  left: 278.5px;
}

.clearfix {
  position: relative;
  width: 375px;
  .cube-selected {
    text-align: center;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ebedf0;
    color: #7d7e80;
    cursor: pointer;
    img {
      height: inherit;
      vertical-align: baseline;
      -o-object-fit: cover;
      object-fit: cover;
    }
    .cube-selected-text {
      font-size: 12px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
</style>