import {
  getPurchaseDetail,
  getPurchaseStoreDetail,
  getPurchaseReturnDetail,
  getSaleDetail,
  getSaleOutboundDetail,
  getSaleReturnDetail,
  getStoreCheckDetail,
  getStoreAdjustDetail,
  getStorLossDetail,
  getStorAllotDetail,
  getStorOutDetail,
  getStorinDetail,
  getStoreCheckSurplusDetail,
  getStoreCheckLossDetail,
  getProductPlanDetail,
  getProductPickDetail,
  getProductReturnDetail,
  getProductDistributeDetail,
  getProductStoreDetail,
  getShopOrderDetail,
  getAssembleGoodsBillDetail,
  getDisassembleGoodsBillDetail,
  shopSaleGetDetail,
  otherOrderGetDetail,
  materialOrderGetDetail,
  getOrderDetail,
  autoDistributeDetail,
  getDetailMaterialDetail,
  materialGetDetailInfo,
  getCostModifyInfo,
  getShopPurchaseDetail,
  getShopPurchaseReturnDetail,
  getSaleGetDetail,
  getShopScrapGetDetail,
  getShopMoveInGetDetail,
  getShopMoveOutGetDetail,
  getShopProduceGetDetail,
  getShopWasteGetDetail,
  getShopCheckGetDetail,
  getShopCheckFullGetDetail,
  getShopCheckLoseGetDetail
} from '@/api/printAllApi.js'

import allNowPrint from '@/components/tablePage/tableButtons/nowPrintJson.js'
export default {
  '/purchase/bill/PurchaseOrder': {
    delKeys: [],
    pageTitle: "采购订单",
    getDetailApi: getPurchaseDetail
  },
  '/purchase/bill/PurchaseInstore': {
    pageTitle: "采购入库单",
    getDetailApi: getPurchaseStoreDetail
  },
  '/purchase/bill/BackDetail': {
    pageTitle: "采购退货单",
    getDetailApi: getPurchaseReturnDetail
  },
  '/sale/bill/saleOrder': {
    pageTitle: "销售订单",
    getDetailApi: getSaleDetail
  },
  '/sale/bill/SaleOutstore': {
    pageTitle: "销售出库单",
    getDetailApi: getSaleOutboundDetail
  },
  '/sale/bill/SaleBack': {
    pageTitle: "销售退货单",
    getDetailApi: getSaleReturnDetail
  },
  '/store/bill/check': {
    pageTitle: "仓库盘点单",
    getDetailApi: getStoreCheckDetail
  },
  '/store/bill/adjust': {
    pageTitle: "库存调整单",
    getDetailApi: getStoreAdjustDetail
  },
  '/store/bill/waste': {
    pageTitle: "仓库报损单",
    getDetailApi: getStorLossDetail
  },
  '/store/bill/move': {
    pageTitle: "仓库调拨单",
    getDetailApi: getStorAllotDetail
  },
  '/store/bill/moveOut': {
    pageTitle: "仓库调出单",
    getDetailApi: getStorOutDetail
  },
  '/store/bill/moveIn': {
    pageTitle: "仓库调入单",
    getDetailApi: getStorinDetail
  },
  '/store/bill/checkFull': {
    pageTitle: "仓库盘盈单",
    getDetailApi: getStoreCheckSurplusDetail
  },
  '/store/bill/checkLose': {
    pageTitle: "仓库盘亏单",
    getDetailApi: getStoreCheckLossDetail
  },
  '/produce/bill/plan': {
    pageTitle: "生产计划单",
    getDetailApi: getProductPlanDetail
  },
  '/produce/bill/leadMaterial': {
    pageTitle: "生产领料单",
    getDetailApi: getProductPickDetail
  },
  '/produce/bill/backMaterial': {
    pageTitle: "生产退料单",
    getDetailApi: getProductReturnDetail
  },
  '/produce/bill/inStore': {
    pageTitle: "产品入库单",
    getDetailApi: getProductStoreDetail
  },
  '/produce/bill/storeDistribute': {
    pageTitle: "产品分货单",
    nowPrint: allNowPrint['/produce/bill/storeDistribute'], // 直接打印
    getDetailApi: getProductDistributeDetail
  },
  '/shop/bill/order': {
    pageTitle: "门店订货单",
    getDetailApi: getShopOrderDetail
  },
  '/store/combinStrip/combinBill': {
    pageTitle: "商品组装单",
    getDetailApi: getAssembleGoodsBillDetail
  },
  '/store/combinStrip/stripBill': {
    pageTitle: "商品拆卸单",
    getDetailApi: getDisassembleGoodsBillDetail
  },
  '/shop/bill/clientSaleOrder': {
    pageTitle: "门店销售订单",
    getDetailApi: shopSaleGetDetail
  },
  '/produce/bill/storeOrder': {
    pageTitle: "其他订货单",
    getDetailApi: otherOrderGetDetail
  },
  '/produce/bill/storeMaterialOrder': {
    pageTitle: "其他原料订货单",
    getDetailApi: materialOrderGetDetail
  },
  '/shop/bill/reserveOrder': {
    pageTitle: "门店预订单",
    getDetailApi: getOrderDetail
  },
  '/produce/bill/autoDistribute': {
    pageTitle: "配货单",
    nowPrint: allNowPrint['/produce/bill/autoDistribute'], // 直接打印
    getDetailApi: autoDistributeDetail
  },
  '/produce/bill/matchMaterial': {
    pageTitle: "生产配料",
    getDetailApi: materialGetDetailInfo
  },
  '/shop/bill/materialOrder': {
    pageTitle: "门店原料订货",
    getDetailApi: getDetailMaterialDetail
  },
  '/shop/bill/stockIn': {
    pageTitle: "门店采购",
    getDetailApi: getShopPurchaseDetail
  },
  '/shop/bill/stockInBack': {
    pageTitle: "门店采购退货",
    getDetailApi: getShopPurchaseReturnDetail
  },
  '/shop/bill/clientSale': {
    pageTitle: "门店销售单",
    getDetailApi: getSaleGetDetail
  },
  '/shop/bill/scrap': {
    pageTitle: "门店报废",
    getDetailApi: getShopScrapGetDetail
  },
  '/shop/bill/moveIn': {
    pageTitle: "门店调入",
    getDetailApi: getShopMoveInGetDetail
  },
  '/shop/bill/moveOut': {
    pageTitle: "门店调出",
    getDetailApi: getShopMoveOutGetDetail
  },
  '/shop/bill/produce': {
    pageTitle: "门店生产",
    getDetailApi: getShopProduceGetDetail
  },
  '/shop/bill/waste': {
    pageTitle: "门店原料消耗",
    getDetailApi: getShopWasteGetDetail
  },
  '/shop/bill/check': {
    pageTitle: "门店盘点",
    getDetailApi: getShopCheckGetDetail
  },
  '/shop/bill/checkFull': {
    pageTitle: "门店盘盈单",
    getDetailApi: getShopCheckFullGetDetail
  },
  '/shop/bill/checkLose': {
    pageTitle: "门店盘亏单",
    getDetailApi: getShopCheckLoseGetDetail
  },
  '/store/bill/costPriceAdjust': {
    pageTitle: "成本调整",
    getDetailApi: getCostModifyInfo
  },
}