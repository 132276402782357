var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, "label-width": "96px", rules: _vm.rules },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "warp",
        },
        [
          _c("topOperatingButton", {
            attrs: {
              showIsStop: _vm.form.billStatus != 2,
              isExamineBtn: true,
              isStopBtn: true,
              isSubmitAddBtn: false,
              disabled: _vm.disabled,
              showAudit: _vm.showAudit,
            },
            on: {
              isStop: _vm.auditBill,
              submitForm: _vm.submitForm,
              auditBill: _vm.auditBill,
              getQuit: _vm.getQuit,
            },
          }),
          _c(
            "cardTitleCom",
            {
              attrs: { cardTitle: "基本信息", billStatus: _vm.form.billStatus },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "headTable x-x" }, [
                  _c("div", { staticClass: "right" }, [
                    _c("div", { staticClass: "bgWarp" }, [
                      _c(
                        "div",
                        { staticClass: "rightImg" },
                        [
                          _c("div", { staticClass: "close" }),
                          _vm.form.imagePlayType == 1
                            ? _c("img", {
                                attrs: {
                                  src:
                                    _vm.form.commonImageRespList[0].imageUrl ||
                                    "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-26/82d75f0f44df40d6bdfb05b32cd44ca8.png",
                                  alt: "商城弹窗广告",
                                },
                              })
                            : _c(
                                "el-carousel",
                                {
                                  attrs: {
                                    interval: 3000,
                                    trigger: "click",
                                    height: _vm.screenheight + "px",
                                  },
                                },
                                _vm._l(
                                  _vm.form.commonImageRespList,
                                  function (item, index) {
                                    return _c(
                                      "el-carousel-item",
                                      { key: item },
                                      [
                                        _c("img", {
                                          style: {
                                            height:
                                              index != 0
                                                ? _vm.screenheight + "px"
                                                : "",
                                          },
                                          attrs: {
                                            id: "screenheight" + index,
                                            src:
                                              item.imageUrl ||
                                              "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-26/82d75f0f44df40d6bdfb05b32cd44ca8.png",
                                            alt: "商城弹窗广告",
                                          },
                                        }),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex1 left" },
                    [
                      _c(
                        "div",
                        { staticClass: "x-w" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "广告编号：", prop: "billNo" } },
                            [
                              _c("el-input", {
                                staticClass: "inputWidth",
                                attrs: {
                                  disabled: true,
                                  placeholder: "广告编号",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.form.billNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "billNo", $$v)
                                  },
                                  expression: "form.billNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "广告名称：", prop: "billName" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "inputWidth",
                                attrs: {
                                  disabled: _vm.disabled,
                                  placeholder: "请输入广告名称",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.form.billName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "billName", $$v)
                                  },
                                  expression: "form.billName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "图片数量：", prop: "imagePlayType" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.disabled },
                              on: { change: _vm.changeImageType },
                              model: {
                                value: _vm.form.imagePlayType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "imagePlayType", $$v)
                                },
                                expression: "form.imagePlayType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("单张图片"),
                              ]),
                              _c(
                                "el-radio",
                                { attrs: { label: 2 } },
                                [
                                  _vm._v("轮播海报 "),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticStyle: {
                                        color: "black",
                                        "margin-left": "10px",
                                      },
                                      attrs: {
                                        content: "最多上传3张",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-x" },
                        [
                          _c(
                            "i",
                            {
                              staticStyle: {
                                color: "#ff4949",
                                "margin-top": "10px",
                              },
                            },
                            [_vm._v("* ")]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "广告图片：",
                                prop: "commonImageRespList",
                                "label-width": "88px",
                              },
                            },
                            [
                              _vm.form.imagePlayType == 2 &&
                              _vm.form.commonImageRespList.length < 3
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addImg()
                                        },
                                      },
                                    },
                                    [_vm._v("添加图片")]
                                  )
                                : _vm._e(),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "x-x" },
                                  _vm._l(
                                    _vm.form.commonImageRespList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.imageUrl,
                                          staticClass: "choosedImageItem x-fc",
                                          on: {
                                            click: function ($event) {
                                              return _vm.getOpenImg(index)
                                            },
                                          },
                                        },
                                        [
                                          !item.imageUrl
                                            ? _c("i", {
                                                staticClass: "el-icon-plus",
                                                staticStyle: {
                                                  "font-size": "32px",
                                                },
                                              })
                                            : _c("img", {
                                                staticClass: "thumbImage",
                                                attrs: {
                                                  src: item.imageUrl,
                                                  alt: "广告图",
                                                },
                                              }),
                                          !_vm.disabled
                                            ? _c(
                                                "span",
                                                { staticClass: "modifyImage" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      (item.imageUrl
                                                        ? "更换"
                                                        : "上传") + "图片"
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          (item.imageUrl ||
                                            _vm.form.commonImageRespList
                                              .length > 1) &&
                                          !_vm.disabled
                                            ? _c("i", {
                                                staticClass:
                                                  "el-icon-circle-close",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.delImg(index)
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _c("div", { staticClass: "annotateGrey" }, [
                                  _vm._v("建议宽度600像素，高度800像素"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "跳转路径：", prop: "linkPath" } },
                        [
                          _c(
                            "div",
                            { staticClass: "x-x" },
                            _vm._l(
                              _vm.form.commonImageRespList,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: { "margin-right": "20px" },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          type: "text",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getOpenLink(index)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            (item.linkPath ? "修改" : "选择") +
                                              "链接"
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "x-f" }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              "图片" +
                                                (index + 1) +
                                                "路径：" +
                                                (item.linkPathName || "")
                                            )
                                          ),
                                        ]
                                      ),
                                      item.linkPath
                                        ? _c("span", {
                                            staticClass:
                                              "el-icon-close operateDel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.delLink(index)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "投放时间：", prop: "activityTimes" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              size: "mini",
                              "picker-options": _vm.forbiddenTime,
                              disabled: _vm.disabled,
                              "unlink-panels": "",
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "default-time": ["00:00:00", "23:59:59"],
                            },
                            model: {
                              value: _vm.form.activityTimes,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "activityTimes", $$v)
                              },
                              expression: "form.activityTimes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "投放时段：",
                            prop: "useTimeRangeType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.form.useTimeRangeType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "useTimeRangeType", $$v)
                                },
                                expression: "form.useTimeRangeType",
                              },
                            },
                            _vm._l(
                              _vm.dict.type.use_time_range_type,
                              function (dict) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: dict.value,
                                    attrs: {
                                      label: parseInt(dict.value),
                                      value: dict.value,
                                    },
                                  },
                                  [_vm._v(_vm._s(dict.label) + " ")]
                                )
                              }
                            ),
                            1
                          ),
                          _vm.form.useTimeRangeType == 2
                            ? _c(
                                "div",
                                { staticClass: "timeTable" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "btn x-f marB10" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            disabled: _vm.disabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleEvent(
                                                "openTimeDateDialog"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("选择时间 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("EditTable", {
                                    attrs: {
                                      options: _vm.timeDateOptions,
                                      disabled: _vm.disabled,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-x" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发放对象：",
                                prop: "vipLevelRangeTypeArr",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  attrs: { disabled: _vm.disabled },
                                  on: { change: _vm.handleCheckVip },
                                  model: {
                                    value: _vm.form.vipLevelRangeTypeArr,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "vipLevelRangeTypeArr",
                                        $$v
                                      )
                                    },
                                    expression: "form.vipLevelRangeTypeArr",
                                  },
                                },
                                [
                                  _c("el-checkbox", { attrs: { label: "0" } }, [
                                    _vm._v("全部会员"),
                                  ]),
                                  _c("el-checkbox", { attrs: { label: "3" } }, [
                                    _vm._v("非会员"),
                                  ]),
                                  _c("el-checkbox", { attrs: { label: "1" } }, [
                                    _vm._v("全部会员+排除"),
                                  ]),
                                  _c("el-checkbox", { attrs: { label: "2" } }, [
                                    _vm._v("指定会员"),
                                  ]),
                                  _c("el-checkbox", { attrs: { label: "4" } }, [
                                    _vm._v("生日会员"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.showVipBD
                            ? _c(
                                "el-select",
                                {
                                  staticClass: "selectVipBD",
                                  attrs: {
                                    size: "mini",
                                    disabled: _vm.disabled,
                                  },
                                  model: {
                                    value: _vm.form.vipBirthdayType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "vipBirthdayType", $$v)
                                    },
                                    expression: "form.vipBirthdayType",
                                  },
                                },
                                _vm._l(
                                  _vm.dict.type.vip_birthday_type,
                                  function (dict) {
                                    return _c("el-option", {
                                      key: dict.value,
                                      attrs: {
                                        label: dict.label,
                                        value: parseInt(dict.value),
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.vipTable
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "marB10" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEvent(
                                            "openVipDialog"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择会员 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEvent(
                                            "openVipLevelDialog"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择会员等级 ")]
                                  ),
                                ],
                                1
                              ),
                              _c("comTable", {
                                attrs: {
                                  columnLabel: _vm.vipTableLable,
                                  tableData: _vm.form.popupAdCaseVipLevelList,
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  delTableRow: function ($event) {
                                    return _vm.delTableRow($event, "vip")
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "tableMiddle",
                                      fn: function () {
                                        return [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "类型",
                                              align: "center",
                                              prop: "lineType",
                                              width: "123",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("dict-tag", {
                                                        attrs: {
                                                          options:
                                                            _vm.dict.type
                                                              .member_type,
                                                          value:
                                                            scope.row.lineType,
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              889154850
                                            ),
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1129299638
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "x-x" },
                        [
                          _c(
                            "i",
                            {
                              staticStyle: {
                                color: "#ff4949",
                                "margin-top": "10px",
                              },
                            },
                            [_vm._v("* ")]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "应用页面：",
                                "label-width": "88px",
                              },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { disabled: _vm.disabled },
                                  model: {
                                    value: _vm.form.isPageHome,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isPageHome", $$v)
                                    },
                                    expression: "form.isPageHome",
                                  },
                                },
                                [_vm._v("店铺首页")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { disabled: _vm.disabled },
                                  model: {
                                    value: _vm.form.isPageGoodsCategory,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "isPageGoodsCategory",
                                        $$v
                                      )
                                    },
                                    expression: "form.isPageGoodsCategory",
                                  },
                                },
                                [_vm._v("分类页")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { disabled: _vm.disabled },
                                  model: {
                                    value: _vm.form.isPagePaySuccess,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "isPagePaySuccess",
                                        $$v
                                      )
                                    },
                                    expression: "form.isPagePaySuccess",
                                  },
                                },
                                [_vm._v("支付成功页")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { disabled: _vm.disabled },
                                  model: {
                                    value: _vm.form.isPageCenter,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isPageCenter", $$v)
                                    },
                                    expression: "form.isPageCenter",
                                  },
                                },
                                [_vm._v("我的页面")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "推送频次：", prop: "pushRateType" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.form.pushRateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "pushRateType", $$v)
                                },
                                expression: "form.pushRateType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 3 } }, [
                                _vm._v("每天一次"),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("永久一次"),
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("每次进入"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.showDialog,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.getAccessUrlList },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("selectLink", {
        attrs: { OpenLink: _vm.openLink },
        on: {
          "update:OpenLink": function ($event) {
            _vm.openLink = $event
          },
          "update:open-link": function ($event) {
            _vm.openLink = $event
          },
          selectLinkObj: _vm.selectLinkObj,
        },
      }),
      _c("Dialog", {
        ref: "tablePageDialog",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }