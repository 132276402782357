<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { httpInStoreList, sumlistPurchaseStore } from "@/api/purchase/index";
import { listSupplier } from "@/api/goods/supplier"; //供应商
import { allEmployeeList } from "@/api/system/employee"; //员工
import { allStoreList } from "@/api/system/store"; //仓库
import { updateInstoreStatus, updateReconStatus } from "@/api/purchase/index";
export default {
  components: { TablePage },
  dicts: ["bill_status"],
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid: "cdfcde34-d105-3d24-4b81-4d1952ff6509",
        getListApi: httpInStoreList,
        getSummaryApi: sumlistPurchaseStore,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "billId",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a + " 00:00:00",
              endBillDate: b + " 23:59:59",
            }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            label: "单据编号",
            seniorSearch: true,
            type: "input",
            model: "",
            filter: "billNo",
          },
          this.$select({
            key: "listSupplier",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          {
            label: "单据状态",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "billStatus",
            option: {
              remote: this.getDicts,
              remoteBody: "bill_status",
              dataKey: "data",
              label: "dictLabel",
              value: "dictValue",
              filterable: true,
            },
          },
          this.$select({
            key: "listStore",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listPurEmployee",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listPurCreateEmployee",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listSimpleQuery",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            btnType: "dropdown",
            other: [
              {
                click: "redoAudit",
                label: "反审核",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "recon",
            label: "对账",
            type: "success",
            btnType: "dropdown",
            other: [
                {
                    click: "redoRecon",
                    label: "反对账",
                },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "stop",
            label: "终止",
            icon: "el-icon-remove-outline",
            type: "primary",
            disabled: () => !this.options?.check?.length,
          },
          {
            label: "打印",
            type: "primary",
            btnType: "dropdown",
            print: {},
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
          },
          {
            prop: "billStatus",
            label: "单据状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "billQty",
            label: "单据数量",
            minWidth: 100,
          },
          {
            prop: "billTaxMoney",
            label: "单据金额(元)",
            minWidth: 140,
          },
          {
            prop: "partnerName",
            label: "供应商",
            minWidth: 160,
          },
          {
            prop: "storeName",
            label: "仓库",
            minWidth: 160,
          },
          {
            prop: "handBillNo",
            label: "手工单据号",
            minWidth: 120,
          },
          {
            prop: "deptName",
            label: "部门",
            minWidth: 160,
          },
          {
            prop: "purEmployeeName",
            label: "采购员",
            minWidth: 160,
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 160,
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 160,
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
          {
            prop: "checkAccountStatus",
            label: "对账状态",
            minWidth: 120,
            formatter: e => (e == '0' ? '未对账' : '已对账')
          },
          {
            prop: "checkAccountBy",
            label: "对账人",
            minWidth: 160,
          },
          {
            prop: "checkAccountTime",
            label: "对账时间",
            minWidth: 160,
          },
          {
            prop: "settleStatusName",
            label: "付款状态",
            minWidth: 160,
          },
          {
            prop: "settleMoney",
            label: "已付款金额",
            minWidth: 160,
          },
          {
            prop: "notSettleMoney",
            label: "未付款金额",
            minWidth: 160,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
        ],
        list: [],
        summary: ["billTaxMoney", "billQty"],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == "/index") {
        vm.options.defaultBody = { billStatus: to.query.billStatus };
        vm.options.search[4].model = to.query.billStatus;
        vm.options.search[1].defaultValue = "";
        let cloneData = cloneDeep(vm.options);
        vm.options = null;
        vm.options = cloneData;
      }
    });
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      let url = "/api/system/purchase/store/updateBillStatus";
      let billType = "110102";
      switch (type) {
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: "PurchaseInstoreDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push({
              name: "PurchaseInstoreDetail",
              query: {
                type: "Add",
              },
            });
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("审核", selectData, url, billType);
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("反审核", selectData, url, billType);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData, url, billType);
          }
          break;
        case "stop":
          {
            if (!selectData.length) return;
            this.handleBillStatus("关闭", selectData, url, billType);
          }
          break;
        case "recon":
          {
              if (!selectData.length) return;
              this.handleBillStatus("对账", selectData, '/api/system/purchase/store/modifyCheckAccountStatus', billType);
          }
          break;
        case "redoRecon":
          {
              if (!selectData.length) return;
              this.handleBillStatus("反对账", selectData, '/api/system/purchase/store/modifyCheckAccountStatus', billType);
          }
          break;
        case "copy":
          {
            if (!selectData.length) return;
            this.handleBillStatus(
              "复制",
              selectData,
              "/api/system/purchase/store/copy",
              billType
            );
          }
          break;
        default:
          break;
      }
    },
    handleBillStatus(command, selectData, url, BillType, type) {
      let billData = []; //过滤的数据
      let name = ""; //要执行的状态名字
      let tag = ""; //提示的状态
      let billStatus = ""; //要执行的状态数字
      let checkAccountStatus = "";
      if (command === "反审核") {
        // billData = selectData.filter(item => (item.billStatus === '2'))
        name = "反审核";
        tag = "已审核";
        billStatus = "0";
      } else {
        // billData = selectData.filter(item => (item.billStatus === '0'))
        if (command === "审核") {
          name = "审核";
          tag = "未审核";
          billStatus = "2";
        } else if (command === "删除") {
          name = "删除";
          tag = "未审核";
          billStatus = "3";
        } else if (command === "关闭") {
          name = "关闭";
          tag = "未审核";
          billStatus = "4";
        } else if (command === "对账") {
          name = "对账";
          checkAccountStatus = "1";
        } else if (command === "反对账") {
          name = "反对账";
          checkAccountStatus = "0";
        }
      }
      const billId = selectData.map((i) => i.billId);
      const billNo = selectData.map((i) => i.billNo);
      const $this = this;
      this.$modal
        .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
        .then(function () {
          if (!['对账', '反对账'].includes(name)) {
            return updateInstoreStatus([...billId], billStatus, url, BillType).then(() => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              $this.$modal.msgSuccess(`${name}成功`);
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
            });
          } else {
            updateReconStatus({ billIds: [...billId], checkAccountStatus: checkAccountStatus, url: url }).then(() => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              $this.$modal.msgSuccess(`${name}成功`);
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
