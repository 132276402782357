<template>
  <div class="dialog-content">
    <div class="content">
      <el-checkbox v-model="isSynMallPrice">同步商城价格</el-checkbox>
      <el-checkbox v-model="isSynCakefreeselectPrice">同步自选价格</el-checkbox>
      <el-checkbox v-model="isSynOrdermealPrice">同步点餐价格</el-checkbox>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">取 消</el-button>
      <el-button type="primary" @click="confirm()">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { mixin } from "@/components/Dialog/components/mixin.js";
import { synOnLinePrice } from "@/api/system/goods/infoDetail";
export default {
  name: "SyncTerminal",
  mixins: [mixin],
  data() {
    return {
      isSynMallPrice: false, // 是否同步商城价格
      isSynCakefreeselectPrice: false, // 是否同步自选价格
      isSynOrdermealPrice: false, // 是否同步点餐价格
    };
  },
  async created() {},
  methods: {
    async confirm() {
      try {
        const { data } = await synOnLinePrice({
          ...this.form,
          isSynMallPrice: this.isSynMallPrice,
          isSynCakefreeselectPrice: this.isSynCakefreeselectPrice,
          isSynOrdermealPrice: this.isSynOrdermealPrice,
        });
        if (!data) {
          this.$message.success("同步成功");
          this.$emit("dialogEvent", "dialogClose");
        } else {
          this.$message.warning(data);
        }
      } catch (err) {
        this.$message.error(err.msg || err.message || err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .content {
  padding: 20px !important;
}
</style>
