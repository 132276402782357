<template>
  <div class="wrap">
    <TablePage
      ref="tablePage"
      v-show="showList"
      v-model="options"
      @handleEvent="handleEvent"
    />
    <TablePage
      ref="tablePageDetail"
      v-if="!showList"
      class="detail"
      v-model="optionsDetail"
      @handleEvent="handleEventDetail"
    />
    <StockQueryDetail
      :openStockQuery.sync="openStockQuery"
      :goodsDetailData="goodsDetailData"
    />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  storeInventoryGoodsList,
  sumStoreInventoryGoodsList,
  storeInventoryGoodsDetail,
} from "@/api/storeReport"; //接口api
import StockQueryDetail from "./components/stockQueryDetail.vue"; //商品库存详情明细对话框组件
export default {
  name: "StockQuery",
  components: { TablePage, StockQueryDetail },
  data() {
    return {
      showList: true,
      openStockQuery: false,
      goodsDetailData: {},
      options: {
        uuid: "ef4d065e-e116-2e40-b728-aa7d6985fac6",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid: "937386eb-7c41-c251-cb82-0c9abe3530e0",
        body: {},
        title: "商品库存查询",
        tableTitle: "商品库存查询",
        exportOption: {
          show: true,
          exportApi: "exportListStoreBookReport",
          exportName: "商品库存查询",
          timeout: 60 * 5 * 1000,
        },
        search: [
          this.$select({
            key: "listCategory",
            option: { filter: "categoryIds", option: { multiple: true } },
          }),
          this.$select({
            key: "listGoods",
            option: { filter: "goodsIds", option: { multiple: true } },
          }),
          this.$select({
            key: "listStore",
            option: { filter: "storeIds", option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        getListApi: storeInventoryGoodsList,
        getSummaryApi: sumStoreInventoryGoodsList,
        columns: [
          { label: "仓库", prop: "storeName", minWidth: 120, fixed: true },
          {
            label: "商品编码",
            prop: "goodsNo",
            minWidth: 120,
            fixed: true,
            link: true,
            click: "oepnDetail",
          },
          { label: "商品名称", prop: "goodsName", minWidth: 100, fixed: true },
          { label: "条码", prop: "barcode", minWidth: 120 },
          { label: "商品类别", prop: "categoryName", minWidth: 100 },
          { label: "批号", prop: "batchNo", minWidth: 80 },
          { label: "规格", prop: "goodsSpec", minWidth: 80 },
          { label: "基础单位", prop: "unitName", minWidth: 80 },
          { label: "标准进价", prop: "purPrice", minWidth: 120 },
          { label: "标准售价", prop: "salePrice", minWidth: 100 },
          { label: "期初数", prop: "firstQty", minWidth: 120 },
          { label: "期初金额", prop: "firstMoney", minWidth: 120 },
          { label: "入库数", prop: "inQty", minWidth: 120 },
          { label: "入库金额", prop: "inMoney", minWidth: 120 },
          { label: "出库数", prop: "outQty", minWidth: 120 },
          { label: "出库金额", prop: "outMoney", minWidth: 120 },
          { label: "期末数", prop: "finalQty", minWidth: 120 },
          { label: "期末金额", prop: "finalMoney", minWidth: 120 },
        ],
        summary: [
          "firstQty",
          "firstMoney",
          "inQty",
          "inMoney",
          "outQty",
          "outMoney",
          "finalQty",
          "finalMoney",
        ],
      },
      optionsDetail: {
        uuid: "1838cd6e-0a0a-a7e7-9f7b-c09ce682ea13",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "商品库存明细",
        tableTitle: "商品库存明细",
        rowKey: "billId",
        delTableHeigth: 170,
        // list: [],
        defaultBody: {
          goodsId: "",
          storeId: "",
          batchNo: "",
        },
        getListApi: storeInventoryGoodsDetail,
        getDataKey: (e) => {
          this.optionsDetail.search = this.optionsDetail.search.map((item) => ({
            ...item,
            model: e.data.mainReport[item.filter],
          }));
          return { list: e.data.tableDataInfo.rows, total: e.data.tableDataInfo.total };
        },
        search: [
          {
            type: "input",
            label: "仓库",
            tip: " ",
            model: "",
            filter: "storeName",
            disabled: true,
          },
          {
            type: "input",
            label: "商品编码",
            tip: " ",
            model: "",
            filter: "goodsNo",
            disabled: true,
          },
          {
            type: "input",
            label: "商品名称",
            tip: " ",
            model: "",
            filter: "goodsName",
            disabled: true,
          },
          {
            type: "input",
            label: "商品条码",
            tip: " ",
            model: "",
            filter: "barcode",
            disabled: true,
          },
          {
            type: "input",
            label: "商品批号",
            tip: " ",
            model: "",
            filter: "batchNo",
            disabled: true,
          },
          {
            type: "input",
            label: "规格",
            tip: " ",
            model: "",
            filter: "goodsSpec",
            disabled: true,
          },
          {
            type: "input",
            label: "基础单位",
            tip: " ",
            model: "",
            filter: "unitName",
            disabled: true,
          },
          {
            type: "input",
            label: "成本价",
            tip: " ",
            model: "",
            filter: "costPrice",
            disabled: true,
          },
          {
            type: "input",
            label: "标准进价",
            tip: " ",
            model: "",
            filter: "purPrice",
            disabled: true,
          },
          {
            type: "input",
            label: "标准售价",
            tip: " ",
            model: "",
            filter: "salePrice",
            disabled: true,
          },
          {
            type: "input",
            label: "期初数",
            tip: " ",
            model: "",
            filter: "firstQty",
            disabled: true,
          },
          {
            type: "input",
            label: "期初金额",
            tip: " ",
            model: "",
            filter: "firstMoney",
            disabled: true,
          },
          {
            type: "input",
            label: "入库数",
            tip: " ",
            model: "",
            filter: "inQty",
            disabled: true,
          },
          {
            type: "input",
            label: "入库金额",
            tip: " ",
            model: "",
            filter: "inMoney",
            disabled: true,
          },
          {
            type: "input",
            label: "出库数",
            tip: " ",
            model: "",
            filter: "outQty",
            disabled: true,
          },
          {
            type: "input",
            label: "出库金额",
            tip: " ",
            model: "",
            filter: "outMoney",
            disabled: true,
          },
          {
            type: "input",
            label: "期末数",
            tip: " ",
            model: "",
            filter: "finalQty",
            disabled: true,
          },
          {
            type: "input",
            label: "期末金额",
            tip: " ",
            model: "",
            filter: "finalMoney",
            disabled: true,
          },
          { type: "button", tip: "关闭", btnType: "primary", click: "close" },
        ],
        summary: [
          "firstQty",
          "firstMoney",
          "inQty",
          "inMoney",
          "outQty",
          "outMoney",
          "finalQty",
          "finalMoney",
        ],
        columns: [
          { label: "记账时间", prop: "auditTime", fixed: true, minWidth: 120 },
          {
            label: "单据编号",
            prop: "billNo",
            minWidth: 120,
            fixed: true,
            link: true,
            click: "getBillData",
          },
          { label: "单据日期", prop: "billDate", minWidth: 100 },
          { label: "单据类型", prop: "billTypeName", minWidth: 120 },
          { label: "商品批号", prop: "batchNo", minWidth: 100 },
          // { label: '标准进价', prop: 'purPrice', minWidth: 120 },
          // { label: '标准售价', prop: 'salePrice', minWidth: 100 },
          { label: "期初数", prop: "firstQty", minWidth: 80 },
          { label: "期初金额", prop: "firstMoney", minWidth: 80 },
          { label: "入库数", prop: "inQty", minWidth: 80 },
          { label: "入库金额", prop: "inMoney", minWidth: 100 },
          { label: "出库数", prop: "outQty", minWidth: 80 },
          { label: "出库金额", prop: "outMoney", minWidth: 100 },
          { label: "期末数", prop: "finalQty", minWidth: 80 },
          { label: "期末金额", prop: "finalMoney", minWidth: 100 },
          {
            label: "入库",
            prop: "billDate",
            children: [
              { label: "采购", prop: "purQty", minWidth: 100 },
              { label: "采购金额", prop: "purMoney", minWidth: 100 },
              { label: "仓库调入数", prop: "purMoney", minWidth: 100 },
              { label: "仓库调入金额", prop: "purMoney", minWidth: 100 },
              { label: "库存调整入库数", prop: "adjustInQty", minWidth: 130 },
              { label: "库存调整入库金额", prop: "adjustInMoney", minWidth: 130 },
              { label: "销售退货数", prop: "saleBackQty", minWidth: 100 },
              { label: "销售退货金额", prop: "saleBackMoney", minWidth: 100 },
              { label: "仓库盘盈数", prop: "fullQty", minWidth: 100 },
              { label: "仓库盘盈金额", prop: "fullMoney", minWidth: 100 },
              { label: "生产退料数", prop: "backMaterialQty", minWidth: 100 },
              { label: "生产退料金额", prop: "backMaterialMoney", minWidth: 100 },
              { label: "产品入库数", prop: "adjustInQty", minWidth: 120 },
              { label: "产品入库金额", prop: "adjustInQty", minWidth: 120 },
              { label: "组装入库数", prop: "combinInQty", minWidth: 130 },
              { label: "组装入库金额", prop: "combinOutMoney", minWidth: 130 },
              { label: "拆卸入库数", prop: "stripInQty", minWidth: 100 },
              { label: "拆卸入库金额", prop: "stripInMoney", minWidth: 100 },
              { label: "门店采购数", prop: "shopPurQty", minWidth: 100 },
              { label: "门店采购金额", prop: "shopPurMoney", minWidth: 100 },
              { label: "门店生产数", prop: "shopProduceInQty", minWidth: 100 },
              { label: "门店生产金额", prop: "shopProduceInMoney", minWidth: 100 },
              { label: "门店调入数", prop: "shopMoveInQty", minWidth: 100 },
              { label: "门店调入金额", prop: "shopMoveInMoney", minWidth: 100 },
              { label: "门店盘盈数", prop: "shopFullQty", minWidth: 100 },
              { label: "门店盘盈金额", prop: "shopFullMoney", minWidth: 100 },
              { label: "现烤生产数", prop: "freshlyBakedProduceInQty", minWidth: 100 },
              {
                label: "现烤生产金额",
                prop: "freshlyBakedProduceInMoney",
                minWidth: 100,
              },
              { label: "现烤调入数", prop: "freshlyBakedMoveInQty", minWidth: 100 },
              { label: "现烤调入金额", prop: "freshlyBakedMoveInMoney", minWidth: 100 },
              { label: "现烤盘盈数", prop: "freshlyBakedFullQty", minWidth: 100 },
              { label: "现烤盘盈金额", prop: "freshlyBakedFullMoney", minWidth: 100 },
              { label: "裱花生产数", prop: "decorationProduceInQty", minWidth: 100 },
              { label: "裱花生产金额", prop: "decorationProduceInMoney", minWidth: 100 },
              { label: "裱花调入数", prop: "decorationMoveInQty", minWidth: 100 },
              { label: "裱花调入金额", prop: "decorationMoveInMoney", minWidth: 100 },
              { label: "裱花盘盈数", prop: "decorationFullQty", minWidth: 100 },
              { label: "裱花盘盈金额", prop: "decorationFullMoney", minWidth: 100 },
            ],
          },
          {
            label: "出库",
            prop: "billDate",
            children: [
              { label: "销售数", prop: "saleQty", minWidth: 100 },
              { label: "销售金额", prop: "saleMoney", minWidth: 100 },
              { label: "仓库调出数", prop: "saleMoney", minWidth: 100 },
              { label: "仓库调出金额", prop: "saleMoney", minWidth: 100 },
              { label: "库存调整出库数", prop: "adjustOutQty", minWidth: 130 },
              { label: "库存调整出库金额", prop: "adjustOutMoney", minWidth: 130 },
              { label: "采购退货数", prop: "purBackQty", minWidth: 100 },
              { label: "采购退货金额", prop: "purBackMoney", minWidth: 100 },
              { label: "仓库盘亏数", prop: "moveOutQty", minWidth: 100 },
              { label: "仓库盘亏金额", prop: "moveOutQty", minWidth: 100 },
              { label: "生产领料数", prop: "leadMaterialQty", minWidth: 100 },
              { label: "生产领料金额", prop: "leadMaterialMoney", minWidth: 100 },
              { label: "组装出库数", prop: "combinOutQty", minWidth: 120 },
              { label: "组装出库金额", prop: "combinOutMoney", minWidth: 120 },
              { label: "拆卸出库数", prop: "stripOutQty", minWidth: 130 },
              { label: "拆卸出库金额", prop: "stripOutMoney", minWidth: 130 },
              { label: "门店销售数", prop: "shopSaleQty", minWidth: 100 },
              { label: "门店销售金额", prop: "shopSaleMoney", minWidth: 100 },
              { label: "门店报废数", prop: "shopWasteQty", minWidth: 100 },
              { label: "门店报废金额", prop: "shopWasteMoney", minWidth: 100 },
              { label: "门店调出数", prop: "shopMoveOutQty", minWidth: 120 },
              { label: "门店调出金额", prop: "shopMoveOutMoney", minWidth: 120 },
              { label: "门店原料消耗数", prop: "shopLeadMaterialQty", minWidth: 130 },
              { label: "门店原料消耗金额", prop: "shopLeadMaterialMoney", minWidth: 130 },
              { label: "门店盘亏数", prop: "shopLoseQty", minWidth: 100 },
              { label: "门店盘亏金额", prop: "shopLoseMoney", minWidth: 100 },
              { label: "门店零售数", prop: "shopRetailQty", minWidth: 100 },
              { label: "门店零售金额", prop: "shopRetailMoney", minWidth: 100 },
              { label: "现烤报废数", prop: "freshlyBakedWasteQty", minWidth: 100 },
              { label: "现烤报废金额", prop: "freshlyBakedWasteMoney", minWidth: 100 },
              { label: "现烤调出数", prop: "freshlyBakedMoveOutQty", minWidth: 100 },
              { label: "现烤调出金额", prop: "freshlyBakedMoveOutMoney", minWidth: 100 },
              {
                label: "现烤原料消耗数",
                prop: "freshlyBakedLeadMaterialQty",
                minWidth: 130,
              },
              {
                label: "现烤原料消耗金额",
                prop: "freshlyBakedLeadMaterialMoney",
                minWidth: 130,
              },
              { label: "现烤盘亏数", prop: "freshlyBakedLoseQty", minWidth: 100 },
              { label: "现烤盘亏金额", prop: "freshlyBakedLoseMoney", minWidth: 100 },
              { label: "裱花报废数", prop: "decorationWasteQty", minWidth: 100 },
              { label: "裱花报废金额", prop: "decorationWasteMoney", minWidth: 100 },
              { label: "裱花调出数", prop: "decorationMoveOutQty", minWidth: 100 },
              { label: "裱花调出金额", prop: "decorationMoveOutMoney", minWidth: 100 },
              {
                label: "裱花原料消耗数",
                prop: "decorationLeadMaterialQty",
                minWidth: 130,
              },
              {
                label: "裱花原料消耗金额",
                prop: "decorationLeadMaterialMoney",
                minWidth: 130,
              },
              { label: "裱花盘亏数", prop: "decorationLoseQty", minWidth: 100 },
              { label: "裱花盘亏金额", prop: "decorationLoseMoney", minWidth: 100 },
            ],
          },
          { label: "备注", prop: "remark" },
        ],
      },
    };
  },
  methods: {
    async handleEventDetail(type, row) {
      switch (type) {
        case "close": // 关闭二级弹窗
          this.showList = true;
          this.optionsDetail = {
            listNo: true,
            loading: true,
            pagination: {
              total: 0,
              page: 1,
              size: 15,
            },
            body: {},
            title: "商品库存明细",
            tableTitle: "商品库存明细",
            rowKey: "billId",
            defaultBody: {},
            delTableHeigth: 170,
            getListApi: storeInventoryGoodsDetail,
            getDataKey: (e) => {
              this.optionsDetail.search = this.optionsDetail.search.map((item) => ({
                ...item,
                model: e.data.mainReport[item.filter],
              }));
              return {
                list: e.data.tableDataInfo.rows,
                total: e.data.tableDataInfo.total,
              };
            },
            search: [
              {
                type: "input",
                label: "仓库",
                tip: " ",
                model: "",
                filter: "storeName",
                disabled: true,
              },
              {
                type: "input",
                label: "商品编码",
                tip: " ",
                model: "",
                filter: "goodsNo",
                disabled: true,
              },
              {
                type: "input",
                label: "商品名称",
                tip: " ",
                model: "",
                filter: "goodsName",
                disabled: true,
              },
              {
                type: "input",
                label: "商品条码",
                tip: " ",
                model: "",
                filter: "barcode",
                disabled: true,
              },
              {
                type: "input",
                label: "商品批号",
                tip: " ",
                model: "",
                filter: "batchNo",
                disabled: true,
              },
              {
                type: "input",
                label: "规格",
                tip: " ",
                model: "",
                filter: "goodsSpec",
                disabled: true,
              },
              {
                type: "input",
                label: "基础单位",
                tip: " ",
                model: "",
                filter: "unitName",
                disabled: true,
              },
              {
                type: "input",
                label: "成本价",
                tip: " ",
                model: "",
                filter: "costPrice",
                disabled: true,
              },
              {
                type: "input",
                label: "标准进价",
                tip: " ",
                model: "",
                filter: "purPrice",
                disabled: true,
              },
              {
                type: "input",
                label: "标准售价",
                tip: " ",
                model: "",
                filter: "salePrice",
                disabled: true,
              },
              {
                type: "input",
                label: "期初数",
                tip: " ",
                model: "",
                filter: "firstQty",
                disabled: true,
              },
              {
                type: "input",
                label: "期初金额",
                tip: " ",
                model: "",
                filter: "firstMoney",
                disabled: true,
              },
              {
                type: "input",
                label: "入库数",
                tip: " ",
                model: "",
                filter: "inQty",
                disabled: true,
              },
              {
                type: "input",
                label: "入库金额",
                tip: " ",
                model: "",
                filter: "inMoney",
                disabled: true,
              },
              {
                type: "input",
                label: "出库数",
                tip: " ",
                model: "",
                filter: "outQty",
                disabled: true,
              },
              {
                type: "input",
                label: "出库金额",
                tip: " ",
                model: "",
                filter: "outMoney",
                disabled: true,
              },
              {
                type: "input",
                label: "期末数",
                tip: " ",
                model: "",
                filter: "finalQty",
                disabled: true,
              },
              {
                type: "input",
                label: "期末金额",
                tip: " ",
                model: "",
                filter: "finalMoney",
                disabled: true,
              },
              { type: "button", tip: "关闭", btnType: "primary", click: "close" },
            ],
            summary: [
              "firstQty",
              "firstMoney",
              "inQty",
              "inMoney",
              "outQty",
              "outMoney",
              "finalQty",
              "finalMoney",
            ],
            columns: [
              { label: "记账时间", prop: "auditTime", fixed: true, minWidth: 120 },
              {
                label: "单据编号",
                prop: "billNo",
                minWidth: 120,
                fixed: true,
                link: true,
                click: "getBillData",
              },
              { label: "单据日期", prop: "billDate", minWidth: 100 },
              { label: "单据类型", prop: "billTypeName", minWidth: 120 },
              { label: "商品批号", prop: "batchNo", minWidth: 100 },
              // { label: '标准进价', prop: 'purPrice', minWidth: 120 },
              // { label: '标准售价', prop: 'salePrice', minWidth: 100 },
              { label: "期初数", prop: "firstQty", minWidth: 80 },
              { label: "期初金额", prop: "firstMoney", minWidth: 80 },
              { label: "入库数", prop: "inQty", minWidth: 80 },
              { label: "入库金额", prop: "inMoney", minWidth: 100 },
              { label: "出库数", prop: "outQty", minWidth: 80 },
              { label: "出库金额", prop: "outMoney", minWidth: 100 },
              { label: "期末数", prop: "finalQty", minWidth: 80 },
              { label: "期末金额", prop: "finalMoney", minWidth: 100 },
              { label: "备注", prop: "remark" },
            ],
          };
          break;
      }
    },
    async handleEvent(type, row) {
      switch (type) {
        case "close": // 关闭二级弹窗
          this.showList = true;
          this.optionsDetail.list = [];
          break;
        case "oepnDetail": //获取点击商品信息
          this.optionsDetail.defaultBody.goodsId = row.goodsId;
          this.optionsDetail.defaultBody.storeId = row.storeId;
          this.optionsDetail.defaultBody.batchNo = row.batchNo ? row.batchNo : "";
          this.showList = false;
          break;
        case "getBillData": // 明细点击
          // let billId = row.billId
          // let billType = row.billType
          // let routerName
          // if(billType === '102') routerName = 'PurchaseInstoreDetail'
          // if(billType === '103') routerName = 'PurchaseBackDetail'
          // if(billType === '105') routerName = 'SaleOutDetail'
          // if(billType === '106') routerName = 'SaleBackDetail'
          // if(billType === '107') routerName = 'storeCheckDetail'
          // if(billType === '108') routerName = 'storeAdjustDetail'
          // if(billType === '109') routerName = 'storeWasteDetail'
          // if(billType === '110') routerName = 'storeMoveDetail'
          // if(billType === '111') routerName = 'storeMoveOutDetail'
          // if(billType === '112') routerName = 'storeMoveInDetail'
          // if(billType === '113') routerName = 'storeCheckFullDetail'
          // if(billType === '114') routerName = 'storeCheckLoseDetail'
          // this.$router.push({
          //   name:routerName,
          //   query: {
          //     billId,
          //     type: 'Update'
          //   }
          // })
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
//.detail {
//  ::v-deep .el-table {
//    height: 500px !important;
//    max-height: 500px !important;
//    overflow: visible !important;
//  }
//}
</style>
